import { BaseEntity } from '../../BaseEntity';

export enum AlertType {
    Error = 'error',
    Notice = 'notice',
    Warning = 'warning',
}

export type Alert = BaseEntity & {
    type: AlertType;
    message: string;
    start_datetime: string;
    end_datetime: string;
};
