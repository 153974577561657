import { Agency } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { AgencyState } from './Agency-types';

const initialState: AgencyState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: AgencyState, action: PayloadAction<string>): AgencyState => state,
};

const slice = createBaseSlice<Agency, AgencyState, typeof additionalReducers>({
    name: 'agency',
    entityName: 'Agency',
    initialState,
    reducers: additionalReducers,
});

const agencySlice = {
    agencyActions: slice.slice.actions,
    agencyReducer: slice.slice.reducer,
    agencyAdapter: slice.adapter,
    agencySelectors: slice.selectors,
    agencyThunks: slice.thunks,
};

export default agencySlice;
export const { agencyReducer, agencyActions, agencyAdapter, agencySelectors, agencyThunks } =
    agencySlice;
