import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName, PopDisplays_LogImportSource } from 'c-sdk';
import { logFileSourceIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import {
    AllEntitiesDropdown,
    AutoGrid,
    Checkbox,
    ControlledFormInput,
    CreateEntityButton,
    TextField,
} from 'c-components';
import { Translate } from 'c-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactGA from 'react-ga';
import LogFileSourceStatusCell from '../Components/LogFileSourceTableCells/LogFileSourceStatusCell';

const paginationTag = 'log-import-source-home';
const columns = [
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_LogImportSource',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.nameCol',
        field: 'name',
        entityName: 'PopDisplays_LogImportSource',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.dirNameCol',
        field: 'directory_name',
        entityName: 'PopDisplays_LogImportSource',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.marketCol',
        field: 'market.name',
        entityName: 'PopDisplays_LogImportSource',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.parseLimit',
        field: 'parse_limit',
        entityName: 'PopDisplays_LogImportSource',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.archiveDeletedFilesCol',
        field: 'archive_delete_files',
        entityName: 'PopDisplays_LogImportSource',
        dataType: 'boolean',
        textAlign: 'center',
    }),
    generateColumn<PopDisplays_LogImportSource>({
        headerTitle: 'Modules.PopDisplays.LogImportSource.table.activeCol',
        field: 'active',
        dataType: 'boolean',
        RenderCell: LogFileSourceStatusCell,
        entityName: 'PopDisplays_LogImportSource',
    }),
];
const textSearchCols = ['any'];
const includes = ['market'];

const schema = yup.object({
    name: yup.string().required(),
    market_id: yup.number().required(),
    parse_limit: yup.number().required(),
    archive_delete_files: yup.boolean().required(),
    archive_date_limit: yup.string().required(),
    active: yup.boolean().required(),
});

const defaultValues = {
    name: '',
    parse_limit: 500,
    archive_date_limit: '-1 week',
    archive_delete_files: true,
    active: true,
};
const formDefaultProps = { defaultValues, resolver: yupResolver(schema) };

const LogImportSourceListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onSuccess = useCallback(() => {
        ref?.current?.search();
    }, []);
    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.LogImportSource.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<PopDisplays_LogImportSource>
                        key="new-log-source-btn"
                        entityName="PopDisplays_LogImportSource"
                        title="Modules.PopDisplays.LogImportSource.create.newSourceButton"
                        buttonLabel={
                            <Translate path="Modules.PopDisplays.LogImportSource.create.newSourceButton" />
                        }
                        form={<NewSourceForm />}
                        onSuccess={onSuccess}
                        formOptions={formDefaultProps}
                        permissions={[PermissionName.Pop_displaysLog_import_sourceCreate]}
                    />,
                ],
                [onSuccess],
            )}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="PopDisplays_LogImportSource"
                textSearchColumns={textSearchCols}
                includes={includes}
                rendererType={FilterableEntityTableRendererType.Contained}
                perPage={100}
            />
        </FullPageEntityTableWrapper>
    );
};
const NewSourceForm = () => (
    <AutoGrid spacing={2} xs={12}>
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.nameLabel" />
                    }
                    {...field}
                    autoFocus
                />
            )}
            name="name"
        />
        <ControlledFormInput
            render={({ field }) => (
                <AllEntitiesDropdown
                    useLocalEntityData
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.marketNameLabel" />
                    }
                    {...field}
                    entityName="PopDisplays_Market"
                    labelField="name"
                />
            )}
            name="market_id"
        />
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.parseLimitLabel" />
                    }
                    type="number"
                    {...field}
                />
            )}
            name="parse_limit"
        />
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.archiveDeleteFilesLabel" />
                    }
                    {...field}
                />
            )}
            name="archive_date_limit"
        />
        <ControlledFormInput
            render={({ field }) => (
                <Checkbox
                    isBoolean
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.archiveDeleteFilesLabel" />
                    }
                    {...field}
                />
            )}
            name="archive_delete_files"
        />
        <ControlledFormInput
            render={({ field }) => (
                <Checkbox
                    isBoolean
                    label={
                        <Translate path="Modules.PopDisplays.LogImportSource.create.activeLabel" />
                    }
                    {...field}
                />
            )}
            name="active"
        />
    </AutoGrid>
);

const setup: PageConfig = {
    id: 'LogImportSourceListPage',
    component: LogImportSourceListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.LogImportSource.metaTitle',
        description: 'Modules.PopDisplays.LogImportSource.description',
        type: 'page',
        icon: logFileSourceIcon,
        aliases: 'SystemSearch.aliases.popDisplaysLogImportSourceList',
        route: PostAuthRoutes.PopDisplays.LogImportSource,
        saveAsRecentPage: true,
        permissions: [PermissionName.Pop_displaysLog_import_sourceRead],
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.LogFileImport.metaTitle' },
        ],
    },
};

export default setup;
