import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Box } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import ContentLayout from 'c-wrapper/Components/ContentLayout';
import { Button, ControlledFormInput, TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { passwordResetSelectors, passwordResetThunks } from '../../Slices';

const schema = (emailError: string) =>
    yup.object({
        email: yup.string().defined().email(emailError),
    });

const RequestPasswordResetForm = () => {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(
            schema(useCommonTranslation('Pages.ForgotPasswordRequest.invalidEmail')),
        ) as any,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    useWatch({ control, name: 'email' });

    const dispatch = useDispatch();
    const resetState = useSelector(passwordResetSelectors.passwordRequestResetEmailState);

    const onSubmit = useCallback(
        async ({ email }) => {
            await dispatch(passwordResetThunks.requestResetEmail({ email }));
        },
        [dispatch],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ContentLayout.Form>
                <ContentLayout.FormFields>
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="email"
                        as={
                            <TextField
                                greyBackdrop
                                label={useCommonTranslation(
                                    'Pages.ForgotPasswordRequest.emailInputLabel',
                                )}
                                placeholder={useCommonTranslation(
                                    'Pages.ForgotPasswordRequest.emailInputPlaceholder',
                                )}
                                required
                                type="email"
                            />
                        }
                    />
                </ContentLayout.FormFields>
                <Box mt="3rem" display="flex" justifyContent="center">
                    <Button
                        id="request-password-reset-submit"
                        type="submit"
                        disabled={resetState.state === NetworkRequestState.InProgress}
                    >
                        {useCommonTranslation('Pages.ForgotPasswordRequest.submitLabel')}
                    </Button>
                </Box>
            </ContentLayout.Form>
        </form>
    );
};

export default RequestPasswordResetForm;
