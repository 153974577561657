import { AxiosError, AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import { AllEntities, BaseEntity } from 'c-sdk';
import { PaginatedEntities, SearchPayload } from '../../Types';

export type FakeApi<Entity extends BaseEntity> = {
    /**
     * Used to toggle between real api and fake api.
     */
    useFake: boolean;
    /**
     * Number of milliseconds the fake api will load for.
     */
    returnDelay?: number;
    /**
     * The fail rate percentage. Set to 100 to recieve and error on every request.
     */
    failurePercentage?: number;
    /**
     * The initial set of entities that will be loaded if nothing exists in local storage. If this is updated and local storage is populated you will need to clear local storage to see the changes.
     */
    initialEntities?: (Partial<Entity> & BaseEntity)[];
    /**
     * This is a function to alter the data that is inputted by a user through updateById reducer.
     */
    amendCreateCapture?: (entity: Partial<Entity>) => Partial<Entity>;
    /**
     * This is a function to alter the data that is inputted by a user through create reducer.
     */
    amendUpdateCapture?: (entity: Partial<Entity>) => Partial<Entity>;
};

type FakeApiFuncProps<Entity extends BaseEntity> = FakeApi<Entity> & {
    entityName: keyof AllEntities;
};
export const getFakeList = <Entity extends BaseEntity>({
    entityName,
    initialEntities,
}: FakeApiFuncProps<Entity>): Entity[] => {
    try {
        const list = window.localStorage.getItem(`FAKE_API_${entityName}`);

        if (typeof list === 'string') {
            const parsed = JSON.parse(list);
            if (Array.isArray(parsed)) {
                return parsed.length === 0 ? (initialEntities as Entity[]) ?? [] : parsed;
            }
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return (initialEntities as Entity[]) ?? [];
};

export const setFakeList = <Entity extends BaseEntity>(
    { entityName }: FakeApiFuncProps<Entity>,
    list: Entity[],
) => {
    window.localStorage.setItem(`FAKE_API_${entityName}`, JSON.stringify(list));
    return list;
};

const RandomError = ({ failurePercentage }: FakeApi<any>) => {
    const ran = Math.floor(Math.random() * 100);
    if (ran <= failurePercentage) {
        return 'An Error occured calling the fake api';
    }
    return undefined;
};

const getListPromise = <Entity extends BaseEntity>(
    props: FakeApiFuncProps<Entity>,
): Promise<Entity[]> =>
    new Promise((resolve: (entities: Entity[]) => void) =>
        setTimeout(() => resolve(getFakeList<Entity>(props)), props.returnDelay ?? 0),
    ).then((entities: Entity[]) => {
        const randomError = RandomError(props);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        if (randomError) throw randomError;
        return entities;
    });
const createResponse = <T>(response: T): [AxiosError | null, T | null, AxiosResponse<T> | null] => [
    null,
    response,
    { data: response, status: 200, statusText: 'OK', headers: {}, config: {} },
];

const createError =
    <ReturnType>() =>
    (error: string): [AxiosError | null, ReturnType | null, AxiosResponse<ReturnType> | null] =>
        [
            { response: { data: { meta: { error } } } as AxiosResponse<any> } as AxiosError,
            null,
            null,
        ];

export const callFakeApiGetById = <Entity extends BaseEntity>({
    id,
    ...props
}: FakeApiFuncProps<Entity> & { id: number }): Promise<
    [AxiosError | null, { data: Entity } | null, AxiosResponse<{ data: Entity }> | null]
> =>
    getListPromise<Entity>(props)
        .then(entities => {
            const data = entities.filter(x => x.id == id);
            if (data && data.length > 0) {
                return createResponse({ data: data[0] });
            }
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw `No Entity '${props.entityName}' was found with id '${id}'`;
        })
        .catch(createError<{ data: Entity }>());

export const callFakeApiGetPaginatedList = <Entity extends BaseEntity>({
    page,
    searchPayload,
    ...props
}: FakeApiFuncProps<Entity> & {
    page: number;
    searchPayload: SearchPayload<Entity>;
}): Promise<
    [
        AxiosError | null,
        PaginatedEntities<Entity> | null,
        AxiosResponse<PaginatedEntities<Entity>> | null,
    ]
> =>
    getListPromise<Entity>(props)
        .then(entities => {
            const perPage = searchPayload?.perPage ?? 10;
            const paginatedData = entities.slice(
                perPage * ((page ?? 1) - 1),
                perPage * ((page ?? 1) - 1) + perPage,
            );
            return createResponse<PaginatedEntities<Entity>>({
                data: paginatedData,
                meta: {
                    pagination: {
                        total: entities.length,
                        count: paginatedData.length,
                        per_page: perPage,
                        current_page: page,
                        total_pages: entities.length / perPage,
                    },
                },
            });
        })
        .catch(createError<PaginatedEntities<Entity>>());

export const callFakeApiUpdateById = <Entity extends BaseEntity>({
    id,
    data,
    amendUpdateCapture,
    ...props
}: FakeApiFuncProps<Entity> & {
    id: number;
    data: Partial<Entity>;
}): Promise<[AxiosError | null, { data: Entity } | null, AxiosResponse<{ data: Entity }> | null]> =>
    getListPromise<Entity>(props)
        .then(entities => {
            let newEntity: Entity;
            const newList = entities.reduce((accumulator, entity) => {
                if (entity.id == id) {
                    newEntity = {
                        ...((amendUpdateCapture ? amendUpdateCapture(entity) : entity) as Entity),
                        ...data,
                    };
                    return [...accumulator, newEntity];
                }
                return [...accumulator, { ...entity }];
            }, []);
            setFakeList<Entity>(props, newList);
            return createResponse<{ data: Entity }>({ data: newEntity });
        })
        .catch(createError<{ data: Entity }>());

export const callFakeApiCreate = <Entity extends BaseEntity>({
    entity,
    amendCreateCapture,
    ...props
}: FakeApiFuncProps<Entity> & {
    entity: Partial<Entity>;
}): Promise<[AxiosError | null, { data: Entity } | null, AxiosResponse<{ data: Entity }> | null]> =>
    getListPromise<Entity>(props)
        .then(entities => {
            const newEntity: Entity = {
                ...((amendCreateCapture ? amendCreateCapture(entity) : entity) as Entity),
                id: Math.random(),
                created_at: formatISO(new Date()),
            };
            setFakeList<Entity>(props, [...entities, newEntity]);
            return createResponse<{ data: Entity }>({ data: newEntity });
        })
        .catch(createError<{ data: Entity }>());

export const callFakeApiDeleteById = <Entity extends BaseEntity>({
    id,
    ...props
}: FakeApiFuncProps<Entity> & {
    id: number;
}): Promise<[AxiosError | null, { data: Entity } | null, AxiosResponse<any>]> =>
    getListPromise<Entity>(props)
        .then(entities => {
            const newList = entities.filter(e => e.id !== id);
            setFakeList<Entity>(props, newList);
            return createResponse<any>({});
        })
        .catch(createError<any>());
