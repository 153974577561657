import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Collapse } from '@mui/material';
import { format, parseISO } from 'date-fns';
import {
    ElasticSearchFrameIdRequestPayload,
    ElasticSearchGroupByFieldRequestPayload,
    ElasticSearchSummaryResponse,
    EsPCAReportField,
    PCAAnalyticsReportResponse,
    PCAMapReportPlayStats,
    PCAReportByFieldPlayStats,
    PCAReportField,
    PCAReportMetric,
    Campaign,
    PCAMapReportPlayStatsMinimal,
} from 'c-sdk';
import { apiClient, useEntityData } from 'c-data';
import {
    ChartReportPart,
    ReportConfig,
    ReportDateTimeframe,
    ReportProps,
    ReportType,
    ReportWrapperProps,
    ReportWrapperRefAPI,
} from 'c-reports/Types';
import { ReportWrapper } from 'c-reports/Components';
import { ByFieldOverall } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/byFieldDataMutators';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { useTheme } from '@mui/styles';
import { TooltipProps } from 'recharts';
import { dateSortArray } from 'c-lib';
import { ChartDataKeyDateFormat } from 'c-reports/Generators';
import to from 'await-to-js';
import CustomTooltip from 'c-main/Components/Campaign/CampaignReports/Views/ByField/CustomTooltip';
import {
    FieldReportType,
    PCAMetricUrlStateKeys,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { useAPIClientRequest, useDateUtils, useUrlState } from 'c-hooks';
import { usePrevious } from 'react-hanger';
import { omit } from 'ramda';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import { allFieldValuesEs } from 'c-main/Lib';
import equal from 'fast-deep-equal';
import { Button } from 'c-components';
import { FilterList, KeyboardArrowDown } from '@mui/icons-material';
import { ReportFieldFilterES } from 'c-main/Components/Campaign/CampaignReports/ReportFieldFilter';
import { elasticSearchSummaryToOldSummary } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/SummaryTransformers';
import {
    groupedByFilterToLeaderboardData,
    mapDataToSqlMapData,
} from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';
import { sortByNameCustomField } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes/sorting';
import ImpactsMapView from './ImpactsMapView';
import PCATotalsByMetric from './PCATotalsByMetric';

type Props = {
    id: number;
};

type HourlyDataShape = Record<string, Record<string, PCAReportByFieldPlayStats>>;
type MapDataShape = Record<string, Record<string, PCAMapReportPlayStatsMinimal>>;

const CampaignAnalyticsES: React.FC<Props> = ({ id }) => {
    const theme = useTheme();
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const { formatDateStringIgnoreTime } = useDateUtils();
    const { replace } = useUrlState();
    const {
        environmentMode,
        showAdvancedFilters,
        advancedFilters,
        advancedFiltersCount,
        onAdvancedFiltersUpdated,
        onResetAdvancedFilters,
        countFields,
        source,
    } = useCampaignWrapperState(id);
    const campaignStart = useMemo(
        () =>
            campaign?.date?.date_start
                ? new Date(format(parseISO(campaign?.date?.date_start), 'yyyy-MM-dd'))
                : new Date(),
        [campaign?.date?.date_start],
    );
    const campaignEnd = useMemo(
        () =>
            campaign?.date?.date_end
                ? new Date(format(parseISO(campaign?.date?.date_end), 'yyyy-MM-dd'))
                : new Date(),
        [campaign?.date?.date_end],
    );

    const [pcaData, setPcaData] = useState<ElasticSearchSummaryResponse>(null);
    const allValuesByField = useMemo(
        () => (pcaData ? allFieldValuesEs(pcaData?.filters) : null),
        [pcaData],
    );

    useEffect(() => {
        // remove
        replace(PCAMetricUrlStateKeys.root, undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reportWrapperRef = useRef<ReportWrapperRefAPI>();
    const prevCountFields = usePrevious(countFields);
    const prevAdvancedFilters = usePrevious(advancedFilters);

    useEffect(() => {
        // just to stop it fetching data on mount
        if (prevCountFields != undefined && prevAdvancedFilters != undefined) {
            // removing hourly data for the currently date range because filters have changed
            setHourlyData(curr => omit([currentHourlyDataKey], curr));
            setFilteredMapData(curr => omit([currentFilteredMapDataKey], curr));
            reportWrapperRef?.current?.refreshData?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countFields, advancedFilters]);

    const data = useMemo<PCAAnalyticsReportResponse | null>(
        () => elasticSearchSummaryToOldSummary(pcaData),
        [pcaData],
    );

    const [timeframe, setTimeframe] = useState<ReportDateTimeframe | null>(ReportDateTimeframe.All);

    const genKey = useCallback(
        (date: Date, date2: Date) =>
            `${formatDateStringIgnoreTime({ date })}-${formatDateStringIgnoreTime({
                date: date2,
            })}`,
        [formatDateStringIgnoreTime],
    );

    const [currentHourlyDataKey, setCurrentHourlyDataKey] = useState(
        genKey(campaignStart, campaignEnd),
    );
    const [currentFilteredMapDataKey, setCurrentFilteredMapDataKey] = useState(
        genKey(campaignStart, campaignEnd),
    );
    const [hourlyData, setHourlyData] = useState<HourlyDataShape>({});
    const [filteredMapData, setFilteredMapData] = useState<MapDataShape>({});

    const scrollDownOnce = useRef(false);
    const prevData = usePrevious(data);

    // BP - Not sure why this was ever in here - leaving in case it bites me later
    // useEffect(() => {
    //     if (prevData == null && data != null && !scrollDownOnce.current) {
    //         scrollDownOnce.current = true;
    //         scrollToTopReportingTab();
    //     }
    // }, [data, prevData]);

    const { start: StartGetHours } = useAPIClientRequest(
        apiClient.ReportsElasticSearch.pcaGroupByField,
    );
    const { start: StartGetFilteredMapData } = useAPIClientRequest(
        apiClient.ReportsElasticSearch.frameIdMapData,
    );

    const hoursFetchFilters = useRef<ElasticSearchGroupByFieldRequestPayload>(null);
    const fetchHoursData = useCallback(
        async (start: Date, end: Date) => {
            const hrKey = genKey(start, end);
            setCurrentHourlyDataKey(hrKey);
            const config: ElasticSearchGroupByFieldRequestPayload = {
                campaignId: id,
                startDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(start, 'yyyy-MM-dd'),
                endDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(end, 'yyyy-MM-dd'),
                field: EsPCAReportField.Hour,
                environment: environmentMode,
                fieldFilters: advancedFilters,
                source,
            };
            if (hourlyData[hrKey] == null && !equal(hoursFetchFilters.current, config)) {
                hoursFetchFilters.current = { ...config };
                const [err, hrs] = await to(StartGetHours(config));

                if (!err && hrs?.data?.data != null) {
                    setHourlyData(val => ({
                        ...val,
                        [hrKey]: groupedByFilterToLeaderboardData({
                            metric: PCAReportMetric.Impacts,
                            data: hrs?.data,
                        }).reportData,
                    }));
                }
            }
        },
        [
            StartGetHours,
            advancedFilters,
            environmentMode,
            genKey,
            hourlyData,
            id,
            timeframe,
            source,
        ],
    );

    const mapFetchFilters = useRef<ElasticSearchFrameIdRequestPayload>(null);
    const fetchFilteredMapData = useCallback(
        async (start: Date, end: Date) => {
            const mapKey = genKey(start, end);
            setCurrentFilteredMapDataKey(mapKey);
            const config: ElasticSearchFrameIdRequestPayload = {
                campaignId: id,
                startDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(start, 'yyyy-MM-dd'),
                endDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(end, 'yyyy-MM-dd'),
                environment: environmentMode,
                fieldFilters: advancedFilters,
                source,
            };
            if (filteredMapData[mapKey] == null && !equal(mapFetchFilters.current, config)) {
                mapFetchFilters.current = { ...config };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const [err, reportData] = await to(StartGetFilteredMapData(config));

                if (!err && reportData?.data?.data != null) {
                    setFilteredMapData(val => ({
                        ...val,
                        [mapKey]: mapDataToSqlMapData(PCAReportMetric.Impacts, reportData?.data)
                            .reportData,
                    }));
                }
            }
        },
        [
            genKey,
            filteredMapData,
            StartGetFilteredMapData,
            id,
            timeframe,
            environmentMode,
            advancedFilters,
            source,
        ],
    );

    const onDataUpdated: ReportConfig<ElasticSearchSummaryResponse>['onDataUpdated'] = useCallback(
        async (reportData, start, end, $timeframe) => {
            setPcaData(reportData);
            setTimeframe($timeframe);
            fetchHoursData(start, end);
            fetchFilteredMapData(start, end);
        },
        [fetchFilteredMapData, fetchHoursData],
    );

    const fetchData: ReportConfig<PCAAnalyticsReportResponse>['fetchData'] = useCallback(
        (start, end, timeframe) =>
            apiClient.ReportsElasticSearch.summary({
                campaignId: id,
                startDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(start, 'yyyy-MM-dd'),
                endDate:
                    timeframe === ReportDateTimeframe.All ? undefined : format(end, 'yyyy-MM-dd'),
                environment: environmentMode,
                fieldFilters: advancedFilters,
                showStats: true,
                source,
            }),
        [id, environmentMode, advancedFilters, source],
    );

    const dateSettings = useMemo<ReportWrapperProps<PCAAnalyticsReportResponse>['dateSettings']>(
        () => ({
            minDate: campaignStart,
            maxDate: campaignEnd,
            availableTimeframes: [
                ReportDateTimeframe.All,
                ReportDateTimeframe.Range,
                ReportDateTimeframe.Monthly,
                ReportDateTimeframe.Weekly,
            ],
            timeframe: ReportDateTimeframe.All,
        }),
        [campaignEnd, campaignStart],
    );

    const actualLabelImpacts = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.impacts.actualLabel`,
    );

    const actualLabelPlays = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.plays.actualLabel`,
    );

    const percentageLabelImpacts = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.impacts.percentageLabel`,
    );
    const percentageLabelPlays = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.plays.percentageLabel`,
    );

    const performanceLabelImpacts = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.impacts.performanceLabel`,
    );

    const expectedLabelImpacts = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.impacts.expectedLabel`,
    );
    const expectedLabelPlays = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.plays.expectedLabel`,
    );

    const missingLabelImpacts = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.impacts.missingLabel`,
    );
    const missingLabelPlays = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.plays.missingLabel`,
    );

    const dateLabelLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.dateLabel',
    );
    const inScheduleLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.inScheduleLabel',
    );
    const outScheduleLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.outScheduleLabel',
    );
    const unbookedLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.unbookedLabel',
    );

    const dailyImpactsData = useMemo(
        () =>
            data
                ? ByFieldOverall(
                      { ...data, reportData: data.dailyImpacts },
                      Object.keys(data?.dailyImpacts ?? {}),
                      actualLabelImpacts,
                      percentageLabelImpacts,
                      expectedLabelImpacts,
                      missingLabelImpacts,
                      dateLabelLabel,
                      inScheduleLabel,
                      outScheduleLabel,
                      unbookedLabel,
                      true,
                  ).sort(dateSortArray(dateLabelLabel, ChartDataKeyDateFormat))
                : null,
        [
            actualLabelImpacts,
            data,
            dateLabelLabel,
            expectedLabelImpacts,
            inScheduleLabel,
            missingLabelImpacts,
            outScheduleLabel,
            percentageLabelImpacts,
            unbookedLabel,
        ],
    );

    const dailyPlaysData = useMemo(
        () =>
            data
                ? ByFieldOverall(
                      { ...data, reportData: data.dailyPlays },
                      Object.keys(data?.dailyPlays ?? {}),
                      actualLabelPlays,
                      percentageLabelPlays,
                      expectedLabelPlays,
                      missingLabelPlays,
                      dateLabelLabel,
                      inScheduleLabel,
                      outScheduleLabel,
                      unbookedLabel,
                      true,
                  ).sort(dateSortArray(dateLabelLabel, ChartDataKeyDateFormat))
                : null,
        [
            actualLabelPlays,
            data,
            dateLabelLabel,
            expectedLabelPlays,
            inScheduleLabel,
            missingLabelPlays,
            outScheduleLabel,
            percentageLabelPlays,
            unbookedLabel,
        ],
    );

    const { dayMonthYearFormat } = useDateUtils();
    const byFieldData = useCallback(
        (
            key: keyof PCAAnalyticsReportResponse,
            field: EsPCAReportField,
            actual: string,
            percentage: string,
            performance: string,
            expected: string,
            missing: string,
        ) =>
            data
                ? sortByNameCustomField(
                      ByFieldOverall(
                          { ...data, reportData: data[key] as any },
                          Object.keys(data?.[key] ?? {}),
                          actual,
                          percentage,
                          expected,
                          missing,
                          dateLabelLabel,
                          inScheduleLabel,
                          outScheduleLabel,
                          unbookedLabel,
                      ),
                      dateLabelLabel,
                      field,
                      dayMonthYearFormat,
                  )
                : null,
        [
            data,
            dateLabelLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            dayMonthYearFormat,
        ],
    );

    const byFieldImpactsData = useCallback(
        (key: keyof PCAAnalyticsReportResponse, field: EsPCAReportField) =>
            byFieldData(
                key,
                field,
                actualLabelImpacts,
                percentageLabelImpacts,
                performanceLabelImpacts,
                expectedLabelImpacts,
                missingLabelImpacts,
            ),
        [
            actualLabelImpacts,
            byFieldData,
            expectedLabelImpacts,
            missingLabelImpacts,
            percentageLabelImpacts,
            performanceLabelImpacts,
        ],
    );

    const hourImpactsData = useMemo(() => {
        if (hourlyData[currentHourlyDataKey] != null) {
            return ByFieldOverall(
                { reportData: hourlyData[currentHourlyDataKey] },
                Object.keys(hourlyData[currentHourlyDataKey] ?? {}),
                actualLabelImpacts,
                percentageLabelImpacts,
                expectedLabelImpacts,
                missingLabelImpacts,
                dateLabelLabel,
                inScheduleLabel,
                outScheduleLabel,
                unbookedLabel,
            );
        }

        return [];
    }, [
        actualLabelImpacts,
        currentHourlyDataKey,
        dateLabelLabel,
        expectedLabelImpacts,
        hourlyData,
        inScheduleLabel,
        missingLabelImpacts,
        outScheduleLabel,
        percentageLabelImpacts,
        unbookedLabel,
    ]);

    const dayOfWeekImpactsData = useMemo(
        () => byFieldImpactsData('dayOfWeek', EsPCAReportField.Dow),
        [byFieldImpactsData],
    );
    const regionsImpactsData = useMemo(
        () => byFieldImpactsData('tvRegion', EsPCAReportField.DisplayTvRegion),
        [byFieldImpactsData],
    );
    const mediaOwnerImpactsData = useMemo(
        () => byFieldImpactsData('mediaOwner', EsPCAReportField.DisplayOwner),
        [byFieldImpactsData],
    );

    const generateParts = useCallback(
        ({ actual, expected }: { actual: string; expected?: string }) => {
            const parts = [
                {
                    dataKey: expected,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    lineProps: { stroke: theme.palette.grey['500'], strokeDasharray: '5 5' },
                    barProps: { fill: theme.palette.grey['500'] },
                },
                {
                    dataKey: actual,
                    lineProps: { stroke: theme.palette.primary.main },
                    barProps: { fill: theme.palette.primary.main },
                },
            ];

            return parts as ChartReportPart[];
        },
        [theme.palette.grey, theme.palette.primary.main],
    );

    const dailyImpactParts = useMemo<ChartReportPart[]>(
        () => generateParts({ actual: actualLabelImpacts, expected: expectedLabelImpacts }),
        [actualLabelImpacts, expectedLabelImpacts, generateParts],
    );

    const dailyPlayParts = useMemo<ChartReportPart[]>(
        () => generateParts({ actual: actualLabelPlays, expected: expectedLabelPlays }),
        [actualLabelPlays, expectedLabelPlays, generateParts],
    );

    const impactFieldParts = useMemo<ChartReportPart[]>(
        () => generateParts({ actual: actualLabelImpacts, expected: expectedLabelImpacts }),
        [actualLabelImpacts, expectedLabelImpacts, generateParts],
    );

    // const playsFieldParts = useMemo<ChartReportPart[]>(() => {
    //     return generateParts({ actual: actualLabelPlays, expected: expectedLabelPlays });
    // }, [actualLabelPlays, expectedLabelPlays, generateParts]);

    const impactsTooltipLabel = useCallback(
        (reportType: FieldReportType, field: PCAReportField) =>
            (tooltipProps: TooltipProps<any, any>) =>
                (
                    <CustomTooltip
                        tooltipProps={tooltipProps}
                        expectedLabel={expectedLabelImpacts}
                        percentageLabel={percentageLabelImpacts}
                        inScheduleLabel={inScheduleLabel}
                        outScheduleLabel={outScheduleLabel}
                        unbookedLabel={unbookedLabel}
                        timeFrame={timeframe}
                        type={reportType}
                        field={field}
                        metric={PCAReportMetric.Impacts}
                        countFields={countFields}
                    />
                ),
        [
            countFields,
            expectedLabelImpacts,
            inScheduleLabel,
            outScheduleLabel,
            percentageLabelImpacts,
            timeframe,
            unbookedLabel,
        ],
    );

    const playsTooltipLabel = useCallback(
        (reportType: FieldReportType, field: PCAReportField) =>
            (tooltipProps: TooltipProps<any, any>) =>
                (
                    <CustomTooltip
                        tooltipProps={tooltipProps}
                        expectedLabel={expectedLabelPlays}
                        percentageLabel={percentageLabelPlays}
                        inScheduleLabel={inScheduleLabel}
                        outScheduleLabel={outScheduleLabel}
                        unbookedLabel={unbookedLabel}
                        timeFrame={timeframe}
                        type={reportType}
                        field={field}
                        metric={PCAReportMetric.Plays}
                        countFields={countFields}
                    />
                ),
        [
            countFields,
            expectedLabelPlays,
            inScheduleLabel,
            outScheduleLabel,
            percentageLabelPlays,
            timeframe,
            unbookedLabel,
        ],
    );

    const reports = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Line,
                namePrefix: `${id}_running_total_plays_overtime`,
                xAxisDataKey: dateLabelLabel,
                data: dailyPlaysData,
                parts: dailyPlayParts,
                renderTooltipLabel: playsTooltipLabel(FieldReportType.OverTime, null),
            },
            {
                type: ReportType.Line,
                namePrefix: `${id}_running_total_impacts_overtime`,
                xAxisDataKey: dateLabelLabel,
                data: dailyImpactsData,
                parts: dailyImpactParts,
                renderTooltipLabel: impactsTooltipLabel(FieldReportType.OverTime, null),
            },
            {
                type: ReportType.Bar,
                namePrefix: `${id}_impacts_by_weekday_overall`,
                xAxisDataKey: dateLabelLabel,
                data: dayOfWeekImpactsData,
                parts: impactFieldParts,
                renderTooltipLabel: impactsTooltipLabel(
                    FieldReportType.Overall,
                    PCAReportField.DayOfWeek,
                ),
            },
            {
                type: ReportType.Bar,
                namePrefix: `${id}_impacts_by_region_overall`,
                xAxisDataKey: dateLabelLabel,
                data: regionsImpactsData,
                parts: impactFieldParts,
                renderTooltipLabel: impactsTooltipLabel(
                    FieldReportType.Overall,
                    PCAReportField.TvRegion,
                ),
            },
            {
                type: ReportType.Bar,
                namePrefix: `${id}_impacts_by_media_owner_overall`,
                xAxisDataKey: dateLabelLabel,
                data: mediaOwnerImpactsData,
                parts: impactFieldParts,
                renderTooltipLabel: impactsTooltipLabel(
                    FieldReportType.Overall,
                    PCAReportField.MediaOwner,
                ),
            },
            {
                type: ReportType.Bar,
                namePrefix: `${id}_impacts_by_hour_overall`,
                xAxisDataKey: dateLabelLabel,
                data: hourImpactsData,
                parts: impactFieldParts,
                renderTooltipLabel: impactsTooltipLabel(
                    FieldReportType.Overall,
                    PCAReportField.Hour,
                ),
            },
            {
                type: ReportType.Custom,
                namePrefix: `${id}_map_impacts_heatmap`,
                RenderReport: () => (
                    <Box id="map_impacts_heatmap">
                        <ImpactsMapView
                            id={id}
                            data={filteredMapData?.[currentFilteredMapDataKey] ?? {}}
                        />
                    </Box>
                ),
                parts: [],
                data: [],
            },
        ],
        [
            currentFilteredMapDataKey,
            dailyImpactParts,
            dailyImpactsData,
            dailyPlayParts,
            dailyPlaysData,
            dateLabelLabel,
            dayOfWeekImpactsData,
            filteredMapData,
            hourImpactsData,
            id,
            impactFieldParts,
            impactsTooltipLabel,
            mediaOwnerImpactsData,
            playsTooltipLabel,
            regionsImpactsData,
        ],
    );

    const advancedFilter = useMemo(
        () =>
            allValuesByField ? (
                <ReportFieldFilterES
                    allValuesByField={allValuesByField}
                    selectedFields={advancedFilters}
                    onSearch={onAdvancedFiltersUpdated}
                    onReset={onResetAdvancedFilters}
                />
            ) : null,
        [advancedFilters, allValuesByField, onAdvancedFiltersUpdated, onResetAdvancedFilters],
    );

    return (
        <Box>
            <ReportWrapper
                ref={reportWrapperRef}
                fetchData={fetchData}
                onDataUpdated={onDataUpdated}
                dateSettings={dateSettings}
                initialStartDate={campaignStart}
                initialEndDate={campaignEnd}
                reportChartWrapperProps={{ height: 500 }}
                beforeChartComponent={
                    <Box py={1}>
                        <Collapse in={showAdvancedFilters.value}>
                            <Box pb={2}>{advancedFilter}</Box>
                        </Collapse>
                        {data?.campaignStats && (
                            <Box mt={1}>
                                <PCATotalsByMetric stats={data.campaignStats} />
                            </Box>
                        )}
                    </Box>
                }
                additionalDateControls={
                    <Box ml="auto">
                        <Button
                            onClick={showAdvancedFilters?.toggle}
                            color={advancedFiltersCount > 0 ? 'primary' : 'secondary'}
                        >
                            <FilterList sx={{ mr: 0.5 }} />
                            {useCommonTranslation(
                                'Modules.Main.Campaigns.Overview.Reports.filterBy',
                            )}
                            <KeyboardArrowDown />
                        </Button>
                    </Box>
                }
                additionalDateControlsPosition="end"
                ReportRender={RenderReports}
                reportProps={reports}
            />
        </Box>
    );
};

const titles: TranslationPath[] = [
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.totalPlays',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.totalImpacts',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.impactsDayOfWeek',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.impactsRegion',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.impactsMediaOwner',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.impactsHour',
    'Modules.Main.Campaigns.Overview.Analytics.reportTitles.impactsHeatmap',
];

const RenderReports: ReportWrapperProps<any>['ReportRender'] = ({ reports }) => (
    <>
        {reports.map((rep, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card key={i} sx={{ my: 2 }}>
                <CardHeader title={<Translate path={titles[i]} />} />
                <CardContent>{rep}</CardContent>
            </Card>
        ))}
    </>
);

export default CampaignAnalyticsES;
