import { SpotlightRecentResource } from 'c-sdk';
import { SearchPayload } from 'c-data-layer';

export const RECENT_PAGES_PAGINATION_TAG = 'recent-pages-tag';
export const RECENT_PAGES_PER_PAGE = 6;

const RECENT_PAGES_TOTAL_PAGES = 5;

export const RECENT_PAGES_SEARCH_PAYLOAD: SearchPayload<SpotlightRecentResource> = {
    perPage: RECENT_PAGES_PER_PAGE * RECENT_PAGES_TOTAL_PAGES,
    filters: {},
};
