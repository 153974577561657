import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from 'c-sdk';

import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { Checkbox } from 'c-components';
import { useCommonTranslation } from 'c-translation';

type Props = RenderColumnDataProps<Campaign>;

const ActionRequiredCell: React.FC<Props> = ({ entity }) => {
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity.id).state === NetworkRequestState.InProgress,
        [entity.id, getUpdatingById],
    );

    const onChangeGatherer = useCallback(() => {
        update(entity.id, {
            id: entity.id,
            gatherer: !entity.gatherer,
        } as Campaign);
    }, [update, entity.id, entity.gatherer]);
    const onChangeScheduler = useCallback(() => {
        update(entity.id, {
            id: entity.id,
            scheduler: !entity.scheduler,
        } as Campaign);
    }, [update, entity.id, entity.scheduler]);
    const onChangeReviewer = useCallback(() => {
        update(entity.id, {
            id: entity.id,
            reviewer: !entity.reviewer,
        } as Campaign);
    }, [update, entity.id, entity.reviewer]);

    const { hasAdminPermission } = useUserPermissions();

    return (
        <Stack gap={1}>
            <Checkbox
                checked={entity.scheduler}
                onChange={onChangeScheduler}
                disabled={loading || !hasAdminPermission}
                checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                icon={loading ? <CircularProgress size={15} /> : undefined}
                formControlLabelSx={{ mr: 0 }}
                label={
                    <Typography variant="caption" noWrap>
                        {useCommonTranslation('Modules.Main.Campaigns.DataStatus.table.scheduler')}
                    </Typography>
                }
            />
            <Checkbox
                checked={entity.gatherer}
                onChange={onChangeGatherer}
                disabled={loading || !hasAdminPermission}
                checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                icon={loading ? <CircularProgress size={15} /> : undefined}
                formControlLabelSx={{ mr: 0 }}
                label={
                    <Typography variant="caption" noWrap>
                        {useCommonTranslation(
                            'Modules.Main.Campaigns.DataStatus.table.gathererCol',
                        )}
                    </Typography>
                }
            />
            <Checkbox
                checked={entity.reviewer}
                onChange={onChangeReviewer}
                disabled={loading || !hasAdminPermission}
                checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                icon={loading ? <CircularProgress size={15} /> : undefined}
                formControlLabelSx={{ mr: 0 }}
                label={
                    <Typography variant="caption" noWrap>
                        {useCommonTranslation('Modules.Main.Campaigns.DataStatus.table.reviewer')}
                    </Typography>
                }
            />
        </Stack>
    );
};

export default ActionRequiredCell;
