import React, { useCallback, useState } from 'react';
import { TextField } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';
import { Box, CircularProgress, InputAdornment, Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { CreativeGroup } from 'c-sdk';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { UpdateGroup } from 'c-main/Components/CreativeManagement/types';

type Props = {
    //
    close: () => void;
    group: CreativeGroup;
    updateGroup: UpdateGroup;
};

const GroupListItemEdit: React.FC<Props> = ({ close, group, updateGroup }) => {
    const [groupName, setGroupName] = useState(group.name);

    const {
        start: StartUpdateGroup,
        reset: ResetUpdateGroup,
        requestState: updateGroupState,
        error: updateGroupError,
    } = useAPIClientRequest(apiClient.Entities.CreativeGroup.update);

    const updateGroupSubmit = useCallback(
        async (groupId: number, name: string) => {
            const [, success] = await to(StartUpdateGroup(groupId, { name }));

            if (success && success?.data?.data?.id != null && success?.data?.data?.id === groupId) {
                updateGroup(success?.data?.data);
                ResetUpdateGroup();
                close();
            }
        },
        [ResetUpdateGroup, StartUpdateGroup, close, updateGroup],
    );

    const onTextKeyDown = useCallback(
        e => {
            if (e.key === 'Enter') {
                updateGroupSubmit(group.id, (e.target as HTMLInputElement).value);
            } else if (e.key === 'Escape') {
                (e.target as HTMLInputElement).blur();
            }
        },
        [group.id, updateGroupSubmit],
    );
    const onTextChanged = useCallback(
        e => {
            if (updateGroupState !== NetworkRequestState.InProgress) {
                setGroupName(e.target.value);
            }
        },
        [updateGroupState],
    );

    return (
        <Box ml={2} height={50} display="flex" alignItems="center">
            <TextField
                onBlur={close}
                // size="small"
                variant="standard"
                onKeyDown={onTextKeyDown}
                autoFocus
                whiteBackdrop={false}
                greyBackdrop={false}
                onChange={onTextChanged}
                value={groupName}
                error={updateGroupState === NetworkRequestState.Error}
                id={`creative-group-name-${group.id}`}
                InputProps={{
                    endAdornment: (
                        <>
                            {updateGroupState === NetworkRequestState.InProgress && (
                                <InputAdornment position="end">
                                    <CircularProgress size={15} />
                                </InputAdornment>
                            )}
                            {updateGroupState === NetworkRequestState.Error && (
                                <Tooltip title={updateGroupError ?? ''}>
                                    <InputAdornment position="end">
                                        <Warning color="error" />
                                    </InputAdornment>
                                </Tooltip>
                            )}
                        </>
                    ),
                }}
            />
        </Box>
    );
};

export default GroupListItemEdit;
