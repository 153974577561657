import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-hanger';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import { AllEntities, BaseEntity } from 'c-sdk';
import { useEntityData, useUpdateEntityData } from 'c-data';
import { PageEntityConfig } from 'c-config';
import { BadRequest, LayoutSetter } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useIsMobileView, useUserImpersonation } from 'c-hooks';
import { UseEntityPageContext } from 'c-wrapper/Components/PostAuth/Context';
import { MobileSystemSearch, systemSearchSelectors } from 'c-system-search';
import Breadcrumbs from '../Breadcrumbs';
import { SystemSearchRecentResourceThunks } from '../../../Modules/SystemSearch/Slices/SystemSearchRecentResource';

function GetEntityPage<Entity extends BaseEntity>({
    page,
    id,
}: {
    entityName: keyof AllEntities;
    page: PageEntityConfig<Entity>;
    id: number;
}) {
    const { isImpersonating } = useUserImpersonation();
    const isSystemSearchOpen = useSelector(systemSearchSelectors.isOpen);
    const isMobile = useIsMobileView();

    const dispatch = useDispatch();
    const { getById, getLoadingById, resetLoadingByIdState } = useEntityData<Entity>(
        page.systemSearch.entityName,
    );
    const { resetUpdatingById } = useUpdateEntityData<Entity>(page.systemSearch.entityName);

    const entity = getById({ id: +id, includes: page.systemSearch.defaultIncludes });
    const t = useCommonTranslation();

    /**
     * Use this to avoid mounting the page content until after the entity fetching has been kicked off (on mount)
     *
     * This avoids page content mounting multiple times.
     */
    const mounted = useRef(false);

    useEffect(() => {
        getById({ id: +id, forceRefresh: true, includes: page.systemSearch.defaultIncludes }); // refresh entity on page mount
        mounted.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const staticBreadcrumbs = useMemo(
        () => page?.systemSearch.breadcrumbs ?? [],
        [page?.systemSearch],
    );

    const generatedBreadcrumbs = useMemo(
        () =>
            entity && page?.systemSearch?.generateEntityBreadcrumbs
                ? page?.systemSearch?.generateEntityBreadcrumbs?.(staticBreadcrumbs, t, entity, id)
                : staticBreadcrumbs,
        [entity, id, page?.systemSearch, staticBreadcrumbs, t],
    );

    const breadCrumbs = useMemo(
        () =>
            generatedBreadcrumbs.map(x => ({
                ...x,
                label: typeof x.label === 'string' ? t(x.label) : x.label,
            })) ?? [],
        [generatedBreadcrumbs, t],
    );

    const pageName = useMemo(
        () => page.systemSearch.nameGen(entity, +id, t),
        [entity, id, page.systemSearch, t],
    );
    const pageStatus = useMemo(
        () => page.systemSearch?.pageStatus?.(entity, +id, t),
        [entity, id, page.systemSearch, t],
    );
    const allBreadcrumbs = useMemo(() => {
        // only tack on the page name if the generate breadcrumbs function isn't implemented
        if (pageName != null && page.systemSearch.generateEntityBreadcrumbs == null) {
            return [...breadCrumbs, { label: pageName }];
        }

        return breadCrumbs;
    }, [breadCrumbs, pageName, page.systemSearch]);

    const loading = getLoadingById(+id);

    const savedPage = useBoolean(false);

    useEffect(
        () => () => {
            resetUpdatingById(id);
            resetLoadingByIdState(id);
        },
        [id, resetLoadingByIdState, resetUpdatingById],
    );
    useEffect(() => {
        if (
            entity !== null &&
            !savedPage.value &&
            page.systemSearch.saveAsRecentPage === true &&
            !isImpersonating
        ) {
            dispatch(
                SystemSearchRecentResourceThunks.create({
                    label: pageName,
                    resourceIdentifier: page.id,
                    entityId: entity.id,
                    entityName: page.systemSearch.entityName,
                    type: 'entity',
                }),
            );
            savedPage.setTrue();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity, savedPage, t]);

    const content = useMemo(() => {
        if (isSystemSearchOpen && isMobile) {
            return <MobileSystemSearch />;
        }

        return <page.component />;
    }, [page, isSystemSearchOpen, isMobile]);

    const showLoading = useMemo(
        () =>
            entity == null || loading.state === NetworkRequestState.InProgress || !mounted.current,
        [entity, loading],
    );

    const breadcrumbComponent = useMemo(() => {
        const defaultContent = <Breadcrumbs breadcrumbs={allBreadcrumbs} />;

        if (!showLoading && page?.systemSearch?.renderEntityBreadcrumbs != null) {
            const Component = page?.systemSearch?.renderEntityBreadcrumbs;
            return (
                <Component
                    breadcrumbs={allBreadcrumbs}
                    defaultContent={defaultContent}
                    entity={entity}
                />
            );
        }

        return defaultContent;
    }, [page?.systemSearch, allBreadcrumbs, showLoading, entity]);

    const pageContent = useMemo(() => {
        switch (loading.state) {
            case NetworkRequestState.Error:
                return <BadRequest statusCode={404} error={loading.error} />;
            case NetworkRequestState.InProgress:
            case NetworkRequestState.Idle:
            case NetworkRequestState.Success:
                return (
                    <>
                        <LayoutSetter
                            // if no title has been set, use the entity page's generated name
                            metaTitle={page.systemSearch.title ?? (pageName as any)}
                            pageId={page.id}
                            disableDesktopContentScrolling={page.disableDesktopContentScrolling}
                            pageStatus={pageStatus}
                        />
                        {showLoading ? <CircularProgress /> : content}
                    </>
                );
            default:
                return <CircularProgress />;
        }
    }, [
        content,
        loading.error,
        loading.state,
        page.disableDesktopContentScrolling,
        page.id,
        page.systemSearch.title,
        pageName,
        pageStatus,
        showLoading,
    ]);

    if (loading.status >= 400) {
        return <BadRequest statusCode={loading.status} />;
    }

    return (
        <>
            {breadcrumbComponent}
            {pageContent}
        </>
    );
}

const GetEntityPageWithId = <Entity extends BaseEntity>({
    page,
}: {
    page: PageEntityConfig<Entity>;
}) => {
    const { id } = useParams<{ id: string }>();

    return useMemo(
        () => (
            <IfHasAllPermissions
                permissions={page.systemSearch?.permissions}
                otherwise={<BadRequest statusCode={403} />}
            >
                <UseEntityPageContext.Provider
                    value={{ entityName: page.systemSearch.entityName, pageConfig: page }}
                >
                    <GetEntityPage<Entity>
                        key={`${page.systemSearch.entityName}-${id}`}
                        entityName={page.systemSearch.entityName}
                        page={page}
                        id={+id}
                    />
                </UseEntityPageContext.Provider>
            </IfHasAllPermissions>
        ),
        [id, page],
    );
};

export default React.memo(GetEntityPageWithId);
