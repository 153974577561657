import React, { useMemo } from 'react';
import { DisplayDates } from 'c-pop-displays/Components/Display';
import { PopDisplays_VirtualCampaignSummary, VirtualCampaignBuilderSummaryPayload } from 'c-sdk';
import { Box } from '@mui/material';
import { useDateUtils } from 'c-hooks';

type Props = {
    summaryPayload: VirtualCampaignBuilderSummaryPayload;
    summary: PopDisplays_VirtualCampaignSummary;
};

const VirtualCampaignBuilderPlaybackSummary: React.FC<Props> = ({ summaryPayload, summary }) => {
    const { parseApiDayMonthYearHourMinuteSecondFormat } = useDateUtils();
    const minDate = useMemo(
        () => parseApiDayMonthYearHourMinuteSecondFormat(summary.min_date),
        [summary, parseApiDayMonthYearHourMinuteSecondFormat],
    );
    const maxDate = useMemo(
        () => parseApiDayMonthYearHourMinuteSecondFormat(summary.max_date),
        [summary, parseApiDayMonthYearHourMinuteSecondFormat],
    );

    return (
        <>
            <Box maxWidth="100%" width="100%" overflow="hidden" display="flex">
                <DisplayDates
                    id={summary.pop_display_id}
                    minDate={minDate}
                    maxDate={maxDate}
                    mediaNames={summaryPayload.media_items}
                    defaultGroupByMedia={false}
                />
            </Box>
        </>
    );
};

export default VirtualCampaignBuilderPlaybackSummary;
