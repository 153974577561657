import React from 'react';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, Displays_Owner as Owner, Client, Agency, Buyer } from 'c-sdk';
import { AutoGrid, EntityField } from 'c-components';
import { User } from 'c-entity-types';
import { Box, Typography } from '@mui/material';

type Props = {
    user: User;
};

const UserLegacyAssociations: React.FC<Props> = ({ user }) => {
    const t = useCommonTranslation();
    return (
        <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3}>
            {user.buyer_id != null && (
                <IfHasAllPermissions permissions={[PermissionName.UniledportalBuyerRead]}>
                    <Block
                        title={t('Modules.Admin.UserEdit.legacyBuyerHeader')}
                        value={
                            <EntityField<Buyer>
                                entityName="Buyer"
                                field="name"
                                id={user.buyer_id}
                            />
                        }
                    />
                </IfHasAllPermissions>
            )}
            {user.agency_id != null && (
                <IfHasAllPermissions permissions={[PermissionName.UniledportalAgencyRead]}>
                    <Block
                        title={t('Modules.Admin.UserEdit.legacyAgencyHeader')}
                        value={
                            <EntityField<Agency>
                                entityName="Agency"
                                field="name"
                                id={user.agency_id}
                            />
                        }
                    />
                </IfHasAllPermissions>
            )}
            {user.client_id != null && (
                <IfHasAllPermissions permissions={[PermissionName.UniledportalClientRead]}>
                    <Block
                        title={t('Modules.Admin.UserEdit.legacyBrandHeader')}
                        value={
                            <EntityField<Client>
                                entityName="Client"
                                field="name"
                                id={user.client_id}
                            />
                        }
                    />
                </IfHasAllPermissions>
            )}
            {user.owner_id != null && (
                <IfHasAllPermissions permissions={[PermissionName.UniledportalOwnerRead]}>
                    <Block
                        title={t('Modules.Admin.UserEdit.legacyOwnerHeader')}
                        value={
                            <EntityField<Owner>
                                entityName="Displays_Owner"
                                field="name"
                                id={user.owner_id}
                            />
                        }
                    />
                </IfHasAllPermissions>
            )}
        </AutoGrid>
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <Box>
        <Typography color="textSecondary">{title}</Typography>
        <Typography sx={{ maxWidth: '20em', wordBreak: 'break-word' }}>{value}</Typography>
    </Box>
);
export default UserLegacyAssociations;
