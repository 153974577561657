import React from 'react';
import { CreativeGroup, Creative } from 'c-sdk';
import useCampaignCreatives from 'c-main/Components/CreativeManagement/useCampaignCreatives';

export enum CreativesOrderDirection {
    ASC,
    DESC,
}

export type CreativeDraggableItem = {
    creativeIds: number[];
};

export enum CreativeDragItemType {
    Creative = 'Single',
}

export type ReValidateCampaign = () => void;
export type OpenCreativeGroup = (groupId?: number) => void;

export type UpdateGroup = (group: CreativeGroup, openGroup?: boolean) => void;
export type AddCreativeGroup = (group: CreativeGroup, openGroup?: boolean) => void;

export type ResetAddGroupRequest = () => void;

export type SetSelectedCreatives = (creativeIds: number[]) => void;

export type OnCreativeChecked = (id: number, e: React.MouseEvent) => void;

export type UpdateCreatives = (creatives: Creative[]) => void;
export type DeleteCreativesAndGroups = (data: {
    creatives?: number[];
    groups?: number[];
    deleteCreativesFromGroup?: boolean;
}) => void;

export type ResetCreativesData = ReturnType<typeof useCampaignCreatives>['resetCreativesData'];
export type AllCreativeFilters = ReturnType<typeof useCampaignCreatives>['allFilters'];
export type SetSearchTerm = ReturnType<typeof useCampaignCreatives>['setSearchTerm'];
export type SetResolutions = ReturnType<typeof useCampaignCreatives>['setResolutionFilters'];
export type SetAspectRatios = ReturnType<typeof useCampaignCreatives>['setAspectFilters'];
export type SetFileTypes = ReturnType<typeof useCampaignCreatives>['setFileTypeFilters'];
export type SetErrors = ReturnType<typeof useCampaignCreatives>['setErrorFilters'];
export type SetDuration = ReturnType<typeof useCampaignCreatives>['setDurationFilter'];
export type SetDate = ReturnType<typeof useCampaignCreatives>['setLiveDateFilter'];
