import React from 'react';
import { Campaign } from 'c-sdk';
import { CampaignLineItemCost } from 'c-main/Components/Campaign/CampaignLineItems';
import useSelectedLineItems from '../../CampaignLineItems/CampaignLineItemPicker/useSelectedLineItems';

const paginationTag = 'create-campaign-line-items-costing';

const CostingStep: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
    const { value: SelectedLineItemIds } = useSelectedLineItems();
    return (
        <CampaignLineItemCost
            // showDiff
            originalPrice={campaign?.budgetSummary?.total}
            selectedLineItems={SelectedLineItemIds}
            paginationTag={paginationTag}
        />
    );
};

export default CostingStep;
