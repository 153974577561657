import React, { useCallback, useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { NetworkRequestState } from 'c-data-layer';
import { Box, Grid, GridProps } from '@mui/material';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { HideImage } from '@mui/icons-material';
import {
    BrandLogo,
    CollapsibleCard,
    ControlledFormInput,
    FileUploadButton,
    IconTopBarBrandLogo,
    TextField,
} from 'c-components';
import { useDispatch, useSelector } from 'react-redux';
import { skinSelectors, skinThunks } from 'c-admin/Slices';
import { Skin } from 'c-entity-types';

type Props = {
    title: TranslationPath;
    skinId: number;
    skin?: Skin;
    submitButton: React.ReactNode;
};

const UploadGridProps: GridProps['sx'] = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    justifyContent: 'space-between',
    alignItems: 'center',
};

const SkinOverviewSection: React.FC<Props> = ({ title, skinId, skin, submitButton }) => {
    const dispatch = useDispatch();
    const open = useBoolean(true);

    const [largeLogo, setLargeLogo] = useState<File>(null);
    const [smallLogo, setSmallLogo] = useState<File>(null);

    const smallLoadingState = useSelector(skinSelectors.getUploadingSmallLogoById)(skinId);
    const largeLoadingState = useSelector(skinSelectors.getUploadingLargeLogoById)(skinId);

    const uploadLogo = useCallback(
        (file: File) => {
            dispatch(skinThunks.uploadLargeLogo(skinId, file));
        },
        [dispatch, skinId],
    );

    const uploadSmallLogo = useCallback(
        (file: File) => {
            dispatch(skinThunks.uploadSmallLogo(skinId, file));
        },
        [dispatch, skinId],
    );

    useEffect(() => {
        if (largeLogo != null) {
            uploadLogo(largeLogo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [largeLogo]);

    useEffect(() => {
        if (smallLogo != null) {
            uploadSmallLogo(smallLogo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smallLogo]);

    return (
        <CollapsibleCard defaultOpen title={title}>
            <Grid container spacing={2} mb={2} p={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <ControlledFormInput
                        render={({ field }) => (
                            <TextField
                                label={
                                    <Translate path="Modules.Admin.SkinsList.create.nameLabel" />
                                }
                                {...field}
                                autoFocus
                            />
                        )}
                        name="name"
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        alignSelf: 'center',
                    }}
                >
                    {submitButton}
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ height: '100%' }} p={2}>
                <Grid item sx={UploadGridProps}>
                    <Box m="auto" pb={1}>
                        {skin?.logo_path != null ? (
                            <BrandLogo />
                        ) : (
                            <Box sx={{ fontSize: 100 }}>
                                <HideImage fontSize="inherit" />
                            </Box>
                        )}
                    </Box>
                    <FileUploadButton
                        id="large-logo-upload"
                        onFileSelected={files => setLargeLogo(files[0])}
                        disabled={largeLoadingState.state === NetworkRequestState.InProgress}
                    >
                        {useCommonTranslation('Modules.Admin.SkinEdit.form.largeLogoUploadLabel')}
                    </FileUploadButton>
                </Grid>
                <Grid item sx={UploadGridProps}>
                    <Box m="auto" pb={1}>
                        {skin?.logo_small_path != null ? (
                            <IconTopBarBrandLogo />
                        ) : (
                            <Box sx={{ fontSize: 100 }}>
                                <HideImage fontSize="inherit" />
                            </Box>
                        )}
                    </Box>
                    <FileUploadButton
                        id="small-logo-upload"
                        onFileSelected={files => setSmallLogo(files[0])}
                        disabled={smallLoadingState.state === NetworkRequestState.InProgress}
                    >
                        {useCommonTranslation('Modules.Admin.SkinEdit.form.smallLogoUploadLabel')}
                    </FileUploadButton>
                </Grid>
            </Grid>
        </CollapsibleCard>
    );
};

export default SkinOverviewSection;
