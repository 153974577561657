import React, { useEffect } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
// eslint-disable-next-line import/no-cycle
import { NewCampaignForm } from 'c-main/Components';
import { PermissionName } from 'c-sdk';
import ReactGA from 'react-ga';

const NewCampaignPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return <NewCampaignForm />;
};

const setup: PageConfig = {
    id: 'NewCampaignPagePage',
    component: NewCampaignPage,
    systemSearch: {
        title: 'Modules.Main.Campaigns.Create.metaTitle',
        description: 'Modules.Main.Campaigns.Create.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.newCampaignPage',
        route: PostAuthRoutes.Campaigns.NewCampaignPage,
        saveAsRecentPage: false,
        breadcrumbs: [
            {
                label: 'Modules.Main.Campaigns.List.metaTitle',
                link: PostAuthRoutes.Campaigns.CampaignList,
            },
            {
                label: 'Modules.Main.Campaigns.Create.metaTitle',
            },
        ],
        permissions: [
            PermissionName.UniledportalCampaignCreate,
            PermissionName.UniledportalCampaignRead,
            PermissionName.UniledportalBuyerRead,
            PermissionName.UniledportalOwnerRead,
            PermissionName.UniledportalBuyer_costRead,
            PermissionName.DisplaysLine_itemsRead,
        ],
    },
};

export default setup;
