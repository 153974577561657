import { SpotlightRecentResource } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { SystemSearchRecentResourceState } from './SystemSearchRecentResource-types';

const initialState: SystemSearchRecentResourceState = { ...baseInitialState };

const slice = createBaseSlice<SpotlightRecentResource, SystemSearchRecentResourceState>({
    name: 'systemSearchRecentResource',
    entityName: 'SpotlightRecentResource',
    initialState,
    reducers: {},
});

const SystemSearchRecentResourceSlice = {
    SystemSearchRecentResourceActions: slice.slice.actions,
    SystemSearchRecentResourceReducer: slice.slice.reducer,
    SystemSearchRecentResourceAdapter: slice.adapter,
    SystemSearchRecentResourceSelectors: slice.selectors,
    SystemSearchRecentResourceThunks: slice.thunks,
};

export default SystemSearchRecentResourceSlice;
export const {
    SystemSearchRecentResourceReducer,
    SystemSearchRecentResourceActions,
    SystemSearchRecentResourceAdapter,
    SystemSearchRecentResourceSelectors,
    SystemSearchRecentResourceThunks,
} = SystemSearchRecentResourceSlice;
