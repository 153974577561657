import { useCommonTranslation } from 'c-translation';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { NetworkRequestState } from 'c-data-layer';
import {
    Box,
    FormLabel,
    LinearProgress,
    LinearProgressProps,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import to from 'await-to-js';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    FileUploadDropzone,
    ListItemText,
} from 'c-components';
import { formatBytes } from 'c-lib';
import { CheckCircle, Error } from '@mui/icons-material';

const ManualAudienceForm = ({ dialogState }) => {
    const t = useCommonTranslation();
    const [files, setFiles] = useState<File[]>();
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const [manualAudienceType, setManualAudienceType] = useState<number[]>([]);
    const { start, requestState, error, data, reset } = useAPIClientRequest(
        ApiClient.Entities.Displays_AudienceUpload.uploadManualAudience,
    );
    useEffect(() => {
        if (requestState === NetworkRequestState.Success) {
            dialogState.setFalse();
        }
    }, [dialogState, requestState]);
    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);
    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);
    const [response, setResponse] = useState<any>();
    useEffect(() => {
        setResponse(data?.data);
    }, [data?.data]);
    const onFilesAdded = useCallback(
        async (newFiles: File[]) => {
            reset();
            setProgress(null);
            setFiles(newFiles);
            const formData = new FormData();
            manualAudienceType.map((audienceVersion, index) =>
                formData.append(`audience_versions[${index}]`, String(audienceVersion)),
            );
            formData.append('file', newFiles[0]);
            await to(start(formData, setProgress));
        },
        [manualAudienceType, start, reset, setProgress],
    );
    const typeHandler = useCallback(
        async (maType: number[]) => {
            setManualAudienceType(maType);
        },
        [setManualAudienceType],
    );

    return (
        <Box>
            <AutoGrid gap={2} xs={100} pb={2}>
                <Box>
                    <FormLabel sx={{ color: 'red' }}>
                        {t('Modules.Displays.manualAudience.version')} *
                    </FormLabel>
                    <AllEntitiesDropdown
                        entityName="Displays_AudienceVersion"
                        labelField="version_name"
                        multi
                        useLocalEntityData
                        onChange={typeHandler}
                        value={manualAudienceType}
                    />
                </Box>
                {manualAudienceType.length > 0 && (
                    <Box>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.manualAudience.file')} *
                        </FormLabel>
                        <FileUploadDropzone
                            fileAccept="text/csv"
                            onFilesAdded={onFilesAdded}
                            dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                        />
                    </Box>
                )}
            </AutoGrid>
            <Box pb={2}>
                {files && (
                    <ListItemText
                        primary={files[0].name}
                        primaryTypographyProps={textProps}
                        secondaryTypographyProps={textProps}
                        secondary={
                            <Stack gap={1} width="100%">
                                <AutoGrid spacing={1}>
                                    {formatBytes(files[0]?.size)} | {files[0]?.type}
                                </AutoGrid>
                                <Box width="100%">
                                    <Stack direction="row" gap={1} alignItems="center">
                                        <Box flex={1}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={progressPercentage}
                                                color={progressColor}
                                            />
                                        </Box>
                                        {requestState === NetworkRequestState.InProgress && (
                                            <Typography variant="caption">
                                                {progressPercentage.toFixed(0)} %
                                            </Typography>
                                        )}
                                        {requestState === NetworkRequestState.Error && (
                                            <Tooltip title={String(error)}>
                                                <Typography color="error.main">
                                                    <Error />
                                                </Typography>
                                            </Tooltip>
                                        )}
                                        {requestState === NetworkRequestState.Success && (
                                            <Typography color="success.main">
                                                <CheckCircle />
                                            </Typography>
                                        )}
                                    </Stack>
                                </Box>
                            </Stack>
                        }
                    />
                )}
            </Box>
            {error && (
                <Box pb={2}>
                    <Alert severity="error">{String(error)}</Alert>
                </Box>
            )}
            {requestState === NetworkRequestState.Success && response && (
                <Box pb={2}>
                    <Alert severity="success">{response.message}</Alert>
                </Box>
            )}
        </Box>
    );
};
const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};
export default ManualAudienceForm;
