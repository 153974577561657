import { NetworkRequest, NetworkRequestState } from 'c-data-layer';
import { createSelector } from '@reduxjs/toolkit';
import { CommonState } from 'c-types/RootState';

export const authenticatedUser = (state: CommonState) => state.auth.authenticatedUser;

export const availableUserTypes = (state: CommonState) => state.auth.availableLoginTypes;

export const isLoggedIn = (state: CommonState) => state.auth.authenticatedUser != null;
export const sessionTimedOut = (state: CommonState) => state.auth.sessionTimedOut;
export const validatingUserSession = (state: CommonState) =>
    state.auth.validatingUserSession.state === NetworkRequestState.InProgress;
export const validatingUserSessionSuccess = (state: CommonState) =>
    state.auth.validatingUserSession.state === NetworkRequestState.Success;

export const loginState = (state: CommonState) => state.auth.loginState;
export const logoutState = (state: CommonState) => state.auth.logoutState;
export const presenceChannelPrefix = createSelector(
    (state: CommonState) => state.auth.authenticatedUser,
    user => `Presence.Company.${user?.id}.`,
    // user => `Presence.Company.${user?.company_id}.`,
);
// export const privateChannelName = createSelector(
//     (state: CommonState) => state.auth.authenticatedUser,
//     (state: CommonState, entityName: string) => entityName,
//     (user, entityName) => `Company.${user?.company_id}.${entityName}`,
// );

const idleState = { state: NetworkRequestState.Idle } as NetworkRequest;
export const getStartImpersonatingState = (state: CommonState) =>
    state.auth.startImpersonatingUser ?? idleState;

export const getStopImpersonatingState = (state: CommonState) =>
    state.auth.stopImpersonatingUser ?? idleState;
