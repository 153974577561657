import React, { useEffect } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { campaignIcon } from 'c-main/icons';
import { CampaignListSearch } from 'c-main/Components';
import { PermissionName } from 'c-sdk';
import ReactGA from 'react-ga';

const paginationTag = 'campaign-list-home-search';
const CampaignListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return <CampaignListSearch paginationTag={paginationTag} />;
};

const setup: PageConfig = {
    id: 'CampaignListPage',
    component: CampaignListPage,
    systemSearch: {
        icon: campaignIcon,
        saveAsRecentPage: true,
        title: 'Modules.Main.Campaigns.List.metaTitle',
        description: 'Modules.Main.Campaigns.List.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.campaignListPage',
        route: PostAuthRoutes.Campaigns.CampaignList,
        breadcrumbs: [],
        permissions: [PermissionName.UniledportalCampaignRead],
    },
};

export default setup;
