import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, Tooltip, Typography } from '@mui/material';
import { CampaignCreativesResponse, CampaignErrorableType, PermissionName } from 'c-sdk';
import {
    AllCreativeFilters,
    DeleteCreativesAndGroups,
    ResetCreativesData,
    ReValidateCampaign,
    SetSelectedCreatives,
    UpdateCreatives,
} from 'c-main/Components/CreativeManagement/types';
import {
    Avatar,
    CustomDragLayer,
    DraggableMouseAdjustment,
    FileBrowserOrderBy,
    FileBrowserOrderByDirection,
} from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { Description } from '@mui/icons-material';
import { sortFilesBy } from 'c-components/FileBrowser/lib';
import { creativesToFileBrowserFiles } from 'c-main/Lib';
import { updateListSelection } from 'c-lib';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import {
    isStaticGroup,
    StaticCreativeGroup,
    UpdateCreativeGroupsPerms,
} from 'c-main/Components/CreativeManagement/lib';
import { useCampaignErrors } from 'c-main/Hooks';
import { useUserPermissions } from 'c-auth-module/Hooks';
import DeleteCreativesDialog from 'c-main/Components/CreativeManagement/Components/Creatives/DeleteCreativesDialog';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useCampaignCreativesData from 'c-main/Components/CreativeManagement/useCampaignCreativesData';
import CreativesTable from './CreativesTable';
import CreativeListItem from './CreativeListItem';
import CreativesSelectAll from './CreativesSelectAll';
import CreativesWrapperControls from './CreativesWrapperControls';
import CreativeListDropZone from './CreativeListDropZone';
import AssignCreativeDialog from './Assign/AssignCreativeDialog';
import UnAssignCreativeDialog from './Assign/UnAssignCreativeDialog';
import RenameCreativeDialog from './RenameCreativeDialog';
import CreativeFilePreviewDialog from './CreativeFilePreviewDialog';

type Props = {
    campaignId: number;
    data: CampaignCreativesResponse;
    selectedCreatives: number[];
    setSelectedCreatives: SetSelectedCreatives;
    openGroup?: number;
    filteredCreatives: number[];
    filters: AllCreativeFilters;
    updateCreatives: UpdateCreatives;
    reValidateCampaign: ReValidateCampaign;
    revalidateLoading: boolean;
    deleteData: DeleteCreativesAndGroups;
    openGroupName: string;
    resetData: ResetCreativesData;
};

const CreateCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeCreate];

const CreativesWrapper: React.FC<Props> = ({
    campaignId,
    data,
    openGroup,
    selectedCreatives,
    setSelectedCreatives,
    filteredCreatives,
    filters,
    updateCreatives,
    reValidateCampaign,
    revalidateLoading,
    deleteData,
    openGroupName,
    resetData,
}) => {
    const [orderByDirection, setOrderByDirection] = useState<FileBrowserOrderByDirection>('desc');
    const [orderByField, setOrderByField] = useState<FileBrowserOrderBy>(
        FileBrowserOrderBy.UploadDate,
    );

    const { getErrors } = useCampaignErrors(campaignId);
    const allCreativeErrors = useMemo(
        () =>
            getErrors({
                entityIds: data?.creatives?.map(c => c.id) ?? [],
                entityTypes: [CampaignErrorableType.Creative],
            }),
        [getErrors, data],
    );

    const visibleFiles = useMemo(() => {
        const creatives = data.creatives.filter(c => {
            // been filtered out
            if (filteredCreatives.indexOf(c.id) === -1) return false;

            if (isStaticGroup(openGroup)) {
                if (openGroup === StaticCreativeGroup.AllCreatives) return true;

                // check if creative is not in any groups
                if (openGroup === StaticCreativeGroup.Ungrouped)
                    return c?.creativeGroupIds?.length === 0;

                // check if creative has an error against it
                if (openGroup === StaticCreativeGroup.Invalid)
                    return allCreativeErrors.some(err => err.errorable_id === String(c.id));

                // check if creative is no assigned to any displays/line items
                if (openGroup === StaticCreativeGroup.UnAssigned) return c?.lineItemsCount <= 0;
            }

            return c.creativeGroupIds.indexOf(openGroup) !== -1;
        });
        return sortFilesBy(creativesToFileBrowserFiles(creatives), orderByField, orderByDirection);
    }, [
        data.creatives,
        orderByField,
        orderByDirection,
        filteredCreatives,
        openGroup,
        allCreativeErrors,
    ]);

    const visibleCreativeIds = useMemo(() => visibleFiles.map(c => c.downloadId), [visibleFiles]);
    const groupCount = useMemo(() => data?.creativeGroups?.length ?? 0, [data?.creativeGroups]);

    const calcSelectedFiles = useCallback(
        (creativeId: number, e: React.MouseEvent) =>
            updateListSelection(selectedCreatives, visibleCreativeIds, creativeId, e?.shiftKey),
        [selectedCreatives, visibleCreativeIds],
    );

    const toggleSelectFile = useCallback(
        (creativeId: number, e: React.MouseEvent) => {
            setSelectedCreatives(calcSelectedFiles(creativeId, e));
        },
        [calcSelectedFiles, setSelectedCreatives],
    );

    const onOrderBy = useCallback(
        (field: FileBrowserOrderBy, direction: FileBrowserOrderByDirection) => {
            setOrderByField(field);
            setOrderByDirection(direction);
        },
        [],
    );

    const [assignCreativeDialog, setAssignCreativeDialog] = useState<number>(null);
    const unsetAssignCreative = useCallback(() => {
        setAssignCreativeDialog(null);
    }, []);

    const assignDialogFile = useMemo(
        () => visibleFiles.find(f => f.downloadId === assignCreativeDialog),
        [assignCreativeDialog, visibleFiles],
    );
    const assignDialogOpen = useMemo(
        () => assignCreativeDialog != null && assignDialogFile != null,
        [assignCreativeDialog, assignDialogFile],
    );

    const [unAssignCreativeDialog, setUnAssignCreativeDialog] = useState<number>(null);
    const unsetUnAssignCreative = useCallback(() => {
        setUnAssignCreativeDialog(null);
    }, []);

    const unAssignDialogFile = useMemo(
        () => visibleFiles.find(f => f.downloadId === unAssignCreativeDialog),
        [unAssignCreativeDialog, visibleFiles],
    );
    const unAssignDialogOpen = useMemo(
        () => unAssignCreativeDialog != null && unAssignDialogFile != null,
        [unAssignCreativeDialog, unAssignDialogFile],
    );

    const { hasAll } = useUserPermissions();
    const canDragAndDropCreatives = useMemo(() => hasAll(UpdateCreativeGroupsPerms), [hasAll]);
    const [isDragging, setIsDragging] = useState(false);

    // creative id to rename
    const [renameCreativeId, setRenameCreativeId] = useState<number>(null);
    const onCloseCreativeRename = useCallback(() => {
        setRenameCreativeId(null);
    }, []);

    const [previewCreativeId, setPreviewCreativeId] = useState<number>(null);
    const previewFile = useMemo(
        () =>
            previewCreativeId ? visibleFiles.find(f => f.downloadId === previewCreativeId) : null,
        [visibleFiles, previewCreativeId],
    );
    const onCloseCreativePreview = useCallback(() => {
        setPreviewCreativeId(null);
    }, []);

    const [deleteCreativeId, setDeleteCreativeId] = useState<number>(null);
    const onCloseDeleteCreative = useCallback(() => {
        setDeleteCreativeId(null);
    }, []);

    const {
        start: startDelete,
        requestState: deleteRequestState,
        error: deleteError,
        reset: resetDelete,
    } = useAPIClientRequest(apiClient.Entities.Creative.delete);

    useEffect(() => {
        if (deleteCreativeId == null) resetDelete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCreativeId]);

    const deleteSelectedCreatives = useCallback(async () => {
        if (deleteCreativeId == null) return;

        const [err, success] = await to(startDelete([deleteCreativeId]));

        if (!err && success != null) {
            deleteData({ creatives: [deleteCreativeId] });
            reValidateCampaign();
        }
    }, [startDelete, deleteCreativeId, deleteData, reValidateCampaign]);

    const memoListItems = useMemo(
        () =>
            visibleFiles.map(f => {
                const isSelected = selectedCreatives.indexOf(f.downloadId) !== -1;
                return (
                    <React.Fragment key={f.id}>
                        <CreativeListItem
                            campaignId={campaignId}
                            file={f}
                            isSelected={isSelected}
                            isBeingMultiDragged={isSelected && isDragging}
                            onChecked={toggleSelectFile}
                            filters={filters}
                            allSelectedIds={selectedCreatives}
                            revalidateLoading={revalidateLoading}
                            onOpenPreview={setPreviewCreativeId}
                            onAssignCreativeClicked={setAssignCreativeDialog}
                            onUnAssignCreativeClicked={setUnAssignCreativeDialog}
                            onRenameClicked={setRenameCreativeId}
                            onDeleteClicked={setDeleteCreativeId}
                            canDragAndDropCreatives={canDragAndDropCreatives}
                        />
                    </React.Fragment>
                );
            }),
        [
            visibleFiles,
            selectedCreatives,
            campaignId,
            isDragging,
            toggleSelectFile,
            filters,
            revalidateLoading,
            canDragAndDropCreatives,
        ],
    );

    const dragLayerTextData = useMemo(
        // defaulting to one when you just drag a single creative without ticking the boxes of multiples
        () => ({ count: selectedCreatives.length > 1 ? selectedCreatives.length : 1 }),
        [selectedCreatives],
    );

    const openGroupTitle = useMemo(
        () => `${openGroupName} (${visibleCreativeIds.length})`,
        [openGroupName, visibleCreativeIds.length],
    );

    const t = useCommonTranslation();

    const { existingFilesArray } = useCampaignCreativesData(campaignId);

    return (
        <>
            {useMemo(
                () => (
                    <DeleteCreativesDialog
                        open={deleteCreativeId != null}
                        onClose={onCloseDeleteCreative}
                        deleteCreativesError={deleteError}
                        deleteCreativesState={deleteRequestState}
                        deleteSelectedCreatives={deleteSelectedCreatives}
                        title="Modules.Main.CreativeManagement.creatives.controls.delete.title"
                        description={t(
                            'Modules.Main.CreativeManagement.creatives.controls.delete.description',
                            { count: 1 },
                        )}
                        buttonLabel={t(
                            'Modules.Main.CreativeManagement.creatives.controls.delete.buttonLabel',
                            { count: 1 },
                        )}
                    />
                ),
                [
                    deleteCreativeId,
                    deleteError,
                    deleteRequestState,
                    deleteSelectedCreatives,
                    onCloseDeleteCreative,
                    t,
                ],
            )}
            <CreativeFilePreviewDialog
                campaignId={campaignId}
                file={previewFile}
                onClose={onCloseCreativePreview}
                updateCreatives={updateCreatives}
            />
            <RenameCreativeDialog
                creativeRenameId={renameCreativeId}
                open={renameCreativeId != null}
                updateCreatives={updateCreatives}
                onClose={onCloseCreativeRename}
                currentCreativeName={
                    data?.creatives?.find(c => c.id === renameCreativeId)?.name ?? ''
                }
            />
            {assignDialogOpen && (
                <AssignCreativeDialog
                    open
                    onClose={unsetAssignCreative}
                    campaignId={campaignId}
                    file={assignDialogFile}
                    resetData={resetData}
                    reValidateCampaign={reValidateCampaign}
                />
            )}
            {unAssignDialogOpen && (
                <UnAssignCreativeDialog
                    open
                    onClose={unsetUnAssignCreative}
                    campaignId={campaignId}
                    file={unAssignDialogFile}
                    resetData={resetData}
                    reValidateCampaign={reValidateCampaign}
                />
            )}
            <IfHasAllPermissions permissions={CreateCreativesPerms}>
                <CreativeListDropZone
                    updateCreatives={updateCreatives}
                    openGroup={openGroup}
                    reValidateCampaign={reValidateCampaign}
                    existingFilesArray={existingFilesArray}
                />
            </IfHasAllPermissions>
            <CustomDragLayer
                height={50}
                width={150}
                mousePosition={DraggableMouseAdjustment.CenterLeft}
                onIsDraggingChange={setIsDragging}
            >
                <Card sx={{ height: 50 }} elevation={5}>
                    <Box display="flex" alignItems="center" height="100%" px={2}>
                        <Avatar sx={{ height: 25, width: 25, mr: 1 }}>
                            <Description fontSize="inherit" />
                        </Avatar>
                        <Typography variant="body2">
                            <Translate
                                path={
                                    'Modules.Main.CreativeManagement.dragAndDrop.dragCount' as any
                                }
                                data={dragLayerTextData}
                            />
                        </Typography>
                    </Box>
                </Card>
            </CustomDragLayer>
            <Box display="flex" flex={1} width="100%" overflow="hidden" flexDirection="column">
                <Box mt={2} display="flex" justifyContent="space-between" px={1}>
                    <Tooltip title={openGroupTitle} placement="top">
                        <Typography variant="h6" noWrap>
                            {openGroupTitle}
                        </Typography>
                    </Tooltip>
                    <Box>
                        <CreativesWrapperControls
                            campaignId={campaignId}
                            setSelectedCreatives={setSelectedCreatives}
                            selectedCreatives={selectedCreatives}
                            groupCount={groupCount}
                            groups={data.creativeGroups}
                            openGroup={openGroup}
                            updateCreatives={updateCreatives}
                            reValidateCampaign={reValidateCampaign}
                            deleteData={deleteData}
                            creatives={data?.creatives ?? []}
                            existingFilesArray={existingFilesArray as string[]}
                        />
                    </Box>
                </Box>

                <CreativesTable
                    id="creatives-table"
                    hideGroupNames
                    files={visibleFiles}
                    selectedCreatives={selectedCreatives}
                    selectAllFiles={
                        <CreativesSelectAll
                            selectedCreatives={selectedCreatives}
                            setSelectedCreatives={setSelectedCreatives}
                            visibleCreatives={visibleCreativeIds}
                        />
                    }
                    rows={memoListItems}
                    onOrderBy={onOrderBy}
                    direction={orderByDirection}
                    orderByField={orderByField}
                />
            </Box>
        </>
    );
};

export default CreativesWrapper;
