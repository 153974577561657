import React, { useCallback, useMemo, useState } from 'react';
import {
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    ControlledFormInput,
    DateField,
    DialogV2,
    EntityAutocomplete,
    FlashMessage,
    FormWrapper,
    TextField,
} from 'c-components';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { Box, Stack, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { omit, uniq } from 'ramda';
import to from 'await-to-js';
import { isBefore, isSameDay, isValid } from 'date-fns';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, VirtualCampaignBuilderSummaryPayload } from 'c-sdk';
import { FormWrapperProps } from 'c-components/Forms/FormWrapper/FormWrapper';
import { useFormContext } from 'react-hook-form';
import VirtualCampaignSummary from './VirtualCampaignSummary';
import VirtualCampaignBuilderPlaybackSummary from './VirtualCampaignBuilderPlaybackSummary';

type Props = {
    //
};

type FormStructure = VirtualCampaignBuilderSummaryPayload & {
    media_item_names: string[]; // from the autocomplete
    media_item_text: string; // from the autocomplete
};

const marketPerms = [PermissionName.Pop_displaysMarketsRead];
const ownerPerms = [PermissionName.DisplaysMarketsRead];
const mediaPerms = [PermissionName.Pop_displaysMediaRead];

const VirtualCampaignBuilderForm: React.FC<Props & { isLoading }> = ({ isLoading }) => {
    const t = useCommonTranslation();
    const { formState, watch } = useFormContext();

    const startDate = watch('start_date');
    const endDate = watch('end_date');
    const { parseApiDayMonthYearFormat } = useDateUtils();

    const minDate = useMemo(() => {
        const parsed = parseApiDayMonthYearFormat(startDate);
        if (isValid(parsed)) return parsed;

        return undefined;
    }, [startDate, parseApiDayMonthYearFormat]);

    const dateError = useMemo(() => {
        const parsedStart = parseApiDayMonthYearFormat(startDate);
        const parsedEnd = parseApiDayMonthYearFormat(endDate);
        if (isValid(parsedStart) && isValid(parsedEnd))
            return !isBefore(parsedStart, parsedEnd) && !isSameDay(parsedStart, parsedEnd);

        return false;
    }, [startDate, endDate, parseApiDayMonthYearFormat]);

    return (
        <Box px={2}>
            <AutoGrid spacing={2} xs={12}>
                <AutoGrid gap={2}>
                    <ControlledFormInput
                        name="start_date"
                        render={({ field }) => (
                            <DateField
                                {...field}
                                openOnFocus
                                inputProps={{
                                    variant: 'standard',
                                    fullWidth: false,
                                    label: t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.form.startDateLabel',
                                    ),
                                }}
                            />
                        )}
                    />
                    <ControlledFormInput
                        name="end_date"
                        render={({ field }) => (
                            <DateField
                                {...field}
                                openOnFocus
                                minDate={minDate}
                                inputProps={{
                                    variant: 'standard',
                                    fullWidth: false,
                                    label: t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.form.endDateLabel',
                                    ),
                                }}
                            />
                        )}
                    />
                </AutoGrid>

                <IfHasAllPermissions permissions={marketPerms}>
                    <ControlledFormInput
                        name="market_id"
                        render={({ field }) => (
                            <AllEntitiesDropdown
                                {...field}
                                label={t(
                                    'Modules.PopDisplays.VirtualCampaignBuilder.form.marketLabel',
                                )}
                                useLocalEntityData
                                multi={false}
                                entityName="PopDisplays_Market"
                                labelField="name"
                            />
                        )}
                    />
                </IfHasAllPermissions>

                <IfHasAllPermissions permissions={ownerPerms}>
                    <ControlledFormInput
                        name="owner_ids"
                        render={({ field }) => (
                            <EntityAutocomplete
                                {...field}
                                multiple
                                fullWidth
                                textFieldProps={{
                                    label: t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.form.ownerLabel',
                                    ),
                                }}
                                entityName="Displays_Owner"
                                searchColumns="name"
                                labelColumn="name"
                            />
                        )}
                    />
                </IfHasAllPermissions>

                <IfHasAllPermissions permissions={mediaPerms}>
                    <ControlledFormInput
                        name="media_item_names"
                        render={({ field }) => (
                            <EntityAutocomplete
                                {...field}
                                multiple
                                fullWidth
                                entityName="PopDisplays_Media"
                                textFieldProps={{
                                    label: t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.form.mediaItemsTextarea',
                                    ),
                                }}
                                searchColumns="name"
                                labelColumn="name"
                                valueColumn="name"
                            />
                        )}
                    />
                </IfHasAllPermissions>

                <ControlledFormInput
                    name="media_item_text"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            label={t(
                                'Modules.PopDisplays.VirtualCampaignBuilder.form.mediaItemsTextarea',
                            )}
                            multiline
                            minRows={5}
                            inputProps={{ sx: { resize: 'both' } }}
                        />
                    )}
                />
                <Button type="submit" disabled={!formState.isValid || isLoading || dateError}>
                    <Translate path="Modules.PopDisplays.VirtualCampaignBuilder.form.submitBtnLabel" />
                </Button>
            </AutoGrid>
        </Box>
    );
};

const VirtualCampaignBuilderFormWrapper: React.FC<Props> = props => {
    const [submittedPayload, setSubmittedPayload] =
        useState<VirtualCampaignBuilderSummaryPayload>(null);
    const { data, start, isLoading, hasFailed, error } = useAPIClientRequest(
        apiClient.VirtualCampaignBuilder.summary,
    );
    const summaries = useMemo(
        () => (Array.isArray(data?.data?.data) ? data?.data?.data : null),
        [data],
    );
    const onSubmit = useCallback(
        async (data: FormStructure) => {
            const payload = {
                ...omit(['media_item_names', 'media_item_text'], data),
                media_items: uniq([
                    ...(data.media_item_names ?? []),
                    ...(data.media_item_text
                        ?.split('\n')
                        ?.map(id => id.trim())
                        .filter(id => id.length > 0) ?? []),
                ]),
                owner_ids: Array.isArray(data.owner_ids) ? data.owner_ids : [],
            };
            setSubmittedPayload(payload);
            await to(start(payload));
        },
        [start],
    );
    const opts = useMemo<FormWrapperProps<FormStructure>['formOptions']>(
        () => ({
            defaultValues: {
                media_item_names: [],
                media_item_text: '',
                owner_ids: [],
                media_items: [],
                market_id: null,
                title: '',
            },
        }),
        [],
    );

    const {
        data: StandardData,
        start: StartDataExport,
        isLoading: IsDataExportLoading,
        hasFailed: HasDataExportFailed,
        hasSucceeded: HasDataExportSucceeded,
        error: DataExportError,
        reset: ResetDataExport,
    } = useAPIClientRequest(apiClient.VirtualCampaignBuilder.standardPlaysExport);
    const {
        data: StandardDisplayData,
        start: StartDisplayDataExport,
        isLoading: IsDisplayDataExportLoading,
        hasFailed: HasDisplayDataExportFailed,
        hasSucceeded: HasDisplayDataExportSucceeded,
        error: DisplayDataExportError,
        reset: ResetDisplayDataExport,
    } = useAPIClientRequest(apiClient.VirtualCampaignBuilder.displayPlaysExport);

    const generateStandardReport = useCallback(async () => {
        await to(StartDataExport(submittedPayload));
    }, [submittedPayload, StartDataExport]);
    const generateDisplayReport = useCallback(async () => {
        await to(StartDisplayDataExport(submittedPayload));
    }, [submittedPayload, StartDisplayDataExport]);

    const [displayIdSummary, setDisplayIdSummary] = useState<number>(null);
    const onCloseSummary = useCallback(() => {
        setDisplayIdSummary(null);
    }, []);

    const openSummary = useMemo(
        () =>
            displayIdSummary != null
                ? summaries?.find(sum => sum.pop_display_id === displayIdSummary)
                : null,
        [summaries, displayIdSummary],
    );
    const t = useCommonTranslation();
    return (
        <>
            <DialogV2
                onClose={onCloseSummary}
                open={openSummary != null}
                maxWidth="xl"
                title={
                    <Typography variant="h4">
                        {t(
                            'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.playbackSummaryDialogTitle',
                            { name: openSummary?.name },
                        )}
                    </Typography>
                }
            >
                {openSummary && (
                    <VirtualCampaignBuilderPlaybackSummary
                        summaryPayload={submittedPayload}
                        summary={openSummary}
                    />
                )}
            </DialogV2>
            <FormWrapper<FormStructure> onSubmit={onSubmit} formOptions={opts}>
                <Stack gap={2}>
                    <VirtualCampaignBuilderForm {...props} isLoading={isLoading} />
                    {hasFailed && <FlashMessage status="error">{String(error)}</FlashMessage>}
                    {summaries && (
                        <>
                            <Stack flexDirection="row" gap={2} justifyContent="flex-end">
                                {/* standard report export */}
                                <DialogV2 onClose={ResetDataExport} open={HasDataExportSucceeded}>
                                    <FlashMessage status="success">
                                        {StandardData?.data?.message}
                                    </FlashMessage>
                                </DialogV2>
                                <DialogV2 onClose={ResetDataExport} open={HasDataExportFailed}>
                                    <FlashMessage status="error">
                                        {String(DataExportError)}
                                    </FlashMessage>
                                </DialogV2>
                                <Button
                                    variant="outlined"
                                    onClick={generateStandardReport}
                                    disabled={IsDataExportLoading}
                                >
                                    {t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.summary.standardReport',
                                    )}
                                </Button>

                                {/* Displays report export */}
                                <DialogV2
                                    onClose={ResetDisplayDataExport}
                                    open={HasDisplayDataExportSucceeded}
                                >
                                    <FlashMessage status="success">
                                        {StandardDisplayData?.data?.message}
                                    </FlashMessage>
                                </DialogV2>
                                <DialogV2
                                    onClose={ResetDisplayDataExport}
                                    open={HasDisplayDataExportFailed}
                                >
                                    <FlashMessage status="error">
                                        {String(DisplayDataExportError)}
                                    </FlashMessage>
                                </DialogV2>
                                <Button
                                    variant="outlined"
                                    onClick={generateDisplayReport}
                                    disabled={IsDisplayDataExportLoading}
                                >
                                    {t(
                                        'Modules.PopDisplays.VirtualCampaignBuilder.summary.displaysReport',
                                    )}
                                </Button>
                            </Stack>
                            <VirtualCampaignSummary
                                summaries={summaries}
                                onSummaryOpen={setDisplayIdSummary}
                            />
                        </>
                    )}
                </Stack>
            </FormWrapper>
        </>
    );
};

export default VirtualCampaignBuilderFormWrapper;
