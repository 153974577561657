import { AxiosRequestConfig } from 'axios';
import { ModuleConstructorProps } from '../Types';

export const campaignChatFuncs = <Entity>(props: ModuleConstructorProps, chatEndpoint: string) => ({
    getChat: (campaignId: number, source?: string, config?: AxiosRequestConfig) => {
        const sourceParam = source ? `?source=${encodeURIComponent(source)}` : '';
        return props.httpClient.get<{ data: Entity }>(
            `${chatEndpoint}/${campaignId}${sourceParam}`,
            config,
        );
    },

    sendMessage: (
        campaignId: number,
        message: string,
        source?: string,
        config?: AxiosRequestConfig,
    ) => {
        const sourceParam = source ? `?source=${encodeURIComponent(source)}` : '';
        return props.httpClient.put<{ data: Entity }>(
            `${chatEndpoint}/${campaignId}${sourceParam}`,
            { message },
            config,
        );
    },

    startNewChatSession: (campaignId: number, source?: string, config?: AxiosRequestConfig) => {
        const sourceParam = source ? `?source=${encodeURIComponent(source)}` : '';
        return props.httpClient.post<{ data: Entity }>(
            `${chatEndpoint}/${campaignId}${sourceParam}`,
            {},
            config,
        );
    },
});
