import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AutoGrid, Dialog, FlashMessage } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';
import { useBoolean, usePrevious } from 'react-hanger';
import { Translate } from 'c-translation';

type Props = {
    loadingState: NetworkRequestState;
};

const CampaignUpdateState: React.FC<Props> = ({ loadingState }) => {
    const dialogState = useBoolean(false);

    const prevLoadingState = usePrevious(loadingState);

    useEffect(() => {
        if (
            (prevLoadingState === NetworkRequestState.Idle ||
                prevLoadingState === NetworkRequestState.Error) &&
            loadingState === NetworkRequestState.InProgress
        ) {
            dialogState.setTrue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState]);

    if (loadingState === NetworkRequestState.Idle) {
        return null;
    }

    return (
        <Dialog
            title={null}
            removeForm
            onClose={dialogState.setFalse}
            show={dialogState.value}
            content={
                <>
                    <AutoGrid spacing={2} xs={12}>
                        {loadingState === NetworkRequestState.InProgress && (
                            <Box display="flex" alignItems="center">
                                <Translate path="Modules.Main.Campaigns.EditLineItems.saving" />
                                <CircularProgress sx={{ ml: 2 }} />
                            </Box>
                        )}
                        {loadingState === NetworkRequestState.Error && (
                            <FlashMessage status="error">
                                <Translate path="Modules.Main.Campaigns.EditLineItems.failedToSave" />
                            </FlashMessage>
                        )}
                    </AutoGrid>
                </>
            }
        />
    );
};

export default CampaignUpdateState;
