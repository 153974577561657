import React, { useCallback } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_LineItem } from 'c-sdk';
import { Button } from 'c-components';
import { Box } from '@mui/material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useSetAtom } from 'jotai';
import { atom_forceAssignAvailableCreativesLineItemIdDialog } from './atoms';
import { ReadAvailableAssignPerms } from './constants';

type Props = RenderColumnDataProps<Displays_LineItem>;

const LineItemsAvailableCreativesCell: React.FC<Props> = ({ entity, defaultContent }) => {
    const setOpenForceUnAssignId = useSetAtom(atom_forceAssignAvailableCreativesLineItemIdDialog);

    const openForceUnAssign = useCallback(() => {
        setOpenForceUnAssignId(entity.id);
    }, [entity.id, setOpenForceUnAssignId]);

    return (
        <Box textAlign="center" display="flex" justifyContent="center">
            {entity?.availableCreativesCount === 0 && defaultContent}
            {entity?.availableCreativesCount > 0 && (
                <IfHasAllPermissions
                    permissions={ReadAvailableAssignPerms}
                    otherwise={defaultContent}
                >
                    <Button size="small" onClick={openForceUnAssign} variant="text">
                        {defaultContent}
                    </Button>
                </IfHasAllPermissions>
            )}
        </Box>
    );
};

export default LineItemsAvailableCreativesCell;
