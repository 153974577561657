import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UserType } from 'c-sdk';
import { Box, Grid, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { Button, FlashMessage, ToggleButtonGroup, ToggleButtonOptionSchema } from 'c-components';
import { useBoolean, useNumber, usePrevious } from 'react-hanger';
import { useAPIClientRequest, useUserImpersonation } from 'c-hooks';
import { NetworkRequestState } from 'c-data-layer';
import UserEntityAutocomplete from 'c-admin/Components/User/UserEntityAutocomplete';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';

type Props = {
    initialUserId?: number;
};

const userByIdIncludes = ['availableUserTypes'];

const ImpersonateUserForm: React.FC<Props> = () => {
    const { setValue: setSelectedUser, value: selectedUser } = useNumber(null);
    const [selectedUserType, setSelectedUserType] = useState<UserType>();

    const { start, startImpersonatingLoadingState, isImpersonating } = useUserImpersonation();

    const onClickStart = useCallback(() => {
        start(selectedUser, selectedUserType);
    }, [selectedUser, start, selectedUserType]);

    const prevState = usePrevious(startImpersonatingLoadingState.state);
    const hasSuccessfullyImpersonated = useBoolean(false);

    useEffect(() => {
        if (
            prevState === NetworkRequestState.InProgress &&
            startImpersonatingLoadingState.state === NetworkRequestState.Success
        ) {
            hasSuccessfullyImpersonated.setTrue();
        }
        if (
            prevState === NetworkRequestState.Success &&
            startImpersonatingLoadingState.state === NetworkRequestState.Idle
        ) {
            hasSuccessfullyImpersonated.setFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startImpersonatingLoadingState.state]);

    const {
        start: LoadUser,
        data: UserData,
        isLoading: IsUserLoading,
    } = useAPIClientRequest(apiClient.Entities.User.get);

    const user = useMemo(() => UserData?.data?.data, [UserData]);
    const loadUser = useCallback(
        async (user: number) => {
            await to(LoadUser(user, userByIdIncludes));
        },
        [LoadUser],
    );
    useEffect(() => {
        setSelectedUserType(null);
        if (selectedUser != null) {
            // load a user with the available user types
            loadUser(selectedUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser]);

    useEffect(() => {
        if (user?.id === selectedUser && user?.availableUserTypes?.length === 1) {
            // automatically pick the first type if there is only one
            setSelectedUserType(user.availableUserTypes[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const t = useCommonTranslation();

    const availableUserTypes = useMemo(
        () => user?.availableUserTypes ?? [],
        [user?.availableUserTypes],
    );
    const showUserTypeChoice = useMemo(() => availableUserTypes.length > 1, [availableUserTypes]);

    const availableUserTypeOptions = useMemo<ToggleButtonOptionSchema[]>(
        () =>
            availableUserTypes.map(type => ({
                label: t(`Pages.Login.userTypes.${type}`),
                value: type,
            })),
        [availableUserTypes, t],
    );

    if (isImpersonating) {
        return (
            <FlashMessage status="error">
                <Translate path="Modules.Admin.ImpersonateUser.isAlreadyImpersonatingError" />
            </FlashMessage>
        );
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid xs={12} item lg={8}>
                    <Typography variant="body1">
                        <Translate path="Modules.Admin.ImpersonateUser.pageHelpDescription" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <UserEntityAutocomplete
                        onChange={setSelectedUser}
                        value={selectedUser}
                        textFieldProps={{
                            label: t('Modules.Admin.ImpersonateUser.userDropdownLabel'),
                        }}
                        multiple={false}
                    />
                </Grid>
                {showUserTypeChoice && (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1 }}>
                            <Translate path="Modules.Admin.ImpersonateUser.chooseUserType" />
                        </Typography>
                        <ToggleButtonGroup
                            exclusive
                            color="primary"
                            options={availableUserTypeOptions}
                            onChange={setSelectedUserType}
                            value={selectedUserType}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                        disabled={
                            selectedUser == null ||
                            selectedUserType == null ||
                            IsUserLoading ||
                            startImpersonatingLoadingState.state === NetworkRequestState.InProgress
                        }
                        onClick={onClickStart}
                    >
                        {t('Modules.Admin.ImpersonateUser.startImpersonateButton', {
                            type: selectedUserType
                                ? t(`Pages.Login.userTypes.${selectedUserType}`)
                                : '',
                        })}
                    </Button>
                </Grid>
                {hasSuccessfullyImpersonated.value && (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <FlashMessage status="success">
                            <Translate path="Modules.Admin.ImpersonateUser.impersonateSuccess" />
                        </FlashMessage>
                    </Grid>
                )}
                {startImpersonatingLoadingState.state === NetworkRequestState.Error && (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <FlashMessage status="error">
                            {startImpersonatingLoadingState.error ?? (
                                <Translate path="Modules.Admin.ImpersonateUser.genericFailureMessage" />
                            )}
                        </FlashMessage>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ImpersonateUserForm;
