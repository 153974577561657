import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    FilterableEntityTable,
    FilterableEntityTableProps,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
} from 'c-pagination';
import { displayAllocationColumns } from 'c-displays/Components/DisplaysEntityColumns';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { usePaginatedEntityData } from 'c-data';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { Alert, Button, DialogV2, LoadingSpinner } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Box, Stack } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import to from 'await-to-js';

type Props = {
    type: 'Displays_Pack' | 'Displays_Tag';
    id: number;
};
const DeAllocations = ({ type, id }: Props) => {
    const textSearchCols = ['location', 'vendor_id', 'address', 'channel'];
    const paginationTag = `${id}-${type}-Associated-Displays`;
    const { paginatedData } = usePaginatedEntityData(paginationTag, 'Displays_Screen');
    const paginatedIds = useMemo(() => paginatedData?.data.filter(id => id), [paginatedData]);
    const [ids, setIds] = useState([]);
    const deAllocationDialogState = useBoolean(false);
    const allocationType = 'deallocate';
    const screenToAllocateType = 'selected';
    const allocateToType = type === 'Displays_Pack' ? 'packs' : 'tags';
    const allocationToTypeId = id;
    const kartFilter = useBoolean(false);
    const t = useCommonTranslation();
    const { start, requestState, data, error, reset } = useAPIClientRequest(
        ApiClient.Entities.Displays_Screen.allocateAndDisAllocate,
    );
    const deAllocate = useCallback(async () => {
        await to(
            start({
                allocation_type: allocationType,
                screen_to_allocate_type: screenToAllocateType,
                display_ids: ids,
                allocation_to_type: allocateToType,
                allocation_to_type_id: id,
            }),
        );
    }, [allocateToType, ids, start, id]);
    useEffect(() => {
        if ((data as any)?.data?.success === 'yes') {
            setIds([]);
            kartFilter.setFalse();
        }
    }, [setIds, data, kartFilter]);
    const closeDialog = useCallback(() => {
        deAllocationDialogState.setFalse();
        reset();
    }, [deAllocationDialogState, reset]);
    const clearSelectedButton = useMemo(
        () => (
            <Button size="small" disabled={ids.length === 0} onClick={() => setIds([])}>
                {t('Modules.Displays.DisplaysList.clearSelected')}
            </Button>
        ),
        [ids.length, t],
    );
    const deAllocationDialog = useMemo(
        () => (
            <>
                <DialogV2
                    onClose={closeDialog}
                    open={deAllocationDialogState.value}
                    title="Modules.Displays.deAllocation"
                >
                    <Box display="flex" justifyContent="center" p={2}>
                        {(requestState === NetworkRequestState.Idle ||
                            requestState === NetworkRequestState.Error) && (
                            <Stack direction="row" gap={2} display="flex" justifyContent="center">
                                <Button onClick={deAllocate}>Yes</Button>
                                <Button onClick={closeDialog}>No</Button>
                            </Stack>
                        )}
                        {requestState === NetworkRequestState.InProgress && <LoadingSpinner />}
                        {requestState === NetworkRequestState.Error && (
                            <Alert severity="error">{String(error)}</Alert>
                        )}
                        {(data as any)?.data?.success === 'yes' && (
                            <Alert severity="success">
                                {type === 'Displays_Pack'
                                    ? t('Modules.Displays.packRemoval')
                                    : t('Modules.Displays.tagRemoval')}
                            </Alert>
                        )}
                    </Box>
                </DialogV2>
                <Button
                    onClick={deAllocationDialogState.setTrue}
                    size="small"
                    disabled={ids.length === 0}
                >
                    {t('Modules.Displays.deAllocation')}
                </Button>
            </>
        ),
        [
            deAllocationDialogState,
            requestState,
            deAllocate,
            error,
            data,
            type,
            ids.length,
            t,
            closeDialog,
        ],
    );
    const kartButton = useMemo(
        () => (
            <Button size="small" disabled={ids.length === 0} onClick={kartFilter.toggle}>
                {kartFilter.value === false
                    ? t('Modules.Displays.DisplaysList.allocationDialog.viewSelected')
                    : t('Modules.Displays.DisplaysList.allocationDialog.viewAll')}
            </Button>
        ),
        [kartFilter, t, ids],
    );
    const tableRef = useRef<FilterableEntityTableRefAPI>();
    useEffect(() => {
        if (tableRef?.current) {
            tableRef.current.search(1);
        }
        if ((data as any)?.data?.success === true) {
            tableRef.current.search(1);
        }
    }, [data, kartFilter.value]);
    const updateSearchPayload = useCallback<FilterableEntityTableProps['updateSearchPayload']>(
        payload => ({
            ...payload,
            filters: {
                ...payload.filters,
                'filter.id': kartFilter.value ? ids : [],
            },
        }),
        [ids, kartFilter.value],
    );
    const onlyIncludeFilterKeys = [
        [
            'filter.packs.id',
            'filter.size',
            'filter.surface_area',
            'filter.conurbation',
            'filter.tv_region',
            'filter.env',
            'filter.resolutions.id',
            'filter.fileTypes.id',
            'filter.status',
        ],
        [
            'filter.tags.id',
            'filter.size',
            'filter.surface_area',
            'filter.conurbation',
            'filter.tv_region',
            'filter.env',
            'filter.resolutions.id',
            'filter.fileTypes.id',
            'filter.status',
        ],
    ];
    return (
        <FullPageEntityTableWrapper
            title="Modules.Displays.DisplaysList.metaTitle"
            controls={useMemo(
                () => [deAllocationDialog, clearSelectedButton, kartButton],
                [deAllocationDialog, clearSelectedButton, kartButton],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                ref={tableRef}
                columns={displayAllocationColumns(setIds, ids, paginatedIds)}
                baseEntityName="Displays_Screen"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                revertToIdSearchOnNumberOnlyInput={false}
                orderBy="id"
                direction="desc"
                filters={{
                    'filter.tags.id': type === 'Displays_Tag' ? [id] : [],
                    'filter.packs.id': type === 'Displays_Pack' ? [id] : [],
                }}
                showFilters
                onlyIncludeFilterKeys={
                    type === 'Displays_Pack' ? onlyIncludeFilterKeys[1] : onlyIncludeFilterKeys[0]
                }
                updateSearchPayload={updateSearchPayload}
                includes={[
                    'energy_conversion_override',
                    'energy_uses_per_second',
                    'fileTypes',
                    'tags',
                    'operationalTimes',
                    'packs',
                    'markets',
                    'resolutions',
                    'displaysVendorIds',
                    'owner',
                ]}
            />
        </FullPageEntityTableWrapper>
    );
};
export default DeAllocations;
