import React, { useCallback } from 'react';
import { useCreateEntity } from 'c-data';
import { BaseEntity } from 'c-sdk';
import EntityDialog, { EntityDialogProps } from '../EntityDialog/EntityDialog';

const CreateEntityDialog = <Entity extends BaseEntity>({
    entityName,
    ...props
}: EntityDialogProps<Entity>) => {
    const { saveError, saveState, newestCreatedId, createEntity, resetCreateState } =
        useCreateEntity<Entity>(entityName);
    const resetState = useCallback(() => {
        resetCreateState();
    }, [resetCreateState]);
    return (
        <EntityDialog<Entity>
            {...props}
            id={newestCreatedId}
            state={saveState}
            error={saveError}
            resetState={resetState}
            postEntity={createEntity}
        />
    );
};

export default CreateEntityDialog;
