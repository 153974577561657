import { createSlice } from '@reduxjs/toolkit';
import { logout, resetEntities, timeout } from 'c-data-layer';
import { authActions } from '../Auth';
import { SessionCheckState } from './session-check-types';

const initialState: SessionCheckState = {
    checkedSession: false,
};

const slice = createSlice({
    initialState,
    name: 'sessionCheck',
    reducers: {},
    extraReducers: builder => {
        builder.addCase(authActions.finishedLogin, state => ({
            ...state,
            checkedSession: true,
        }));
        builder.addCase(authActions.finishedValidatingSession, state => ({
            ...state,
            checkedSession: true,
        }));
        builder.addCase(authActions.failedValidatingSession, state => ({
            ...state,
            checkedSession: true,
        }));
        // builder.addCase(logout, state => ({
        //     ...state,
        //     checkedSession: false,
        // }));
        // builder.addCase(timeout, state => ({
        //     ...state,
        //     checkedSession: false,
        // }));
        builder.addCase(resetEntities, state => ({
            ...state,
            checkedSession: true,
        }));
    },
});
const sessionCheckSlice = {
    sessionCheckActions: slice.actions,
    sessionCheckReducer: slice.reducer,
};
export default sessionCheckSlice;
export const { sessionCheckActions, sessionCheckReducer } = sessionCheckSlice;
