import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { logout, resetEntities, timeout } from './base-actions';
import { BaseSliceEntityAdapter } from '../../Types/slices';

const createExtraReducers =
    <InitialState, State>({
        adapter,
        initialState,
        resetOnLogout,
        resetOnTimeout,
        extraReducers,
    }: {
        adapter: BaseSliceEntityAdapter<any>;
        initialState: InitialState;
        resetOnLogout: boolean;
        resetOnTimeout: boolean;
        extraReducers?: (
            adapter: BaseSliceEntityAdapter<any>,
            builder: ActionReducerMapBuilder<State>,
        ) => void;
    }): ((builder: ActionReducerMapBuilder<any>) => void) =>
    //
    builder => {
        if (resetOnLogout) {
            builder.addCase(logout, () => initialState);
        }
        if (resetOnTimeout) {
            builder.addCase(timeout, () => initialState);
        }

        builder.addCase(resetEntities, () => initialState);

        if (extraReducers) {
            extraReducers(adapter, builder);
        }
    };
export default createExtraReducers;
