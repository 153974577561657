import React, { useCallback, useMemo, useState } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import { PermissionName } from 'c-sdk';
import {
    ColorLens as SkinIcon,
    Translate as TranslateIcon,
    BugReport as DebugIcon,
    AccessibilityNew as AccessibilityIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'c-auth-module/Slices';
import { useAuthenticatedUser } from 'c-hooks';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { uiSelectors, uiThunks } from 'c-wrapper/Slices';
import LangSwitcher from './LangSwitcher';
import SkinSwitcher from './SkinSwitcher';
import ColorCorrectionSwitcher from './ColorCorrectionSwitcher';

type Props = {
    //
};

enum SwitcherType {
    Lang,
    Skin,
    Color,
}

const SkinLangSwitcher: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const [openSwitch, setOpenSwitch] = useState<SwitcherType>(null);
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const isDebugEnabled = useSelector(uiSelectors.debugEnabled);
    const { user } = useAuthenticatedUser();
    const { hasAll } = useUserPermissions();

    const onClickLang = useCallback(
        () => setOpenSwitch(openSwitch === SwitcherType.Lang ? null : SwitcherType.Lang),
        [openSwitch, setOpenSwitch],
    );

    const onClickSkin = useCallback(
        () => setOpenSwitch(openSwitch === SwitcherType.Skin ? null : SwitcherType.Skin),
        [openSwitch, setOpenSwitch],
    );

    const onClickColor = useCallback(
        () => setOpenSwitch(openSwitch === SwitcherType.Color ? null : SwitcherType.Color),
        [openSwitch, setOpenSwitch],
    );

    const onClickDebug = useCallback(() => dispatch(uiThunks.toggleDebug()), [dispatch]);

    const canSwitchSkins = useMemo(
        () =>
            isLoggedIn &&
            (user?.skins?.length > 1 || hasAll([PermissionName.UniledportalSkinUpdate])),
        [isLoggedIn, user?.skins?.length, hasAll],
    );

    return (
        <>
            {isLoggedIn && (
                <Collapse in={openSwitch === SwitcherType.Lang}>
                    <LangSwitcher />
                </Collapse>
            )}
            {canSwitchSkins && (
                <Collapse in={openSwitch === SwitcherType.Skin}>
                    <SkinSwitcher open={openSwitch === SwitcherType.Skin} />
                </Collapse>
            )}
            {isLoggedIn && (
                <Collapse in={openSwitch === SwitcherType.Color}>
                    <ColorCorrectionSwitcher />
                </Collapse>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                    <IconButton
                        onClick={onClickDebug}
                        color={isDebugEnabled ? 'primary' : 'secondary'}
                    >
                        <DebugIcon />
                    </IconButton>
                </IfHasAllPermissions>
                {isLoggedIn && (
                    <IconButton onClick={onClickLang}>
                        <TranslateIcon />
                    </IconButton>
                )}
                {canSwitchSkins && (
                    <IconButton onClick={onClickSkin}>
                        <SkinIcon />
                    </IconButton>
                )}
                {isLoggedIn && (
                    <IconButton onClick={onClickColor}>
                        <AccessibilityIcon />
                    </IconButton>
                )}
            </Box>
        </>
    );
};

export default SkinLangSwitcher;
