import React, { useCallback, useContext } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import {
    AllEntitiesDropdown,
    ControlledFormInput,
    TextField,
    UpdateEntityButton,
} from 'c-components';
import { PermissionName, Agency } from 'c-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PaginatedEntityTableContext } from 'c-pagination/EntityPaginationContext';
import { useCommonTranslation } from 'c-translation';
import { Stack } from '@mui/material';

type Props = RenderColumnDataProps<Agency>;

const EditPerms = [PermissionName.UniledportalAgencyUpdate];

const AgencyControlsCell: React.FC<Props> = ({ entity }) => {
    const { search } = useContext(PaginatedEntityTableContext);
    const onSuccess = useCallback(() => {
        search();
    }, [search]);
    const t = useCommonTranslation();
    return (
        <IfHasAllPermissions permissions={EditPerms}>
            <UpdateEntityButton<Agency>
                onSuccess={onSuccess}
                entityId={entity.id}
                entityName="Agency"
                title={t('Modules.Main.Agency.List.update.title', { name: entity.name })}
                permissions={EditPerms}
                form={
                    <Stack gap={2}>
                        <ControlledFormInput
                            render={({ field }) => (
                                <TextField
                                    label={t('Modules.Main.Agency.List.update.nameLabel')}
                                    {...field}
                                    autoFocus
                                />
                            )}
                            name="name"
                        />
                        <ControlledFormInput
                            render={({ field }) => (
                                <AllEntitiesDropdown
                                    entityName="Market"
                                    labelField="name"
                                    label={t('Modules.Main.Agency.List.update.marketLabel')}
                                    {...field}
                                />
                            )}
                            name="market_id"
                        />
                    </Stack>
                }
            />
        </IfHasAllPermissions>
    );
};

export default AgencyControlsCell;
