import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { BaseEntity } from 'c-sdk';
import { path } from 'ramda';
import { DateRangeColumnProps } from 'c-pagination/types';
import { format } from 'date-fns';
import { isoDateNoTimeDiff } from 'c-lib';
import { useDateUtils } from 'c-hooks';

type Props<Entity> = { entity: Entity; dateRangeProps?: DateRangeColumnProps };

function formatDate(value: string, dateFormat: string) {
    const date = isoDateNoTimeDiff(value);

    if (date == null) {
        return '';
    }

    return format(date, dateFormat);
}

const defaultRenderDates: DateRangeColumnProps['renderDates'] = (start, end, glue) => (
    <Box sx={{ whiteSpace: 'nowrap' }}>
        {start} {!glue ? '-' : glue} {end}
    </Box>
);
function EntityDataDateRangeColumn<Entity extends BaseEntity>({
    entity,
    dateRangeProps: { startCol, endCol, glue, dateFormat, renderDates = defaultRenderDates },
}: Props<Entity>) {
    const { formatDateStringIgnoreTime, dayMonthYearFormat } = useDateUtils();
    const dateFormatToUse = useMemo(
        () => dateFormat ?? dayMonthYearFormat,
        [dateFormat, dayMonthYearFormat],
    );
    const dateString = useCallback(
        (theDate: string) => {
            if (dateFormatToUse === dayMonthYearFormat) {
                return formatDateStringIgnoreTime({ date: theDate });
            }
            return formatDate(theDate, dateFormatToUse);
        },
        [dateFormatToUse, dayMonthYearFormat, formatDateStringIgnoreTime],
    );

    const startText = useMemo(
        () => dateString(path(startCol.split('.'), entity)),
        [dateString, startCol, entity],
    );
    const endText = useMemo(
        () => dateString(path(endCol.split('.'), entity)),
        [dateString, endCol, entity],
    );

    const content = useMemo(
        () => renderDates(startText, endText, glue),
        [startText, endText, glue, renderDates],
    );

    return <>{content}</>;
}

export default EntityDataDateRangeColumn;
