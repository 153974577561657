import React, { Ref, useCallback, useMemo, useRef } from 'react';
import { ChartReportPart, ChartReportProps, ReportType } from 'c-reports/Types';
import { AutoGrid, Button, FormWrapper } from 'c-components';
import { Box, Collapse, Divider } from '@mui/material';
import { useBoolean } from 'react-hanger';
import { useFormContext, UseFormProps, useWatch } from 'react-hook-form';
import { useCommonTranslation } from 'c-translation';
import { Settings } from '@mui/icons-material';
import { NetworkRequestState } from 'c-data-layer';
import html2canvas from 'html2canvas';
import to from 'await-to-js';
import { useDebounce } from 'use-debounce';
import { css } from '@emotion/react';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { ReportChartWrapper, ReportTableWrapper } from 'c-reports/Components';
import { format, parse } from 'date-fns';
import ChartExportForm from './ChartExportForm';
import {
    BarChartFormDataSchema,
    CategoricalChartFormDataSchema,
    ChartExportImageType,
    ChartExportProps,
    ChartFormDataSchema,
} from './types';
import {
    barChartFormData,
    categoricalChartFormData,
    chartFormData,
    lineChartFormData,
} from './ExportData';
import ExportDataButton from './ExportDataButton';

const ChartExport = (
    {
        type,
        chartProps,
        tableProps,
        loading,
        defaultFormData,
        closeBtn,
        namePrefix,
    }: ChartExportProps & { loading: boolean; defaultFormData: Partial<ChartFormDataSchema> },
    ref: Ref<any>,
) => {
    const { watch } = useFormContext<ChartFormDataSchema>();
    const chartComponentProps = useMemo(
        () => ({ ...chartProps, brushProps: { y: -30 }, hideBrush: true, hideTooltip: true }),
        [chartProps],
    );
    const tableComponentProps = useMemo(() => ({ ...tableProps, ref }), [tableProps, ref]);
    const settingState = useBoolean(false);

    const chartHiddenComponentProps = useMemo(
        () => ({ ...chartComponentProps, hideBrush: true, ref }),
        [chartComponentProps, ref],
    );

    const [debouncedWidth] = useDebounce(+watch('width'), 1000);
    const [debouncedHeight] = useDebounce(+watch('height'), 1000);

    /**
     * Putting the actual chart that'll be exported out of view
     */
    const reportData = useMemo(
        () => (type === ReportType.Table ? tableProps.data : chartProps.data),
        [type, tableProps?.data, chartProps?.data],
    );

    const t = useCommonTranslation();
    return (
        <>
            <Box display="flex" height="90vh" flexDirection="column" alignContent="stretch">
                <Box>
                    <Box display="flex">
                        <AutoGrid spacing={2}>
                            <Button type="submit" disabled={loading}>
                                {loading
                                    ? t('Reporting.exporting.exportSubmitLoading')
                                    : t('Reporting.exporting.exportSubmit')}
                            </Button>
                            <ExportDataButton
                                data={reportData}
                                namePrefix={defaultFormData.reportNamePrefix}
                            />
                            <Button variant="outlined" onClick={settingState.toggle}>
                                {useCommonTranslation('Reporting.exporting.exportSettings')}{' '}
                                <Settings fontSize="inherit" />
                            </Button>
                        </AutoGrid>
                        <Box ml="auto">{closeBtn}</Box>
                    </Box>
                    <Collapse in={settingState.value}>
                        <Box maxHeight={400} overflow="auto" mt={2} pt={1}>
                            <ChartExportForm type={type} defaultData={defaultFormData} />
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2}>
                    <Divider />
                </Box>
                <Box
                    flex={1}
                    width="100%"
                    // to make the brush visible when placed above the chart
                    pt={4}
                    css={css(`.recharts-surface{overflow: visible; }`)}
                >
                    <Box width={debouncedWidth} height={debouncedHeight}>
                        <ChartComponentWrapper
                            chartProps={chartComponentProps}
                            tableProps={tableComponentProps}
                            type={type}
                            namePrefix={namePrefix}
                        />
                    </Box>
                </Box>
            </Box>
            {type !== ReportType.Table && (
                <Box position="absolute" top={-999999} left={-999999}>
                    <Box width={debouncedWidth} height={debouncedHeight}>
                        <ChartComponentWrapper
                            chartProps={chartHiddenComponentProps}
                            tableProps={tableProps}
                            type={type}
                            namePrefix={namePrefix}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

const ChartComponentWrapper = (props: Omit<ChartExportProps, 'closeBtn'>) => {
    if (props.type === ReportType.Table) {
        return (
            <ReportTableWrapper
                {...props.tableProps}
                loadingState={NetworkRequestState.Idle}
                disableInteraction
                disableVirtualization
            />
        );
    }

    return <ChartComponent {...props} />;
};

const ChartComponent = ({ chartProps, type }: Omit<ChartExportProps, 'closeBtn'>) => {
    const { watch: WatchCategorical } = useFormContext<CategoricalChartFormDataSchema>();
    // const { watch: WatchBar } = useFormContext<BarChartFormDataSchema>();

    const [{ top, right, bottom, left }] = useDebounce(WatchCategorical('chartProps.margin'), 1000);
    const [dateFormat] = useDebounce(WatchCategorical('xAxisProps.dateFormat'), 1000);
    const [originalDateFormat] = useDebounce(
        WatchCategorical('xAxisProps.originalDateFormat'),
        1000,
    );
    const showLegend = WatchCategorical('showLegend');
    const [{ angle, textAnchor, showAllLabels }] = useDebounce(
        WatchCategorical('xAxisProps'),
        1000,
    );

    const liveChartSegments = useWatch<BarChartFormDataSchema>({
        name: 'segments',
    }) as BarChartFormDataSchema['segments'];
    const liveBarDataOverrides = useWatch<BarChartFormDataSchema>({
        name: 'dataOverrides',
    }) as BarChartFormDataSchema['dataOverrides'];

    const [chartSegments] = useDebounce(liveChartSegments, 1000);
    const [barDataOverrides] = useDebounce(liveBarDataOverrides, 1000);

    const amendedCategoricalChartProps = useMemo(
        () =>
            ({
                ...chartProps,
                hideLegend: !showLegend,
                chartProps: {
                    margin: {
                        top: +top,
                        right: +right,
                        bottom: +bottom,
                        left: +left,
                    },
                } as CategoricalChartProps,
                xAxisProps: {
                    angle: +angle,
                    textAnchor,
                    interval: showAllLabels === true ? 0 : undefined,
                },
            } as ChartReportProps<any>),
        [chartProps, top, right, bottom, left, angle, textAnchor, showAllLabels, showLegend],
    );
    const amendedBarChartProps = useMemo(
        () =>
            type !== ReportType.Bar
                ? null
                : ({
                      ...amendedCategoricalChartProps,
                      parts: chartSegments
                          .map(segment => {
                              const part =
                                  amendedCategoricalChartProps.parts[segment.originalIndex];

                              return {
                                  ...part,
                                  barProps: {
                                      ...part.barProps,
                                      fill: segment.color,
                                      customColors: barDataOverrides.reduce(
                                          (acc, curr) => ({
                                              ...acc,
                                              [curr.newLabel]:
                                                  curr.partColors[segment.originalIndex],
                                          }),
                                          {},
                                      ),
                                  },
                                  labelOverride: segment.label,
                              } as ChartReportPart;
                          })
                          .filter((part, partIndex) => chartSegments[partIndex].visible),
                      data: barDataOverrides.reduce((acc, curr, currIndex) => {
                          if (barDataOverrides[currIndex].visible) {
                              acc.push({
                                  ...amendedCategoricalChartProps.data[curr.originalIndex],
                                  [amendedCategoricalChartProps.xAxisDataKey]:
                                      barDataOverrides[currIndex].newLabel,
                              });
                          }
                          return acc;
                      }, []),
                  } as ChartReportProps<any>),
        [type, amendedCategoricalChartProps, barDataOverrides, chartSegments],
    );

    const amendedLineChartProps = useMemo(() => {
        if (type !== ReportType.Line) {
            return null;
        }

        const formatDate = (value: string) => {
            try {
                return format(parse(value, originalDateFormat, new Date()), dateFormat);
            } catch {
                return value;
            }
        };

        return {
            ...amendedCategoricalChartProps,
            parts: amendedCategoricalChartProps.parts
                .map(
                    (part, partIndex) =>
                        ({
                            ...part,
                            lineProps: {
                                ...part.lineProps,
                                stroke: chartSegments[partIndex].color,
                            },
                            labelOverride: chartSegments[partIndex].label,
                        } as ChartReportPart),
                )
                .filter((part, partIndex) => chartSegments[partIndex].visible),
            data: chartProps.data.map(d => ({
                ...d,
                [chartProps.xAxisDataKey]: formatDate(d[chartProps.xAxisDataKey]),
            })),
        } as ChartReportProps<any>;
    }, [
        type,
        amendedCategoricalChartProps,
        chartProps.data,
        chartProps.xAxisDataKey,
        dateFormat,
        originalDateFormat,
        chartSegments,
    ]);

    const theCategoricalChartProps = useMemo(() => {
        if (type === ReportType.Bar) return amendedBarChartProps;
        if (type === ReportType.Line) return amendedLineChartProps;

        return amendedCategoricalChartProps;
    }, [type, amendedCategoricalChartProps, amendedBarChartProps, amendedLineChartProps]);

    if (type !== ReportType.Table) {
        return (
            <ReportChartWrapper
                {...theCategoricalChartProps}
                type={type}
                loadingState={NetworkRequestState.Idle}
            />
        );
    }

    return null;
};

const ChartExportRefRapper = React.forwardRef(ChartExport);

const defaultDataFunctions = {
    [ReportType.Bar]: barChartFormData,
    [ReportType.Line]: lineChartFormData,
    [ReportType.Pie]: categoricalChartFormData,
    [ReportType.Mixed]: categoricalChartFormData,
    [ReportType.Table]: chartFormData,
};

const ChartExportWrapper: React.FC<ChartExportProps> = props => {
    const ref = useRef<HTMLElement | { container: HTMLElement }>();
    const loadingState = useBoolean(false);
    const { type } = props;
    const onSubmit = useCallback(
        async (data: ChartFormDataSchema) => {
            if (ref.current) {
                const htmlElement =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore - `.container` is just where recharts puts its html element ref
                    type === ReportType.Table ? ref?.current : ref?.current?.container;

                if (htmlElement != null) {
                    loadingState.setTrue();

                    setTimeout(async () => {
                        const [err, success] = await to(
                            html2canvas(htmlElement as HTMLElement, {
                                logging: false,
                                backgroundColor: null,
                            }),
                        );

                        if (!err && success != null) {
                            const dataUrl = success.toDataURL(data.imageType, 1);

                            const saveLink = document.createElement('a');
                            // download file name
                            saveLink.download =
                                data.imageType === ChartExportImageType.PNG
                                    ? `${data.reportNamePrefix}.png`
                                    : `${data.reportNamePrefix}.jpeg`;
                            // download file data
                            saveLink.href = dataUrl;
                            // start download
                            saveLink.click();
                        } else {
                            console.log('failed to export chart', err);
                        }
                        loadingState.setFalse();
                    }, 500);
                }
            }
        },
        [loadingState, type],
    );

    const opts = useMemo<UseFormProps<ChartFormDataSchema>>(
        () => ({
            defaultValues: {
                ...defaultDataFunctions[props.type](
                    (props.type === ReportType.Table
                        ? props.tableProps
                        : (props.chartProps as any)) as any,
                ),
                reportNamePrefix: props.namePrefix,
                width: 1520,
                height: 641,
            },
        }),
        [props.chartProps, props.tableProps, props.type, props.namePrefix],
    );

    return (
        <FormWrapper<ChartFormDataSchema> onSubmit={onSubmit} formOptions={opts}>
            <ChartExportRefRapper
                {...props}
                defaultFormData={opts.defaultValues as any}
                loading={loadingState.value}
                ref={ref}
            />
        </FormWrapper>
    );
};

export default ChartExportWrapper;
