import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Displays_LineItem, ScheduleGet } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import {
    DataGridPro,
    DataGridProProps,
    GridFooterContainerProps,
    GridSortModel,
} from '@mui/x-data-grid-pro';
import { ListedLineItem } from 'c-main/Components/Schedule/types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, IconButton, InputAdornment, Stack, Switch, Tab } from '@mui/material';
import { lineItemToListedLineItem } from 'c-main/Components/Schedule/lib';
import {
    Alert,
    AutoGrid,
    Button,
    Chip,
    DialogV2,
    Filterdropdown,
    LoadingSpinner,
    TextField,
} from 'c-components';
import { Search } from '@mui/icons-material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import GanttChartBar from 'c-main/Components/Schedule/Components/GanttChartComponents/GanttChartBar';
import GanttChartHeaders from 'c-main/Components/Schedule/Components/GanttChartComponents/GantChartHeaders';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import CustomFooter from 'c-main/Components/Schedule/Components/LineItems/CustomFooter';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { debounce, uniqBy } from 'lodash';

import { useBoolean } from 'react-hanger';
import DetachDialog from 'c-main/Components/Schedule/Components/LineItems/DetachDialog';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from 'c-data-layer';
import CloseIcon from '@mui/icons-material/Close';
import ScreenTable, {
    atom_frameIdFilter,
    atom_scheduleFilters,
    atom_screenData,
} from 'c-main/Components/Schedule/Components/ScreenTable';
import { useAtom } from 'jotai/index';

interface Item {
    id: number | string;
    name: string;
    owner: string;
    schedules: string[];
    type: string;
    frameId?: string;
}

type Props = {
    campaignId: number;
    schedules: ScheduleGet[];
    lineItems: Displays_LineItem[];
    loading: boolean;
    onSelectLineItems: (lineItems: Item) => void;
    selectedLineItems: Item[];
    setSelected: (lineItems: (prevSelected: Item[]) => Item[]) => void;
};

const initialState = {
    columns: {
        columnVisibilityModel: {
            environment: false,
            resolutions: false,
            size: false,
            graph: false,
        },
    },
};
interface TabData {
    lineItemId: number | string;
    label: string;
}

const CampaignScheduleLineItemList: React.FC<Props> = ({
    campaignId,
    schedules,
    lineItems,
    loading,
    onSelectLineItems,
    selectedLineItems,
    setSelected,
}) => {
    const t = useCommonTranslation();
    const [search, setSearch] = useState('');
    const [openTabs, setOpenTabs] = useState<TabData[]>([]);
    const [activeTab, setActiveTab] = useState<string>('lineItems');
    const [, setScreenDataMap] = useAtom(atom_screenData);
    const [, setCurrentPageMap] = useAtom(atom_screenData);
    const [, setPerPageMap] = useAtom(atom_screenData);
    const [, setTotalPagesMap] = useAtom(atom_screenData);
    const [, setTotalRowsMap] = useAtom(atom_screenData);
    const [, setFrameIdFilters] = useAtom(atom_frameIdFilter);
    const [, setScheduleFilters] = useAtom(atom_scheduleFilters);
    const [reloadStateMap, setReloadStateMap] = useState<{ [key: string]: boolean }>({});

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        environment: false,
        resolutions: false,
        size: false,
        graph: false,
        owner: true,
        schedules: true,
        frame_id: true,
    });
    const showFilters = useBoolean(false);
    const handleAddTab = useCallback(
        (lineItemId: number | string, label: string) => {
            const tabExists = openTabs.some(tab => tab.lineItemId === lineItemId);

            if (!tabExists) {
                setOpenTabs(prev => [...prev, { lineItemId, label }]);
                setReloadStateMap(prev => ({
                    ...prev,
                    [lineItemId]: false,
                }));
            }

            setActiveTab(String(lineItemId));
        },
        [openTabs],
    );
    const handleCloseTab = useCallback(
        (lineItemId: number | string) => {
            setOpenTabs(prev => prev.filter(tab => tab.lineItemId !== lineItemId));
            if (activeTab === String(lineItemId)) {
                const nextTab = openTabs.find(tab => tab.lineItemId !== lineItemId);
                setActiveTab(nextTab ? String(nextTab.lineItemId) : 'lineItems');
            }
            setScreenDataMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setCurrentPageMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setPerPageMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setTotalPagesMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setTotalRowsMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setReloadStateMap(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setFrameIdFilters(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
            setScheduleFilters(prev => {
                const { [lineItemId]: _, ...rest } = prev;
                return rest;
            });
        },
        [
            activeTab,
            openTabs,
            setCurrentPageMap,
            setFrameIdFilters,
            setPerPageMap,
            setScheduleFilters,
            setScreenDataMap,
            setTotalPagesMap,
            setTotalRowsMap,
        ],
    );

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const detachState = useBoolean(false);
    const [detach, setDetach] = useState({ lineItemId: null, schedules: [], screenId: null });
    const handleDetach = useCallback(
        (id: number | string, type: string, schedules: ScheduleGet[]) => {
            if (type === 'Display') {
                setDetach({
                    screenId: id,
                    lineItemId: null,
                    schedules: schedules.map(schedule => ({
                        id: schedule.id,
                        name: schedule.name,
                    })),
                });
            } else {
                setDetach({
                    lineItemId: id,
                    schedules: schedules.map(schedule => ({
                        id: schedule.id,
                        name: schedule.name,
                    })),
                    screenId: null,
                });
            }
            detachState.setTrue();
        },
        [detachState],
    );
    const handleCloseDetachDialog = useCallback(() => {
        setDetach({ lineItemId: null, schedules: [], screenId: null });
        detachState.setFalse();
    }, [detachState]);
    const {
        getScheduleLineItems,
        page,
        currentPage,
        setExpandedScheduleId,
        perPage,
        lineItemFilters,
        lineItemOptions,
        setLineItemOptions,
        setScheduleSearch,
        attachStateRequest,
        detachStateRequest,
    } = useNewCampaignSchedule(campaignId);
    const rows = useMemo<ListedLineItem[]>(
        () => lineItems.map(item => lineItemToListedLineItem(item, schedules, t)),
        [lineItems, schedules, t],
    );

    const { minStartDate, maxEndDate } = useMemo(() => {
        let minDate = new Date();
        let maxDate = new Date(0);
        schedules?.forEach(schedule => {
            schedule.rules?.forEach(rule => {
                const startDate = new Date(rule.start_date);
                const endDate = new Date(rule.end_date);
                if (startDate < minDate) minDate = startDate;
                if (endDate > maxDate) maxDate = endDate;
            });
        });
        return {
            minStartDate: minDate,
            maxEndDate: maxDate,
        };
    }, [schedules]);
    const [dateRange, setDateRange] = useState({
        startDate: minStartDate,
        endDate: maxEndDate,
    });
    useEffect(() => {
        setDateRange({ startDate: minStartDate, endDate: maxEndDate });
    }, [minStartDate, maxEndDate]);
    const handleDateRangeChange = (range: any[]) => {
        setDateRange({ startDate: range[0], endDate: range[1] });
    };
    const handleSelectAllClick = useCallback(() => {
        const allItems: Item[] = rows.map(row => ({
            id: row.id,
            name: row.name,
            owner: row.owner,
            frameId: row.frame_id,
            schedules: row.schedules.map(schedule => schedule.name),
            type: row.type,
        }));

        const allSelected = allItems.every(item =>
            selectedLineItems.some(selectedItem => selectedItem.id === item.id),
        );

        if (allSelected) {
            setSelected((prevSelected: Item[]) =>
                prevSelected.filter(
                    selectedItem => !allItems.some(item => item.id === selectedItem.id),
                ),
            );
        } else {
            setSelected((prevSelected: Item[]) => uniqBy([...prevSelected, ...allItems], 'id'));
        }
    }, [rows, selectedLineItems, setSelected]);

    const allItemsSelected = useMemo(() => {
        const allItems = rows.map(row => row.id);
        return allItems.every(id => selectedLineItems.some(selectedItem => selectedItem.id === id));
    }, [rows, selectedLineItems]);

    const indeterminate = useMemo(
        () => selectedLineItems.length > 0 && !allItemsSelected,
        [allItemsSelected, selectedLineItems.length],
    );
    const {
        start: detachStart,
        isLoading: detachLoading,
        reset: detachReset,
        error: detachError,
        requestState: detachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.detachSchedules);

    const detachScheduleCall = useCallback(async () => {
        const scheduleIds = detach.schedules.map(schedule => schedule.id);
        const data = detach.lineItemId
            ? {
                  line_items: [detach.lineItemId],
                  schedules: scheduleIds,
                  displays: [],
              }
            : {
                  schedules: scheduleIds,
                  displays: [detach.screenId],
                  line_items: [],
              };
        await detachStart(campaignId, data);
    }, [campaignId, detach.lineItemId, detach.schedules, detach.screenId, detachStart]);
    useEffect(() => {
        if (detachRequestState === NetworkRequestState.Success) {
            setDetach({ lineItemId: null, schedules: [], screenId: null });
            detachReset();
            getScheduleLineItems(page, perPage);
            detachState.setFalse();
        }
    }, [
        detachRequestState,
        detachState,
        getScheduleLineItems,
        page,
        perPage,
        currentPage,
        detachReset,
    ]);

    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol'),
                renderHeader: () => (
                    <Stack direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={allItemsSelected}
                            indeterminate={indeterminate}
                            onChange={handleSelectAllClick}
                            onClick={e => e.stopPropagation()}
                        />
                        <Box fontWeight="bold">
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol')}
                        </Box>
                    </Stack>
                ),
                sortable: true,
                minWidth: 250,
                renderCell: ({ row }) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{ marginLeft: row.type === 'Display' ? 4 : 0 }}
                    >
                        <Checkbox
                            checked={selectedLineItems.some(
                                selectedItem => selectedItem.id === row.id,
                            )}
                            onChange={() =>
                                onSelectLineItems({
                                    id: row.id,
                                    name: row.name,
                                    owner: row.owner,
                                    frameId: row.frame_id,
                                    schedules: row.schedules.map(
                                        (schedule: ScheduleGet) => schedule.name,
                                    ),
                                    type: row.type,
                                })
                            }
                        />

                        <Stack gap={2}>
                            {row.name}
                            {(row as any).frame_id === 'In a Pack' && (
                                <Box>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleAddTab(row.id, row.name);
                                        }}
                                    >
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.table.openPack',
                                            {
                                                count: (row as ListedLineItem).childScreenCount,
                                            },
                                        )}
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                ),
            },
            {
                field: 'owner',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.ownerCol'),
                sortable: false,
                minWidth: 250,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.frameIdCol'),
                minWidth: 120,
                sortable: false,
            },
            {
                field: 'schedules',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleCol'),
                minWidth: 550,
                sortable: false,
                renderCell: ({ row }) => {
                    const id = row.id;
                    const type = row.type;
                    const schedules = row.schedules;
                    return (
                        <Box>
                            {row.schedules.map((schedule: ScheduleGet) => {
                                if (schedule.is_file) {
                                    return (
                                        <Chip
                                            key={schedule.id}
                                            onClick={() => [
                                                setExpandedScheduleId(schedule.id),
                                                setScheduleSearch(schedule.name),
                                            ]}
                                            label={schedule.name}
                                        />
                                    );
                                }
                                if (schedule.name === 'Default') {
                                    return (
                                        <Chip
                                            key={schedule.id}
                                            onClick={() => [
                                                setExpandedScheduleId(schedule.id),
                                                setScheduleSearch(schedule.name),
                                            ]}
                                            label={schedule.name}
                                        />
                                    );
                                }
                                return (
                                    <Chip
                                        key={schedule.id}
                                        onDelete={() => handleDetach(id, type, [schedule])}
                                        onClick={() => [
                                            setExpandedScheduleId(schedule.id),
                                            setScheduleSearch(schedule.name),
                                        ]}
                                        label={schedule.name}
                                    />
                                );
                            })}
                            <Button
                                variant="text"
                                onClick={() => handleDetach(id, type, schedules)}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.table.clearAll')}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                field: 'graph',
                minWidth: 10000,
                sortable: false,
                renderHeader: () => (
                    <GanttChartHeaders
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                    />
                ),
                renderCell: ({ row }) => (
                    <GanttChartBar
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        row={row}
                        t={t}
                    />
                ),
            },
            {
                field: 'environment',
                headerName: '',
                sortable: false,
            },
            {
                field: 'resolutions',
                headerName: '',
                sortable: false,
            },
            {
                field: 'size',
                headerName: '',
                sortable: false,
            },
        ],
        [
            t,
            allItemsSelected,
            indeterminate,
            handleSelectAllClick,
            selectedLineItems,
            onSelectLineItems,
            handleAddTab,
            handleDetach,
            setExpandedScheduleId,
            setScheduleSearch,
            dateRange.startDate,
            dateRange.endDate,
        ],
    );

    const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }, []);

    const toggleColumnVisibility = () => {
        setColumnVisibilityModel(prevModel => ({
            ...prevModel,
            owner: !prevModel.owner,
            schedules: !prevModel.schedules,
            graph: !prevModel.graph,
            frame_id: !prevModel.frame_id,
        }));
    };
    const detachTitle = useCallback(() => {
        if (detach.lineItemId) {
            const name = rows.find(row => row.id === detach.lineItemId)?.name;
            return t('Modules.Main.Campaigns.CampaignSchedule.detachDialogTitle', { name });
        }
        return null;
        // const name = screens.find(screen => screen.id === detach.screenId)?.name;
        // return t('Modules.Main.Campaigns.CampaignSchedule.detachDialogTitle', { name });
    }, [detach.lineItemId, rows, t]);
    const handleFilterChange = useCallback(
        (filterKey: string, selectedValues: string[] | number[]) => {
            setLineItemOptions(prevOptions => ({
                ...prevOptions,
                filters: {
                    ...prevOptions.filters,
                    [filterKey]: selectedValues,
                },
            }));
        },
        [setLineItemOptions],
    );
    const filters = useMemo(() => {
        if (lineItemFilters.length > 0) {
            return lineItemFilters?.map(filter => (
                <Filterdropdown
                    disabled={loading}
                    key={filter.key}
                    value={lineItemOptions.filters[filter.key]}
                    onChange={event => handleFilterChange(filter.key, event)}
                    options={filter.options}
                    multiple
                    placeholder={`${filter.label}`}
                />
            ));
        }
        return null;
    }, [handleFilterChange, lineItemFilters, lineItemOptions.filters, loading]);
    useEffect(() => {
        const debouncedApiCall = debounce(() => {
            getScheduleLineItems(
                lineItemOptions.page,
                lineItemOptions.perPage,
                lineItemOptions.orderBy,
                lineItemOptions.direction,
                lineItemOptions.filters,
                search,
            );
        }, 1000);

        debouncedApiCall();

        return () => {
            debouncedApiCall.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItemOptions, search]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'name', sort: 'asc' }, // Default sorting on owner column
    ]);
    const handleSortModelChange = useCallback(
        (newSortModel: GridSortModel) => {
            setSortModel(newSortModel);

            if (newSortModel.length > 0) {
                const { field, sort } = newSortModel[0];

                setLineItemOptions(prevOptions => ({
                    ...prevOptions,
                    orderBy: field,
                    direction: sort ?? 'asc',
                }));
            } else {
                setLineItemOptions(prevOptions => ({
                    ...prevOptions,
                    orderBy: 'name',
                    direction: 'asc',
                }));
            }
        },
        [setLineItemOptions],
    );

    const props = useMemo<Partial<DataGridProProps>>(
        () => ({
            disableColumnResize: false,
            columns,
            rows,
            // rowHeight: 100,
            getRowHeight: () => 'auto',
            sortModel,
            onSortModelChange: handleSortModelChange,
        }),
        [columns, handleSortModelChange, rows, sortModel],
    );
    useEffect(() => {
        if (activeTab === null) {
            setActiveTab('lineItems');
        }
    }, [activeTab]);
    useEffect(() => {
        if (
            attachStateRequest === NetworkRequestState.Success ||
            detachStateRequest === NetworkRequestState.Success ||
            detachRequestState === NetworkRequestState.Success
        ) {
            setReloadStateMap(prevState => {
                const updatedState = { ...prevState };
                openTabs.forEach(tab => {
                    updatedState[tab.lineItemId] = true;
                });
                return updatedState;
            });
        }
    }, [attachStateRequest, detachRequestState, detachStateRequest, openTabs, setReloadStateMap]);

    return (
        <>
            <DialogV2
                onClose={handleCloseDetachDialog}
                open={detachState.value}
                title={detachTitle()}
                maxWidth="xl"
            >
                <Box display="flex" flexDirection="column" height="100%">
                    <Box flex={1} overflow="auto">
                        <DetachDialog
                            detach={detach}
                            detachState={detachState}
                            setDetach={setDetach}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: 'background.paper',
                            padding: 2,
                            boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={detachScheduleCall}
                            disabled={detachRequestState === NetworkRequestState.InProgress}
                        >
                            {detachLoading ? (
                                <LoadingSpinner />
                            ) : (
                                t('Modules.Main.Campaigns.CampaignSchedule.detachDialogButton')
                            )}
                        </Button>
                    </Box>
                    {detachError && <Alert severity="error">{String(detachError)}</Alert>}
                </Box>
            </DialogV2>
            <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Line Item Screen Tabs">
                        <Tab
                            label={t('Modules.Main.Campaigns.CampaignSchedule.lineItems')}
                            value="lineItems"
                        />
                        {openTabs.map(tab => (
                            <Tab
                                key={tab.lineItemId}
                                label={
                                    <Box display="flex" alignItems="center">
                                        {tab.label}
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleCloseTab(tab.lineItemId);
                                            }}
                                            size="small"
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                }
                                value={String(tab.lineItemId)}
                            />
                        ))}
                    </TabList>
                </Box>
                {activeTab === 'lineItems' && (
                    <Box overflow="scroll">
                        <Box
                            flex={1}
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            overflow="hidden"
                            pt={1}
                        >
                            <Stack direction="row">
                                <Box>
                                    <TextField
                                        value={search}
                                        size="small"
                                        variant="outlined"
                                        onChange={onSearchChange}
                                        placeholder={t(
                                            'Modules.Main.Campaigns.CampaignSchedule.filters.searchLabel',
                                        )}
                                        disabled={loading}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Search fontSize="inherit" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <IconButton onClick={showFilters.toggle}>
                                    <FilterAltIcon />
                                </IconButton>
                            </Stack>
                            {showFilters.value && (
                                <AutoGrid gap={2} xs={3} md={3} pt={2}>
                                    {filters}
                                </AutoGrid>
                            )}
                            <Stack
                                direction="row"
                                pt={3}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Switch onChange={toggleColumnVisibility} disabled={loading} />
                                {columnVisibilityModel.graph && (
                                    <DateRangePicker
                                        maxDate={maxEndDate}
                                        minDate={minStartDate}
                                        onChange={handleDateRangeChange}
                                        value={[dateRange.startDate, dateRange.endDate]}
                                    />
                                )}
                            </Stack>
                            <Box flex={1} overflow="hidden" mt={1} display="flex" minHeight="34rem">
                                <DataGridPro
                                    {...(props as DataGridProProps)}
                                    initialState={initialState}
                                    loading={loading}
                                    columnVisibilityModel={columnVisibilityModel}
                                    components={{
                                        Footer: CustomFooter,
                                    }}
                                    componentsProps={{
                                        footer: {
                                            campaignId,
                                        } as GridFooterContainerProps,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}

                {openTabs.map(tab => (
                    <TabPanel
                        key={tab.lineItemId}
                        value={String(tab.lineItemId)}
                        sx={{
                            height: '100%',
                            overflow: 'hidden',
                            display: activeTab === String(tab.lineItemId) ? 'block' : 'none',
                        }}
                    >
                        <Stack gap={1} overflow="hidden" sx={{ height: '100%' }}>
                            <Box flex={1} overflow="auto" mt={1} display="flex" minHeight="34rem">
                                <ScreenTable
                                    campaignId={campaignId}
                                    lineItemId={tab.lineItemId}
                                    scheduleLineItems={rows}
                                    setExpandedScheduleId={setExpandedScheduleId}
                                    handleDetach={handleDetach}
                                    reloadStateMap={reloadStateMap}
                                    setReloadStateMap={setReloadStateMap}
                                />
                            </Box>
                        </Stack>
                    </TabPanel>
                ))}
            </TabContext>
        </>
    );
};

export default CampaignScheduleLineItemList;
