import { Dispatch } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { CoreThunk } from '../../Types/CoreThunk';
import { NetworkRequest, NetworkRequestState } from '../../Types/network';

const hookUtilGetById =
    <RootState, Entity>(thunk: (id: number, includes?: string[]) => CoreThunk<RootState>) =>
    (
        id: number,
        keyedEntities: Dictionary<Entity>,
        getNetworkState: (id: number) => NetworkRequest,
        dispatch: Dispatch<any>,
        includes?: string[],
    ) => {
        const entity = keyedEntities[id] ?? null;
        const networkState = getNetworkState(id).state;
        if (
            entity == null &&
            networkState !== NetworkRequestState.InProgress &&
            networkState !== NetworkRequestState.Error
        ) {
            dispatch(thunk(id, includes));
        }

        return entity ?? null;
    };

export default hookUtilGetById;
