import { pickBy, uniq } from 'ramda';
import { AllEntities, Permission } from 'c-sdk';
import {
    PermissionByCategory,
    PermissionNamespace,
    PermissionsByNamespace,
    PermissionsDiff,
} from 'c-admin';
import { snake2PascalCase, stringEndsWithAny, stringStartsWithAny } from 'c-lib';

const CreateSuffix = '.create';
const ReadSuffix = '.read';
const UpdateSuffix = '.update';
const DestroySuffix = '.destroy';

export const checkedPermissionIds = (formValues: Record<number, boolean>) =>
    Object.keys(pickBy(val => val === true, formValues));

export const permissionDiffs = (
    formValues: Record<number, boolean>,
    allPermissionIds: number[],
    rolePermissionIds: number[],
): PermissionsDiff =>
    allPermissionIds.reduce(
        (acc, permissionId) => {
            const permValue = formValues[permissionId] ?? false;
            if (rolePermissionIds.indexOf(permissionId) !== -1 && permValue === false) {
                // was there but now not selected
                acc.removed.push(permissionId);
            }
            if (rolePermissionIds.indexOf(permissionId) === -1 && permValue === true) {
                // was not there but now is selected
                acc.added.push(permissionId);
            }
            return acc;
        },
        { added: [], removed: [] } as PermissionsDiff,
    );

export const groupedPermissions = (
    permissions: Permission[],
    generalNamespaces: PermissionNamespace[] = [],
): PermissionByCategory => {
    const { crud, raw } = namespacedPermissions(permissions, generalNamespaces);
    return {
        general: uniq([
            ...onlyNotEntityPermissions(permissions),
            ...permissions.filter(p => stringStartsWithAny(p.name, generalNamespaces)),
        ]),
        namespaces: crud,
        rawPermissionsByNamespace: raw,
    };
};

type NamespacedReturn = {
    crud: PermissionsByNamespace;
    raw: PermissionByCategory['rawPermissionsByNamespace'];
};

const namespacedPermissions = (
    permissions: Permission[],
    excludedNamespaces: PermissionNamespace[] = [],
): NamespacedReturn =>
    onlyEntityPermissions(permissions).reduce(
        (acc, curr) => {
            const parts = curr.name.split('.');
            const namespace = parts[0] as PermissionNamespace;

            // {namespace}.{model}.{create|read|update|delete}
            if (parts.length === 3 && excludedNamespaces.indexOf(namespace) === -1) {
                const entity = snake2PascalCase(parts[1]) as keyof AllEntities;

                if (acc.crud[namespace] == null) {
                    acc.crud[namespace] = {};
                }
                if (acc.crud[namespace][entity] == null) {
                    acc.crud[namespace][entity] = {};
                }

                if (curr.name.endsWith(CreateSuffix)) acc.crud[namespace][entity].create = curr;
                if (curr.name.endsWith(ReadSuffix)) acc.crud[namespace][entity].read = curr;
                if (curr.name.endsWith(UpdateSuffix)) acc.crud[namespace][entity].update = curr;
                if (curr.name.endsWith(DestroySuffix)) acc.crud[namespace][entity].delete = curr;

                if (!Array.isArray(acc.raw[namespace])) {
                    acc.raw[namespace] = [];
                }

                acc.raw[namespace].push(curr);
            }
            return acc;
        },
        { crud: {}, raw: {} } as NamespacedReturn,
    );

const onlyEntityPermissions = (permissions: Permission[]): Permission[] =>
    permissions.filter(
        p =>
            stringEndsWithAny(p.name, [CreateSuffix, ReadSuffix, UpdateSuffix, DestroySuffix]) &&
            p.name.split('.').length === 3,
    );

const onlyNotEntityPermissions = (permissions: Permission[]): Permission[] =>
    permissions.filter(
        p =>
            !stringEndsWithAny(p.name, [CreateSuffix, ReadSuffix, UpdateSuffix, DestroySuffix]) ||
            p.name.split('.').length !== 3,
    );
