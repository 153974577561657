import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { AllEntities, BaseEntity } from 'c-sdk';
import { SearchPayload } from '../Types/Search';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { EntityStores, NetworkRequestState, SlicePaginationData } from '../Types';
import { hookUtilGetPaginatedList } from './utils';
import { emptyObjects } from '../lib';

export const PAGINATION_TAG_ALL = '__Pagination__All__';

const emptyEntities = () => {};
/**
 * This hook is intended for receiving a full list of entities of a certain type.
 * @param entityName
 */
const useAllEntityData = <RootState, Entity extends BaseEntity>(entityName: keyof AllEntities) => {
    const dispatch = useDispatch();

    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const selectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);
    const paginatedData = useSelector(
        (state: EntityStores<Entity>) =>
            selectors?.paginationData(state, PAGINATION_TAG_ALL) ??
            emptyObjects.slicePaginationData(),
    ) as unknown as SlicePaginationData<Entity>;

    const keyedEntities = useSelector(selectors?.selectEntities ?? emptyEntities);

    const allEntities = useMemo(
        () =>
            /**
             * put entities back into the order of the paginated list
             * which will have been dictated by the search payload ordering
             */
            (paginatedData?.data ?? []).reduce((acc, entityId) => {
                const entity = keyedEntities[entityId];
                if (entity) {
                    acc.push(entity);
                }

                return acc;
            }, [] as Entity[]),
        [keyedEntities, paginatedData.data],
    );

    const currentMetaPage = useMemo(
        () => paginatedData?.meta?.pagination?.current_page,
        [paginatedData],
    );
    const currentLoading = useMemo(() => paginatedData?.loadingState?.state, [paginatedData]);

    const getAll = useCallback(
        (
            searchPayload: SearchPayload<Entity> = { perPage: 99999, filters: {} },
            forceRefresh = false,
        ) => {
            hookUtilGetPaginatedList<RootState, Entity>(thunks.getPaginatedList)(
                PAGINATION_TAG_ALL,
                forceRefresh,
                searchPayload,
                dispatch,
                false,
                null,
                currentMetaPage,
                currentLoading,
            );
        },
        [thunks, dispatch],
    );

    const isLoading = useMemo(
        () => paginatedData.loadingState.state === NetworkRequestState.InProgress,
        [paginatedData],
    );

    return useMemo(
        () => ({
            getAll,
            allEntities,
            isLoading,
        }),
        [getAll, allEntities, isLoading],
    );
};

export default useAllEntityData;
