import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from 'c-data-layer';
import { Alert, AutoGrid, Button, DialogV2, FileUploadDropzone, ListItemText } from 'c-components';
import {
    Box,
    LinearProgress,
    LinearProgressProps,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckCircle, Error } from '@mui/icons-material';
import { Displays_AnalogueScreen, Displays_Pack, Displays_Screen } from 'c-sdk';
import { formatBytes } from 'c-lib';
import { useBoolean } from 'react-hanger';

const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};

export const handleBrochureDownload = async (e: any, href: string, id: number | string) => {
    const response = await fetch(href);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}brochureFile.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const MassUpdatingDisplays = ({ id = null, location, dialogState = null }) => {
    const { start, requestState, reset, error } = useAPIClientRequest(
        ApiClient.Entities.Displays_Screen.massUpdate,
    );
    const [file, setFile] = useState<File[] | null>([]);
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const [ownerId, setOwnerId] = useState<number>(null);
    const onChange = useCallback(async (newFiles: File[]) => {
        setFile(newFiles);
    }, []);
    const t = useCommonTranslation();

    const buttonDisabler = useMemo(() => {
        if (file?.length === 0) {
            return true;
        }
        if (requestState === NetworkRequestState.InProgress) {
            return true;
        }
        return false;
    }, [file, requestState]);

    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success) {
            return { color: 'success.main', ...primaryTypography };
        }
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);

    const submitFile = useCallback(async () => {
        setProgress(null);
        const formData = new FormData();
        if (location === 'owners') {
            formData.append('owner_id', id);
        }
        formData.append('file', file[0]);
        await to(start(formData, setProgress));
    }, [file, id, start, location]);
    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);
    useEffect(() => {
        if (dialogState) {
            if (requestState === NetworkRequestState.Success) {
                dialogState.setFalse();
                setFile(null);
            }
        }
    }, [dialogState, requestState]);
    return (
        <>
            <AutoGrid xs={9} columns={1}>
                <FileUploadDropzone
                    fileAccept=".csv"
                    onFilesAdded={onChange}
                    dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                />
                {file?.length > 0 && (
                    <ListItemText
                        primary={file[0]?.name}
                        primaryTypographyProps={textProps}
                        secondaryTypographyProps={textProps}
                        secondary={
                            <Stack gap={1} width="100%">
                                <AutoGrid spacing={1}>
                                    {formatBytes(file[0]?.size)} | {file[0]?.type}
                                </AutoGrid>
                                <Box width="100%">
                                    <Stack direction="row" gap={1} alignItems="center">
                                        <Box flex={1}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={progressPercentage}
                                                color={progressColor}
                                            />
                                        </Box>
                                        {requestState === NetworkRequestState.InProgress && (
                                            <Typography variant="caption">
                                                {progressPercentage.toFixed(0)} %
                                            </Typography>
                                        )}
                                        {requestState === NetworkRequestState.Error && (
                                            <Tooltip title={String(error)}>
                                                <Typography color="error.main">
                                                    <Error />
                                                </Typography>
                                            </Tooltip>
                                        )}
                                        {requestState === NetworkRequestState.Success && (
                                            <Typography color="success.main">
                                                <CheckCircle />
                                            </Typography>
                                        )}
                                    </Stack>
                                </Box>
                            </Stack>
                        }
                    />
                )}
            </AutoGrid>
            <Box pt={2}>
                <Button disabled={buttonDisabler} onClick={submitFile}>
                    {t('Modules.Displays.OwnerList.submit')}
                </Button>
            </Box>
        </>
    );
};

export const useBrochureEditHandling = (
    id,
    setFormValue,
    entityName: string,
    brochureDownload: string,
    dialogState,
    delDialogState,
    entity?: Displays_Screen | Displays_Pack | Displays_AnalogueScreen,
) => {
    const [brochure, setBrochure] = useState<File[]>();
    const brochureState = useBoolean(entity?.brochure != null);
    const t = useCommonTranslation();
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );

    const {
        start,
        data,
        requestState,
        reset,
        error: uploadErr,
    } = useAPIClientRequest(ApiClient.Entities[entityName].createBrochure);

    const {
        start: delStart,
        requestState: delReq,
        reset: delReset,
        error: delErr,
    } = useAPIClientRequest(ApiClient.Entities[entityName].deleteBrochure);

    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);

    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success) {
            brochureState.setTrue();
            return { color: 'success.main', ...primaryTypography };
        }
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState, brochureState]);

    const deleteFile = useCallback(async () => {
        await to(delStart(id));
    }, [delStart, id]);

    useEffect(() => {
        if (requestState === NetworkRequestState.Success) {
            setFormValue('brochure', (data as any)?.data?.brochure);
            dialogState.setFalse();
            reset();
            setTimeout(() => {
                setBrochure(null);
            }, 2000);
        }
    }, [requestState, setFormValue, data, dialogState, reset]);

    useEffect(() => {
        if (delReq === NetworkRequestState.Success) {
            setFormValue('brochure', undefined);
            brochureState.setFalse();
            delDialogState.setFalse();
            delReset();
        }
    }, [delDialogState, delReq, setFormValue, delReset, brochureDownload, brochureState]);

    const onChange = useCallback(
        async (newFiles: File[]) => {
            setBrochure(newFiles);
            reset();
            setProgress(null);
            const formData = new FormData();
            formData.append('brochure_file', newFiles[0]);
            await to(start(id, formData, setProgress));
        },
        [id, start, reset, setProgress],
    );

    const dialogBrochureFileDelete = useMemo(
        () => (
            <DialogV2 onClose={delDialogState.setFalse} open={delDialogState.value}>
                <Typography
                    variant="inherit"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {t('Modules.Displays.DisplaysList.table.confirmation')}
                </Typography>
                <Stack
                    direction="row"
                    gap={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button onClick={deleteFile}>
                        {t('Modules.Displays.DisplaysList.table.yes')}
                    </Button>
                    <Button onClick={delDialogState.setFalse}>
                        {t('Modules.Displays.DisplaysList.table.no')}
                    </Button>
                </Stack>
                <Box>
                    {delReq === NetworkRequestState.InProgress && <CircularProgress />}
                    {delReq === NetworkRequestState.Success && <CheckCircle color="success" />}
                </Box>
                {delReq === NetworkRequestState.Error && (
                    <Alert severity="error" variant="outlined">
                        {String(delErr)}
                    </Alert>
                )}
            </DialogV2>
        ),
        [delDialogState.setFalse, delDialogState.value, delErr, delReq, deleteFile, t],
    );

    const dialogBrochureFileUpdate = useMemo(
        () => (
            <>
                <DialogV2
                    onClose={dialogState.setFalse}
                    open={dialogState.value}
                    title={t('Modules.Displays.DisplaysList.table.updateBrochure')}
                >
                    <Box>
                        {entityName === 'Displays_Screen' &&
                            (entity as Displays_Screen)?.packs?.length > 0 && (
                                <>
                                    <Typography>
                                        {t('Modules.Displays.DisplaysDisplayEdit.brochureWarning')}
                                    </Typography>
                                </>
                            )}
                        <AutoGrid spacing={2} xs={12}>
                            <FileUploadDropzone
                                fileAccept=".pdf"
                                onFilesAdded={onChange}
                                dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                            />
                        </AutoGrid>
                    </Box>
                    <Box pb={2}>
                        {brochure && (
                            <ListItemText
                                primary={brochure[0].name}
                                primaryTypographyProps={textProps}
                                secondaryTypographyProps={textProps}
                                secondary={
                                    <Stack gap={1} width="100%">
                                        <AutoGrid spacing={1}>
                                            {formatBytes(brochure[0]?.size)} | {brochure[0]?.type}
                                        </AutoGrid>
                                        <Box width="100%">
                                            <Stack direction="row" gap={1} alignItems="center">
                                                <Box flex={1}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={progressPercentage}
                                                        color={progressColor}
                                                    />
                                                </Box>
                                                {requestState ===
                                                    NetworkRequestState.InProgress && (
                                                    <Typography variant="caption">
                                                        {progressPercentage.toFixed(0)} %
                                                    </Typography>
                                                )}
                                                {requestState === NetworkRequestState.Error && (
                                                    <Tooltip title={String(uploadErr)}>
                                                        <Typography color="error.main">
                                                            <Error />
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                                {requestState === NetworkRequestState.Success && (
                                                    <Typography color="success.main">
                                                        <CheckCircle />
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                }
                            />
                        )}
                    </Box>
                </DialogV2>
                <Stack pt={4}>
                    <Button onClick={dialogState.setTrue}>
                        {brochureState.value
                            ? t('Modules.Displays.DisplaysPackEdit.fields.replaceBrochure')
                            : t('Modules.Displays.DisplaysPackEdit.fields.uploadBrochure')}
                    </Button>
                </Stack>
            </>
        ),
        [
            brochure,
            brochureState.value,
            dialogState.setFalse,
            dialogState.setTrue,
            dialogState.value,
            entity,
            entityName,
            onChange,
            progressColor,
            progressPercentage,
            requestState,
            t,
            textProps,
            uploadErr,
        ],
    );

    return {
        dialogBrochureFileDelete,
        dialogBrochureFileUpdate,
        handleBrochureDownload: e => handleBrochureDownload(e, brochureDownload, id),
    };
};
