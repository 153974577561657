import React, { useCallback } from 'react';
import TargetForm from 'c-main/Components/Schedule/Components/Targets/TargetForm';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { Stack } from '@mui/material';
import { Alert } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';

const CreateNewTarget = ({ id, createBool }) => {
    const { setTargets } = useNewCampaignSchedule(id);
    const { start, error, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.createTargets,
    );

    const createTarget = useCallback(
        async data => {
            const [err, success] = await to(start(id, data));
            if (!err && success && success?.data?.data) {
                setTargets(prevTargets => [...prevTargets, success.data?.data]);
                createBool.setFalse();
            }
        },
        [start, id, setTargets, createBool],
    );

    return (
        <Stack>
            <TargetForm
                onSubmit={createTarget}
                isUpdating={false}
                initialData={{}}
                isLoading={requestState === NetworkRequestState.InProgress}
            />
            {error && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
        </Stack>
    );
};

export default CreateNewTarget;
