import React from 'react';
import { addConfig, AppProviders, mergeConfigs } from 'c-data-layer';
import { Config } from 'c-config';
import { createStore } from 'c-data';
import { CommonState } from 'c-types';
import AppWrapper from 'c-wrapper/Components/AppWrapper';
import CommonWrapper from 'c-wrapper/Components/CommonWrapper';
import { version } from '../../package.json';
import { defaultTheme } from './Themes';
import commonConfig from '../commonConfig';

const rootConfig: Partial<Config> = {};

export const config = {
    ...(mergeConfigs([commonConfig, rootConfig] as any) as any),
    mergeFunction: (c: any) => {
        // eslint-disable-next-line no-param-reassign
        c.systemSearchParsed = (commonConfig as any).systemSearchParsed;
        return c;
    },
} as Partial<Config>;

addConfig(config as any);

const App = () => {
    const { store, persistor } = createStore<CommonState>({
        version,
        // stores I don't want committed to the local storage
        blacklist: [
            'jobStatus',
            'logFileImport',
            'logFileResource',
            'buyer',
            'buyerCost',
            // 'campaign',
            'campaignDisplay',
            'campaignPopShot',
            'creative',
            'displaysLineItems',
            'displaysOwner',
            'displaysPack',
            'popChannelFrame',
            'popDisplay',
            'permission',
            'resolution',
        ],
        // transforms: [
        //     excludeReducerNetworkStateData({
        //         create: true,
        //         deletingById: true,
        //         loadingById: true,
        //         newestCreatedId: true,
        //         pagination: true,
        //         updatingById: true,
        //     }),
        // ],
    });

    return (
        <>
            <AppProviders store={store} persistor={persistor} loading={<></>}>
                <CommonWrapper themeOptions={defaultTheme}>
                    <AppWrapper />
                </CommonWrapper>
            </AppProviders>
        </>
    );
};

export default App;
