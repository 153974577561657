const initials = (input = '', maxInitials: number | null = null, extended: boolean = false) => {
    if (!input.trim()) return '??';

    if (extended) {
        // Advanced functionality
        const capitalLetters = input.match(/[A-Z]/g);
        if (capitalLetters && capitalLetters.length > 1) {
            // Prioritize capital letters
            return maxInitials === null
                ? capitalLetters.join('')
                : capitalLetters.slice(0, maxInitials).join('');
        }

        const words = input
            .split(' ')
            .map(word => word.replace(/[^a-zA-Z]/g, '')) // Remove non-alphabetic characters
            .filter(word => word.length > 0); // Filter out empty results

        if (words.length > 1) {
            // Multi-word logic
            const extracted = words.map(word => word.charAt(0).toUpperCase());
            return maxInitials === null
                ? extracted.join('')
                : extracted.slice(0, maxInitials).join('');
        }

        // Fallback to syllable-based initials
        const syllableBased = extractSyllableInitials(words[0] || '', maxInitials);
        if (syllableBased.length > 0) {
            return syllableBased;
        }
    }

    // Default functionality: extract initials by word
    const words = input.split(' ').filter(word => word.length > 0); // Remove empty words
    const extracted = words.map(word => word.charAt(0).toUpperCase());

    const lastAttempt =
        maxInitials === null ? extracted.join('') : extracted.slice(0, maxInitials).join('');
    if (lastAttempt.length > 0) {
        return lastAttempt;
    }

    return '??';
};

const extractSyllableInitials = (word: string, maxInitials: number | null) => {
    const vowels = 'aeiou';
    const syllableInitials = word
        .split('')
        .filter((char, index) => index === 0 || vowels.includes(char.toLowerCase()))
        .map(char => char.toUpperCase());
    return maxInitials === null
        ? syllableInitials.join('')
        : syllableInitials.slice(0, maxInitials).join('');
};

export default initials;
