import { AxiosRequestConfig } from 'axios';
import { CreativeGroup as CreativeGroupModel, ModuleConstructorProps } from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const CreativeGroup = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<CreativeGroupModel>(
        { endpoints, httpClient, baseUrl },
        'CreativeGroup',
    ),
    delete: (creativeGroupIds: number[], deleteCreatives: boolean, config?: AxiosRequestConfig) =>
        httpClient.delete(
            `${endpoints.Entities.CreativeGroup.delete}`,
            {
                creative_group_ids: creativeGroupIds,
                delete_creatives: deleteCreatives,
            },
            config,
        ),
});

export default CreativeGroup;
