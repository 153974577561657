import React, { useCallback, useMemo } from 'react';
import { apiClient } from 'c-data';
import { LogFileParsedDaily } from 'c-pop-displays/Components/LogFileParsed';
import { format, isValid } from 'date-fns';
import { DisplayMediaBreakdownData } from 'c-sdk';
import { ReportConfig, ReportDateTimeframe } from 'c-reports/Types';

type Props = {
    id: number;
    minDate: Date;
    maxDate: Date;
    mediaNames?: string[];
    defaultGroupByMedia?: boolean;
    defaultGroupByHour?: boolean;
};

const DisplayDates: React.FC<Props> = ({
    id,
    minDate,
    maxDate,
    mediaNames,
    defaultGroupByMedia,
    defaultGroupByHour,
}) => {
    const validDates = useMemo(() => isValid(minDate) && isValid(maxDate), [minDate, maxDate]);

    const fetchData: ReportConfig<{ data: DisplayMediaBreakdownData[] }>['fetchData'] = useCallback(
        (start, end, timeframe) => {
            if (timeframe === ReportDateTimeframe.All) {
                apiClient.PopDisplays.displayMediaBreakdown(id, {
                    perPage: 9999999,
                    media_items: mediaNames ?? [],
                });
            }
            return apiClient.PopDisplays.displayMediaBreakdown(id, {
                start_date: start ? `${format(start, 'yyyy-MM-dd')} 00:00:00` : undefined,
                end_date: end ? `${format(end, 'yyyy-MM-dd')} 23:59:59` : undefined,
                perPage: 9999999,
                media_items: mediaNames ?? [],
            });
        },
        [id, mediaNames],
    );

    if (!validDates) return null;

    return (
        <LogFileParsedDaily
            minDate={minDate}
            maxDate={maxDate}
            chartName={`${id}-daily-totals`}
            fetchData={fetchData}
            defaultGroupByMedia={defaultGroupByMedia}
            defaultGroupByHour={defaultGroupByHour}
        />
    );
};
export default DisplayDates;
