function generateArrayQSParams(
    arrayParam: number[] | string[] = [],
    paramKey: string,
    prepend?: string,
) {
    let qs = '';

    arrayParam.forEach((param, index) => {
        const qsToAdd = `${paramKey}[]=${param}`;
        if (index === 0 && !prepend) {
            qs = qsToAdd;
        } else if (index === 0 && prepend) {
            qs = `${prepend}${qsToAdd}`;
        } else {
            qs = `${qs}&${qsToAdd}`;
        }
    });

    return qs;
}

export default generateArrayQSParams;
