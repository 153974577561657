import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllEntities, BaseEntity } from 'c-sdk';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { NetworkRequestState } from '../Types';

const useCreateEntity = <RootState, Entity extends BaseEntity>(entityName: keyof AllEntities) => {
    const dispatch = useDispatch();
    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const selectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);

    const saveState = useSelector(selectors.creatingState);
    const saveError = useSelector(selectors.creatingError);
    const saveValidationErrors = useSelector(selectors.creatingValidationErrors);
    const newestCreatedId = useSelector(selectors.newestCreatedId);
    const { resetCreateState: Reset } = thunks;

    const createEntity = useCallback(
        (data: Partial<Entity>, includes?: string[]) => {
            if (saveState !== NetworkRequestState.InProgress) {
                dispatch(thunks.create(data, includes));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thunks, saveState],
    );

    const resetCreateState = useCallback(() => {
        dispatch(Reset());
    }, [Reset, dispatch]);

    const upsert = useCallback(
        (entity: Entity) => {
            dispatch(thunks.upsertEntity(entity));
        },
        [dispatch, thunks],
    );

    const upsertMany = useCallback(
        (entities: Entity[]) => {
            dispatch(thunks.upsertEntities(entities));
        },
        [dispatch, thunks],
    );

    return useMemo(
        () => ({
            saveState,
            saveError,
            saveValidationErrors,
            newestCreatedId,
            createEntity,
            resetCreateState,
            upsert,
            upsertMany,
        }),
        [
            saveState,
            saveError,
            saveValidationErrors,
            newestCreatedId,
            createEntity,
            resetCreateState,
            upsert,
            upsertMany,
        ],
    );
};

export default useCreateEntity;
