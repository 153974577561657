import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Campaign, PCAMapReportResponseMinimal } from 'c-sdk';
import { GoogleMap, HeatmapLayer, HeatmapLayerProps } from '@react-google-maps/api';
import {
    generateHeatMapData,
    generateMarkerLocations,
    getCampaignReportingMapCenter,
    getCampaignReportingMapZoom,
    MarkerLocation,
    ReportingMapOptions,
} from 'c-main/Lib';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { useEntityData } from 'c-data';

type Props = {
    id: number;
    data: PCAMapReportResponseMinimal['reportData'];
};

const containerStyle = {
    width: '100%',
    height: '80vh',
    borderRadius: '4px',
};

const ImpactsMapView: React.FC<Props> = ({ id, data }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const mapRef = useRef<google.maps.Map | null>();

    const boundingBoxData = useMemo<google.maps.LatLngBounds>(() => {
        const bounds = new google.maps.LatLngBounds();
        Object.keys(data).forEach(key => {
            const [actual, expected, performance, inSchedule, outSchedule, unbooked, lat, lng] =
                data[key];
            bounds.extend(new google.maps.LatLng(Number(lat), Number(lng)));
        });

        // If bounds are too small (i.e., single point or very close points)
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        // Calculate the current size
        const latDiff = Math.abs(ne.lat() - sw.lat());
        const lngDiff = Math.abs(ne.lng() - sw.lng());

        // If the area is too small, extend it
        const minSize = 1; // Adjust this value to control minimum map view size
        if (latDiff < minSize || lngDiff < minSize) {
            const center = bounds.getCenter();
            bounds.extend(
                new google.maps.LatLng(center.lat() + minSize / 2, center.lng() + minSize / 2),
            );
            bounds.extend(
                new google.maps.LatLng(center.lat() - minSize / 2, center.lng() - minSize / 2),
            );
        }

        return bounds;
    }, [data]);

    const heatMapData = useMemo<HeatmapLayerProps['data']>(() => {
        return generateHeatMapData(data, MapDataType.HeatActual);
    }, [data]);

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            mapRef.current = map;
            map.fitBounds(boundingBoxData);
        },
        [boundingBoxData],
    );

    useEffect(() => {
        const map = mapRef.current;
        if (map && boundingBoxData) {
            // Add a small delay to ensure map is ready
            setTimeout(() => {
                if (mapRef.current) {
                    // Check again in case map was unmounted
                    mapRef.current.fitBounds(boundingBoxData);
                }
            }, 100);
        }
    }, [boundingBoxData]);

    const onMapUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const markerLocations = useMemo<MarkerLocation[]>(
        () => generateMarkerLocations(data, MapDataType.HeatActual),
        [data],
    );
    const center = useMemo(() => getCampaignReportingMapCenter(campaign), [campaign]);
    const zoom = useMemo(() => getCampaignReportingMapZoom(campaign), [campaign]);
    const repositionedOnce = useRef(false);

    useEffect(() => {
        if (markerLocations.length > 0 && !repositionedOnce.current && mapRef?.current) {
            // basically repositioning the map on first load when the first set of markers (screens) are loaded
            const bounds = new google.maps.LatLngBounds();
            markerLocations.forEach(loc => {
                if (loc.location != null) bounds.extend(loc.location);
            });

            // setTimeout(() => mapRef?.current?.fitBounds?.(bounds), 1000);
            repositionedOnce.current = true;
        }
    }, [markerLocations]);

    const heatmapLayer = useMemo(
        () => (
            <HeatmapLayer
                data={heatMapData}
                options={{
                    radius: 25,
                    opacity: 0.8,
                    dissipating: true,
                }}
            />
        ),
        [heatMapData],
    );

    return (
        <>
            <GoogleMap
                ref={mapRef as any}
                onLoad={onMapLoad}
                onUnmount={onMapUnmount}
                // zoom={zoom}
                // center={center}
                mapContainerStyle={containerStyle}
                options={ReportingMapOptions}
            >
                {heatmapLayer}
            </GoogleMap>
        </>
    );
};

export default ImpactsMapView;
