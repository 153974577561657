import { Displays_Pack } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { DisplaysPackState } from './DisplaysPack-types';

const initialState: DisplaysPackState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: DisplaysPackState, action: PayloadAction<string>): DisplaysPackState => state,
};

const slice = createBaseSlice<Displays_Pack, DisplaysPackState, typeof additionalReducers>({
    name: 'displaysPack',
    entityName: 'Displays_Pack',
    initialState,
    reducers: additionalReducers,
});

const displaysPackSlice = {
    displaysPackActions: slice.slice.actions,
    displaysPackReducer: slice.slice.reducer,
    displaysPackAdapter: slice.adapter,
    displaysPackSelectors: slice.selectors,
    displaysPackThunks: slice.thunks,
};

export default displaysPackSlice;
export const {
    displaysPackReducer,
    displaysPackActions,
    displaysPackAdapter,
    displaysPackSelectors,
    displaysPackThunks,
} = displaysPackSlice;
