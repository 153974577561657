import React, { useCallback, useMemo } from 'react';
import {
    AutoGrid,
    Button,
    DateField,
    DateTimeField,
    Filterdropdown,
    OptionSchema,
    TextField,
} from 'c-components';
import {
    Badge,
    Box,
    Collapse,
    IconButton,
    InputAdornment,
    Stack,
    TextFieldProps,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    AllCreativeFilters,
    SetAspectRatios,
    SetDate,
    SetDuration,
    SetErrors,
    SetFileTypes,
    SetResolutions,
    SetSearchTerm,
} from 'c-main/Components/CreativeManagement/types';
import { CampaignErrorType, PermissionName } from 'c-sdk';
import { FilterAlt, Search } from '@mui/icons-material';
import { Translate, useCommonTranslation } from 'c-translation';
import { sortAspectRatios } from 'c-lib';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useTheme } from '@mui/styles';
import { useBoolean } from 'react-hanger';

type Props = {
    searchTerm: string;
    setSearchTerm: SetSearchTerm;
    filters: AllCreativeFilters;

    resolutions: string[];
    setResolutions: SetResolutions;

    aspectRatios: string[];
    setAspectRatios: SetAspectRatios;

    fileTypes: string[];
    setFileTypes: SetFileTypes;

    errorFilters: CampaignErrorType[];
    setErrors: SetErrors;

    duration?: string;
    setDuration: SetDuration;

    liveDate?: string;
    setLiveDate: SetDate;

    endDate?: string;
    setEndDate: SetDate;

    uploadDate?: string;
    setUploadDate: SetDate;
};

const dateInputProps: TextFieldProps = { size: 'small' };
const liveDateInputProps: TextFieldProps = { ...dateInputProps, id: 'live-date-filter' };
const endDateInputProps: TextFieldProps = { ...dateInputProps, id: 'end-date-filter' };
const dateAddedInputProps: TextFieldProps = { ...dateInputProps, id: 'date-added-date-filter' };

const errorsPerms = [PermissionName.UniledportalCampaign_errorRead];

const FiltersWrapper: React.FC<Props> = ({
    setSearchTerm,
    searchTerm,
    filters,

    resolutions,
    setResolutions,

    aspectRatios,
    setAspectRatios,

    fileTypes,
    setFileTypes,

    errorFilters,
    setErrors,

    duration,
    setDuration,

    liveDate,
    setLiveDate,

    endDate,
    setEndDate,

    uploadDate,
    setUploadDate,
}) => {
    const onSearchUpdated = useCallback(
        e => {
            setSearchTerm(e.target.value);
        },
        [setSearchTerm],
    );
    const onDurationUpdated = useCallback(
        e => {
            setDuration(e.target.value);
        },
        [setDuration],
    );

    const resolutionOptions = useMemo<OptionSchema[]>(
        () =>
            filters.resolutions
                .sort((a, b) => Number(a.split('x')?.[0] ?? '') - Number(b.split('x')?.[0] ?? ''))
                .map(res => ({ label: res, value: res })),
        [filters.resolutions],
    );

    const aspectRatioOptions = useMemo<OptionSchema[]>(
        () =>
            filters.aspectRatios
                .sort(sortAspectRatios)
                .map(aspect => ({ label: aspect, value: aspect })),
        [filters.aspectRatios],
    );

    const fileTypeOptions = useMemo<OptionSchema[]>(
        () => filters.fileTypes.map(type => ({ label: type, value: type })),
        [filters.fileTypes],
    );
    const filterOpenState = useBoolean(false);
    const t = useCommonTranslation();

    const errorOptions = useMemo<OptionSchema[]>(
        () =>
            filters.errors.map(errorType => ({
                label: t(
                    `Modules.Main.CreativeManagement.creatives.filters.errorLabels.${errorType}`,
                ),
                value: errorType,
            })),
        [filters.errors, t],
    );

    const theme = useTheme();
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));
    const durationInput = useMemo(
        () => (
            <TextField
                value={duration}
                placeholder={t('Modules.Main.CreativeManagement.creatives.filters.durationLabel')}
                label={t('Modules.Main.CreativeManagement.creatives.filters.durationLabel')}
                onChange={onDurationUpdated}
                variant="outlined"
                size="small"
                id="creative-duration-search"
            />
        ),
        [duration, onDurationUpdated, t],
    );
    const filterButton = useMemo(
        () => (
            <IconButton onClick={filterOpenState.toggle} className="filter-toggle">
                <FilterAlt fontSize="inherit" />
            </IconButton>
        ),
        [filterOpenState.toggle],
    );

    const resetFilters = useCallback(() => {
        setDuration('');
        setAspectRatios([]);
        setResolutions([]);
        setSearchTerm('');
        setErrors([]);
        setFileTypes([]);
        setLiveDate(null);
        setEndDate(null);
        setUploadDate(null);
    }, [
        setAspectRatios,
        setDuration,
        setEndDate,
        setErrors,
        setFileTypes,
        setLiveDate,
        setResolutions,
        setSearchTerm,
        setUploadDate,
    ]);

    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={resetFilters}>
                <Typography variant="caption">
                    <Translate path="Pagination.resetFilters" />
                </Typography>
            </Button>
        ),
        [resetFilters],
    );

    //
    // const [liveDate, setLiveDate] = useState<string>(null);
    // const [endDate, setEndDate] = useState<string>(null);
    // const [dateAdded, setDateAdded] = useState<string>(null);
    return (
        <>
            <AutoGrid spacing={2} xs={12} md={6} lg={4} xl={3}>
                <TextField
                    value={searchTerm}
                    placeholder={t(
                        'Modules.Main.CreativeManagement.creatives.filters.searchPlaceholder',
                    )}
                    onChange={onSearchUpdated}
                    variant="outlined"
                    size="small"
                    id="creative-name-search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{resetButton}</InputAdornment>,
                    }}
                />
                <Box ml={1}>{filterButton}</Box>
            </AutoGrid>
            <Collapse in={filterOpenState.value}>
                <AutoGrid spacing={2} xs={12} md={6} lg={4} xl={3} pt={2}>
                    <Filterdropdown
                        multiple
                        placeholder={t(
                            'Modules.Main.CreativeManagement.creatives.filters.resolutionsPlaceholder',
                        )}
                        options={resolutionOptions}
                        value={resolutions}
                        onChange={setResolutions}
                        id="creative-resolution-filter"
                    />
                    <Filterdropdown
                        multiple
                        placeholder={t(
                            'Modules.Main.CreativeManagement.creatives.filters.aspectRatiosPlaceholder',
                        )}
                        options={aspectRatioOptions}
                        value={aspectRatios}
                        onChange={setAspectRatios}
                        id="creative-aspect-ratio-filter"
                    />
                    <Stack direction="row" gap={2}>
                        <Filterdropdown
                            multiple
                            placeholder={t(
                                'Modules.Main.CreativeManagement.creatives.filters.fileTypesPlaceholder',
                            )}
                            options={fileTypeOptions}
                            value={fileTypes}
                            onChange={setFileTypes}
                            id="creative-file-type-filter"
                        />
                        {isXl && durationInput}
                    </Stack>
                    {!isXl && durationInput}
                    <IfHasAllPermissions permissions={errorsPerms}>
                        <Filterdropdown
                            multiple
                            placeholder={t(
                                'Modules.Main.CreativeManagement.creatives.filters.errorsPlaceholder',
                            )}
                            options={errorOptions}
                            value={errorFilters}
                            onChange={setErrors}
                            id="creative-error-filter"
                        />
                    </IfHasAllPermissions>
                    <DateField
                        label={t('Modules.Main.CreativeManagement.creatives.filters.liveDateLabel')}
                        value={liveDate}
                        onChange={setLiveDate}
                        inputProps={liveDateInputProps}
                    />
                    <DateField
                        label={t('Modules.Main.CreativeManagement.creatives.filters.endDateLabel')}
                        value={endDate}
                        onChange={setEndDate}
                        inputProps={endDateInputProps}
                    />
                    <DateTimeField
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.filters.dateAddedLabel',
                        )}
                        value={uploadDate}
                        onChange={setUploadDate}
                        inputProps={dateAddedInputProps}
                    />
                </AutoGrid>
            </Collapse>
        </>
    );
};

export default FiltersWrapper;
