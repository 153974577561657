import { Displays_Tag } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_TagState } from './Displays_Tag-types';

const initialState: Displays_TagState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_TagState, action: PayloadAction<string>): Displays_TagState => state,
};

const slice = createBaseSlice<Displays_Tag, Displays_TagState, typeof additionalReducers>({
    name: 'displays_Tag',
    entityName: 'Displays_Tag',
    initialState,
    reducers: additionalReducers,
});

const displays_TagSlice = {
    displays_TagActions: slice.slice.actions,
    displays_TagReducer: slice.slice.reducer,
    displays_TagAdapter: slice.adapter,
    displays_TagSelectors: slice.selectors,
    displays_TagThunks: slice.thunks,
};

export default displays_TagSlice;
export const {
    displays_TagReducer,
    displays_TagActions,
    displays_TagAdapter,
    displays_TagSelectors,
    displays_TagThunks,
} = displays_TagSlice;
