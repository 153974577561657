import { ascend, sort, toLower, uniq } from 'ramda';
import {
    ElasticSearchAvailableFilters,
    EsPCAReportFilterField,
    PCAMapReportPlayStats,
    PCAMapReportResponse,
    PCAReportField,
} from 'c-sdk';
import { isPlainObject } from '@reduxjs/toolkit';
import { sortByName } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes/sorting';
import { Direction } from 'c-types';

export type AllPCAFieldValues = Record<PCAReportField, string[]>;
export type AllEsPCAFieldValues = Record<EsPCAReportFilterField, string[]>;

export const allFieldValues = (report: PCAMapReportResponse): AllPCAFieldValues => {
    const sortFunc = ascend(toLower);

    const data = Object.keys(report.reportData).reduce(
        (acc, curr) => {
            const [
                actual,
                expected,
                percentage,
                inSchedule,
                outSchedule,
                unbooked,

                latitude,
                longitude,

                town,
                tvRegion,
                surfaceArea,
                conurbation,
                environment,
                format,
                lineItemName,
                mediaOwner,
                resolution,
            ] = report.reportData[curr] as PCAMapReportPlayStats;

            acc[PCAReportField.Town].push(town);
            acc[PCAReportField.TvRegion].push(tvRegion);
            acc[PCAReportField.Conurbation].push(conurbation);
            acc[PCAReportField.Environment].push(environment);
            acc[PCAReportField.Format].push(format);
            acc[PCAReportField.MediaOwner].push(mediaOwner);
            acc[PCAReportField.FrameId].push(curr);
            acc[PCAReportField.LineItemName].push(lineItemName);
            // acc[PCAReportField.SurfaceArea].push(surfaceArea);
            // acc[PCAReportField.Resolution].push(resolution);
            // acc[PCAReportField.CreativeName].push();
            // acc[PCAReportField.DayOfWeek].push();
            return acc;
        },
        {
            [PCAReportField.Town]: [],
            [PCAReportField.TvRegion]: [],
            [PCAReportField.Conurbation]: [],
            [PCAReportField.Environment]: [],
            [PCAReportField.Format]: [],
            [PCAReportField.MediaOwner]: [],
            [PCAReportField.FrameId]: [],
            [PCAReportField.LineItemName]: [],
            [PCAReportField.SurfaceArea]: [],
            [PCAReportField.CreativeName]: [],
            [PCAReportField.DayOfWeek]: [],
            [PCAReportField.Resolution]: [],
        } as AllPCAFieldValues,
    );

    return Object.keys(data).reduce((acc, curr) => {
        acc[curr as PCAReportField] = sort(
            sortFunc,
            uniq(data[curr as PCAReportField]),
        ) as string[];
        return acc;
    }, data);
};

export const allFieldValuesEs = (
    reportFilters: ElasticSearchAvailableFilters,
    selfRefine = false,
): AllEsPCAFieldValues => {
    const result: AllEsPCAFieldValues = {
        [EsPCAReportFilterField.CreativeName]: [],
        [EsPCAReportFilterField.DisplayTown]: [],
        [EsPCAReportFilterField.DisplayTvRegion]: [],
        [EsPCAReportFilterField.DisplayConurbation]: [],
        [EsPCAReportFilterField.DisplayEnv]: [],
        [EsPCAReportFilterField.DisplayFormat]: [],
        [EsPCAReportFilterField.DisplayOwner]: [],
        [EsPCAReportFilterField.DisplayType]: [],
        [EsPCAReportFilterField.DisplayResolution]: [],
        [EsPCAReportFilterField.FrameId]: [],
        [EsPCAReportFilterField.DisplayLineItemId]: [],
        [EsPCAReportFilterField.DisplayId]: [],
        [EsPCAReportFilterField.DisplayLineName]: [],
        [EsPCAReportFilterField.Schedule]: [],
        [EsPCAReportFilterField.Dow]: [],
        [EsPCAReportFilterField.Day]: [],
        [EsPCAReportFilterField.Hour]: [],
        [EsPCAReportFilterField.IssueId]: [],
    };

    // Early return if no filters
    if (!reportFilters) return result;

    for (const [filter, values] of Object.entries(reportFilters)) {
        if (!isPlainObject(values) || Object.keys(values).length === 0) {
            continue;
        }

        let validOptions: Array<{ name: string }>;

        if (!selfRefine) {
            // Simple case: just get all keys
            validOptions = Object.keys(values).map(key => ({ name: key }));
        } else {
            // Get only keys where value is true
            validOptions = Object.entries(values).reduce((acc, [key, value]) => {
                if (value === true) {
                    acc.push({ name: key });
                }
                return acc;
            }, [] as Array<{ name: string }>);

            // Skip if no valid options found
            if (validOptions.length === 0) {
                continue;
            }
        }

        // Use existing sortByName function
        result[filter] = sortByName(validOptions, Direction.ASC, 'name', filter, 'dd-MMM-yyyy').map(
            opt => opt.name,
        );
    }

    return result;
};
