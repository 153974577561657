import React, { useCallback, useMemo, useRef } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from 'c-sdk';
import { Avatar, Button, DialogV2 } from 'c-components';
import {
    AvatarGroup,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useBoolean } from 'react-hanger';
import { userInitials } from 'c-admin/Lib';
import AddIcon from '@mui/icons-material/Add';
import AdditionalContactDialogDisplay from 'c-main/Components/Campaign/CampaignTableCells/AdditionalUsers/AdditionalContactDialogDisplay';
import { copyTextToClipboard } from 'c-lib';
import { useCommonTranslation } from 'c-translation';

type Props = RenderColumnDataProps<Campaign>;
const AdditionalUsers: React.FC<Props> = ({ entity }) => {
    const anchorEl = useRef();
    const dialogOpenState = useBoolean(false);
    const menuOpenState = useBoolean(false);

    const additionalContacts = useMemo(
        () => entity.additionalContacts,
        [entity.additionalContacts],
    );
    const contactEmails = useMemo(
        () => additionalContacts.map(user => user.email).join(', '),
        [additionalContacts],
    );
    const copyEmails = useCallback(() => {
        copyTextToClipboard(contactEmails);
    }, [contactEmails]);
    const t = useCommonTranslation();

    const firstContact = additionalContacts[0];

    const addToContactsMenuItem = useMemo(
        () =>
            dialogOpenState.value === false ? null : (
                <>
                    <DialogV2
                        onClose={dialogOpenState.setFalse}
                        open={dialogOpenState.value}
                        title={t(
                            'Modules.Main.Campaigns.DataStatus.contacts.contactsDropDownLabel',
                        )}
                    >
                        <AdditionalContactDialogDisplay
                            additionalContacts={additionalContacts}
                            openDialog={dialogOpenState}
                            campaignId={entity.id}
                            buyerId={entity.buyer_id}
                        />
                    </DialogV2>
                </>
            ),
        [additionalContacts, dialogOpenState, entity.buyer_id, entity.id, t],
    );

    const ncAdditionalContactButton = useMemo(
        () => (
            <>
                <IconButton onClick={menuOpenState.setTrue} ref={anchorEl}>
                    <AddIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl.current}
                    open={menuOpenState.value}
                    onClose={menuOpenState.setFalse}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <MenuItem onClick={dialogOpenState.setTrue}>Edit Contacts</MenuItem>
                </Menu>
                {addToContactsMenuItem}
            </>
        ),
        [
            addToContactsMenuItem,
            dialogOpenState.setTrue,
            menuOpenState.setFalse,
            menuOpenState.setTrue,
            menuOpenState.value,
        ],
    );

    if (firstContact == null) {
        return ncAdditionalContactButton;
    }

    return (
        <>
            <Box
                onClick={menuOpenState.setTrue}
                ref={anchorEl}
                pr={2}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <AvatarGroup max={2}>
                    {additionalContacts.map(contact => (
                        <Avatar key={contact.id}>{userInitials(contact)}</Avatar>
                    ))}
                </AvatarGroup>
            </Box>
            <Menu
                anchorEl={anchorEl.current}
                open={menuOpenState.value}
                onClose={menuOpenState.setFalse}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <ListItem>
                    <ListItemText>
                        <Typography variant="caption">
                            {t('Modules.Main.Campaigns.DataStatus.contacts.otherContactsLabel')}
                        </Typography>
                    </ListItemText>
                    <ListItemText>
                        <Button size="small" variant="text" onClick={copyEmails}>
                            {t('Modules.Main.Campaigns.DataStatus.contacts.copyEmailsLabel')}
                        </Button>
                    </ListItemText>
                    <IconButton onClick={copyEmails}>
                        <ContentCopyIcon sx={{ maxHeight: '14px' }} />
                    </IconButton>
                </ListItem>
                {additionalContacts.map(contact => (
                    <MenuItem key={contact.id} onClick={menuOpenState.setFalse}>
                        <ListItemAvatar>
                            <Avatar>{userInitials(contact)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={contact.name} secondary={contact.email} />
                    </MenuItem>
                ))}
                <MenuItem onClick={dialogOpenState.setTrue}>
                    {t('Modules.Main.Campaigns.DataStatus.contacts.contactsDropDownLabel')}
                </MenuItem>
            </Menu>
            {addToContactsMenuItem}
        </>
    );
};

export default AdditionalUsers;
