import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { BaseEntity } from 'c-sdk';
import { useDateUtils } from 'c-hooks';
import { DateColumnProps } from 'c-pagination/types';

type Props<Entity> = { value: string; dateProps: DateColumnProps };

function EntityDataDateColumn<Entity extends BaseEntity>({ value, dateProps }: Props<Entity>) {
    const { formatDateString, formatDateStringIgnoreTime } = useDateUtils();
    const dateString = useMemo(() => {
        if (!dateProps?.showTime) {
            return formatDateStringIgnoreTime({ date: value });
        }
        return formatDateString({ date: value, removeTime: !dateProps?.showTime });
    }, [dateProps?.showTime, formatDateString, formatDateStringIgnoreTime, value]);

    return <Box sx={{ whiteSpace: 'nowrap' }}>{dateString}</Box>;
}

export default EntityDataDateColumn;
