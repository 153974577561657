import { Displays_LineItem } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_LineItemState } from './DisplaysLineItems-types';

const initialState: Displays_LineItemState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_LineItemState, action: PayloadAction<string>): Displays_LineItemState => state,
};

const slice = createBaseSlice<Displays_LineItem, Displays_LineItemState, typeof additionalReducers>(
    {
        name: 'displaysLineItems',
        entityName: 'Displays_LineItem',
        initialState,
        reducers: additionalReducers,
    },
);

const displaysLineItemsSlice = {
    displaysLineItemsActions: slice.slice.actions,
    displaysLineItemsReducer: slice.slice.reducer,
    displaysLineItemsAdapter: slice.adapter,
    displaysLineItemsSelectors: slice.selectors,
    displaysLineItemsThunks: slice.thunks,
};

export default displaysLineItemsSlice;
export const {
    displaysLineItemsReducer,
    displaysLineItemsActions,
    displaysLineItemsAdapter,
    displaysLineItemsSelectors,
    displaysLineItemsThunks,
} = displaysLineItemsSlice;
