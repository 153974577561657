import { createSelector, EntityState } from '@reduxjs/toolkit';
import { BaseEntity } from 'c-sdk';
import { NetworkRequestState } from '../../Types/network';
import { emptyObjects } from '../../lib';
import {
    BaseSliceEntityAdapter,
    BaseSliceInitialState,
    BaseSliceSelectors,
    EntityStores,
    SlicePaginationData,
} from '../../Types/slices';

const selectors = <Entity extends BaseEntity>(
    adapter: BaseSliceEntityAdapter<Entity>,
    name: keyof EntityStores,
): BaseSliceSelectors<Entity> => {
    const selectEntitySliceReduxToolkit = createSelector(
        (state: EntityState<Entity>) => state[name],
        state => state as EntityState<Entity>,
    );

    const selectEntitySlice = createSelector(
        (state: EntityStores) => state[name],
        state => state as BaseSliceInitialState<Entity>,
    );
    const selectEntitySliceEntities = createSelector(selectEntitySlice, state => state.entities);

    const loadingByIdMap = createSelector(
        (state: EntityStores) => selectEntitySlice(state).loadingById,
        loading => loading,
    );
    const loadingById = createSelector(
        loadingByIdMap,
        (state: EntityStores, id: number) => id,
        (loading, id) => loading[id] ?? { state: NetworkRequestState.Idle },
    );

    const updatingByIdMap = createSelector(
        (state: EntityStores) => selectEntitySlice(state).updatingById,
        loading => loading,
    );
    const updatingById = createSelector(
        updatingByIdMap,
        (state: EntityStores, id: number) => id,
        (updating, id) => updating[id] ?? { state: NetworkRequestState.Idle },
    );

    const selectByIds = createSelector(
        selectEntitySliceEntities,
        (state, ids: number[]) => ids,
        (entities, ids) => ids.map(id => entities[id]),
    );

    const deletingByIdMap = createSelector(
        (state: EntityStores) => selectEntitySlice(state).deletingById,
        loading => loading,
    );
    const deletingById = createSelector(
        deletingByIdMap,
        (state: EntityStores, id: number) => id,
        (deleting, id) => deleting[id] ?? { state: NetworkRequestState.Idle },
    );

    const creating = createSelector(
        (state: EntityStores) => selectEntitySlice(state).create,
        state => state ?? { state: NetworkRequestState.Idle },
    );

    const paginationData = createSelector(
        (state: EntityStores, tag: string) =>
            selectEntitySlice(state).pagination[tag] ?? emptyObjects.slicePaginationData(),
        data => data as SlicePaginationData<Entity>,
    );

    const paginationDataFilters = createSelector(paginationData, data => data.filters ?? {});
    const paginationDataFiltersByKey = createSelector(
        (state: EntityStores, tag: string, filterKey: string) => filterKey,
        paginationDataFilters,
        (filterKey, filters) => filters?.[filterKey],
    );

    return {
        ...adapter.getSelectors(selectEntitySliceReduxToolkit),

        loadingByIdMap,
        loadingById,
        loadingByIdState: createSelector(
            (state: EntityStores, id: number) => loadingById(state, id),
            networkState => networkState.state,
        ),
        loadingByIdError: createSelector(
            (state: EntityStores, id: number) => loadingById(state, id),
            networkState => networkState.error,
        ),

        updatingByIdMap,
        updatingById,
        updatingByIdState: createSelector(
            (state: EntityStores, id: number) => updatingById(state, id),
            networkState => networkState.state,
        ),
        updatingByIdError: createSelector(
            (state: EntityStores, id: number) => updatingById(state, id),
            networkState => networkState.error,
        ),
        selectByIds,
        deletingByIdMap,
        deletingById,
        deletingByIdState: createSelector(
            (state: EntityStores, id: number) => deletingById(state, id),
            networkState => networkState.state,
        ),
        deletingByIdError: createSelector(
            (state: EntityStores, id: number) => deletingById(state, id),
            networkState => networkState.error,
        ),

        creating,
        creatingState: createSelector(creating, state => state.state),
        creatingError: createSelector(creating, state => state.error),
        creatingValidationErrors: createSelector(creating, state => state.validation ?? {}),
        newestCreatedId: createSelector(selectEntitySlice, state => state.newestCreatedId),

        paginationData,
        paginationDataFilters,
        paginationDataFilterValue: paginationDataFiltersByKey,
    };
};

export default selectors;
