import React, { useMemo } from 'react';
import { useEntityData } from 'c-data';
import { Box, IconButton } from '@mui/material';
import { editIcon } from 'c-main/icons';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    OptionSchema,
    RadioGroupField,
    TextField,
    UpdateEntityDialog,
} from 'c-components';
import { pick } from 'ramda';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import { useWatch } from 'react-hook-form';
import { BuyerReferenceInput } from 'c-main/Components';
import { BuyerReferenceType, Buyer } from 'c-sdk';

type Props = {
    buyer: Buyer;
};

const transformData = (data: Partial<Buyer>) => {
    const postData: Partial<Buyer> = {
        ...data,
        reference_min_length: data.reference_min_length ? +data.reference_min_length : null,
        reference_max_length: data.reference_max_length ? +data.reference_max_length : null,
    };

    if ((data.reference_min_length as unknown as string) === '') {
        postData.reference_min_length = null;
    }
    if ((data.reference_max_length as unknown as string) === '') {
        postData.reference_min_length = null;
    }
    if ((data.reference_prefix as unknown as string) === '') {
        postData.reference_prefix = null;
    }
    if ((data.reference_type as unknown as string) === '') {
        postData.reference_type = null;
    }

    return postData;
};

const BuyerOverviewEdit: React.FC<Props> = ({ buyer }) => {
    const editModal = useBoolean(false);
    const defaultData = useMemo(
        () =>
            pick(
                [
                    'id',
                    'name',
                    'market_id',
                    'reference_prefix',
                    'reference_type',
                    'reference_min_length',
                    'reference_max_length',
                ],
                buyer,
            ),
        [buyer],
    );

    return (
        <Box display="flex" alignItems="center" height="100%">
            <IconButton onClick={editModal.setTrue}>{editIcon}</IconButton>
            <UpdateEntityDialog<Buyer>
                entityName="Buyer"
                entityId={buyer.id}
                title="Modules.Main.Buyer.Overview.EditDialog.title"
                defaultData={defaultData}
                show={editModal.value}
                form={<EditBuyerForm />}
                onClose={editModal.setFalse}
                transformData={transformData}
            />
        </Box>
    );
};

const EditBuyerForm = () => {
    const t = useCommonTranslation();
    const typeOpts = useMemo<OptionSchema[]>(
        () => [
            {
                label: t(
                    `Modules.Main.Buyer.Overview.EditDialog.refTypes.${BuyerReferenceType.Any}`,
                ),
                value: BuyerReferenceType.Any,
            },
            {
                label: t(
                    `Modules.Main.Buyer.Overview.EditDialog.refTypes.${BuyerReferenceType.Alpha}`,
                ),
                value: BuyerReferenceType.Alpha,
            },
            {
                label: t(
                    `Modules.Main.Buyer.Overview.EditDialog.refTypes.${BuyerReferenceType.Alphanumeric}`,
                ),
                value: BuyerReferenceType.Alphanumeric,
            },
            {
                label: t(
                    `Modules.Main.Buyer.Overview.EditDialog.refTypes.${BuyerReferenceType.Numeric}`,
                ),
                value: BuyerReferenceType.Numeric,
            },
        ],
        [t],
    );

    return (
        <AutoGrid spacing={2} xs={12}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Buyer.Overview.EditDialog.nameLabel')}
                    />
                )}
                name="name"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Market"
                        labelField="name"
                        label={t('Modules.Main.Buyer.Overview.EditDialog.marketLabel')}
                        {...field}
                    />
                )}
                name="market_id"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Buyer.Overview.EditDialog.refPrefixLabel')}
                    />
                )}
                name="reference_prefix"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <RadioGroupField
                        {...field}
                        label={t('Modules.Main.Buyer.Overview.EditDialog.refTypeLabel')}
                        options={typeOpts}
                    />
                )}
                name="reference_type"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        type="number"
                        label={t('Modules.Main.Buyer.Overview.EditDialog.refMinLengthLabel')}
                    />
                )}
                name="reference_min_length"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        type="number"
                        label={t('Modules.Main.Buyer.Overview.EditDialog.refMaxLengthLabel')}
                    />
                )}
                name="reference_max_length"
            />
            <ReferencePreview />
        </AutoGrid>
    );
};

const ReferencePreview = () => {
    // const { watch } = useFormContext<Buyer>();
    const allValues = useWatch();
    // const [value, setValue] = useState('');
    //
    // useEffect(() => {
    //     setValue('');
    // }, [allValues]);
    return (
        <BuyerReferenceInput
            id="ref-input-preview"
            key={JSON.stringify(allValues)}
            label={useCommonTranslation('Modules.Main.Buyer.Overview.EditDialog.refPreviewLabel')}
            buyer={transformData(allValues as unknown as Buyer) as Buyer}
            // value={value}
            // onChange={setValue}
        />
    );
};

const BuyerOverviewEditWrapper: React.FC<{ id: number }> = ({ id }) => {
    const { getById } = useEntityData<Buyer>('Buyer');
    const buyer = getById({ id });

    return buyer && <BuyerOverviewEdit buyer={buyer} />;
};

export default BuyerOverviewEditWrapper;
