import React, { useCallback, useMemo, useState } from 'react';
import { Skin } from 'c-entity-types';
import { AutoGrid, Button, CollapsibleCard, EntityAutocomplete, FlashMessage } from 'c-components';
import { Box } from '@mui/material';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from 'c-sdk';
import { useEntityData } from 'c-data';
import { Translate, TranslationPath } from 'c-translation';
import { useDispatch, useSelector } from 'react-redux';
import { skinSelectors, skinThunks } from 'c-admin/Slices';
import { NetworkRequestState } from 'c-data-layer';
import UserEntityAutocomplete from 'c-admin/Components/User/UserEntityAutocomplete';

type Props = {
    title: TranslationPath;
    skinId: number;
};

const SkinUserAssign: React.FC<Props> = ({ title, skinId }) => {
    const { hasAll } = useUserPermissions();
    const canEditAgencies = useMemo(
        () => hasAll([PermissionName.UniledportalAgencyRead]),
        [hasAll],
    );
    const canEditBuyers = useMemo(() => hasAll([PermissionName.UniledportalBuyerRead]), [hasAll]);
    const canEditClients = useMemo(() => hasAll([PermissionName.UniledportalClientRead]), [hasAll]);
    const canEditOwners = useMemo(() => hasAll([PermissionName.UniledportalOwnerRead]), [hasAll]);
    const canEditUsers = useMemo(() => hasAll([PermissionName.UniledportalUserRead]), [hasAll]);

    const { getById } = useEntityData<Skin>('Skin');
    const skin = getById({ id: skinId });

    const dispatch = useDispatch();
    const [agencies, setAgencies] = useState([...(skin.agencies ?? [])]);
    const [buyers, setBuyers] = useState([...(skin.buyers ?? [])]);
    const [clients, setClients] = useState([...(skin.clients ?? [])]);
    const [owners, setOwners] = useState([...(skin.owners ?? [])]);
    const [users, setUsers] = useState([...(skin.users ?? [])]);

    const { state, error } = useSelector(skinSelectors.getAttachingSkinById)(skinId);

    const saveAssignment = useCallback(() => {
        const data: Pick<Skin, 'agencies' | 'buyers' | 'clients' | 'owners' | 'users'> = {};
        if (canEditAgencies) data.agencies = agencies;
        if (canEditBuyers) data.buyers = buyers;
        if (canEditClients) data.clients = clients;
        if (canEditOwners) data.owners = owners;
        if (canEditUsers) data.users = users;

        dispatch(skinThunks.attachSkin(skinId, data));
    }, [
        agencies,
        buyers,
        canEditAgencies,
        canEditBuyers,
        canEditClients,
        canEditOwners,
        canEditUsers,
        clients,
        dispatch,
        owners,
        skinId,
        users,
    ]);

    const submitButton = useMemo(
        () => (
            <>
                {error != null && <FlashMessage status="error">{error}</FlashMessage>}
                <Button
                    onClick={saveAssignment}
                    disabled={state === NetworkRequestState.InProgress}
                >
                    <Translate path="Modules.Admin.SkinEdit.form.assignSkin" />
                </Button>
            </>
        ),
        [error, saveAssignment, state],
    );

    return (
        <CollapsibleCard title={title}>
            <AutoGrid spacing={2} xs={12} md={4} p={2}>
                {canEditAgencies && (
                    <Box>
                        <Box>
                            <EntityAutocomplete
                                onChange={setAgencies}
                                multiple
                                value={agencies}
                                entityName="Agency"
                                textFieldProps={{
                                    label: (
                                        <Translate path="Modules.Admin.SkinEdit.form.agencyAssignLabel" />
                                    ) as unknown as string,
                                }}
                                searchColumns="name"
                                labelColumn="name"
                            />
                        </Box>
                    </Box>
                )}
                {canEditBuyers && (
                    <Box>
                        <Box>
                            <EntityAutocomplete
                                onChange={setBuyers}
                                multiple
                                value={buyers}
                                entityName="Buyer"
                                textFieldProps={{
                                    label: (
                                        <Translate path="Modules.Admin.SkinEdit.form.buyerAssignLabel" />
                                    ) as unknown as string,
                                }}
                                searchColumns="name"
                                labelColumn="name"
                            />
                        </Box>
                    </Box>
                )}
                {canEditClients && (
                    <Box>
                        <Box>
                            <EntityAutocomplete
                                onChange={setClients}
                                multiple
                                value={clients}
                                entityName="Client"
                                textFieldProps={{
                                    label: (
                                        <Translate path="Modules.Admin.SkinEdit.form.clientAssignLabel" />
                                    ) as unknown as string,
                                }}
                                searchColumns="name"
                                labelColumn="name"
                            />
                        </Box>
                    </Box>
                )}
                {canEditOwners && (
                    <Box>
                        <EntityAutocomplete
                            onChange={setOwners}
                            multiple
                            value={owners}
                            entityName="Displays_Owner"
                            textFieldProps={{
                                label: (
                                    <Translate path="Modules.Admin.SkinEdit.form.ownerAssignLabel" />
                                ) as unknown as string,
                            }}
                            searchColumns="name"
                            labelColumn="name"
                        />
                    </Box>
                )}
                {canEditUsers && (
                    <Box>
                        <UserEntityAutocomplete
                            onChange={setUsers}
                            multiple
                            value={users}
                            textFieldProps={{
                                label: (
                                    <Translate path="Modules.Admin.SkinEdit.form.userAssignLabel" />
                                ) as unknown as string,
                            }}
                        />
                    </Box>
                )}
            </AutoGrid>
            <Box mt={2} pl={2}>
                {submitButton}
            </Box>
        </CollapsibleCard>
    );
};

export default SkinUserAssign;
