import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Role } from 'c-entity-types';
import { Permission } from 'c-sdk';
import { Button, Checkbox, TextField } from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Stack, Tooltip } from '@mui/material';
import { useAllEntityData, useUpdateEntityData } from 'c-data';
import { groupedPermissions, permissionDiffs } from 'c-admin/Lib';
import { PermissionNamespace } from 'c-admin';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelectors, roleThunks } from 'c-admin/Slices/Role';
import { NetworkRequestState } from 'c-data-layer';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import PermissionsNamespaceGroups from './PermissionsNamespaceGroups';
import PermissionDiffDialog from './PermissionDiffDialog';

type Props = {
    role: Role;
};

const RoleEditWrapper: React.FC<Props> = ({ role }) => {
    const dispatch = useDispatch();
    const { getUpdatingById } = useUpdateEntityData<Role>('Role');
    const { getAll, allEntities } = useAllEntityData<Permission>('Permission');
    const [roles, setRoles] = useState([]);
    const grouped = useMemo(
        () => groupedPermissions(allEntities ?? [], [PermissionNamespace.Reporting]),
        [allEntities],
    );
    const permissionValues = useSelector(roleSelectors.getPermissionsDictionary);

    const { start, data } = useAPIClientRequest(ApiClient.Entities.Role.list);
    useEffect(() => {
        start({ perPage: 99999 });
    }, [start]);
    useEffect(() => {
        if (data?.data?.data) {
            setRoles(data?.data?.data);
        }
    }, [data]);
    const processedRoles = useMemo(() => {
        if (!roles) return [];
        return roles
            .filter(role => role.invite_type.campaign_agency === true)
            .map(role => ({
                id: role.id,
                invited: true,
            }));
    }, [roles]);
    const isDisabled = useMemo(() => {
        if (!data?.data?.data) return true;
        if (processedRoles.length === 0) {
            return false;
        }
        return role.id !== processedRoles[0].id;
    }, [data?.data?.data, processedRoles, role.id]);

    useEffect(() => {
        getAll(undefined, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            roleThunks.setRoleEditingPermissions(
                (role.permissions ?? []).reduce((acc, curr) => {
                    acc[curr] = true;
                    return acc;
                }, {} as Record<string, boolean>),
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    /**
     * Not using React Hook Form for this form because the performance is really bad with several hundred checkboxes
     * because the onChange function provided changes references
     */

    const [name, setName] = useState(role?.name);
    const [campaignAgencyInvite, setCampaignAgencyInvite] = useState(
        role?.invite_type?.campaign_agency,
    );
    useEffect(() => {
        setName(role.name);
        setCampaignAgencyInvite(role.invite_type.campaign_agency);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);
    const onNameChange = useCallback(e => setName(e.target.value), [setName]);

    const saveOpen = useBoolean(false);

    const state = getUpdatingById(role.id);

    const diff = useMemo(
        () =>
            permissionDiffs(
                permissionValues,
                allEntities.map(p => p.id),
                role.permissions,
            ),
        [permissionValues, role.permissions, allEntities],
    );

    const saveDisabled = useMemo(() => {
        // creating a bunch of variables for clarity.
        const someDiff = diff.added.length > 0 || diff.removed.length > 0;
        const loading = state.state === NetworkRequestState.InProgress;
        const nameChanged = name !== role?.name;
        const inviteChanged = campaignAgencyInvite !== role?.invite_type?.campaign_agency;

        return !(someDiff || loading || nameChanged || inviteChanged);
    }, [
        diff,
        role?.name,
        name,
        role?.invite_type?.campaign_agency,
        campaignAgencyInvite,
        state.state,
    ]);

    const btn = useMemo(
        () => (
            <Box mb={2}>
                <Button onClick={saveOpen.setTrue} disabled={saveDisabled}>
                    <Translate path="Modules.Admin.RoleEdit.saveLabel" />
                </Button>
            </Box>
        ),
        [saveOpen.setTrue, saveDisabled],
    );

    const nameLabel = useCommonTranslation('Modules.Admin.RoleEdit.nameInputLabel');
    const agencyCheckboxLabel = useCommonTranslation('Modules.Admin.RoleEdit.agencyCheckboxLabel');
    const agencyCheckboxExplanation = useCommonTranslation(
        'Modules.Admin.RoleEdit.agencyCheckboxExplanation',
    );
    return (
        <>
            {saveOpen.value && (
                <PermissionDiffDialog
                    onClose={saveOpen.setFalse}
                    role={role}
                    name={name}
                    campaignAgencyInvite={campaignAgencyInvite}
                />
            )}
            <Card
                title={useCommonTranslation('Modules.Admin.RoleEdit.pageTitle', {
                    name: `'${role?.name}'`,
                })}
            >
                <CardContent>
                    {allEntities.length > 0 && (
                        <PermissionsNamespaceGroups
                            grouped={grouped}
                            role={role}
                            nameInput={
                                <Stack gap={2}>
                                    <TextField
                                        label={nameLabel}
                                        onChange={onNameChange}
                                        value={name}
                                    />
                                    <Checkbox
                                        isBoolean
                                        onChange={setCampaignAgencyInvite}
                                        disabled={isDisabled}
                                        label={
                                            <Stack direction="row" alignItems="center" gap={0.5}>
                                                {agencyCheckboxLabel}
                                                <Tooltip title={agencyCheckboxExplanation}>
                                                    <InfoOutlined fontSize="inherit" />
                                                </Tooltip>
                                            </Stack>
                                        }
                                        value={campaignAgencyInvite}
                                    />
                                </Stack>
                            }
                            submitButton={btn}
                        />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default RoleEditWrapper;
