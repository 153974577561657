import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Displays_LineItem, PermissionName } from 'c-sdk';
import { CircularProgress, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { userIcon } from 'c-main/icons';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from 'c-data-layer';
import to from 'await-to-js';
import { CopyButton, ListItemIcon, ListItemText } from 'c-components';
import { UserAvatar } from 'c-admin/Components';

type Props = RenderColumnDataProps<Displays_LineItem>;

const adminPerms = [PermissionName.Admin];

const LineItemOwnerCell: React.FC<Props> = ({ entity, defaultContent }) => (
    <Stack gap={1} direction="row" alignItems="center">
        {defaultContent}
        <IfHasAllPermissions permissions={adminPerms}>
            <UserDropdown ownerId={entity.owner_id} />
        </IfHasAllPermissions>
    </Stack>
);

const UserDropdown: React.FC<{ ownerId: number }> = ({ ownerId }) => {
    const btnRef = useRef();
    const menu = useBoolean(false);
    const loadedOnce = useRef(false);

    const { start, data, isLoading, hasFailed, requestStateRef } = useAPIClientRequest(
        apiClient.Entities.Displays_Owner.get,
    );

    const loadUsers = useCallback(async () => {
        if (
            requestStateRef.current === NetworkRequestState.InProgress ||
            loadedOnce.current === true
        )
            return;

        const [err] = await to(start(ownerId, ['users']));
        if (!err) {
            loadedOnce.current = true;
        }
    }, [ownerId, requestStateRef, start]);

    const users = useMemo(() => data?.data?.data?.users ?? [], [data]);

    useEffect(() => {
        if (menu.value) loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.value]);

    return (
        <>
            <Menu
                open={menu.value}
                anchorEl={btnRef.current}
                onClose={menu.setFalse}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {isLoading && (
                    <MenuItem sx={{ textAlign: 'center' }}>
                        <CircularProgress size={15} />
                    </MenuItem>
                )}
                {users.map(user => (
                    <MenuItem key={user.id}>
                        <ListItemIcon>
                            <UserAvatar size={4} user={user} />
                        </ListItemIcon>
                        <ListItemText primary={user.name} secondary={user.email} />
                        <CopyButton copyContent={user.email} />
                    </MenuItem>
                ))}
            </Menu>
            <IconButton
                ref={btnRef}
                onClick={menu.setTrue}
                size="small"
                color={hasFailed ? 'error' : 'primary'}
            >
                {userIcon}
            </IconButton>
        </>
    );
};

export default LineItemOwnerCell;
