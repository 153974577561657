import React, { useCallback, useMemo } from 'react';
import { Displays_LineItem, FrameValidationResponse } from 'c-sdk';
import { Alert, AutoGrid, Checkbox, ListItemIcon, ListItemText } from 'c-components';
import { Box, ListItem, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { getHumanReadableLineItemResolutions } from 'c-main/Components/CreativeManagement/lib';

type Props = {
    conflicts: FrameValidationResponse['conflicts'];
    lineItems: Displays_LineItem[];

    selectedConflicts: Record<string, string>;
    onSelectConflict: (frameId: string, lineItemId: string) => void;
};

const BulkAttachConflicts: React.FC<Props> = ({
    conflicts,
    lineItems,
    selectedConflicts,
    onSelectConflict,
}) => {
    const onChecked = useCallback(
        (frameId: string, lineItemId: string) => {
            onSelectConflict(frameId, lineItemId);
        },
        [onSelectConflict],
    );
    const t = useCommonTranslation();
    return (
        <Box textAlign="left">
            <Alert severity="info">
                {t('Modules.Main.Campaigns.Create.bulkAttachConflictDescription')}
            </Alert>
            {conflicts.map(conflictGroup => {
                return (
                    <Box key={conflictGroup.vendor_id}>
                        <Typography variant="body1">
                            {t('Modules.Main.Campaigns.Create.bulkAttachConflictFrameLabel')}:{' '}
                            {conflictGroup.vendor_id}
                        </Typography>
                        <Box>
                            {conflictGroup.options.map(opt => (
                                <ConflictItem
                                    key={opt.line_item_id}
                                    frameId={conflictGroup.vendor_id}
                                    selected={
                                        selectedConflicts?.[conflictGroup.vendor_id] ===
                                        opt.line_item_id
                                    }
                                    onSelectConflict={onChecked}
                                    lineItem={lineItems.find(line => line.id === opt.line_item_id)}
                                />
                            ))}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

type ConflictProps = {
    frameId: string;
    selected: boolean;
    onSelectConflict: (frameId, lineItemId: string) => void;
    lineItem: Displays_LineItem;
};
const ConflictItem: React.FC<ConflictProps> = ({
    frameId,
    selected,
    onSelectConflict,
    lineItem,
}) => {
    const onChange = useCallback(() => {
        if (selected) {
            onSelectConflict(frameId, undefined);
            return;
        }
        onSelectConflict(frameId, lineItem?.id);
    }, [onSelectConflict, frameId, lineItem, selected]);
    const resolutions = useMemo(() => getHumanReadableLineItemResolutions([lineItem]), [lineItem]);
    return (
        <>
            <ListItem disablePadding key={lineItem.id}>
                <ListItemIcon>
                    <Checkbox isBoolean onChange={onChange} checked={selected} label="" />
                </ListItemIcon>
                <ListItemText
                    primary={lineItem.name}
                    secondary={
                        <AutoGrid gap={2}>
                            <>{lineItem.owner?.name}</>
                            <>{resolutions}</>
                            <>{lineItem.size}</>
                        </AutoGrid>
                    }
                />
            </ListItem>
        </>
    );
};

export default BulkAttachConflicts;
