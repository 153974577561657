import { Dispatch } from 'react';
import { BaseEntity } from 'c-sdk';
import { CoreThunk } from '../../Types/CoreThunk';
import { SearchPayload } from '../../Types/Search';
import { CustomListSearchGenerator, NetworkRequestState } from '../../Types';

const hookUtilGetPaginatedList =
    <RootState, Entity extends BaseEntity>(
        thunk: (
            tag: string,
            searchPayload: SearchPayload<Entity>,
            asCursorPaginated?: boolean,
            customSearch?: CustomListSearchGenerator,
        ) => CoreThunk<RootState>,
    ) =>
    (
        tag: string,
        forceRefresh: boolean,
        searchPayload: SearchPayload<Entity>,
        dispatch: Dispatch<any>,
        asCursorPaginated = false,
        customSearch?: CustomListSearchGenerator,
        currentMetaPage?: number,
        currentLoading?: NetworkRequestState,
    ) => {
        if (
            (currentMetaPage !== searchPayload.page || forceRefresh) &&
            currentLoading !== NetworkRequestState.InProgress
        ) {
            dispatch(thunk(tag, searchPayload, asCursorPaginated, customSearch));
        }
    };

export default hookUtilGetPaginatedList;
