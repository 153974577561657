import { CaseReducerActions, PayloadAction } from '@reduxjs/toolkit';
import { AllEntities, BaseEntity } from 'c-sdk';
import { BaseSliceInitialState, BaseSliceSelectors } from '../Types';
import { BaseSliceThunks } from '../Types/thunks';

export type EntityConfig<RootState, Entity extends BaseEntity> = {
    entityName: string;
    actions: CaseReducerActions<
        {
            upsertEntities: (
                state: BaseSliceInitialState<Entity>,
                action: PayloadAction<Entity[]>,
            ) => BaseSliceInitialState<Entity>;
        },
        any
    >;
    selectors: BaseSliceSelectors<Entity>;
    thunks?: BaseSliceThunks<RootState, Entity>;
};

export type EntitySchemas<RootState, AllEntities extends { [key: string]: BaseEntity }> = {
    [key in keyof AllEntities]: EntityConfig<RootState, AllEntities[key]>;
};

const EntitySchemaActions: EntitySchemas<any, any> = {};

export const getBaseSliceThunks = <RootState, Entity extends BaseEntity>(
    entityName: keyof AllEntities,
) =>
    EntitySchemaActions[entityName as string]?.thunks as unknown as BaseSliceThunks<
        RootState,
        Entity
    >;

export const getBaseSliceSelectors = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    EntitySchemaActions[entityName as string]?.selectors as unknown as BaseSliceSelectors<Entity>;

export const getEntitySchemaActions = <AllEntities>(entityName: keyof AllEntities) =>
    EntitySchemaActions[entityName as string];

export { EntitySchemaActions };
