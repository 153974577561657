import { BaseEntity } from 'c-sdk';
import { EntityDataPath, EntityListColumn } from 'c-pagination/types';
import { EntityDataColumn } from '../Components/EntityDataColumn';

function generateColumn<Entity extends BaseEntity, RelationEntity extends BaseEntity = Entity>({
    ...rest
}: EntityListColumn<Entity, RelationEntity> & {
    field?: EntityDataPath<Entity> | EntityDataPath<RelationEntity>;
}): EntityListColumn<Entity, RelationEntity> {
    return {
        RenderCell: EntityDataColumn,
        dataType: 'string',
        textAlign: 'left',
        ...rest,
    };
}

export default generateColumn;
