import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEntityData } from 'c-data';
import { useCommonTranslation } from 'c-translation';
import { Alert, Button } from 'c-components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { AddCircle } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { CampaignErrorableType, CampaignErrorType, PermissionName, Campaign } from 'c-sdk';
import { StaticCreativeGroup } from './lib';
import useCampaignCreatives from './useCampaignCreatives';
import GroupWrapper from './Components/Groups/GroupWrapper';
import CreativesWrapper from './Components/Creatives/CreativesWrapper';
import FiltersWrapper from './Components/Filters/FiltersWrapper';
import AutoGroupsDialog from './Components/Groups/AutoGroupsDialog';
import CampaignErrorList from '../Campaign/CampaignError/CampaignErrorList';

type Props = {
    id: number;
};

const CreateGroupPerms: PermissionName[] = [PermissionName.UniledportalCreative_groupCreate];
const filtersGridItemSx = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
};
const cardContentSx = { height: '85vh', overflow: 'hidden', display: 'flex' };
const gridContainerSx = { height: '100%' };
const groupWrapperGridItemSx = { height: '100%', display: 'flex' };

const CampaignCreativeManagement: React.FC<Props> = ({ id }) => {
    const {
        resetCreativesData,

        getCreatives,
        creatives,
        openGroup,
        setOpenGroup,

        addGroup,

        updateGroup,
        updateCreatives,

        selectedCreatives,
        setSelectedCreatives,

        filteredCreatives,
        allFilters,
        debouncedFilters,

        searchTerm,
        setSearchTerm,

        resolutionFilters,
        setResolutionFilters,

        aspectRatioFilters,
        setAspectFilters,

        fileTypeFilters,
        setFileTypeFilters,

        errorFilters,
        setErrorFilters,

        durationFilter,
        setDurationFilter,

        liveDateFilter,
        setLiveDateFilter,

        endDateFilter,
        setEndDateFilter,

        uploadDateFilter,
        setUploadDateFilter,

        deleteCreativesAndGroups,

        reValidateCampaign,
        revalidateLoading,
    } = useCampaignCreatives(id);

    useEffect(() => {
        getCreatives();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });

    /**
     * Decided we only want to show a subset of campaign errors for now
     */
    const relevantCampaignLevelErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err =>
                    err.errorable_type === CampaignErrorableType.Campaign &&
                    err.type === CampaignErrorType.NoMatchingResolution,
            ) ?? [],
        [campaign],
    );

    const creativeLevelErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err => err.errorable_type === CampaignErrorableType.Creative,
            ) ?? [],
        [campaign],
    );

    const applyCreativeErrorsFilters = useCallback(() => {
        setResolutionFilters([]);
        setAspectFilters([]);
        setFileTypeFilters([]);
        setSearchTerm('');
        setDurationFilter(null);
        setLiveDateFilter(null);
        setEndDateFilter(null);
        setUploadDateFilter(null);
        setErrorFilters(allFilters.errors);
        setOpenGroup(StaticCreativeGroup.Invalid);
    }, [
        setAspectFilters,
        setErrorFilters,
        setFileTypeFilters,
        setResolutionFilters,
        setDurationFilter,
        setLiveDateFilter,
        setEndDateFilter,
        setUploadDateFilter,
        setSearchTerm,
        setOpenGroup,
        allFilters.errors,
    ]);

    const t = useCommonTranslation();

    const openGroupName = useMemo(() => {
        if (openGroup === StaticCreativeGroup.AllCreatives)
            return t('Modules.Main.CreativeManagement.groups.allArtwork');
        if (openGroup === StaticCreativeGroup.Invalid)
            return t('Modules.Main.CreativeManagement.groups.invalidGroup');
        if (openGroup === StaticCreativeGroup.Ungrouped)
            return t('Modules.Main.CreativeManagement.groups.ungroupedGroup');
        if (openGroup === StaticCreativeGroup.UnAssigned)
            return t('Modules.Main.CreativeManagement.groups.unassignedGroup');

        return creatives.creativeGroups.find(g => g.id === openGroup)?.name ?? '';
    }, [creatives.creativeGroups, openGroup, t]);

    const autoGroupsState = useBoolean(false);

    const autoGroupBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={CreateGroupPerms}>
                <Button
                    size="small"
                    variant="text"
                    id="creatives-auto-group"
                    onClick={autoGroupsState.setTrue}
                >
                    <AddCircle fontSize="inherit" />{' '}
                    <Box ml={0.5}>
                        {t('Modules.Main.CreativeManagement.groups.autoGroupsLabel')}
                    </Box>
                </Button>
            </IfHasAllPermissions>
        ),
        [autoGroupsState.setTrue, t],
    );

    return (
        <DndProvider backend={HTML5Backend}>
            {campaign?.flags?.dirty === true && (
                <Box mb={2}>
                    <Alert
                        severity="warning"
                        variant="outlined"
                        action={
                            <Button
                                variant="text"
                                color="inherit"
                                disabled={revalidateLoading}
                                onClick={reValidateCampaign}
                            >
                                {revalidateLoading ? (
                                    <CircularProgress size={15} />
                                ) : (
                                    t(
                                        'Modules.Main.CreativeManagement.warnings.revalidateCampaignLabel',
                                    )
                                )}
                            </Button>
                        }
                    >
                        {t('Modules.Main.CreativeManagement.warnings.campaignNeedsValidating')}
                    </Alert>
                </Box>
            )}
            {creativeLevelErrors.length > 0 && (
                <Box mb={2}>
                    <Alert
                        severity="warning"
                        variant="outlined"
                        action={
                            <Button
                                variant="text"
                                color="inherit"
                                onClick={applyCreativeErrorsFilters}
                            >
                                {t('Modules.Main.CreativeManagement.warnings.filterAllButtonLabel')}
                            </Button>
                        }
                    >
                        {t('Modules.Main.CreativeManagement.warnings.invalidCreatives')}
                    </Alert>
                </Box>
            )}
            {relevantCampaignLevelErrors && (
                <Box mb={2}>
                    <CampaignErrorList errors={relevantCampaignLevelErrors} hideOutcome />
                </Box>
            )}
            {autoGroupsState.value && (
                <AutoGroupsDialog
                    campaignId={id}
                    resolutions={allFilters?.resolutions ?? []}
                    open={autoGroupsState.value}
                    onClose={autoGroupsState.setFalse}
                    resetData={resetCreativesData}
                />
            )}
            <Card elevation={0} sx={useMemo(() => ({ mb: 2 }), [])}>
                <CardContent sx={cardContentSx}>
                    {creatives && (
                        <>
                            <Grid container spacing={1} sx={gridContainerSx} alignItems="stretch">
                                <Grid item xs={12} lg={3} xl={2} sx={groupWrapperGridItemSx}>
                                    <GroupWrapper
                                        campaignId={id}
                                        data={creatives}
                                        openGroup={openGroup}
                                        onClickGroup={setOpenGroup}
                                        addGroup={addGroup}
                                        updateGroup={updateGroup}
                                        setSelectedCreatives={setSelectedCreatives}
                                        updateCreatives={updateCreatives}
                                        reValidateCampaign={reValidateCampaign}
                                        deleteData={deleteCreativesAndGroups}
                                        autoGroupsBtn={autoGroupBtn}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={9} xl={10} sx={filtersGridItemSx}>
                                    <Box px={1}>
                                        <FiltersWrapper
                                            searchTerm={searchTerm}
                                            setSearchTerm={setSearchTerm}
                                            filters={allFilters}
                                            resolutions={resolutionFilters}
                                            setResolutions={setResolutionFilters}
                                            aspectRatios={aspectRatioFilters}
                                            setAspectRatios={setAspectFilters}
                                            fileTypes={fileTypeFilters}
                                            setFileTypes={setFileTypeFilters}
                                            errorFilters={errorFilters}
                                            setErrors={setErrorFilters}
                                            duration={durationFilter}
                                            setDuration={setDurationFilter}
                                            liveDate={liveDateFilter}
                                            setLiveDate={setLiveDateFilter}
                                            endDate={endDateFilter}
                                            setEndDate={setEndDateFilter}
                                            uploadDate={uploadDateFilter}
                                            setUploadDate={setUploadDateFilter}
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        height="100%"
                                        overflow="hidden"
                                        display="flex"
                                        position="relative"
                                    >
                                        <CreativesWrapper
                                            campaignId={id}
                                            data={creatives}
                                            filteredCreatives={filteredCreatives}
                                            openGroup={openGroup}
                                            selectedCreatives={selectedCreatives}
                                            setSelectedCreatives={setSelectedCreatives}
                                            filters={debouncedFilters}
                                            updateCreatives={updateCreatives}
                                            reValidateCampaign={reValidateCampaign}
                                            revalidateLoading={revalidateLoading}
                                            deleteData={deleteCreativesAndGroups}
                                            openGroupName={openGroupName}
                                            resetData={resetCreativesData}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </CardContent>
            </Card>
        </DndProvider>
    );
};

export default CampaignCreativeManagement;
