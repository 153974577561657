import React, { useCallback, useEffect } from 'react';
import merge from 'deepmerge';
import equal from 'fast-deep-equal';
import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { Button, FlashMessage, FormWrapper } from 'c-components';
import { Skin, useEntityData, useUpdateEntityData } from 'c-data';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelectors, uiThunks } from 'c-wrapper';
import SkinEditKitchenSink from 'c-admin/Components/Skin/SkinEditKitchenSink';
import { NetworkRequestState } from 'c-data-layer';
import { onlySkinThemeValues } from 'c-admin/Lib';
import { Translate, TranslationPath } from 'c-translation';
import SkinColorSection from './SkinColorSection';
import SkinOverviewSection from './SkinOverviewSection';
import SkinUserAssign from './SkinUserAssign';

type Props = {
    skinId: number;
};

const SkinEditWrapper: React.FC<
    Props & { colourSubmitButton: React.ReactNode; nameSubmitButton: React.ReactNode }
> = ({ skinId, colourSubmitButton, nameSubmitButton }) => {
    const dispatch = useDispatch();
    const { watch } = useFormContext<Skin>();

    const values = watch();

    const updatePreview = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 500);
    const previewSkin = useSelector(uiSelectors.playAroundSkin);

    useEffect(() => {
        const newValues = merge(previewSkin ?? {}, values ?? {});
        updatePreview(() => {
            dispatch(uiThunks.setPlayAroundSkin(newValues));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, previewSkin, dispatch]);

    return (
        <Box>
            <Box mb={2}>
                <SkinOverviewSection
                    title="Modules.Admin.SkinEdit.form.overviewTitle"
                    skinId={skinId}
                    skin={previewSkin}
                    submitButton={nameSubmitButton}
                />
            </Box>
            <Box mb={2}>
                <SkinUserAssign title="Modules.Admin.SkinEdit.form.assignTitle" skinId={skinId} />
            </Box>
            <Box>
                <SkinColorSection
                    submitButton={colourSubmitButton}
                    title="Modules.Admin.SkinEdit.form.colorsTitle"
                    items={[
                        {
                            key: 'primary_color',
                            label: 'Modules.Admin.SkinEdit.form.primaryColorLabel',
                        },
                        {
                            key: 'secondary_color',
                            label: 'Modules.Admin.SkinEdit.form.secondaryColorLabel',
                        },
                        {
                            key: 'divider_color',
                            label: 'Modules.Admin.SkinEdit.form.dividerColorLabel',
                        },
                        {
                            key: 'card_bg_color',
                            label: 'Modules.Admin.SkinEdit.form.cardColorLabel',
                        },
                        {
                            key: 'content_bg_color',
                            label: 'Modules.Admin.SkinEdit.form.bgColorLabel',
                        },
                        {
                            key: 'nav_bg_color',
                            label: 'Modules.Admin.SkinEdit.form.navBgColorLabel',
                        },
                        {
                            key: 'primary_text_color',
                            label: 'Modules.Admin.SkinEdit.form.primaryTextColorLabel',
                        },
                        {
                            key: 'secondary_text_color',
                            label: 'Modules.Admin.SkinEdit.form.secondaryTextColorLabel',
                        },
                        {
                            key: 'disabled_text_color',
                            label: 'Modules.Admin.SkinEdit.form.disabledTextColorLabel',
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};

const SkinFormWrapper: React.FC<Props> = props => {
    const dispatch = useDispatch();
    const { getUpdatingById, update } = useUpdateEntityData('Skin');
    const { getById } = useEntityData<Skin>('Skin');
    const skin = getById({ id: props.skinId });

    const onSubmit = useCallback(
        data => {
            update(props.skinId, data);
        },
        [update, props.skinId],
    );

    const previewTheme = useSelector(uiSelectors.playAroundSkin);

    const { state, error } = getUpdatingById(props.skinId);

    useEffect(() => {
        /**
         * update the preview theme if the skin entity has changed
         */
        if (
            skin?.id != null &&
            skin?.id === previewTheme?.id &&
            !equal(onlySkinThemeValues(skin), onlySkinThemeValues(previewTheme))
        ) {
            dispatch(uiThunks.setPlayAroundSkin(skin));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skin]);

    const submitButton = useCallback(
        (title: TranslationPath) => (
            <>
                {error != null && <FlashMessage status="error">{error}</FlashMessage>}
                <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                    <Translate path={title} />
                </Button>
            </>
        ),
        [error, state],
    );

    return (
        <Box>
            <FormWrapper
                onSubmit={onSubmit}
                formOptions={{ defaultValues: merge(skin, previewTheme ?? {}) }}
            >
                <SkinEditWrapper
                    colourSubmitButton={submitButton('Modules.Admin.SkinEdit.form.save')}
                    nameSubmitButton={submitButton('Modules.Admin.SkinEdit.form.save')}
                    {...props}
                />
            </FormWrapper>
            <Box>
                <SkinEditKitchenSink />
            </Box>
        </Box>
    );
};

export default SkinFormWrapper;
