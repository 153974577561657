import { BaseEntity } from '../../BaseEntity';
import { Displays_Owner } from './Displays_Owner';
import { Displays_Resolution } from './Displays_Resolution';
import { Displays_FileType } from './Displays_FileType';
import { Displays_Screen } from './Displays_Screen';
import { Displays_Country } from './Displays_Country';
import { Displays_Market } from './Displays_Market';
import { Displays_OperationalTime } from './Displays_OperationalTime';

export enum DisplayPackType {
    Pack = 'pack',
    Site = 'site',
}

export type Displays_Pack = BaseEntity & {
    country_id?: number;
    market_id?: number;
    ext_id?: number;
    owner_id?: number;

    pack_type: DisplayPackType;
    resolution: string;
    size: string;
    animation: string;
    slots: number;
    slot_length: string;
    env: string;
    name: string;
    description: string;
    brochurelink: string;
    brochure: string;
    status: number;

    unilive_id?: number;

    // include
    market?: Displays_Market;

    // include
    owner?: Displays_Owner;

    // include
    resolutions?: Displays_Resolution[];

    // include
    filetypes?: Displays_FileType[];

    // include
    country?: Displays_Country;

    // include
    activeDisplays?: Displays_Screen[];

    // include
    activeDisplaysCount?: number;

    // include
    inactiveDisplays?: Displays_Screen[];

    // include
    inactiveDisplaysCount?: number;

    operationalTimes?: Displays_OperationalTime[];
};
