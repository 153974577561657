import { BaseEntity } from 'c-sdk';
// import getUnixTime from 'date-fns/getUnixTime';
// import parseISO from 'date-fns/parseISO';

const shouldUpsert = <Entity extends BaseEntity>(newData: Entity, current?: Entity) =>
    // there are problems where sometimes entities aren't upserted and we're not sure why.
    // this is the case on the data status page with campaign notes. Sometimes the API seems to return
    // the same timestamps despite updating the content of the model's fields.
    // so always accept a new entity. We're not doing realtime websockets at the moment
    // so the possibility of overwriting an entity with an old one is basically zero.
    true;

// if (current == null && newData != null) {
//     // entity doesn't currently exist
//     return true;
// }
//
// if (
//     current.created_at == null &&
//     current.updated_at == null &&
//     newData?.created_at == null &&
//     newData?.updated_at == null
// ) {
//     // just in case some entities have missing date stamps in them (legacy DB)
//     return true;
// }
//
// if (current.updated_at == null && newData.updated_at != null) {
//     // new data has been updated since creation
//     return true;
// }
//
// if (current.updated_at != null && current.updated_at === newData.updated_at) {
//     // same entity, but might have additional fields in it
//     return true;
// }
//
// if (current.updated_at != null && newData?.updated_at == null) {
//     // "new" data is an older copy than what we currently have
//     return false;
// }
//
// if (current?.updated_at != null && newData?.updated_at != null) {
//     const currentTime = getUnixTime(parseISO(current.updated_at));
//     const newDataTime = getUnixTime(parseISO(newData?.updated_at));
//
//     if (newDataTime > currentTime) {
//         // new data was updated more recently
//         return true;
//     }
// }
//
// return false;
export default shouldUpsert;
