import { AxiosRequestConfig } from 'axios';
import {
    CampaignInstruction as CampaignInstructionModel,
    HttpProgressUpdate,
    ModuleConstructorProps,
} from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';
import { generateArrayQSParams } from '../Lib';

const CampaignInstruction = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<CampaignInstructionModel>(
        { endpoints, httpClient, baseUrl },
        'CampaignInstruction',
    ),
    create: (
        {
            formData,
            onProgress,
        }: {
            /**
             * campaign_id: number - the campaign id to attach the creative to
             * instruction_details?: string - the text content of the instruction
             * files: File[] - the actual files to upload and attach to the instruction
             */
            formData;
            onProgress?: HttpProgressUpdate;
        },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');

        return httpClient.postFile<{ data: CampaignInstructionModel }>(
            `${endpoints.Entities.CampaignInstruction.create}${includeParams}`,
            formData,
            onProgress,
            {},
            config,
        );
    },
});

export default CampaignInstruction;
