import { useCallback, useMemo } from 'react';
import { PermissionName, UserType } from 'c-sdk';
import { useAuthenticatedUser } from 'c-hooks';
import { userHasAllPermissions, userHasSomePermissions } from 'c-auth-module/Lib';

function useUserPermissions() {
    const { user, chosenUserType, ...rest } = useAuthenticatedUser();
    const hasAllPermissions = useCallback(
        (permissions?: PermissionName[]) =>
            !user ? false : userHasAllPermissions(user, permissions ?? []),
        [user],
    );
    const hasSomePermissions = useCallback(
        (permissions?: PermissionName[]) => {
            if (!user) return false;
            if (permissions == null) return true;
            if (Array.isArray(permissions) && permissions.length === 0) return true;
            return userHasSomePermissions(user, permissions ?? []);
        },
        [user],
    );

    // just a convenience method for checking if a user has the admin permissions
    const hasAdminPermission = useMemo(
        () => userHasAllPermissions(user, [PermissionName.Admin]),
        [user],
    );

    // just a convenience method for checking if a user has the admin permissions
    const isUserOneOfTypes = useCallback(
        (userTypes: UserType[]) =>
            userTypes == null ||
            userTypes?.length === 0 ||
            (userTypes ?? []).indexOf(chosenUserType) !== -1,
        [chosenUserType],
    );

    return useMemo(
        () => ({
            hasAll: hasAllPermissions,
            hasSome: hasSomePermissions,
            hasAdminPermission,
            isUserOneOfTypes,
            ...rest,
        }),
        [hasAdminPermission, hasAllPermissions, hasSomePermissions, isUserOneOfTypes, rest],
    );
}

export default useUserPermissions;
