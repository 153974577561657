import React, { useMemo } from 'react';
import { PopDisplays_Display } from 'c-sdk';
import { MuiRouterLink } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { generatePath } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';

type Props = {
    displays: PopDisplays_Display[];
};

const DisplayDetailsTable: React.FC<Props> = ({ displays }) => {
    const t = useCommonTranslation();
    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'id',
                headerName: t('Modules.PopDisplays.Display.table.idCol'),
                sortable: true,
                renderCell: params => {
                    return (
                        <MuiRouterLink
                            to={generatePath(PostAuthRoutes.PopDisplays.DisplayOverview, {
                                id: params.id,
                            })}
                        >
                            {params.id}
                        </MuiRouterLink>
                    );
                },
            },
            {
                field: 'source',
                headerName: t('Modules.PopDisplays.Display.table.sourceCol'),
                sortable: true,
            },
            {
                field: 'name',
                headerName: t('Modules.PopDisplays.Display.table.nameCol'),
                sortable: true,
                flex: 1,
            },
            {
                field: 'channel',
                headerName: t('Modules.PopDisplays.Display.table.channelCol'),
                sortable: true,
                flex: 1,
            },
            {
                field: 'frame',
                headerName: t('Modules.PopDisplays.Display.table.frameCol'),
                sortable: true,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.PopDisplays.Display.table.frameIdCol'),
                sortable: true,
                minWidth: 120,
            },
        ],
        [t],
    );

    return (
        <DataGridPro
            autoHeight
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnSelector
            disableColumnPinning
            hideFooter
            columns={columns}
            rows={displays}
        />
    );
};

export default DisplayDetailsTable;
