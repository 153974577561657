import React, { useCallback, useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { FormWrapper, TransparentCard } from 'c-components';
import { userIcon } from 'c-main/icons';
import NewUserForm from 'c-admin/Components/User/NewUserForm';
import * as yup from 'yup';
import { useCreateEntity, User } from 'c-data';
import { pick } from 'ramda';
import { usePrevious } from 'react-hanger';
import { NetworkRequestState } from 'c-data-layer';
import { generatePath, useHistory } from 'react-router-dom';
import { EditUserPage } from 'c-admin/Pages/EditUserPage';
import ReactGA from 'react-ga';

const schema = yup.object({
    name_first: yup.string().required().min(1),
    name_last: yup.string().required().min(1),
    email: yup.string().required().email(),
});

const NewUserPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { createEntity, resetCreateState, saveState, newestCreatedId } = useCreateEntity('User');
    const onSubmit = useCallback(
        (data: User) => {
            const newUser = { ...pick(['name_first', 'name_last', 'email'], data) } as User;

            if (data.buyers?.length > 0) newUser.buyers = data.buyers;
            if (data.agencies?.length > 0) newUser.agencies = data.agencies;
            if (data.clients?.length > 0) newUser.clients = data.clients;
            if (data.owners?.length > 0) newUser.owners = data.owners;
            if (data.roles?.length > 0) newUser.roles = data.roles;

            createEntity(newUser, EditUserPage.systemSearch.defaultIncludes);
        },
        [createEntity],
    );

    useEffect(() => {
        resetCreateState();
        return () => {
            resetCreateState();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prevSaveState = usePrevious(saveState);
    const $history = useHistory();
    useEffect(() => {
        if (
            prevSaveState === NetworkRequestState.InProgress &&
            saveState === NetworkRequestState.Success &&
            newestCreatedId != null
        ) {
            $history.push(generatePath(PostAuthRoutes.Admin.EditUser, { id: newestCreatedId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveState]);

    return (
        <TransparentCard title={useCommonTranslation('Modules.Admin.UserCreate.metaTitle')}>
            <CardContent>
                <FormWrapper onSubmit={onSubmit} validationSchema={schema}>
                    <NewUserForm />
                </FormWrapper>
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'NewUserPage',
    component: NewUserPage,
    systemSearch: {
        title: 'Modules.Admin.UserCreate.metaTitle',
        description: 'Modules.Admin.UserCreate.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.newUser',
        route: PostAuthRoutes.Admin.NewUser,
        saveAsRecentPage: true,
        icon: userIcon,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Admin.UsersList.metaTitle',
                link: PostAuthRoutes.Admin.UserList,
            },
            { label: 'Modules.Admin.UserCreate.metaTitle' },
        ],
    },
};

export default setup;
