import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    CampaignDataNotice,
    EsPCAReportField,
    PCAReportField,
    PermissionName,
    SetCampaignNoticesRequest,
    Campaign,
} from 'c-sdk';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { Badge, CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { Flag } from '@mui/icons-material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useEntityData } from 'c-data';
import { useBoolean } from 'react-hanger';
import { Checkbox } from 'c-components';
import { useDebounce } from 'use-debounce';
import equal from 'fast-deep-equal';
import { NetworkRequestState } from 'c-data-layer';
import to from 'await-to-js';
import { useCommonTranslation } from 'c-translation';

type Props = {
    campaignId: number;
    field: string;
    id: EntityId;
    name: string;
};

const setNoticesPermissions = [PermissionName.AdminCan_set_display_notices];
const noNotices: CampaignDataNotice = {
    awaiting_data: false,
    inspecting_data: false,
    awaiting_booking_plan: false,
    inflight_report_sent: false,
    pca_report_sent: false,
};

const isLineItemField = field =>
    field === PCAReportField.LineItemName || field === EsPCAReportField.DisplayLineItemName;
const isOwnerField = field =>
    field === PCAReportField.MediaOwner || field === EsPCAReportField.DisplayOwner;

const SetCampaignNotices: React.FC<Props> = ({ campaignId, field, id, name }) => {
    const { start, requestState } = useAPIClientRequest(apiClient.Entities.Campaign.setNotices);
    const { upsertEntity, getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id: campaignId });

    const onSetNotices = useCallback(
        async (notices: CampaignDataNotice) => {
            const [err, response] = await to(
                start(
                    campaignId,
                    {
                        line_items: isLineItemField(field) ? ([id] as string[]) : undefined,
                        media_owner: isOwnerField(field) ? (id as number) : undefined,
                        notice: notices,
                    } as SetCampaignNoticesRequest,
                    ['notices'],
                ),
            );

            if (!err && response?.data?.data?.id != null) {
                upsertEntity(response.data.data as unknown as Campaign);
            }
        },
        [campaignId, start, field, id, upsertEntity],
    );

    const noticesObject = useMemo(() => {
        if (isLineItemField(field))
            return campaign?.notices?.line_items?.[name] ?? { ...noNotices };
        if (isOwnerField(field)) return campaign?.notices?.media_owners?.[name] ?? { ...noNotices };

        return { ...noNotices };
    }, [campaign, field, name]);

    const badgeCount = useMemo(
        () => Object.values(noticesObject).filter(val => val === true).length,
        [noticesObject],
    );
    const btnRef = useRef();
    const menuState = useBoolean(false);
    const [editedNotices, setEditedNotices] = useState({ ...noticesObject });

    useEffect(() => {
        setEditedNotices({ ...noticesObject });
    }, [noticesObject]);

    const [debouncedNotices] = useDebounce(editedNotices, 1000);

    useEffect(() => {
        if (!equal(noticesObject, debouncedNotices)) {
            onSetNotices(debouncedNotices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedNotices]);

    const toggleAwaiting = useCallback(() => {
        setEditedNotices(val => ({
            ...val,
            awaiting_data: !val.awaiting_data,
        }));
    }, []);

    const toggleInspecting = useCallback(() => {
        setEditedNotices(val => ({
            ...val,
            inspecting_data: !val.inspecting_data,
        }));
    }, []);

    const toggleAwaitingBookingPlan = useCallback(() => {
        setEditedNotices(val => ({
            ...val,
            awaiting_booking_plan: !val.awaiting_booking_plan,
        }));
    }, []);

    return (
        <IfHasAllPermissions permissions={setNoticesPermissions}>
            <>
                <Badge badgeContent={badgeCount} max={99} color="primary" variant="dot">
                    <IconButton ref={btnRef} onClick={menuState.toggle}>
                        {requestState === NetworkRequestState.InProgress ? (
                            <CircularProgress size={25} />
                        ) : (
                            <Flag fontSize="inherit" />
                        )}
                    </IconButton>
                </Badge>
                <Menu open={menuState.value} anchorEl={btnRef.current} onClose={menuState.setFalse}>
                    <MenuItem>
                        <Checkbox
                            isBoolean
                            onChange={toggleAwaiting}
                            checked={editedNotices.awaiting_data}
                            label={useCommonTranslation(
                                'Modules.Main.Campaigns.DataStatus.awaitingDataLabel',
                            )}
                            disabled={requestState === NetworkRequestState.InProgress}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Checkbox
                            onChange={toggleInspecting}
                            isBoolean
                            checked={editedNotices.inspecting_data}
                            label={useCommonTranslation(
                                'Modules.Main.Campaigns.DataStatus.inspectingDataLabel',
                            )}
                            disabled={requestState === NetworkRequestState.InProgress}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Checkbox
                            onChange={toggleAwaitingBookingPlan}
                            isBoolean
                            checked={editedNotices.awaiting_booking_plan}
                            label={useCommonTranslation(
                                'Modules.Main.Campaigns.DataStatus.awaitingBookingPlanLabel',
                            )}
                            disabled={requestState === NetworkRequestState.InProgress}
                        />
                    </MenuItem>
                </Menu>
            </>
        </IfHasAllPermissions>
    );
};

export default SetCampaignNotices;
