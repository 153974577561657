import React from 'react';
import { Stack } from '@mui/material';
import { CampaignError, PermissionName } from 'c-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import CampaignErrorAlert from './CampaignErrorAlert';

type Props = {
    errors: CampaignError[];
    hideExpected?: boolean;
    hideOutcome?: boolean;
};

const perms = [PermissionName.UniledportalCampaign_errorRead];
const CampaignErrorList: React.FC<Props> = ({ errors, hideExpected, hideOutcome }) => (
    <IfHasAllPermissions permissions={perms}>
        <Stack spacing={2}>
            {errors.map(err => (
                <CampaignErrorAlert
                    key={err.id}
                    error={err}
                    hideExpected={hideExpected}
                    hideOutcome={hideOutcome}
                />
            ))}
        </Stack>
    </IfHasAllPermissions>
);
export default CampaignErrorList;
