import React, { useEffect, useMemo } from 'react';
import { usePaginatedEntityData } from 'c-data';
import { Displays_LineItem } from 'c-sdk';
import { useSelector } from 'react-redux';
import { displaysLineItemsSelectors } from 'c-main/Slices';
import { Table, TableBody, TableCell, TableHead, TableRow, TransparentCard } from 'c-components';
import { Box, CardContent, CircularProgress, TableContainer, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from 'c-data-layer';
import { useFormContext } from 'react-hook-form';
import { CampaignFormNames } from 'c-main/Types';
import { numberOfDays } from 'c-lib';
import { useCampaignLineItemCost } from 'c-main/Hooks';
import TotalCost from './TotalCost';
import CostingByOwnerTable from './CostingByOwnerTable';

type Props = {
    selectedLineItems: number[];
    paginationTag: string;
    showDiff?: boolean;
    originalPrice?: number;
};

const CampaignLineItemCost: React.FC<Props> = ({
    selectedLineItems,
    paginationTag,
    showDiff = false,
    originalPrice,
}) => {
    const { getPaginationData, paginatedData } = usePaginatedEntityData<Displays_LineItem>(
        paginationTag,
        'Displays_LineItem',
    );

    const byOwner = useSelector(displaysLineItemsSelectors.lineItemsByOwner)(selectedLineItems);

    const t = useCommonTranslation();

    const { watch } = useFormContext();
    const start = watch(CampaignFormNames.start);
    const end = watch(CampaignFormNames.end);

    const startDate = useMemo(() => new Date(start), [start]);
    const endDate = useMemo(() => new Date(end), [end]);
    const maxDays = useMemo(() => numberOfDays(startDate, endDate), [startDate, endDate]);

    const noOwnerTable = useMemo(() => {
        if (byOwner.noOwner?.length > 0) {
            return (
                <CostingByOwnerTable
                    lineItemIds={byOwner.noOwner}
                    maxDays={maxDays}
                    startDate={startDate}
                    endDate={endDate}
                />
            );
        }

        return null;
    }, [byOwner.noOwner, endDate, maxDays, startDate]);

    const byOwnerTables = useMemo(
        () =>
            Object.entries(byOwner.byOwner).reduce((views, [ownerId, lineItemIds]) => {
                views.push(
                    <CostingByOwnerTable
                        key={ownerId}
                        ownerId={+ownerId}
                        lineItemIds={lineItemIds}
                        maxDays={maxDays}
                        startDate={startDate}
                        endDate={endDate}
                    />,
                );
                return views;
            }, [] as React.ReactNode[]),
        [byOwner.byOwner, endDate, maxDays, startDate],
    );

    const buyerId = watch(CampaignFormNames.buyer);
    const { hasCost } = useCampaignLineItemCost(+buyerId);

    useEffect(() => {
        // reload the line items when this step mounts to make sure we have the latest line items
        getPaginationData(
            {
                filters: { 'filter.id': selectedLineItems },
                perPage: selectedLineItems.length,
            },
            true,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoading = useMemo(
        () => paginatedData?.loadingState?.state === NetworkRequestState.InProgress,
        [paginatedData?.loadingState?.state],
    );

    return (
        <TransparentCard>
            <CardContent>
                <Box mx="auto">
                    <Box textAlign="center" my={2}>
                        {isLoading && <CircularProgress />}
                    </Box>
                    {!isLoading && (
                        <>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {t(
                                                    'Modules.Main.Campaigns.Create.LineItemsTable.nameHeader',
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {t(
                                                    'Modules.Main.Campaigns.Create.LineItemsTable.startDateHeader',
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {t(
                                                    'Modules.Main.Campaigns.Create.LineItemsTable.endDateHeader',
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {t(
                                                    'Modules.Main.Campaigns.Create.LineItemsTable.daysHeader',
                                                )}
                                            </TableCell>
                                            {hasCost && (
                                                <TableCell>
                                                    {t(
                                                        'Modules.Main.Campaigns.Create.LineItemsTable.costHeader',
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {noOwnerTable}
                                        {byOwnerTables}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {hasCost && (
                                <Box ml="auto">
                                    <Box>
                                        <TotalCost
                                            lineItemIds={selectedLineItems}
                                            showDiff={showDiff}
                                            originalPrice={originalPrice}
                                        />
                                    </Box>
                                    <Typography variant="body2" textAlign="right">
                                        <Translate
                                            path="Modules.Main.Campaigns.Create.Costing.lineItemCount"
                                            data={{ count: selectedLineItems.length }}
                                        />
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </CardContent>
        </TransparentCard>
    );
};

export default CampaignLineItemCost;
