import React, { useMemo } from 'react';
import { PermissionName, Campaign } from 'c-sdk';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'c-components';
import { TableContainer } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { sortEntityDesc } from 'c-data';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import CampaignCostRow from './CampaignCostRow';

type Props = {
    campaign: Campaign;
};

const CampaignCosts: React.FC<Props> = ({ campaign }) => {
    const orderedSummaries = useMemo(
        () => [...(campaign?.budgetSummaries ?? [])].sort((a, b) => sortEntityDesc(a, b)),
        [campaign.budgetSummaries],
    );

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow isHeader>
                        <TableCell isHeader>
                            {useCommonTranslation(
                                'Modules.Main.Campaigns.Overview.Costs.createdAtHeader',
                            )}
                        </TableCell>
                        <TableCell isHeader>
                            {useCommonTranslation(
                                'Modules.Main.Campaigns.Overview.Costs.displaysHeader',
                            )}
                        </TableCell>
                        <TableCell isHeader>
                            {useCommonTranslation(
                                'Modules.Main.Campaigns.Overview.Costs.priceHeader',
                            )}
                        </TableCell>
                        <TableCell isHeader>
                            {useCommonTranslation(
                                'Modules.Main.Campaigns.Overview.Costs.statusHeader',
                            )}
                        </TableCell>
                        <IfHasAllPermissions
                            permissions={[PermissionName.UniledportalBudget_summaryOverride]}
                        >
                            <>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.Overview.Costs.overrideReasonHeader',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.Overview.Costs.originalPriceHeader',
                                    )}
                                </TableCell>
                            </>
                        </IfHasAllPermissions>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderedSummaries.map((summary, i) => (
                        <CampaignCostRow key={summary.id} summary={summary} current={i === 0} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CampaignCosts;
