import React, { useMemo } from 'react';
import { CampaignCreativesAutoGenerateGroupsResponse } from 'c-sdk';
import {
    Checkbox,
    HighlightWord,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import { Box, TableContainer, Typography, TypographyProps } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useListSelection } from 'c-hooks';

type Props = ReturnType<typeof useListSelection> & {
    visibleGroups: CampaignCreativesAutoGenerateGroupsResponse['groups'];
    searchTerm: string;
    disableCheckboxes: boolean;
};

const greyTdProps = {
    typographyProps: { variant: 'body2', color: 'grey.600' } as TypographyProps,
};
const countTdProps = {
    ...greyTdProps,
    sx: { width: '4em', textAlign: 'center' },
};
const checkboxTdSx = {
    width: 80,
    maxWidth: 80,
    pr: 0,
};

const AutoGroupsTable: React.FC<Props> = ({
    visibleGroups,
    searchTerm,
    disableCheckboxes,
    onItemToggled,
    selectedIds,
    onSelectAllToggle,
    selectAllChecked,
    selectAllIndeterminate,
    totalCount,
    selectedCount,
}) => {
    const memoGroups = useMemo(
        () =>
            visibleGroups.map(group => {
                const count = (group.current?.length ?? 0) + (group.new?.length ?? 0);

                // the group already exists and we're adding new creatives to it.
                const showNew = group.current?.length > 0 && group.new?.length > 0;
                return (
                    <TableRow key={group.name}>
                        <TableCell sx={checkboxTdSx}>
                            <Checkbox
                                disabled={disableCheckboxes}
                                checked={selectedIds.indexOf(group.name) !== -1}
                                onClick={e => onItemToggled(group.name, e)}
                                label=""
                            />
                        </TableCell>
                        <TableCell {...greyTdProps}>
                            <HighlightWord searchTerm={searchTerm} text={group.name} />
                        </TableCell>
                        <TableCell {...countTdProps}>
                            <Box display="flex" justifyContent="center">
                                {count}
                                {showNew && (
                                    <Typography
                                        fontSize="inherit"
                                        color="success.main"
                                        sx={{ ml: 0.5 }}
                                    >
                                        {`(+${group.new.length})`}
                                    </Typography>
                                )}
                            </Box>
                        </TableCell>
                    </TableRow>
                );
            }),
        [visibleGroups, selectedIds, searchTerm, onItemToggled, disableCheckboxes],
    );
    return (
        <TableContainer>
            <Table stickyHeader spacedOutRows>
                <TableHeadWrapper
                    disableCheckboxes={disableCheckboxes}
                    onSelectAllToggle={onSelectAllToggle}
                    selectAllIndeterminate={selectAllIndeterminate}
                    selectAllChecked={selectAllChecked}
                    selected={selectedCount}
                    total={totalCount}
                />
                <TableBody>{memoGroups}</TableBody>
            </Table>
        </TableContainer>
    );
};

type HeadProps = Pick<
    ReturnType<typeof useListSelection>,
    'selectAllChecked' | 'selectAllIndeterminate' | 'onSelectAllToggle'
> & { selected: number; total: number; disableCheckboxes: boolean };

const TableHeadWrapper: React.FC<HeadProps> = ({
    selectAllChecked,
    selectAllIndeterminate,
    onSelectAllToggle,
    selected,
    total,
    disableCheckboxes,
}) => (
    <TableHead>
        <TableRow isHeader>
            <TableCell isHeader sx={checkboxTdSx}>
                <Checkbox
                    disabled={disableCheckboxes}
                    checked={selectAllChecked}
                    indeterminate={selectAllIndeterminate}
                    onClick={onSelectAllToggle}
                    label={
                        <Typography fontSize="inherit" fontWeight="inherit">
                            {`${selected}/${total}`}
                        </Typography>
                    }
                />
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.Main.CreativeManagement.groups.autoGroups.groupNamesHeader',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.Main.CreativeManagement.groups.autoGroups.creativesCountHeader',
                )}
            </TableCell>
        </TableRow>
    </TableHead>
);

export default AutoGroupsTable;
