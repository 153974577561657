import { PopDisplays_ChannelFrame } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_ChannelFrameState } from './ChannelFrame-types';

const initialState: PopDisplays_ChannelFrameState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PopDisplays_ChannelFrameState, action: PayloadAction<string>): PopDisplays_ChannelFrameState => state,
};

const slice = createBaseSlice<
    PopDisplays_ChannelFrame,
    PopDisplays_ChannelFrameState,
    typeof additionalReducers
>({
    name: 'popChannelFrame',
    entityName: 'PopDisplays_ChannelFrame',
    initialState,
    reducers: additionalReducers,
});

const channelFrameSlice = {
    channelFrameActions: slice.slice.actions,
    channelFrameReducer: slice.slice.reducer,
    channelFrameAdapter: slice.adapter,
    channelFrameSelectors: slice.selectors,
    channelFrameThunks: slice.thunks,
};

export default channelFrameSlice;
export const {
    channelFrameReducer,
    channelFrameActions,
    channelFrameAdapter,
    channelFrameSelectors,
    channelFrameThunks,
} = channelFrameSlice;
