import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import {
    ByFieldChartProps,
    FieldReportType,
    ReportTabProps,
    ReportView,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { Collapse } from '@mui/material';
import { ElasticSearchAvailableFilters } from 'c-sdk';
import { ReportDateTimeframe, ReportWrapperProps, ReportWrapperRefAPI } from 'c-reports/Types';
import { useCommonTranslation } from 'c-translation';
import { TooltipProps } from 'recharts';
import CustomTooltip from 'c-main/Components/Campaign/CampaignReports/Views/ByField/CustomTooltip';
import ESGroupByField from './ESGroupByField';
import ESOverTimeByField from './ESOverTimeByField';

type Props = ReportTabProps & {
    view: ReportView;
    field?: string;
    onReportTypeSwitched?: (type: FieldReportType) => void;
    fieldReportType?: FieldReportType;
    initialTimeFrame?: ReportDateTimeframe;

    reportWrapperRef?: MutableRefObject<ReportWrapperRefAPI>;
    additionalControls?: React.ReactNode;
    onFiltersUpdated: (filters: ElasticSearchAvailableFilters) => void;
    refineFilters: boolean;

    // you can set this when you're expecting to group by a single thing
    // for example, looking at how a single frame has done over time
    // we just want to group the data by date/time
    groupResultsByDate?: boolean;
};

const ByFieldES: React.FC<Props> = ({
    metric,
    view,
    field,
    fieldReportType,
    currentStartDate,
    currentEndDate,
    campaignStart,
    campaignEnd,
    campaign,
    reportWrapperRef,
    currentTimeframe,
    initialTimeFrame,
    dateSettings,
    environment,
    onReportTypeSwitched,
    additionalControls,
    showAdvancedFilters,
    onFiltersUpdated,
    advancedFilter,
    fieldFilters,
    countFields,
    refineFilters,
    onResponse,
    groupResultsByDate = false,
    source,
}) => {
    const actualLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.${metric}.actualLabel`,
    );
    const percentageLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.${metric}.percentageLabel`,
    );
    const performanceLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.${metric}.performanceLabel`,
    );
    const expectedLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.${metric}.expectedLabel`,
    );
    const missingLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.${metric}.missingLabel`,
    );
    const dateLabelLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.dateLabel',
    );
    const hourLabel = useCommonTranslation('Modules.Main.Campaigns.Overview.Reports.hourLabel');
    const fieldLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.fields.${field}`,
    );
    const inScheduleLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.inScheduleLabel',
    );
    const outScheduleLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.outScheduleLabel',
    );
    const unbookedLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.unbookedLabel',
    );

    useEffect(() => {
        if (field == null && fieldReportType === FieldReportType.OverallLeaderboard) {
            onReportTypeSwitched(FieldReportType.OverTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    const reportWrapperProps = useMemo<ReportWrapperProps<any>>(
        () => ({
            ref: reportWrapperRef,
            requestKeyPrefix: '',
            fetchData: null,
            initialStartDate: currentStartDate,
            initialEndDate: currentEndDate,
            dateSettings,
            reportChartWrapperProps: { height: '75vh' },
            reportWrapperProps: {
                sx: {
                    bgcolor: 'white',
                    mt: 1,
                    pb: 1,
                    pt: fieldReportType !== FieldReportType.OverallLeaderboard && 1,
                },
            },
            additionalDateControls: <>{additionalControls}</>,
            additionalDateControlsPosition: 'end',
            beforeChartComponent: (
                <>
                    {advancedFilter && (
                        <Collapse in={showAdvancedFilters}>{advancedFilter}</Collapse>
                    )}
                </>
            ),
        }),
        [
            additionalControls,
            advancedFilter,
            currentEndDate,
            currentStartDate,
            dateSettings,
            fieldReportType,
            reportWrapperRef,
            showAdvancedFilters,
        ],
    );

    const renderTooltipLabel = useCallback(
        (tooltipProps: TooltipProps<any, any>) => (
            <CustomTooltip
                tooltipProps={tooltipProps}
                expectedLabel={expectedLabel}
                percentageLabel={percentageLabel}
                inScheduleLabel={inScheduleLabel}
                outScheduleLabel={outScheduleLabel}
                unbookedLabel={unbookedLabel}
                timeFrame={initialTimeFrame}
                type={fieldReportType}
                field={field}
                metric={metric}
                countFields={countFields}
            />
        ),
        [
            expectedLabel,
            percentageLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            initialTimeFrame,
            fieldReportType,
            field,
            metric,
            countFields,
        ],
    );

    const renderTooltipLabelHideActualValue = useCallback(
        (tooltipProps: TooltipProps<any, any>) => (
            <CustomTooltip
                hideActualValue
                tooltipProps={tooltipProps}
                expectedLabel={expectedLabel}
                percentageLabel={percentageLabel}
                inScheduleLabel={inScheduleLabel}
                outScheduleLabel={outScheduleLabel}
                unbookedLabel={unbookedLabel}
                timeFrame={initialTimeFrame}
                type={fieldReportType}
                field={field}
                metric={metric}
                countFields={countFields}
            />
        ),
        [
            expectedLabel,
            percentageLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            initialTimeFrame,
            fieldReportType,
            field,
            metric,
            countFields,
        ],
    );

    const byFieldReportProps = useMemo<ByFieldChartProps>(
        () => ({
            campaignId: campaign.id,
            minDate: campaignStart,
            maxDate: campaignEnd,
            currentStartDate,
            currentEndDate,
            currentTimeframe,
            metric,
            pcaData: null,
            timeFrame: initialTimeFrame,
            field,
            reportWrapperProps,
            renderTooltipLabel,
            renderTooltipLabelHideActualValue,
            fieldLabel,
            expectedLabel,
            missingLabel,
            percentageLabel,
            performanceLabel,
            actualLabel,
            dateLabelLabel,
            hourLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            environment,
            countFields,
            source,
        }),
        [
            campaign.id,
            campaignStart,
            campaignEnd,
            currentStartDate,
            currentEndDate,
            currentTimeframe,
            metric,
            initialTimeFrame,
            field,
            reportWrapperProps,
            renderTooltipLabel,
            renderTooltipLabelHideActualValue,
            fieldLabel,
            expectedLabel,
            missingLabel,
            percentageLabel,
            performanceLabel,
            actualLabel,
            dateLabelLabel,
            hourLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            environment,
            countFields,
            source,
        ],
    );

    const actualReportType = useMemo(() => {
        if (view === ReportView.Overall && fieldReportType === FieldReportType.OverallLeaderboard) {
            // there's no leaderboard when not viewing by field
            return FieldReportType.OverTime;
        }

        return fieldReportType;
    }, [view, fieldReportType]);

    const groupByReport = useMemo(
        () =>
            actualReportType === FieldReportType.OverallLeaderboard ||
            actualReportType === FieldReportType.Overall,
        [actualReportType],
    );

    return (
        <>
            {groupByReport && (
                <>
                    <ESGroupByField
                        {...byFieldReportProps}
                        onFiltersUpdated={onFiltersUpdated}
                        fieldFilters={fieldFilters}
                        field={field}
                        refineFilters={refineFilters}
                        reportType={actualReportType}
                        onResponse={onResponse}
                    />
                </>
            )}

            {actualReportType === FieldReportType.OverTime && (
                <>
                    <ESOverTimeByField
                        {...byFieldReportProps}
                        reportType={actualReportType}
                        onFiltersUpdated={onFiltersUpdated}
                        fieldFilters={fieldFilters}
                        field={field}
                        onResponse={onResponse}
                        groupResultsByDate={groupResultsByDate || view === ReportView.Overall}
                        lineChartAccumulative={view === ReportView.Overall}
                    />
                </>
            )}
        </>
    );
};

export default ByFieldES;
