import React from 'react';
import { useEntityData } from 'c-data';
import { PopDisplays_Display } from 'c-sdk';
import { AutoGrid } from 'c-components';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Grid, Typography } from '@mui/material';
import ChannelFramesDetailsTable from '../ChannelFramesDetailsTable';

type Props = {
    id: number;
};

const DisplayDetails: React.FC<Props> = ({ id }) => {
    const { getById } = useEntityData<PopDisplays_Display>('PopDisplays_Display');
    const display = getById({ id });

    if (!display) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <AutoGrid spacing={0} xs={12}>
                    <KV title="Modules.PopDisplays.Display.table.idCol" value={id} />
                    <KV
                        title="Modules.PopDisplays.Display.table.sourceCol"
                        value={display.source}
                    />
                    <KV title="Modules.PopDisplays.Display.table.nameCol" value={display.name} />
                    <KV
                        title="Modules.PopDisplays.Display.table.channelCol"
                        value={display.channel}
                    />
                    <KV title="Modules.PopDisplays.Display.table.frameCol" value={display.frame} />
                    <KV
                        title="Modules.PopDisplays.Display.table.displayPortalIdCol"
                        value={display.displays_portal_id}
                    />
                    <KV
                        title="Modules.PopDisplays.Display.table.frameIdCol"
                        value={display.frame_id}
                    />
                    <KV
                        title="Modules.PopDisplays.Display.table.displayKeyCol"
                        value={display.display_key}
                    />
                    <KV
                        title="Modules.PopDisplays.Display.table.slotLengthCol"
                        value={display.slot_length}
                    />
                    <KV title="Modules.PopDisplays.Display.table.slotsCol" value={display.slots} />
                    <KV
                        title="Modules.PopDisplays.Display.table.envCol"
                        value={display.environment}
                    />
                    <KV
                        title="Modules.PopDisplays.Display.table.resolutionCol"
                        value={display.resolution}
                    />
                    <KV
                        title="Modules.PopDisplays.Display.table.animationCol"
                        value={display.animation}
                    />
                    <KV title="Modules.PopDisplays.Display.table.ownerCol" value={display.owner} />
                    <KV
                        title="Modules.PopDisplays.Display.table.marketCol"
                        value={display.market}
                    />
                </AutoGrid>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={9}>
                {Array.isArray(display.channelFrames) && (
                    <ChannelFramesDetailsTable
                        displayId={display.id}
                        frames={display.channelFrames}
                    />
                )}
            </Grid>
        </Grid>
    );
};

const KV: React.FC<{ title: TranslationPath; value: React.ReactNode }> = ({ title, value }) => (
    <AutoGrid columnGap={1}>
        <Typography variant="body1">
            <strong>{useCommonTranslation(title)}:</strong>
        </Typography>
        <Typography variant="body1">{value}</Typography>
    </AutoGrid>
);

export default DisplayDetails;
