import {
    Displays_LineItem,
    SchedulableType,
    Schedule,
    ScheduleGet,
    ScheduleRule,
    ScheduleRuleGet,
} from 'c-sdk';
import { AppTranslationFunction } from 'c-translation';
import { equals, invertObj, last, reject, uniq } from 'ramda';
import { ListedLineItem, ScheduleFormDataRule } from './types';

export type TimePair = { start: string; end: string };

export const ScheduleDayToJSDayNumber = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
};

// swaps the keys and values around
const DayNumberInverted = invertObj(ScheduleDayToJSDayNumber);

export const AllScheduleRuleTimes = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
];

/**
 *
 * @param a - e.g. 01:00, 01:30
 * @param b - e.g. 01:00, 01:30
 * @return - The difference of index in all rule times. This helps identify if there is a gap in
 */
export const timeStringGap = (a: string, b: string): number =>
    Math.abs(AllScheduleRuleTimes.indexOf(a) - AllScheduleRuleTimes.indexOf(b));

export const ruleHoursToTimePairs = (
    ruleHours: Partial<ScheduleRule['hours_of_day']>,
): TimePair[] => {
    const trueHours = uniq(
        Object.keys(reject(equals(false))(ruleHours)).sort((a, b) => timeStringGap(a, b)),

        // this final sort puts the time strings in the correct order, so 01:00 comes before 01:30
    ).sort((a, b) => AllScheduleRuleTimes.indexOf(a) - AllScheduleRuleTimes.indexOf(b));

    if (trueHours.length > 0) {
        return trueHours.reduce(
            (pairs, time, currentIndex, array) => {
                const endGap = timeStringGap(last(pairs).end, time);
                if (endGap === 1) {
                    // gap is only 1, meaning its a continuous period of time
                    // eslint-disable-next-line no-param-reassign
                    pairs[pairs.length - 1].end = time;
                } else if (endGap > 1) {
                    // gap is larger than 1, meaning we need to start a new time period
                    pairs.push({ start: time, end: time });
                }

                return pairs;
            },
            [{ start: trueHours[0], end: trueHours[0] }] as TimePair[],
        );
    }

    return [];
};

/**
 * We need to round up the end times to the next value for display purposes
 *
 * For example, if you only have 10:00 set to true, that means 10:00 -> 10:30
 * @param ruleHours
 */
export const ruleHoursToTimePairsRoundedUp = (
    ruleHours: Partial<ScheduleRule['hours_of_day']>,
): TimePair[] =>
    ruleHoursToTimePairs(ruleHours).map(pair => {
        const lastHour = last(AllScheduleRuleTimes);
        const isLast = pair.end === lastHour;
        const hourIndex = AllScheduleRuleTimes.indexOf(pair.end);
        return {
            ...pair,
            end: isLast ? AllScheduleRuleTimes[0] : AllScheduleRuleTimes[hourIndex + 1],
        };
    });

/**
 * if hour ranges cross the midnight border, 'fix' them so they don't
 * for example, a pair of 22:00 -> 02:00 would be turned into two pairs of
 * 22:00 -> 23:30, 00:00 -> 02:00
 * @param data
 */
export const fixFormHourRanges = (
    data: ScheduleFormDataRule['hours'],
): ScheduleFormDataRule['hours'] =>
    data
        .map(({ from, to }) => ({
            // assuming midnight if either to or from are null/empty
            from: AllScheduleRuleTimes.indexOf(from) !== -1 ? from : AllScheduleRuleTimes[0],
            to: AllScheduleRuleTimes.indexOf(to) !== -1 ? to : AllScheduleRuleTimes[0],
        }))
        .reduce((hours, pair, currentIndex) => {
            const startIndex = AllScheduleRuleTimes.indexOf(pair.from);
            const endIndex = AllScheduleRuleTimes.indexOf(pair.to);
            if (startIndex > endIndex) {
                hours.push({ from: pair.from, to: null });

                // only add a second entry if it didn't end at midnight
                if (endIndex !== 0) hours.push({ from: AllScheduleRuleTimes[0], to: pair.to });

                return hours;
            }
            return [...hours, pair];
        }, [] as ScheduleFormDataRule['hours']);

export const formRuleHourDataToRuleHourData = (
    data: ScheduleFormDataRule['hours'],
): ScheduleRule['hours_of_day'] =>
    AllScheduleRuleTimes.reduce((hours, currentHour, index, array) => {
        // work out whether the current hour is true;
        let hourTrue = false;
        let nextHour = array[(index + 1) % array.length]; // Get the next hour in the array

        // Treat 00:00 as 24:00 in the hour range
        if (nextHour === '00:00') {
            nextHour = '24:00';
        }

        const hourRange = `${currentHour} - ${nextHour}`;

        fixFormHourRanges(data).forEach(pair => {
            if (!hourTrue) {
                const startIndex = AllScheduleRuleTimes.indexOf(pair.from);
                let endIndex = AllScheduleRuleTimes.indexOf(pair.to);

                // Treat 00:00 as 24:00
                if (pair.to === '00:00') {
                    endIndex = AllScheduleRuleTimes.length;
                }

                const isTrue =
                    (startIndex !== -1 &&
                        index >= startIndex &&
                        endIndex !== -1 &&
                        index < endIndex) || // note: changed from endIndex - 1 to endIndex for correct range handling
                    // handling case when it starts before midnight and ends at midnight (treated as 24:00)
                    (startIndex !== -1 && pair.to === null && startIndex <= index);

                // if end index is equivalent to 24:00 (originally 00:00), it means it ends at midnight
                // so if start index is valid, but the time ends at midnight, it's fine.
                if (isTrue) hourTrue = true;
            }
        });

        return { ...hours, [hourRange]: hourTrue };
    }, {} as ScheduleRule['hours_of_day']);

export const formRuleDataToRule = (data: ScheduleFormDataRule): Partial<ScheduleRule> => ({
    id: data.id,
    start_date: data.date_range?.[0],
    end_date: data.date_range?.[1],
    sov: data.sov,
    days_of_week: data.days_of_week.reduce(
        // basically set to true if the day number in the in days of week number array
        (daysOfWeek, value) => ({ ...daysOfWeek, [DayNumberInverted[value]]: true }),
        {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        } as ScheduleRule['days_of_week'],
    ),
    slot_length: data.slot_length ?? null,
    hours_of_day: formRuleHourDataToRuleHourData(data.hours),
});

function convertTimeRangesToDict(timeRanges) {
    const timeObj = {};

    timeRanges.forEach(timeRange => {
        const [startTime, endTime] = timeRange.split(' - ');
        timeObj[startTime] = true;
        timeObj[endTime] = false;
    });

    return timeObj;
}

export const scheduleRuleToFormRuleData = (data: ScheduleRuleGet): ScheduleFormDataRule => {
    const times = convertTimeRangesToDict(data.hours_of_day);

    return {
        id: data.id,
        sov: data.sov,
        slot_length: data.slot_length,
        date_range: [data.start_date, data.end_date],
        days_of_week: data.days_of_week.map(weekday => ScheduleDayToJSDayNumber[weekday]),
        hours: ruleHoursToTimePairsRoundedUp(times).map(pair => ({
            from: pair.start,
            to: pair.end,
        })),
    };
};

export const generateSchedulableRowId = (id: string | number, type: SchedulableType) =>
    `${type}||${id}`;

export const extractSchedulableRowId = item => ({ id: item.id, type: item.type });

export const exportSchedulableItemIds = (ids: string[]) =>
    ids.reduce(
        (carry, currentValue) => {
            const pair = extractSchedulableRowId(currentValue);
            if (pair.type === SchedulableType.Screen) carry.screens.push(Number(pair.id));
            if (pair.type === SchedulableType.LineItem) carry.lineItems.push(String(pair.id));
            return carry;
        },
        { screens: [], lineItems: [] } as {
            screens: number[];
            lineItems: string[];
        },
    );

export const scheduleName = (schedule: ScheduleGet, t: AppTranslationFunction) =>
    schedule.name?.trim()?.length > 0
        ? schedule.name
        : t('en.Modules.Main.Campaigns.CampaignSchedule.Schedules.scheduleName', {
              count: schedule.id,
          });

export const schedulableScheduleSettings = (
    schedules: Schedule[],
    schedulableId: string,
    schedulableType: SchedulableType,
) =>
    schedules.reduce<ListedLineItem['scheduleSettings']>((settings, schedule) => {
        const schedulable = schedule?.schedulables?.find(
            sc => sc.schedulable_id === schedulableId && sc.schedulable_type === schedulableType,
        );

        if (schedulable != null)
            return [
                ...settings,
                {
                    scheduleId: schedule.id,
                    sov: schedulable.sov,
                    proof_of_play: schedulable.proof_of_play,
                },
            ];

        return settings;
    }, []);
const extractUniqueSchedules = (screens, schedules) => {
    const uniqueSchedules = new Map();

    screens?.forEach(screen => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        screen?.schedules?.data
            ? screen?.schedules?.data?.forEach(schedule => {
                  if (!uniqueSchedules.has(schedule?.id)) {
                      uniqueSchedules.set(schedule?.id, schedule);
                  }
              })
            : screen?.schedules?.forEach(schedule => {
                  if (!uniqueSchedules.has(schedule?.id)) {
                      uniqueSchedules.set(schedule?.id, schedule);
                  }
              });
    });

    const result = Array.from(uniqueSchedules.values()).map(uniqueSchedule => {
        const fullSchedule = schedules.find(sch => sch?.id === uniqueSchedule?.id);
        return fullSchedule || uniqueSchedule;
    });

    return result;
};
function calculateLineItemSOV(lineItem): string | null {
    const { screens } = lineItem;
    if (screens?.length === 0) return null;

    const sovs = screens?.map(screen => screen?.sov);
    const firstSOV = sovs?.[0];

    const isConsistent = sovs?.every(sov => sov === firstSOV);

    return isConsistent ? firstSOV : null;
}
export const lineItemToListedLineItem = (item: Displays_LineItem, schedules: ScheduleGet[], t) => {
    const matchedSchedules =
        item.schedules?.map(
            itemSchedule =>
                schedules.find(schedule => schedule.id === itemSchedule.id) || itemSchedule,
        ) ?? [];
    return {
        id: item.id,
        type: SchedulableType[item.type],
        name: item.name,
        environment: item.environment,
        size: item.size,
        resolutions: item.resolutions?.map(res => `${res.width}x${res.height}`) ?? [],
        fileTypes: item.fileTypes?.map(type => type.type) ?? [],
        owner: item.owner?.name ?? '',
        sov: calculateLineItemSOV(item),
        frame_id: item.frame_id
            ? item.frame_id
            : t('Modules.Main.Campaigns.CampaignSchedule.inAPack'),
        schedules: matchedSchedules,
        childScreenCount: item.bookedDisplaysCount,
    };
};

export const getTimeRanges = times => {
    if (times.length === 0) return [];

    return times
        .reduce((acc, time, index) => {
            const [currentStart, currentEnd] = time.split(' - ');

            if (index === 0) {
                acc.push([currentStart, currentEnd]);
            } else {
                const [prevStart, prevEnd] = acc[acc.length - 1];

                if (prevEnd === currentStart) {
                    acc[acc.length - 1][1] = currentEnd;
                } else {
                    acc.push([currentStart, currentEnd]);
                }
            }

            return acc;
        }, [])
        .map(range => range.join(' - '));
};
