import { FakeApi } from 'c-data-layer';
import { Skin } from 'c-entity-types';
import { formatISO } from 'date-fns';
import { isRunningStorybook, isRunningTests } from 'c-is-running';

// eslint-disable-next-line import/no-mutable-exports
let FakeSkins: FakeApi<Skin>;

if (isRunningTests || isRunningStorybook) {
    FakeSkins = {
        useFake: true,
        initialEntities: [
            {
                id: 1,
                name: 'Example',
                primary_color: 'rgba(25, 118, 212, 1)',
                secondary_color: 'rgba(83,86,90,1)',
                divider_color: 'rgba(0,0,0,0.12)',
                card_bg_color: 'rgba(255,255,255,1)',

                primary_text_color: 'rgba(0,0,0,0.87)',
                secondary_text_color: 'rgba(0,0,0,0.6)',
                disabled_text_color: 'rgba(0,0,0,0.38)',

                content_bg_color: 'rgba(238,238,238,1)',
                nav_bg_color: 'rgba(255,255,255,1)',

                created_at: formatISO(new Date()),
            },
        ],
    };
}

export default FakeSkins;
