import React, { useCallback, useMemo } from 'react';
import { useDeleteEntityData } from 'c-data';
import { BaseEntity } from 'c-sdk';
import EntityDialog, { EntityDialogProps } from '../EntityDialog/EntityDialog';

const DeleteEntityDialog = <Entity extends BaseEntity>({
    entityName,
    entityId,
    ...props
}: EntityDialogProps<Entity> & { entityId: number }) => {
    const { deleteEntity, resetDeletingById, getDeletingById } =
        useDeleteEntityData<Entity>(entityName);
    const resetState = useCallback(() => {
        resetDeletingById(entityId);
    }, [resetDeletingById, entityId]);

    const onDelete = useCallback(() => {
        deleteEntity(entityId);
    }, [entityId, deleteEntity]);

    const state = useMemo(() => getDeletingById(entityId), [getDeletingById, entityId]);
    return (
        <EntityDialog<Entity>
            {...props}
            id={entityId}
            state={state.state}
            error={state.error}
            resetState={resetState}
            postEntity={onDelete}
            submitLabel="SubmitButton.defaultDeleteLabel"
            submitButtonColor="error"
        />
    );
};

export default DeleteEntityDialog;
