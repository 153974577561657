import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSetAtom } from 'jotai';
import { Alert, AutoGrid, Button, DialogV2, FileUploadDropzone, ListItemText } from 'c-components';
import MonitorIcon from '@mui/icons-material/Monitor';
import {
    Box,
    IconButton,
    LinearProgress,
    LinearProgressProps,
    ListItem,
    ListItemButton,
    Menu,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_Owner, Displays_Pack, Displays_Tag } from 'c-sdk';
import {
    atom_OwnersDialogState,
    atom_OwnersPackDialogState,
    atom_PacksListDialogState,
    atom_TagsDialogState,
} from 'c-displays/atoms';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { analogueListIcon } from 'c-main/icons';
import { useBoolean } from 'react-hanger';
import ArticleIcon from '@mui/icons-material/Article';
import { useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from 'c-data-layer';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckCircle, Error } from '@mui/icons-material';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { formatBytes } from 'c-lib';
import { useDeleteEntityData } from 'c-data';

interface ButtonConfig {
    color: 'primary' | 'error';
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    icon: React.ReactNode;
    id: number;
}

export const PacksListButton: React.FC<RenderColumnDataProps<Displays_Pack>> = ({ entity }) => {
    const setAtomState = useSetAtom(atom_PacksListDialogState);
    const showGreenButton = entity.activeDisplaysCount > 0;
    const showRedButton = entity.inactiveDisplaysCount > 0;

    const redButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showRedButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 0,
                id: entity.id,
            });
            return null;
        },
        [showRedButton, setAtomState, entity.id],
    );

    const greenButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showGreenButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 1,
                id: entity.id,
            });
            return null;
        },
        [showGreenButton, setAtomState, entity],
    );

    const GreenButton = useMemo(() => {
        if (!showGreenButton) {
            return null;
        }
        return (
            <Button color="success" onClick={greenButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.activeDisplaysCount}</Box>
            </Button>
        );
    }, [entity.activeDisplaysCount, greenButtonHandler, showGreenButton]);
    const RedButton = useMemo(() => {
        if (!showRedButton) {
            return null;
        }
        return (
            <Button color="error" onClick={redButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.inactiveDisplaysCount}</Box>
            </Button>
        );
    }, [entity.inactiveDisplaysCount, redButtonHandler, showRedButton]);

    return (
        <Stack direction="row" gap={1}>
            {GreenButton}
            {RedButton}
        </Stack>
    );
};

export const OwnersDisplaysListButton: React.FC<RenderColumnDataProps<Displays_Owner>> = ({
    entity,
}) => {
    const setAtomState = useSetAtom(atom_OwnersDialogState);
    const showGreenButton = entity.activeScreensCount > 0;
    const showRedButton = entity.inactiveScreensCount > 0;
    const showGreyButton = entity.analogueScreensCount > 0;
    const greyButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showGreyButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: null,
                id: entity.id,
            });
            return null;
        },
        [showGreyButton, setAtomState, entity.id],
    );
    const redButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showRedButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 0,
                id: entity.id,
            });
            return null;
        },
        [showRedButton, setAtomState, entity.id],
    );

    const greenButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showGreenButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 1,
                id: entity.id,
            });
            return null;
        },
        [showGreenButton, setAtomState, entity],
    );

    const GreenButton = useMemo(() => {
        if (!showGreenButton) {
            return null;
        }
        return (
            <Button color="success" onClick={greenButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.activeScreensCount}</Box>
            </Button>
        );
    }, [entity.activeScreensCount, greenButtonHandler, showGreenButton]);
    const RedButton = useMemo(() => {
        if (!showRedButton) {
            return null;
        }
        return (
            <Button color="error" onClick={redButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.inactiveScreensCount}</Box>
            </Button>
        );
    }, [entity.inactiveScreensCount, redButtonHandler, showRedButton]);
    const GreyButton = useMemo(() => {
        if (!showGreyButton) {
            return null;
        }
        return (
            <Button color="secondary" onClick={greyButtonHandler} size="small">
                {analogueListIcon}
                <Box p={1}>{entity.analogueScreensCount}</Box>
            </Button>
        );
    }, [entity.analogueScreensCount, greyButtonHandler, showGreyButton]);
    return (
        <Stack direction="row" gap={1}>
            {GreenButton}
            {RedButton}
            {GreyButton}
        </Stack>
    );
};

export const OwnersPacksListButton: React.FC<RenderColumnDataProps<Displays_Owner>> = ({
    entity,
}) => {
    const setAtomState = useSetAtom(atom_OwnersPackDialogState);
    const showPacksButton = entity.packs.length > 0;
    const packsButtonHandler = useCallback(
        e => {
            e.preventDefault();

            if (!showPacksButton) {
                setAtomState({ id: null });
                return null;
            }
            setAtomState({
                id: entity.id,
            });
            return null;
        },
        [entity, setAtomState, showPacksButton],
    );
    const PacksButton = useMemo(() => {
        if (!showPacksButton) {
            return null;
        }
        return (
            <Button color="info" onClick={packsButtonHandler} size="small">
                <LibraryBooksIcon fontSize="inherit" />
                <Box p={1}>{entity.packs.length}</Box>
            </Button>
        );
    }, [entity.packs.length, packsButtonHandler, showPacksButton]);
    return <>{PacksButton}</>;
};
export const TagsListButton: React.FC<RenderColumnDataProps<Displays_Tag>> = ({ entity }) => {
    const setAtomState = useSetAtom(atom_TagsDialogState);
    const showGreenButton = entity.activeScreensCount > 0;
    const showRedButton = entity.inactiveScreensCount > 0;

    const redButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showRedButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 0,
                id: entity.id,
            });
            return null;
        },
        [showRedButton, setAtomState, entity.id],
    );

    const greenButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            if (!showGreenButton) {
                setAtomState({ status: null, id: null });
                return null;
            }
            setAtomState({
                status: 1,
                id: entity.id,
            });
            return null;
        },
        [showGreenButton, setAtomState, entity],
    );

    const GreenButton = useMemo(() => {
        if (!showGreenButton) {
            return null;
        }
        return (
            <Button color="success" onClick={greenButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.activeScreensCount}</Box>
            </Button>
        );
    }, [entity.activeScreensCount, greenButtonHandler, showGreenButton]);
    const RedButton = useMemo(() => {
        if (!showRedButton) {
            return null;
        }
        return (
            <Button color="error" onClick={redButtonHandler} size="small">
                <MonitorIcon fontSize="inherit" />
                <Box p={1}>{entity.inactiveScreensCount}</Box>
            </Button>
        );
    }, [entity.inactiveScreensCount, redButtonHandler, showRedButton]);
    return (
        <Stack direction="row" gap={1}>
            {GreenButton}
            {RedButton}
        </Stack>
    );
};
const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};
export const DisplaysBrochureColumn = ({ entity, entityName }) => {
    const [newEntity, setNewEntity] = useState(entity);
    const id = entity.id;
    const dialogState = useBoolean(false);
    const dialogDeleteState = useBoolean(false);
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const t = useCommonTranslation();
    const { start, requestState, error, reset, data } = useAPIClientRequest(
        ApiClient.Entities[entityName].createBrochure,
    );
    const {
        start: delStart,
        requestState: delReq,
        error: delErr,
        reset: delReset,
    } = useAPIClientRequest(ApiClient.Entities[entityName].deleteBrochure);
    const [brochure, setBrochure] = useState<File[]>();
    const anchorEl = useRef();
    const menuOpenState = useBoolean(false);
    useEffect(() => {
        if (delReq === NetworkRequestState.Success) {
            setNewEntity(prevEntity => ({ ...prevEntity, brochure: null }));
        }
        if (requestState === NetworkRequestState.Success) {
            setNewEntity(prevEntity => ({ ...prevEntity, brochure: data?.data?.brochure }));
        }
    }, [delReq, setNewEntity, requestState, data]);

    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);
    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);
    const onChange = useCallback(
        async files => {
            setBrochure(files);
            setProgress(null);
            reset();
            const formData = new FormData();
            formData.append('brochure_file', files[0]);
            await to(start(id, formData, setProgress));
        },
        [id, reset, start],
    );

    const deleteFile = useCallback(async () => {
        await to(delStart(id));
    }, [id, delStart]);

    const brochureButtonHandlerOpen = React.useCallback(
        e => {
            e.preventDefault();

            dialogState.setTrue();
        },
        [dialogState],
    );
    const deleteBrochureButtonHandler = React.useCallback(
        e => {
            e.preventDefault();

            dialogDeleteState.setTrue();
        },
        [dialogDeleteState],
    );
    const brochureMenuButtonHandlerOpen = React.useCallback(
        e => {
            e.preventDefault();

            menuOpenState.setTrue();
        },
        [menuOpenState],
    );
    const brochureMenuButtonHandlerClose = React.useCallback(
        e => {
            e.preventDefault();

            menuOpenState.setFalse();
        },
        [menuOpenState],
    );
    const brochureButtonHandlerClose = React.useCallback(
        e => {
            e.preventDefault();
            reset();
            delReset();
            setBrochure(null);
            setProgress(null);

            dialogState.setFalse();
        },
        [dialogState, delReset, reset, setBrochure, setProgress],
    );
    const deleteBrochureButtonHandlerClose = React.useCallback(
        e => {
            e.preventDefault();

            dialogDeleteState.setFalse();
        },
        [dialogDeleteState],
    );

    const handleBrochureDownload = useCallback(async (e: any, href: string, id: number) => {
        e.preventDefault();
        const response = await fetch(href);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}brochureFile.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, []);
    const currentBrochureOptionButtons: ButtonConfig[] = useMemo(
        () => [
            {
                color: 'primary',
                onClick: e => handleBrochureDownload(e, newEntity.brochure, id),
                icon: <ArticleIcon />,
                id: 1,
            },
            {
                color: 'error',
                onClick: e => deleteBrochureButtonHandler(e),
                icon: <DeleteIcon />,
                id: 2,
            },
        ],
        [deleteBrochureButtonHandler, handleBrochureDownload, id, newEntity],
    );

    const BrochureButton = useMemo(
        () => (
            <>
                <Button
                    variant="text"
                    color={newEntity.brochure ? 'primary' : 'error'}
                    onClick={brochureMenuButtonHandlerOpen}
                    ref={anchorEl}
                >
                    <ArticleIcon />
                </Button>
                <Menu
                    anchorEl={anchorEl.current}
                    open={menuOpenState.value}
                    onClose={brochureMenuButtonHandlerClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    {newEntity.brochure &&
                        currentBrochureOptionButtons.map(button => (
                            <ListItem key={button.id}>
                                <ListItemButton>
                                    <Button color={button.color} onClick={button.onClick}>
                                        {button.icon}
                                    </Button>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    <ListItem>
                        <ListItemButton color="primary" onClick={brochureButtonHandlerOpen}>
                            <Button>
                                <UploadFileIcon />
                            </Button>
                        </ListItemButton>
                    </ListItem>
                </Menu>
            </>
        ),
        [
            newEntity,
            brochureMenuButtonHandlerOpen,
            menuOpenState.value,
            brochureMenuButtonHandlerClose,
            currentBrochureOptionButtons,
            brochureButtonHandlerOpen,
        ],
    );

    return (
        <>
            <DialogV2
                onClose={brochureButtonHandlerClose}
                open={dialogState.value === true}
                title={t('Modules.Displays.DisplaysList.table.updateBrochure')}
            >
                {requestState === NetworkRequestState.Success ? (
                    <Box textAlign="center">
                        <Typography>
                            {t('Modules.Displays.DisplaysDisplayEdit.brochureSuccess')}
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        {entityName === 'Displays_Screen' && entity.packs.length > 0 && (
                            <>
                                <Typography>
                                    {t('Modules.Displays.DisplaysDisplayEdit.brochureWarning')}
                                </Typography>
                            </>
                        )}
                        <AutoGrid spacing={2} xs={12}>
                            <FileUploadDropzone
                                fileAccept=".pdf"
                                onFilesAdded={onChange}
                                dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                            />
                        </AutoGrid>
                        <Box pb={2}>
                            {brochure && (
                                <ListItemText
                                    primary={brochure[0].name}
                                    primaryTypographyProps={textProps}
                                    secondaryTypographyProps={textProps}
                                    secondary={
                                        <Stack gap={1} width="100%">
                                            <AutoGrid spacing={1}>
                                                {formatBytes(brochure[0]?.size)} |{' '}
                                                {brochure[0]?.type}
                                            </AutoGrid>
                                            <Box width="100%">
                                                <Stack direction="row" gap={1} alignItems="center">
                                                    <Box flex={1}>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={progressPercentage}
                                                            color={progressColor}
                                                        />
                                                    </Box>
                                                    {requestState !== NetworkRequestState.Idle && (
                                                        <Box>
                                                            {requestState ===
                                                            NetworkRequestState.InProgress ? (
                                                                <Typography variant="caption">
                                                                    {progressPercentage.toFixed(0)}{' '}
                                                                    %
                                                                </Typography>
                                                            ) : requestState ===
                                                              NetworkRequestState.Error ? (
                                                                <Tooltip title={String(error)}>
                                                                    <Typography color="error.main">
                                                                        <Error />
                                                                    </Typography>
                                                                </Tooltip>
                                                            ) : (
                                                                <Typography color="success.main">
                                                                    <CheckCircle />
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    }
                                />
                            )}
                        </Box>
                        {requestState === NetworkRequestState.Error && (
                            <Alert severity="error" variant="outlined">
                                {String(error)}
                            </Alert>
                        )}
                    </Box>
                )}
            </DialogV2>
            <DialogV2
                onClose={deleteBrochureButtonHandlerClose}
                open={dialogDeleteState.value === true}
                title={t('Modules.Displays.DisplaysList.table.deleteBrochure')}
            >
                <Typography
                    variant="inherit"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {delReq === NetworkRequestState.Success
                        ? t('Modules.Displays.DisplaysList.table.deletedBrochure')
                        : t('Modules.Displays.DisplaysList.table.confirmation')}
                </Typography>
                {delReq !== NetworkRequestState.Success && (
                    <Stack
                        direction="row"
                        gap={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button onClick={deleteFile}>
                            {t('Modules.Displays.DisplaysList.table.yes')}
                        </Button>
                        <Button onClick={deleteBrochureButtonHandlerClose}>
                            {t('Modules.Displays.DisplaysList.table.no')}
                        </Button>
                    </Stack>
                )}
                <Box>
                    {delReq === NetworkRequestState.InProgress && <CircularProgress />}
                    {delReq === NetworkRequestState.Success && <CheckCircle color="success" />}
                </Box>
                {delReq === NetworkRequestState.Error && (
                    <Alert severity="error" variant="outlined">
                        {String(delErr)}
                    </Alert>
                )}
            </DialogV2>
            {BrochureButton}
        </>
    );
};

export const DeleteEntityButtonFromTable = ({ id, entityName }) => {
    const deleteEntityState = useBoolean(false);
    const { deleteEntity, getDeletingById } = useDeleteEntityData(entityName);
    const t = useCommonTranslation();
    useEffect(() => {
        if (getDeletingById(id).state === NetworkRequestState.Success) {
            deleteEntityState.setFalse();
        }
    }, [deleteEntityState, getDeletingById, id]);
    const errorConversion = (errorMessage: string) => {
        if (errorMessage.includes('display_has_creative')) {
            return t('Modules.Displays.DisplaysDisplayEdit.deleteWithCampaign');
        }
        if (errorMessage.includes(`display_resolutions_pivot`)) {
            return t('Modules.Displays.DisplaysDisplayEdit.deleteWithResolution');
        }

        return errorMessage;
    };
    const dialogOpenerHandler = useCallback(
        e => {
            e.preventDefault();
            deleteEntityState.setTrue();
        },
        [deleteEntityState],
    );
    const dialogClosingHandler = useCallback(
        e => {
            e.preventDefault();
            deleteEntityState.setFalse();
        },
        [deleteEntityState],
    );
    const deleteHandler = useCallback(
        e => {
            e.preventDefault();
            deleteEntity(id);
        },
        [deleteEntity, id],
    );
    const DeleteButton = useMemo(
        () => (
            <>
                <IconButton color="error" onClick={dialogOpenerHandler}>
                    <DeleteIcon />
                </IconButton>
            </>
        ),
        [dialogOpenerHandler],
    );
    const titleSwitcher = useMemo(() => {
        if (entityName === 'Displays_Screen') {
            return 'Modules.Displays.DisplaysDisplayEdit.deleteDialog.title';
        }
        if (entityName === 'Displays_Owner') {
            return 'Modules.Displays.DisplaysOwnerEdit.deleteDialog.title';
        }
        if (entityName === 'Displays_Tag') {
            return 'Modules.Displays.DisplaysTagEdit.deleteDialog.title';
        }
        if (entityName === 'Displays_Pack') {
            return 'Modules.Displays.DisplaysPackEdit.fields.deletePack';
        }
        return '';
    }, [entityName]);

    return (
        <>
            <DialogV2 onClose={dialogClosingHandler} open={deleteEntityState.value}>
                <Stack gap={3}>
                    <Typography align="center" variant="h1">
                        {t(titleSwitcher)}
                    </Typography>
                    <AutoGrid justifyContent="center" gap={5}>
                        <Button
                            color="success"
                            onClick={deleteHandler}
                            disabled={getDeletingById(id).state === NetworkRequestState.InProgress}
                        >
                            {t('Modules.Displays.DisplaysDisplayEdit.deleteDialog.yes')}
                        </Button>
                        <Button color="error" onClick={dialogClosingHandler}>
                            {t('Modules.Displays.DisplaysDisplayEdit.deleteDialog.no')}
                        </Button>
                    </AutoGrid>
                </Stack>
                {getDeletingById(id).state === NetworkRequestState.Error && (
                    <Box pb={2}>
                        <Alert severity="error" variant="outlined">
                            {errorConversion(String(getDeletingById(id).error))}
                        </Alert>
                    </Box>
                )}
            </DialogV2>

            {DeleteButton}
        </>
    );
};
