import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AllEsPCAFieldValues } from 'c-main/Lib';
import { ElasticSearchFilters, EsPCAReportFilterField, PCAReportCountField } from 'c-sdk';
import { Box } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { AutoGrid, Button, Filterdropdown, OptionSchema } from 'c-components';

type Props = {
    showCountFieldsDropdown?: boolean;
    allValuesByField: AllEsPCAFieldValues;
    selectedFields: ElasticSearchFilters;

    onSearch: (filters: Record<string, string[]>, counts: PCAReportCountField[]) => void;

    onReset: () => void;
    filterFields?: EsPCAReportFilterField[];
};

const defaultFields: EsPCAReportFilterField[] = [
    EsPCAReportFilterField.CreativeName,
    EsPCAReportFilterField.DisplayTown,
    EsPCAReportFilterField.DisplayTvRegion,
    EsPCAReportFilterField.DisplayConurbation,
    EsPCAReportFilterField.DisplayEnv,
    EsPCAReportFilterField.DisplayFormat,
    EsPCAReportFilterField.DisplayOwner,
    // EsPCAReportFilterField.DisplayType,
    // EsPCAReportFilterField.DisplayResolution,
    EsPCAReportFilterField.FrameId,
    // EsPCAReportFilterField.DisplayLineItemId,
    // EsPCAReportFilterField.DisplayId,
    EsPCAReportFilterField.DisplayLineName,
    EsPCAReportFilterField.Schedule,
    EsPCAReportFilterField.Dow,
    // EsPCAReportFilterField.Day,
    EsPCAReportFilterField.Hour,
    EsPCAReportFilterField.IssueId,
];

const ReportFieldFilterES: React.FC<Props> = ({
    allValuesByField,
    selectedFields,
    onSearch,
    onReset,
    showCountFieldsDropdown = true,
    filterFields = defaultFields,
}) => {
    const [internalFilterFields, setInternalFilterFields] =
        useState<Props['selectedFields']>(selectedFields);

    const onChange = useCallback((field: EsPCAReportFilterField, value) => {
        setInternalFilterFields(current => ({ ...current, [field]: value }));
    }, []);

    useEffect(() => {
        setInternalFilterFields(selectedFields);
    }, [selectedFields]);

    const t = useCommonTranslation();
    const groupedOptions = useMemo(
        () =>
            /**
             * Try to use the 'showFields' prop if it has been set to show the filters in order
             * otherwise just show all
             */
            filterFields
                .filter(field => allValuesByField?.[field]?.length > 0)
                .reduce((acc, field: EsPCAReportFilterField) => {
                    allValuesByField[field].forEach(value => {
                        if (!acc[field]) {
                            acc[field] = [];
                        }
                        acc[field].push({
                            label: value,
                            value,
                            group: t(`Modules.Main.Campaigns.Overview.Reports.fields.${field}`),
                        });
                    });
                    return acc;
                }, {} as Record<EsPCAReportFilterField, OptionSchema[]>),
        [allValuesByField, filterFields, t],
    );

    const onClickSearch = useCallback(() => {
        onSearch(internalFilterFields, []);
    }, [onSearch, internalFilterFields]);

    const elements = useMemo(() => {
        const allElements = [
            ...Object.keys(allValuesByField)
                .filter(field => groupedOptions[field]?.length > 0)
                .map(field => (
                    <Box key={field}>
                        <Filterdropdown
                            selectAllOption
                            multiple
                            options={groupedOptions[field]}
                            onChange={val => {
                                onChange(field as EsPCAReportFilterField, val);
                            }}
                            value={internalFilterFields?.[field] ?? []}
                            placeholder={t(
                                `Modules.Main.Campaigns.Overview.Reports.fields.${field}`,
                            )}
                        />
                    </Box>
                )),
        ];

        if (!showCountFieldsDropdown) {
            return allElements;
        }

        return [...allElements];
    }, [
        allValuesByField,
        groupedOptions,
        internalFilterFields,
        onChange,
        showCountFieldsDropdown,
        t,
    ]);

    return (
        <Box>
            <AutoGrid spacing={1} xs={6} sm={6} md={4} lg={3} xl={2}>
                {elements}
            </AutoGrid>
            <Box my={2}>
                <AutoGrid columnGap={1} justifyContent="center">
                    <Button onClick={onReset} key="reset">
                        {t('Modules.Main.Campaigns.Overview.Reports.advancedFiltering.reset')}
                    </Button>
                    <Button onClick={onClickSearch} key="search">
                        {t('Modules.Main.Campaigns.Overview.Reports.advancedFiltering.search')}
                    </Button>
                </AutoGrid>
            </Box>
        </Box>
    );
};

export default ReportFieldFilterES;
