import { PopDisplays_Display } from 'c-sdk';
import { SystemSearchEntityResource } from 'c-system-search';

export const displayNameGen: SystemSearchEntityResource<PopDisplays_Display>['nameGen'] = (
    entity,
    entityId,
) => {
    if (entity == null) return String(entityId);

    return [entity.name, entity.channel].filter(s => s != null && s?.length > 0).join(' - ');
};
