import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DialogV2, TabsWrapper } from 'c-components';
import { Box, DialogProps, Stack, Typography } from '@mui/material';
import { PCACampaignFramesRequestPayload, PCAFrameDetails, PCAReportMetric } from 'c-sdk';
import { TabWrapperSettings } from 'c-types';
import { ReportDateTimeframe } from 'c-reports/Types';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import merge from 'deepmerge';
import { format } from 'date-fns';
import { isPlainObject } from '@reduxjs/toolkit';
import { esFrameDetailsToMySql } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';
import FieldFrameListEs from '../Views/ByField/FieldFrameList/FieldFrameListEs';

type Props = {
    campaignId: number;
    onClose: () => void;
    metric: PCAReportMetric;
    openField: string;
    field: string;
    fieldValue: string;
    minDate: Date;
    maxDate: Date;
    currentStartDate: Date;
    currentEndDate: Date;
    currentTimeframe: ReportDateTimeframe;
    filters: PCACampaignFramesRequestPayload['fieldFilters'];
    environment: string;
    refineFilters: boolean;
    source: string;
};

const dialogProps = { maxWidth: 'xl', id: 'frame-list-dialog' } as Partial<DialogProps>;
const contentProps = { sx: { pb: 0 } };
const boxSx = { height: '80vh', display: 'flex', flexDirection: 'column' };
const TabListProps = {
    variant: 'standard',
    centered: false,
} as any;
const titleSx = { pb: 0 };

const LeaderboardFramesDialogES: React.FC<Props> = ({
    campaignId,
    onClose,
    metric,
    openField,
    field,
    fieldValue,
    minDate,
    maxDate,
    currentStartDate,
    currentEndDate,
    filters,
    currentTimeframe,
    environment,
    source,
    refineFilters,
}) => {
    const t = useCommonTranslation();
    const { start, isLoading, data, error, reset } = useAPIClientRequest(
        apiClient.ReportsElasticSearch.frameIdStats,
    );

    console.log('data', data?.data);

    const fetchFrames = useCallback(async () => {
        await to(
            start({
                campaignId,
                environment,
                source,
                fieldFilters: merge({ [field]: [fieldValue] }, filters),
                startDate:
                    currentTimeframe !== ReportDateTimeframe.All
                        ? `${format(currentStartDate, 'yyyy-MM-dd')} 00:00:00`
                        : undefined,
                endDate:
                    currentTimeframe !== ReportDateTimeframe.All
                        ? `${format(currentEndDate, 'yyyy-MM-dd')} 23:59:59`
                        : undefined,
            }),
        );
    }, [
        campaignId,
        currentEndDate,
        currentStartDate,
        currentTimeframe,
        environment,
        field,
        fieldValue,
        filters,
        source,
        start,
    ]);
    useEffect(() => {
        if (fieldValue != null) {
            fetchFrames();
        } else {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldValue]);

    const frames = useMemo(() => {
        if (!isPlainObject(data?.data?.data)) {
            return [];
        }

        return Object.entries(data?.data?.data).reduce(
            (acc, [frameId, details]) => [...acc, esFrameDetailsToMySql(frameId, details, metric)],
            [] as PCAFrameDetails[],
        );
    }, [data, metric]);

    const unbookedFrames = useMemo(() => frames.filter(f => f.unbookedBool === true), [frames]);
    const bookedFrames = useMemo(() => frames.filter(f => f.unbookedBool === false), [frames]);

    const tabs = useMemo<TabWrapperSettings['tabs']>(() => {
        const tabs = [
            {
                title: t('Modules.Main.Campaigns.Overview.Reports.frameList.frameTabAll', {
                    count: frames.length,
                }),
                content: <></>,
            },
        ];

        if (bookedFrames.length > 0)
            tabs.push({
                title: t('Modules.Main.Campaigns.Overview.Reports.frameList.frameTabBooked', {
                    count: bookedFrames.length,
                }),
                content: <></>,
            });
        if (unbookedFrames.length > 0)
            tabs.push({
                title: t('Modules.Main.Campaigns.Overview.Reports.frameList.frameTabUnbooked', {
                    count: unbookedFrames.length,
                }),
                content: <></>,
            });

        return tabs;
    }, [t, frames.length, bookedFrames.length, unbookedFrames.length]);

    const [openTab, setOpenTab] = useState(null);

    // eslint-disable-next-line consistent-return
    const visibleFrames = useMemo(() => {
        if (openTab == null || openTab === '0') return frames;
        if (openTab === '1') return bookedFrames;
        if (openTab === '2') return unbookedFrames;
    }, [openTab, frames, bookedFrames, unbookedFrames]);

    return (
        <DialogV2
            title={
                <Stack>
                    <Typography variant="h4">
                        {t(`Modules.Main.Campaigns.Overview.Reports.fields.${field}`)}: {openField}
                    </Typography>
                    {unbookedFrames.length > 0 && (
                        <TabsWrapper
                            tabs={tabs}
                            tabListProps={TabListProps}
                            onTabChange={setOpenTab}
                            type="tertiary"
                        />
                    )}
                </Stack>
            }
            titleSx={unbookedFrames.length > 0 ? titleSx : undefined}
            titleDivider
            onClose={onClose}
            open
            MuiDialogProps={dialogProps}
            MuiDialogContentProps={contentProps}
        >
            <Box sx={boxSx}>
                <FieldFrameListEs
                    field={field}
                    metric={metric}
                    fieldValue={fieldValue}
                    campaignId={campaignId}
                    minDate={minDate}
                    maxDate={maxDate}
                    refineFilters={refineFilters}
                    environment={environment}
                    frames={visibleFrames}
                    isLoading={isLoading}
                />
            </Box>
        </DialogV2>
    );
};

export default LeaderboardFramesDialogES;
