import { ModuleConstructorProps } from '../Types';
import { campaignChatFuncs } from './AiEntityModuleFuncs';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const Campaign_Chat = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs({ endpoints, httpClient, baseUrl }, 'Campaign_Chat'),
    ...campaignChatFuncs(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Campaign_Chat.chatEndpoint,
    ),
});
export default Campaign_Chat;
