import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ByFieldChartProps,
    FieldReportType,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { ReportConfig, ReportDateTimeframe } from 'c-reports/Types';
import {
    CampaignNoticeKey,
    ElasticSearchAvailableFilters,
    ElasticSearchGroupByFieldResponse,
    ElasticSearchHistogramInterval,
    ElasticSearchHistogramResponse,
    EsPCAReportField,
    PCAReportField,
} from 'c-sdk';
import apiClient from 'c-data/apiClient';
import { format } from 'date-fns';
import { OverTimeCharts } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes';
import {
    dailyHistogramToOldDataStructure,
    hourlyHistogramToOldDataStructure,
} from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/HistogramTransformers';
import FieldFilter from 'c-main/Components/Campaign/CampaignReports/Views/ByField/FieldFilter';
import { AutoGrid, Checkbox } from 'c-components';
import { useBoolean } from 'react-hanger';
import { performanceStatsToOldCampaignStats } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';

type Props = ByFieldChartProps & {
    reportType: FieldReportType;
    onFiltersUpdated: (filters: ElasticSearchAvailableFilters) => void;
    fieldFilters?: Record<string, string[]>;
    onResponse?: (response, startDate: Date, endDate: Date, timeframe: ReportDateTimeframe) => void;
    // you can set this when you're expecting to group by a single thing
    // for example, looking at how a single frame has done over time
    groupResultsByDate?: boolean;
};
const defaultFieldOptionsCount = 10;

const ESOverTimeByField: React.FC<Props> = props => {
    const {
        campaignId,
        environment,
        source,
        metric,
        onFiltersUpdated,
        fieldFilters,
        field,
        timeFrame,
        onResponse,
        groupResultsByDate,
        reportType,
    } = props;
    const [pcaData, setPcaData] = useState<ElasticSearchHistogramResponse>(null);
    useEffect(() => {
        onFiltersUpdated(pcaData?.filters);
    }, [pcaData?.filters, onFiltersUpdated]);

    const isOneDay = useMemo(() => timeFrame === ReportDateTimeframe.Daily, [timeFrame]);

    const overtimeData = useMemo(() => {
        if (isOneDay) {
            return hourlyHistogramToOldDataStructure({
                metric,
                data: pcaData,
                groupByField: !groupResultsByDate,
                onlyHourNumber: false,
            });
        }
        return dailyHistogramToOldDataStructure({
            metric,
            data: pcaData,
            groupByField: !groupResultsByDate,
        });
    }, [isOneDay, metric, pcaData, groupResultsByDate]);

    const fetchData: ReportConfig<ElasticSearchGroupByFieldResponse>['fetchData'] = useCallback(
        (start, end, timeframe) => {
            const isOneDay = timeframe === ReportDateTimeframe.Daily;
            return apiClient.ReportsElasticSearch.histogram({
                campaignId,
                showStats: true,
                interval: isOneDay
                    ? ElasticSearchHistogramInterval.Hour
                    : ElasticSearchHistogramInterval.Day,
                startDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(start, 'yyyy-MM-dd')} 00:00:00`,
                endDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(end, 'yyyy-MM-dd')} 23:59:59`,
                field: (field as EsPCAReportField) ?? EsPCAReportField.StartDate,
                environment,
                source,
                fieldFilters,
                dataStatus: fieldFilters?.[PCAReportField.DataStatus] as CampaignNoticeKey[],
            });
        },
        [campaignId, environment, field, fieldFilters, source],
    );

    const onDataUpdated: ReportConfig<any>['onDataUpdated'] = useCallback(
        (data: ElasticSearchHistogramResponse, start, end, timeframe) => {
            setPcaData(data);
            if (data?.campaign_stats) {
                onResponse(
                    { campaignStats: performanceStatsToOldCampaignStats(data?.campaign_stats) },
                    start,
                    end,
                    timeframe,
                );
            }
        },
        [onResponse],
    );

    /**
     * For example, when searching by town, this will contain a list of all towns in the PCA data.
     */
    const allFields = useMemo<string[]>(() => Object.keys(overtimeData ?? {}), [overtimeData]);

    /**
     * Will contain a list of all selected towns to be displayed in the charts.
     */
    const [selectedFields, setSelectedFields] = useState<string[]>(undefined);

    const actualSelectedFieldParts = useMemo<string[]>(() => {
        let updatedAllFields = allFields;

        if (field === 'creative_name') {
            updatedAllFields = allFields.filter(f => f !== '');
        }

        if (reportType === FieldReportType.Overall || field == null) {
            return updatedAllFields;
        }

        /**
         * will contain a list of selected field values.
         *
         * For example, if filtering by Town and you've selected 'Poole' and 'Watford',
         * this array will contain those two
         */
        const withoutMissing = (selectedFields ?? []).filter(
            sel => updatedAllFields.indexOf(sel) !== -1,
        );

        /**
         * All field values not selected. Using the towns example above, it'll contain
         * a list of all towns except 'Poole' and 'Watford'
         */
        const allExcludingSelected = updatedAllFields.filter(
            sel => withoutMissing.indexOf(sel) === -1,
        );

        /**
         * selectedFields starts as null. The options will be emptied if someone
         * manually deselects all selected options.
         */
        if (withoutMissing.length === 0 && selectedFields == null) {
            return allExcludingSelected.slice(0, defaultFieldOptionsCount);
        }
        return withoutMissing;
    }, [selectedFields, allFields, reportType, field]);

    const showOverTimeExpectedLines = useBoolean(field !== 'creative_name');
    const allFieldsWithoutEmpty = useMemo(() => {
        if (field === 'creative_name') {
            return allFields.filter(f => f !== '');
        }
        return allFields;
    }, [allFields, field]);

    const actualChartProps = useMemo<ByFieldChartProps>(
        () => ({
            ...props,
            searchTerm: '',
            pcaData: { reportData: overtimeData },
            reportWrapperProps: {
                ...props.reportWrapperProps,
                fetchData,
                onDataUpdated,
                beforeChartComponent: (
                    <>
                        {!groupResultsByDate && (
                            <AutoGrid spacing={2} xs={12} sm={6} md="auto" alignItems="center">
                                <FieldFilter
                                    allFields={allFieldsWithoutEmpty}
                                    selectedFieldValues={actualSelectedFieldParts}
                                    setSelectedFieldValues={setSelectedFields}
                                />
                                {field !== 'creative_name' && (
                                    <Checkbox
                                        label="Show Expected"
                                        isBoolean
                                        onChange={showOverTimeExpectedLines.setValue}
                                        value={showOverTimeExpectedLines.value}
                                    />
                                )}
                            </AutoGrid>
                        )}
                        {props.reportWrapperProps.beforeChartComponent}
                    </>
                ),
            },
            allFields: allFieldsWithoutEmpty,
            actualSelectedFieldParts,
        }),
        [
            props,
            overtimeData,
            fetchData,
            onDataUpdated,
            groupResultsByDate,
            allFieldsWithoutEmpty,
            actualSelectedFieldParts,
            field,
            showOverTimeExpectedLines.setValue,
            showOverTimeExpectedLines.value,
        ],
    );

    return (
        <>
            <OverTimeCharts
                {...actualChartProps}
                showExpectedLines={showOverTimeExpectedLines.value}
                timeFrame={isOneDay ? ReportDateTimeframe.Daily : timeFrame}
                field={groupResultsByDate ? undefined : field}
            />
        </>
    );
};

export default ESOverTimeByField;
