import React, { useMemo } from 'react';
import { Campaign } from 'c-sdk';
import { initials } from 'c-admin/Lib';
import { Avatar, FileBrowserFile } from 'c-components';
import { Box } from '@mui/material';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';

type Props = {
    campaign: Campaign;
    size?: number;
    preferredSize?: keyof FileBrowserFile['urls'];
};

const defaultSize = 45;

const CampaignThumbnail: React.FC<Props> = ({ campaign, size = 45, preferredSize = 'small' }) => {
    const campaignInitials = useMemo(() => initials(campaign.name, 2, true), [campaign]);
    const firstThumbnailFile = useMemo(
        () => campaign.creativeThumbnails?.find(f => f.urls?.[preferredSize] != null),
        [campaign, preferredSize],
    );
    const browserFile = useMemo<FileBrowserFile>(() => {
        if (firstThumbnailFile != null) {
            return {
                name: firstThumbnailFile.filename_raw,
                urls: firstThumbnailFile.urls,
                mimeType: firstThumbnailFile.filetype,
                fileType: firstThumbnailFile.image_type,
            } as FileBrowserFile;
        }

        return null;
    }, [firstThumbnailFile]);

    const campInitialsComponent = useMemo(() => {
        if (campaignInitials.length >= 2) {
            return (
                <Box display="flex" alignItems="flex-end">
                    {campaignInitials}
                </Box>
            );
        }

        return campaignInitials;
    }, [campaignInitials]);

    const avatarSize = useMemo(() => size * 0.9, [size]);

    return (
        <Box height="100%" display="flex" alignItems="center">
            <Avatar sx={useMemo(() => ({ height: avatarSize, width: avatarSize }), [size])}>
                {browserFile != null ? (
                    <RawFilePreview
                        file={browserFile}
                        showVideoPreview={false}
                        preferredSize={preferredSize}
                        maxHeight={size}
                        maxWidth={size}
                        fallback={campInitialsComponent}
                    />
                ) : (
                    campInitialsComponent
                )}
            </Avatar>
        </Box>
    );
};

export default CampaignThumbnail;
