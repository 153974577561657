import React, { useMemo } from 'react';
import { CardContent, Divider, List, ListItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EntityField, ListItemText, TransparentCard } from 'c-components';
import { Client, Buyer } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import { numberOfDays } from 'c-lib';
import { useEntityData } from 'c-data';
import { useDateUtils } from 'c-hooks';
import TotalCost from 'c-main/Components/Campaign/CampaignLineItems/Costing/TotalCost';
import { CampaignFormNames } from 'c-main/Types';
import useSelectedLineItems from '../../CampaignLineItems/CampaignLineItemPicker/useSelectedLineItems';

const SummaryStep = () => {
    const { formatDateString } = useDateUtils();
    const { watch } = useFormContext();
    // const days = watch(CampaignFormNames.lineItemDays);
    const startDate = watch(CampaignFormNames.start);
    const endDate = watch(CampaignFormNames.end);
    const noOfDays = useMemo(
        () => numberOfDays(new Date(startDate), new Date(endDate)),
        [startDate, endDate],
    );

    const { value: SelectedLineItems } = useSelectedLineItems();
    const t = useCommonTranslation();

    // const agency = watch(CampaignFormNames.agency_id);
    const buyer = watch(CampaignFormNames.buyer);
    const brand = watch(CampaignFormNames.client_id);

    const { getById } = useEntityData<Buyer>('Buyer');
    const buyerEntity = getById({ id: buyer });

    // const totalDays = useMemo(
    //     () =>
    //         SelectedLineItems.reduce(
    //             (total, lineItemId) => total + Number(days?.[lineItemId] ?? noOfDays),
    //             0,
    //         ),
    //     [SelectedLineItems, days, noOfDays],
    // );

    return (
        <TransparentCard title={null}>
            <CardContent sx={{ py: 0 }}>
                <List>
                    <Block
                        title={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignName',
                        )}
                        value={watch(CampaignFormNames.name)}
                    />
                    <Divider />
                    <Block
                        title={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignRef',
                        )}
                        value={watch(CampaignFormNames.reference)}
                    />
                    <Divider />

                    <Block
                        title={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignDates',
                        )}
                        value={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignDatesValue',
                            {
                                start: formatDateString({
                                    date: startDate,
                                }),
                                end: formatDateString({
                                    date: endDate,
                                }),
                                days: noOfDays,
                            },
                        )}
                    />
                    <Divider />
                    {buyer && (
                        <>
                            <Block
                                title={t('Modules.Main.Campaigns.Create.Summary.campaignBuyer')}
                                value={
                                    <EntityField<Buyer>
                                        id={buyer}
                                        entityName="Buyer"
                                        field="name"
                                    />
                                }
                            />
                            <Divider />
                        </>
                    )}
                    {!brand && (
                        <>
                            <Block
                                title={t('Modules.Main.Campaigns.Create.Summary.campaignClient')}
                                value={watch(CampaignFormNames.client_name)}
                            />
                            <Divider />
                        </>
                    )}
                    {brand && (
                        <>
                            <Block
                                title={t('Modules.Main.Campaigns.Create.Summary.campaignClient')}
                                value={
                                    <EntityField<Client>
                                        id={brand}
                                        entityName="Client"
                                        field="name"
                                    />
                                }
                            />
                            <Divider />
                        </>
                    )}
                    {/* {agency && (  */}
                    {/*    <>  */}
                    {/*        <Block */}
                    {/*            title={t('Modules.Main.Campaigns.Create.Summary.campaignAgency')} */}
                    {/*            value={ */}
                    {/*                <EntityField<Agency> */}
                    {/*                    id={agency} */}
                    {/*                    entityName="Agency" */}
                    {/*                    field="name" */}
                    {/*                /> */}
                    {/*            } */}
                    {/*        /> */}
                    {/*        <Divider /> */}
                    {/*    </> */}
                    {/* )} */}
                    {/* {!agency && ( */}
                    {/*    <> */}
                    {/*        <Block */}
                    {/*            title={t('Modules.Main.Campaigns.Create.Summary.campaignAgency')} */}
                    {/*            value={watch(CampaignFormNames.agency_name)} */}
                    {/*        /> */}
                    {/*        <Divider /> */}
                    {/*    </> */}
                    {/* )} */}
                    {buyerEntity?.latest_cost_id != null && (
                        <>
                            <ListItem>
                                <TotalCost hideIfZero lineItemIds={SelectedLineItems} />
                            </ListItem>
                        </>
                    )}
                </List>
            </CardContent>
        </TransparentCard>
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <ListItem disableGutters disablePadding>
        <ListItemText primary={title} secondary={value} />
    </ListItem>
);

export default SummaryStep;
