import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { PermissionName } from 'c-sdk';
import { useArray, usePrevious } from 'react-hanger';
import { User } from 'c-entity-types';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { LoadingSpinner } from 'c-components';
import { ChatMessage } from './types';
import NewMessage from './Components/NewMessage/NewMessage';
import MessageList from './Components/Messages/MessageList';

type Props = {
    localUser: User;
    messages: ChatMessage[];

    messageListLoading: boolean;
    messageListLoadingFailed?: React.ReactNode;

    onSendMessage: (message: string, files: File[]) => void;
    newMessageLoading: boolean;
    newMessageSuccess: boolean;
    newMessageError?: string;

    emptyMessagesContent: React.ReactNode;
    newMessagePlaceholder: string;
    sendNewMessagePermissions?: PermissionName[];
    includeAttachments?: boolean;
};

const emptyPerms = [];
const ChatWrapper: React.FC<Props> = ({
    localUser,
    messages,
    messageListLoading,
    messageListLoadingFailed,
    onSendMessage,
    newMessageLoading,
    newMessageSuccess,
    newMessageError,
    emptyMessagesContent,
    newMessagePlaceholder,
    sendNewMessagePermissions = emptyPerms,
    includeAttachments = true,
}) => {
    const noMessages = useMemo(
        () => messages.length === 0 && !messageListLoading && !messageListLoadingFailed,
        [messages.length, messageListLoading, messageListLoadingFailed],
    );
    const showMessages = useMemo(
        () => messages.length > 0 && !messageListLoading && !messageListLoadingFailed,
        [messages.length, messageListLoading, messageListLoadingFailed],
    );

    const messageEndRef = useRef<HTMLElement>();
    useEffect(() => {
        messageEndRef?.current?.scrollIntoView();
        setTimeout(() => messageEndRef?.current?.scrollIntoView(), 200);
    }, [messages]);

    const [newMessageValue, setNewMessageValue] = useState('');
    const {
        value: rawNewMessageAttachments,
        add: addNewMessageAttachment,
        removeIndex: removeNewMessageAttachment,
        clear: clearMessageMessageAttachments,
    } = useArray<File>([]);

    const onSend = useCallback(() => {
        onSendMessage(newMessageValue, rawNewMessageAttachments);
    }, [newMessageValue, rawNewMessageAttachments, onSendMessage]);

    const prevNewMessageSuccess = usePrevious(newMessageSuccess);
    useEffect(() => {
        if (!prevNewMessageSuccess && newMessageSuccess) {
            // a message was just successfully sent
            clearMessageMessageAttachments();
            setNewMessageValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessageSuccess]);

    return (
        <Box display="flex" height="100%" flex={1} flexDirection="column" overflow="hidden">
            <Box
                className="message-list"
                flex={1}
                display="flex"
                flexDirection="column"
                position="relative"
                overflow="auto"
            >
                {noMessages && (
                    <Box my="auto" mx="auto">
                        {emptyMessagesContent}
                    </Box>
                )}
                {messageListLoadingFailed && (
                    <Box my="auto" mx="auto">
                        {messageListLoadingFailed}
                    </Box>
                )}
                {messageListLoading && <LoadingSpinner />}
                {showMessages && <MessageList messages={messages} localUser={localUser} />}
                <Box id="messageEnd" ref={messageEndRef} />
            </Box>
            <IfHasAllPermissions permissions={sendNewMessagePermissions}>
                <Box className="new-message">
                    <NewMessage
                        newMessageLoading={newMessageLoading}
                        localUser={localUser}
                        newMessagePlaceholder={newMessagePlaceholder}
                        onSendMessage={onSend}
                        newMessage={newMessageValue}
                        setNewMessage={setNewMessageValue}
                        rawAttachments={rawNewMessageAttachments}
                        addAttachments={addNewMessageAttachment}
                        removeAttachment={removeNewMessageAttachment}
                        newMessageError={newMessageError}
                        showAttachments={includeAttachments}
                    />
                </Box>
            </IfHasAllPermissions>
        </Box>
    );
};

export default ChatWrapper;
