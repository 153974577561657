import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from 'c-sdk';
import { Checkbox } from 'c-components';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';
import { Box, CircularProgress } from '@mui/material';
import { useUserPermissions } from 'c-auth-module/Hooks';

type Props = RenderColumnDataProps<Campaign>;

const CampaignHighPriorityCell: React.FC<Props> = ({ entity }) => {
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity.id).state === NetworkRequestState.InProgress,
        [entity.id, getUpdatingById],
    );

    const onChange = useCallback(() => {
        update(entity.id, { id: entity.id, high_priority: !entity.high_priority } as Campaign);
    }, [update, entity.id, entity.high_priority]);

    const { hasAdminPermission } = useUserPermissions();

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Checkbox
                    disabled={loading || !hasAdminPermission}
                    checked={entity.high_priority}
                    label={null}
                    onChange={onChange}
                    isBoolean
                    checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                    icon={loading ? <CircularProgress size={15} /> : undefined}
                />
            </Box>
        </>
    );
};

export default CampaignHighPriorityCell;
