import { AxiosRequestConfig } from 'axios';
import urlcat from 'urlcat';
import {
    DisplayMediaBreakdownData,
    DisplayMediaBreakdownPayload,
    HttpProgressUpdate,
    ListSearchOptions,
    ModuleConstructorProps,
    PopDisplays_Display,
    PopDisplaysPlayoutExportPayload,
} from '../Types';
import { generateArrayQSParams } from '../Lib';
import { ListEndpointFunc } from './BaseEntityModuleFuncs';

const PopDisplays = ({ endpoints, httpClient }: ModuleConstructorProps) => ({
    playoutExport: (data: PopDisplaysPlayoutExportPayload, config?: AxiosRequestConfig) =>
        httpClient.post<{ success: boolean; message: string }>(
            endpoints.popDisplays.playoutExport,
            data,
            config,
        ),
    displayMediaBreakdown: (
        displayId: number,
        data?: DisplayMediaBreakdownPayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ data: DisplayMediaBreakdownData[] }>(
            urlcat(endpoints.Entities.PopDisplays_Display.mediaBreakdown, {
                id: displayId,
                perPage: data.perPage,
            }),
            data,
            config,
        ),
    channelFrameMediaBreakdown: (
        channelFrameId: number,
        data?: DisplayMediaBreakdownPayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ data: DisplayMediaBreakdownData[] }>(
            urlcat(endpoints.Entities.PopDisplays_ChannelFrame.mediaBreakdown, {
                id: channelFrameId,
                perPage: data.perPage,
            }),
            data,
            config,
        ),
    channelFrameExport: (data?: ListSearchOptions, config?: AxiosRequestConfig) =>
        ListEndpointFunc<{ success: boolean; message: string }>(httpClient)(
            { endpoint: endpoints.Entities.PopDisplays_ChannelFrame.export, options: data },
            config,
        ),
    displayExport: (data?: ListSearchOptions, config?: AxiosRequestConfig) =>
        ListEndpointFunc<{ success: boolean; message: string }>(httpClient)(
            { endpoint: endpoints.Entities.PopDisplays_Display.export, options: data },
            config,
        ),
    /**
     * file: File - the actual file to upload
     */
    channelFrameImport: (
        { formData, onProgress }: { formData; onProgress?: HttpProgressUpdate },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{ success: boolean; message: string }>(
            endpoints.Entities.PopDisplays_ChannelFrame.import,
            formData,
            onProgress,
            {},
            config,
        ),
    attachDisplayChannelFrames: (
        displayId: number,
        channelFrameIds: number[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ data: PopDisplays_Display }>(
            `${urlcat(endpoints.Entities.PopDisplays_Display.attachChannelFrames, {
                id: displayId,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            { channel_frame_ids: channelFrameIds },
            config,
        ),
    detachDisplayChannelFrames: (
        displayId: number,
        channelFrameIds: number[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includesString = generateArrayQSParams(includes, 'include', '?');

        return httpClient.delete<{ data: PopDisplays_Display }>(
            `${urlcat(endpoints.Entities.PopDisplays_Display.detachChannelFrames, {
                id: displayId,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            { channel_frame_ids: channelFrameIds },
            config,
        );
    },
});

export default PopDisplays;
