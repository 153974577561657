import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { HttpProgressUpdate, ModuleConstructorProps, Skin as SkinModel } from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const Skin = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<SkinModel>({ endpoints, httpClient, baseUrl }, 'Skin'),
    uploadLargeLogo: (
        {
            skinId,
            formData,
            onProgress,
        }: {
            skinId: number;
            formData;
            onProgress?: HttpProgressUpdate;
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{ data: SkinModel }>(
            urlcat(endpoints.Entities.Skin.uploadLargeLogo, { id: skinId }),
            formData,
            onProgress,
            {},
            config,
        ),
    uploadSmallLogo: (
        {
            skinId,
            formData,
            onProgress,
        }: {
            skinId: number;
            formData;
            onProgress?: HttpProgressUpdate;
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{ data: SkinModel }>(
            urlcat(endpoints.Entities.Skin.uploadSmallLogo, { id: skinId }),
            formData,
            onProgress,
            {},
            config,
        ),
    attach: (
        {
            skinId,
            users,
            agencies,
            buyers,
            clients,
            owners,
        }: {
            skinId: number;
            users?: number[];
            agencies?: number[];
            buyers?: number[];
            clients?: number[];
            owners?: number[];
        },
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            ...(users != null && { users }),
            ...(agencies != null && { agencies }),
            ...(buyers != null && { buyers }),
            ...(clients != null && { clients }),
            ...(owners != null && { owners }),
        };

        return httpClient.post<{ data: SkinModel }>(
            urlcat(endpoints.Entities.Skin.attach, {
                id: skinId,
            }),
            data,
            config,
        );
    },
});

export default Skin;
