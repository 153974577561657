import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { AllEntities, BaseEntity } from 'c-sdk';
import { SearchPayload } from '../Types/Search';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { CustomListSearchGenerator, EntityStores, SlicePaginationData } from '../Types';
import { hookUtilGetPaginatedList } from './utils';
import { paginatedDataToSearchPayload } from '../lib';

const usePaginatedEntityData = <RootState, Entity extends BaseEntity>(
    tag: string,
    entityName: keyof AllEntities,
    customSearch?: CustomListSearchGenerator,
) => {
    const dispatch = useDispatch();

    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const selectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);

    const paginatedData = useSelector((state: EntityStores<Entity>) =>
        selectors.paginationData(state, tag),
    ) as unknown as SlicePaginationData<Entity>;

    const currentMetaPage = useMemo(
        () => paginatedData?.meta?.pagination?.current_page,
        [paginatedData],
    );
    const currentLoading = useMemo(() => paginatedData?.loadingState?.state, [paginatedData]);

    const getPaginationData = useCallback(
        (searchPayload: SearchPayload<Entity>, forceRefresh = false) => {
            hookUtilGetPaginatedList(thunks.getPaginatedList)(
                tag,
                forceRefresh,
                searchPayload,
                dispatch,
                false,
                customSearch,
                currentMetaPage,
                currentLoading,
            );
        },
        [tag, thunks, dispatch, customSearch, currentMetaPage, currentLoading],
    );

    const refresh = useCallback(
        (searchOverrides?: Partial<SearchPayload<Entity>>) => {
            if (paginatedData != null) {
                getPaginationData(
                    paginatedDataToSearchPayload(paginatedData, searchOverrides),
                    true,
                );
            }
        },
        [paginatedData, getPaginationData],
    );

    const upsert = useCallback(
        (entity: Entity) => {
            dispatch(thunks.upsertEntity(entity));
        },
        [dispatch, thunks],
    );

    const upsertMany = useCallback(
        (entities: Entity[]) => {
            dispatch(thunks.upsertEntities(entities));
        },
        [dispatch, thunks],
    );

    const reset = useCallback(() => {
        dispatch(thunks.resetPaginatedList(tag));
    }, [dispatch, tag, thunks]);

    return useMemo(
        () => ({
            paginatedData,
            getPaginationData,
            refresh,
            reset,
            thunks,
            upsert,
            upsertMany,
        }),
        [paginatedData, getPaginationData, refresh, reset, thunks, upsert, upsertMany],
    );
};

export default usePaginatedEntityData;
