import React from 'react';
import { PCAReportMetric } from 'c-sdk';
import { AutoGrid, HighlightWord, NumberFormat, NumberFormatWhole } from 'c-components';
import { Box, IconButton, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import SetCampaignNotices from 'c-main/Components/Campaign/SetCampaignNotices';
import HoursMins from 'c-main/Components/Campaign/CampaignReports/HoursMins';

export const LeaderboardRows = (
    data: any[],
    {
        campaignId,
        fieldLabel,
        filterParts,
        metric,
        field,
        actualLabel,
        expectedLabel,
        inScheduleLabel,
        outScheduleLabel,
        unbookedLabel,
        showPerformance,
        percentageLabel,
        showInSchedule,
        showOutSchedule,
        showUnbooked,

        onOpenField,
    }: {
        campaignId: number;
        fieldLabel: string;
        filterParts: string[];
        metric: PCAReportMetric;
        field: string;
        actualLabel: string;
        expectedLabel: string;
        inScheduleLabel: string;
        outScheduleLabel: string;
        unbookedLabel: string;
        showPerformance: boolean;
        percentageLabel: string;

        showInSchedule: boolean;
        showOutSchedule: boolean;
        showUnbooked: boolean;

        onOpenField: (field: string) => void;
    },
) => {
    return data.map((dataRow, dataIndex) => {
        const fieldLabelText: string = dataRow[fieldLabel];
        const matchedParts: string[] = filterParts.filter(
            p => fieldLabelText.toLowerCase().indexOf(p) !== -1,
        );

        const longestMatchedPart = matchedParts.reduce((acc, curr) => {
            if (curr.length > acc.length) {
                return curr;
            }
            return acc;
        }, '');

        const actualContent =
            metric === PCAReportMetric.Time ? (
                <HoursMins
                    seconds={dataRow[actualLabel]}
                    variant="body2"
                    alwaysShowMinutes
                    showSecondsIfNoHours
                />
            ) : (
                <NumberFormatWhole value={dataRow[actualLabel]} />
            );

        const expectedContent =
            metric === PCAReportMetric.Time ? (
                <HoursMins
                    seconds={dataRow[expectedLabel]}
                    variant="body2"
                    alwaysShowMinutes
                    showSecondsIfNoHours
                />
            ) : (
                <NumberFormatWhole value={dataRow[expectedLabel]} />
            );

        const inScheduleContent =
            metric === PCAReportMetric.Time ? (
                <HoursMins
                    seconds={dataRow[inScheduleLabel]}
                    variant="body2"
                    alwaysShowMinutes
                    showSecondsIfNoHours
                />
            ) : (
                <NumberFormatWhole value={dataRow[inScheduleLabel]} />
            );

        const outScheduleContent =
            metric === PCAReportMetric.Time ? (
                <HoursMins
                    seconds={dataRow[outScheduleLabel]}
                    variant="body2"
                    alwaysShowMinutes
                    showSecondsIfNoHours
                />
            ) : (
                <NumberFormatWhole value={dataRow[outScheduleLabel]} />
            );

        const unbookedContent =
            metric === PCAReportMetric.Time ? (
                <HoursMins
                    seconds={dataRow[unbookedLabel]}
                    variant="body2"
                    alwaysShowMinutes
                    showSecondsIfNoHours
                />
            ) : (
                <NumberFormatWhole value={dataRow[unbookedLabel]} />
            );

        const performanceColor = () => {
            if (!showPerformance || Number(dataRow[expectedLabel]) <= 0) return undefined;

            return Number(dataRow[percentageLabel]) >= 100 ? 'success.main' : 'error.main';
        };

        const performanceContent = () => {
            if (Number(dataRow[expectedLabel]) <= 0) return <>-</>;

            return (
                <Box color={performanceColor()}>
                    <NumberFormat value={dataRow[percentageLabel]} />%
                </Box>
            );
        };

        return {
            content: [
                dataIndex + 1,
                <>
                    <AutoGrid
                        spacing={0}
                        sx={{
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                maxWidth: '25rem',
                                minWidth: '10rem',
                                wordBreak: 'break-word',
                            }}
                        >
                            <HighlightWord searchTerm={longestMatchedPart} text={fieldLabelText} />
                        </Typography>

                        <Box display="flex" flexWrap="nowrap">
                            {dataRow.id != null && (
                                <SetCampaignNotices
                                    campaignId={campaignId}
                                    field={field}
                                    id={dataRow.id}
                                    name={fieldLabelText}
                                />
                            )}
                            <IconButton
                                onClick={() => {
                                    onOpenField(fieldLabelText);
                                    // setOpenField(fieldLabelText);
                                }}
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Box>
                    </AutoGrid>
                </>,
                showPerformance ? performanceContent() : undefined,
                showPerformance ? expectedContent : undefined,
                showInSchedule ? inScheduleContent : undefined,
                showOutSchedule ? outScheduleContent : undefined,
                showUnbooked ? unbookedContent : undefined,
                actualContent,
            ]
                .filter(c => c != null)
                .map(c => ({ content: c })),
        };
    });
};
