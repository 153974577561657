import {
    callApi,
    errorMessageFromResponse,
    NetworkRequestState,
    normaliseDataThunks,
    timeout,
    validationErrorsFromResponse,
} from 'c-data-layer';
import { commonSchemas, apiClient, User } from 'c-data';
import { authSelectors } from 'c-auth-module/Slices';
import { passwordResetActions } from 'c-auth-module/Slices/PasswordReset';
import { CommonThunk } from 'c-types';
import { userActions, userSelectors } from './user-slice';

export const updateById =
    (id: number, user: Partial<User>): CommonThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const currentUser = authSelectors.authenticatedUser(state);
        const updatingMe = currentUser?.id === id;

        if (userSelectors.updatingByIdState(state, id) === NetworkRequestState.InProgress) {
            return;
        }

        dispatch(userActions.startUpdatingById({ id }));

        const [error, success] = await dispatch(
            callApi(
                updatingMe
                    ? apiClient.Entities.User.updateMe(user as User | any).request
                    : apiClient.Entities.User.update(id, user as User | any).request,
            ),
        );

        if (success) {
            dispatch(
                normaliseDataThunks.updateWithNormalisedData(commonSchemas.User, success.data),
            );
            dispatch(userActions.finishedUpdatingById(success.data));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (user.current_password && updatingMe) {
                // the API invalidates the session of the user whose password was updated
                dispatch(timeout());
                dispatch(passwordResetActions.finishedResetPassword());
            }
            return;
        }
        dispatch(
            userActions.failedUpdatingById({
                id,
                error: errorMessageFromResponse(error),
                validation: validationErrorsFromResponse(error),
            }),
        );
    };
