import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { CampaignRagStatus, Campaign } from 'c-sdk';
import { ButtonProps } from '@mui/material';
import { AutoGrid, Button } from 'c-components';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';

type Props = RenderColumnDataProps<Campaign>;

const colors = {
    [CampaignRagStatus.Green]: 'success',
    [CampaignRagStatus.Amber]: 'warning',
    [CampaignRagStatus.Red]: 'error',
};

const CampaignRagCell: React.FC<Props> = ({ entity }) => {
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity?.id).state === NetworkRequestState.InProgress,
        [entity?.id, getUpdatingById],
    );

    const onChange = useCallback(
        (val: CampaignRagStatus) => {
            update(entity.id, { id: entity.id, internal_rag_status: val } as Campaign);
        },
        [update, entity.id],
    );
    return (
        <AutoGrid xs={4} flexWrap="nowrap" columnGap={0.5}>
            <RagButton
                campaignStatus={entity?.internal_rag_status}
                status={CampaignRagStatus.Green}
                onClick={onChange}
                loading={loading}
            />
            <RagButton
                campaignStatus={entity?.internal_rag_status}
                status={CampaignRagStatus.Amber}
                onClick={onChange}
                loading={loading}
            />
            <RagButton
                campaignStatus={entity?.internal_rag_status}
                status={CampaignRagStatus.Red}
                onClick={onChange}
                loading={loading}
            />
        </AutoGrid>
    );
};

type RagProps = {
    campaignStatus: CampaignRagStatus;
    status: CampaignRagStatus;
    onClick: (status: CampaignRagStatus) => void;
    loading: boolean;
};
const RagButton: React.FC<RagProps> = ({ campaignStatus, status, onClick, loading }) => {
    const onBtnClick = useCallback(() => {
        onClick(status);
    }, [status, onClick]);

    return (
        <Button
            onClick={onBtnClick}
            size="small"
            disabled={loading}
            sx={{ height: 25, width: 25, minWidth: 0 }}
            color={colors[status] as ButtonProps['color']}
            variant={campaignStatus === status ? 'contained' : 'outlined'}
        />
    );
};

export default CampaignRagCell;
