import { ascend, descend, invertObj, prop, sort } from 'ramda';
import { EsPCAReportField, PCAReportField } from 'c-sdk';
import { Direction } from 'c-types';
import { en } from 'c-translation';
import { getUnixTime, parse } from 'date-fns';

const orderFuncs = {
    [EsPCAReportField.Dow]: sortByDayOfWeek,
    [PCAReportField.DayOfWeek]: sortByDayOfWeek,

    [EsPCAReportField.Hour]: sortByHour,
    [PCAReportField.Hour]: sortByHour,

    [EsPCAReportField.StartDate]: sortByDate,
    [EsPCAReportField.EndDate]: sortByDate,
};

/**
 * @param data
 * @param direction
 * @param orderByCol - The human readable name of the column
 * @param fieldName - the ES/MySQL field name, see `PCAReportField` or `EsPCAReportField`
 * @param dateFormat
 */
export const sortByName = <T = any>(
    data: T[],
    direction: Direction,
    orderByCol: string,
    fieldName: string,
    dateFormat: string,
): T[] => {
    const dir = direction === Direction.DESC ? descend : ascend;
    const byDirection = dir(prop(orderByCol));

    return sort(orderFuncs[fieldName]?.(orderByCol, direction, dateFormat) ?? byDirection, data);
};

/**
 * @param data
 * @param orderByCol - The human readable name of the column
 * @param fieldName - the ES/MySQL field name, see `PCAReportField` or `EsPCAReportField`
 * @param dateFormat
 */
export const sortByNameCustomField = (
    data: any[],
    orderByCol: string,
    fieldName: string,
    dateFormat: string,
): any[] => {
    if (orderFuncs[fieldName])
        return sort(orderFuncs[fieldName](orderByCol, Direction.ASC, dateFormat), data);

    return data;
};

const Weekdays = invertObj(en.Weekdays);

function sortByDayOfWeek(colName: string, direction: Direction) {
    return (a, b) => {
        const aNum = Number(Weekdays[a[colName]]);
        const bNum = Number(Weekdays[b[colName]]);
        return direction === Direction.ASC ? aNum - bNum : bNum - aNum;
    };
}

function sortByHour(colName: string, direction: Direction) {
    return (a, b) => {
        const aNum = Number(a[colName]);
        const bNum = Number(b[colName]);
        return direction === Direction.ASC ? aNum - bNum : bNum - aNum;
    };
}

function sortByDate(colName: string, direction: Direction, dateFormat: string) {
    return (a, b) => {
        const aNum = getUnixTime(parse(a[colName], dateFormat, new Date()));
        const bNum = getUnixTime(parse(b[colName], dateFormat, new Date()));
        // return 0;
        return direction === Direction.ASC ? aNum - bNum : bNum - aNum;
    };
}
