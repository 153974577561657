import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllEntities, BaseEntity } from 'c-sdk';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { NetworkRequestState } from '../Types/network';

const useDeleteEntityData = <RootState, Entity extends BaseEntity>(
    entityName: keyof AllEntities,
) => {
    const dispatch = useDispatch();
    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const sliceSelectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);
    const deletingById = useSelector(sliceSelectors.deletingByIdMap);

    const getDeletingById = useCallback(
        (id: number) => deletingById[id] ?? { state: NetworkRequestState.Idle },
        [deletingById],
    );

    const deleteEntity = useCallback(
        (id: number) => dispatch(thunks.deleteById(id)),
        [thunks, dispatch],
    );

    return useMemo(
        () => ({
            deleteEntity,
            getDeletingById,
            resetDeletingById: thunks.resetDeletingByIdState,
        }),
        [deleteEntity, getDeletingById, thunks.resetDeletingByIdState],
    );
};

export default useDeleteEntityData;
