import { Permission } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PermissionState } from './Permission-types';

const initialState: PermissionState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PermissionState, action: PayloadAction<string>): PermissionState => state,
};

const slice = createBaseSlice<Permission, PermissionState, typeof additionalReducers>({
    name: 'permission',
    entityName: 'Permission',
    initialState,
    reducers: additionalReducers,
});

const permissionSlice = {
    permissionActions: slice.slice.actions,
    permissionReducer: slice.slice.reducer,
    permissionAdapter: slice.adapter,
    permissionSelectors: slice.selectors,
    permissionThunks: slice.thunks,
};

export default permissionSlice;
export const {
    permissionReducer,
    permissionActions,
    permissionAdapter,
    permissionSelectors,
    permissionThunks,
} = permissionSlice;
