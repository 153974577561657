import { useCommonTranslation } from 'c-translation';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormLabel } from '@mui/material';
import { Alert, AllEntitiesDropdown, AutoGrid, Button, SelectField, TextField } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';
import { Redirect } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';

export const DisplayOwnerForm = ({ control, state, error, id }) => {
    const t = useCommonTranslation();
    const SuccessAlert = useMemo(() => {
        if (state === 'success') {
            return (
                <>
                    <Alert severity="success">
                        {t('Modules.Displays.DisplaysOwnerEdit.success')}
                    </Alert>
                    <Redirect to={PostAuthRoutes.Displays.OwnerList} />
                </>
            );
        }
        return null;
    }, [state, t]);

    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.DisplaysOwnerEdit.submit')}
            </Button>
        ),
        [state, t],
    );

    return (
        <Box>
            <AutoGrid xs={5} gap={2} pb={2}>
                <Controller
                    name="countries"
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.country')} *
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_Country"
                                useLocalEntityData
                                labelField="name"
                                multi
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.name')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="partner_id"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.partner')} *
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_Partner"
                                labelField="name"
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="markets"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.market')} *
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Market"
                                labelField="name"
                                multi
                                useLocalEntityData
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="address_line_1"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.address1')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="address_line_2"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.address2')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="address_line_3"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.address3')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="address_line_4"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.address4')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="postcode"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.postcode')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="external_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.extId')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="vendor_name"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysOwnerEdit.fields.vendor')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.status')}
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: 0,
                                        label: t(
                                            'Modules.Displays.DisplaysOwnerEdit.fields.disable',
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: t(
                                            'Modules.Displays.DisplaysOwnerEdit.fields.enable',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                    name="status"
                />
            </AutoGrid>
            {state === NetworkRequestState.Error && (
                <Box pb={2}>
                    <Alert severity="error" variant="outlined">
                        {String(error)}
                    </Alert>
                </Box>
            )}
            {SubmitButton}
            {SuccessAlert}
        </Box>
    );
};
