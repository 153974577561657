import { getEntityMapper } from '../moduleConfig';

const mapRecord = <RootState>(
    datum: Record<string, unknown>,
    schema: any,
    maps: ReturnType<typeof getEntityMapper>,
    getState: () => RootState,
) => {
    let newData = { ...datum };
    if (maps[schema.key]) {
        newData = maps[schema.key](newData, getState);
    }
    Object.entries(schema.schema).forEach(([key, value]) => {
        if (newData[key]) {
            newData[key] = mapEntities(
                value,
                newData[key] as Record<string, unknown> | Array<Record<string, unknown>>,
                maps,
                getState,
            );
        }
    });
    return newData;
};

const mapEntities = <RootState>(
    schema: any,
    data: Record<string, unknown> | Array<Record<string, unknown>>,
    maps: ReturnType<typeof getEntityMapper>,
    getState: () => RootState,
) => {
    if (schema && data && maps) {
        if (Array.isArray(schema) && Array.isArray(data)) {
            return data.map(datum => mapRecord(datum, schema[0], maps, getState));
        }
        if (!Array.isArray(schema) && !Array.isArray(data)) {
            return mapRecord<RootState>(data, schema, maps, getState);
        }
    }
    return data;
};

export default mapEntities;
