export enum NetworkRequestState {
    Idle = 'idle',
    InProgress = 'inProgress',
    Error = 'error',
    Success = 'success',
}

export type NetworkRequest = {
    state: NetworkRequestState;
    error?: string;
    status?: number;
    validation?: Record<string, string[]>;
};

export type ByIdNetworkRequests = Record<number, NetworkRequest>;
