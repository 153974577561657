import { AxiosError, AxiosResponse } from 'axios';
import to from 'await-to-js';
import { CoreThunk } from '../../Types/CoreThunk';
import { timeout } from '../../Slices/Base/base-actions';

const callApi =
    <RootState, S>(
        req: Promise<AxiosResponse<S>>,
    ): CoreThunk<RootState, Promise<[AxiosError | null, S | null, AxiosResponse<S> | null]>> =>
    async dispatch => {
        const [error, success] = await to<AxiosResponse<S>, AxiosError>(req);

        if (error != null && error?.isAxiosError === true && error?.response?.status === 401) {
            dispatch(timeout());
        }

        return [error, success?.data, success];
    };

export default callApi;
