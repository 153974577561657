import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { Buyer as BuyerModel, ModuleConstructorProps } from '../Types';
import { generateArrayQSParams } from '../Lib';
import { BaseCreateFunc, BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const Buyer = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<BuyerModel>({ endpoints, httpClient, baseUrl }, 'Buyer'),
    create: (data: BuyerModel, includes?: string[], config?: AxiosRequestConfig) =>
        BaseCreateFunc<BuyerModel>({ endpoints, httpClient, baseUrl }, 'Buyer')(
            data,
            includes,
            config,
        ),
    attachAudienceSource: (
        id: number,
        audienceSourceId: number,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        const url = `${urlcat(endpoints.Entities.Buyer.attachAudienceVersion, {
            id,
        })}${includeParams}`;

        return httpClient.post<{ success: boolean }>(
            url,
            { audience_source_id: audienceSourceId },
            config,
        );
    },
});

export default Buyer;
