import { Market } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { MarketState } from './Market-types';

const initialState: MarketState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: MarketState, action: PayloadAction<string>): MarketState => state,
};

const slice = createBaseSlice<Market, MarketState, typeof additionalReducers>({
    name: 'market',
    entityName: 'Market',
    initialState,
    reducers: additionalReducers,
});

const marketSlice = {
    marketActions: slice.slice.actions,
    marketReducer: slice.slice.reducer,
    marketAdapter: slice.adapter,
    marketSelectors: slice.selectors,
    marketThunks: slice.thunks,
};

export default marketSlice;
export const { marketReducer, marketActions, marketAdapter, marketSelectors, marketThunks } =
    marketSlice;
