import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Loading } from 'c-components';
import { authSelectors, authThunks, sessionCheckSelectors } from 'c-auth-module/Slices';
import { NetworkRequestState } from 'c-data-layer';
import { InviteRoutes, PostAuthRoutes, PreAuthRoutes } from 'c-routes';
import HotjarInitialiser from 'c-wrapper/Components/HotjarInitialiser';
import InviteLandingPage from 'c-auth-module/Pages/Invite/InviteLandingPage';
import { useIsMobileView } from 'c-hooks';
import { useSystemAlerts } from 'c-main/Hooks';
import PreAuth from '../PreAuth';
import PrivateRoute from '../PrivateRoute';
import PostAuth from '../PostAuth';
import { usePostAuthHome } from '../CommonWrapper';
import NoUserRedirect from './NoUserRedirect';
import ScrollToTop from './ScrollToTop';

const preAuthValues = Object.values(PreAuthRoutes);
const postAuthValues = (values: { [moduleName: string]: { [pageName: string]: string } }) =>
    Object.entries(values).reduce(
        (moduleAccumulator, [, module]) => [
            ...moduleAccumulator,
            ...Object.entries(module).reduce(
                (routeAccumulator, [, route]) => [...routeAccumulator, route],
                [],
            ),
        ],
        [],
    );

const allPostAuthRoutes = postAuthValues(PostAuthRoutes);

const AppWrapper = () => {
    const $user = useSelector(authSelectors.authenticatedUser);
    const $logoutState = useSelector(authSelectors.logoutState);
    const checkedSession = useSelector(sessionCheckSelectors.checkedSession);
    // const $sessionTimedOut = useSelector(authSelectors.sessionTimedOut);
    const $validatingUserSession = useSelector(authSelectors.validatingUserSession);
    const dispatch = useDispatch();
    const postAuthHome = usePostAuthHome();
    const isMobile = useIsMobileView();

    useEffect(() => {
        // check if a session validation check needs to happen on mount
        // this will be needed if the user refreshes the page after logging in, for example.
        if (!checkedSession) {
            dispatch(authThunks.validateUserSession(isMobile));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // useEffect(() => {
    //     // maybe show a session timed out overlay with a password input
    // }, [$sessionTimedOut]);

    const { fetchAlerts } = useSystemAlerts();

    useEffect(() => {
        fetchAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const privateRoute = useMemo(
        () => (
            <PrivateRoute
                authenticated={$user != null}
                redirectPath={PreAuthRoutes.Login}
                path={[...allPostAuthRoutes, '/*']}
            >
                {checkedSession && <PostAuth />}
            </PrivateRoute>
        ),
        [$user, checkedSession],
    );

    const isLoading = useMemo(() => {
        if (!checkedSession) return true;
        if ($validatingUserSession) return true;

        return $logoutState?.state === NetworkRequestState.InProgress;
    }, [checkedSession, $validatingUserSession, $logoutState]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {import.meta.env.VITE_HOTJAR_SITE_ID && <HotjarInitialiser />}
            <ScrollToTop />
            <Switch>
                <Route path={preAuthValues}>
                    <PreAuth />
                </Route>
                {!$user && (
                    <Route path={InviteRoutes.Invite}>
                        <InviteLandingPage />
                    </Route>
                )}
                {!$user && <NoUserRedirect />}
                {$user && (
                    <Route exact path="/">
                        <Redirect from="/" to={postAuthHome} />
                    </Route>
                )}
                {privateRoute}
            </Switch>
        </>
    );
};

export default AppWrapper;
