import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, List, Stack, IconButton } from '@mui/material';
import { FolderCopy, Add, Close } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { DialogV2, TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';

const MediaTableGroups = ({
    mediaGroups,
    activeGroup,
    setActiveGroup,
    handleGroupChange,
    media_ids,
    tableData,
    handleGroupRemove,
    setMedia_ids,
}) => {
    const mediaGroupKeys = Object.keys(mediaGroups);
    const newGroup = useBoolean(false);
    const [groupToAdd, setGroupToAdd] = useState<null | string>(null);
    const groupToRemove = useBoolean(false);
    const t = useCommonTranslation();
    const [newGroupName, setNewGroupName] = useState('');
    const mediaIdToNameMap = useMemo(
        () =>
            tableData.reduce((acc, row) => {
                acc[row.id] = row.name;
                return acc;
            }, {} as Record<number, string>),
        [tableData],
    );

    const mediaNames = useMemo(
        () =>
            media_ids.map(media_id => ({
                id: media_id,
                name: mediaIdToNameMap[media_id] || `ID: ${media_id}`,
            })),
        [media_ids, mediaIdToNameMap],
    );
    useEffect(() => {
        if (media_ids.length === 0) {
            newGroup.setFalse();
            setGroupToAdd(null);
            setNewGroupName('');
            groupToRemove.setFalse();
        }
    }, [groupToRemove, media_ids.length, newGroup]);
    const ungroupedMediaCount = useMemo(
        () => tableData.filter(item => item.group_name == null).length,
        [tableData],
    );

    return (
        <Box display="flex" flex={1} width="100%" flexDirection="column">
            <Box pb={4} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="small"
                    onClick={newGroup.setTrue}
                    disabled={media_ids.length === 0}
                >
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.createNewGroup')}
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    disabled={media_ids.length === 0}
                    onClick={groupToRemove.setTrue}
                >
                    {t(
                        'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.removeFromGroups',
                    )}
                </Button>
            </Box>
            <DialogV2
                onClose={newGroup.setFalse}
                open={newGroup.value}
                maxWidth="xl"
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.createNewGroup',
                )}
            >
                <Stack gap={1}>
                    <Typography variant="body2">
                        {t(
                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.newGroupName',
                        )}
                    </Typography>
                    <TextField
                        type="text"
                        value={newGroupName}
                        onChange={e => setNewGroupName(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            handleGroupChange(newGroupName);
                            newGroup.setFalse();
                        }}
                    >
                        {t(
                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.createNewGroup',
                        )}
                    </Button>
                </Stack>
            </DialogV2>

            <DialogV2
                onClose={() => setGroupToAdd(null)}
                open={groupToAdd !== null}
                maxWidth="xl"
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.addMediaToGroup',
                    { name: groupToAdd },
                )}
            >
                <Typography variant="h4" textAlign="center">
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.additionMessage')}
                </Typography>
                {mediaNames.map(({ id, name }) => (
                    <Stack
                        key={id}
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                        gap={1}
                    >
                        <Stack direction="row" gap={2} pb={2}>
                            <Typography variant="body1">{name}</Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setMedia_ids(prev => {
                                        const updated = new Set(prev);
                                        updated.delete(id);
                                        return updated;
                                    });
                                }}
                                color="error"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
                <Button
                    onClick={() => {
                        handleGroupChange(groupToAdd);
                        setGroupToAdd(null);
                    }}
                    variant="contained"
                >
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.confirm')}
                </Button>
            </DialogV2>

            <DialogV2
                onClose={groupToRemove.setFalse}
                open={groupToRemove.value}
                maxWidth="xl"
                title="Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.removeFromGroups"
            >
                <Typography variant="h4" textAlign="center">
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.additionMessage')}
                </Typography>
                {mediaNames.map(({ id, name }) => (
                    <Stack
                        key={id}
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                        gap={1}
                    >
                        <Stack direction="row" gap={2} pb={2}>
                            <Typography variant="body1">{name}</Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setMedia_ids(prev => {
                                        const updated = new Set(prev);
                                        updated.delete(id);
                                        return updated;
                                    });
                                }}
                                color="error"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
                <Button
                    onClick={() => {
                        handleGroupRemove();
                        groupToRemove.setFalse();
                    }}
                    variant="contained"
                >
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.confirm')}
                </Button>
            </DialogV2>

            <Box flex={1} overflow="auto">
                <List disablePadding>
                    <Stack gap={1}>
                        <Box
                            key="all"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={1}
                            borderRadius={1.5}
                            bgcolor={activeGroup === 'All' ? 'primary.light' : 'background.paper'}
                            sx={{
                                cursor: 'pointer',
                                boxShadow:
                                    activeGroup === 'All' ? '0px 4px 8px rgba(0,0,0,0.2)' : 'none',
                            }}
                            onClick={() => {
                                setActiveGroup('All');
                            }}
                        >
                            <Stack direction="row" gap={1}>
                                <FolderCopy fontSize="small" style={{ marginRight: 8 }} />
                                <Typography variant="body2" flex={1}>
                                    {t(
                                        'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.all',
                                    )}{' '}
                                    ({tableData.length})
                                </Typography>
                            </Stack>
                        </Box>
                        {ungroupedMediaCount > 0 && (
                            <Box
                                key="all"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                p={1}
                                borderRadius={1.5}
                                bgcolor={
                                    activeGroup === null ? 'primary.light' : 'background.paper'
                                }
                                sx={{
                                    cursor: 'pointer',
                                    boxShadow:
                                        activeGroup === null
                                            ? '0px 4px 8px rgba(0,0,0,0.2)'
                                            : 'none',
                                }}
                                onClick={() => {
                                    setActiveGroup(null);
                                }}
                            >
                                <Stack direction="row" gap={1}>
                                    <FolderCopy fontSize="small" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" flex={1}>
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.groups.ungrouped',
                                        )}{' '}
                                        ({ungroupedMediaCount})
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                        {mediaGroupKeys.map(groupKey => {
                            const group = mediaGroups[groupKey];
                            return (
                                <Box
                                    key={groupKey}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={1}
                                    borderRadius={1.5}
                                    bgcolor={
                                        activeGroup === groupKey
                                            ? 'primary.light'
                                            : 'background.paper'
                                    }
                                    sx={{
                                        cursor: 'pointer',
                                        boxShadow:
                                            activeGroup === groupKey
                                                ? '0px 4px 8px rgba(0,0,0,0.2)'
                                                : 'none',
                                    }}
                                    onClick={() => {
                                        setActiveGroup(groupKey);
                                    }}
                                >
                                    <Stack direction="row" gap={1}>
                                        <FolderCopy fontSize="small" style={{ marginRight: 8 }} />
                                        <Typography variant="body2" flex={1}>
                                            {groupKey} ({group})
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <IconButton
                                            disabled={media_ids.length === 0}
                                            onClick={() => setGroupToAdd(groupKey)}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            );
                        })}
                    </Stack>
                </List>
            </Box>
        </Box>
    );
};

export default MediaTableGroups;
