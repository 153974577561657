// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosError } from 'axios';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callApi, errorMessageFromResponse, NetworkRequestState } from 'c-data-layer';
import { UnPromisify } from 'c-types';
import { AxiosRequestData } from 'c-sdk';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type ReqType<U> = (...args: Parameters<U>) => AxiosRequestData<U>;

function useAPIClientRequest<U, T extends ReqType<U>>(apiRequest: T) {
    const requestStateRef = useRef<NetworkRequestState>(NetworkRequestState.Idle);
    const dataRef = useRef<UnPromisify<ReturnType<T>['request']>>(null);

    const [requestState, setRequestState] = useState<NetworkRequestState>(NetworkRequestState.Idle);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<UnPromisify<ReturnType<T>['request']>>(null);
    const dispatch = useDispatch();

    const abortControllerRef = useRef<AbortController>(null);

    const start = useCallback(
        async (...args: Parameters<T>) => {
            if (requestStateRef.current === NetworkRequestState.InProgress) {
                // return dataRef.current;
                abortControllerRef?.current?.abort();
            }

            setError(null);
            setRequestState(NetworkRequestState.InProgress);
            requestStateRef.current = NetworkRequestState.InProgress;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { request, controller } = apiRequest(...args);

            abortControllerRef.current = controller;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const [err, _, success]: [AxiosError, U, (typeof dataRef)['current']] = await dispatch(
                callApi(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    request,
                ),
            );

            if (!err && success != null) {
                requestStateRef.current = NetworkRequestState.Success;
                setRequestState(NetworkRequestState.Success);

                dataRef.current = success;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setData(success);
            } else if (err) {
                dataRef.current = null;
                setData(null);

                setRequestState(NetworkRequestState.Error);
                requestStateRef.current = NetworkRequestState.Error;
                const errorString = String(errorMessageFromResponse(err as any) ?? '');
                setError(errorString);

                throw Error(errorString);
            }

            return dataRef.current;
        },
        [dispatch, apiRequest],
    );

    const reset = useCallback(() => {
        setRequestState(NetworkRequestState.Idle);
        setError(null);
        setData(null);
    }, []);

    return useMemo(
        () => ({
            requestState,
            start,
            error,
            data,
            reset,
            isIdle: requestState === NetworkRequestState.Idle,
            isLoading: requestState === NetworkRequestState.InProgress,
            hasFailed: requestState === NetworkRequestState.Error,
            hasSucceeded: requestState === NetworkRequestState.Success,
            abortControllerRef,
            requestStateRef,
        }),
        [data, error, requestState, reset, start],
    );
}

export default useAPIClientRequest;
