import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { Displays_Country, PermissionName } from 'c-sdk';
import { useEntityPage } from 'c-hooks';
import * as yup from 'yup';
import { useUpdateEntityData } from 'c-data';
import { Controller, useForm } from 'react-hook-form';
import { Alert, AllEntitiesDropdown, AutoGrid, Button, SelectField, TextField } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

const DisplaysCountryEditPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { entity, id } = useEntityPage<Displays_Country>();
    const t = useCommonTranslation();
    const validationSchema = yup.object().shape({});
    const { update, getUpdatingById } = useUpdateEntityData('Displays_Country');
    const { state, error } = useMemo(() => getUpdatingById(id), [getUpdatingById, id]);
    const defaultValues = useMemo(
        () =>
            ({
                ...entity,
                owners: entity.owners.map(owner => owner.id),
            } as any),
        [entity],
    );
    const handleFormSubmit = useCallback(
        data => {
            update(id, data);
        },
        [id, update],
    );

    const { control, handleSubmit } = useForm({
        defaultValues,
    });

    const SuccessAlert = useMemo(() => {
        if (state === NetworkRequestState.Success) {
            return (
                <>
                    <Alert severity="success">
                        {t('Modules.Displays.DisplaysCountryEdit.success')}
                    </Alert>
                    <Redirect to={PostAuthRoutes.Displays.CountryList} />
                </>
            );
        }
        return null;
    }, [state, t]);
    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.DisplaysCountryEdit.submitButton')}
            </Button>
        ),
        [state, t],
    );
    return (
        <Box pt={2}>
            <Typography variant="h1" pb={2}>
                {t('Modules.Displays.DisplaysCountryEdit.description')}
            </Typography>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Box p={2}>
                    <AutoGrid xs={4} gap={2} pb={2}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    label={t('Modules.Displays.DisplaysCountryEdit.fields.name')}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="alpha_2"
                            render={({ field }) => (
                                <TextField
                                    label={t('Modules.Displays.DisplaysCountryEdit.fields.alpha2')}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="alpha_3"
                            render={({ field }) => (
                                <TextField
                                    label={t('Modules.Displays.DisplaysCountryEdit.fields.alpha3')}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="code_num"
                            render={({ field }) => (
                                <TextField
                                    label={t(
                                        'Modules.Displays.DisplaysCountryEdit.fields.numericCode',
                                    )}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="currency_id"
                            render={({ field }) => (
                                <AllEntitiesDropdown
                                    entityName="Displays_Currency"
                                    useLocalEntityData
                                    labelField="name"
                                    label={t(
                                        'Modules.Displays.DisplaysCountryEdit.fields.currency',
                                    )}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="status"
                            render={({ field }) => (
                                <SelectField
                                    label={t('Modules.Displays.DisplaysCountryEdit.fields.status')}
                                    {...field}
                                    value={field.value}
                                    fullWidth
                                    options={[
                                        {
                                            value: 0,
                                            label: t(
                                                'Modules.Displays.DisplaysCountryEdit.fields.inactive',
                                            ),
                                        },
                                        {
                                            value: 1,
                                            label: t(
                                                'Modules.Displays.DisplaysCountryEdit.fields.active',
                                            ),
                                        },
                                    ]}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="owners"
                            render={({ field }) => (
                                <AllEntitiesDropdown
                                    entityName="Displays_Owner"
                                    useLocalEntityData
                                    multi
                                    labelField="name"
                                    label={t('Modules.Displays.DisplaysCountryEdit.fields.owners')}
                                    {...field}
                                />
                            )}
                        />
                    </AutoGrid>
                    {state === NetworkRequestState.Error && (
                        <Box pb={2}>
                            <Alert severity="error" variant="outlined">
                                {String(error)}
                            </Alert>
                        </Box>
                    )}
                    {SubmitButton}
                    {SuccessAlert}
                </Box>
            </form>
        </Box>
    );
};

const setup: PageEntityConfig<Displays_Country> = {
    id: 'DisplaysCountryEditPage',
    component: DisplaysCountryEditPage,
    systemSearch: {
        title: undefined,
        description: undefined,
        icon: undefined,
        type: 'entity',
        aliases: undefined,
        route: PostAuthRoutes.Displays.DisplaysCountryEdit,
        saveAsRecentPage: true,
        entityName: 'Displays_Country',
        nameGen: (entity, id) => entity.name,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.CountriesList.metaTitle',
                link: PostAuthRoutes.Displays.CountryList,
            },
        ],
        permissions: [PermissionName.Admin],
        defaultIncludes: ['owners'],
    },
};

export default setup;
