import { AxiosRequestConfig } from 'axios';
import urlcat from 'urlcat';
import {
    CampaignScheduleListPayload,
    CreateSchedulePayload,
    HttpProgressUpdate,
    ModuleConstructorProps,
    Schedulable,
    ScheduleGet,
    UpdateSchedulablePayload,
    UpdateSchedulePayload,
} from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';
import { generateArrayQSParams } from '../Lib';

const Schedule = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<ScheduleGet>({ endpoints, httpClient, baseUrl }, 'Schedule'),
    campaignSchedules: (
        { campaign_id, date_from, date_to, includes }: CampaignScheduleListPayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ data: ScheduleGet[] }>(
            `${urlcat(endpoints.Entities.Schedule.campaignSchedules, {
                campaign: campaign_id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            { date_from, date_to },
            config,
        ),
    create: (
        {
            campaign_id,
            template,
            replace,
            name,
            creatives,
            line_items,
            screens,
            rules,
            includes,
        }: CreateSchedulePayload,
        config?: AxiosRequestConfig,
    ) => {
        let url = endpoints.Entities.Schedule.create;

        if (campaign_id) {
            url = `${urlcat(endpoints.Entities.Schedule.createInCampaign, {
                campaign: campaign_id,
            })}`;
        }

        return httpClient.post<{ data: ScheduleGet }>(
            `${url}${generateArrayQSParams(includes, 'include', '?')}`,
            { template, replace, name, creatives, line_items, screens, rules },
            config,
        );
    },
    update: (
        {
            campaign_id,
            schedule_id,
            name,
            creatives,
            line_items,
            screens,
            rules,
            includes,
        }: UpdateSchedulePayload,
        config?: AxiosRequestConfig,
    ) => {
        let url = `${urlcat(endpoints.Entities.Schedule.update, {
            id: schedule_id,
        })}`;

        if (campaign_id) {
            url = `${urlcat(endpoints.Entities.Schedule.updateInCampaign, {
                campaign: campaign_id,
                id: schedule_id,
            })}`;
        }

        return httpClient.put<{ data: ScheduleGet }>(
            `${url}${generateArrayQSParams(includes, 'include', '?')}`,
            { name, creatives, line_items, screens, rules },
            config,
        );
    },
    delete: (scheduleIds: number[], config?: AxiosRequestConfig) =>
        httpClient.delete(endpoints.Entities.Schedule.delete, { schedules: scheduleIds }, config),
    deleteRules: (scheduleId: number[], scheduleRuleIds: number[], config?: AxiosRequestConfig) =>
        httpClient.delete(
            urlcat(endpoints.Entities.Schedule.deleteRules, { id: scheduleId }),
            { rules: scheduleRuleIds },
            config,
        ),

    updateSchedulable: (
        {
            campaign_id,
            schedules,
            creative_id,
            line_item_id,
            screen_id,
            includes,
        }: UpdateSchedulablePayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.put<{ data: Schedulable[] }>(
            `${urlcat(endpoints.Entities.Schedule.updateSchedulable, {
                campaign: campaign_id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            { creative_id, line_item_id, screen_id, schedules },
            config,
        ),

    /**
     * file: File - the actual file to upload
     */
    downloadSite: (campaign: number, id: number, config?: AxiosRequestConfig) =>
        httpClient.get<{ data: string }>(
            `${urlcat(endpoints.Entities.Schedule.downloadSite, { campaign, id })}`,
            config,
        ),
    revalidateSite: (campaign: number, id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ data: string }>(
            `${urlcat(endpoints.Entities.Schedule.revalidateSite, { campaign, id })}`,
            config,
        ),

    uploadSiteList: (
        {
            formData,
            campaign,
            onProgress,
        }: { formData; campaign: number; onProgress?: HttpProgressUpdate },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{ status: 'valid' | 'invalid'; errors: []; valid: [] }>(
            `${urlcat(endpoints.Entities.Schedule.uploadSiteList, {
                campaign,
            })}`,
            formData,
            onProgress,
            {},
            config,
        ),
});

export default Schedule;
