import React from 'react';
import { Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { EntityListColumn } from 'c-pagination';
import { Displays_LineItem } from 'c-sdk';
import { Button } from 'c-components';
import cx from 'classnames';
import { useCommonTranslation } from 'c-translation';
import useSelectedLineItems from './useSelectedLineItems';

const SelectedToggle: EntityListColumn<Displays_LineItem>['RenderHeader'] = ({ column }) => {
    const { value, toggleShowSelected, showSelected } = useSelectedLineItems();
    const resultsSelected = useCommonTranslation(
        'Modules.Main.Campaigns.Create.LineItemsTable.resultsSelected',
        { count: value?.length ?? 0 },
    );

    return (
        <>
            <Button
                className={cx('toggle-selected', {
                    'show-selected': showSelected,
                    'hide-selected': !showSelected,
                })}
                disabled={value.length === 0}
                variant="outlined"
                onClick={toggleShowSelected}
                sx={{ whiteSpace: 'nowrap' }}
            >
                <Box mr={1}>{resultsSelected}</Box>
                {showSelected && <Visibility fontSize="inherit" />}
                {!showSelected && <VisibilityOff fontSize="inherit" />}
            </Button>
        </>
    );
};

export default SelectedToggle;
