import React, { useCallback, useMemo, useState } from 'react';
import { PCAFrameDetails, PCAReportMetric } from 'c-sdk';
import { Dialog, LoadingSpinner, TabsWrapper } from 'c-components';
import { Box, IconButton, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useCommonTranslation } from 'c-translation';
import { TabWrapperSettings } from 'c-types';
import { useAtom } from 'jotai';
import { atom_DateRange } from 'c-displays/atoms';
import FilterableFrameList from './FilterableFrameList';
import EsFrameByHourWeekly from './EsFrameByHourWeekly';

type Props = {
    campaignId: number;
    field: string;
    metric: PCAReportMetric;
    fieldValue?: string;
    minDate: Date;
    maxDate: Date;
    environment: string;
    refineFilters: boolean;
    frames: PCAFrameDetails[];
    isLoading: boolean;
};

const TabListProps = {
    variant: 'standard',
    centered: false,
} as any;
const HourDialogProps = {
    fullScreen: true,
    sx: { mx: 2, my: 2 },
    id: 'hourly-breakdown-dialog',
};
const HourDialogContentProps = { sx: { pb: 0, pt: 0 } };

const FieldFrameListEs: React.FC<Props> = ({
    campaignId,
    field,
    metric,
    fieldValue,
    minDate,
    maxDate,
    environment,
    frames,
    isLoading,
}) => {
    const [selectedFrame, setSelectedFrame] = useState<string>(null);
    const [atomTimeFrameValue, setAtomTimeFrameValue] = useAtom(atom_DateRange);
    const deSelectFrame = useCallback(() => {
        setSelectedFrame(null);
        setAtomTimeFrameValue({ startDate: null, endDate: null });
    }, [setAtomTimeFrameValue]);

    const selectedFrameData = useMemo(
        () => frames.find(f => f.frameId === selectedFrame),
        [selectedFrame, frames],
    );

    const baseFrameTableProps = useMemo(
        () => ({ campaignId, field, minDate, maxDate, frame: selectedFrameData, environment }),
        [campaignId, environment, field, maxDate, minDate, selectedFrameData],
    );

    const t = useCommonTranslation();
    const defaultOpenTab = useMemo(() => {
        if (metric === PCAReportMetric.Impacts) return '0';
        if (metric === PCAReportMetric.Plays) return '1';

        return '2';
    }, [metric]);
    const metricTabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: t('Modules.Main.Campaigns.Overview.Tabs.impactsReports'),
                content: (
                    <HourTableWrapper>
                        <EsFrameByHourWeekly
                            {...baseFrameTableProps}
                            metric={PCAReportMetric.Impacts}
                        />
                    </HourTableWrapper>
                ),
            },
            {
                title: t('Modules.Main.Campaigns.Overview.Tabs.playsReports'),
                content: (
                    <HourTableWrapper>
                        <EsFrameByHourWeekly
                            {...baseFrameTableProps}
                            metric={PCAReportMetric.Plays}
                        />
                    </HourTableWrapper>
                ),
            },
            {
                title: t('Modules.Main.Campaigns.Overview.Tabs.timeReports'),
                content: (
                    <HourTableWrapper>
                        <EsFrameByHourWeekly
                            {...baseFrameTableProps}
                            metric={PCAReportMetric.Time}
                        />
                    </HourTableWrapper>
                ),
            },
        ],
        [baseFrameTableProps, t],
    );

    const dateFormatter = useMemo(
        () => date => {
            if (date != null) {
                return date
                    .toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    })
                    .replace(/\s+/g, '/');
            }
            return '';
        },
        [],
    );

    return (
        <Box flex={0.99} display="flex" overflow="hidden">
            {isLoading && <LoadingSpinner />}
            {frames.length > 0 && (
                <FilterableFrameList
                    key={fieldValue}
                    field={field}
                    frames={frames}
                    onSelect={setSelectedFrame}
                    metric={metric}
                />
            )}
            <Dialog
                title={
                    <Stack>
                        <Box display="flex" justifyContent="space-between">
                            <Box />
                            <Box>{`${selectedFrameData?.address}`}</Box>
                            <IconButton onClick={deSelectFrame}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-evenly"
                            fontWeight={100}
                            sx={{
                                backgroundColor: 'rgb(244, 245, 250)',
                                borderRadius: '7px',
                                fontSize: '12px',
                                width: '70%',
                            }}
                            m="auto"
                            p={1}
                        >
                            <Stack direction="row">
                                <Box fontWeight={500} pr={1}>
                                    {t('Modules.Main.Campaigns.Overview.Reports.frameID')}:
                                </Box>
                                {`${selectedFrameData?.frameId}`}
                            </Stack>
                            <Stack direction="row">
                                <Box fontWeight={500} pr={1}>
                                    {t('Modules.Main.Campaigns.Overview.Reports.campaignDates')}:
                                </Box>
                                {`(${dateFormatter(minDate)}) - (${dateFormatter(maxDate)})`}
                            </Stack>
                            {atomTimeFrameValue != null && (
                                <Stack direction="row">
                                    <Box fontWeight={500} pr={1}>
                                        {t('Modules.Main.Campaigns.Overview.Reports.pageDates')}:
                                    </Box>
                                    {`(${dateFormatter(
                                        atomTimeFrameValue?.startDate,
                                    )}) - (${dateFormatter(atomTimeFrameValue?.endDate)})`}
                                </Stack>
                            )}
                        </Box>
                    </Stack>
                }
                onClose={deSelectFrame}
                show={selectedFrame !== null}
                removeForm
                MuiDialogProps={HourDialogProps}
                MuiDialogContentProps={HourDialogContentProps}
                cancelLabel="Modules.Main.Campaigns.Overview.Reports.frameList.closeDialogBtn"
                content={
                    <>
                        <TabsWrapper
                            defaultOpenTab={defaultOpenTab}
                            tabs={metricTabs}
                            type="tertiary"
                            tabListProps={TabListProps}
                        />
                    </>
                }
            />
        </Box>
    );
};

const HourTableWrapper: React.FC<{ children }> = ({ children }) => (
    <Box
        height="75vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
    >
        {children}
    </Box>
);

export default FieldFrameListEs;
