import React, { useCallback, useMemo } from 'react';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';
import { RenderColumnDataProps } from 'c-pagination';
import { Box, CircularProgress, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { Button } from 'c-components';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { WarningAmber } from '@mui/icons-material';
import { Campaign } from 'c-sdk';

type Props = RenderColumnDataProps<Campaign>;

const loadingEl = <CircularProgress size={15} />;
const loadingElSmall = <CircularProgress size={10} sx={{ ml: 1 }} />;
const resetSx = { display: 'flex', alignItems: 'center' };
const resetErrSx = { ml: 1 };

const CampaignLevelNotices: React.FC<Props> = ({ entity }) => {
    const { start, isLoading, hasFailed, error } = useAPIClientRequest(
        apiClient.Entities.Campaign.resetNotices,
    );
    const { update, getUpdatingById, upsert } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity?.id).state === NetworkRequestState.InProgress,
        [entity?.id, getUpdatingById],
    );

    const onResetStatus = useCallback(async () => {
        const [err, succ] = await to(start(entity.id, ['notices']));

        if (!err && succ.data.data.id === entity.id) {
            upsert(succ.data.data as unknown as Campaign);
        }
    }, [start, entity, upsert]);

    const onAwaitingDataSwitched = useCallback(() => {
        if (entity) {
            update(entity?.id, {
                id: entity?.id,
                data_status: {
                    ...entity?.data_status,
                    awaiting_data: !entity?.data_status?.awaiting_data,
                },
            } as Campaign);
        }
    }, [update, entity]);

    const onInspectingDataSwitched = useCallback(() => {
        if (entity) {
            update(entity?.id, {
                id: entity?.id,
                data_status: {
                    ...entity?.data_status,
                    inspecting_data: !entity?.data_status?.inspecting_data,
                },
            } as Campaign);
        }
    }, [update, entity]);

    return (
        <Stack rowGap={2} sx={{ whiteSpace: 'nowrap' }}>
            <StatusSwitch
                label="Modules.Main.Campaigns.DataStatus.awaitingDataLabel"
                state={entity?.data_status?.awaiting_data}
                onChange={onAwaitingDataSwitched}
                loading={loading}
            />
            <StatusSwitch
                label="Modules.Main.Campaigns.DataStatus.inspectingDataLabel"
                state={entity?.data_status?.inspecting_data}
                onChange={onInspectingDataSwitched}
                loading={loading}
            />
            <Button
                sx={resetSx}
                disabled={isLoading}
                onClick={onResetStatus}
                color={hasFailed ? 'error' : undefined}
                variant="outlined"
            >
                {useCommonTranslation('Modules.Main.Campaigns.DataStatus.resetDataStatusLabel')}
                {isLoading && loadingElSmall}
                {hasFailed && (
                    <Tooltip title={String(error)}>
                        <WarningAmber fontSize="inherit" sx={resetErrSx} />
                    </Tooltip>
                )}
            </Button>
        </Stack>
    );
};

const StatusSwitch: React.FC<{
    label: TranslationPath;
    state: boolean;
    onChange: () => void;
    loading: boolean;
}> = ({ label, state, onChange, loading }) => (
    // setting a height so the UI doesn't jump around when switching bet
    <Box display="flex" justifyContent="space-between" alignItems="center" height={15}>
        <Typography variant="caption">
            <Translate path={label} />
        </Typography>
        {loading ? loadingEl : <Switch checked={state} onChange={onChange} size="small" />}
    </Box>
);

export default CampaignLevelNotices;
