import { AxiosRequestConfig } from 'axios';
import { Creative as CreativeModel, HttpProgressUpdate, ModuleConstructorProps } from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';
import { generateArrayQSParams } from '../Lib';

const Creative = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<CreativeModel>({ endpoints, httpClient, baseUrl }, 'Creative'),
    downloadLink: (ids: number[] | string[]) =>
        `${baseUrl}${endpoints.Entities.Creative.download}${generateArrayQSParams(
            ids,
            'creatives',
            '?',
        )}`,
    delete: (creatives: number[], config?: AxiosRequestConfig) =>
        httpClient.delete(`${endpoints.Entities.Creative.delete}`, { creatives }, config),
    update: (creatives: CreativeModel[], includes?: string[], config?: AxiosRequestConfig) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.put<{
            data: CreativeModel[];
        }>(`${endpoints.Entities.Creative.update}${includeParams}`, { creatives }, config);
    },
    create: (
        {
            formData,
            onProgress,
        }: {
            /**
             * campaign_id: number - the campaign id to attach the creative to
             * group_name?: string - create a new group for the new creative by giving it a name
             * group_id?: number - upload the creative to a specific group
             * file: File - the actual file to upload
             * live_date?: string - date string to set the initial live date to
             * end_date?: string - date string to set the initial end date to
             * name?: string - the name for the new creative
             */
            formData;
            onProgress?: HttpProgressUpdate;
        },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.postFile<{ data: CreativeModel }>(
            `${endpoints.Entities.Creative.create}${includeParams}`,
            formData,
            onProgress,
            {},
            config,
        );
    },
});

export default Creative;
