import React, { useEffect, useMemo } from 'react';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { userIcon } from 'c-main/icons';
import { PermissionName } from 'c-sdk';
import { User } from 'c-entity-types';
// eslint-disable-next-line import/no-cycle
import UserEditPassword from 'c-admin/Components/User/UserEditPassword';
import { TabWrapperSettings } from 'c-types';
import { TabsWrapper } from 'c-components';
import { useEntityPage } from 'c-hooks';
import ReactGA from 'react-ga';
import UserEditWrapper from './Components/UserEditWrapper';

const passwordUpdatePerms = [PermissionName.AdminCan_change_passwords];
const EditUserPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Admin.UserEdit.userDetailsTab',
                content: <UserEditWrapper id={id} />,
                tabPanelStyling: { py: 0 },
            },
            {
                title: 'Modules.Admin.UserEdit.passwordTab',
                permissions: passwordUpdatePerms,
                content: <UserEditPassword id={id} />,
                tabPanelStyling: { py: 0 },
            },
        ],
        [id],
    );
    return <TabsWrapper tabs={tabs} />;
};

const setup: PageEntityConfig<User> = {
    id: 'EditUserPage',
    component: EditUserPage,
    systemSearch: {
        title: 'Modules.Admin.UserEdit.metaTitle',
        description: 'Modules.Admin.UserEdit.description',
        type: 'entity',

        route: PostAuthRoutes.Admin.EditUser,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.UsersList.metaTitle', link: PostAuthRoutes.Admin.UserList },
        ],
        icon: userIcon,
        entityName: 'User',
        defaultIncludes: [
            'roles',
            'buyers',
            'agencies',
            'clients',
            'owners',
            'buyer',
            'agency',
            'client',
            'owner',
            'locale',
        ],
        searchColumn: ['name', 'email'],
        prefix: 'SystemSearch.prefixes.user',
        prefixDescription: 'SystemSearch.prefixDescriptions.user',
        nameGen: (entity, id) => (entity ? entity.name : String(id)),
        permissions: [
            PermissionName.UniledportalUserRead,
            PermissionName.UniledportalUserUpdate,
            PermissionName.UniledportalUserCreate,
        ],
    },
};

export default setup;
