import { CampaignBookingSummary, Campaign } from 'c-sdk';

/**
 * basically trying to merge & dedupe the old and new booking summaries in case one is uploaded via the old system
 */
export const getCampaignBookingSummaries = (campaign: Campaign): CampaignBookingSummary[] => {
    return campaign?.bookingSummaries ?? [];
    // const summaries = [...(campaign.bookingSummaries ?? [])];
    // const uniqueNames = summaries.map(sum => sum.name);
    //
    // if (Array.isArray(campaign.old_booking_summaries)) {
    //     campaign.old_booking_summaries?.forEach(({ name, url }) => {
    //         /**
    //          * if its not in the list of entities and is the in old booking summaries
    //          * it may have been uploaded from the old portal
    //          */
    //         if (uniqueNames.indexOf(name) === -1) {
    //             summaries.push({
    //                 id: Math.random(),
    //                 name,
    //                 path: url,
    //                 raw_path: url,
    //                 campaign_id: campaign.id,
    //                 created_at: null,
    //             });
    //         }
    //     });
    // }
    //
    // return summaries;
};
