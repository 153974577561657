import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseBoolean, usePrevious } from 'react-hanger';
import { Button, DialogV2, FlashMessage, OptionSchema, SelectField } from 'c-components';
import { CreativeGroup, Creative } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';

type Props = {
    dialogState: UseBoolean;
    groups: CreativeGroup[];
    selectedCreatives: number[];

    updateCreatives: (creatives: Partial<Creative>[]) => void;
    updateCreativesState: NetworkRequestState;
    updateCreativesError?: string;
};

const MoveCreativesDialog: React.FC<Props> = ({
    dialogState: { setFalse: CloseDialog, value: DialogOpen },
    groups,
    selectedCreatives,
    updateCreatives,
    updateCreativesState,
    updateCreativesError,
}) => {
    const t = useCommonTranslation();
    const [newGroups, setNewGroups] = useState<number[]>([]);

    const onGroupChanged = useCallback(e => {
        setNewGroups(e.target.value);
    }, []);

    useEffect(() => {
        setNewGroups(null);
    }, [DialogOpen]);

    const onSave = useCallback(() => {
        // if `allArtworkVal` set new group to null. They're essentially moving it to no group.
        updateCreatives(
            selectedCreatives.map(id => ({
                id,
                creativeGroupIds: newGroups ?? [],
                // push the new group id into the existing list of groups the creative belongs to
                // uniq([
                //     ...data.creatives.find(({ id: CreativeId }) => id === CreativeId)
                //         ?.creativeGroupIds,
                //     newGroup,
                // ]),
            })),
        );
    }, [newGroups, selectedCreatives, updateCreatives]);

    const options = useMemo<OptionSchema[]>(
        () => [
            // {
            //     label: t('Modules.Main.CreativeManagement.creatives.controls.move.allArtwork'),
            //     value: allArtworkVal,
            // },
            ...groups.map(g => ({ label: g.name, value: g.id })),
        ],
        [groups],
    );

    const prevLoadingState = usePrevious(updateCreativesState);
    useEffect(() => {
        if (
            updateCreativesState === NetworkRequestState.Success &&
            prevLoadingState === NetworkRequestState.InProgress
        ) {
            CloseDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCreativesState]);
    return (
        <DialogV2
            onClose={CloseDialog}
            open={DialogOpen}
            title="Modules.Main.CreativeManagement.creatives.controls.move.title"
            actions={
                <>
                    <Button
                        onClick={onSave}
                        disabled={updateCreativesState === NetworkRequestState.InProgress}
                    >
                        {useCommonTranslation(
                            'Modules.Main.CreativeManagement.creatives.controls.move.submitLabel',
                        )}
                    </Button>
                </>
            }
        >
            <>
                <Box>
                    <SelectField
                        options={options}
                        value={newGroups}
                        onChange={onGroupChanged}
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.controls.move.chooseGroupLabel',
                        )}
                        id="move-files-folder-dropdown"
                        multiple
                    />
                    {updateCreativesState === NetworkRequestState.Error && (
                        <Box mt={2}>
                            <FlashMessage status="error">
                                {String(updateCreativesError)}
                            </FlashMessage>
                        </Box>
                    )}
                </Box>
            </>
        </DialogV2>
    );
};

export default MoveCreativesDialog;
