import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { taskQueueIcon } from 'c-main/icons';
import { PermissionName, PopDisplays_JobStatus } from 'c-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
    RenderRowProps,
} from 'c-pagination';
import { AutoGrid, Button, CountdownProgressCircle } from 'c-components';
import { useEntityData } from 'c-data';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

import ExpandableHtmlContent from 'c-admin/Pages/TaskQueuePage/ExpandableHtmlContent';

import { useCommonTranslation } from 'c-translation';
import StopTaskCol from './StopTaskCol';
import { isErrorNote, isSuccessNote } from './lib';

// Helper to parse dates
const parseDate = (date: Date | string | null): Date | null => {
    if (!date) return null;
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

const HumanTimeFormat = ({ time }) => {
    if (!time) return <></>;

    // Convert string to Date if necessary
    let date: Date;
    if (typeof time === 'string') {
        date = new Date(time);
        if (Number.isNaN(date.getTime())) {
            console.warn(`Invalid date string provided to shrinkDate: "${time}"`);
            return null;
        }
    } else {
        date = time;
    }

    const now = new Date();
    const isToday =
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear();

    // Format time as 'HH:MM'
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
    const formattedTime = timeFormatter.format(date);

    if (isToday) {
        return (
            <Typography align="left" fontSize="small">
                {formattedTime}
            </Typography>
        );
    }

    // Format date as 'DD MMM' (e.g., '12 Dec')
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
    });
    const formattedDate = dateFormatter.format(date);

    return (
        <Typography align="left" fontSize="small">
            {formattedTime}{' '}
            <em>
                <small>({formattedDate})</small>
            </em>
        </Typography>
    );
};

const DurationTimeFormat = ({ started_at, completed_at }) => {
    if (!started_at) return <></>;

    const start = parseDate(started_at);
    if (!start) {
        console.warn(`Invalid started_at date: "${started_at}"`);
        return null;
    }

    const end = parseDate(completed_at) || new Date();
    let diff = Math.abs(end.getTime() - start.getTime()) / 1000; // difference in seconds

    const units: { label: string; seconds: number }[] = [
        { label: 'y', seconds: 31536000 },
        { label: 'm', seconds: 2592000 },
        { label: 'w', seconds: 604800 },
        { label: 'd', seconds: 86400 },
        { label: 'h', seconds: 3600 },
        { label: 'm', seconds: 60 },
        { label: 's', seconds: 1 },
    ];

    // Maximum number of parts to include
    const maxParts = 3;

    const parts = units.reduce((acc: string[], unit) => {
        if (acc.length >= maxParts) return acc; // Limit to maxParts

        const value = Math.floor(diff / unit.seconds);
        if (value > 0) {
            acc.push(`${value}${unit.label}`);
            diff -= value * unit.seconds;
        }
        return acc;
    }, []);

    return (
        <Typography fontSize="small" noWrap>
            {parts.join(' ')}
        </Typography>
    );
};

const columns = [
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_JobStatus',
        orderable: true,
        RenderCell: ({ entity }) => (
            <Typography align="left" fontSize="small" noWrap>
                {entity.id}
            </Typography>
        ),
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.nameCol',
        field: 'name',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: ({ entity }) => (
            <>
                <Typography
                    fontSize="small"
                    align={'left'}
                    sx={{ maxWidth: 400, whiteSpace: 'normal', overflowWrap: 'break-word' }}
                >
                    <ExpandableHtmlContent contentHtml={entity.name} />
                </Typography>
            </>
        ),
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.createdCol',
        field: 'created_at',
        entityName: 'PopDisplays_JobStatus',
        textAlign: 'center',
        orderable: true,
        RenderCell: ({ entity }) => <HumanTimeFormat time={entity.created_at} />,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.startedAtCol',
        field: 'started_at',
        entityName: 'PopDisplays_JobStatus',
        textAlign: 'center',
        orderable: true,
        RenderCell: ({ entity }) => <HumanTimeFormat time={entity.started_at} />,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.updatedAtCol',
        field: 'updated_at',
        entityName: 'PopDisplays_JobStatus',
        textAlign: 'center',
        orderable: true,
        RenderCell: ({ entity }) => <HumanTimeFormat time={entity.updated_at} />,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.completedAtCol',
        field: 'completed_at',
        entityName: 'PopDisplays_JobStatus',
        textAlign: 'center',
        orderable: true,
        RenderCell: ({ entity }) => <HumanTimeFormat time={entity.completed_at} />,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.durationCol',
        field: 'duration',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: ({ entity }) => (
            <DurationTimeFormat completed_at={entity.completed_at} started_at={entity.started_at} />
        ),
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.statusCol',
        field: 'notes',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: ({ entity }) => (
            <Typography
                fontSize="small"
                sx={{ maxWidth: 300, whiteSpace: 'normal', overflowWrap: 'break-word' }}
            >
                <ExpandableHtmlContent contentHtml={entity.notes} />
            </Typography>
        ),
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: '',
        field: 'actions',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: StopTaskCol,
    }),
];
const paginationTag = 'task-queue-list-search';

const textSearchCols = ['name'];

const TaskQueuePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const [timer, setTimer] = useState(2_500);
    const [inputValue, setInputValue] = useState<string | number>(timer);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onTick = useCallback(() => {
        ref?.current?.search?.();
    }, []);
    const { keyedEntities } = useEntityData<PopDisplays_JobStatus>('PopDisplays_JobStatus');
    const generateSx = useCallback<RenderRowProps<any>['generateSx']>(
        (row, id) => {
            if (keyedEntities?.[id]) {
                if (isErrorNote(keyedEntities[id]))
                    return { bgcolor: '#FF7F7F', color: 'error.contrastText' };
                if (isSuccessNote(keyedEntities[id]))
                    return { bgcolor: '#90ee90', color: 'success.contrastText' };
            }
            return null;
        },
        [keyedEntities],
    );
    const handleInputChange = event => {
        const value = event.target.value;
        setInputValue(value);
    };

    const handleSubmit = useCallback(() => {
        const MIN_TIMER_VALUE = 1000;
        const MAX_TIMER_VALUE = 20000;
        const newValue = Number(inputValue);
        if (newValue < MIN_TIMER_VALUE) {
            setTimer(MIN_TIMER_VALUE);
            setInputValue(MIN_TIMER_VALUE);
        } else if (newValue > MAX_TIMER_VALUE) {
            setTimer(MAX_TIMER_VALUE);
            setInputValue(MAX_TIMER_VALUE);
        } else {
            setTimer(newValue);
        }
    }, [inputValue]);
    const t = useCommonTranslation();
    const TimeField = useMemo(
        () => (
            <AutoGrid mb={2} gap={2} display="flex" alignItems="center">
                <Typography variant="body1">
                    {t('Modules.PopDisplays.JobStatus.refresh')}:
                </Typography>
                <TextField value={inputValue} onChange={handleInputChange} type="number" />
                <Button onClick={handleSubmit} variant="text" color="primary">
                    {t('Modules.PopDisplays.JobStatus.setTime')}
                </Button>
            </AutoGrid>
        ),
        [t, inputValue, handleSubmit],
    );

    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.JobStatus.metaTitle"
            // controls={useMemo(() => [TimeField], [TimeField])}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                revertToIdSearchOnNumberOnlyInput={false}
                baseEntityName="PopDisplays_JobStatus"
                textSearchColumns={textSearchCols}
                generateRowSx={generateSx}
                tableProps={{
                    // spacedOutRows: true,
                    boxShadow: 0,
                    sx: { borderCollapse: 'separate' },
                }}
                // disabledRowDividers
                orderBy="id"
                rendererType={FilterableEntityTableRendererType.Contained}
                dense
                loadBar={false}
                afterFilters={
                    <Box height="100%" display="flex" alignItems="center" pb={0.5}>
                        <CountdownProgressCircle
                            loop
                            duration={timer}
                            onComplete={onTick}
                            size={30}
                            thickness={8}
                        />
                    </Box>
                }
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'TaskQueuePage',
    component: TaskQueuePage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.JobStatus.metaTitle',
        description: 'Modules.PopDisplays.JobStatus.description',
        type: 'page',
        icon: taskQueueIcon,
        aliases: 'SystemSearch.aliases.popDisplaysTaskQueue',
        route: PostAuthRoutes.Admin.TaskQueue,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.PopDisplays.JobStatus.metaTitle' },
        ],
        permissions: [PermissionName.Pop_displaysFailed_jobsRead],
    },
};

export default setup;
