import { AxiosRequestConfig } from 'axios';
import {
    CampaignPopShot as CampaignPopShotModel,
    HttpProgressUpdate,
    ModuleConstructorProps,
} from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';
import { generateArrayQSParams } from '../Lib';

const CampaignPopShot = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<CampaignPopShotModel>(
        { endpoints, httpClient, baseUrl },
        'CampaignPopShot',
    ),
    create: (
        {
            formData,
            onProgress,
            includes,
        }: {
            /**
             * campaign_id: number - the campaign id to attach the pop shot to
             * line_item_id: string - the line item id id to attach the pop shot to
             * type: string 'professional'|'pop_shot'
             * file: File - the actual file to upload
             */
            formData;
            onProgress?: HttpProgressUpdate;
            includes?: string[];
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{ data: CampaignPopShotModel }>(
            `${endpoints.Entities.CampaignPopShot.create}${generateArrayQSParams(
                includes ?? [],
                'include',
                '?',
            )}`,
            formData,
            onProgress,
            {},
            config,
        ),
    update: (
        popShots: Pick<CampaignPopShotModel, 'id' | 'type'>[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.put<{
            data: CampaignPopShotModel[];
        }>(
            `${endpoints.Entities.CampaignPopShot.update}${includeParams}`,
            { pop_shots: popShots },
            config,
        );
    },
});

export default CampaignPopShot;
