import React, { useMemo } from 'react';
import { Box, Chip, CircularProgress, InputAdornment, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import { NetworkRequestState } from 'c-data-layer';
import { TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { SystemSearchEntityLoading } from '../../types';

const useStyles = makeStyles(() => ({
    searchInput: {
        caretColor: 'white',
        color: 'white',
        fontSize: '24px',
        borderBottom: 'black',
    },
}));

const SystemSearchSearchField = React.forwardRef(
    (
        { entityResults, ...props }: TextFieldProps & { entityResults: SystemSearchEntityLoading },
        ref,
    ) => {
        const errorSearching = useCommonTranslation('SystemSearch.errorSearching');
        const noResults = useCommonTranslation('SystemSearch.noResults');
        const { searchInput } = useStyles();
        const endAdornment = useMemo(() => {
            if (entityResults.state === NetworkRequestState.InProgress) {
                return (
                    <InputAdornment position="end">
                        <Box id="system-search-spinner" mr="10px" color="white">
                            <CircularProgress size="30px" color="inherit" />
                        </Box>
                    </InputAdornment>
                );
            }
            if (entityResults.state === NetworkRequestState.Error) {
                return (
                    <InputAdornment position="end">
                        <Chip size="small" label={errorSearching} color="secondary" />
                    </InputAdornment>
                );
            }
            if (
                entityResults.state === NetworkRequestState.Success &&
                entityResults.results.length === 0
            ) {
                return (
                    <InputAdornment position="end">
                        <Chip size="small" label={noResults} />
                    </InputAdornment>
                );
            }
            return null;
        }, [entityResults.results.length, entityResults.state, errorSearching, noResults]);
        return (
            <TextField
                {...props}
                whiteBackdrop={false}
                greyBackdrop={false}
                variant="standard"
                placeholder={useCommonTranslation('SystemSearch.defaultSearchPlaceholder')}
                spellCheck={false}
                InputProps={{
                    className: searchInput,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search color="inherit" />
                        </InputAdornment>
                    ),
                    endAdornment,
                }}
                autoFocus
                ref={ref}
            />
        );
    },
);

export default SystemSearchSearchField;
