import {
    ElasticSearchPerformanceStats,
    ElasticSearchSummaryResponse,
    PCAAnalyticsReportResponse,
    PCAReportMetric,
    PCAReportPlayStats,
} from 'c-sdk';
import { esValuesToPcaValues, performanceStatsToOldCampaignStats } from './Transformers';

export const elasticSearchSummaryToOldSummary = (
    data: ElasticSearchSummaryResponse,
): PCAAnalyticsReportResponse => {
    if (!data) return null;

    return {
        campaignStats: performanceStatsToOldCampaignStats(data?.data?.campaign_stats),
        lineItemName: groupedByField(PCAReportMetric.Impacts, data?.data?.display_line_item_name),
        tvRegion: groupedByField(PCAReportMetric.Impacts, data?.data?.display_tv_region),
        mediaOwner: groupedByField(PCAReportMetric.Impacts, data?.data?.display_owner),
        dayOfWeek: groupedByField(PCAReportMetric.Impacts, data?.data?.day_of_week),
        hour: {},
        dailyImpacts: groupedByField(PCAReportMetric.Impacts, data?.data?.daily_stats),
        dailyPlays: groupedByField(PCAReportMetric.Plays, data?.data?.daily_stats),
    };
};

const groupedByField = (
    metric: PCAReportMetric,
    data: Record<string, ElasticSearchPerformanceStats>,
): Record<string, PCAReportPlayStats> =>
    Object.entries(data ?? {}).reduce(
        (acc, [fieldName, values]) => ({
            ...acc,
            [fieldName]: esValuesToPcaValues(metric, values),
        }),
        {},
    );
