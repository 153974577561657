import { CampaignCreativesResponse, PermissionName, UserType } from 'c-sdk';
import { CustomFilterConfigType, FilterConfigSelect } from 'c-pagination';

export const AssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalCampaign_display_creativeCreate,
    PermissionName.UniledportalCampaign_display_creativeUpdate,
    PermissionName.UniledportalCampaign_displayCreate,
    PermissionName.UniledportalCampaign_displayUpdate,
];

export const ReadAssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalCampaign_display_creativeRead,
    PermissionName.UniledportalCampaign_displayRead,
    PermissionName.UniledportalCreative_creative_groupRead,
];

export const ReadAvailableAssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalCreative_creative_groupRead,
];

export const MassUnAssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalDisplayRead,
    PermissionName.UniledportalCampaign_display_creativeDestroy,
];

export const UnAssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalCampaign_display_creativeCreate,
    PermissionName.UniledportalCampaign_display_creativeUpdate,
    PermissionName.UniledportalCampaign_displayCreate,
    PermissionName.UniledportalCampaign_displayUpdate,
];

export const generateCustomFilterConfig = (
    creatives: CampaignCreativesResponse,
): FilterConfigSelect[] => [
    {
        label: 'Modules.Main.Campaigns.Create.LineItemsTable.groupFilterLabel',
        key: 'creativeGroups',
        type: CustomFilterConfigType.Select,
        permissions: [
            PermissionName.UniledportalCreative_groupRead,
            PermissionName.UniledportalCreativeRead,
        ],
        userTypes: [UserType.Admin, UserType.Client, UserType.Buyer, UserType.Agency],
        options:
            creatives?.creativeGroups?.map(group => ({ label: group.name, value: group.id })) ?? [],
        multiple: true,
    },
];
