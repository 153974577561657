import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import {
    Displays_LineItem,
    FrameValidationRequest,
    FrameValidationResponse,
    ModuleConstructorProps,
} from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';
import { generateArrayQSParams } from '../Lib';

const LineItem = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<Displays_LineItem>(
        { endpoints, httpClient, baseUrl },
        'Displays_LineItem',
    ),
    getCampaignLineItem: (
        id: string,
        campaign_id: number,
        opts: {
            includes?: string[];
            creativeIds?: number[];
            creativeGroupIds?: number[];
        },
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(opts.includes ?? [], 'include', '&');
        const creativeParams = generateArrayQSParams(opts.creativeIds ?? [], 'creatives', '&');
        const groupParams = generateArrayQSParams(
            opts.creativeGroupIds ?? [],
            'creativeGroups',
            '&',
        );

        return httpClient.get<{ data: Displays_LineItem }>(
            `${urlcat(endpoints.Entities.Displays_LineItem.get, {
                id,
                campaign_id,
            })}${includeParams}${creativeParams}${groupParams}`,
            config,
        );
    },
    validateFrameIds: (data: FrameValidationRequest, config?: AxiosRequestConfig) =>
        httpClient.post<FrameValidationResponse>(
            endpoints.Entities.Displays_LineItem.validateFrameIds,
            data,
            config,
        ),
    frameDownload: (
        { frameIds, type }: { frameIds: string[]; type: 'all' | 'unique' },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<string>(
            endpoints.Entities.Displays_LineItem.frameDownload,
            { frame_ids: frameIds, download_type: type },
            { ...config, responseType: 'blob' },
        ),
});

export default LineItem;
