import React, { useMemo } from 'react';
import { PCAReportMetric } from 'c-sdk';
import { AutoGrid, NumberFormat, NumberFormatWhole } from 'c-components';
import { Box, Typography, TypographyProps } from '@mui/material';
import { Translate, TranslationPath } from 'c-translation';
import HoursMins from './HoursMins';

type Props = {
    metric: PCAReportMetric;
    actual: number;
    expected: number;
    performance: number;
    inSchedule: number;
    outSchedule: number;
    unbooked: number;
};

const gridItemProps = { sx: { display: 'flex', justifyContent: 'center' } };

const MetricPerformance: React.FC<Props> = ({
    metric,
    actual,
    expected,
    performance,
    inSchedule,
    outSchedule,
    unbooked,
}) => {
    const performanceColour = useMemo(() => {
        if (expected <= 0) return 'grey.600';

        return +performance >= 100 ? 'success.main' : 'warning.main';
    }, [expected, performance]);

    return (
        <>
            <AutoGrid spacing={0} xs={12} md={4} GridItemProps={gridItemProps}>
                <Block
                    metric={metric}
                    count={expected > 0 ? performance : null}
                    countColor={performanceColour}
                    label={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallTabTotalPerformance`}
                    percentage
                    asWholeNumber={false}
                />
                <Block
                    metric={metric}
                    count={expected}
                    countColor="grey.600"
                    label={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallTabTotalTabExpectedTitle`}
                />
                <Block
                    metric={metric}
                    count={actual}
                    countColor="grey.600"
                    label={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallTabTotalTabActualTitle`}
                />
            </AutoGrid>
        </>
    );
};

type BlockProps = {
    metric: PCAReportMetric;
    asWholeNumber?: boolean;
    count?: number | string;
    countColor: TypographyProps['color'];
    label: TranslationPath;
    percentage?: boolean;
};
const Block: React.FC<BlockProps> = ({
    metric,
    asWholeNumber = true,
    count,
    countColor,
    label,
    percentage = false,
}) => (
    <Box>
        <Typography variant="h2" color={countColor}>
            {metric === PCAReportMetric.Time && !percentage ? (
                <HoursMins seconds={+count} variant="h2" />
            ) : (
                <>
                    {count == null ? (
                        '-'
                    ) : (
                        <>
                            {asWholeNumber ? (
                                <NumberFormatWhole value={count} />
                            ) : (
                                <NumberFormat value={count} />
                            )}
                            {percentage && '%'}
                        </>
                    )}
                </>
            )}
        </Typography>
        <Typography variant="body1" color="grey.400" textAlign={{ xs: 'center', md: 'left' }}>
            <Translate path={label} />
        </Typography>
    </Box>
);

export default MetricPerformance;
