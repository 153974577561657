import React, { useMemo } from 'react';
import { Campaign, PCAReportMetric, PermissionName } from 'c-sdk';
import { TabWrapperSettings } from 'c-types';
import CampaignDisplayIssues from 'c-main/Components/Campaign/CampaignDisplayIssues';
import CampaignAnalytics from 'c-main/Components/Campaign/CampaignAnalytics';
import { TabsWrapper } from 'c-components';
import CampaignReportsWrapper from 'c-main/Components/Campaign/CampaignReports/CampaignReportsWrapper';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import CampaignNotices from 'c-main/Components/Campaign/CampaignReports/CampaignNotices';
import EsEnergyUsage from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/EnergyUsage';
import DspReporting from 'c-main/Components/Campaign/CampaignReportingWrapper/DspReporting/DspReporting';
import UkDspReporting from 'c-main/Components/Campaign/CampaignReportingWrapper/DspReporting/UkDspReporting';

type Props = {
    id: number;
    campaign: Campaign;
};
const reportsPerms = [PermissionName.ReportingPcareportRead];
const adminPerms = [PermissionName.DisplaysAdminsDestroy];
const displayVerificationPermissions = [PermissionName.DisplaysDisplay_issuesRead];

const CampaignReportingWrapper: React.FC<Props> = ({ id, campaign }) => {
    const { environmentMode, source } = useCampaignWrapperState(id);
    const pcaKey = useMemo(() => `${environmentMode}-${source}`, [environmentMode, source]);
    const tabs = useMemo<TabWrapperSettings['tabs']>(() => {
        const theTabs = [
            {
                title: 'Modules.Main.Campaigns.Overview.Tabs.analyticsSummaryReports',
                permissions: reportsPerms,
                content: <CampaignAnalytics id={id} key={pcaKey} />,
                tabPanelStyling: { py: 0 },
                urlName: 'summary',
                className: 'pca-summary',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.Tabs.impactsReports',
                permissions: reportsPerms,
                content: (
                    <CampaignReportsWrapper id={id} metric={PCAReportMetric.Impacts} key={pcaKey} />
                ),
                tabPanelStyling: { py: 0 },
                urlName: 'impacts',
                className: 'pca-impacts',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.Tabs.playsReports',
                permissions: reportsPerms,
                content: (
                    <CampaignReportsWrapper id={id} metric={PCAReportMetric.Plays} key={pcaKey} />
                ),
                tabPanelStyling: { py: 0 },
                urlName: 'plays',
                className: 'pca-plays',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.Tabs.timeReports',
                permissions: reportsPerms,
                content: (
                    <CampaignReportsWrapper id={id} metric={PCAReportMetric.Time} key={pcaKey} />
                ),
                tabPanelStyling: { py: 0 },
                urlName: 'time',
                className: 'pca-time',
            },
        ];

        if (campaign.energy_efficiency_enabled) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.energyUsage',
                permissions: reportsPerms,
                content: <EsEnergyUsage id={id} key={pcaKey} />,
                tabPanelStyling: { py: 0 },
                urlName: 'energy',
                className: 'pca-energy',
            });
        }

        if (campaign.display_verification_enabled) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.displayVerification',
                permissions: displayVerificationPermissions,
                content: <CampaignDisplayIssues id={id} />,
                tabPanelStyling: { py: 0 },
                urlName: 'display_verf',
                className: 'pca-display-verf',
            });
        }
        if (campaign.is_vistar) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.dsp',
                content: <DspReporting id={id} />,
                tabPanelStyling: { py: 0 },
                urlName: 'dsp',
                className: 'dsp',
                permissions: [PermissionName.ImpressionMultiplierRead],
            });
        }
        if (!campaign.is_vistar && (id === 9464 || id === 10160)) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.dsp',
                content: <DspReporting id={id} />,
                tabPanelStyling: { py: 0 },
                urlName: 'dsp',
                className: 'dsp',
                permissions: [PermissionName.ImpressionMultiplierRead],
            });
        }
        if (id === 8896) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.dsp',
                content: <UkDspReporting />,
                tabPanelStyling: { py: 0 },
                urlName: 'dsp',
                className: 'dsp',
                permissions: adminPerms,
            });
        }

        return theTabs;
    }, [
        id,
        pcaKey,
        campaign.energy_efficiency_enabled,
        campaign.display_verification_enabled,
        campaign.is_vistar,
    ]);

    return (
        <>
            <CampaignNotices campaign={campaign} />
            <TabsWrapper tabs={tabs} type="secondary" urlStatePath="metric" key={pcaKey} />
        </>
    );
};

export default CampaignReportingWrapper;
