import React, { useMemo } from 'react';
import { NetworkRequestState } from 'c-data-layer';
import { CircularProgress, ListItem, Tooltip, Typography, TypographyProps } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import { formatBytes } from 'c-lib';
import AutoGrid from 'c-components/AutoGrid';
import ListItemText from 'c-components/ListItemText';

type Props = {
    file: File;
    requestState?: NetworkRequestState;
    error?: string;
};

const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};

const FileUploadListItem: React.FC<Props> = ({ file, requestState, error }) => {
    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);

    return (
        <ListItem>
            <ListItemText
                primary={file.name}
                primaryTypographyProps={textProps}
                secondaryTypographyProps={textProps}
                secondary={
                    <AutoGrid spacing={1}>
                        {formatBytes(file.size)} | {file.type}
                    </AutoGrid>
                }
            />
            {requestState === NetworkRequestState.InProgress && <CircularProgress size={25} />}
            {requestState === NetworkRequestState.Error && (
                <Tooltip title={String(error)}>
                    <Typography color="error.main">
                        <Error />
                    </Typography>
                </Tooltip>
            )}
            {requestState === NetworkRequestState.Success && (
                <Typography color="success.main">
                    <CheckCircle />
                </Typography>
            )}
        </ListItem>
    );
};

export default FileUploadListItem;
