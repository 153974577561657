import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { ModuleConstructorProps, Role as RoleModel } from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const Role = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<RoleModel>({ endpoints, httpClient, baseUrl }, 'Role'),
    updatePermissions: (
        { roleId, permissionIds }: { roleId: number; permissionIds: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ data: RoleModel }>(
            urlcat(endpoints.Entities.Role.updatePermissions, { id: roleId }),
            { permissions: permissionIds },
            config,
        ),
});

export default Role;
