import { AxiosError } from 'axios';

function errorMessageFromResponse(error: AxiosError<any>) {
    let errorMessage = error?.response?.data?.meta?.error;

    if (errorMessage == null) {
        errorMessage = error?.response?.data?.message;
    }

    if (errorMessage == null) {
        errorMessage = error?.response?.data?.debug;
    }

    if (errorMessage == null) {
        errorMessage = 'Error';
    }

    return String(errorMessage);
}

export default errorMessageFromResponse;
