import React, { useCallback, useMemo, useRef } from 'react';
import { FilterableEntityTable, FilterableEntityTableRefAPI, generateColumn } from 'c-pagination';
import { useEntityPage } from 'c-hooks';
import { Typography } from '@mui/material';
import { AutoGrid, ControlledFormInput, CreateEntityButton, TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { PermissionName, BuyerCost } from 'c-sdk';

type Props = {
    //
};

const Columns = [
    generateColumn<BuyerCost>({
        headerTitle: 'Modules.Main.Buyer.Overview.Costs.daysCol',
        field: 'days',
        entityName: 'BuyerCost',
    }),
    generateColumn<BuyerCost>({
        headerTitle: 'Modules.Main.Buyer.Overview.Costs.costCol',
        field: 'cost',
        entityName: 'BuyerCost',
        dataType: 'currency',
    }),
    generateColumn<BuyerCost>({
        headerTitle: 'Modules.Main.Buyer.Overview.Costs.maxCostCol',
        field: 'max_cost',
        entityName: 'BuyerCost',
        dataType: 'currency',
    }),
    generateColumn<BuyerCost>({
        headerTitle: 'Modules.Main.Buyer.Overview.Costs.createdCol',
        field: 'created_at',
        entityName: 'BuyerCost',
        dataType: 'date',
    }),
];

const BuyerCostsTabContent: React.FC<Props> = () => {
    const { id } = useEntityPage();
    const paginationTag = useMemo(() => `buyers.${id}.costs`, [id]);
    const ref = useRef<FilterableEntityTableRefAPI>();

    const onCostCreated = useCallback(() => {
        ref?.current?.search(1);
    }, []);

    const defaultData = useMemo(() => ({ buyer_id: id }), [id]);
    const t = useCommonTranslation();

    return (
        <AutoGrid spacing={2} xs={12}>
            <Typography variant="body1">
                {useCommonTranslation('Modules.Main.Buyer.Overview.Costs.explanation')}
            </Typography>
            <CreateEntityButton<BuyerCost>
                defaultData={defaultData}
                entityName="BuyerCost"
                title="Modules.Main.Buyer.Overview.Costs.newCostDialogTitle"
                permissions={[PermissionName.UniledportalBuyer_costCreate]}
                form={
                    <AutoGrid spacing={2} xs={12}>
                        <ControlledFormInput
                            name="days"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={t('Modules.Main.Buyer.Overview.Costs.newCostDays')}
                                />
                            )}
                        />
                        <ControlledFormInput
                            name="cost"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={t('Modules.Main.Buyer.Overview.Costs.newCost')}
                                    type="number"
                                />
                            )}
                        />
                        <ControlledFormInput
                            name="max_cost"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={t('Modules.Main.Buyer.Overview.Costs.newMaxCost')}
                                    type="number"
                                />
                            )}
                        />
                    </AutoGrid>
                }
                buttonLabel={useCommonTranslation(
                    'Modules.Main.Buyer.Overview.Costs.newCostButton',
                )}
                onSuccess={onCostCreated}
            />
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                ref={ref}
                filters={{ 'filter.buyer.id': [id], 'filter.buyers.id': [id] }}
                baseEntityName="BuyerCost"
                columns={Columns}
                orderBy="id"
                direction="desc"
            />
        </AutoGrid>
    );
};

export default BuyerCostsTabContent;
