import { Displays_Country } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_CountryState } from './Displays_Country-types';

const initialState: Displays_CountryState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_CountryState, action: PayloadAction<string>): Displays_CountryState => state,
};

const slice = createBaseSlice<Displays_Country, Displays_CountryState, typeof additionalReducers>({
    name: 'displays_Country',
    entityName: 'Displays_Country',
    initialState,
    reducers: additionalReducers,
});

const displays_CountrySlice = {
    displays_CountryActions: slice.slice.actions,
    displays_CountryReducer: slice.slice.reducer,
    displays_CountryAdapter: slice.adapter,
    displays_CountrySelectors: slice.selectors,
    displays_CountryThunks: slice.thunks,
};

export default displays_CountrySlice;
export const {
    displays_CountryReducer,
    displays_CountryActions,
    displays_CountryAdapter,
    displays_CountrySelectors,
    displays_CountryThunks,
} = displays_CountrySlice;
