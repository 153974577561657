import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import equal from 'fast-deep-equal';
import { logout, resetEntities, timeout } from 'c-data-layer';
import { authActions } from 'c-auth-module/Slices/Auth/auth-slice';
import { PresenceChannelMember } from 'c-auth-module/Types';
import { Skin } from 'c-entity-types';
import { getDefaultSkin } from 'c-lib';
import { onlySkinThemeValues } from 'c-admin/Lib';
import { PageStatusSettings } from 'c-system-search/types';
import { UISliceState } from './ui-types';

const initialState: UISliceState = {
    drawerOpen: false,
    crmWideMembers: [],
    channelMembers: [],
    metaTitle: '',
    previewTheme: getDefaultSkin(),
    debugEnabled: false,
    disableDesktopContentScrolling: false,
};

const slice = createSlice({
    initialState,
    name: 'ui',
    reducers: {
        setPageStatus: (state, action: PayloadAction<PageStatusSettings>) => ({
            ...state,
            pageStatus: action.payload,
        }),
        setDisableDesktopContentScrolling: (state, action: PayloadAction<boolean>) => ({
            ...state,
            disableDesktopContentScrolling: action.payload,
        }),
        toggleDebug: (state, action: PayloadAction<string>) => ({
            ...state,
            debugEnabled: !state.debugEnabled,
        }),
        toggleDrawerOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            drawerOpen: action.payload,
        }),
        toggleNotesDrawerOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            notesDrawerOpen: action.payload,
        }),
        setCrmMembers: (state, action: PayloadAction<PresenceChannelMember[]>) => ({
            ...state,
            crmWideMembers: action.payload,
        }),
        addCrmMember: (state, action: PayloadAction<PresenceChannelMember>) => ({
            ...state,
            crmWideMembers: state.crmWideMembers
                .filter(mem => mem.user_id !== action.payload.user_id)
                .concat(action.payload),
        }),
        removeCrmMember: (state, action: PayloadAction<PresenceChannelMember>) => ({
            ...state,
            crmWideMembers: state.crmWideMembers.filter(
                mem => mem.user_id !== action.payload.user_id,
            ),
        }),
        setPlayAroundTheme: (state, action: PayloadAction<Skin>) => {
            if (equal(state.playAroundTheme, onlySkinThemeValues(action.payload))) {
                return state;
            }
            return {
                ...state,
                playAroundTheme: onlySkinThemeValues(action.payload),
            };
        },
        setPreviewTheme: (state, action: PayloadAction<Skin>) => {
            if (equal(state.previewTheme, onlySkinThemeValues(action.payload))) {
                return state;
            }
            return {
                ...state,
                previewTheme: onlySkinThemeValues(action.payload),
            };
        },
        setPageMembers: (state, action: PayloadAction<PresenceChannelMember[]>) => ({
            ...state,
            channelMembers: action.payload,
        }),
        addPageMember: (state, action: PayloadAction<PresenceChannelMember>) => ({
            ...state,
            channelMembers: state.channelMembers
                .filter(mem => mem.user_id !== action.payload.user_id)
                .concat(action.payload),
        }),
        removePageMember: (state, action: PayloadAction<PresenceChannelMember>) => ({
            ...state,
            channelMembers: state.channelMembers.filter(
                mem => mem.user_id !== action.payload.user_id,
            ),
        }),
        setLayoutMetaData: (
            state,
            action: PayloadAction<{
                title: string;
            }>,
        ) => ({
            ...state,
            metaTitle: action.payload.title,
        }),
    },
    extraReducers: builder => {
        builder.addCase(authActions.finishedLogin, (state, action) => ({
            ...state,
            drawerOpen: !action.payload.isMobile,
        }));
        builder.addCase(logout, state => ({
            ...initialState,
            previewTheme: getDefaultSkin(),
        }));
        builder.addCase(timeout, state => ({
            ...initialState,
            previewTheme: getDefaultSkin(),
        }));
        builder.addCase(resetEntities, state => ({
            ...state,
            debugEnabled: false,
            previewTheme: getDefaultSkin(),
        }));
    },
});

const uiSlice = {
    uiActions: slice.actions,
    uiReducer: slice.reducer,
};

export default uiSlice;
export const { uiActions, uiReducer } = uiSlice;
