import { getUnixTime } from 'date-fns';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkRequestState, logout, timeout, resetEntities } from 'c-data-layer';
import { SystemSearchEntitySearchResult } from '../../types';
import { SystemSearchState } from './systemSearch-types';

const initialState: SystemSearchState = {
    open: false,
    searchTerm: '',
    entityLoading: {},
};

const slice = createSlice({
    initialState,
    name: 'systemSearch',
    reducers: {
        toggleOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            open: action.payload,
        }),
        updateSearchTerm: (state, action: PayloadAction<string>) => ({
            ...state,
            searchTerm: action.payload ?? '',
        }),
        startLoadingEntities: (state, action: PayloadAction<{ tag: string; term: string }>) => ({
            ...state,
            entityLoading: {
                ...state.entityLoading,
                [action.payload.tag]: {
                    state: NetworkRequestState.InProgress,
                    results: state.entityLoading[action.payload.tag]?.results ?? [],
                    searchTerm: action.payload.term,
                },
            },
        }),
        finishLoadingEntities: (
            state,
            action: PayloadAction<{
                tag: string;
                results: SystemSearchEntitySearchResult[];
                term: string;
            }>,
        ) => ({
            ...state,
            entityLoading: {
                ...state.entityLoading,
                [action.payload.tag]: {
                    state: NetworkRequestState.Success,
                    results: action.payload.results,
                    searchTerm: action.payload.term,
                    searchTime: getUnixTime(new Date()),
                },
            },
        }),
        failedLoadingEntities: (
            state,
            action: PayloadAction<{ tag: string; term: string; error: string }>,
        ) => ({
            ...state,
            entityLoading: {
                ...state.entityLoading,
                [action.payload.tag]: {
                    state: NetworkRequestState.Error,
                    results: state.entityLoading[action.payload.tag]?.results ?? [],
                    searchTerm: action.payload.term,
                },
            },
        }),
    },
    extraReducers: builder => {
        builder.addCase(logout, () => initialState);
        builder.addCase(timeout, () => initialState);
        builder.addCase(resetEntities, () => initialState);
    },
});

export default slice;
export const { actions, reducer } = slice;
