import React, { useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Translate } from 'c-translation';
import { ControlledFormInput, CreateEntityButton, TextField } from 'c-components';
import { PermissionName } from 'c-sdk';
import { Role } from 'c-entity-types';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { roleIcon } from 'c-main/icons';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

const paginationTag = 'role-list-search';

const columns = [
    generateColumn<Role>({
        headerTitle: 'Modules.Admin.RolesList.table.idCol',
        field: 'id',
        entityName: 'Role',
        orderable: true,
    }),
    generateColumn<Role>({
        headerTitle: 'Modules.Admin.RolesList.table.nameCol',
        field: 'name',
        entityName: 'Role',
        orderable: true,
    }),
];

const schema = yup.object({
    name: yup.string().required(),
});

const textSearchCols = ['name'];

const RoleListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <FullPageEntityTableWrapper
            title="Modules.Admin.RolesList.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<Role>
                        key="new-role-btn"
                        entityName="Role"
                        title="Modules.Admin.RolesList.create.newRoleButton"
                        buttonLabel={
                            <Translate path="Modules.Admin.RolesList.create.newRoleButton" />
                        }
                        submitLabel="Modules.Admin.RolesList.create.submitButton"
                        form={<NewRoleForm />}
                        redirectPath={PostAuthRoutes.Admin.EditRole}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        permissions={[PermissionName.UniledportalRoleCreate]}
                    />,
                ],
                [],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="Role"
                routeTemplate={PostAuthRoutes.Admin.EditRole}
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
            />
        </FullPageEntityTableWrapper>
    );
};

const NewRoleForm = () => (
    <>
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={<Translate path="Modules.Admin.RolesList.create.nameLabel" />}
                    {...field}
                    autoFocus
                />
            )}
            name="name"
        />
    </>
);

const setup: PageConfig = {
    id: 'RoleListPage',
    component: RoleListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Admin.RolesList.metaTitle',
        description: 'Modules.Admin.RolesList.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.roleListPage',
        route: PostAuthRoutes.Admin.RoleList,
        saveAsRecentPage: true,
        icon: roleIcon,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.RolesList.metaTitle' },
        ],
        permissions: [
            PermissionName.UniledportalRoleRead,
            PermissionName.UniledportalRoleUpdate,
            PermissionName.UniledportalRoleCreate,
        ],
    },
};

export default setup;
