import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    CardActions,
    CardContent,
    CardHeaderProps,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { useNumber } from 'react-hanger';
import { Button, TransparentCard } from 'c-components';
import { useFormContext } from 'react-hook-form';
import { useEntityData } from 'c-data';
import { Buyer } from 'c-sdk';
import { CampaignFormNames } from 'c-main/Types';
import { DetailsStep, ChooseDisplaysStep, CostingStep, SummaryStep, StepContent } from './Steps';

type Props = {
    onStepChange: (step: number) => void;
    onBuyerChange: (buyer: Buyer) => void;

    campaignCreate: React.ReactNode;
};

const totalSteps = 4;

enum Steps {
    Details,
    ChooseDisplays,
    Schedule,
    Summary,
}

const stepTitles: Record<Steps, TranslationPath> = {
    [Steps.Details]: 'Modules.Main.Campaigns.Create.stepDetailsTitle',
    [Steps.ChooseDisplays]: 'Modules.Main.Campaigns.Create.stepLineItemsTitle',
    [Steps.Schedule]: 'Modules.Main.Campaigns.Create.stepScheduleTitle',
    [Steps.Summary]: 'Modules.Main.Campaigns.Create.stepSummaryTitle',
};

const stepMaxWidths = {
    [Steps.Details]: 1100,
    [Steps.ChooseDisplays]: 1200,
    [Steps.Schedule]: 1200,
    [Steps.Summary]: 600,
};

const cardTitleTypographyProps: CardHeaderProps['titleTypographyProps'] = { variant: 'h3' };

const NewCampaignStepper: React.FC<Props> = ({ onStepChange, onBuyerChange, campaignCreate }) => {
    const { increase: goForward, decrease: goBack, value: currentStep } = useNumber(0);
    const { formState, watch } = useFormContext();

    const buyerId = watch(CampaignFormNames.buyer);
    const { getById: GetBuyer } = useEntityData<Buyer>('Buyer');
    const buyer = GetBuyer({ id: buyerId });

    useEffect(() => {
        onBuyerChange(buyer);
    }, [buyer, onBuyerChange]);

    const showLast = useMemo(() => currentStep === totalSteps - 1, [currentStep]);
    const hasNext = useMemo(() => currentStep < totalSteps - 1, [currentStep]);
    const hasPrev = useMemo(() => currentStep > 0, [currentStep]);

    useEffect(() => {
        onStepChange(currentStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const goNext = useCallback(() => {
        if (hasNext) {
            goForward();
        }
    }, [goForward, hasNext]);

    const goPrev = useCallback(() => {
        if (hasPrev) {
            goBack();
        }
    }, [goBack, hasPrev]);

    const visibleStep = useMemo<Steps>(() => {
        switch (currentStep) {
            case 0:
                return Steps.Details;
            case 1:
                return Steps.ChooseDisplays;
            case 2:
                return Steps.Schedule;
            case 3:
                return Steps.Summary;
            default:
                return Steps.Details;
        }
    }, [currentStep]);

    const stepTitle = useCommonTranslation(stepTitles[visibleStep]);

    const stepLabel = useCommonTranslation('Modules.Main.Campaigns.Create.currentStepLabel', {
        current: currentStep + 1,
        total: totalSteps,
    });
    const stepCountTitle = useMemo(
        () => [
            <Typography key="step-count" variant="h4">
                {stepLabel}
            </Typography>,
        ],
        [stepLabel],
    );

    const memoStepper = useMemo(
        () => (
            <Stepper alternativeLabel activeStep={currentStep} sx={{ py: 2 }}>
                <Step>
                    <StepLabel>
                        <Translate path="Modules.Main.Campaigns.Create.detailsStepLabel" />
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <Translate path="Modules.Main.Campaigns.Create.chooseDisplaysStepLabel" />
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <Translate path="Modules.Main.Campaigns.Create.scheduleStepLabel" />
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <Translate path="Modules.Main.Campaigns.Create.summaryStepLabel" />
                    </StepLabel>
                </Step>
            </Stepper>
        ),
        [currentStep],
    );

    const memoSteps = useMemo(
        () => (
            <>
                {visibleStep === Steps.Details && (
                    <StepContent visible>
                        <DetailsStep />
                    </StepContent>
                )}
                {visibleStep === Steps.ChooseDisplays && (
                    <StepContent visible>
                        <ChooseDisplaysStep />
                    </StepContent>
                )}
                {visibleStep === Steps.Schedule && (
                    <StepContent visible>
                        <CostingStep />
                    </StepContent>
                )}
                {visibleStep === Steps.Summary && (
                    <StepContent visible>
                        <SummaryStep />
                        {campaignCreate}
                    </StepContent>
                )}
            </>
        ),
        [campaignCreate, visibleStep],
    );

    const memoActions = useMemo(
        () => (
            <CardActions sx={{ pt: 2, px: 0 }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button variant="outlined" onClick={goPrev} disabled={!hasPrev}>
                        <Translate path="Modules.Main.Campaigns.Create.prevStepLabel" />
                    </Button>
                    {!showLast && (
                        <Button onClick={goNext} disabled={!hasNext || !formState.isValid}>
                            <Translate path="Modules.Main.Campaigns.Create.nextStepLabel" />
                        </Button>
                    )}
                    {showLast && (
                        <Button type="submit">
                            <Translate path="Modules.Main.Campaigns.Create.createCampaignLabel" />
                        </Button>
                    )}
                </Box>
            </CardActions>
        ),
        [formState.isValid, goNext, goPrev, hasNext, hasPrev, showLast],
    );

    return (
        <>
            <Box
                minHeight={200}
                maxWidth={stepMaxWidths[visibleStep]}
                width="100%"
                mx="auto"
                position="relative"
                mt={2}
            >
                <TransparentCard
                    title={stepTitle}
                    titleTypographyProps={cardTitleTypographyProps}
                    controls={stepCountTitle}
                    controlsUnderTitle
                >
                    <CardContent>
                        <Box width="100%" display="flex">
                            <Box width="100%">
                                {memoStepper}
                                {memoSteps}
                                {memoActions}
                            </Box>
                        </Box>
                    </CardContent>
                </TransparentCard>
            </Box>
        </>
    );
};

export default NewCampaignStepper;
