import { Displays_Owner as ScreenModel, ModuleConstructorProps } from '../Types';

import { BaseEntityModuleFuncs, screenOrOwnerUpdateFunc } from './BaseEntityModuleFuncs';

const Displays_Owner = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<ScreenModel>({ endpoints, httpClient, baseUrl }, 'Displays_Owner'),

    ...screenOrOwnerUpdateFunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Owner.getOwner,
    ),
});

export default Displays_Owner;
