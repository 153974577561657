import merge from 'deepmerge';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { BaseEntity } from 'c-sdk';
import getUnixTime from 'date-fns/getUnixTime';
import parseISO from 'date-fns/parseISO';
import { BaseAdapterOptions, BaseSliceEntityAdapter } from '../../Types/slices';
import { shouldUpsert } from '../Lib';

const overwriteMerge = (originalArray: any[], newArray: any[]) => newArray;

const baseAdapter = <Entity extends BaseEntity>(options?: BaseAdapterOptions<Entity>) => {
    const adapter = createEntityAdapter({
        selectId: entity => entity.id,
        sortComparer: (a, b) => {
            const aTime = getUnixTime(parseISO(a.created_at));
            const bTime = getUnixTime(parseISO(b.created_at));

            if (aTime > bTime) {
                return -1;
            }

            if (aTime < bTime) {
                return 1;
            }

            return 0;
        },
        ...options,
    }) as BaseSliceEntityAdapter<Entity>;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    adapter.conditionallyUpsert = (state, entities) => {
        const currentEntities = state.entities;

        const filteredEntities = entities.reduce((acc, newEntity) => {
            if (shouldUpsert(newEntity, currentEntities[newEntity.id])) {
                acc.push(
                    merge(currentEntities[newEntity.id] ?? {}, newEntity, {
                        arrayMerge: overwriteMerge,
                        customMerge: options?.customMerge,
                    }) as Entity,
                );
            }
            return acc;
        }, [] as Entity[]);

        return adapter.upsertMany(state, filteredEntities);
    };
    return adapter;
};

export default baseAdapter;
