import { BaseEntity } from '../../BaseEntity';
import { Agency } from './Agency';
import { Permission } from './Permission';
import { Role } from './Role';
import { Skin } from './Skin';
import { Buyer } from './Buyer';
import { Owner } from './Owner';
import { Client } from './Client';

export enum UserType {
    Admin = 'admin',
    Agency = 'agency',
    Buyer = 'buyer',
    Client = 'client',
    Owner = 'owner',
}

export type User = BaseEntity & {
    stream: number;
    type: string;
    setting: number;
    progress: number;
    name: string;
    name_first: string;
    name_last: string;
    email: string;
    telephone: string;
    mobile: string;
    username: string;
    password: string;
    arn: string;
    token: string;
    userlevel: number;
    salt: string;
    code: string;

    active: boolean;
    // the ISO language code like 'en-GB', 'en-US', etc.
    locale?: string;

    properties: {
        invited: boolean;
        password_reset_requested: boolean;
        verified: boolean;
    };

    old_portal_disable?: boolean;

    // indicates whether profiling has been turned on for the user
    profiling?: boolean;

    agency_id?: number;
    buyer_id?: number;
    client_id?: number;
    owner_id?: number;
    stat_id?: number;
    market_id?: number;

    agency?: Agency;
    agencies?: Agency[];

    buyer?: Buyer;
    buyers?: Buyer[];

    client?: Client;
    clients?: Client[];

    owner?: Owner;
    owners?: Owner[];

    permissions?: Permission[];
    roles?: Role[];

    skins?: Skin[];

    // only set while impersonating
    impersonatedBy?: User;

    availableUserTypes?: UserType[];

    chosenUserType?: UserType;

    color_correction: UserColorCorrection;
};

export enum UserColorCorrection {
    Protanope = 'protanope',
    Deuteranope = 'deuteranope',
    Tritanope = 'tritanope',
    Dark = 'dark',
}

export type UserPasswordReset = {
    url: string;
    token: string;
    redeemed_at?: string;
    expires_at: string;
    created_at: string;
};
