import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { CampaignCreativesResponse, Displays_LineItem } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import { Button, DialogV2, ErrorImageTryAgain, FlashMessage, LoadingSpinner } from 'c-components';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useUserPermissions } from 'c-auth-module/Hooks';
import to from 'await-to-js';
import { UnAssignPerms } from 'c-main/Components/Campaign/CampaignOverviewDisplays/constants';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import LineItemCreativeAssignGridTwo from './LineItemCreativeAssignGridTwo';
import AssignLineItemTable from './AssignLineItemTable';

type Props = {
    campaignId: number;
    lineItem: Displays_LineItem;
    open: boolean;
    onClose: () => void;
    creatives: CampaignCreativesResponse;
    setCampaignCreatives: (data: CampaignCreativesResponse) => void;
    reValidateCampaign: () => void;
    creativeGroupsFilter: number[];
};

const emptyCreatives = { creatives: [], creativeGroups: [] };

const LineItemCreativeUnAssignDialog: React.FC<Props> = ({
    campaignId,
    lineItem,
    open,
    onClose,
    creatives,
    setCampaignCreatives,
    reValidateCampaign,
    creativeGroupsFilter,
}) => {
    const [selectedCreatives, setSelectedCreatives] = useState<number[]>([]);
    const t = useCommonTranslation();

    const {
        isLoading: IsLineItemLoading,
        data: LineItemData,
        start: LoadLineItem,
        hasSucceeded: HasLineItemSucceeded,
        hasFailed: HasLineItemFailed,
    } = useAPIClientRequest(apiClient.Entities.Displays_LineItem.getCampaignLineItem);

    const theLineItem = useMemo<Displays_LineItem>(
        () => LineItemData?.data?.data ?? null,
        [LineItemData],
    );

    const alreadyAssignedCreatives = useMemo(
        () => theLineItem?.creatives?.map(c => c.id) ?? [],
        [theLineItem],
    );

    const creativesListData = useMemo(() => {
        if (!IsLineItemLoading && HasLineItemSucceeded) {
            // since we're unassigning we only want to show a list of currently assigned creatives
            return {
                ...creatives,
                creatives: creatives.creatives.filter(
                    creative => alreadyAssignedCreatives.indexOf(creative.id) !== -1,
                ),
            };
        }

        return emptyCreatives;
    }, [HasLineItemSucceeded, IsLineItemLoading, creatives, alreadyAssignedCreatives]);

    const loadLineItem = useCallback(async () => {
        await to(
            LoadLineItem(lineItem.id, campaignId, {
                includes: ['creatives', 'fileTypes', 'resolutions'],
                creativeGroupIds:
                    creativeGroupsFilter.length > 0 ? creativeGroupsFilter : undefined,
            }),
        );
    }, [LoadLineItem, campaignId, lineItem.id, creativeGroupsFilter]);

    useEffect(() => {
        loadLineItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        start: UnAssignCreatives,
        isLoading: AreCreativesUnAssigning,
        hasFailed: HaveCreativesFailed,
        error,
    } = useAPIClientRequest(apiClient.Entities.Campaign.forceUnAssignCreativesFromLineItems);

    const failedToLoad = useMemo(() => HasLineItemFailed, [HasLineItemFailed]);

    const assignCreatives = useCallback(async () => {
        const [err, suc] = await to(
            UnAssignCreatives(campaignId, {
                creatives: selectedCreatives.map(creative => ({
                    id: creative,
                    lineItems: [String(lineItem.id)],
                })),
            }),
        );

        if (!err && suc?.data?.creatives != null && suc?.data?.creativeGroups != null) {
            setCampaignCreatives(suc.data);
            reValidateCampaign();
            onClose();
        }
    }, [
        UnAssignCreatives,
        campaignId,
        lineItem.id,
        selectedCreatives,
        setCampaignCreatives,
        reValidateCampaign,
        onClose,
    ]);

    const failureMessage = useMemo(() => {
        const errStr = String(error);
        if (errStr.trim().length > 0) return errStr;

        return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.failedToAssign');
    }, [t, error]);

    const assignDisabled = useMemo(
        () => selectedCreatives.length === 0 || AreCreativesUnAssigning,
        [selectedCreatives.length, AreCreativesUnAssigning],
    );

    const { hasAll } = useUserPermissions();
    const canUnAssign = useMemo(() => hasAll(UnAssignPerms), [hasAll]);

    return (
        <DialogV2
            title={lineItem.name}
            description="Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.unAssignDialogDescription"
            maxWidth="xl"
            onClose={onClose}
            open={open}
            contentHeight="70vh"
            actions={
                <>
                    <IfHasAllPermissions permissions={UnAssignPerms}>
                        <Button disabled={assignDisabled} onClick={assignCreatives}>
                            {t(
                                'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.unAssignSubmitBtn',
                            )}
                            {AreCreativesUnAssigning && (
                                <Box ml={1} display="flex" alignItems="center">
                                    <CircularProgress size={15} />
                                </Box>
                            )}
                        </Button>
                    </IfHasAllPermissions>
                </>
            }
        >
            <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
                <Stack spacing={2} flex={1} sx={{ overflow: 'hidden' }}>
                    {theLineItem && (
                        <Box>
                            <AssignLineItemTable lineItem={theLineItem} />
                        </Box>
                    )}

                    <Divider />
                    <Box
                        flex={1}
                        minHeight={250}
                        height="100%"
                        overflow="hidden"
                        position="relative"
                    >
                        {IsLineItemLoading && <LoadingSpinner />}
                        {!failedToLoad && (
                            <>
                                <LineItemCreativeAssignGridTwo
                                    canAction={canUnAssign}
                                    lineItem={theLineItem as any}
                                    creatives={creativesListData}
                                    onSelectionUpdated={setSelectedCreatives}
                                    selectedCreatives={selectedCreatives}
                                    alreadyAssignedCreatives={alreadyAssignedCreatives}
                                    unAssignMode
                                />
                            </>
                        )}

                        {failedToLoad && (
                            <ErrorImageTryAgain
                                title="Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.failedToLoadAssignData"
                                retryLabel="Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.failedToLoadAssignDataRetry"
                                retry={loadLineItem}
                            />
                        )}
                    </Box>

                    {HaveCreativesFailed && (
                        <Box alignSelf="center">
                            <FlashMessage status="error">{failureMessage}</FlashMessage>
                        </Box>
                    )}
                </Stack>
            </Box>
        </DialogV2>
    );
};

export default LineItemCreativeUnAssignDialog;
