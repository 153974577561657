/* eslint-disable no-param-reassign */
import { ReducersMapObject } from '@reduxjs/toolkit';
import { BaseEntity } from 'c-sdk';
import { DrawerConfiguration, DrawerNavigationItem } from './Types/DrawerConfiguration';
import { EntitySchemas } from './Data/EntitySchemaActions';

export type ModuleConfiguration<RootState, AllEntities extends { [key: string]: BaseEntity }> = {
    reducers: ReducersMapObject;
    EntitySchemaActions: Partial<EntitySchemas<RootState, AllEntities>>;
    navigation: DrawerConfiguration;
    pages: ModulePageConfiguration[];
    mergeFunction?: (
        config: ModuleConfiguration<RootState, AllEntities>,
        config2Merge: ModuleConfiguration<RootState, AllEntities>,
    ) => ModuleConfiguration<RootState, AllEntities>;
    entityMapper?: {
        [key in keyof AllEntities]?: (
            entity: AllEntities[key],
            getState: () => RootState,
        ) => AllEntities[key];
    };
};

export type ModulePageConfiguration = {
    id: string;
    component: () => JSX.Element;
    content?: JSX.Element;
};

const config: ModuleConfiguration<any, any> = {
    reducers: {},
    EntitySchemaActions: {},
    navigation: { groups: {} },
    pages: [],
    entityMapper: {},
};
type ItemsType = { [key: number]: DrawerNavigationItem };
const addItems = (items: ItemsType, items2Merge: ItemsType): ItemsType => {
    const result = { ...items };
    if (!items2Merge) return null;
    Object.entries(items2Merge).forEach(([key, item]) => {
        result[+key] = {
            ...result[+key],
            ...item,
            items: addItems(result?.[+key]?.items, item.items),
        };
    });
    return result;
};

const mergeNavigation = <RootState>(
    navigation: ModuleConfiguration<RootState, any>['navigation'] = { groups: {} },
    navigation2Merge: ModuleConfiguration<RootState, any>['navigation'] = { groups: {} },
) =>
    Object.entries(navigation2Merge.groups).reduce((acc, [key, group]) => {
        acc.groups[+key] = {
            ...navigation.groups[+key],
            ...group,
            items: addItems(navigation.groups[+key]?.items, group.items),
        };
        return acc;
    }, navigation);

const mergeConfig = <RootState>(
    c: ModuleConfiguration<RootState, any> = {} as any,
    config2Merge: ModuleConfiguration<RootState, any> = {} as any,
) => {
    if (config2Merge.navigation) {
        c.navigation = mergeNavigation(c.navigation, config2Merge.navigation);
    }
    if (config2Merge.reducers) {
        c.reducers = { ...c.reducers, ...config2Merge.reducers };
    }
    if (config2Merge.EntitySchemaActions) {
        c.EntitySchemaActions = {
            ...c.EntitySchemaActions,
            ...config2Merge.EntitySchemaActions,
        };
    }
    if (config2Merge.pages) {
        c.pages = [...(c.pages ?? []), ...(config2Merge.pages ?? [])];
    }
    if (config2Merge.entityMapper) {
        c.entityMapper = { ...c.entityMapper, ...config2Merge.entityMapper };
    }
    config2Merge.mergeFunction?.(c, config2Merge);
    return c;
};
export const mergeConfigs = <RootState>(configs: ModuleConfiguration<RootState, any>[]) =>
    configs.reduce(mergeConfig, {} as any);

export const addConfig = <RootState>(config2Merge: ModuleConfiguration<RootState, any>) =>
    mergeConfig(config, config2Merge);

export const addEntityMap = (
    entityName: string,
    entityMap: <Entity extends BaseEntity, RootState>(
        entity: Entity,
        getState: () => RootState,
    ) => Entity,
) => {
    config.entityMapper = { ...config.entityMapper, [entityName]: entityMap };
};

export const getEntityMapper = () => config.entityMapper;

export default config;
