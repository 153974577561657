import React, {
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box } from '@mui/material';
import { insert, isNil, pick, reject, uniq } from 'ramda';
import { NetworkRequestState, paginatedDataToSearchPayload, SearchPayload } from 'c-data-layer';
import {
    CustomFilterConfigType,
    FilterableEntityTableProps,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererProps,
    FilterableEntityTableRendererType,
    FilterConfig,
} from 'c-pagination/types';
import { usePaginatedEntityData } from 'c-data';
import { generateRows, generateSearchColumnsData } from 'c-pagination/Lib';
// eslint-disable-next-line import/no-cycle
import { EntityFilters, PaginatedEntityList, TablePagination } from 'c-pagination/Components';
import merge from 'deepmerge';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { usePrevious } from 'react-hanger';
import { useIsMobileView } from 'c-hooks';
import { ListSearchOptions, PermissionName } from 'c-sdk';
import equal from 'fast-deep-equal/es6';
import {
    useCommonTranslation,
    useCommonTranslationInstance,
    useModelFieldTranslation,
} from 'c-translation';
import {
    PaginatedEntityTableContext,
    PaginatedEntityTableContextValues,
} from 'c-pagination/EntityPaginationContext';
import ContainedRenderer from './ContainedRenderer';
import DefaultRenderer from './DefaultRenderer';

type Props = FilterableEntityTableProps;

const searchTermKey = 'search';
const pickFields = [
    'orderBy',
    'direction',
    'filters',
    'perPage',
    'page',
    'includes',
    'searchables',
];
const searchPayloadForComparison = payload => reject(isNil, pick(pickFields, payload));
const defaultRenderIds = ids => ids;
const dedupedFilters = (options: ListSearchOptions): ListSearchOptions => ({
    ...options,
    filters: Object.entries(options.filters ?? {}).reduce((acc, [filterKey, values]) => {
        if (Array.isArray(values) && values.length > 0)
            return { ...acc, [filterKey]: uniq((values ?? []) as any[]) };

        return acc;
    }, {}),
});

const defaultUpdateSearchPayload: Props['updateSearchPayload'] = payload => payload;

function FilterableEntityTable(
    {
        orderBy = 'id',
        direction = 'desc',
        perPage = 25,
        page = 1,
        showFilters,
        includes,
        filters,
        baseEntityName,
        tag,
        routeTemplate,
        columns,
        resetOnUnmount = false,
        refreshOnMount = true,
        textSearchColumns = [],
        revertToIdSearchOnNumberOnlyInput = true,
        // filterFilters = true,
        filterFilters,
        smartFilters = false,
        renderIds = defaultRenderIds,
        onRowClick,
        afterFilters,
        onlyIncludeFilterKeys,
        updateSearchPayload = defaultUpdateSearchPayload,
        customListSearch,
        tableContainerProps,
        tableProps,
        customFilterConfig,
        generateRowSx,
        disabledRowDividers,
        dense,
        CustomRenderer,
        rendererType = FilterableEntityTableRendererType.Default,
        alwaysOpenFilters = false,
        filtersInlineSearch = false,
        displayDownloadButton = false,
        reImportButton = false,
        customFilters = [],
        customFilterResets = [],
        filterApplyButton = false,
        loadBar = true,
        disableCache = false,
    }: Props,
    ref: Ref<FilterableEntityTableRefAPI>,
) {
    const { hasAll, isUserOneOfTypes, isAdmin } = useUserPermissions();
    const { paginatedData, getPaginationData, reset } = usePaginatedEntityData(
        tag,
        baseEntityName,
        customListSearch,
    );
    const searchKey = useMemo(() => {
        // just want to pre populate the search input with whatever may have previously been searched for
        const possibleSearchColumns = ['id', ...textSearchColumns];
        const searchedColumns = Object.keys(paginatedData?.searchables ?? {});

        return searchedColumns.find(col =>
            possibleSearchColumns.find(searchCol => col.endsWith(searchCol)),
        );
    }, [paginatedData?.searchables, textSearchColumns]);

    const [defaultSearchTerm, setDefaultSearchTerm] = useState(
        disableCache ? '' : paginatedData?.searchables?.[searchKey] ?? '',
    );

    const prevSearchOptions = useRef({});
    const tableRef = useRef<HTMLDivElement>();

    const prevLoading = usePrevious(paginatedData?.loadingState?.state);
    const prevPage = usePrevious(paginatedData?.meta?.pagination?.current_page);

    useEffect(() => {
        if (
            paginatedData?.loadingState?.state === NetworkRequestState.Idle &&
            prevLoading === NetworkRequestState.InProgress &&
            paginatedData?.meta?.pagination?.current_page !== prevPage &&
            prevPage != null
        ) {
            tableRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData?.loadingState?.state]);

    const defaultSearchPayload = useMemo<SearchPayload<any>>(
        () =>
            reject(isNil, {
                orderBy,
                direction,
                perPage,
                page,
                showFilters,
                filters,
                includes,
            }),
        [orderBy, direction, perPage, page, showFilters, includes, filters],
    );

    const rows = useMemo(
        () =>
            generateRows({
                ids: renderIds(paginatedData.data),
                baseEntityName,
                routeTemplate,
                onClick: onRowClick,
            }),
        [paginatedData.data, baseEntityName, routeTemplate, renderIds, onRowClick],
    );

    const onSearch = useCallback(
        (page: number, settings: SearchPayload<any>, showFilters?: true, force = false) => {
            const newSearchCriteria = dedupedFilters(
                updateSearchPayload({
                    ...settings,
                    page,
                    showFilters: filterFilters === true ? true : showFilters,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    filterFilters,
                    smartFilters,
                }),
            );
            if (
                force ||
                !equal(
                    searchPayloadForComparison(newSearchCriteria),
                    searchPayloadForComparison(prevSearchOptions.current),
                )
            ) {
                prevSearchOptions.current = newSearchCriteria;
                getPaginationData({ ...newSearchCriteria }, true);
            }
        },
        [getPaginationData, updateSearchPayload, filterFilters, smartFilters],
    );

    const refresh = useCallback(
        (page?: number) => {
            onSearch(
                page ?? paginatedData?.meta?.pagination?.current_page,
                merge<SearchPayload<any>>(defaultSearchPayload, {
                    filters: paginatedData?.filters ?? {},
                    searchables: paginatedData?.searchables ?? {},
                    direction: paginatedData?.direction ?? undefined,
                    orderBy: paginatedData?.orderBy ?? undefined,
                }),
                true,
                true,
            );
        },
        [
            onSearch,
            paginatedData?.meta?.pagination?.current_page,
            paginatedData?.filters,
            paginatedData?.searchables,
            paginatedData?.direction,
            paginatedData?.orderBy,
            defaultSearchPayload,
        ],
    );

    const contextValues = useMemo<PaginatedEntityTableContextValues>(
        () => ({
            search: page => refresh(page),
        }),
        [refresh],
    );

    /**
     * provide a custom ref (useRef) API
     */
    useImperativeHandle(ref, () => contextValues, [contextValues]);

    const onResetFilters = useCallback(() => {
        setDefaultSearchTerm('');

        /**
         * Set default values to new instance of an object.
         * This will auto reset the form.
         */
        onSearch(1, { ...defaultSearchPayload, searchables: { [searchKey]: '' } }, showFilters);
    }, [onSearch, defaultSearchPayload, showFilters, searchKey]);
    useEffect(() => {
        if (disableCache) {
            setDefaultSearchTerm('');
            onResetFilters();
        }
    }, [disableCache, onResetFilters, setDefaultSearchTerm]);
    useEffect(() => {
        if (paginatedData?.meta?.pagination?.current_page == null) {
            onResetFilters();
        } else if (refreshOnMount) {
            refresh();
        }
        return () => {
            if (resetOnUnmount) {
                reset();
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const i18n = useCommonTranslationInstance();
    const mt = useModelFieldTranslation();
    const t = useCommonTranslation();
    const visibleCustomFilters = useMemo<FilterConfig[]>(
        () =>
            (customFilterConfig ?? []).filter(f => {
                if (isAdmin) return true;

                const hasPermissions = hasAll(f.permissions ?? []);
                const hasUserType = isUserOneOfTypes(f.userTypes ?? []);

                return hasPermissions && hasUserType;
            }),
        [customFilterConfig, hasAll, isAdmin, isUserOneOfTypes],
    );

    const actualFilters = useMemo(() => {
        if (!Array.isArray(paginatedData?.meta?.filters)) {
            return [];
        }
        return paginatedData.meta.filters.filter(
            f =>
                f.expose === true &&
                (onlyIncludeFilterKeys ? onlyIncludeFilterKeys?.indexOf(f.key) !== -1 : true) &&
                (hasAll(f.permissions ?? []) || hasAll([PermissionName.Admin])),
        );
    }, [hasAll, paginatedData?.meta?.filters, onlyIncludeFilterKeys]);

    const parsedFilters = useMemo<FilterConfig[]>(() => {
        let allParsedFilters: FilterConfig[] = actualFilters.map(f => {
            const labelKey = `Pagination.filterLabels.${baseEntityName}.${f.key}`;
            return {
                label: i18n.exists(labelKey) ? t(labelKey) : f.label,
                type: CustomFilterConfigType.Select,
                key: f.key,
                permissions: f.permissions,
                options: f.options.map(opt => ({
                    label: mt(baseEntityName, f.key.split('.').pop(), opt.name),
                    value: opt.value,
                    disabled: opt.disabled,
                })),
            };
        });

        visibleCustomFilters.forEach(f => {
            if (f.insertAtIndex != null)
                allParsedFilters = insert(f.insertAtIndex, f, allParsedFilters);
            else allParsedFilters.push(f);
        });

        return allParsedFilters;
    }, [actualFilters, visibleCustomFilters, t, i18n, mt, baseEntityName]);

    const showFilterSectionColumn = useMemo(() => {
        if (!showFilters) {
            return false;
        }
        if (
            paginatedData?.loadingState?.state === NetworkRequestState.InProgress &&
            parsedFilters?.length === 0
        ) {
            // in the middle of loading the filters
            return false;
        }

        if (parsedFilters.length === 0 && textSearchColumns?.length === 0) {
            return false;
        }

        return true;
    }, [
        paginatedData?.loadingState?.state,
        parsedFilters.length,
        showFilters,
        textSearchColumns?.length,
    ]);

    const onSubmit = useCallback(
        (theSearchTerm: string, force = false) => {
            onSearch(
                1,
                {
                    ...paginatedData,
                    searchables: generateSearchColumnsData(
                        textSearchColumns,
                        theSearchTerm,
                        revertToIdSearchOnNumberOnlyInput === true,
                    ),
                },
                undefined,
                force,
            );
        },
        [onSearch, paginatedData, textSearchColumns, revertToIdSearchOnNumberOnlyInput],
    );

    const actualTableProps = useMemo<typeof tableProps>(() => {
        if (rendererType === FilterableEntityTableRendererType.Contained)
            return { stickyHeader: true, ...tableProps };
        return tableProps;
    }, [tableProps, rendererType]);

    const filterForm = useMemo(
        // sticky headers turn the header row white (so you can't see behind it,
        // but that means there is no visible gap between the filters and the table
        () => (
            <Box mb={actualTableProps?.stickyHeader ? 2 : 0}>
                <EntityFilters
                    entityName={baseEntityName}
                    tag={tag}
                    allFilters={parsedFilters}
                    textSearchColumn={textSearchColumns.length > 0 ? searchTermKey : null}
                    reset={onResetFilters}
                    onSubmit={onSubmit}
                    defaultSearchVal={String(defaultSearchTerm)}
                    afterFilters={afterFilters}
                    alwaysOpenFilters={alwaysOpenFilters}
                    filtersInlineSearch={filtersInlineSearch}
                    displayDownloadButton={displayDownloadButton}
                    reImportButton={reImportButton}
                    customFilters={customFilters}
                    customFilterResets={customFilterResets}
                    filterApplyButton={filterApplyButton}
                />
            </Box>
        ),
        [
            actualTableProps?.stickyHeader,
            baseEntityName,
            tag,
            parsedFilters,
            textSearchColumns.length,
            onResetFilters,
            onSubmit,
            defaultSearchTerm,
            afterFilters,
            alwaysOpenFilters,
            filtersInlineSearch,
            displayDownloadButton,
            reImportButton,
            customFilters,
            customFilterResets,
            filterApplyButton,
        ],
    );

    const filterFormComponent = useMemo(() => {
        if (
            (showFilterSectionColumn && paginatedData?.meta?.filters != null) ||
            textSearchColumns.length > 0
        ) {
            return filterForm;
        }

        if (
            (!showFilters || Object.keys(paginatedData?.meta?.filters ?? {}).length === 0) &&
            textSearchColumns.length > 0
        ) {
            return filterForm;
        }
        return null;
    }, [
        filterForm,
        paginatedData?.meta?.filters,
        showFilterSectionColumn,
        showFilters,
        textSearchColumns.length,
    ]);

    const onPageChange = useCallback(
        (e, pageNumber: number, perPage?: number) => {
            onSearch(
                pageNumber,
                paginatedDataToSearchPayload(paginatedData, {
                    perPage: perPage ?? paginatedData?.meta?.pagination?.per_page,
                }),
            );
        },
        [onSearch, paginatedData],
    );

    const pagination = useMemo(
        () => (
            <TablePagination
                showFirstButton
                showLastButton
                count={paginatedData?.meta?.pagination?.total ?? 0}
                totalPages={paginatedData?.meta?.pagination?.total_pages ?? 0}
                onPageChange={onPageChange}
                page={paginatedData?.meta?.pagination?.current_page ?? 1}
                rowsPerPage={paginatedData?.meta?.pagination?.per_page ?? 0}
                loadingPageNumber={
                    loadBar
                        ? paginatedData?.loadingState?.state === NetworkRequestState.InProgress
                            ? paginatedData?.page
                            : undefined
                        : undefined
                }
            />
        ),
        [
            paginatedData?.meta?.pagination?.total,
            paginatedData?.meta?.pagination?.total_pages,
            paginatedData?.meta?.pagination?.current_page,
            paginatedData?.meta?.pagination?.per_page,
            paginatedData?.loadingState?.state,
            paginatedData?.page,
            onPageChange,
            loadBar,
        ],
    );

    const loading = useMemo(
        () => paginatedData?.loadingState?.state === NetworkRequestState.InProgress,
        [paginatedData],
    );
    const error = useMemo(
        () => paginatedData?.loadingState?.state === NetworkRequestState.Error,
        [paginatedData],
    );
    const genericErrorMessage = useCommonTranslation('PaginatedList.genericError');
    const errorMessage = useMemo(
        () => paginatedData?.loadingState?.error ?? genericErrorMessage,
        [genericErrorMessage, paginatedData],
    );

    const paginatedEntityTable = useMemo(
        () => (
            <PaginatedEntityList
                onSearch={onSearch}
                tag={tag}
                paginationData={paginatedData}
                columns={columns}
                rows={rows}
                tableContainerProps={tableContainerProps}
                tableProps={actualTableProps}
                baseEntityName={baseEntityName}
                generateRowSx={generateRowSx}
                disabledRowDividers={disabledRowDividers}
                dense={dense}
            />
        ),
        [
            baseEntityName,
            columns,
            dense,
            disabledRowDividers,
            generateRowSx,
            onSearch,
            paginatedData,
            rows,
            tableContainerProps,
            actualTableProps,
            tag,
        ],
    );
    const rendererProps = useMemo<FilterableEntityTableRendererProps>(
        () => ({
            table: paginatedEntityTable,
            loading: loadBar ? loading : false,
            error: error ? errorMessage : null,
            filterForm: filterFormComponent,
            wrapperComponentRef: tableRef,
            pagination,
        }),
        [
            error,
            errorMessage,
            filterFormComponent,
            loading,
            paginatedEntityTable,
            pagination,
            loadBar,
        ],
    );

    const isMobile = useIsMobileView();
    const Renderer = useMemo(() => {
        if (CustomRenderer != null) return CustomRenderer;

        if (!isMobile && rendererType === FilterableEntityTableRendererType.Contained)
            return ContainedRenderer;

        return DefaultRenderer;
    }, [CustomRenderer, rendererType, isMobile]);

    return (
        <PaginatedEntityTableContext.Provider value={contextValues}>
            <Renderer {...rendererProps} />
        </PaginatedEntityTableContext.Provider>
    );
}

export default React.forwardRef(FilterableEntityTable);
