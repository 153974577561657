import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import {
    Button,
    ControlledFormInput,
    CurrencyFormat,
    DateField,
    EntityField,
    TableCell,
    TableRow,
    TextField,
} from 'c-components';
import { Displays_LineItem, Displays_Owner } from 'c-sdk';
import { cssRotation, numberOfDays } from 'c-lib';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useBoolean, usePrevious } from 'react-hanger';
import { CampaignFormNames } from 'c-main/Types';
import { useFormContext } from 'react-hook-form';
import { useCampaignLineItemCost } from 'c-main/Hooks';
import { useDebounce } from 'use-debounce';
import { isAfter, isBefore, isValid, startOfDay } from 'date-fns';
import { useDateUtils } from 'c-hooks';

type Props = {
    ownerId?: number;
    lineItemIds: number[];
    maxDays: number;
    startDate: Date;
    endDate: Date;
};

const withCostCols = 5;
const noCostCols = withCostCols - 1;

const CostingByOwner: React.FC<Props> = ({ ownerId, lineItemIds, maxDays, startDate, endDate }) => {
    const open = useBoolean(true);

    const { watch } = useFormContext();
    const buyerId = watch(CampaignFormNames.buyer);
    const { hasCost } = useCampaignLineItemCost(+buyerId);

    return (
        <>
            <TableRow className="owner">
                <TableCell colSpan={hasCost ? withCostCols : noCostCols}>
                    <Box display="flex">
                        <Box>
                            <Button variant="text" onClick={open.toggle}>
                                <Typography
                                    variant="h4"
                                    color="grey.600"
                                    alignItems="center"
                                    display="flex"
                                >
                                    {!ownerId && (
                                        <Translate path="Modules.Main.Campaigns.Create.LineItemsSummary.noOwnersHeader" />
                                    )}
                                    {ownerId && (
                                        <EntityField<Displays_Owner>
                                            field="name"
                                            entityName="Displays_Owner"
                                            id={ownerId}
                                        />
                                    )}
                                    {` (${lineItemIds.length})`}
                                    <KeyboardArrowDown css={cssRotation({ rotate: open.value })} />
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
            {open.value && (
                <>
                    {lineItemIds.map(id => (
                        <LineItemDetails
                            key={id}
                            lineItemId={id}
                            maxDays={maxDays}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ))}
                </>
            )}
        </>
    );
};

const LineItemDetails: React.FC<{
    lineItemId: number;
    maxDays: number;
    startDate: Date;
    endDate: Date;
}> = ({ lineItemId, maxDays, startDate, endDate }) => {
    const { apiFormatDateString } = useDateUtils();
    const { watch, setValue } = useFormContext();

    const buyerId = watch(CampaignFormNames.buyer);
    const { getCost, getLineItemDaysKey, getLineItemStartDateKey, getLineItemEndDateKey, hasCost } =
        useCampaignLineItemCost(+buyerId);
    const t = useCommonTranslation();

    const startKey = useMemo(
        () => getLineItemStartDateKey(lineItemId),
        [getLineItemStartDateKey, lineItemId],
    );
    const endKey = useMemo(
        () => getLineItemEndDateKey(lineItemId),
        [getLineItemEndDateKey, lineItemId],
    );
    const daysKey = useMemo(() => getLineItemDaysKey(lineItemId), [getLineItemDaysKey, lineItemId]);

    const theStartDate = watch(startKey);
    const theEndDate = watch(endKey);
    const theDays = watch(daysKey);
    const [debouncedStart] = useDebounce(theStartDate, 500);
    const [debouncedEnd] = useDebounce(theEndDate, 500);
    const [debouncedDays] = useDebounce(theDays, 500);

    const cost = useMemo(
        () => getCost(debouncedDays ?? maxDays),
        [maxDays, debouncedDays, getCost],
    );

    useEffect(() => {
        const startD = new Date(debouncedStart);
        const endD = new Date(debouncedEnd);

        if (isValid(startD) && isValid(endD)) {
            setValue(daysKey, numberOfDays(startD, endD));
        }
    }, [debouncedStart, debouncedEnd, setValue, daysKey]);

    useEffect(() => {
        if (!isValid(new Date(theStartDate))) {
            setValue(startKey, apiFormatDateString({ date: startDate }));
        } else {
            const startDateObj = new Date(theStartDate);
            if (
                isValid(startDateObj) &&
                isBefore(startOfDay(startDateObj), startOfDay(startDate))
            ) {
                // the line item start date is now before the campaign start date
                // set the line item start date to the campaign start date
                setValue(startKey, apiFormatDateString({ date: startDate }));
            }
        }
    }, [theStartDate, setValue, startKey, startDate, apiFormatDateString]);

    useEffect(() => {
        if (!isValid(new Date(theEndDate))) {
            setValue(endKey, apiFormatDateString({ date: endDate }));
        } else {
            const endDateObj = new Date(theEndDate);
            if (isValid(endDateObj) && isAfter(startOfDay(endDateObj), startOfDay(endDate))) {
                // the line item date is now after the campaign end date
                // set the line item date end to the campaign end date
                setValue(endKey, apiFormatDateString({ date: endDate }));
            }
        }
    }, [theEndDate, setValue, endKey, endDate, apiFormatDateString]);

    const prevStartDate = usePrevious(theStartDate);
    const prevEndDate = usePrevious(theStartDate);

    useEffect(() => {
        const startDateObj = new Date(theStartDate);
        const endDateObj = new Date(theEndDate);

        if (isValid(startDateObj) && isValid(endDateObj)) {
            if (prevStartDate !== theStartDate && isAfter(startDateObj, endDateObj)) {
                // start date has changed
                // the start date is after the end date. Set the end date to be the same as the start date.
                setValue(endKey, apiFormatDateString({ date: startDateObj }));
            }
            if (prevEndDate !== theEndDate && isBefore(endDateObj, startDateObj)) {
                // end date has changed
                // the end date is before the start date. Set the start date to be the same as the end date.
                setValue(startKey, apiFormatDateString({ date: endDateObj }));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theStartDate, theEndDate, setValue, endKey, startKey, apiFormatDateString]);

    return (
        <TableRow className="lineitem">
            <TableCell>
                <EntityField<Displays_LineItem>
                    id={lineItemId}
                    entityName="Displays_LineItem"
                    field="name"
                />
            </TableCell>
            <TableCell>
                <ControlledFormInput
                    name={startKey}
                    defaultValue={startDate}
                    render={({ field }) => (
                        <DateField
                            {...field}
                            inputProps={{ variant: 'standard', fullWidth: false, disabled: true }}
                            minDate={startDate}
                            maxDate={endDate}
                        />
                    )}
                />
            </TableCell>
            <TableCell>
                <ControlledFormInput
                    name={endKey}
                    defaultValue={endDate}
                    render={({ field }) => (
                        <DateField
                            {...field}
                            inputProps={{ variant: 'standard', fullWidth: false, disabled: true }}
                            minDate={startDate}
                            maxDate={endDate}
                        />
                    )}
                />
            </TableCell>

            <TableCell>
                <ControlledFormInput
                    name={daysKey}
                    defaultValue={maxDays}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            placeholder={t(
                                'Modules.Main.Campaigns.Create.Costing.daysInputPlaceholder',
                                {
                                    count: maxDays,
                                },
                            )}
                            fullWidth={false}
                            variant="standard"
                            whiteBackdrop={false}
                            greyBackdrop={false}
                            sx={{ width: '6em' }}
                            type="number"
                        />
                    )}
                />
            </TableCell>
            {hasCost && (
                <TableCell>
                    <CurrencyFormat value={cost} />
                </TableCell>
            )}
        </TableRow>
    );
};

export default CostingByOwner;
