import { BaseEntity } from '../../BaseEntity';
import { Owner } from '../Portal/Owner';
import { Market } from '../Portal/Market';
import { Creative } from '../Portal/Creative';
import { CampaignPopShot } from '../Portal/CampaignPopShot';
import { Displays_Resolution } from './Displays_Resolution';
import { Displays_Screen } from './Displays_Screen';
import { ScheduleGet } from '../Portal';

export enum Displays_LineItemLineItemType {
    Digital = 'digital',
    Package = 'package',
    Logo = 'logo',
    Analogue = 'analogue',
    SiteGroup = 'site_group',
}

export enum Displays_LineItemAnimation {
    Static = 'Static',
    Motion = 'Motion',
    JPEG = 'JPEG',
    Subtle = 'Subtle',
    SubtleInboundOnly = 'Subtle (Inbound Only)',
}

export type Displays_LineItem = BaseEntity & {
    id: string;
    name?: string;
    type: Displays_LineItemLineItemType;
    unilive_id?: number;
    resolution?: string;
    animation: Displays_LineItemAnimation;
    description?: string;
    size?: string;

    // strinteger
    slot_length?: string;
    // strinteger
    slots?: string;
    environment?: string;

    // absolute url to brochure file
    brochure?: string;
    // absolute url to brochure
    brochure_link?: string;

    filetypes?: string;

    owner_id?: number;
    market_id?: number;

    owner?: Owner;
    market?: Market;

    bookedDisplaysCount?: number;

    schedules?: ScheduleGet[];

    // include
    resolutions?: Displays_Resolution[];

    // include
    fileTypes?: Displays_LineItemFileType[];

    // include
    frame_id?: number | string;

    // creative data is relative to the campaign you load the line items for.
    // only available via `apiClient.Entities.Campaign.lineItems` endpoint

    // creatives assigned to the line item
    // include
    creatives?: Creative[];

    // include
    creativesCount?: number;

    // creatives which would be automatically assigned to the creative
    // include
    availableCreatives?: Creative[];

    // include
    availableCreativesCount?: number;

    // include
    campaignPopShots?: CampaignPopShot[];
    campaignPopShotsCount?: number;

    // include
    screens?: Displays_Screen[];
    screensCount?: number;
};

export type Displays_LineItemFileType = BaseEntity & {
    type: string;
    is_static_animation: boolean;
};
