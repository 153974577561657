import React, { useCallback, useMemo, useRef } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { User } from 'c-entity-types';
import { initials } from 'c-admin/Lib';
import { UserProfilePopOver } from 'c-admin/Components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, Campaign } from 'c-sdk';
import { Box, CircularProgress, ClickAwayListener, IconButton, Popper } from '@mui/material';
import { editIcon } from 'c-main/icons';
import { Autocomplete, AutoGrid, OptionSchema } from 'c-components';
import { useBoolean } from 'react-hanger';
import { usePaginatedEntityData, useUpdateEntityData } from 'c-data';
import { DataStatusPaginationTag } from 'c-main/entityColumns';
import { NetworkRequestState } from 'c-data-layer';
import { useEntityField } from 'c-hooks';

type Props = RenderColumnDataProps<Campaign> & {
    filterKey?: string;
};

const adminPerms = [PermissionName.Admin];

const CampaignUserCell: React.FC<Props> = ({
    columnText,
    entity,
    filterKey,
    baseEntity,
    column,
}) => {
    const btnRef = useRef();
    const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);
    const { paginatedData } = usePaginatedEntityData(DataStatusPaginationTag, 'Campaign');

    const opts = useMemo<OptionSchema[]>(
        () =>
            paginatedData.meta?.filters
                ?.find(value => value.key === filterKey)
                ?.options?.map(({ name, value }) => ({
                    label: name,
                    value,
                })) ?? [],
        [paginatedData?.meta?.filters, filterKey],
    );

    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(baseEntity?.id).state === NetworkRequestState.InProgress,
        [baseEntity?.id, getUpdatingById],
    );

    const onChange = useCallback(
        val => {
            update(baseEntity.id, {
                id: baseEntity.id,
                // have to force null rather than just undefined because undefined isn't serialised into JSON
                [column.field]: val == null ? null : val,
            } as Campaign);
        },
        [update, baseEntity.id, column.field],
    );

    const { text } = useEntityField<User>(entity?.[column.field], 'User', 'name');

    return (
        <AutoGrid columnGap={1} flexWrap="nowrap" alignItems="center">
            <UserProfilePopOver userId={entity[column.field]}>
                <>{text != null ? initials(text) : ''}</>
            </UserProfilePopOver>

            <IfHasAllPermissions permissions={adminPerms}>
                <>
                    <IconButton size="small" onClick={open} ref={btnRef} disabled={loading}>
                        {loading ? <CircularProgress size={15} /> : editIcon}
                    </IconButton>
                    <Popper open={isOpen} anchorEl={btnRef.current}>
                        <ClickAwayListener onClickAway={close}>
                            <Box width={250}>
                                <Autocomplete
                                    options={opts}
                                    value={entity[column.field]}
                                    onChange={onChange}
                                    textFieldProps={{ autoFocus: true }}
                                    open={isOpen}
                                    multiple={false}
                                    onClose={close}
                                />
                            </Box>
                        </ClickAwayListener>
                    </Popper>
                </>
            </IfHasAllPermissions>
        </AutoGrid>
    );
};

export default CampaignUserCell;
