import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import {
    CampaignInviteDetails,
    ImpersonateResponse,
    LoginResponse,
    ModuleConstructorProps,
    RegisterPayload,
    User,
    UserType,
} from '../Types';
import { generateArrayQSParams } from '../Lib';

const Auth = ({ endpoints, httpClient }: ModuleConstructorProps) => ({
    login: (
        {
            email,
            password,
            userType,
        }: {
            email: string;
            password: string;
            userType?: UserType;
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<LoginResponse>(
            endpoints.auth.login,
            {
                email,
                password,
                chosen_user_type: userType,
            },
            { withCredentials: false, ...config },
        ),
    refresh: (config?: AxiosRequestConfig) =>
        httpClient.post<LoginResponse>(endpoints.auth.refresh, config),
    logout: (config?: AxiosRequestConfig) => httpClient.post(endpoints.auth.logout, config),
    requestPasswordResetEmail: ({ email }: { email: string }, config?: AxiosRequestConfig) =>
        httpClient.post(
            endpoints.auth.requestForgotPassword,
            { email },
            { withCredentials: false, ...config },
        ),

    resetPassword: (
        {
            email,
            password_reset_token,
            password,
        }: {
            email: string;
            password_reset_token: string;
            password: string;
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            endpoints.auth.resetPassword,
            {
                email,
                password,
                token: password_reset_token,
            },
            { withCredentials: false, ...config },
        ),
    startImpersonating: (userId: number, userType: UserType, config?: AxiosRequestConfig) =>
        httpClient.post<ImpersonateResponse>(
            urlcat(endpoints.auth.startImpersonating, {
                id: userId,
            }),
            { chosen_user_type: userType },
            config,
        ),
    stopImpersonating: (config?: AxiosRequestConfig) =>
        httpClient.get<ImpersonateResponse>(endpoints.auth.stopImpersonating, config),
    inviteDetails: (token: string, config?: AxiosRequestConfig) =>
        httpClient.post<{ data: CampaignInviteDetails }>(
            endpoints.auth.inviteDetails,
            { token },
            {
                ...config,
            },
        ),
    inviteAccept: (token: string, config?: AxiosRequestConfig) =>
        httpClient.post(
            endpoints.auth.inviteAccept,
            { token },
            {
                ...config,
            },
        ),
    register: (data: RegisterPayload, includes?: string[], config?: AxiosRequestConfig) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');

        return httpClient.post<{ data: User }>(`${endpoints.auth.register}${includeParams}`, data, {
            ...config,
        });
    },
    verifyEmail: (verification_code: string, includes?: string[], config?: AxiosRequestConfig) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');

        return httpClient.post<{ data: User }>(
            `${endpoints.auth.verifyEmail}${includeParams}`,
            { verification_code },
            {
                ...config,
            },
        );
    },
    revalidate: (key: string, config?: AxiosRequestConfig) =>
        httpClient.post<LoginResponse>(`${endpoints.auth.revalidate}/${key}`, config),
});

export default Auth;
