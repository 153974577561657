import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
    Box,
    Grid,
    IconButton,
    List,
    Pagination,
    PaginationProps,
    Tooltip,
    Typography,
} from '@mui/material';
import { SpotlightRecentResource } from 'c-sdk';
import { usePaginatedEntityData } from 'c-data';
import { useCommonTranslation } from 'c-translation';
import { splitEvery } from 'ramda';
import { useNumber } from 'react-hanger';
import { Clear } from '@mui/icons-material';
import { SystemSearchRecentResourceThunks } from 'c-system-search/Slices/SystemSearchRecentResource';
import SystemSearchRecentPage from '../SystemSearchRecentPage';
import { SystemSearchSelectedItemGrid } from '../../types';
import {
    RECENT_PAGES_PAGINATION_TAG,
    RECENT_PAGES_PER_PAGE,
    RECENT_PAGES_SEARCH_PAYLOAD,
} from '../../constants';
import { useDispatch } from 'react-redux';

type Props = {
    selected: SystemSearchSelectedItemGrid;
    onSelected: (x: number, y: number) => void;
};

const SystemSearchRecentPages: React.FC<Props> = ({ selected, onSelected }) => {
    const { getPaginationData, paginatedData } = usePaginatedEntityData<SpotlightRecentResource>(
        RECENT_PAGES_PAGINATION_TAG,
        'SpotlightRecentResource',
    );

    // TEMP FOR TESTING MULTIPLE COLS
    // const {
    //     paginatedData: recentCustomers,
    //     getPaginationData: getCustomers,
    // } = usePaginatedEntityData<SystemSearchRecentResource>(
    //     RECENT_CUSTOMERS_PAGINATION_TAG,
    //     'SystemSearchRecentResource',
    // );

    useEffect(() => {
        getPaginationData(RECENT_PAGES_SEARCH_PAYLOAD, true);
        // getCustomers(1, RECENT_CUSTOMERS_SEARCH_PAYLOAD, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = useMemo(
        () => splitEvery(RECENT_PAGES_PER_PAGE, paginatedData?.data ?? []),
        [paginatedData?.data],
    );

    const indexState = useNumber(0);

    const onChangeIndex = useCallback(
        index => {
            indexState.setValue(index);
        },
        [indexState],
    );

    const dragRef = useRef(false);
    const timeoutRef = useRef(null);

    const onDragStart = useCallback(() => {
        clearTimeout(timeoutRef.current);
        dragRef.current = true;
    }, []);
    const onDragEnd = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            dragRef.current = false;
        }, 1000);
    }, []);
    const onItemSelected: Props['onSelected'] = useCallback(
        (x, y) => {
            setTimeout(() => {
                if (dragRef.current === false) {
                    // stop 'clicks' while swiping so users don't navigate away to other pages while swiping

                    onSelected(x, indexState.value * RECENT_PAGES_PER_PAGE + y);
                    // onSelected(x, y);
                }
            }, 250);
        },
        [onSelected, indexState.value],
    );
    const onPageChanged: PaginationProps['onChange'] = useCallback(
        (e, page) => {
            indexState.setValue(page - 1);
        },
        [indexState],
    );

    const pagination = useMemo(
        () =>
            data.length > 1 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={data.length}
                        onChange={onPageChanged}
                        page={indexState.value + 1}
                        color="primary"
                    />
                </Box>
            ) : null,
        [data.length, indexState.value, onPageChanged],
    );

    const dispatch = useDispatch();
    const onDeleteAll = useCallback(() => {
        dispatch(SystemSearchRecentResourceThunks.clearHistory());
    }, [dispatch]);

    const haveData = useMemo(() => paginatedData?.data?.length > 0, [paginatedData?.data?.length]);

    const clearAllLabel = useCommonTranslation('SystemSearch.recentPagesClearAll');
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Box>
                        <Typography variant="h4" color="textPrimary">
                            {useCommonTranslation('SystemSearch.recentPages')}
                            {haveData && (
                                <Tooltip title={clearAllLabel}>
                                    <IconButton onClick={onDeleteAll}>
                                        <Clear />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Typography>
                    </Box>
                    <SwipeableViews
                        style={{ width: '100%' }}
                        enableMouseEvents
                        onChangeIndex={onChangeIndex}
                        resistance
                        index={indexState.value}
                        onSwitching={onDragStart}
                        onTransitionEnd={onDragEnd}
                    >
                        {data.map((list, li) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Box key={li}>
                                <List disablePadding>
                                    {list.map((p, i) => (
                                        <SystemSearchRecentPage
                                            key={p}
                                            id={p}
                                            x={0}
                                            y={i}
                                            onClick={onItemSelected}
                                            active={selected.x === 0 && selected.y === i}
                                        />
                                    ))}
                                </List>
                            </Box>
                        ))}
                    </SwipeableViews>
                    {pagination}
                </Grid>
                {/* <Grid item xs={12} md={6}> */}
                {/*    <Box> */}
                {/*        <Typography variant="h4"> */}
                {/*            {useCommonTranslation('SystemSearch.otherColumn')} */}
                {/*        </Typography> */}
                {/*    </Box> */}
                {/*    <Typography>Work In Progress</Typography> */}
                {/* <List disablePadding> */}
                {/*   {recentCustomers.data.map((p, i) => ( */}
                {/*       <SystemSearchRecentPage */}
                {/*           key={p} */}
                {/*           id={p} */}
                {/*           x={1} */}
                {/*           y={i} */}
                {/*           onClick={onResourceClick} */}
                {/*           active={selected.x === 1 && selected.y === i} */}
                {/*       /> */}
                {/*   ))} */}
                {/* </List> */}
                {/* </Grid> */}
            </Grid>
        </Box>
    );
};

export default SystemSearchRecentPages;
