import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignPopShot } from 'c-sdk';
import { Box, CircularProgress } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { popShotToFile } from 'c-main/Lib';
import { AutoGrid, DialogV2, FileBrowserFile, FlashMessage } from 'c-components';
import { usePrevious } from 'react-hanger';
import to from 'await-to-js';
import FileBrowserCarouselView from 'c-components/FileBrowser/FileBrowserCarouselView';
import { atom_popShotHasProShots, atom_popShotShowPro, atom_uploadPopShotDialog } from './atoms';
import PopShotTile from './PopShotTile';

type Props = {
    id: number;
};

const CampaignOverviewPopShotsTab: React.FC<Props> = ({ id }) => {
    const { start, isLoading, hasFailed, error, data } = useAPIClientRequest(
        apiClient.Entities.CampaignPopShot.list,
    );

    const loadImages = useCallback(async () => {
        await to(
            start({
                filters: { 'filter.campaign_id': [id] },
                perPage: 9999,
                includes: ['file', 'lineItem'],
                direction: 'desc',
            }),
        );
    }, [start, id]);

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const dialogOpen = useAtomValue(atom_uploadPopShotDialog);
    const prevDialogOpen = usePrevious(dialogOpen);

    useEffect(() => {
        if (!dialogOpen && prevDialogOpen) loadImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogOpen]);

    const onlyPro = useAtomValue(atom_popShotShowPro);

    const popShots = useMemo<CampaignPopShot[]>(() => {
        if (Array.isArray(data?.data?.data))
            return data.data.data.filter(pop => (onlyPro ? pop.type === 'professional' : true));
        return [];
    }, [data, onlyPro]);

    const setHasProPopShots = useSetAtom(atom_popShotHasProShots);
    useEffect(() => {
        setHasProPopShots(popShots?.some(p => p.type === 'professional'));
    }, [popShots, setHasProPopShots]);

    const files = useMemo<FileBrowserFile[]>(
        () =>
            popShots.filter(popshot => popshot.file != null).map(popshot => popShotToFile(popshot)),
        [popShots],
    );

    const [openPreviewIndex, setOpenPreviewIndex] = useState<number>(null);

    const openPreviewFile = useMemo(
        () => (openPreviewIndex != null ? files[openPreviewIndex] : null),
        [files, openPreviewIndex],
    );

    const closePreview = useCallback(() => {
        setOpenPreviewIndex(null);
    }, []);

    const fileComponents = useMemo(
        () => (
            <AutoGrid xs={12} md={6} lg={4} xl={3} spacing={2}>
                {popShots
                    .filter(popshot => popshot.file != null)
                    .map((popShot, pi) => (
                        <PopShotTile
                            key={popShot.id}
                            onClick={setOpenPreviewIndex}
                            onDelete={loadImages}
                            onUpdated={loadImages}
                            popShot={popShot}
                            index={pi}
                        />
                    ))}
            </AutoGrid>
        ),
        [popShots, loadImages],
    );

    const carouselDialog = useMemo(
        () => (
            <DialogV2
                open={openPreviewFile != null}
                onClose={closePreview}
                maxWidth={'90vw' as any}
            >
                {openPreviewFile && (
                    <Box height="85vh">
                        <FileBrowserCarouselView
                            files={files}
                            onFileClick={null}
                            ListWrapper={null}
                            filters={{
                                aspectRatios: [],
                                resolutions: [],
                                term: '',
                                fileTypes: [],
                            }}
                            onFileToggled={null}
                            selectedFiles={null}
                            preferredSize="raw"
                            maxHeight="65vh"
                            initialSelectedFileIndex={openPreviewIndex}
                        />
                    </Box>
                )}
            </DialogV2>
        ),
        [closePreview, openPreviewFile, openPreviewIndex, files],
    );

    return (
        <>
            {hasFailed && (
                <FlashMessage mb={2} status="error">
                    {String(error)}
                </FlashMessage>
            )}

            <Box maxHeight="75vh" display="flex" overflow="auto" p={2}>
                {isLoading && <CircularProgress />}
                {!isLoading && fileComponents}
                {carouselDialog}
            </Box>
        </>
    );
};

export default CampaignOverviewPopShotsTab;
