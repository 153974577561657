import { TooltipProps } from 'recharts';
import {
    EsPCAReportFilterField,
    PCAReportCountField,
    PCAReportField,
    PCAReportMetric,
    PCAReportRequestPayload,
    Campaign,
} from 'c-sdk';
import { ReportDateSettings, ReportDateTimeframe, ReportWrapperProps } from 'c-reports/Types';
import { ReactNode } from 'react';

const rootPcaMetricPath = 'pca.metric';
export const PCAMetricUrlStateKeys = {
    selfRefiningFilters: `pca.srf`,
    sandbox: `pca.sandbox`,
    root: rootPcaMetricPath,
    view: `${rootPcaMetricPath}.view`,
    field: `${rootPcaMetricPath}.field`,
    fieldSearch: `${rootPcaMetricPath}.fieldSearch`,
    reportType: `${rootPcaMetricPath}.reportType`,
    byFieldReportType: `${rootPcaMetricPath}.fieldReportType`,

    date: `${rootPcaMetricPath}.date`,
    timeframe: `${rootPcaMetricPath}.date.timeframe`,
    startDate: `${rootPcaMetricPath}.date.startDate`,
    endDate: `${rootPcaMetricPath}.date.endDate`,

    filters: `${rootPcaMetricPath}.filters`,
    countFields: `${rootPcaMetricPath}.countFields`,
};

export type PCAUrlState = {
    view?: ReportView;
    field?: PCAReportField | EsPCAReportFilterField;
    fieldSearch?: string;
    reportType?: FieldReportType;
    byFieldReportType?: FieldReportType;

    // self refining filters (ES only)
    srf?: boolean;
    // 1 or 0
    sandbox?: number;
    environment?: string;
    source?: string;

    date?: {
        timeframe?: ReportDateTimeframe;
        startDate?: string;
        endDate?: string;
    };

    filters?: PCAReportRequestPayload['fieldFilters'];
    countFields?: PCAReportRequestPayload['countFields'];
};

export enum ReportView {
    Overall = 'overall',
    Field = 'field',
    Map = 'map',
}

export enum ReportSource {
    ElasticSearch = 'elastic',
    Athena = 'athena',
}

export type ReportTabProps = {
    campaign: Campaign;
    campaignStart: Date;
    campaignEnd: Date;
    currentStartDate: Date;
    currentEndDate: Date;
    currentTimeframe?: ReportDateTimeframe;
    metric: PCAReportMetric;
    overTimeAccumulative: boolean;
    setOverTimeAccumulative: (state: boolean) => void;
    onResponse?: (response, startDate: Date, endDate: Date, timeframe: ReportDateTimeframe) => void;
    showAdvancedFilters?: boolean;
    advancedFilter?: ReactNode;
    dateSettings: ReportDateSettings;
    environment: string;
    source?: ReportSource;

    fieldFilters?: Record<string, string[]>;
    countFields: PCAReportCountField[];
};

export enum FieldReportType {
    Overall,
    OverTime,
    OverallLeaderboard,
}

export type ByFieldChartProps = {
    campaignId: number;
    minDate: Date;
    maxDate: Date;

    currentStartDate: Date;
    currentEndDate: Date;
    currentTimeframe: ReportDateTimeframe;

    searchTerm?: string;

    environment: string;
    source?: ReportSource;

    metric: PCAReportMetric;
    pcaData: any;
    allFields?: string[];
    actualSelectedFieldParts?: string[];
    timeFrame: ReportDateTimeframe;
    field: string;
    countFields: PCAReportCountField[];

    actualLabel: string;
    percentageLabel: string;
    performanceLabel: string;
    expectedLabel: string;
    missingLabel: string;
    fieldLabel: string;
    dateLabelLabel: string;
    hourLabel: string;
    inScheduleLabel: string;
    outScheduleLabel: string;
    unbookedLabel: string;

    renderTooltipLabel: (tooltipProps: TooltipProps<any, any>) => JSX.Element;
    renderTooltipLabelHideActualValue: (tooltipProps: TooltipProps<any, any>) => JSX.Element;

    reportWrapperProps: ReportWrapperProps<any>;
    lineChartAccumulative?: boolean;
};

export enum MapDataType {
    HeatNone,
    HeatActual,
    HeatExpected,
    HeatPerformance,
    HeatInSchedule,
    HeatOutSchedule,
    HeatUnbooked,
    Marker,
}
