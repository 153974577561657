import {
    callApi,
    errorMessageFromResponse,
    NetworkRequestState,
    normaliseDataThunks,
    SearchPayload,
} from 'c-data-layer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { generateSearchColumnsData } from 'c-pagination';
import { AnEntity, ListSearchResponse } from 'c-sdk';
import { getUnixTime } from 'date-fns';
import { apiClient, commonSchemas } from 'c-data';
import { CommonThunk } from 'c-types';
import { actions } from './systemSearch-slice';
import { entityLoadingStateByTag, entitySearchTimeByTag } from './systemSearch-selectors';
import { SystemSearchEntityResource, SystemSearchEntitySearchResult } from '../../types';

export const open = (): CommonThunk => async dispatch => {
    dispatch(actions.toggleOpen(true));
};

export const close = (): CommonThunk => async dispatch => {
    dispatch(actions.toggleOpen(false));
};

export const updateSearchTerm =
    (term: string): CommonThunk =>
    async dispatch => {
        dispatch(actions.updateSearchTerm(term));
    };

export const searchEntities =
    (term: string, entities: SystemSearchEntityResource[]): CommonThunk =>
    async (dispatch, getState) => {
        const tag = term;
        const loadingState = entityLoadingStateByTag(getState(), tag);
        const searchTime = entitySearchTimeByTag(getState(), tag);
        const searchTimeDiff =
            searchTime == null ? 0 : Math.abs(getUnixTime(new Date()) - searchTime);

        if (loadingState === NetworkRequestState.InProgress) {
            // already doing this exact search
            return;
        }

        // only search for the same search term every 30 seconds
        if (loadingState === NetworkRequestState.Success && searchTimeDiff < 30) {
            return;
        }

        dispatch(actions.startLoadingEntities({ tag, term }));

        const entity = entities[0];
        const searchPayload: SearchPayload<any> = {
            page: 1,
            perPage: 5,
            searchables: generateSearchColumnsData(entity.searchColumn, term, true),
            orderBy: entity.orderByColumn ?? 'id',
            direction: entity.orderByDirection ?? 'desc',
        };

        const [error, success] = await dispatch(
            callApi(
                apiClient.Entities[entity.entityName].list(searchPayload).request as Promise<
                    AxiosResponse<ListSearchResponse<AnEntity>>
                >,
            ),
        );

        if (!error) {
            // Update the CRM's data
            dispatch(
                normaliseDataThunks.updateWithNormalisedData(
                    [commonSchemas[entity.entityName]],
                    success.data,
                ),
            );

            // generate systemSearch search results
            const results = success.data.reduce((acc, curr) => {
                acc.push({ entityName: entity.entityName, id: curr.id });
                return acc;
            }, [] as SystemSearchEntitySearchResult[]);

            dispatch(actions.finishLoadingEntities({ tag, results, term }));
        } else {
            dispatch(
                actions.failedLoadingEntities({
                    tag,
                    error: errorMessageFromResponse(error),
                    term,
                }),
            );
        }
    };
