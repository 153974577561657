import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCommonTranslation } from 'c-translation';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';

import { useAPIClientRequest } from 'c-hooks';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tooltip,
    Typography,
} from '@mui/material';
import { Alert, Button, Checkbox, Chip, DialogV2, TextAreaField, TextField } from 'c-components';
import { UseBoolean, useBoolean } from 'react-hanger';
import { TabContext, TabList } from '@mui/lab';
import { ContentCopy, Search } from '@mui/icons-material';
import { MediaTable as MediaTableList } from 'c-sdk';

import PopSettingsCreativesSearch from 'c-main/Components/Schedule/Components/PopSettings/PopSettingsCreativesSearch';
import ParamsTable from 'c-main/Components/Schedule/Components/MediaTable/ParamsTable';

import MediaTableGroups from 'c-main/Components/Schedule/Components/MediaTable/MediaTableGroups';
import UseAPIClientRequest from '../../../../../../Hooks/useAPIClientRequest';

type Props = {
    campaignId: number;
    addNameState: UseBoolean;
};

const tabLabels = [
    { label: 'ID', field: 'id', translation: 'id' },
    { label: 'Name', field: 'name', translation: 'name' },
    { label: 'Media Ext ID', field: 'media_extid', translation: 'mediaExtId' },
    { label: 'Creative Ref', field: 'creative_ref', translation: 'creativeRef' },
    { label: 'Campaign Name', field: 'campaign_name', translation: 'campaignName' },
    { label: 'Campaign Ext ID', field: 'campaign_extid', translation: 'campaignExtId' },
    { label: 'Campaign Ref', field: 'campaign_ref', translation: 'campaignRef' },
];

const MediaTable: React.FC<Props> = ({ campaignId, addNameState }) => {
    const t = useCommonTranslation();
    const [activeTab, setActiveTab] = useState('0');
    const [paramsData, setParamsData] = useState([]);
    const [tabContent, setTabContent] = useState<{ [key: string]: string[] }>({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [media_ids, setMedia_ids] = useState<Set<number>>(new Set());
    const [mediaGroups, setMediaGroups] = useState({});
    const [activeGroup, setActiveGroup] = useState<string | null>('All');

    const { start: groupStart, error: groupError } = useAPIClientRequest(
        ApiClient.Entities.Campaign.editGroup,
    );
    const { start: groupRemove, error: groupRemoveError } = useAPIClientRequest(
        ApiClient.Entities.Campaign.removeIdsFromMediaGroup,
    );
    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            setActiveTab(newValue.toString());
        },
        [setActiveTab],
    );
    const paramDialogState = useBoolean(false);
    const [tableData, setTableData] = useState<MediaTableList[]>([]);
    const allMediaNames = useMemo(() => tableData.map(media => media.name), [tableData]);
    const filteredTableData = useMemo(
        () =>
            tableData.filter(row => {
                const matchesSearch = Object.values(row).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
                );

                const matchesParameter = selectedParameter
                    ? row.parameter?.some(param => param?.parameter === selectedParameter)
                    : true;
                const matchesGroup = activeGroup !== 'All' ? row.group_name === activeGroup : true;

                return matchesSearch && matchesParameter && matchesGroup;
            }),
        [tableData, searchQuery, selectedParameter, activeGroup],
    );
    const isAllSelected = useMemo(() => {
        if (filteredTableData.length === 0) return false; // No rows to select
        return filteredTableData.every(row => media_ids.has(row.id));
    }, [filteredTableData, media_ids]);

    const isIndeterminate = useMemo(
        () => filteredTableData.some(row => media_ids.has(row.id)) && !isAllSelected,
        [filteredTableData, media_ids, isAllSelected],
    );

    const handleCheckboxChange = useCallback((id: number) => {
        setMedia_ids(prevSelectionModel => {
            const newSelection = new Set(prevSelectionModel);
            if (newSelection.has(id)) {
                newSelection.delete(id);
            } else {
                newSelection.add(id);
            }
            return newSelection;
        });
    }, []);

    const handleSelectAll = useCallback(() => {
        setMedia_ids(prevSelectionModel => {
            const newSelection = new Set(prevSelectionModel);

            if (isAllSelected) {
                // Deselect all visible rows
                filteredTableData.forEach(row => newSelection.delete(row.id));
            } else {
                // Select all visible rows
                filteredTableData.forEach(row => newSelection.add(row.id));
            }

            return newSelection;
        });
    }, [filteredTableData, isAllSelected]);

    const cols: GridColDef[] = [
        {
            field: 'checkbox',
            headerName: '',
            sortable: false,
            renderHeader: () => (
                <Checkbox
                    checked={isAllSelected}
                    indeterminate={isIndeterminate}
                    onChange={handleSelectAll}
                />
            ),
            renderCell: ({ row }) => (
                <Checkbox
                    checked={media_ids.has(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                />
            ),
        },
        {
            field: 'id',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.id'),
            width: 90,
        },
        {
            field: 'name',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.name'),
            renderHeader: () => (
                <Stack direction="row" alignItems="center" gap={1}>
                    <Box>{t('Modules.Main.Campaigns.CampaignSchedule.popSettings.name')}</Box>
                    <Tooltip title={t('Copy all media names')}>
                        <IconButton
                            size="small"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(allMediaNames.join('\n'));
                            }}
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
            flex: 1,
        },
        {
            field: 'logImportSource.name',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.source'),
            width: 170,
            renderCell: ({ row }) => <Box>{row?.logImportSource?.name}</Box>,
        },
        {
            field: 'first_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.firstSeen'),
            width: 170,
        },
        {
            field: 'last_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.lastSeen'),
            width: 170,
        },
        {
            field: 'parameter',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.parameters'),
            flex: 1,
            renderCell: ({ row }) => (
                <Stack gap={1} direction="row">
                    {row?.parameter?.map((param: any) => {
                        if (param != null) {
                            return (
                                <Chip
                                    key={param?.id}
                                    label={`${param.parameter}: ${param.identifier}`}
                                />
                            );
                        }
                        return '';
                    })}
                </Stack>
            ),
        },
    ];
    const { start, data, isLoading, error } = UseAPIClientRequest(
        ApiClient.Entities.Campaign.mediaList,
    );
    const {
        start: getParamStart,
        error: paramsGetError,
        isLoading: getParmasLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.GetMediaParameter);
    const {
        start: updateParamsStart,
        error: updateError,
        isLoading: updateLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.UpdateMediaParameter);
    const {
        start: delParam,
        error: delError,
        isLoading: delLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.DeleteMultipleMediaParameters);

    const {
        start: syncStart,
        data: syncData,
        error: syncError,
        isLoading: syncLoading,
    } = UseAPIClientRequest(ApiClient.Entities.Campaign.syncMedia);

    const getMediaListData = useCallback(async () => {
        const [err, succ] = await to(start(campaignId));
        if (succ) {
            const uniqueData = Array.from(
                new Map(succ.data.media.map(item => [item.id, item])).values(),
            ); // Ensure no duplicates
            setTableData(uniqueData);
            setMediaGroups(succ.data.mediaGroups);
        }
    }, [start, campaignId]);
    useEffect(() => {
        if (addNameState.value) {
            getMediaListData();
            addNameState.setFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNameState.value]);
    useEffect(() => {
        getMediaListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getParams = useCallback(async () => {
        const [err, succ] = await to(getParamStart(campaignId));
        if (succ) {
            setParamsData(succ.data.data);
        }
    }, [campaignId, getParamStart]);

    const refreshData = useCallback(async () => {
        await to(getParams());
        await to(getMediaListData());
    }, [getMediaListData, getParams]);
    const handleGroupChange = useCallback(
        async (group_name: string) => {
            const [err, succ] = await to(
                groupStart(campaignId, { group_name, media_ids: Array.from(media_ids) }),
            );
            if (succ) {
                setMedia_ids(new Set());
                refreshData();
            }
        },
        [campaignId, groupStart, media_ids, refreshData],
    );
    const handleGroupRemove = useCallback(async () => {
        const [err, succ] = await to(groupRemove(campaignId, { media_ids: Array.from(media_ids) }));
        if (succ) {
            setMedia_ids(new Set());
            refreshData();
        }
        if (err) {
            console.log(err);
        }
    }, [campaignId, groupRemove, media_ids, refreshData]);

    const handleDialogOpen = useCallback(() => {
        setTabContent({});
        getParams();
        paramDialogState.setTrue();
    }, [paramDialogState, getParams]);

    const handleApplyChanges = useCallback(async () => {
        const updatedFields = Object.keys(tabContent).reduce((acc, field) => {
            if (tabContent[field] && tabContent[field].length > 0) {
                acc[field] = tabContent[field];
            }
            return acc;
        }, {} as { [key: string]: string[] });
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, identifiers] of Object.entries(updatedFields)) {
            const payload = {
                parameter: field as
                    | 'id'
                    | 'name'
                    | 'media_extid'
                    | 'creative_ref'
                    | 'campaign_name'
                    | 'campaign_extid'
                    | 'campaign_ref',
                identifier: identifiers,
            };

            // eslint-disable-next-line no-await-in-loop
            await to(updateParamsStart(campaignId, payload));
        }
        paramDialogState.setFalse();
        await to(refreshData());
    }, [tabContent, paramDialogState, refreshData, updateParamsStart, campaignId]);

    const handleTabContentChange = useCallback((field: string, value: string) => {
        setTabContent(prev => ({
            ...prev,
            [field]: value.split('\n').filter(v => v.trim()), // Remove empty lines
        }));
    }, []);

    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={() => setSearchQuery('')}>
                <Typography variant="caption">
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.clear')}
                </Typography>
            </Button>
        ),
        [t],
    );
    const handleDeleteSelected = useCallback(
        async (ids: number[]) => {
            if (ids.length === 0) return;
            const [err, succ] = await to(delParam(campaignId, ids));
            if (succ) await refreshData();
        },
        [campaignId, delParam, refreshData],
    );
    const handleSync = useCallback(async () => {
        await to(syncStart(campaignId));
        await to(refreshData());
    }, [campaignId, refreshData, syncStart]);
    const handleExportCSV = () => {
        const headers = ['ID', 'Name', 'Source', 'First Seen', 'Last Seen', 'Parameters'];
        const rows = tableData.map(row => [
            row.id,
            `"${row.name}"`,
            `"${(row as any)?.logImportSource?.name || ''}"`,
            row.first_seen || '',
            row.last_seen || '',
            row.parameter?.map(param => `${param.parameter}: ${param.identifier}`).join('; ') || '',
        ]);

        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `media_table_export_${new Date().toISOString()}.csv`;
        link.click();
    };

    return (
        <>
            <DialogV2
                onClose={paramDialogState.setFalse}
                open={paramDialogState.value}
                title="Modules.Main.Campaigns.CampaignSchedule.mediaTable.editParams"
                maxWidth="xl"
            >
                {paramsGetError && <Alert severity="error">{String(paramsGetError)}</Alert>}
                <TabContext value={activeTab.toString()}>
                    <TabList onChange={handleTabChange}>
                        {tabLabels.map((tab, index) => (
                            <Tab key={tab.label} label={tab.label} value={index.toString()} />
                        ))}
                    </TabList>

                    <Box mt={2}>
                        {tabLabels.map((tab, index) => (
                            <Box
                                key={tab.label}
                                hidden={activeTab !== index.toString()}
                                display={activeTab === index.toString() ? 'block' : 'none'}
                            >
                                <Typography variant="body1" gutterBottom>
                                    {t(
                                        `Modules.Main.Campaigns.CampaignSchedule.popSettings.${tab.translation}`,
                                    )}
                                </Typography>
                                <TextAreaField
                                    multiline
                                    fullWidth
                                    rows={5}
                                    value={tabContent[tab.field]?.join('\n') || ''}
                                    onChange={e =>
                                        handleTabContentChange(tab.field, e.target.value)
                                    }
                                    variant="outlined"
                                />
                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    {!updateLoading ? (
                                        <Button variant="contained" onClick={handleApplyChanges}>
                                            {t(
                                                'Modules.Main.Campaigns.CampaignSchedule.mediaTable.applyChanges',
                                            )}
                                        </Button>
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </Box>

                                <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                                    {getParmasLoading || delLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <ParamsTable
                                            params={paramsData?.filter(
                                                param => param.parameter === tab.field,
                                            )}
                                            param={tab.field}
                                            loading={delLoading}
                                            onDeleteSelected={handleDeleteSelected}
                                            getParamsLoading={getParmasLoading}
                                            delError={delError}
                                        />
                                    )}
                                </Box>
                            </Box>
                        ))}
                        {delError && <Alert severity="error">{String(delError)}</Alert>}
                    </Box>
                </TabContext>
                {updateError && <Alert severity="error">{String(updateError)}</Alert>}
            </DialogV2>
            {groupRemoveError && <Alert severity="error">{String(groupRemoveError)}</Alert>}
            {groupError && <Alert severity="error">{String(groupError)}</Alert>}
            <Stack direction="row" gap={1}>
                <Box minWidth="15rem">
                    <MediaTableGroups
                        mediaGroups={mediaGroups}
                        activeGroup={activeGroup}
                        setActiveGroup={setActiveGroup}
                        handleGroupChange={handleGroupChange}
                        media_ids={Array.from(media_ids)}
                        tableData={tableData}
                        handleGroupRemove={handleGroupRemove}
                        setMedia_ids={setMedia_ids}
                    />
                </Box>
                <Box flex={1} minWidth={0} pr={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <TextField
                                variant="outlined"
                                placeholder={t(
                                    'Modules.Main.Campaigns.CampaignSchedule.mediaTable.search',
                                )}
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchQuery && (
                                        <InputAdornment position="end">
                                            {resetButton}
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '300px' }}
                            />
                        </Box>
                        <Stack direction="row" gap={1}>
                            <Stack>
                                <Button onClick={handleSync}>
                                    {t(
                                        'Modules.Main.Campaigns.CampaignSchedule.mediaTable.syncMedia',
                                    )}
                                </Button>
                                {syncError && <Alert severity="error">{String(syncError)}</Alert>}
                            </Stack>
                            <Button variant="contained" onClick={handleDialogOpen}>
                                {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.editParams')}
                            </Button>
                            <Button variant="contained" onClick={handleExportCSV}>
                                {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.exportCsv')}
                            </Button>

                            <PopSettingsCreativesSearch
                                campaignId={campaignId}
                                mediaTableData={tableData}
                                onApplyChanges={refreshData}
                            />
                        </Stack>
                    </Box>
                    <DataGridPro
                        columns={cols}
                        rows={filteredTableData}
                        loading={isLoading || syncLoading}
                        autoHeight
                        disableColumnResize={false}
                        disableRowSelectionOnClick
                        rowSelectionModel={Array.from(media_ids)}
                    />
                    {error && <Alert severity="error">{String(error)}</Alert>}
                </Box>
            </Stack>
        </>
    );
};

export default MediaTable;
