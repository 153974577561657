import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';

import {
    AllEntities,
    Displays_OperationalTime,
    Displays_Vendor,
    FrameSearchScreens,
    HttpProgressUpdate,
    ListSearchOptions,
    ListSearchResponse,
    ModuleConstructorProps,
} from '../Types';
import { generateArrayQSParams } from '../Lib';

type ListEndpointArgs = {
    endpoint: string;
    options: ListSearchOptions;
};

export function BaseEntityModuleFuncs<Entity>(
    { endpoints, httpClient, baseUrl }: ModuleConstructorProps,
    entityName: keyof AllEntities,
) {
    if (process.env.NODE_ENV === 'development') {
        if (!endpoints.Entities[entityName].list) {
            console.warn(`Entity '${entityName}' does not contain an endpoint for 'list'`);
        }
        if (!endpoints.Entities[entityName].get) {
            console.warn(`Entity '${entityName}' does not contain an endpoint for 'get'`);
        }
        if (!endpoints.Entities[entityName].create) {
            console.warn(`Entity '${entityName}' does not contain an endpoint for 'create'`);
        }
        if (!endpoints.Entities[entityName].update) {
            console.warn(`Entity '${entityName}' does not contain an endpoint for 'update'`);
        }
    }

    return {
        list: (options: ListSearchOptions = {}) =>
            ListEndpointFunc<ListSearchResponse<Entity>>(httpClient)({
                endpoint: endpoints.Entities[entityName].list,
                options,
            }),
        cursor: (options: ListSearchOptions = {}) =>
            ListEndpointFunc<ListSearchResponse<Entity>>(httpClient)({
                endpoint: endpoints.Entities[entityName].cursor,
                options,
            }),
        create: BaseCreateFunc<Entity>({ endpoints, httpClient, baseUrl }, entityName),
        get: (
            id: number,
            includes?: string[],
            showIncludes?: boolean,
            config?: AxiosRequestConfig,
        ) => {
            const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
            return httpClient.get<{ data: Entity }>(
                `${urlcat(endpoints.Entities[entityName].get, {
                    id,
                    showIncludes,
                })}${includeParams}`,
                config,
            );
        },
        update: (
            id: number,
            entity: Partial<Entity>,
            includes?: string[],
            config?: AxiosRequestConfig,
        ) => {
            const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
            return httpClient.put<{ data: Entity }>(
                `${urlcat(endpoints.Entities[entityName].update, { id })}${includeParams}`,
                entity,
                config,
            );
        },
        delete: (id: number, config?: AxiosRequestConfig) =>
            httpClient.delete(urlcat(endpoints.Entities[entityName].delete, { id }), null, config),
    };
}

export const BaseCreateFunc =
    <Entity>(
        { endpoints, httpClient, baseUrl }: ModuleConstructorProps,
        entityName: keyof AllEntities,
    ) =>
    (entity: Partial<Entity>, includes?: string[], config?: AxiosRequestConfig) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.post<{ data: Entity }>(
            `${endpoints.Entities[entityName].create}${includeParams}`,
            entity,
            config,
        );
    };

export const ListEndpointFunc =
    <ResponseType>(client: ModuleConstructorProps['httpClient']) =>
    ({ endpoint, options }: ListEndpointArgs, config?: AxiosRequestConfig) => {
        const {
            searchables = {},
            page = 1,
            perPage = 25,
            filters = {},
            includes = [],
            showFilters,
            showSearchables,
            filterFilters,
            showIncludes,
            orderBy,
            direction,
            cursor,
            smartFilters = false,
        } = options;

        const scopes = generateArrayQSParams(
            Array.isArray(filters?.scope) ? filters.scope : [] ?? [],
            'scope',
            '&',
        );
        return client.post<ResponseType>(
            `${urlcat(endpoint, {
                page,
                perPage,
                orderBy,
                direction,
                cursor,
                showFilters,
                showSearchables,
                showIncludes,
                filterFilters: smartFilters,

                ...searchables,
            })}${scopes}`,
            {
                ...searchables,
                ...filters,
                page,
                perPage,
                filterFilters,
                orderBy,
                direction,
                cursor,
                include: includes,
            },
            config,
        );
    };
export const lineItemSchedualableFunc = (
    props: ModuleConstructorProps,
    endpoint: string,
    id: number,
    perPage: number,
    includes: string[],
    config?: AxiosRequestConfig,
) =>
    props.httpClient.post<{ data: any }>(
        `${urlcat(endpoint, { id })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
        { perPage },
        config,
    );
export const operationalHoursFuncs = <Entity>(
    props: ModuleConstructorProps,
    createEndpoint: string,
    deleteEndpoint: string,
) => ({
    createOperationalHours: (
        id: number,
        operationalTimes: Partial<Displays_OperationalTime>[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.post<{ data: Entity }>(
            `${urlcat(createEndpoint, {
                id,
            })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            { operational_timings: operationalTimes },
            config,
        ),
    deleteOperationalHours: (
        id: number,
        operationalHourId: number,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.delete<{ data: Entity }>(
            `${urlcat(deleteEndpoint, {
                id,
                oid: operationalHourId,
            })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            null,
            config,
        ),
});
export const brochureFuncs = <Entity>(
    props: ModuleConstructorProps,
    createEndpoint: string,
    deleteEndpoint: string,
) => ({
    createBrochure: (
        id: number,
        formData: FormData,
        onProgress?: HttpProgressUpdate,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.postFile<{ data: Entity }>(
            `${urlcat(createEndpoint, {
                id,
            })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,

            formData,
            onProgress,
            {},
            config,
        ),
    deleteBrochure: (id: number, includes?: string[], config?: AxiosRequestConfig) =>
        props.httpClient.delete<{ data: Entity }>(
            `${urlcat(deleteEndpoint, {
                id,
            })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            null,
            config,
        ),
});
export const environmentFuncs = (props: ModuleConstructorProps, listEndpoint: string) => ({
    listEnvironments: (config?: AxiosRequestConfig) =>
        props.httpClient.get<{ data: string[] }>(`${listEndpoint}`, config),
});
export const attachVendorIdsFuncs = <Entity>(
    props: ModuleConstructorProps,
    createEndpoint: string,
    deleteEndpoint: string,
    listEndpoint: string,
) => ({
    attachVendors: (
        id: number,
        vendors: Partial<Displays_Vendor>[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.post<{ data: Entity }>(
            `${urlcat(createEndpoint, {
                id,
            })}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            { vendors },
            config,
        ),
    deleteVendors: (screenId: number, vendorId: number, config?: AxiosRequestConfig) =>
        props.httpClient.delete<{ data: Entity }>(
            `${urlcat(deleteEndpoint, { screenId, vendorId })}`,
            {},
            config,
        ),
    listVendorIDs: (config?: AxiosRequestConfig) => props.httpClient.post(`${listEndpoint}`),
});
export const uploadManualAudienceFunc = <Entity>(
    props: ModuleConstructorProps,
    createEndpoint: string,
) => ({
    uploadManualAudience: (
        formData: FormData,
        onProgress?: HttpProgressUpdate,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.postFile<{ data: Entity }>(
            `${urlcat(createEndpoint, {})}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            formData,
            onProgress,
            {},
            config,
        ),
});

export const frameSearchfunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    frameSearch: (
        data: { market_id: number; frames: { vendor_id: string }[] },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        props.httpClient.post<FrameSearchScreens>(
            `${urlcat(createEndpoint, {})}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            data,
            config,
        ),
});
export const exportCsvfunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    csvExport: (
        data: {
            'search.any'?: string;
            filter?: any;
            'search.vendor_id': string;
        },
        config?: AxiosRequestConfig,
        includes?: string[],
    ) =>
        props.httpClient.post(
            `${urlcat(createEndpoint, {})}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            data,
            config,
        ),
});
export const reImportFunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    reImport: (
        data: {
            'search.any'?: string;
            filter?: any;
            'search.vendor_id': string;
        },
        config?: AxiosRequestConfig,
        includes?: string[],
    ) =>
        props.httpClient.post(
            `${urlcat(createEndpoint, {})}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            data,
            config,
        ),
});
export const massUpdatingFunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    massUpdate: (formData: FormData, onProgress?: HttpProgressUpdate) =>
        props.httpClient.postFile(`${urlcat(createEndpoint, {})}`, formData, onProgress),
});
export const screenOrOwnerUpdateFunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    screenOrOwnerUpdate: (id: number) => props.httpClient.post(`${urlcat(createEndpoint, {})}`, id),
});
export const screenAllocationfunc = (props: ModuleConstructorProps, createEndpoint: string) => ({
    allocateAndDisAllocate: (
        data: {
            allocation_type: 'allocate' | 'deallocate';
            screen_to_allocate_type: 'selected' | 'filtered';
            filter?: any;
            display_ids?: number[];
            allocation_to_type: 'packs' | 'tags';
            allocation_to_type_id?: number;
            allow_override?: boolean;
        },
        config?: AxiosRequestConfig,
        includes?: string[],
    ) =>
        props.httpClient.post(
            `${urlcat(createEndpoint, {})}${generateArrayQSParams(includes ?? [], 'include', '?')}`,
            data,
            config,
        ),
});
