import React, { useCallback } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import TargetForm from 'c-main/Components/Schedule/Components/Targets/TargetForm';
import { Stack } from '@mui/material';
import { Alert } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';

const UpdateTarget = ({ targetId, id, initialData, updateBool }) => {
    const { setTargets } = useNewCampaignSchedule(id);

    const {
        start: startUpdateTarget,
        error,
        requestState,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.updateTargets);

    const updateTarget = useCallback(
        async data => {
            const [err, success] = await to(startUpdateTarget(id, targetId, data));
            if (!err && success && success?.data?.data) {
                setTargets(prevTargets =>
                    prevTargets.map(target =>
                        target.id === targetId ? success.data?.data : target,
                    ),
                );
                updateBool.setFalse();
            }
        },
        [startUpdateTarget, id, targetId, setTargets, updateBool],
    );

    return (
        <Stack>
            <TargetForm
                initialData={initialData}
                onSubmit={updateTarget}
                isUpdating
                isLoading={requestState === NetworkRequestState.InProgress}
            />
            {error && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
        </Stack>
    );
};

export default UpdateTarget;
