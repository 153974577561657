import React, { useCallback, useMemo } from 'react';
import { BaseEntity, PermissionName } from 'c-sdk';
import { useBoolean } from 'react-hanger';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { IconButton } from '@mui/material';
import { editIcon } from 'c-main/icons';
import { useEntityData } from 'c-data';
import { EntityDialogProps } from '../EntityDialog/EntityDialog';
import UpdateEntityDialog from './UpdateEntityDialog';

const UpdateEntityButton = <Entity extends BaseEntity>({
    show,
    onClose,
    buttonIcon = editIcon,
    permissions,
    defaultData,
    ...props
}: Omit<EntityDialogProps<Entity>, 'show'> & {
    entityId: number;
    buttonIcon?: React.ReactNode;
    show?: boolean;
    permissions?: PermissionName[];
}) => {
    const { hasAll } = useUserPermissions();
    const updateEntity = useBoolean(false);
    const { getById } = useEntityData(props.entityName);
    const entity = getById({ id: props.entityId });

    const actualDefaultData = useMemo<Partial<Entity>>(
        () => (defaultData ?? { ...entity }) as Partial<Entity>,
        [defaultData, entity],
    );

    const toggle = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            updateEntity.toggle();
        },
        [updateEntity],
    );

    const hasPerms = useMemo(() => hasAll(permissions), [hasAll, permissions]);

    if (!hasPerms) return null;

    return (
        <>
            <IfHasAllPermissions permissions={permissions}>
                <IconButton size="small" onClick={toggle} id={`new-${props.entityName as string}`}>
                    {buttonIcon}
                </IconButton>
            </IfHasAllPermissions>
            <UpdateEntityDialog<Entity>
                {...props}
                defaultData={actualDefaultData}
                onClose={onClose ?? updateEntity.setFalse}
                show={show ?? updateEntity.value}
            />
        </>
    );
};

export default UpdateEntityButton;
