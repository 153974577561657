import React, { useCallback, useEffect, useMemo } from 'react';
import { UseBoolean } from 'react-hanger';
import {
    Autocomplete,
    AutoGrid,
    Checkbox,
    ControlledFormInput,
    EntityAutocomplete,
    OptionSchema,
    TextField,
    UpdateEntityDialog,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { pick } from 'ramda';
import { useAPIClientRequest, useEntityField } from 'c-hooks';
import { apiClient, useEntityData } from 'c-data';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, Client, Buyer, Campaign } from 'c-sdk';
import { CampaignFormNames } from 'c-main/Types';
import to from 'await-to-js';
import { Box } from '@mui/material';

type Props = { campaign: Campaign; dialogState: UseBoolean };

const CampaignOverviewEdit: React.FC<Props> = ({ campaign, dialogState }) => {
    // const { text: AgencyName } = useEntityField<Agency>(campaign.agency_id, 'Agency', 'name');
    const { text: ClientName } = useEntityField<Client>(campaign.client_id, 'Client', 'name');
    const { text: BuyerName } = useEntityField<Buyer>(campaign.buyer_id, 'Buyer', 'name');
    const defaultData = useMemo(
        () => ({
            ...pick(
                [
                    'id',
                    /* 'agency_id', */ 'client_id',
                    'buyer_id',
                    'name',
                    'reference',
                    'is_vistar',
                ],
                campaign,
            ),
            [CampaignFormNames.additional_contacts]: campaign.additionalContacts.map(
                contact => contact.id,
            ),
        }),
        [campaign],
    );
    return (
        <UpdateEntityDialog<Campaign>
            entityName="Campaign"
            entityId={campaign.id}
            title="Modules.Main.Campaigns.Overview.EditDialog.title"
            defaultData={defaultData}
            includes={['additionalContacts']}
            show={dialogState.value}
            form={
                <EditCampaignForm
                    campaignId={campaign.id}
                    // agencyName={AgencyName}
                    buyerId={campaign.buyer_id}
                    clientName={ClientName}
                    buyerName={BuyerName}
                />
            }
            onClose={dialogState.setFalse}
        />
    );
};
// const agencyLabelGen = (entity, t: AppTranslationFunction) =>
//     t('Modules.Main.Campaigns.Overview.EditDialog.agencyDropdownLabel', entity);

// const alterSearchTerm = (term: string) => {
//     if (term == null) {
//         return term;
//     }
//
//     // we currently include the campaign count next to the agency name, e.g. `Sky (200 campaigns)`
//     // want to remove that additional campaign count part from the actual http search
//
//     const insideBrackets = term.match(/\(([^)]+)\)/)?.[1] ?? '';
//     return term.replace(insideBrackets, '').replace('(', '').replace(')', '').trim();
// };

const EditCampaignForm = ({
    campaignId,
    // agencyName,
    clientName,
    buyerName,
    buyerId,
}: {
    campaignId: number;
    buyerId: number;
    // agencyName: string;
    clientName: string;
    buyerName: string;
}) => {
    const t = useCommonTranslation();
    const { start, data } = useAPIClientRequest(apiClient.Entities.Buyer.get);
    const loadSelectedBuyer = useCallback(
        async (buyerId: number) => {
            await to(start(buyerId, ['users']));
        },
        [start],
    );
    useEffect(() => {
        loadSelectedBuyer(buyerId);
    }, [buyerId, loadSelectedBuyer]);
    const buyerUsers = useMemo(
        () => (buyerId == null ? [] : data?.data?.data?.users ?? []),
        [buyerId, data?.data?.data?.users],
    );
    const buyerUserOptions = useMemo<OptionSchema[]>(
        () => buyerUsers.map(user => ({ label: user.name, value: user.id })),
        [buyerUsers],
    );
    return (
        <AutoGrid spacing={2} xs={12}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Campaigns.Overview.EditDialog.campaignNameLabel')}
                    />
                )}
                name="name"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Campaigns.Overview.EditDialog.referenceLabel')}
                    />
                )}
                name="reference"
            />
            <IfHasAllPermissions permissions={[PermissionName.UniledportalBuyerRead]}>
                <ControlledFormInput
                    render={({ field }) => (
                        <EntityAutocomplete
                            {...field}
                            multiple={false}
                            entityName="Buyer"
                            textFieldProps={{
                                label: t(
                                    'Modules.Main.Campaigns.Overview.OverviewSection.buyerHeader',
                                ),
                            }}
                            searchColumns="name"
                            labelColumn="name"
                            autoLoadSearchTerm={buyerName}
                        />
                    )}
                    name="buyer_id"
                />
            </IfHasAllPermissions>
            <IfHasAllPermissions
                permissions={[
                    PermissionName.UniledportalUserRead,
                    PermissionName.UniledportalCampaign_userCreate,
                    PermissionName.UniledportalCampaign_userRead,
                    PermissionName.UniledportalCampaign_userUpdate,
                ]}
            >
                <ControlledFormInput
                    name={CampaignFormNames.additional_contacts}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            id="campaign-edit-additional-contacts"
                            multiple
                            options={buyerUserOptions}
                            textFieldProps={{
                                label: t(
                                    'Modules.Main.Campaigns.Create.additionalContactsDropdownLabel',
                                ),
                            }}
                        />
                    )}
                />
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={[PermissionName.UniledportalClientRead]}>
                <ControlledFormInput
                    render={({ field }) => (
                        <EntityAutocomplete
                            {...field}
                            multiple={false}
                            entityName="Client"
                            textFieldProps={{
                                label: t(
                                    'Modules.Main.Campaigns.Overview.OverviewSection.brandHeader',
                                ),
                            }}
                            searchColumns="name"
                            labelColumn="name"
                            autoLoadSearchTerm={clientName}
                        />
                    )}
                    name="client_id"
                />
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                <ControlledFormInput
                    name="is_vistar"
                    render={({ field }) => (
                        <Box pl={1}>
                            <Checkbox
                                {...field}
                                checked={field.value}
                                label={t(
                                    'Modules.Main.Campaigns.Overview.OverviewSection.isVistar',
                                )}
                            />
                        </Box>
                    )}
                />
            </IfHasAllPermissions>
            {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalAgencyRead]}> */}
            {/*    <ControlledFormInput */}
            {/*        render={({ field }) => (* /}
            {/*            <EntityAutocomplete */}
            {/*                {...field} */}
            {/*                multiple={false} */}
            {/*                entityName="Agency" */}
            {/*                textFieldProps={{ */}
            {/*                    label: t( */}
            {/*                        'Modules.Main.Campaigns.Overview.OverviewSection.agencyHeader', */}
            {/*                    ), */}
            {/*                }} */}
            {/*                searchColumns="name" */}
            {/*                labelColumn="name" */}
            {/*                includes={['campaignsCount']} */}
            {/*                orderByField="campaignsCount" */}
            {/*                orderByDirection="desc" */}
            {/*                labelGen={agencyLabelGen} */}
            {/*                autoLoadSearchTerm={agencyName} */}
            {/*                alterSearchTerm={alterSearchTerm} */}
            {/*            /> */}
            {/*        )} */}
            {/*        name="agency_id" */}
            {/*    /> */}
            {/* </IfHasAllPermissions> */}
        </AutoGrid>
    );
};

const CampaignOverviewEditWrapper: React.FC<{ id: number; dialogState: UseBoolean }> = ({
    id,
    dialogState,
}) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });

    return campaign && <CampaignOverviewEdit campaign={campaign} dialogState={dialogState} />;
};

export default CampaignOverviewEditWrapper;
