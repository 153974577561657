import { BaseSliceInitialState } from '../../Types/slices';
import { NetworkRequestState } from '../../Types/network';

const baseInitialState: BaseSliceInitialState<any> = {
    isEntitySlice: true,
    updatingById: {},
    deletingById: {},
    loadingById: {},
    create: { state: NetworkRequestState.Idle },

    pagination: {},

    entities: {},
    ids: [],
};

export default baseInitialState;
