import { callApi, errorMessageFromResponse, validationErrorsFromResponse } from 'c-data-layer';
import apiClient from 'c-data/apiClient';
import { CommonThunk } from 'c-types';
import { passwordResetActions } from './password-reset-slice';

export const resetRequestResetEmail = (): CommonThunk => async dispatch => {
    dispatch(passwordResetActions.resetRequestEmail());
};
export const resetRequestResetPassword = (): CommonThunk => async dispatch => {
    dispatch(passwordResetActions.resetResetPassword());
};

export const requestResetEmail =
    ({ email }: { email: string }): CommonThunk =>
    async dispatch => {
        dispatch(passwordResetActions.startResetRequestEmail());

        const [error] = await dispatch(
            callApi(apiClient.Auth.requestPasswordResetEmail({ email }).request),
        );

        if (!error) {
            dispatch(passwordResetActions.finishedResetRequestEmail());
        } else {
            dispatch(passwordResetActions.failedResetRequestEmail(errorMessageFromResponse(error)));
        }
    };

export const requestPasswordReset =
    (resetProps: { email: string; password_reset_token: string; password: string }): CommonThunk =>
    async dispatch => {
        dispatch(passwordResetActions.startResetPassword());

        const [error] = await dispatch(callApi(apiClient.Auth.resetPassword(resetProps).request));

        if (!error) {
            dispatch(passwordResetActions.finishedResetPassword());
        } else {
            dispatch(
                passwordResetActions.failedResetPassword({
                    error: errorMessageFromResponse(error),
                    validation: validationErrorsFromResponse(error),
                }),
            );
        }
    };
