import React, { useEffect } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { TransparentCard } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { systemSupportIcon } from 'c-main/icons';
import { PermissionName } from 'c-sdk';
import { CardContent } from '@mui/material';
import ReactGA from 'react-ga';
import ImpersonateUserForm from './ImpersonateUserForm';

const ImpersonateUserPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard title={useCommonTranslation('Modules.Admin.ImpersonateUser.metaTitle')}>
            <CardContent>
                <ImpersonateUserForm />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'ImpersonateUserPage',
    component: ImpersonateUserPage,
    systemSearch: {
        title: 'Modules.Admin.ImpersonateUser.metaTitle',
        description: 'Modules.Admin.ImpersonateUser.description',
        type: 'page',
        route: PostAuthRoutes.Admin.ImpersonateUser,
        saveAsRecentPage: true,
        icon: systemSupportIcon,
        aliases: 'SystemSearch.aliases.impersonatePage',
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.ImpersonateUser.metaTitle' },
        ],
        permissions: [
            PermissionName.AdminCan_impersonate_user,
            PermissionName.UniledportalUserRead,
        ],
    },
};

export default setup;
