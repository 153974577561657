import { Displays_Screen as ScreenModel, ModuleConstructorProps } from '../Types';
import {
    attachVendorIdsFuncs,
    BaseEntityModuleFuncs,
    brochureFuncs,
    environmentFuncs,
    exportCsvfunc,
    frameSearchfunc,
    massUpdatingFunc,
    operationalHoursFuncs,
    reImportFunc,
    screenAllocationfunc,
    screenOrOwnerUpdateFunc,
} from './BaseEntityModuleFuncs';

const Displays_Screen = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<ScreenModel>({ endpoints, httpClient, baseUrl }, 'Displays_Screen'),
    ...operationalHoursFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.createOperationalTimes,
        endpoints.Entities.Displays_Screen.destroyOperationalTime,
    ),
    ...attachVendorIdsFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.attachVendorIds,
        endpoints.Entities.Displays_Screen.deleteVendorIds,
        endpoints.Entities.Displays_Screen.listVendorIds,
    ),
    ...brochureFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.brochure,
        endpoints.Entities.Displays_Screen.brochure,
    ),
    ...frameSearchfunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.frameSearch,
    ),
    ...screenAllocationfunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.screenAllocation,
    ),
    ...exportCsvfunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.exportCsv,
    ),
    ...reImportFunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.reImport,
    ),
    ...massUpdatingFunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.massUpdating,
    ),
    ...screenOrOwnerUpdateFunc(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.getScreens,
    ),
    ...environmentFuncs(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Screen.listEnvironments,
    ),
});

export default Displays_Screen;
