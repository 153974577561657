import React, { PropsWithChildren, useMemo } from 'react';
import { UserType } from 'c-sdk';
import { useAuthenticatedUser } from 'c-hooks';
import ConditionalDebugWrapper from 'c-auth-module/Components/ConditionalDebugWrapper';
import { ConditionalDebugWrapperProps } from '../ConditionalDebugWrapper/ConditionalDebugWrapper';

type Props = PropsWithChildren<
    {
        roles: UserType[];
        forceShow?: boolean;
    } & Omit<ConditionalDebugWrapperProps, 'show' | 'tooltipLines'>
>;

const IfUserIsOneOfTypes: React.FC<Props> = ({ roles, forceShow, ...rest }) => {
    const { chosenUserType } = useAuthenticatedUser();
    const show = useMemo(
        () =>
            forceShow ||
            roles == null ||
            roles?.length === 0 ||
            (roles ?? []).indexOf(chosenUserType) !== -1,
        [chosenUserType, roles, forceShow],
    );
    const lines = useMemo(() => (roles ?? []).map(r => `user type: ${r}`), [roles]);

    const hideDebugWrapper = useMemo(() => roles == null || roles?.length <= 0, [roles]);

    if (hideDebugWrapper) return <>{rest.children}</>;

    return <ConditionalDebugWrapper show={show} tooltipLines={lines} {...rest} />;
};

export default IfUserIsOneOfTypes;
