import React, { useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { countriesListIcon } from 'c-main/icons';
import { PermissionName } from 'c-sdk';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import { displayCountryColumns } from 'c-displays/Components/DisplaysEntityColumns';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    CreateEntityButton,
    SelectField,
    TextField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactGA from 'react-ga';

const textSearchCols = ['name'];
const paginationTag = 'displays-admin-country-list';

const CountryListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const schema = yup.object().shape({
        name: yup.string().required('Name is required'),
        alpha_2: yup
            .string()
            .required('Alpha-2 code is required')
            .matches(/^[A-Za-z]{2}$/, 'Alpha-2 code must be 2 letters'),
        alpha_3: yup
            .string()
            .required('Alpha-3 code is required')
            .matches(/^[A-Za-z]{3}$/, 'Alpha-3 code must be 3 letters'),
        code_num: yup
            .string()
            .required('Numeric code is required')
            .matches(/^[0-9]{3}$/, 'Numeric code must be 3 digits'),
        currency_id: yup.number().required('Currency ID is required'),
    });
    return (
        <FullPageEntityTableWrapper
            title="Modules.Displays.CountriesList.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton
                        entityName="Displays_Country"
                        title="Modules.Displays.CountriesList.createBtn"
                        form={<NewCountryForm />}
                        buttonLabel={t('Modules.Displays.CountriesList.createBtn')}
                        submitLabel="Modules.Displays.CountriesList.submitBtn"
                        key="new-country-button"
                        permissions={[PermissionName.DisplaysPacksCreate]}
                        redirectPath={PostAuthRoutes.Displays.DisplaysCountryEdit}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                    />,
                ],
                [schema, t],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={displayCountryColumns}
                baseEntityName="Displays_Country"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                routeTemplate={PostAuthRoutes.Displays.DisplaysCountryEdit}
                direction="desc"
                showFilters
                includes={['currency']}
            />
        </FullPageEntityTableWrapper>
    );
};
const NewCountryForm = () => {
    const t = useCommonTranslation();
    return (
        <AutoGrid xs={6} spacing={2} pb={2}>
            <ControlledFormInput
                name="name"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.name')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="alpha_2"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.alpha2')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="alpha_3"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.alpha3')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="code_num"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.numericCode')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="currency_id"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Currency"
                        useLocalEntityData
                        labelField="name"
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.currency')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="status"
                render={({ field }) => (
                    <SelectField
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.status')}
                        {...field}
                        value={field.value}
                        fullWidth
                        options={[
                            {
                                value: 0,
                                label: t('Modules.Displays.DisplaysCountryEdit.fields.inactive'),
                            },
                            {
                                value: 1,
                                label: t('Modules.Displays.DisplaysCountryEdit.fields.active'),
                            },
                        ]}
                    />
                )}
            />
            <ControlledFormInput
                name="owners"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Owner"
                        useLocalEntityData
                        multi
                        labelField="name"
                        label={t('Modules.Displays.DisplaysCountryEdit.fields.owners')}
                        {...field}
                    />
                )}
            />
        </AutoGrid>
    );
};

const setup: PageConfig = {
    id: 'CountryListPage',
    component: CountryListPage,
    systemSearch: {
        title: 'Modules.Displays.CountriesList.metaTitle',
        description: 'Modules.Displays.CountriesList.metaTitle',
        icon: countriesListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.displaysCountriesList',
        route: PostAuthRoutes.Displays.CountryList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.CountriesList.metaTitle',
            },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
