import React, { useCallback, useMemo } from 'react';
import { useColorCorrection } from 'c-hooks';
import { useCommonTranslation } from 'c-translation';
import { OptionSchema, SelectField } from 'c-components';
import { UserColorCorrection } from 'c-sdk';
import { Box } from '@mui/material';

type Props = {
    //
};

const noneValue = 'none';

const ColorCorrectionSwitcher: React.FC<Props> = () => {
    const { color_correction, switchColorCorrection, isSwitching } = useColorCorrection();
    const t = useCommonTranslation();
    const options = useMemo<OptionSchema[]>(
        () => [
            {
                label: t('Modules.Admin.ColorCorrectionSwitcher.none'),
                value: noneValue,
            },
            {
                label: t('Modules.Admin.ColorCorrectionSwitcher.protanope'),
                value: UserColorCorrection.Protanope,
            },
            {
                label: t('Modules.Admin.ColorCorrectionSwitcher.deuteranope'),
                value: UserColorCorrection.Deuteranope,
            },
            {
                label: t('Modules.Admin.ColorCorrectionSwitcher.tritanope'),
                value: UserColorCorrection.Tritanope,
            },
        ],
        [t],
    );

    const onChange = useCallback(
        e => {
            const value = e.target.value;
            switchColorCorrection(value === noneValue ? null : value);
        },
        [switchColorCorrection],
    );

    return (
        <Box py={2}>
            <SelectField
                disabled={isSwitching}
                label={t('Modules.Admin.ColorCorrectionSwitcher.label')}
                options={options}
                value={color_correction ?? noneValue}
                onChange={onChange}
            />
        </Box>
    );
};

export default ColorCorrectionSwitcher;
