import React, { Ref, useContext, useMemo } from 'react';
import { TableCellProps, TableRow as MuiTableRow, TableRowProps } from '@mui/material';
import cx from 'classnames';
import merge from 'deepmerge';
import { makeStyles } from '@mui/styles';
// eslint-disable-next-line import/no-cycle
import { TableContext, TableCell } from 'c-components/Tables';

type Props = TableRowProps & {
    clickable?: boolean;
    isHeader?: boolean;
    contentBg?: boolean;
    collapsed?: boolean;
    disabledRowDividers?: boolean;
    dense?: boolean;
    disablePaddingSideColumns?: boolean;
};

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: 'pointer',
    },
}));

const emptyTableCellSx: TableCellProps['sx'] = {
    border: 'none',
    p: 1.5,
    width: 0,
    transition: 'all 400ms',
};
const emptyTableCellCollapseSx: TableCellProps['sx'] = { ...emptyTableCellSx, py: 0 };

const TableRow = (
    {
        children,
        className,
        clickable = false,
        isHeader = false,
        contentBg = false,
        collapsed = false,
        disabledRowDividers = false,
        dense = false,
        disablePaddingSideColumns = false,
        sx,
        ...rest
    }: Props,
    ref: Ref<any>,
) => {
    const { spacedOutRows } = useContext(TableContext);

    const actuallyDisabledRowDividers = disabledRowDividers || spacedOutRows;
    const actuallyDisablePaddingColumns = disablePaddingSideColumns || spacedOutRows;

    const actualRowSx = useMemo<TableRowProps['sx']>(
        () =>
            spacedOutRows && !isHeader
                ? (merge(
                      {
                          boxShadow: 4,
                          borderRadius: 1,
                      },
                      sx ?? ({} as any),
                  ) as TableRowProps['sx'])
                : sx,
        [sx, spacedOutRows, isHeader],
    );

    const { clickable: clickableClass } = useStyles();
    const classNameMemo = useMemo(
        () =>
            cx(className, {
                [clickableClass]: clickable,
            }),
        [className, clickable, clickableClass],
    );

    return (
        <MuiTableRow className={classNameMemo} sx={actualRowSx} ref={ref} {...rest}>
            {!actuallyDisablePaddingColumns && (
                <TableCell
                    sx={collapsed ? emptyTableCellCollapseSx : emptyTableCellSx}
                    contentBg={contentBg}
                    collapsed={collapsed}
                    disabledRowDividers={actuallyDisabledRowDividers}
                    dense={dense}
                />
            )}
            {children}

            {!actuallyDisablePaddingColumns && (
                <TableCell
                    sx={collapsed ? emptyTableCellCollapseSx : emptyTableCellSx}
                    contentBg={contentBg}
                    collapsed={collapsed}
                    disabledRowDividers={actuallyDisabledRowDividers}
                    dense={dense}
                />
            )}
        </MuiTableRow>
    );
};

export default React.forwardRef(TableRow);
