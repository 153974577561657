import React, { useEffect } from 'react';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { Skin } from 'c-entity-types';
import { useEntityData } from 'c-data';
import { TransparentCard } from 'c-components';
import { CardContent } from '@mui/material';
import { PermissionName } from 'c-sdk';
import { skinIcon } from 'c-main/icons';
import { useEntityPage } from 'c-hooks';
import ReactGA from 'react-ga';
import SkinEditWrapper from './Components/SkinEditWrapper';

const EditSkinPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<Skin>('Skin');
    const skin = getById({ id });
    return (
        <TransparentCard
            title={useCommonTranslation('Modules.Admin.SkinEdit.pageTitle', {
                name: `'${skin?.name}'`,
            })}
        >
            <CardContent>
                <SkinEditWrapper skinId={id} />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<Skin> = {
    id: 'EditSkinPage',
    component: EditSkinPage,
    systemSearch: {
        title: 'Modules.Admin.SkinEdit.metaTitle',
        description: 'Modules.Admin.SkinEdit.description',
        type: 'entity',
        // aliases: [],
        icon: skinIcon,
        route: PostAuthRoutes.Admin.EditSkin,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.SkinsList.metaTitle', link: PostAuthRoutes.Admin.SkinList },
        ],
        defaultIncludes: ['agencies', 'buyers', 'clients', 'owners', 'users'],
        entityName: 'Skin',
        nameGen: (entity, id) => entity?.name ?? String(id),
        permissions: [PermissionName.UniledportalSkinRead, PermissionName.UniledportalSkinUpdate],
        prefix: 'SystemSearch.prefixes.skin',
        searchColumn: ['name'],
        prefixDescription: 'SystemSearch.prefixDescriptions.skin',
    },
};

export default setup;
