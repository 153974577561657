import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FilePreviewDialog from 'c-components/FileBrowser/FilePreviewDialog';
import { AutoGrid, Button, FileBrowserFile, FlashMessage, TextField } from 'c-components';
import { CampaignErrorableType, Creative } from 'c-sdk';
import { useCampaignErrors } from 'c-main/Hooks';
import { UpdateCreatives } from 'c-main/Components/CreativeManagement/types';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TextFieldProps,
} from '@mui/material';
import { editIcon } from 'c-main/icons';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from 'c-data-layer';

type Props = {
    campaignId: number;
    file: FileBrowserFile;
    onClose: () => void;
    updateCreatives: UpdateCreatives;
};

const CreativeFilePreviewDialog: React.FC<Props> = ({
    campaignId,
    file,
    onClose,
    updateCreatives,
}) => {
    const t = useCommonTranslation();
    const { getErrors } = useCampaignErrors(campaignId);
    const errors = useMemo(
        () =>
            file?.downloadId
                ? getErrors({
                      entityIds: [file?.downloadId],
                      entityTypes: [CampaignErrorableType.Creative],
                  })
                : null,
        [getErrors, file?.downloadId],
    );

    const { setTrue: OpenEdit, setFalse: CloseEdit, value: IsEditing } = useBoolean(false);
    const [newName, setNewName] = useState('');
    const onNameChange = useCallback(e => {
        setNewName(e.target.value);
    }, []);

    const { start, isLoading, requestState, error, reset } = useAPIClientRequest(
        apiClient.Entities.Creative.update,
    );
    const onSave = useCallback(async () => {
        if (newName === file?.name) return;
        const [, success] = await to(
            start([{ id: file?.downloadId, name: newName }] as Creative[], [
                'file',
                'creativeGroupIds',
                'lineItemsCount',
            ]),
        );

        if (Array.isArray(success?.data?.data)) {
            updateCreatives(success?.data?.data);
            reset();
            CloseEdit();
        }
    }, [start, updateCreatives, newName, file?.downloadId, reset, file?.name, CloseEdit]);

    const onRenameKeyUp = useCallback<TextFieldProps['onKeyDown']>(
        e => {
            if (e.key === 'Enter') onSave();
        },
        [onSave],
    );
    const onBlur = useCallback<TextFieldProps['onBlur']>(() => {
        if (!isLoading) CloseEdit();
    }, [isLoading, CloseEdit]);

    const title = useMemo(() => {
        if (file && !IsEditing)
            return (
                <AutoGrid gap={0}>
                    {file.name}
                    <IconButton size="small" onClick={OpenEdit}>
                        {editIcon}
                    </IconButton>
                </AutoGrid>
            );

        if (file && IsEditing)
            return (
                <Stack gap={1}>
                    <TextField
                        value={newName}
                        onChange={onNameChange}
                        variant="standard"
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.controls.rename.previewDialogNameLabel',
                        )}
                        onKeyUp={onRenameKeyUp}
                        autoFocus
                        disabled={isLoading}
                        onBlur={onBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        size="small"
                                        variant="text"
                                        onClick={onSave}
                                        disabled={isLoading || newName === file.name}
                                    >
                                        {!isLoading ? (
                                            t(
                                                'Modules.Main.CreativeManagement.creatives.controls.rename.previewDialogSave',
                                            )
                                        ) : (
                                            <CircularProgress size={15} />
                                        )}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {requestState === NetworkRequestState.Error && (
                        <Box mt={2}>
                            <FlashMessage status="error">{String(error)}</FlashMessage>
                        </Box>
                    )}
                </Stack>
            );

        return null;
    }, [
        IsEditing,
        OpenEdit,
        error,
        file,
        isLoading,
        newName,
        onNameChange,
        onRenameKeyUp,
        onBlur,
        onSave,

        requestState,
        t,
    ]);

    useEffect(() => {
        setNewName(file?.name ?? '');
        CloseEdit();
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    useEffect(() => {
        setNewName(file?.name ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IsEditing]);

    return (
        <FilePreviewDialog
            title={title}
            file={file}
            open={file != null}
            close={onClose}
            errors={errors}
        />
    );
};

export default CreativeFilePreviewDialog;
