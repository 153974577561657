import React, { useCallback } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from 'c-data-layer';
import to from 'await-to-js';
import { Alert, Button, DialogV2 } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Box, Stack } from '@mui/material';
import { scheduleName } from 'c-main/Components/Schedule/lib';
import { ScheduleGet } from 'c-sdk';

type Props = {
    onClose: () => void;
    scheduleId: number;
    schedule: ScheduleGet;
    campaignId?: number;
    removeSchedules: (scheduleIds: number[]) => void;
};

const DeleteScheduleDialog: React.FC<Props> = ({
    onClose,
    campaignId,
    scheduleId,
    schedule,
    removeSchedules,
}) => {
    const { start, isLoading, error, requestStateRef } = useAPIClientRequest(
        apiClient.Entities.Campaign.deleteSchedules,
    );

    const deleteSchedules = useCallback(async () => {
        if (requestStateRef.current === NetworkRequestState.InProgress) return;

        const [err] = await to(start(campaignId, scheduleId));

        if (!err) {
            removeSchedules([scheduleId]);
            onClose();
        }
    }, [requestStateRef, start, campaignId, scheduleId, removeSchedules, onClose]);

    const t = useCommonTranslation();

    return (
        <DialogV2
            onClose={onClose}
            open={scheduleId != null}
            titleVariant="h6"
            title={t('Modules.Main.Campaigns.CampaignSchedule.Schedules.deleteDialog.title', {
                name: scheduleName(schedule, t),
            })}
            description={t(
                'Modules.Main.Campaigns.CampaignSchedule.Schedules.deleteDialog.description',
            )}
            actions={
                <Stack gap={2}>
                    {error != null && (
                        <Box my={2}>
                            <Alert severity="error" variant="outlined">
                                {String(error)}
                            </Alert>
                        </Box>
                    )}
                    <Button disabled={isLoading} onClick={deleteSchedules} color="error">
                        {t(
                            'Modules.Main.Campaigns.CampaignSchedule.Schedules.deleteDialog.deleteBtn',
                        )}
                    </Button>
                </Stack>
            }
        />
    );
};

export default DeleteScheduleDialog;
