import { useCallback } from 'react';
import { useEntityData } from 'c-data';
import { SpotlightRecentResource } from 'c-sdk';
import config from 'c-config';

function useGetRecentPageConfig() {
    const { getById } = useEntityData<SpotlightRecentResource>('SpotlightRecentResource');
    const getConfig = useCallback(
        id => {
            const data = getById({ id });

            const identifier =
                data?.type === 'entity'
                    ? data.resourceIdentifier?.split('-')[0]
                    : data.resourceIdentifier;
            return { page: config.pages.filter(p => p.id === identifier)[0], data };
        },
        [getById],
    );

    return { getConfig };
}

export default useGetRecentPageConfig;
