import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Box, TableSortLabel } from '@mui/material';
import { EsPCAReportField, PCACampaignFramesRequestPayload, PCAReportCountField } from 'c-sdk';
import { ReportProps, ReportType, TableReportHeader, TableReportRow } from 'c-reports/Types';
import { ReportWrapper } from 'c-reports/Components';
import { useCommonTranslation } from 'c-translation';
import { Direction } from 'c-types';
import { useDateUtils } from 'c-hooks';
import { ByFieldChartProps } from 'c-main/Components/Campaign/CampaignReports/types';
import LeaderboardFramesDialogES from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/LeaderboardFramesDialogES';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import { ByFieldOverall } from '../byFieldDataMutators';
import { LeaderboardRows } from './ChartUtils';
import { sortByName } from './sorting';

type Props = ByFieldChartProps & {
    filters: PCACampaignFramesRequestPayload['fieldFilters'];
    refineFilters?: boolean;
};

const OverallLeaderboardCharts: React.FC<Props> = ({
    pcaData,
    fieldLabel,
    expectedLabel,
    percentageLabel,
    performanceLabel,
    actualLabel,
    missingLabel,
    inScheduleLabel,
    outScheduleLabel,
    unbookedLabel,
    renderTooltipLabel,
    reportWrapperProps,
    allFields,
    countFields,
    metric,
    field,
    campaignId,
    minDate,
    maxDate,
    searchTerm,
    filters,
    currentStartDate,
    currentTimeframe,
    currentEndDate,
    environment,
    refineFilters = false,
}) => {
    const data = useMemo(
        () =>
            ByFieldOverall(
                pcaData,
                allFields,
                actualLabel,
                percentageLabel,
                expectedLabel,
                missingLabel,
                fieldLabel,
                inScheduleLabel,
                outScheduleLabel,
                unbookedLabel,
            )
                .map(data => ({ ...data, [performanceLabel]: Number(data[percentageLabel]) }))
                .filter(item => item[fieldLabel] !== ''),
        [
            pcaData,
            allFields,
            actualLabel,
            percentageLabel,
            expectedLabel,
            missingLabel,
            fieldLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            performanceLabel,
        ],
    );

    const showPerformance = useMemo(
        () =>
            countFields.indexOf(PCAReportCountField.InSchedule) !== -1 &&
            field !== EsPCAReportField.CreativeName,
        [countFields, field],
    );

    const showInSchedule = useMemo(
        () => countFields.indexOf(PCAReportCountField.InSchedule) !== -1,
        [countFields],
    );

    const showOutSchedule = useMemo(
        () => countFields.indexOf(PCAReportCountField.OutSchedule) !== -1,
        [countFields],
    );

    const showUnbooked = useMemo(
        () => countFields.indexOf(PCAReportCountField.UnBooked) !== -1,
        [countFields],
    );

    const [orderByCol, setOrderByCol] = useState(() => {
        if (!showPerformance) return actualLabel;

        return performanceLabel;
    });
    const [orderByDirection, setOrderByDirection] = useState(Direction.DESC);
    const [openField, setOpenField] = useState(null);

    const closeDialog = useCallback(() => {
        setOpenField(null);
    }, []);

    const orderByClicked = useCallback(
        (field: string) => {
            let newDirection;

            let flip = false;
            // if same field, flip the ordering direction
            if (orderByCol === field) {
                flip = true;
            }

            if (flip) {
                newDirection = orderByDirection === Direction.ASC ? Direction.DESC : Direction.ASC;
            } else {
                newDirection = Direction.ASC;
            }

            // if switching fields order descending
            if (orderByCol !== field) {
                newDirection = Direction.DESC;
            }

            setOrderByCol(field);
            setOrderByDirection(newDirection);
        },
        [orderByCol, orderByDirection],
    );

    const headerLabels = useMemo(() => {
        const allFields = [fieldLabel, performanceLabel, expectedLabel];

        if (showInSchedule) allFields.push(inScheduleLabel);
        if (showOutSchedule) allFields.push(outScheduleLabel);
        if (showUnbooked) allFields.push(unbookedLabel);

        allFields.push(actualLabel);

        if (!showPerformance)
            return allFields.filter(f => [performanceLabel, expectedLabel].indexOf(f) === -1);

        return allFields;
    }, [
        fieldLabel,
        performanceLabel,
        actualLabel,
        expectedLabel,
        inScheduleLabel,
        outScheduleLabel,
        unbookedLabel,
        showInSchedule,
        showOutSchedule,
        showUnbooked,
        showPerformance,
    ]);

    const headers = useMemo<TableReportHeader[]>(
        () => [
            { content: '#', width: 0 },
            ...headerLabels.map(field => ({
                content: (
                    <TableHeader
                        key={field}
                        onClick={orderByClicked}
                        active={orderByCol === field}
                        field={field}
                        direction={orderByDirection}
                    >
                        {field}
                    </TableHeader>
                ),
            })),
        ],
        [headerLabels, orderByClicked, orderByCol, orderByDirection],
    );

    const filterParts = useMemo(
        () =>
            searchTerm
                .toLowerCase()
                .split('|')
                .filter(p => p.trim().length > 0),
        [searchTerm],
    );

    const filterData = useCallback(
        (data: any[]) => {
            if (searchTerm == null || searchTerm.trim().length === 0) return data;
            return data.filter(dataRow => {
                const fieldLower = dataRow[fieldLabel]?.toLowerCase() ?? '';
                return filterParts.filter(part => fieldLower.indexOf(part) !== -1).length > 0;
            });
        },
        [fieldLabel, searchTerm, filterParts],
    );

    const { dayMonthYearFormat } = useDateUtils();
    const filteredSortedData = useMemo(
        () =>
            sortByName(
                filterData(data),
                orderByDirection,
                orderByCol,
                fieldLabel === orderByCol ? field : null,
                dayMonthYearFormat,
            ),
        [data, field, filterData, orderByCol, orderByDirection, fieldLabel, dayMonthYearFormat],
    );

    const rows = useMemo<TableReportRow[]>(
        () =>
            LeaderboardRows(filteredSortedData, {
                campaignId,
                fieldLabel,
                filterParts,
                metric,
                field,
                actualLabel,
                expectedLabel,
                inScheduleLabel,
                outScheduleLabel,
                unbookedLabel,
                showPerformance,
                percentageLabel,
                showInSchedule,
                showOutSchedule,
                showUnbooked,
                onOpenField: setOpenField,
            }),
        [
            filteredSortedData,
            campaignId,
            fieldLabel,
            filterParts,
            metric,
            field,
            actualLabel,
            expectedLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
            showPerformance,
            percentageLabel,
            showInSchedule,
            showOutSchedule,
            showUnbooked,
        ],
    );

    const reportProps = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Table,
                namePrefix: `${campaignId}_${metric}_${field}_leaderboard`,
                data: filteredSortedData,
                renderTooltipLabel,
                rows,
                headers,
                disableVirtualization: true,
            },
            // } as TableOrChartProps<any>,
        ],
        [campaignId, field, filteredSortedData, headers, metric, renderTooltipLabel, rows],
    );

    const beforeChartComponent = useMemo(
        () => (
            <>
                {reportWrapperProps?.beforeChartComponent && (
                    <Box>{reportWrapperProps.beforeChartComponent}</Box>
                )}
            </>
        ),
        [reportWrapperProps.beforeChartComponent],
    );

    const t = useCommonTranslation();
    const { source } = useCampaignWrapperState(campaignId);

    const framesDialog = useMemo(
        () => (
            <LeaderboardFramesDialogES
                campaignId={campaignId}
                onClose={closeDialog}
                metric={metric}
                openField={openField}
                fieldValue={openField}
                field={field}
                minDate={minDate}
                maxDate={maxDate}
                currentStartDate={currentStartDate}
                currentEndDate={currentEndDate}
                currentTimeframe={currentTimeframe}
                filters={filters}
                environment={environment}
                refineFilters={refineFilters}
                source={source}
            />
        ),
        [
            campaignId,
            closeDialog,
            currentEndDate,
            currentStartDate,
            currentTimeframe,
            environment,
            field,
            filters,
            maxDate,
            metric,
            minDate,
            openField,
            refineFilters,
            source,
        ],
    );

    return (
        <>
            <ReportWrapper
                reportProps={reportProps}
                {...reportWrapperProps}
                beforeChartComponent={beforeChartComponent}
                reportWrapperProps={{
                    ...reportWrapperProps.reportWrapperProps,
                    maxHeight: '80vh',
                    overflow: 'auto',
                }}
            />
            {openField != null && framesDialog}
        </>
    );
};

type HeaderProps = {
    //
    field: string;
    active: boolean;
    direction: Direction;
    onClick: (field: string) => void;
};
const TableHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
    field,
    active,
    direction,
    onClick,
    children,
}) => {
    const orderByClicked = useCallback(() => onClick(field), [onClick, field]);
    return (
        <TableSortLabel direction={direction} active={active} onClick={orderByClicked}>
            {children}
        </TableSortLabel>
    );
};

export default OverallLeaderboardCharts;
