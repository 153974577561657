import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName, PopDisplays_ChannelFrame } from 'c-sdk';
import { displaysIcon, downloadIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { AlertProps, IconButton } from '@mui/material';
import to from 'await-to-js';
import { usePaginatedEntityData } from 'c-data';
import { Alert, DialogV2 } from 'c-components';
import ReactGA from 'react-ga';
import ChannelFrameImportButton from './ChannelFrameImportButton';

const paginationTag = 'pop-display-list-search';
const textSearchCols = ['id', 'channel'];

const columns = [
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.sourceCol',
        field: 'source',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.channelCol',
        field: 'channel',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.frameCol',
        field: 'frame',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.playerCol',
        field: 'player',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.uuidCol',
        field: 'uuid',
        entityName: 'PopDisplays_ChannelFrame',
        orderable: true,
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.displayIdCol',
        field: 'display_id',
        entityName: 'PopDisplays_ChannelFrame',
    }),
    generateColumn<PopDisplays_ChannelFrame>({
        headerTitle: 'Modules.PopDisplays.ChannelFrame.table.displayPortalIdCol',
        field: 'displays_portal_id',
        entityName: 'PopDisplays_ChannelFrame',
    }),
];

const ChannelFrameListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onClose = useCallback(() => {
        ref?.current?.search();
    }, []);
    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.ChannelFrame.metaTitle"
            controls={useMemo(
                () => [
                    <ChannelFrameImportButton onClose={onClose} key="import" />,
                    <DownloadBtn key="download" />,
                ],
                [onClose],
            )}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="PopDisplays_ChannelFrame"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                revertToIdSearchOnNumberOnlyInput={false}
                orderBy="channel"
                direction="asc"
                routeTemplate={PostAuthRoutes.PopDisplays.ChannelFrameOverview}
                showFilters
                filtersInlineSearch
            />
        </FullPageEntityTableWrapper>
    );
};

const DownloadBtn: React.FC = () => {
    const { paginatedData } = usePaginatedEntityData(paginationTag, 'PopDisplays_ChannelFrame');
    const { start, data, isLoading, hasSucceeded, hasFailed, error, reset } = useAPIClientRequest(
        apiClient.PopDisplays.channelFrameExport,
    );

    const download = useCallback(async () => {
        await to(start({ filters: paginatedData?.filters ?? {}, page: 1, perPage: 9999999 }));
    }, [start, paginatedData]);

    const alertColor = useMemo<AlertProps['color']>(() => {
        if (hasSucceeded) return 'success';
        if (hasFailed) return 'error';

        return 'info';
    }, [hasSucceeded, hasFailed]);

    const message = useMemo(() => {
        if (hasSucceeded) return data?.data?.message ? String(data?.data?.message) : '';
        if (hasFailed) return String(error);

        return null;
    }, [data?.data?.message, error, hasFailed, hasSucceeded]);

    return (
        <>
            <DialogV2 onClose={reset} open={hasSucceeded || hasFailed}>
                <Alert variant="outlined" color={alertColor}>
                    {message}
                </Alert>
            </DialogV2>
            <IconButton onClick={download} disabled={isLoading}>
                {downloadIcon}
            </IconButton>
        </>
    );
};

const setup: PageConfig = {
    id: 'ChannelFrameListPage',
    component: ChannelFrameListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.ChannelFrame.metaTitle',
        description: 'Modules.PopDisplays.ChannelFrame.description',
        icon: displaysIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.popDisplaysChannelFrameList',
        route: PostAuthRoutes.PopDisplays.ChannelFrameList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.ChannelFrame.metaTitle' },
        ],
        permissions: [
            PermissionName.Pop_displaysDisplay_channel_framesRead,
            PermissionName.Pop_displaysDisplay_channel_framesUpdate,
            PermissionName.Pop_displaysDisplay_channel_framesCreate,
        ],
    },
};

export default setup;
