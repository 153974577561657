import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Displays_Screen, Displays_Vendor } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import {
    Box,
    FormLabel,
    IconButton,
    Stack,
    TableBody,
    TableContainer,
    TableHead,
} from '@mui/material';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient, useEntityData } from 'c-data';
import { Alert, AutoGrid, Button, DialogV2, DynamicAutocomplete, TextField } from 'c-components';
import to from 'await-to-js';
import { deleteIcon } from 'c-main/icons';

type Props = {
    id: number;
    vendorIds: Displays_Vendor[];
    entity: Displays_Screen;
};
interface VendorOptionType {
    value: string;
    label: string;
    inputValue?: string;
}
interface DataInterface {
    data: string[];
}

export const VendorIDs = ({ id, vendorIds, entity }: Props) => {
    const t = useCommonTranslation();
    const [vendorToDelete, setVendorToDelete] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [vendorName, setVendorName] = React.useState<VendorOptionType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [vendorOptionsArray, setVendorOptionsArray] = useState<VendorOptionType[]>([]);
    const { start, isLoading, error } = useAPIClientRequest(
        apiClient.Entities.Displays_Screen.attachVendors,
    );
    const {
        start: liStart,
        data,
        isLoading: isListLoading,
    } = useAPIClientRequest(apiClient.Entities.Displays_Screen.listVendorIDs);
    useEffect(() => {
        liStart();
    }, [liStart]);
    useEffect(() => {
        setVendorOptionsArray(
            (data as DataInterface)?.data?.map(vendor => ({
                label: vendor,
                value: vendor,
            })) ?? [],
        );
    }, [data]);
    const {
        start: delStart,
        error: delError,
        isLoading: delLoading,
    } = useAPIClientRequest(apiClient.Entities.Displays_Screen.deleteVendors);
    const { upsertEntity } = useEntityData('Displays_Screen');
    const createVendor = useCallback(
        async data => {
            const [, response] = await to(start(id, data, []));
            if (response && response?.data?.data?.id === id) {
                const newData = { ...entity };
                newData.displaysVendorIds = response?.data?.data?.displaysVendorIds;
                upsertEntity(response?.data?.data);
                setVendorId(null);
                setVendorName(null);
            }
        },
        [entity, id, start, upsertEntity],
    );
    const handleInputChange = useCallback(
        (event: React.SyntheticEvent, newInputValue: string) => {
            setInputValue(newInputValue);
        },
        [setInputValue],
    );
    const addNewVendor = useCallback(
        (event: string) => {
            const newVendor = { label: event, value: event };
            setVendorOptionsArray(currentArray => [...currentArray, newVendor]);
            setVendorName(newVendor);
        },
        [setVendorName, setVendorOptionsArray],
    );
    const onChangeHandler = useCallback(
        (event: VendorOptionType) => {
            setVendorName(event);
        },
        [setVendorName],
    );
    const deleteVendor = useCallback(
        async (vendorId: number, screenId: number) => {
            const [, response] = await to(delStart(screenId, vendorId));
            if ((response as any)?.data?.data) {
                const newData = {
                    ...entity,
                    displaysVendorIds: entity.displaysVendorIds.filter(
                        item => item.id !== vendorId,
                    ),
                };
                delete newData.mediaOwners;
                delete newData.resolutions;
                delete newData.fileTypes;
                delete newData.packs;
                delete newData.tags;

                upsertEntity(newData);
                setVendorToDelete(null);
            }
        },
        [delStart, entity, upsertEntity],
    );

    const createData = (vendor_id: string, vendor_name: string, id: number) => ({
        vendor_id,
        vendor_name,
        id,
    });

    const onSubmit = () => {
        createVendor([{ id: vendorId, name: vendorName.value }]);
    };

    const table = useMemo(() => {
        const rows = vendorIds.map(
            (vendor: { vendor_id: string; vendor_name: string; id: number }) =>
                createData(vendor.vendor_id, vendor.vendor_name, vendor.id),
        );
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Modules.Displays.vendorIds.name')}</TableCell>
                            <TableCell>{t('Modules.Displays.vendorIds.id')}</TableCell>
                            <TableCell>{t('Modules.Displays.vendorIds.deleteVendorId')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow
                                key={row.id as number}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.vendor_name}</TableCell>
                                <TableCell>{row.vendor_id}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="error"
                                        onClick={() =>
                                            setVendorToDelete({
                                                id: row.id,
                                                vendor_id: row.vendor_id,
                                                vendor_name: row.vendor_name,
                                            })
                                        }
                                    >
                                        {deleteIcon}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }, [t, vendorIds]);

    const DeleteVendorDialog = useMemo(
        () => (
            <DialogV2
                title="Modules.Displays.vendorIds.dialogTitle"
                onClose={() => setVendorToDelete(null)}
                open={vendorToDelete != null}
                maxWidth="lg"
            >
                <Stack pb={2}>
                    <Box display="flex" justifyContent="center" p={1}>
                        {t('Modules.Displays.vendorIds.name')}: {vendorToDelete?.vendor_name}
                    </Box>
                    <Box display="flex" justifyContent="center" p={1}>
                        {t('Modules.Displays.vendorIds.id')}: {vendorToDelete?.vendor_id}
                    </Box>
                </Stack>
                {delError && (
                    <Alert severity="error" variant="outlined">
                        {String(delError)}
                    </Alert>
                )}
                <Stack direction="row" gap={2} display="flex" justifyContent="center">
                    <Button
                        onClick={() => deleteVendor(vendorToDelete?.id, id)}
                        disabled={delLoading}
                    >
                        {t('Modules.Displays.vendorIds.yes')}
                    </Button>
                    <Button onClick={() => setVendorToDelete(null)}>
                        {t('Modules.Displays.vendorIds.no')}
                    </Button>
                </Stack>
            </DialogV2>
        ),
        [vendorToDelete, t, delLoading, deleteVendor, id, delError],
    );

    return (
        <AutoGrid xs={12} gap={2} p={4}>
            {DeleteVendorDialog}
            <AutoGrid gap={2} xs={5}>
                <>
                    <FormLabel>{t('Modules.Displays.vendorIds.name')}</FormLabel>
                    <DynamicAutocomplete
                        value={vendorName?.value}
                        options={vendorOptionsArray}
                        setOptions={setVendorOptionsArray}
                        setValue={setVendorName}
                        disabled={isListLoading}
                    />
                </>

                <>
                    <FormLabel>{t('Modules.Displays.vendorIds.id')}</FormLabel>
                    <TextField onChange={e => setVendorId(e.target.value)} value={vendorId || ''} />
                </>

                <Button
                    onClick={onSubmit}
                    type="submit"
                    disabled={isLoading || !vendorId || !vendorName}
                >
                    {t('Modules.Displays.OperationalTimings.submit')}
                </Button>
            </AutoGrid>
            {error && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
            {table}
        </AutoGrid>
    );
};
