import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from 'c-sdk';
import { Box, Tooltip, Typography } from '@mui/material';
import AutoGrid from 'c-components/AutoGrid';
import { DebugWrapperCss } from 'c-auth-module/Lib';

export type ConditionalDebugWrapperProps = {
    show: boolean;

    otherwise?: ReactNode;
    disableDebugWrap?: boolean;

    tooltipLines: string[];
};

const ConditionalDebugWrapper: React.FC<PropsWithChildren<ConditionalDebugWrapperProps>> = ({
    show,
    otherwise,
    disableDebugWrap,
    tooltipLines,
    children,
}) => {
    const debug = useSelector(uiSelectors.debugEnabled);
    const { hasAll } = useUserPermissions();
    const canSeeDebug = useMemo(() => hasAll([PermissionName.Admin]), [hasAll]);

    const tooltipContent = useMemo(
        () => (
            <AutoGrid spacing={1} xs={12}>
                {(tooltipLines ?? []).map(line => (
                    <Typography key={line} variant="caption">
                        {line}
                    </Typography>
                ))}
            </AutoGrid>
        ),
        [tooltipLines],
    );

    if (!show) {
        return otherwise ? <>{otherwise}</> : null;
    }

    if (!canSeeDebug || !debug || disableDebugWrap) {
        return <>{children}</>;
    }

    return (
        <Tooltip title={tooltipContent}>
            <Box css={DebugWrapperCss} p={0.25} m={0.25}>
                {children}
            </Box>
        </Tooltip>
    );
};

export default ConditionalDebugWrapper;
