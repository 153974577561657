import { BaseEntity } from '../../BaseEntity';
import { Creative } from './Creative';
import { Campaign } from './Campaign';
import { Displays_LineItem, Displays_Screen } from '../Displays';

export type Schedule = BaseEntity & {
    campaign_id: number;
    name: string;
    template: boolean;
    is_default: boolean;
    disable_flag: boolean;
    // include
    schedulables?: ScheduleSchedulable[];

    // include
    rules?: ScheduleRule[];
};
type ScheduleFile = {
    created_at: string;
    file_name: string;
    id: number;
    updated_at: string;
    schedule_id: number;
    data: { refresh: boolean; status: string; message: string };
};

type ScheduleWithFile = {
    is_file: true;
    scheduleFile: ScheduleFile;
};

type ScheduleWithoutFile = {
    is_file: false;
    scheduleFile?: never;
};

export type ScheduleGet = BaseEntity & {
    campaign_id: number;
    name: string;
    template: boolean;
    is_default: boolean;
    disable_flag: boolean;
    schedulables?: ScheduleSchedulable[];
    rules?: ScheduleRuleGet[];
} & (ScheduleWithFile | ScheduleWithoutFile);

export enum SchedulableProofOfPlay {
    Empty = '',
    Target = 'target',
    Impacts = 'impacts',
}

export enum SchedulableType {
    Campaign = 'Campaign',
    Creative = 'Creative',
    LineItem = 'LineItem',
    package = 'package',
    // Screen = 'Screen',

    // Screen has a custom morph class because of legacy Displays system, where it is called `Displays`
    // Once we migrate the data we'll need to change this back to `Screen`
    Screen = 'Display',
    Display = 'Display',
}

export type ScheduleSchedulable =
    | SchedulableCampaign
    | SchedulableCreative
    | SchedulableLineItem
    | SchedulableScreen;

export type Schedulable = BaseEntity & {
    schedule_id: number;
    schedulable_id: string;
    schedulable_type: SchedulableType;
    campaign_id: number;
    proof_of_play?: SchedulableProofOfPlay;

    // decimal with 10 digits of precision
    sov?: string;

    // include
    schedule?: Schedule;
};

export type SchedulableCampaign = Schedulable & {
    schedulable_type: SchedulableType.Campaign;

    // include
    schedulable: Campaign;
};

export type SchedulableCreative = Schedulable & {
    schedulable_type: SchedulableType.Creative;

    // include
    schedulable: Creative;
};

export type SchedulableLineItem = Schedulable & {
    schedulable_type: SchedulableType.LineItem;

    // include
    schedulable: Displays_LineItem;
};

export type SchedulableScreen = Schedulable & {
    schedulable_type: SchedulableType.Screen;

    // include
    schedulable: Displays_Screen;
};

export type ScheduleRule = BaseEntity & {
    schedule_id: number;

    // date string as `yyyy-MM-dd`
    start_date?: string;

    // date string as `yyyy-MM-dd`
    end_date?: string;

    hours_of_day: {
        '00:00': boolean;
        '00:30': boolean;
        '01:00': boolean;
        '01:30': boolean;
        '02:00': boolean;
        '02:30': boolean;
        '03:00': boolean;
        '03:30': boolean;
        '04:00': boolean;
        '04:30': boolean;
        '05:00': boolean;
        '05:30': boolean;
        '06:00': boolean;
        '06:30': boolean;
        '07:00': boolean;
        '07:30': boolean;
        '08:00': boolean;
        '08:30': boolean;
        '09:00': boolean;
        '09:30': boolean;
        '10:00': boolean;
        '10:30': boolean;
        '11:00': boolean;
        '11:30': boolean;
        '12:00': boolean;
        '12:30': boolean;
        '13:00': boolean;
        '13:30': boolean;
        '14:00': boolean;
        '14:30': boolean;
        '15:00': boolean;
        '15:30': boolean;
        '16:00': boolean;
        '16:30': boolean;
        '17:00': boolean;
        '17:30': boolean;
        '18:00': boolean;
        '18:30': boolean;
        '19:00': boolean;
        '19:30': boolean;
        '20:00': boolean;
        '20:30': boolean;
        '21:00': boolean;
        '21:30': boolean;
        '22:00': boolean;
        '22:30': boolean;
        '23:00': boolean;
        '23:30': boolean;
    };
    days_of_week: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    };

    // decimal with 10 digits of precision
    sov?: string;

    // decimal with 10 digits of precision
    impact?: string;

    target?: number;

    // include
    schedule?: Schedule;
    slot_length?: number;
};
export type ScheduleRuleGet = BaseEntity & {
    schedule_id: number;

    // date string as `yyyy-MM-dd`
    start_date?: string;

    // date string as `yyyy-MM-dd`
    end_date?: string;

    hours_of_day: {
        '00:00': boolean;
        '00:30': boolean;
        '01:00': boolean;
        '01:30': boolean;
        '02:00': boolean;
        '02:30': boolean;
        '03:00': boolean;
        '03:30': boolean;
        '04:00': boolean;
        '04:30': boolean;
        '05:00': boolean;
        '05:30': boolean;
        '06:00': boolean;
        '06:30': boolean;
        '07:00': boolean;
        '07:30': boolean;
        '08:00': boolean;
        '08:30': boolean;
        '09:00': boolean;
        '09:30': boolean;
        '10:00': boolean;
        '10:30': boolean;
        '11:00': boolean;
        '11:30': boolean;
        '12:00': boolean;
        '12:30': boolean;
        '13:00': boolean;
        '13:30': boolean;
        '14:00': boolean;
        '14:30': boolean;
        '15:00': boolean;
        '15:30': boolean;
        '16:00': boolean;
        '16:30': boolean;
        '17:00': boolean;
        '17:30': boolean;
        '18:00': boolean;
        '18:30': boolean;
        '19:00': boolean;
        '19:30': boolean;
        '20:00': boolean;
        '20:30': boolean;
        '21:00': boolean;
        '21:30': boolean;
        '22:00': boolean;
        '22:30': boolean;
        '23:00': boolean;
        '23:30': boolean;
    };
    days_of_week: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // decimal with 10 digits of precision
    sov?: string;

    // decimal with 10 digits of precision
    impact?: string;

    target?: number;

    // include
    schedule?: Schedule;
    slot_length?: number;
};
