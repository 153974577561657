import React, {
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Badge,
    Box,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { uniq } from 'ramda';
import { AllEntities } from 'c-sdk';
import { NetworkRequestState, SearchPayload } from 'c-data-layer';
import { useDebouncedCallback } from 'use-debounce';
import equal from 'fast-deep-equal';
import { useBoolean, usePrevious } from 'react-hanger';
import { getBaseSliceThunks, usePaginatedEntityData } from 'c-data';
import { AutoGrid, Button, LoadingSpinner, TextField } from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { FilterAlt, Search } from '@mui/icons-material';
import { IfHasAllPermissions, IfUserIsOneOfTypes } from 'c-auth-module/Components';
import {
    CustomFilterConfigType,
    FilterableEntityTableProps,
    FilterConfig,
    FilterConfigRendererProps,
} from 'c-pagination/types';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
// eslint-disable-next-line import/no-cycle
import DropdownFilter from './DropdownFilter';
import DateFilter from './DateFilter';
import DateRangeFilter from './DateRangeFilter';
import EntityAutocompleteFilter from './EntityAutocompleteFilter';
import TextFilter from './TextFilter';

type Props = {
    entityName: keyof AllEntities;
    tag: string;
    allFilters: FilterConfig[];
    textSearchColumn?: string;
    reset: () => void;
    onSubmit: (searchVal: string, force?: boolean) => void;

    defaultSearchVal: string;

    afterFilters?: FilterableEntityTableProps['afterFilters'];
    alwaysOpenFilters?: boolean;
    filtersInlineSearch?: boolean;
    displayDownloadButton?: boolean;
    reImportButton?: boolean;
    customFilters?: React.ReactNode[];
    customFilterResets: (() => void)[];
    filterApplyButton: boolean;
};

const searchSizes = {
    xs: 12,
    sm: 8,
    md: 6,
    lg: 4,
    xl: 3,
};

const afterSearchProps = {
    xs: 12,
    sm: 12 - searchSizes.sm,
    md: 12 - searchSizes.md,
    lg: 12 - searchSizes.lg,
    xl: 12 - searchSizes.xl,
};

const afterSearchBoxProps = {
    sx: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
};

const defaultFilterCount = (value: any[]) => (Array.isArray(value) ? uniq(value).length : 0);

const filterComponentsMap = {
    [CustomFilterConfigType.Select]: DropdownFilter,
    [CustomFilterConfigType.Date]: DateFilter,
    [CustomFilterConfigType.DateRange]: DateRangeFilter,
    [CustomFilterConfigType.EntityAutocomplete]: EntityAutocompleteFilter,
    [CustomFilterConfigType.Text]: TextFilter,
};

const actualConfigValue = (
    config: FilterConfig,
    allFilterValues: SearchPayload<any>['filters'],
) => {
    let filterValue = [];
    if (Array.isArray(allFilterValues?.[config.key])) {
        filterValue = allFilterValues?.[config.key];
    }

    return uniq(filterValue);
};

const EntityFilters: React.FC<Props> = ({
    entityName,
    tag,
    textSearchColumn,
    reset,
    defaultSearchVal,
    onSubmit,

    afterFilters,
    allFilters,

    alwaysOpenFilters = false,
    filtersInlineSearch = false,
    displayDownloadButton = false,
    reImportButton = false,
    customFilters = [],
    customFilterResets = [],
    filterApplyButton = false,
}) => {
    const t = useCommonTranslation();
    const { paginatedData } = usePaginatedEntityData(tag, entityName);
    const [searchVal, setSearchVal] = useState(defaultSearchVal);
    const filterOpenState = useBoolean(alwaysOpenFilters);
    const {
        start,
        data,
        requestState,
        reset: requestReset,
    } = useAPIClientRequest(ApiClient.Entities.Displays_Screen.csvExport);
    const {
        start: startReImport,
        data: reImportData,
        requestState: reImportRequestState,
        reset: reImportReset,
    } = useAPIClientRequest(ApiClient.Entities.Displays_Screen.reImport);
    const getData = useCallback(async () => {
        await to(
            start({
                'search.any': searchVal,
                ...paginatedData.filters,
                'search.vendor_id': searchVal,
            }),
        );
    }, [paginatedData.filters, start, searchVal]);
    const getReImportData = useCallback(async () => {
        await to(
            startReImport({
                'search.any': searchVal,
                ...paginatedData.filters,
                'search.vendor_id': searchVal,
            }),
        );
    }, [paginatedData.filters, startReImport, searchVal]);

    const applyButton = useMemo(() => {
        const handleDownloadClick = async () => {
            await getData();
        };
        if (requestState === NetworkRequestState.InProgress) {
            return <LoadingSpinner />;
        }
        if (requestState === NetworkRequestState.Success) {
            return (
                <Stack direction="row">
                    <Typography fontWeight={0}>{(data as any)?.data.message}</Typography>
                    <Button size="small" variant="text" onClick={() => requestReset()}>
                        x
                    </Button>
                </Stack>
            );
        }
        return (
            <Button
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                disabled={Object.keys(paginatedData.filters).length === 0 && searchVal.length === 0}
                onClick={handleDownloadClick}
            >
                {t('Modules.Displays.DisplaysList.downloadCSV')}
            </Button>
        );
    }, [requestState, paginatedData.filters, searchVal.length, t, getData, data, requestReset]);

    const reImportButtonComponent = useMemo(() => {
        const handleReImportClick = async () => {
            await getReImportData();
        };
        if (reImportRequestState === NetworkRequestState.InProgress) {
            return <LoadingSpinner />;
        }
        if (reImportRequestState === NetworkRequestState.Success) {
            return (
                <Stack direction="row">
                    <Typography fontWeight={0}>{(reImportData as any)?.data.message}</Typography>
                    <Button size="small" variant="text" onClick={() => reImportReset()}>
                        x
                    </Button>
                </Stack>
            );
        }
        return (
            <Button
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                disabled={Object.keys(paginatedData.filters).length === 0 && searchVal.length === 0}
                onClick={handleReImportClick}
            >
                {t('Modules.Displays.DisplaysList.reImport')}
            </Button>
        );
    }, [
        getReImportData,
        paginatedData.filters,
        reImportData,
        reImportRequestState,
        reImportReset,
        searchVal.length,
        t,
    ]);

    const onSearchChange = useCallback(e => {
        setSearchVal(e.target.value);
    }, []);

    const doSearch = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);

    const searchedData = useRef<{ filters: Record<string, any>; search: string }>({
        filters: paginatedData?.filters,
        search: searchVal,
    });

    const prevFilters = usePrevious(paginatedData.filters);
    const prevSearch = usePrevious(searchVal);

    const doTheSearch = useCallback(
        (force = false) => {
            onSubmit(searchVal, force);
        },
        [searchVal, onSubmit],
    );
    const applyFiltersButton = useMemo(
        () => (
            <Button
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                    doSearch(() => {
                        doTheSearch();
                    })
                }
            >
                {t('Modules.Main.CreativeManagement.creatives.filters.apply')}
            </Button>
        ),
        [doSearch, doTheSearch, t],
    );

    // const submitBtnRef = useRef<HTMLButtonElement>();

    useEffect(() => {
        const filters = paginatedData.filters;
        /**
         * Queue up a delayed (debounced) search when the search term is updated
         */
        if (!filterApplyButton) {
            doSearch(() => {
                if (filters != null && !equal(filters, prevFilters)) {
                    doTheSearch();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doSearch, searchVal, filterApplyButton]);

    useEffect(() => {
        const filters = paginatedData.filters;
        /**
         * Queue up a delayed (debounced) search when the filters are updated
         */
        if (!filterApplyButton) {
            doSearch(() => {
                if (filters != null && !equal(filters, prevFilters)) {
                    doTheSearch();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData.filters, doSearch, prevFilters, filterApplyButton]);

    useEffect(() => {
        const val = searchVal;
        /**
         * Queue up a delayed (debounced) search when the search value is updated
         */
        if (!filterApplyButton) {
            doSearch(() => {
                if (val != null && val !== prevSearch && prevSearch != null) {
                    doTheSearch();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, doSearch, filterApplyButton]);

    useEffect(() => {
        if (paginatedData?.loadingState?.state === NetworkRequestState.InProgress) {
            /**
             * cache the filters that were searched for.
             * When the search finishes we can check if the filters
             * or search term have changed and trigger another search
             */
            searchedData.current = { filters: paginatedData.filters, search: searchVal };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData?.loadingState?.state]);

    useEffect(() => {
        /**
         * If finished searching and search criteria changed
         * while the HTTP request was pending, fire off another search
         * with the newest filters
         */
        if (!filterApplyButton) {
            if (paginatedData?.loadingState?.state === NetworkRequestState.Idle) {
                if (
                    (!equal(searchedData.current.filters, paginatedData.filters) ||
                        searchedData.current.search !== searchVal) &&
                    (searchVal != null || !equal(paginatedData.filters, {}))
                ) {
                    doTheSearch();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData?.loadingState?.state, filterApplyButton]);

    const onClickReset = useCallback(() => {
        setSearchVal('');
        customFilterResets.forEach(resetFn => resetFn());
        reset();
    }, [customFilterResets, reset]);
    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={onClickReset}>
                <Typography variant="caption">
                    <Translate path="Pagination.resetFilters" />
                </Typography>
            </Button>
        ),
        [onClickReset],
    );

    const onSearchKeyUp = useCallback<KeyboardEventHandler<any>>(
        event => {
            if (event.key === 'Enter') {
                doTheSearch(true);
            }
        },
        [doTheSearch],
    );

    const searchPlaceholder = useCommonTranslation('Pagination.searchPlaceholder');
    const searchInput = useMemo(
        () => (
            <>
                <TextField
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                    onKeyUp={onSearchKeyUp}
                    value={searchVal}
                    size="small"
                    id={`search-${tag}`}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{resetButton}</InputAdornment>,
                    }}
                />
            </>
        ),
        [searchPlaceholder, onSearchChange, resetButton, searchVal, tag, onSearchKeyUp],
    );

    const appliedFiltersCount = useMemo(
        () =>
            allFilters.reduce((count, config) => {
                const actualValue = actualConfigValue(config, paginatedData?.filters ?? {});

                let filterCount = defaultFilterCount(actualValue);

                if (config.filterCountOverride) {
                    filterCount = config.filterCountOverride(
                        filterCount,
                        actualValue,
                        paginatedData?.filters ?? {},
                    );
                }

                if (filterCount > 0) return count + 1;

                return count;
            }, 0),
        [paginatedData?.filters, allFilters],
    );

    const filterButton = useMemo(
        () => (
            <Badge badgeContent={appliedFiltersCount} color="primary" max={99}>
                <IconButton onClick={filterOpenState.toggle} className="filter-toggle">
                    <FilterAlt fontSize="inherit" />
                </IconButton>
            </Badge>
        ),
        [filterOpenState.toggle, appliedFiltersCount],
    );

    const allFilterComponents = useMemo(() => {
        const filterComponents = allFilters.map((f, idx) => (
            <Box key={f.key}>
                <IfUserIsOneOfTypes roles={f.userTypes} forceShow>
                    <IfHasAllPermissions permissions={f.permissions} forceShow>
                        <Box sx={{ bgcolor: 'white', borderRadius: '8px' }}>
                            <CustomConfigFiltersWrapper
                                entityName={entityName}
                                tag={tag}
                                config={f}
                                Component={filterComponentsMap[f.type]}
                            />
                        </Box>
                    </IfHasAllPermissions>
                </IfUserIsOneOfTypes>
            </Box>
        ));
        if (customFilters.length > 0) {
            customFilters.map(filter => filterComponents.push(<Box>{filter}</Box>));
        }
        const buttons = [];
        if (filterApplyButton) {
            buttons.push(
                <Box pl={1} key="applyFiltersButton">
                    {applyFiltersButton}
                </Box>,
            );
        }
        if (displayDownloadButton) {
            buttons.push(
                <Box key="applyButton" ml={1}>
                    {applyButton}
                </Box>,
            );
        }
        if (reImportButton) {
            buttons.push(
                <Box key="reImportButton" ml={1}>
                    {reImportButtonComponent}
                </Box>,
            );
        }
        if (buttons.length > 0) {
            const buttonList = (
                <AutoGrid gap={1} xs={2} columns={buttons.length}>
                    {buttons.map(button => (
                        <Box key={button.key}>{button}</Box>
                    ))}
                </AutoGrid>
            );
            filterComponents.push(buttonList);
        }

        return filterComponents;
    }, [
        allFilters,
        customFilters,
        filterApplyButton,
        displayDownloadButton,
        reImportButton,
        entityName,
        tag,
        applyFiltersButton,
        applyButton,
        reImportButtonComponent,
    ]);

    const collapsibleFilterComponents = useMemo(
        () => (
            <>
                <Collapse in={filterOpenState.value}>
                    <Box mt={1}>
                        <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3} xl={2}>
                            {allFilterComponents}
                        </AutoGrid>
                    </Box>
                </Collapse>
            </>
        ),
        [filterOpenState.value, allFilterComponents],
    );

    const afterFiltersWithWrapper = useMemo(() => {
        if (afterFilters) {
            return (
                <Grid item {...afterSearchProps}>
                    <Box {...afterSearchBoxProps}>{afterFilters}</Box>
                </Grid>
            );
        }
        return null;
    }, [afterFilters]);

    if (allFilters.length === 0 && textSearchColumn == null) {
        return null;
    }

    if (allFilters.length === 0) {
        return (
            <>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item {...searchSizes}>
                        {searchInput}
                    </Grid>
                    {afterFiltersWithWrapper}
                </Grid>
            </>
        );
    }

    return (
        <>
            <Box mt={1}>
                {textSearchColumn && (
                    <Grid container spacing={2}>
                        <Grid item {...searchSizes}>
                            <Box display="flex">
                                {searchInput}
                                {!alwaysOpenFilters && !filtersInlineSearch && (
                                    <Box ml={1}>{filterButton}</Box>
                                )}
                            </Box>
                        </Grid>
                        {filtersInlineSearch &&
                            allFilterComponents.map((filter, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid item {...searchSizes} key={idx}>
                                    {filter}
                                </Grid>
                            ))}
                        {afterFiltersWithWrapper}
                        <Grid item {...searchSizes} />
                    </Grid>
                )}
                {!filtersInlineSearch && collapsibleFilterComponents}
            </Box>
        </>
    );
};

type ConfigWrapperProps = {
    entityName: keyof AllEntities;
    tag: string;

    Component?: React.FC<FilterConfigRendererProps<any>>;
    config: FilterConfig;
};
const CustomConfigFiltersWrapper: React.FC<ConfigWrapperProps> = ({
    Component,
    tag,
    entityName,
    config,
}) => {
    const dispatch = useDispatch();

    const { updatePaginatedListFilter } = useMemo(
        () => getBaseSliceThunks(entityName),
        [entityName],
    );
    const { paginatedData } = usePaginatedEntityData(tag, entityName);
    const onChange = useCallback<FilterConfigRendererProps<any>['onChange']>(
        value => {
            let newValue = value;
            if (config.onChangeOverrideValue) {
                newValue = config.onChangeOverrideValue(newValue);
            }

            dispatch(updatePaginatedListFilter(tag, config.key, newValue));
        },
        [config, dispatch, updatePaginatedListFilter, tag],
    );

    const actualValue = useMemo(
        () => actualConfigValue(config, paginatedData?.filters ?? {}),
        [config, paginatedData?.filters],
    );

    const count = useMemo(() => {
        const defaultCount = defaultFilterCount(actualValue);

        return config.filterCountOverride
            ? config.filterCountOverride(defaultCount, actualValue, paginatedData?.filters ?? {})
            : defaultCount;
    }, [actualValue, config, paginatedData?.filters]);

    const filterId = useMemo(
        () => `${entityName}-${config.key}-${tag}`.replace(/\./g, '_'),
        [config.key, entityName, tag],
    );

    const filterClassName = useMemo(
        () => `${entityName}-${config.key}`.replace(/\./g, '_'),
        [config.key, entityName],
    );

    return (
        <Component
            value={actualValue}
            onChange={onChange}
            count={count}
            config={config}
            id={filterId}
            className={filterClassName}
        />
    );
};

export default EntityFilters;
