import React, { useCallback, useMemo, useState } from 'react';
import { PopDisplays_LogFileResource } from 'c-sdk';
import { Typography } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { uniq } from 'ramda';
import { Autocomplete, AutoGrid, OptionSchema, TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';

type Props = {
    resources: PopDisplays_LogFileResource[];
    loading: boolean;
};

const initialState: DataGridProProps['initialState'] = {
    sorting: {
        sortModel: [
            { field: 'type', sort: 'asc' },
            { field: 'index', sort: 'asc' },
        ],
    },
};

const LogFileResourceTable: React.FC<Props> = ({ resources, loading }) => {
    const uniqueTypeOptions = useMemo<OptionSchema[]>(
        () => uniq(resources.map(res => res.type)).map(type => ({ value: type, label: type })),
        [resources],
    );

    const [types, setTypes] = useState<string[]>([]);
    const [search, setSearch] = useState('');

    const visibleRes = useMemo(() => {
        const doSearchCheck = search?.trim()?.length > 0;
        const doTypesCheck = types?.length > 0;

        if (doSearchCheck || doTypesCheck) {
            return resources.filter(res => {
                let visible = true;

                if (doSearchCheck && res.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                    visible = false;
                }
                if (doTypesCheck && types.indexOf(res.type) === -1) {
                    visible = false;
                }

                return visible;
            });
        }
        return resources;
    }, [resources, search, types]);

    const onSearchUpdated = useCallback(e => {
        setSearch(e.target.value);
    }, []);

    const t = useCommonTranslation();
    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'type',
                headerName: t('Modules.PopDisplays.LogFileResource.table.typeCol'),
                sortable: true,
            },
            {
                field: 'index',
                headerName: t('Modules.PopDisplays.LogFileResource.table.indexCol'),
                sortable: true,
                type: 'number',
            },
            {
                field: 'count',
                headerName: t('Modules.PopDisplays.LogFileResource.table.countCol'),
                sortable: true,
                type: 'number',
            },
            {
                field: 'name',
                headerName: t('Modules.PopDisplays.LogFileResource.table.nameCol'),
                flex: 1,
                sortable: true,
            },
            {
                field: 'extId',
                headerName: t('Modules.PopDisplays.LogFileResource.table.extIdCol'),
            },
            {
                field: 'revision',
                headerName: t('Modules.PopDisplays.LogFileResource.table.revisionCol'),
            },
        ],
        [t],
    );

    return (
        <>
            <Typography variant="h4">
                {useCommonTranslation(
                    'Modules.PopDisplays.LogFileImport.details.resourcesSectionTitle',
                )}{' '}
                ({visibleRes.length})
            </Typography>
            <AutoGrid sx={{ mb: 2 }} xs="auto" spacing={2}>
                <TextField
                    sx={{ mt: 0.5 }}
                    size="small"
                    variant="standard"
                    label={t('Modules.PopDisplays.LogFileImport.details.filterByNameLabel')}
                    value={search}
                    onChange={onSearchUpdated}
                />
                <Autocomplete
                    sx={{ minWidth: 200 }}
                    textFieldProps={{
                        label: useCommonTranslation(
                            'Modules.PopDisplays.LogFileImport.details.filterByTypeLabel',
                        ),
                        variant: 'standard',
                    }}
                    options={uniqueTypeOptions}
                    onChange={setTypes}
                    value={types}
                    multiple
                />
            </AutoGrid>
            <DataGridPro
                disableRowSelectionOnClick
                disableColumnReorder
                disableColumnSelector
                disableColumnPinning
                hideFooter
                loading={loading}
                initialState={initialState}
                columns={columns}
                rows={visibleRes}
                rowHeight={50}
            />
        </>
    );
};

export default LogFileResourceTable;
