import React, { forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material';
import { AllEntities, BaseEntity, ListSearchOptions } from 'c-sdk';
import { useDebouncedCallback } from 'use-debounce';
import { NetworkRequestState } from 'c-data-layer';
import { OptionSchema } from 'c-components/Forms/formTypes';
import Autocomplete, { AutocompleteProps } from 'c-components/Forms/Autocomplete';
import { stringOnlyNumbers } from 'c-lib';
import { AppTranslationFunction, useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { uniqWith } from 'ramda';
import { usePrevious } from 'react-hanger';
import { useEntityData } from 'c-data';
import merge from 'deepmerge';
import equal from 'fast-deep-equal';

export type EntityAutocompleteProps = Omit<AutocompleteProps, 'options' | 'renderOption'> & {
    entityName: keyof AllEntities;

    // TS2589: Type instantiation is excessively deep and possibly infinite.
    // searchColumn: Paths<Entity>;
    searchColumns: string | string[];

    labelColumn: string;
    valueColumn?: string;

    includes?: string[];
    scopes?: string[];
    defaultFilters?: ListSearchOptions['filters'];

    autoLoadSearchTerm?: string;

    additionalOptions?: OptionSchema[];
    orderByField?: string;
    orderByDirection?: ListSearchOptions['direction'];

    /**
     * if set to true, will not fall back to searching by the `id` field if only a number is entered
     */
    disableSearchFieldIdFallback?: boolean;

    labelGen?: (entity, t: AppTranslationFunction) => string;
    renderOption?: (
        optionProps: React.HTMLAttributes<HTMLLIElement>,
        option: OptionSchema,
        entity,
        t: AppTranslationFunction,
    ) => React.ReactNode;

    // alter the search term before going off to the API
    alterSearchTerm?: (term: string) => string;

    customListSearch?: ReturnType<(typeof apiClient.Entities)[keyof AllEntities]['list']>;
};

function calcOptions(
    ids: (number | string)[],
    keyedEntities: Record<number, any>,
    valueColumn: string,
    labelColumn: string,
    t: AppTranslationFunction,
    labelGen?: EntityAutocompleteProps['labelGen'],
) {
    return ids
        .filter(entityId => keyedEntities[entityId] != null)
        .map(entityId => ({
            value: (keyedEntities[entityId] as any)?.[valueColumn as any],
            label: labelGen
                ? labelGen(keyedEntities[entityId], t)
                : (keyedEntities[entityId] as any)?.[labelColumn as any],
        }));
}

const defaultAlterSearchTerm: EntityAutocompleteProps['alterSearchTerm'] = term => term;

const generateSearchables = (columns: string[], term: string) =>
    columns.reduce((searchables, col) => ({ ...searchables, [`search.${col}`]: term }), {});

const empty = [];
const emptyFilters = {};
function EntityAutocomplete(
    {
        entityName,
        searchColumns,
        labelColumn,
        valueColumn = 'id',
        value,
        multiple,
        onChange,
        includes = empty,
        scopes = empty,
        defaultFilters = emptyFilters,
        autoLoadSearchTerm = '',
        additionalOptions = empty,
        disableSearchFieldIdFallback = false,
        labelGen,
        renderOption,
        orderByField,
        orderByDirection,
        alterSearchTerm = defaultAlterSearchTerm,
        customListSearch,
        ...props
    }: EntityAutocompleteProps,
    ref: Ref<any>,
) {
    const [allUniqueEntities, setAllUniqueEntities] = useState<BaseEntity[]>([]);
    const localKeyedEntities = useMemo<Record<number, BaseEntity>>(
        () =>
            allUniqueEntities.reduce(
                (acc, curr) => ({ ...acc, [curr.id]: curr }),
                {} as Record<number, BaseEntity>,
            ),
        [allUniqueEntities],
    );
    const { keyedEntities: ReduxEntities } = useEntityData(entityName);

    /**
     * Key the entities by the value column. Defaults to keyed by id.
     *
     * Sometimes you might want to set the name of an entity as the value, so to make looking up
     * by option value easier, key by the value column.
     */
    const keyedEntities = useMemo<Record<number | string, BaseEntity>>(
        () =>
            Object.values(merge(ReduxEntities, localKeyedEntities)).reduce(
                (entities, entity) => ({ ...entities, [entity[valueColumn]]: entity }),
                {},
            ),
        [ReduxEntities, localKeyedEntities, valueColumn],
    );

    const a = apiClient.Entities[entityName].list;
    const { start, requestState, data } = useAPIClientRequest(
        (customListSearch as unknown as typeof a) ?? apiClient.Entities[entityName].list,
    );

    const currentResponseIds = useMemo(
        () => data?.data?.data?.map((entity: BaseEntity) => entity[valueColumn]) ?? [],
        [data, valueColumn],
    );

    const t = useCommonTranslation();

    const options = useMemo<OptionSchema[]>(
        () => [
            ...additionalOptions,
            ...calcOptions(
                currentResponseIds,
                keyedEntities,
                valueColumn,
                labelColumn,
                t,
                labelGen,
            ),
        ],
        [
            currentResponseIds,
            keyedEntities,
            labelColumn,
            valueColumn,
            additionalOptions,
            labelGen,
            t,
        ],
    );

    const allOptionValues = useMemo(() => options.map(o => o.value), [options]);
    const missingOptions = useMemo(() => {
        // if (!multiple) {
        //     return [];
        // }
        const missingIds = [];
        if (!multiple && value != null && allOptionValues.indexOf(value) === -1) {
            missingIds.push(value);
        } else if (multiple && Array.isArray(value)) {
            value.forEach(v => {
                if (allOptionValues.indexOf(v) === -1) {
                    missingIds.push(v);
                }
            });
        }

        return calcOptions(missingIds, keyedEntities, valueColumn, labelColumn, t, labelGen);
    }, [multiple, value, allOptionValues, keyedEntities, valueColumn, labelColumn, labelGen, t]);

    /**
     * This auto complete performs http requests depending on the search term.
     *
     * Imagine you search for `goog` and get an option to select `Google`. You select `Google` and then
     * search for `micro`.
     *
     * The new results will include `Microsoft`, but not your selected `Google` option
     * so we need to add already selected options not in the option list back in.
     */
    const allOptions = useMemo(() => [...missingOptions, ...options], [options, missingOptions]);

    const optionLabels = useMemo(
        () =>
            allOptions.reduce((acc, curr) => {
                acc[curr.value] = curr.label;
                return acc;
            }, {} as Record<any, any>),
        [allOptions],
    );

    const searchedVal = useRef('');
    const [inputVal, setInputVal] = useState('');

    useEffect(() => {
        if (!multiple && value != null && inputVal == '') {
            // need to set the input value to the selected option value
            // if this is a singular dropdown
            const valueOption = allOptions.find(opt => opt.value === value);
            if (valueOption != null) {
                setInputVal(valueOption.label);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allOptions, value]);

    const doSearch = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);

    const actualSearchColumns = useMemo(
        () => (Array.isArray(searchColumns) ? searchColumns : [searchColumns]),
        [searchColumns],
    );

    const triggerSearch = useCallback(
        async (searchVal, force = false) => {
            const actualSearchVal = alterSearchTerm(searchVal);
            if (
                ((actualSearchVal == null || actualSearchVal === '') && !force) ||
                requestState === NetworkRequestState.InProgress
            ) {
                return;
            }

            let searchKeys = actualSearchColumns;
            if (stringOnlyNumbers(actualSearchVal) && !disableSearchFieldIdFallback) {
                searchKeys = ['id'];
            }

            searchedVal.current = actualSearchVal;

            const searchPayload = {
                searchables: generateSearchables(searchKeys, actualSearchVal),
                includes,
                filters: { scope: scopes, ...defaultFilters },
                page: 1,
                orderBy: orderByField,
                direction: orderByDirection,
            };
            const [, succ] = await to(
                start(searchPayload),
                // customListSearch
                //     ? (customListSearch(searchPayload) as unknown as ReturnType<typeof start>)
                //     : start(searchPayload),
            );

            if (Array.isArray(succ?.data?.data)) {
                setAllUniqueEntities(curr =>
                    uniqWith(
                        (entity1: BaseEntity, entity2: BaseEntity) => entity1.id === entity2.id,
                        // @ts-ignore
                    )([...succ?.data?.data, ...curr]),
                );
            }
        },
        [
            start,
            requestState,
            actualSearchColumns,
            disableSearchFieldIdFallback,
            includes,
            scopes,
            defaultFilters,
            orderByField,
            orderByDirection,
            alterSearchTerm,
        ],
    );

    const prevDefaultFilters = usePrevious(defaultFilters);

    useEffect(() => {
        if (!equal(prevDefaultFilters, defaultFilters)) {
            triggerSearch(inputVal, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevDefaultFilters, defaultFilters]);

    const onSearchTextChanged = useCallback(
        (event, value: string, reason: AutocompleteInputChangeReason, force = false) => {
            if (reason === 'reset') {
                return;
            }

            // if (multiple) {
            setInputVal(value ?? '');
            // }
            if ((value && value.length > 0) || force) {
                doSearch(() => triggerSearch(value, force));
            }
        },
        [doSearch, triggerSearch],
    );

    const prevRequestState = usePrevious(requestState);
    useEffect(() => {
        /**
         * Trigger search again if the search value changed while the search was in progress
         */
        if (
            requestState === NetworkRequestState.Idle ||
            prevRequestState === NetworkRequestState.InProgress
        ) {
            if (inputVal !== searchedVal.current) {
                triggerSearch(inputVal);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState, triggerSearch, inputVal]);

    const onValueChanged = useCallback<AutocompleteProps['onChange']>(
        (val, reason) => {
            onChange(val, reason);

            if (multiple) {
                // set the search term to empty when an option is selected.
                // feels weird if the old search value is still there
                setInputVal('');
            } else {
                setInputVal(optionLabels[val]);
            }

            if (reason === 'clear' && autoLoadSearchTerm != null) {
                triggerSearch(autoLoadSearchTerm, true);
            }
        },
        [onChange, multiple, autoLoadSearchTerm, optionLabels, triggerSearch],
    );

    useEffect(() => {
        if (autoLoadSearchTerm != null) {
            // used to make a search request on component mount so that a list of entities is immediately available
            triggerSearch(autoLoadSearchTerm, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actualInputValue = useMemo(() => {
        if (!multiple && inputVal == null) {
            return '';
        }
        return inputVal;
    }, [inputVal, multiple]);

    const customRenderOption = useCallback<AutocompleteProps['renderOption']>(
        (optionProps, option, state) => {
            const entity = keyedEntities?.[option.value];

            if (entity) return renderOption(optionProps, option, entity, t);

            return <></>;
        },
        [keyedEntities, renderOption, t],
    );

    return (
        <Autocomplete
            ref={ref}
            options={allOptions}
            value={value ?? (multiple ? null : '')}
            onInputChange={onSearchTextChanged}
            loading={requestState === NetworkRequestState.InProgress}
            inputValue={actualInputValue}
            multiple={multiple}
            onChange={onValueChanged}
            alterSearchTerm={alterSearchTerm}
            renderOption={renderOption ? customRenderOption : undefined}
            {...props}
        />
    );
}

export default forwardRef(EntityAutocomplete);
