import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_LineItemLineItemType, Displays_LineItem } from 'c-sdk';
import { Chip } from 'c-components';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

type Props = RenderColumnDataProps<Displays_LineItem>;

/**
 * We're assuming this component will be used in on a page which includes the `resolutions` relationship on line items.
 */
const LineItemResolutionsCell: React.FC<Props> = ({ entity }) => {
    const isSiteGroup = useMemo(
        () => entity?.type === Displays_LineItemLineItemType.SiteGroup,
        [entity],
    );
    const resolutions = useMemo(
        () =>
            [...(entity?.resolutions ?? [])]
                ?.sort((a, b) => (b?.total ?? 0) - (a?.total ?? 0))
                ?.map(res => `${res.width}x${res.height}`) ?? [],
        [entity],
    );
    const extraResolutions = useMemo(
        () => (resolutions.length > 1 ? resolutions.slice(1) : []),
        [resolutions],
    );

    if (isSiteGroup && extraResolutions.length > 0) {
        return (
            <Stack gap={0.5} flexDirection="row" alignItems="center">
                {resolutions[0]}
                <Tooltip
                    title={
                        <>
                            {extraResolutions.map(res => (
                                <Typography key={res} variant="body2">
                                    {res}
                                </Typography>
                            ))}
                        </>
                    }
                >
                    <Box>
                        <Chip label={`+${extraResolutions.length}`} size="small" color="primary" />
                    </Box>
                </Tooltip>
            </Stack>
        );
    }

    return <>{resolutions.join(', ')}</>;
};

export default LineItemResolutionsCell;
