import { createTransform } from 'redux-persist';
import { BaseSliceInitialState, NetworkRequestState } from '../../Types';

const excludeReducerNetworkStateData = ({
    loadingById = false,
    updatingById = false,
    deletingById = false,
    create = false,
    newestCreatedId = false,
    pagination = false,
}: {
    loadingById?: boolean;
    updatingById?: boolean;
    deletingById?: boolean;
    create?: boolean;
    newestCreatedId?: boolean;
    pagination?: boolean;
}) =>
    createTransform((inbound: BaseSliceInitialState<any>) => {
        // only want to tamper with base entity slices
        if (inbound.isEntitySlice) {
            let newPagination = inbound.pagination;

            if (pagination) {
                newPagination = Object.keys(inbound.pagination).reduce(
                    (acc, curr) => ({
                        ...acc,
                        [curr]: {
                            ...inbound.pagination[curr],
                            loadingState: { state: NetworkRequestState.Idle },
                        },
                    }),
                    {} as BaseSliceInitialState<any>['pagination'],
                );
            }

            return {
                ...inbound,
                loadingById: loadingById ? {} : inbound.loadingById,
                updatingById: updatingById ? {} : inbound.updatingById,
                deletingById: deletingById ? {} : inbound.deletingById,
                create: create ? { state: NetworkRequestState.Idle } : inbound.create,
                newestCreatedId: newestCreatedId ? undefined : inbound.newestCreatedId,
                pagination: newPagination,
            } as BaseSliceInitialState<any>;
        }

        return inbound;
    });

export default excludeReducerNetworkStateData;
