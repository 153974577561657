import { BaseEntity } from '../../BaseEntity';
import { Campaign } from './Campaign';
import { Agency } from './Agency';
import { Resolution } from './Resolution';
import { File } from './File';
import { CreativeGroup } from './CreativeGroup';
import { Displays_LineItem } from '../Displays';

export type Creative = BaseEntity & {
    resolution_id?: number;
    agency_id?: number;
    owner_id?: number;
    campaign_id?: number;
    file_id?: number;
    note_id?: number;
    user_id?: number;
    stream: number;
    progress: number;
    setting: number;
    type: CreativeType;
    name: string;
    name_original: string;
    order: number;
    creative_count: number;
    display_count: number;
    favourites: number;
    subscribes: number;

    live_date?: string;
    end_date?: string;

    // absolute url to download the file
    absolute_url?: string;

    // @see CreativeGroup
    group_id?: number;

    campaigns?: Campaign[];
    agency?: Agency[];
    resolution?: Resolution;
    file?: File;

    // include
    creativeGroups?: CreativeGroup[];

    // include
    creativeGroupIds?: number[];

    // include
    lineItems?: Displays_LineItem[];
    // include
    lineItemsCount?: number;
};

export enum CreativeType {
    Group = 'group',
    File = 'file',
    Image = 'image',
    Video = 'video',
}
