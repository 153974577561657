import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { Market, PermissionName } from 'c-sdk';
import { marketsIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import * as yup from 'yup';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    CreateEntityButton,
    SelectField,
    TextField,
} from 'c-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';
import MarketControlsCell from './MarketControlsCell';

const paginationTag = 'market-list-search';

const columns = [
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.id',
        field: 'id',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.name',
        field: 'name',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.country',
        field: 'country.name',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.currency',
        field: 'currency.name',
        entityName: 'Market',
    }),
    generateColumn<Market>({
        headerTitle: '',
        field: 'controls',
        entityName: 'Market',
        RenderCell: MarketControlsCell,
    }),
];
const textSearchCols = ['name'];

const schema = yup.object({
    name: yup.string().required(),
});

const MarketListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onMarketCreated = useCallback(() => {
        ref?.current?.search();
    }, []);
    const t = useCommonTranslation();
    return (
        <FullPageEntityTableWrapper
            title="Modules.Admin.MarketList.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<Market>
                        key="new-market-btn"
                        entityName="Market"
                        title="Modules.Admin.MarketList.create.newMarketButton"
                        buttonLabel={t('Modules.Admin.MarketList.create.newMarketButton')}
                        submitLabel="Modules.Admin.MarketList.create.submitButton"
                        form={<NewMarketForm />}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        permissions={[PermissionName.UniledportalMarketCreate]}
                        onSuccess={onMarketCreated}
                    />,
                ],
                [onMarketCreated, t],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                ref={ref}
                columns={columns}
                baseEntityName="Market"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                includes={['owners', 'currency', 'country']}
            />
        </FullPageEntityTableWrapper>
    );
};

const NewMarketForm = () => {
    const t = useCommonTranslation();
    return (
        <AutoGrid xs={6} spacing={2} pb={2}>
            <ControlledFormInput
                name="name"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysMarketEdit.fields.name')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="country_id"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Country"
                        labelField="name"
                        label={t('Modules.Displays.DisplaysMarketEdit.fields.country')}
                        useLocalEntityData
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="owners"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Owner"
                        labelField="name"
                        multi
                        label={t('Modules.Displays.DisplaysMarketEdit.fields.owners')}
                        useLocalEntityData
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="logo"
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.DisplaysMarketEdit.fields.logo')}
                        {...field}
                    />
                )}
            />
            <ControlledFormInput
                name="status"
                render={({ field }) => (
                    <SelectField
                        label={t('Modules.Displays.DisplaysMarketEdit.fields.status')}
                        {...field}
                        value={field.value}
                        fullWidth
                        options={[
                            {
                                value: 0,
                                label: t('Modules.Displays.DisplaysMarketEdit.fields.inactive'),
                            },
                            {
                                value: 1,
                                label: t('Modules.Displays.DisplaysMarketEdit.fields.active'),
                            },
                        ]}
                    />
                )}
            />
        </AutoGrid>
    );
};

const setup: PageConfig = {
    id: 'MarketListPage',
    component: MarketListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Admin.MarketList.metaTitle',
        description: 'Modules.Admin.MarketList.description',
        icon: marketsIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.marketListPage',
        route: PostAuthRoutes.Admin.MarketList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.MarketList.metaTitle' },
        ],
        permissions: [
            PermissionName.UniledportalMarketRead,
            PermissionName.UniledportalMarketUpdate,
            PermissionName.UniledportalMarketCreate,
        ],
    },
};

export default setup;
