import React from 'react';

export type TableContextValues = {
    spacedOutRows?: boolean;
    boxShadow?: number;
};

export const TableContext = React.createContext<TableContextValues>({
    spacedOutRows: false,
    boxShadow: 2,
});
