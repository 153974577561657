import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { agencyIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { PermissionName, Agency } from 'c-sdk';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { useCommonTranslation } from 'c-translation';
import { Stack } from '@mui/material';
import {
    AllEntitiesDropdown,
    ControlledFormInput,
    CreateEntityButton,
    TextField,
} from 'c-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactGA from 'react-ga';
import AgencyControlsCell from './AgencyControlsCell';

const paginationTag = 'agency-list-home-search';

const columns = [
    generateColumn<Agency>({
        headerTitle: 'Modules.Main.Agency.List.table.idCol',
        field: 'id',
        entityName: 'Agency',
        orderable: true,
    }),
    generateColumn<Agency>({
        headerTitle: 'Modules.Main.Agency.List.table.nameCol',
        field: 'name',
        entityName: 'Agency',
        orderable: true,
    }),
    generateColumn<Agency>({
        headerTitle: 'Modules.Main.Agency.List.table.campaignCount',
        field: 'campaignsCount',
        entityName: 'Agency',
        orderable: true,
        permissions: [PermissionName.UniledportalCampaignRead],
    }),
    generateColumn<Agency>({
        headerTitle: 'Modules.Main.Agency.List.table.marketCol',
        field: 'market.name',
        entityName: 'Agency',
        filterKeyPath: 'market.id',
        permissions: [PermissionName.UniledportalMarketRead],
    }),
    generateColumn<Agency>({
        headerTitle: '',
        field: 'controls',
        entityName: 'Agency',
        RenderCell: AgencyControlsCell,
        permissions: [PermissionName.UniledportalAgencyUpdate],
    }),
];

const includes = ['campaignsCount', 'market'];
const textSearchCols = ['name'];
const schema = yup.object({
    name: yup.string().required(),
    market_id: yup.number().required(),
});

const AgencyListPage = () => {
    useEffect(() => {
        // Track a page view
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onAgencyCreated = useCallback(() => {
        ref?.current?.search();
    }, []);
    const t = useCommonTranslation();
    return (
        <FullPageEntityTableWrapper
            title="Modules.Main.Agency.List.listTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<Agency>
                        key="new-agency-btn"
                        entityName="Agency"
                        title="Modules.Main.Agency.List.newAgencyButton"
                        buttonLabel={t('Modules.Main.Agency.List.newAgencyButton')}
                        submitLabel="Modules.Main.Agency.List.newAgencySubmit"
                        form={<NewAgencyForm />}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        permissions={[PermissionName.UniledportalAgencyCreate]}
                        onSuccess={onAgencyCreated}
                    />,
                ],
                [onAgencyCreated, t],
            )}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="Agency"
                includes={includes}
                showFilters
                // routeTemplate={PostAuthRoutes.Agency.AgencyOverview}
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
            />
        </FullPageEntityTableWrapper>
    );
};
const NewAgencyForm = () => {
    const t = useCommonTranslation();
    return (
        <Stack gap={2}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Main.Agency.List.update.nameLabel')}
                        {...field}
                        autoFocus
                    />
                )}
                name="name"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Market"
                        labelField="name"
                        label={t('Modules.Main.Agency.List.update.marketLabel')}
                        {...field}
                    />
                )}
                name="market_id"
            />
        </Stack>
    );
};

const setup: PageConfig = {
    id: 'AgencyListPage',
    component: AgencyListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        icon: agencyIcon,
        title: 'Modules.Main.Agency.List.metaTitle',
        description: 'Modules.Main.Agency.List.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.agencyListPage',
        route: PostAuthRoutes.Agency.AgencyList,
        breadcrumbs: [],
        saveAsRecentPage: true,
        permissions: [
            PermissionName.UniledportalAgencyRead,
            PermissionName.UniledportalAgencyCreate,
            PermissionName.UniledportalAgencyUpdate,
        ],
    },
};

export default setup;
