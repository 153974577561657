import React, { useMemo } from 'react';
import { Box, DialogProps, Typography } from '@mui/material';
import { RenderColumnDataProps } from 'c-pagination';
import { PopDisplays_LogFileImport } from 'c-sdk';
import { AutoGrid, Button, Dialog } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import LogFileImportDetails from '../LogFileImportDetails';

type Props = RenderColumnDataProps<PopDisplays_LogFileImport>;

const dialogProps: Partial<DialogProps> = { maxWidth: 'xl', fullWidth: true };

const LogFileImportFileNameCell: React.FC<Props> = ({ defaultContent, entity }) => {
    const { toggle: toggleDialog, setFalse: hideDialog, value: showDialog } = useBoolean(false);

    const t = useCommonTranslation();
    const dialogTitle = useMemo(
        () => (
            <>
                <>
                    {entity.file}
                    <AutoGrid GridItemProps={{ xs: 'auto' }} spacing={2}>
                        <Typography variant="body2" color="textSecondary">
                            {t('Modules.PopDisplays.LogFileImport.table.idCol')}: {entity.id}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {t('Modules.PopDisplays.LogFileImport.table.typeCol')}: {entity.type}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {t('Modules.PopDisplays.LogFileImport.table.statusCol')}:{' '}
                            {entity.status}
                        </Typography>
                    </AutoGrid>
                </>
            </>
        ),
        [entity, t],
    );
    return (
        <Box>
            <Button variant="text" onClick={toggleDialog}>
                {defaultContent}
            </Button>
            <Dialog
                MuiDialogProps={dialogProps}
                onClose={hideDialog}
                show={showDialog}
                title={dialogTitle}
                removeForm
                content={
                    <>
                        {showDialog && (
                            <Box height="75vh" display="flex" overflow="hidden">
                                <LogFileImportDetails logFileImportId={entity.id} />
                            </Box>
                        )}
                    </>
                }
            />
        </Box>
    );
};

export default LogFileImportFileNameCell;
