import { BaseEntity } from '../../BaseEntity';
import { Displays_FrameType } from './Displays_FrameType';
import { Displays_Partner } from './Displays_Partner';
import { Displays_Market } from './Displays_Market';
import { Displays_Owner } from './Displays_Owner';
import { Displays_Pack } from './Displays_Pack';
import { Displays_Resolution } from './Displays_Resolution';
import { Displays_FileType } from './Displays_FileType';
import { Displays_LineItem } from './Displays_LineItem';
import { Displays_Tag } from './Displays_Tag';
import { Displays_OperationalTime } from './Displays_OperationalTime';
import { Displays_Vendor } from './Displays_Vendor';
import { Displays_AudienceVersion } from './Displays_AudienceVersion';

export enum Displays_ScreenType {
    Display = 'Display',
    Logo = 'Logo',
}

export enum Displays_ScreenAnimationType {
    Static = 'Static',
    Motion = 'Motion',
    Subtle = 'Subtle',
}

export type Displays_Screen = BaseEntity & {
    ext_id?: string;

    type: Displays_ScreenType;
    description?: string;
    brochurelink?: string;
    env?: string;

    // strintiger
    slots?: string;
    // strinteger
    sov?: string;
    // strinteger
    slot_length?: string;
    animation?: Displays_ScreenAnimationType;
    size?: string;
    sales_code?: string;
    // strinteger
    surface_area?: string;
    // strinteger
    height?: string;
    // strinteger
    width?: string;
    brochure?: string;
    brochure_file?: File;
    location?: string;
    address?: string;
    postcode?: string;
    lat?: string;
    long?: string;
    town?: string;
    tv_region?: string;
    conurbation?: string;
    channel?: string;
    frame?: string;

    frame_id?: string;
    previous_frame_id?: string;

    unilive_id?: number;

    partner_id?: number;
    market_id?: number;
    frame_type_id?: number;

    partner?: Displays_Partner;
    market?: Displays_Market;
    frameType?: Displays_FrameType;

    mediaOwners?: Displays_Owner[];

    packs?: Displays_Pack[];

    // include
    resolutions?: Displays_Resolution[];

    // include
    fileTypes?: Displays_FileType[];

    // include
    lineItem?: Displays_LineItem;
    // include
    tags?: Displays_Tag[];

    operationalTimes?: Displays_OperationalTime[];
    // include
    displaysVendorIds?: Displays_Vendor[];

    // include
    audienceVersion: Displays_AudienceVersion[];
};
type FrameSearchScreen = Displays_Screen & {
    line_item_name: string;
    status: number;
};
type Conflict = {
    vendor_id: string;
    options: FrameSearchScreen[];
};

export type FrameSearchScreens = {
    screens: FrameSearchScreen[];
    missing: string[];
    conflicts: Conflict[];
};
