import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllEntities, BaseEntity } from 'c-sdk';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { hookUtilGetById } from './utils';
import { ByIdNetworkRequests, NetworkRequestState } from '../Types/network';

const emptyLoadingById = () => ({} as ByIdNetworkRequests);
const emptyKeyedEntities = () => ({} as Record<number, any>);

const useEntityData = <RootState, Entity extends BaseEntity>(entityName: keyof AllEntities) => {
    const dispatch = useDispatch();
    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const sliceSelectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);

    const loadingById = useSelector(sliceSelectors?.loadingByIdMap ?? emptyLoadingById);
    const getLoadingById = useCallback(
        (id: number) => loadingById[id] ?? { state: NetworkRequestState.Idle },
        [loadingById],
    );
    const upsertEntity = useCallback(
        (entity: Entity) => dispatch(thunks.upsertEntity(entity)),
        [dispatch, thunks],
    );

    const upsertEntities = useCallback(
        (entities: Entity[]) => dispatch(thunks.upsertEntities(entities)),
        [dispatch, thunks],
    );

    const keyedEntities = useSelector(
        sliceSelectors?.selectEntities ?? emptyKeyedEntities,
    ) as Record<number, Entity>;

    const getById = useCallback(
        ({
            id,
            forceRefresh = false,
            includes,
        }: {
            id: number;
            forceRefresh?: boolean;
            includes?: string[];
        }) => {
            if (id == null) {
                return null;
            }
            return hookUtilGetById<RootState, Entity>(thunks.getById)(
                id,
                forceRefresh ? {} : keyedEntities,
                getLoadingById,
                dispatch,
                includes,
            ) as Entity | undefined | null;
        },
        [keyedEntities, thunks, dispatch, getLoadingById],
    );
    const resetLoadingByIdState = useCallback(
        (id: number) => dispatch(thunks.resetLoadingByIdState(id)),
        [thunks, dispatch],
    );

    return useMemo(
        () => ({
            keyedEntities,

            getById,
            resetLoadingByIdState,
            loadingById,
            getLoadingById,

            upsertEntity,
            upsertEntities,
        }),
        [
            keyedEntities,
            getById,
            resetLoadingByIdState,
            loadingById,
            getLoadingById,
            upsertEntity,
            upsertEntities,
        ],
    );
};

export default useEntityData;
