import { stringOnlyNumbers } from 'c-lib';
import { ListSearchOptions } from 'c-sdk';

const generateSearchColumnsData = (
    searchColumns: string[],
    term: string,
    revertToIdSearchOnNumberOnlyInput = true,
): ListSearchOptions['searchables'] => {
    if (term == null) {
        return {};
    }

    if (stringOnlyNumbers(term) && revertToIdSearchOnNumberOnlyInput) {
        return { [`search.id`]: term };
    }

    return searchColumns.reduce((acc, column) => {
        acc[`search.${column}`] = term;
        return acc;
    }, {});
};

export default generateSearchColumnsData;
