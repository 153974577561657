import React, { useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Translate } from 'c-translation';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { Skin } from 'c-entity-types';
import { PermissionName } from 'c-sdk';
import { ControlledFormInput, CreateEntityButton, TextField } from 'c-components';
import { newSkinDefaults } from 'c-admin/Lib';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { skinIcon } from 'c-main/icons';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

const paginationTag = 'skin-list-search';

const columns = [
    generateColumn<Skin>({
        headerTitle: 'Modules.Admin.SkinsList.table.idCol',
        field: 'id',
        entityName: 'Skin',
        orderable: true,
    }),
    generateColumn<Skin>({
        headerTitle: 'Modules.Admin.SkinsList.table.nameCol',
        field: 'name',
        entityName: 'Skin',
        orderable: true,
    }),
];

const schema = yup.object({
    name: yup.string().required(),
});

const textSearchCols = ['name'];

const SkinListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <FullPageEntityTableWrapper
            title="Modules.Admin.SkinsList.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<Skin>
                        key="new-skin-btn"
                        entityName="Skin"
                        title="Modules.Admin.SkinsList.create.newSkinButton"
                        buttonLabel={
                            <Translate path="Modules.Admin.SkinsList.create.newSkinButton" />
                        }
                        submitLabel="Modules.Admin.SkinsList.create.submitButton"
                        form={<NewSkinForm />}
                        defaultData={newSkinDefaults}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        redirectPath={PostAuthRoutes.Admin.EditSkin}
                        permissions={[PermissionName.UniledportalSkinCreate]}
                    />,
                ],
                [],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="Skin"
                routeTemplate={PostAuthRoutes.Admin.EditSkin}
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
            />
        </FullPageEntityTableWrapper>
    );
};

const NewSkinForm = () => (
    <>
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={<Translate path="Modules.Admin.SkinsList.create.nameLabel" />}
                    {...field}
                    autoFocus
                />
            )}
            name="name"
        />
    </>
);

const setup: PageConfig = {
    id: 'SkinListPage',
    component: SkinListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Admin.SkinsList.metaTitle',
        description: 'Modules.Admin.SkinsList.description',
        icon: skinIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.skinListPage',
        route: PostAuthRoutes.Admin.SkinList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.SkinsList.metaTitle' },
        ],
        permissions: [
            PermissionName.UniledportalSkinRead,
            PermissionName.UniledportalSkinUpdate,
            PermissionName.UniledportalSkinCreate,
        ],
    },
};

export default setup;
