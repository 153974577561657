import { AllEntities, API, BaseEntity, ListSearchResponse } from 'c-sdk';
import { AxiosResponse } from 'axios';
import { CoreThunk } from '../../Types/CoreThunk';
import { SearchPayload } from '../../Types/Search';
import { getBaseSliceSelectors } from '../../Data/EntitySchemaActions';
import { normaliseDataThunks } from '../../Data/data-thunks';
import {
    callApi,
    callFakeApiCreate,
    callFakeApiDeleteById,
    callFakeApiGetById,
    callFakeApiGetPaginatedList,
    callFakeApiUpdateById,
    errorMessageFromResponse,
    FakeApi,
    validationErrorsFromResponse,
} from '../../lib';
import {
    BaseCaseReducers,
    BaseSliceInitialState,
    EntitySchemaType,
    NetworkRequestState,
} from '../../Types';
import { BaseSliceDefaultThunks } from '../../Types/thunks';

const baseThunks =
    <RootState>(entitySchemas: EntitySchemaType, apiClient: API) =>
    <Entity extends BaseEntity>(
        entityName: keyof AllEntities,
        actions: BaseCaseReducers<Entity, BaseSliceInitialState<Entity>, any>,
        fakeApi?: FakeApi<Entity>,
    ): BaseSliceDefaultThunks<RootState, Entity> => {
        if (process.env.NODE_ENV === 'development') {
            if (typeof apiClient.Entities[entityName]?.get !== 'function') {
                // eslint-disable-next-line no-console
                console.warn(`'get' method missing for ${entityName} in 'ts-api-sdk'`);
            }
            if (typeof apiClient.Entities[entityName]?.update !== 'function') {
                // eslint-disable-next-line no-console
                console.warn(`'update' method missing for ${entityName} in 'ts-api-sdk'`);
            }
            if (typeof apiClient.Entities[entityName]?.create !== 'function') {
                // eslint-disable-next-line no-console
                console.warn(`'create' method missing for ${entityName} in 'ts-api-sdk'`);
            }
        }

        return {
            resetLoadingByIdState:
                (id: number): CoreThunk<RootState> =>
                async dispatch => {
                    dispatch(actions.resetLoadingByIdState({ id }));
                },
            resetUpdatingByIdState:
                (id: number): CoreThunk<RootState> =>
                async dispatch => {
                    dispatch(actions.resetUpdatingByIdState({ id }));
                },
            resetCreateState: (): CoreThunk<RootState> => async dispatch => {
                dispatch(actions.resetCreateState());
            },
            resetDeletingByIdState:
                (id: number): CoreThunk<RootState> =>
                async dispatch => {
                    dispatch(actions.resetDeletingByIdState({ id }));
                },
            getById:
                (id: number, includes?: string[]): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    if (!fakeApi?.useFake && apiClient.Entities[entityName]?.get == null) {
                        // Not all entities have their own get endpoint because they are received
                        // as children of other entities and stored through the normalisation process
                        // soooo preventing any fetching being done for those entities which don't
                        // have endpoints configured
                        return;
                    }
                    if (id === 0) {
                        return;
                    }
                    const selectors = getBaseSliceSelectors<Entity>(entityName);
                    if (
                        selectors.loadingByIdState(getState(), id) ===
                        NetworkRequestState.InProgress
                    ) {
                        return;
                    }

                    dispatch(actions.startLoadingById({ id }));

                    const [error, data, response] = await (fakeApi?.useFake
                        ? callFakeApiGetById<Entity>({
                              id,
                              entityName,
                              ...fakeApi,
                          })
                        : dispatch(
                              callApi(
                                  apiClient.Entities[entityName].get(id, includes)
                                      .request as unknown as Promise<
                                      AxiosResponse<{ data: Entity }>
                                  >,
                              ),
                          ));
                    const status = response?.status ?? error?.response?.status;
                    if (data) {
                        dispatch(
                            normaliseDataThunks.updateWithNormalisedData(
                                Array.isArray(data.data)
                                    ? [entitySchemas[entityName]]
                                    : entitySchemas[entityName],
                                data.data,
                            ),
                        );
                        dispatch(actions.finishedLoadingById({ entity: data.data, status }));
                        return;
                    }

                    dispatch(
                        actions.failedLoadingById({
                            id,
                            error: errorMessageFromResponse(error),
                            status,
                        }),
                    );
                },
            getPaginatedList:
                (
                    tag: string,
                    searchPayload: SearchPayload<Entity>,
                    cursorPaginated,
                    customListFunction,
                ): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    const selectors = getBaseSliceSelectors<Entity>(entityName);
                    const paginationData = selectors.paginationData(getState(), tag);
                    if (
                        paginationData &&
                        paginationData.loadingState.state == NetworkRequestState.InProgress
                    ) {
                        return;
                    }
                    dispatch(
                        actions.startLoadingPaginatedList({
                            ...searchPayload,
                            tag,
                        }),
                    );

                    const listFunction = () => {
                        if (fakeApi?.useFake) {
                            return callFakeApiGetPaginatedList<Entity>({
                                page: searchPayload.page,
                                searchPayload,
                                entityName,
                                ...fakeApi,
                            });
                        }

                        if (customListFunction) {
                            return dispatch(callApi(customListFunction(searchPayload).request));
                        }

                        if (cursorPaginated) {
                            return dispatch(
                                callApi(
                                    apiClient.Entities[entityName].cursor(searchPayload)
                                        .request as unknown as Promise<
                                        AxiosResponse<ListSearchResponse<Entity>>
                                    >,
                                ),
                            );
                        }

                        return dispatch(
                            callApi(
                                apiClient.Entities[entityName].list(searchPayload)
                                    .request as unknown as Promise<
                                    AxiosResponse<ListSearchResponse<Entity>>
                                >,
                            ),
                        );
                    };

                    const [error, success] = await listFunction();

                    if (!error) {
                        dispatch(
                            normaliseDataThunks.updateWithNormalisedData(
                                [entitySchemas[entityName]],
                                success.data,
                            ),
                        );
                        dispatch(
                            actions.finishedLoadingPaginatedList({
                                ...success,
                                tag,
                                wasCursorRequest: cursorPaginated,
                            }),
                        );
                        return;
                    }

                    dispatch(
                        actions.failedLoadingPaginatedList({
                            error: errorMessageFromResponse(error),
                            tag,
                        }),
                    );
                },
            resetPaginatedList:
                (tag: string): CoreThunk<RootState> =>
                async dispatch => {
                    dispatch(actions.resetPaginatedList({ tag }));
                },
            resetPaginatedListFilters: (tag, value) => async dispatch => {
                dispatch(actions.resetPaginatedListFilters({ tag, value }));
            },
            updatePaginatedListFilter: (tag, key, value) => async dispatch => {
                dispatch(actions.updatePaginatedListFilter({ tag, key, value }));
            },
            togglePaginatedListFilterValue: (tag, key, value) => async (dispatch, getState) => {
                dispatch(actions.togglePaginatedListFilterValue({ tag, key, value }));
            },
            updateById:
                (id: number, data: Partial<Entity>, includes?: string[]): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    const selectors = getBaseSliceSelectors<Entity>(entityName);
                    if (
                        selectors.updatingByIdState(getState(), id) ===
                        NetworkRequestState.InProgress
                    ) {
                        return;
                    }

                    dispatch(actions.startUpdatingById({ id }));

                    const [error, success] = await (fakeApi?.useFake
                        ? callFakeApiUpdateById<Entity>({
                              id,
                              data,
                              entityName,
                              ...fakeApi,
                          })
                        : dispatch(
                              callApi(
                                  apiClient.Entities[entityName].update(
                                      id as any,
                                      data as any,
                                      includes,
                                  ).request as unknown as Promise<AxiosResponse<{ data: Entity }>>,
                              ),
                          ));

                    if (success) {
                        dispatch(
                            normaliseDataThunks.updateWithNormalisedData(
                                entitySchemas[entityName],
                                success.data,
                            ),
                        );
                        dispatch(actions.finishedUpdatingById(success.data));
                        return;
                    }

                    dispatch(
                        actions.failedUpdatingById({
                            id,
                            error: errorMessageFromResponse(error),
                            validation: validationErrorsFromResponse(error),
                        }),
                    );
                },
            create:
                (entity: Partial<Entity>, includes?: string[]): CoreThunk<RootState> =>
                async (dispatch, getState: () => RootState) => {
                    const selectors = getBaseSliceSelectors<Entity>(entityName);
                    // @ts-ignore
                    if (selectors.creatingState(getState()) === NetworkRequestState.InProgress) {
                        return;
                    }

                    dispatch(actions.startCreating());

                    const [error, success] = await (fakeApi?.useFake
                        ? callFakeApiCreate<Entity>({
                              entity,
                              entityName,
                              ...fakeApi,
                          })
                        : dispatch(
                              callApi(
                                  apiClient.Entities[entityName].create(entity as any, includes)
                                      .request as unknown as Promise<
                                      AxiosResponse<{ data: Entity }>
                                  >,
                              ),
                          ));

                    if (success) {
                        dispatch(
                            normaliseDataThunks.updateWithNormalisedData(
                                entitySchemas[entityName],
                                success.data,
                            ),
                        );
                        dispatch(actions.finishedCreating(success.data));
                        return;
                    }

                    dispatch(
                        actions.failedCreating({
                            error: errorMessageFromResponse(error),
                            validation: validationErrorsFromResponse(error),
                        }),
                    );
                },
            deleteById:
                (id: number): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    const selectors = getBaseSliceSelectors<Entity>(entityName);
                    if (
                        selectors.deletingByIdState(getState(), id) ===
                        NetworkRequestState.InProgress
                    ) {
                        return;
                    }

                    dispatch(actions.startDeletingById({ id }));

                    const [error, success] = await (fakeApi?.useFake
                        ? callFakeApiDeleteById<Entity>({
                              id,
                              entityName,
                              ...fakeApi,
                          })
                        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          dispatch(callApi(apiClient.Entities[entityName].delete(id).request)));

                    if (success) {
                        dispatch(actions.finishedDeletingById({ id }));
                        return;
                    }

                    dispatch(
                        actions.failedDeletingById({
                            id,
                            error: errorMessageFromResponse(error),
                        }),
                    );
                },
            upsertEntities:
                (entities): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    dispatch(
                        normaliseDataThunks.updateWithNormalisedData(
                            [entitySchemas[entityName]],
                            entities,
                        ),
                    );
                },
            upsertEntity:
                (entity): CoreThunk<RootState> =>
                async (dispatch, getState) => {
                    dispatch(
                        normaliseDataThunks.updateWithNormalisedData(
                            entitySchemas[entityName],
                            entity,
                        ),
                    );
                },
        };
    };

export default baseThunks;
