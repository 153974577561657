import { useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import config, { PageEntityConfig } from 'c-config';
import { NetworkRequestState } from 'c-data-layer';
import {
    Filter,
    ParsedSystemSearchConfiguration,
    SystemSearchEntityResource,
    SystemSearchResourceOrEntity,
} from 'c-system-search';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { useCommonTranslation } from 'c-translation';
import { systemSearchSelectors, systemSearchThunks } from '../Slices/SystemSearch';
import { localSearch } from '../Lib';

function useSystemSearch() {
    const dispatch = useDispatch();
    const entitySearch = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);
    const { hasAll } = useUserPermissions();
    const t = useCommonTranslation();

    /**
     * Filter out system search pages and entities based on the logged in user's permissions
     *
     *
     * Use the selected language to convert search aliases and entity search prefixes to the local language
     */
    const filteredConfig = useMemo<ParsedSystemSearchConfiguration>(
        () => ({
            entityAlways: config?.pages?.find(
                page =>
                    (page as PageEntityConfig<any>)?.systemSearch?.alwaysSearchForEntity === true,
            )?.systemSearch as SystemSearchEntityResource,
            prefixes: Object.entries(config?.systemSearchParsed?.prefixes ?? {}).reduce(
                (acc, [prefixTranslationPath, resources]) => {
                    /**
                     * Translating the prefix search term to the chosen language
                     */
                    acc[t(prefixTranslationPath).toLowerCase()] = (
                        resources.filter(p =>
                            hasAll(p.permissions),
                        ) as unknown as SystemSearchResourceOrEntity[]
                    ).map(res => ({ ...res, prefix: t(prefixTranslationPath) }));
                    return acc;
                },
                {} as ParsedSystemSearchConfiguration['prefixes'],
            ),
            aliases: Object.entries(config?.systemSearchParsed?.aliases ?? {}).reduce(
                (acc, [prefixTranslationPath, resources]) => {
                    /**
                     * Aliases are set as a TranslationPath. See UnTranslatedSystemSearchResourceOrEntity
                     *
                     * We need to convert the translation path to an array of string aliases in the users chosen language
                     * first before we do any page searching. This is done to enable use to set the page aliases per language
                     * instead of it being hardcoded in every possible language in the code.
                     */
                    const aliases = t(prefixTranslationPath);
                    const actualAliases: string[] = Array.isArray(aliases) ? aliases : [aliases];
                    actualAliases.forEach(alias => {
                        // lower casing the aliases because the matching is done on lowercase letters
                        acc[alias.toLowerCase()] = (
                            resources.filter(p =>
                                hasAll(p.permissions),
                            ) as unknown as SystemSearchResourceOrEntity[]
                        ).map(alias => ({ ...alias, aliases: actualAliases }));
                    });
                    return acc;
                },
                {} as ParsedSystemSearchConfiguration['aliases'],
            ),
        }),
        [hasAll, t],
    );

    const search = useCallback(
        (term: string) => {
            const result = localSearch(filteredConfig, term);
            if (result.entities.length > 0 && result.term.length > 0) {
                entitySearch(() => {
                    dispatch(systemSearchThunks.searchEntities(result.term, result.entities));
                });
            }
            return result;
        },
        [dispatch, entitySearch, filteredConfig],
    );

    const results = useSelector(systemSearchSelectors.entityLoadingStates);
    const getEntityResults = useCallback(
        (term: string) => results[term] ?? { state: NetworkRequestState.Idle, results: [] },
        [results],
    );
    const filters = useMemo(
        () =>
            Object.entries(filteredConfig?.prefixes)
                .reduce(
                    (acc, [, val]) => [
                        ...acc,
                        ...val.reduce(
                            (a, { prefix, prefixDescription }) =>
                                prefix && prefixDescription
                                    ? [...a, { prefix, description: prefixDescription }]
                                    : a,
                            [],
                        ),
                    ],
                    [] as Filter[],
                )
                .filter(
                    (entry: Filter, index, self) =>
                        index === self.findIndex(selfEntry => selfEntry.prefix === entry.prefix),
                ),
        [filteredConfig],
    );

    return { search, getEntityResults, filters };
}

export default useSystemSearch;
