import { Buyer } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { BuyerState } from './Buyer-types';

const initialState: BuyerState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: BuyerState, action: PayloadAction<string>): BuyerState => state,
};

const slice = createBaseSlice<Buyer, BuyerState, typeof additionalReducers>({
    name: 'buyer',
    entityName: 'Buyer',
    initialState,
    reducers: additionalReducers,
});

const buyerSlice = {
    buyerActions: slice.slice.actions,
    buyerReducer: slice.slice.reducer,
    buyerAdapter: slice.adapter,
    buyerSelectors: slice.selectors,
    buyerThunks: slice.thunks,
};

export default buyerSlice;
export const { buyerReducer, buyerActions, buyerAdapter, buyerSelectors, buyerThunks } = buyerSlice;
