import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllEntities, BaseEntity } from 'c-sdk';
import { getBaseSliceSelectors, getBaseSliceThunks } from '../Data/EntitySchemaActions';
import { NetworkRequestState } from '../Types/network';

const useUpdateEntityData = <RootState, Entity extends BaseEntity>(
    entityName: keyof AllEntities,
) => {
    const dispatch = useDispatch();
    const thunks = useMemo(() => getBaseSliceThunks<RootState, Entity>(entityName), [entityName]);
    const sliceSelectors = useMemo(() => getBaseSliceSelectors<Entity>(entityName), [entityName]);
    const updatingById = useSelector(sliceSelectors.updatingByIdMap);

    const getUpdatingById = useCallback(
        (id: number) => updatingById[id] ?? { state: NetworkRequestState.Idle },
        [updatingById],
    );

    const update = useCallback(
        (id: number, data: Entity, includes?: string[]) =>
            dispatch(thunks.updateById(id, data, includes)),
        [thunks, dispatch],
    );

    const resetUpdatingById = useCallback(
        (id: number) => dispatch(thunks.resetUpdatingByIdState(id)),
        [thunks, dispatch],
    );

    const upsert = useCallback(
        (entity: Entity) => {
            dispatch(thunks.upsertEntity(entity));
        },
        [dispatch, thunks],
    );

    const upsertMany = useCallback(
        (entities: Entity[]) => {
            dispatch(thunks.upsertEntities(entities));
        },
        [dispatch, thunks],
    );

    return useMemo(
        () => ({
            update,
            getUpdatingById,
            resetUpdatingById,
            upsert,
            upsertMany,
        }),
        [update, getUpdatingById, resetUpdatingById, upsert, upsertMany],
    );
};

export default useUpdateEntityData;
