import { Displays_Tag as TagModel, ModuleConstructorProps } from '../Types';
import { BaseEntityModuleFuncs, operationalHoursFuncs } from './BaseEntityModuleFuncs';

const Displays_Tag = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<TagModel>({ endpoints, httpClient, baseUrl }, 'Displays_Tag'),
    ...operationalHoursFuncs<TagModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Tag.createOperationalTimes,
        endpoints.Entities.Displays_Tag.destroyOperationalTime,
    ),
});

export default Displays_Tag;
