import { AxiosRequestConfig } from 'axios';
import {
    ModuleConstructorProps,
    PopDisplays_VirtualCampaignSummary,
    VirtualCampaignBuilderSummaryPayload,
} from '../Types';

const VirtualCampaignBuilder = ({ endpoints, httpClient }: ModuleConstructorProps) => ({
    summary: (data: VirtualCampaignBuilderSummaryPayload, config?: AxiosRequestConfig) =>
        httpClient.post<{ data: PopDisplays_VirtualCampaignSummary[] }>(
            endpoints.virtualCampaignBuilder.summary,
            data,
            config,
        ),
    standardPlaysExport: (
        data: VirtualCampaignBuilderSummaryPayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ success: boolean; message: string }>(
            endpoints.virtualCampaignBuilder.standardPlaysExport,
            data,
            config,
        ),
    displayPlaysExport: (data: VirtualCampaignBuilderSummaryPayload, config?: AxiosRequestConfig) =>
        httpClient.post<{ success: boolean; message: string }>(
            endpoints.virtualCampaignBuilder.displayPlaysExport,
            data,
            config,
        ),
});

export default VirtualCampaignBuilder;
