import React, { useEffect, useMemo, useState } from 'react';
import { MetaLayout, PreAuthLayout } from 'c-wrapper';
import { NetworkRequestState } from 'c-data-layer';
import { Alert, FlashMessage } from 'c-components';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { usePrevious } from 'react-hanger';
import { Translate, useCommonTranslation } from 'c-translation';
import { authSelectors, authThunks, passwordResetSelectors, passwordResetThunks } from '../Slices';
import LoginForm from '../Components/LoginForm';

const LoginPage: React.FC<{ welcomePage?: boolean }> = ({ welcomePage = false }) => {
    const dispatch = useDispatch();
    const reqState = useSelector(passwordResetSelectors.passwordResetPasswordRequestState);
    const loginState = useSelector(authSelectors.loginState);
    const prevReqState = usePrevious(reqState);
    const [showSuccessMessage, setShowSuccessMessage] = useState(
        reqState === NetworkRequestState.Success,
    );

    useEffect(() => {
        // this is essentially for supporting being logged out after you change your own password
        if (reqState === NetworkRequestState.Success && prevReqState === NetworkRequestState.Idle) {
            setShowSuccessMessage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reqState]);

    useEffect(() => {
        dispatch(authThunks.resetLoginState());
    }, [dispatch]);
    const genericLoginError = useCommonTranslation('Pages.Login.genericLoginError');

    const flashMessage = useMemo(() => {
        if (loginState.state === NetworkRequestState.Error) {
            return (
                <FlashMessage status="error" id="login-error">
                    {loginState.error ?? genericLoginError}
                </FlashMessage>
            );
        }
        if (showSuccessMessage) {
            return (
                <FlashMessage status="success" id="password-reset-success">
                    <Translate path="Pages.Login.passwordResetSuccess" />
                </FlashMessage>
            );
        }

        return null;
    }, [showSuccessMessage, loginState, genericLoginError]);

    useEffect(() => {
        if (showSuccessMessage) {
            dispatch(passwordResetThunks.resetRequestResetPassword());
        }
        return () => {
            dispatch(passwordResetThunks.resetRequestResetPassword());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MetaLayout metaTitle="Pages.Login.metaTitle">
            <PreAuthLayout flashMessage={flashMessage}>
                {welcomePage && (
                    <Box mb={2}>
                        <Alert severity="info" variant="outlined">
                            <Translate path="Pages.Login.welcomeMessage" />
                        </Alert>
                    </Box>
                )}
                <LoginForm />
            </PreAuthLayout>
        </MetaLayout>
    );
};

export default LoginPage;
