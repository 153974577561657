import * as ET from './EntityTypes';

class AllEntities {
    Agency: ET.Agency = undefined;

    Alert: ET.Alert = undefined;

    Buyer: ET.Buyer = undefined;

    BuyerCost: ET.BuyerCost = undefined;

    Campaign: ET.Campaign = undefined;

    CampaignDisplay: ET.CampaignDisplay = undefined;

    CampaignInstruction: ET.CampaignInstruction = undefined;

    CampaignOwnerContactedDate: ET.CampaignOwnerContactedDate = undefined;

    CampaignPopShot: ET.CampaignPopShot = undefined;

    Creative: ET.Creative = undefined;

    CreativeGroup: ET.CreativeGroup = undefined;

    Client: ET.Client = undefined;

    Date: ET.Date = undefined;

    Display: ET.Display = undefined;

    File: ET.File = undefined;

    Market: ET.Market = undefined;

    MissingDisplay: ET.MissingDisplay = undefined;

    Note: ET.Note = undefined;

    Owner: ET.Owner = undefined;

    Pack: ET.Pack = undefined;

    Pca: ET.Pca = undefined;

    Permission: ET.Permission = undefined;

    Post: ET.Post = undefined;

    Request: ET.Request = undefined;

    Resolution: ET.Resolution = undefined;

    Role: ET.Role = undefined;

    Rule: ET.Rule = undefined;

    Schedule: ET.Schedule;

    SpotlightRecentResource: ET.SpotlightRecentResource = undefined;

    Stream: ET.Stream = undefined;

    Skin: ET.Skin = undefined;

    User: ET.User = undefined;

    Displays_AudienceFallback: ET.Displays_AudienceFallback = undefined;

    Displays_AnalogueScreen: ET.Displays_AnalogueScreen = undefined;

    Displays_AudienceUpload: ET.Displays_AudienceUpload = undefined;

    Displays_AudienceSource: ET.Displays_AudienceSource = undefined;

    Displays_AudienceVersion: ET.Displays_AudienceVersion = undefined;

    Displays_Country: ET.Displays_Country = undefined;

    Displays_Currency: ET.Displays_Currency = undefined;

    Displays_DisplayIssues: ET.Displays_DisplayIssues = undefined;

    Displays_FrameType: ET.Displays_FrameType = undefined;

    Displays_Market: ET.Displays_Market = undefined;

    Displays_OperationalTime: ET.Displays_OperationalTime = undefined;

    Displays_Owner: ET.Displays_Owner = undefined;

    Displays_Pack: ET.Displays_Pack = undefined;

    Displays_Partner: ET.Displays_Partner = undefined;

    Displays_Screen: ET.Displays_Screen = undefined;

    Displays_LineItem: ET.Displays_LineItem = undefined;

    Displays_Tag: ET.Displays_Tag = undefined;

    Displays_FileType: ET.Displays_FileType = undefined;

    Displays_Vendor: ET.Displays_Vendor = undefined;

    PopDisplays_ChannelFrame: ET.PopDisplays_ChannelFrame = undefined;

    PopDisplays_Display: ET.PopDisplays_Display = undefined;

    PopDisplays_GraphReportType: ET.PopDisplays_GraphReportType = undefined;

    PopDisplays_JobStatus: ET.PopDisplays_JobStatus = undefined;

    PopDisplays_LogFileImport: ET.PopDisplays_LogFileImport = undefined;

    PopDisplays_LogFileParsed: ET.PopDisplays_LogFileParsed = undefined;

    PopDisplays_LogFileResource: ET.PopDisplays_LogFileResource = undefined;

    PopDisplays_LogImportSource: ET.PopDisplays_LogImportSource = undefined;

    PopDisplays_LogSourceAccount: ET.PopDisplays_LogSourceAccount = undefined;

    PopDisplays_LogSourceCampaignIdentifier: ET.PopDisplays_LogSourceCampaignIdentifier = undefined;

    PopDisplays_Market: ET.PopDisplays_Market = undefined;

    PopDisplays_Media: ET.PopDisplays_Media = undefined;

    Campaign_Chat: ET.Campaign_Chat = undefined;
}

export type AnEntity = AllEntities[keyof AllEntities];

export type EntityName<Entity extends AnEntity> = ({ name: keyof AllEntities } & {
    [key in keyof AllEntities as AllEntities[key] extends Entity ? 'name' : never]: key;
})['name'];

export default AllEntities;
