import React from 'react';
import { EntityListColumn } from 'c-pagination';
import { Campaign } from 'c-sdk';
import CampaignThumbnail from '../CampaignThumbnail';

const CampaignListThumbnailCell: EntityListColumn<Campaign>['RenderCell'] = ({ entity }) => (
    <CampaignThumbnail campaign={entity} />
);

export default CampaignListThumbnailCell;
