import { Displays_Pack as PackModel, ModuleConstructorProps } from '../Types';
import {
    BaseEntityModuleFuncs,
    brochureFuncs,
    operationalHoursFuncs,
} from './BaseEntityModuleFuncs';

const Displays_Pack = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<PackModel>({ endpoints, httpClient, baseUrl }, 'Displays_Pack'),
    ...operationalHoursFuncs<PackModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Pack.createOperationalTimes,
        endpoints.Entities.Displays_Pack.destroyOperationalTime,
    ),
    ...brochureFuncs<PackModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_Pack.brochure,
        endpoints.Entities.Displays_Pack.brochure,
    ),
});

export default Displays_Pack;
