import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient, useUpdateEntityData } from 'c-data';
import to from 'await-to-js';
import { Autocomplete, Button, OptionSchema } from 'c-components';
import { User, Campaign } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import { UseBoolean } from 'react-hanger';
import { Box } from '@mui/material';

type Props = {
    campaignId: number;
    buyerId: number;
    additionalContacts: User[];
    openDialog: UseBoolean;
};

const AdditionalContactDialogDisplay: React.FC<Props> = ({
    campaignId,
    buyerId,
    additionalContacts,
    openDialog,
}) => {
    const [value, setValue] = useState(additionalContacts?.map(user => user.id) ?? []);
    const { start, data } = useAPIClientRequest(apiClient.Entities.Buyer.get);
    const loadSelectedBuyer = useCallback(
        async (buyerId: number) => {
            await to(start(buyerId, ['users']));
        },
        [start],
    );

    const t = useCommonTranslation();

    useEffect(() => {
        if (buyerId != null) {
            // when the currently selected buyer changes, load that buyer's user list if its not null
            loadSelectedBuyer(buyerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyerId]);

    const buyerUsers = useMemo(() => data?.data?.data?.users ?? [], [data]);

    const buyerUserOptions = useMemo<OptionSchema[]>(
        // turn the list of buyer users into an array of OptionSchema objects
        // those objects can then be given to SelectField component
        () => buyerUsers.map(user => ({ label: user.name, value: user.id })),
        [buyerUsers],
    );

    const {
        start: putCampaign,
        data: campaignData,
        isLoading,
    } = useAPIClientRequest(apiClient.Entities.Campaign.syncContacts);

    const { upsert } = useUpdateEntityData<Campaign>('Campaign');

    const saveContacts = useCallback(async () => {
        const [error, response] = await to(putCampaign(campaignId, value, ['additionalContacts']));
        if (error == null) {
            openDialog.setFalse();

            upsert(response.data.data as unknown as Campaign);
        }
    }, [campaignId, openDialog, putCampaign, upsert, value]);

    return (
        <>
            <Box pt={2}>
                <Autocomplete
                    disabled={isLoading}
                    onChange={setValue}
                    id="campaign-additional-contacts"
                    value={value}
                    multiple
                    options={buyerUserOptions}
                    textFieldProps={{
                        label: t(
                            'Modules.Main.Campaigns.DataStatus.contacts.contactsDropDownLabel',
                        ),
                    }}
                />
            </Box>
            <Button disabled={isLoading} onClick={saveContacts}>
                {t('Modules.Main.Campaigns.DataStatus.contacts.saveButton')}
            </Button>
        </>
    );
};

export default AdditionalContactDialogDisplay;
