import { BaseEntity } from '../../BaseEntity';
import { Agency } from './Agency';
import { Creative } from './Creative';
import { Date } from './Date';
import { Market } from './Market';
import { CampaignDisplay } from './CampaignDisplay';
import { Client } from './Client';
import { BudgetSummary } from './BudgetSummary';
import { Displays_DisplayIssues, Displays_LineItem, Displays_Screen } from '../Displays';
import { Owner } from './Owner';
import { PopDisplays_LogSourceCampaignIdentifier } from '../PopDisplays';
import { File } from './File';
import { User } from './User';
import { CampaignInstruction } from './CampaignInstruction';
import { CampaignOwnerContactedDate } from './CampaignOwnerContactedDate';
import { CampaignPopShot } from './CampaignPopShot';
import { MissingDisplay } from './MissingDisplay';

export type Campaign = BaseEntity & {
    stream: number;
    setting: number;
    type: string;
    name: string;
    reference: string;
    text: string;
    data: string;
    dsp_insertion_order_ids?: string[];
    is_vistar: boolean;
    gatherer: boolean;
    scheduler: boolean;
    reviewer: boolean;
    progress: {
        started: boolean;
        displays_selected: boolean;
        displays_approved: boolean;
        displays_changed: boolean;
        owner_approved: boolean;
        owners_approved: boolean;
        creative_uploaded: boolean;
        creative_assigned: boolean;
        creatives_uploaded: boolean;
        creatives_assigned: boolean;
        creatives_changed: boolean;
        agency_approved: boolean;
        creatives_approved: boolean;
        campaign_approved: boolean;
        archived: boolean;
        billed: boolean;
        billed_changed: boolean;
        creatives_invalid: boolean;
        agency_seen: boolean;
    };

    favourites: number;
    subscribes: number;
    seens: number;
    displays_token: string;
    creative_count: number;
    creative_resolution_count: number;
    creative_assigned_count: number;
    display_count: number;
    display_assigned_count: number;
    resolution_count: number;
    analogue_count: number;
    digital_count: number;
    package_count: number;
    rule_count: number;
    resolution_assigned_count: number;
    quotation_creatives: number;
    quotation_resolutions: string;
    quotation_budget: number;
    booking_type: string;
    pca_available?: { [key: string]: string };
    flags: {
        dirty: boolean;
        under_configuration: boolean;
    };

    old_booking_summaries: { name: string; url: string }[];
    awaiting_booking_data?: boolean;

    booking_summary_validated?: boolean;
    booking_summary_validated_updated_by?: number; // user id
    booking_summary_validated_updated_at?: string;
    bookingSummaryValidatedUser?: User; // include

    // include
    bookingSummaries?: CampaignBookingSummary[];

    display_verification_enabled: boolean;
    energy_efficiency_enabled: boolean;

    playout_percentage?: string;
    pca_last_run_at?: string;
    pca_last_run_at_sandbox?: string;

    request_access_agency_id?: number;
    client_id?: number;
    buyer_id?: number;
    agency_id?: number;
    channel_id?: number;
    date_id?: number;
    reminder_display_id?: number;
    reminder_creative_digital_id?: number;
    reminder_creative_analogue_id?: number;
    creative_id?: number;
    stat_id?: number;
    store_id?: number;
    file_id?: number;
    invoice_id?: number;
    owner_id?: number;
    market_id?: number;

    // user who created the campaign
    user_id?: number;

    internal_owner_id?: number;
    internal_assignee_id?: number;

    internal_notes?: string;
    internal_notes_updated_by?: number; // user id
    internal_notes_updated_at?: string;
    internalNotesUser?: User; // include

    internal_rag_status?: CampaignRagStatus;

    high_priority: boolean;

    // indicates whether to forcibly default the campaign to use the mysql PCA endpoints
    pca_mysql?: boolean;

    // yyyy-MM-dd date strings
    internal_planner_aware?: string[];

    sign_off?: boolean;
    sign_off_updated_by?: number; // user id
    sign_off_updated_at?: string;
    signOffUser?: User; // include

    pca_report_requested_by?: number; // user id
    pca_report_requested_at?: string;
    pcaReportUser?: User; // include

    inflight_report_requested_by?: number; // user id
    inflight_report_requested_at?: string;
    inflightReportUser?: User; // include

    data_status: CampaignDataNotice;

    refreshed: string;

    status: CampaignStatus;

    agency?: Agency;
    client?: Client;
    creative?: Creative;
    date?: Date;
    market?: Market;

    creatives?: Creative[];
    displays?: CampaignDisplay[];

    // include
    campaignInstructions?: CampaignInstruction[];

    // latest budget summary
    budgetSummary?: BudgetSummary;

    // all budget summaries
    budgetSummaries?: BudgetSummary[];

    // include
    lineItemsCount?: number;
    lineItems?: Displays_LineItem[];

    notices?: CampaignNotices;

    displayIssues?: Displays_DisplayIssues[];

    owners?: CampaignOwner[];

    // include
    // popSettings?: PopSettings;

    // include
    errors?: CampaignError[];

    // include
    creativeThumbnails?: File[];

    // include - the user who created the campaign
    user?: User;

    // include
    internalAssignee?: User;

    // include
    internalOwner?: User;

    // include
    users?: User[];
    usersCount?: number;

    // include
    ownerContactedDates?: CampaignOwnerContactedDate[];

    // include
    additionalContacts?: User[];

    // include
    popShots?: CampaignPopShot[];
    popShotsCount?: number;

    // include
    missingDisplays?: MissingDisplay[];
    missingDisplaysCount?: number;

    // include
    popSettings?: CampaignPopSettings;

    // include
    logSourceCampaignIdentifiers?: PopDisplays_LogSourceCampaignIdentifier[];
    impression_multiplier?: number | string;
};
export type CampaignDisplaySchedule = Displays_Screen & {
    schedulesCount: number;
    schedules: {
        data: {
            campaign_id: number;
            id: number;
            isDefault?: boolean;
            name: string;
            template: boolean;
        }[];
    };
};

export type CampaignOwner = {
    campaign_id: number;
    owner_id: number;

    playout_percentage?: string;

    owner?: Owner;
};

export type CampaignDataNotice = {
    awaiting_data: boolean;
    inspecting_data: boolean;
    awaiting_booking_plan: boolean;
    inflight_report_sent: boolean;
    pca_report_sent: boolean;
};
export type CampaignNotices = {
    overall: CampaignDataNotice;

    // the key is the name of the line item
    line_items: Record<string, CampaignDataNotice>;

    // the key is the name of the media owner
    media_owners: Record<string, CampaignDataNotice>;
};

export enum CampaignStatus {
    CampaignStarted = 'campaign_started',
    CreativesUploaded = 'creatives_uploaded',
    CampaignReady = 'campaign_ready',
    CampaignArchived = 'campaign_archived',
    CampaignComplete = 'campaign_complete',
    Unknown = 'unknown',
}

export enum CampaignNoticeKey {
    AwaitingData = 'awaiting_data',
    InspectingData = 'inspecting_data',
    AwaitingBookingPlan = 'awaiting_booking_plan',
}

export type CampaignError = BaseEntity & {
    campaign_id: number;

    // id of entity connected to error
    errorable_id: string;

    // the full class path of the entity connected to the error
    errorable_type: CampaignErrorableType;

    type: CampaignErrorType;

    data: {
        expected: string[];
        outcome: string[];
    };
};

export enum CampaignErrorableType {
    Campaign = 'Campaign',
    LineItem = 'LineItem',
    Creative = 'Creative',
}

export enum CampaignErrorType {
    MissingLineItemMimeType = 'missing_line_item_mime_type',
    MissingAnalogueLineItem = 'missing_analogue_line_item',
    NoMatchingLineItemResolutions = 'no_matching_line_item_resolutions',
    CreativeMissingResolution = 'creative_missing_resolution',
    IncorrectAnimation = 'incorrect_animation',
    IncorrectImageAnimation = 'incorrect_image_animation',
    IncorrectVideoAnimation = 'incorrect_video_animation',
    IncorrectFileAnalogue = 'incorrect_file_analogue',

    // Campaign level errors
    NoMatchingResolution = 'no_matching_resolution',
    MissingCreativeResolutions = 'missing_creative_resolutions',
    NoMatchingResolutionCreatives = 'no_matching_resolution_creatives',
    NoMatchingImageCreatives = 'no_matching_image_creatives',
    NoMatchingAnalogueCreatives = 'no_matching_analogue_creatives',
    NoMatchingVideoCreatives = 'no_matching_video_creatives',
}

export enum CampaignRagStatus {
    Red = 'red',
    Amber = 'amber',
    Green = 'green',
}

export type CampaignBookingSummary = BaseEntity & {
    raw_path: string;
    path: string;
    name: string;

    campaign_id: number;
    user_id?: number;

    // include
    user?: User;
};

export type CampaignPopSettings = {
    unilive_campaign_id?: number;
    creatives?: string[];
    campaign_properties?: {
        refresh?: {
            type?: 'two_days_ago' | 'standard' | 'custom';
            custom_date?: string; // date string
        };
        graphReport?: {
            type_id?: number; // PopDisplays_GraphReportType id
        };
    };
    audience_source_id?: number;
    pop_settings_use?: number;
};
