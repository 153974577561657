import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { PermissionName } from 'c-sdk';
import { creativeIcon } from 'c-main/icons';
import { TransparentCard } from 'c-components';
import { CreativeSearchWrapper } from 'c-main/Components';
import ReactGA from 'react-ga';

const CreativesSearchPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard title={useCommonTranslation('Modules.Main.Creative.SearchPage.metaTitle')}>
            <CardContent>
                <CreativeSearchWrapper
                    paginationTag="global-creative-search"
                    showAgencyFilter={false}
                />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'CreativesSearchPage',
    component: CreativesSearchPage,
    systemSearch: {
        title: 'Modules.Main.Creative.SearchPage.metaTitle',
        description: 'Modules.Main.Creative.SearchPage.description',
        icon: creativeIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.creativesSearchPage',
        route: PostAuthRoutes.Creatives.CreativesSearch,
        saveAsRecentPage: true,
        breadcrumbs: [],
        permissions: [
            PermissionName.UniledportalCreativeRead,
            PermissionName.UniledportalFileRead,
            PermissionName.UniledportalResolutionRead,
        ],
    },
};

export default setup;
