export type CampaignStats = {
    plays: number;
    plays_expected: number;
    plays_percentage: number;
    plays_in_schedule: number;
    plays_out_schedule: number;
    plays_unbooked: number;

    impacts: number;
    impacts_expected: number;
    impacts_percentage: number;
    impacts_in_schedule: number;
    impacts_out_schedule: number;
    impacts_unbooked: number;

    time: number;
    time_expected: number;
    time_percentage: number;
    time_in_schedule: number;
    time_out_schedule: number;
    time_unbooked: number;

    kilowatts: number;
    kgco2: number;
};

export type PCAReportRequestPayload = {
    campaignId: number;
    startDate: string;
    endDate: string;
    metric: PCAReportMetric;
    campaignStats?: boolean;
    timeFrame?: PCAReportTimeFrame;
    field?: PCAReportField;
    environment?: string;
    source?: 'es' | 'athena';

    fieldFilters?: Partial<Record<PCAReportField, any[]>>;

    /**
     * Defaults to all
     */
    countFields?: PCAReportCountField[];
};

export enum PCAReportEnvironment {
    Live = 'live',
    Sandbox = 'sandbox',
}

export enum PCAReportTimeFrame {
    Hourly = 'Hourly',
    Daily = 'Daily',
}

export enum PCAReportCountField {
    InSchedule = 'in_schedule',
    OutSchedule = 'out_schedule',
    UnBooked = 'unbooked',
}

export enum PCAReportMetric {
    Plays = 'plays',
    Impacts = 'impacts',
    Time = 'time',
}

export enum PCAReportField {
    Town = 'town',
    TvRegion = 'tvRegion',
    Conurbation = 'conurbation',
    Environment = 'environment',
    Format = 'format',
    MediaOwner = 'mediaOwner',
    FrameId = 'frameId',
    LineItemName = 'lineItemName',
    SurfaceArea = 'surfaceArea',
    Resolution = 'resolution',
    CreativeName = 'creativeName',
    DayOfWeek = 'dayOfWeek',
    Hour = 'hour',
    DataStatus = 'data_status',
}

export type PCAReportPlayStats = [
    actual: number,
    expected: number,
    percentage: number,
    in_schedule: number,
    out_schedule: number,
    unbooked: number,
];

export type PCAReportByFieldPlayStats = [
    actual: number,
    expected: number,
    percentage: number,
    in_schedule: number,
    out_schedule: number,
    unbooked: number,
    resourceId?: string | number,
];

export type PCAReportHourlyResponse = {
    campaignStats?: CampaignStats;

    /**
     * The key of the top level object will be a date, for example, '01-12-2021'
     * The key at the second level will be a the hour (0 - 23)
     *
     * Example:
     * const example = {
     *    reportData: {
     *        '2021-06-15': {
     *            '0': [0, 2925, '0.0000', 123, 1234, 1],
     *            ...
     *            '23': [1678, 4185, '40.0956'm 123, 1234, 1],
     *        },
     *    },
     * };
     */
    reportData: Record<string, Record<string, PCAReportPlayStats | CampaignStats>>;
};

export type PCAReportDailyResponse = {
    campaignStats?: CampaignStats;

    /**
     * The key will be a date, for example, '01-12-2021'
     *
     * Example:
     *  const example = {
     *     reportData: {
     *         '2021-06-15': [242075, 295691, '81.8676', 123, 1234, 1],
     *     },
     *  };
     */
    reportData: Record<string, PCAReportPlayStats>;
};

export type PCAReportByFieldResponse = {
    campaignStats?: CampaignStats;

    /**
     * The key could be a town name (if the Town field is chosen), for example, 'London'
     *
     * Example:
     * const example = {
     *    reportData: {
     *        'London': [242075, 295691, '81.8676', 123, 1234, 1],
     *    },
     * };
     */
    reportData: Record<string, PCAReportByFieldPlayStats>;
};

export type PCAReportEnergyStats = {
    kilowatts: string; // decimal
    kgco2: string; // decimal
    sum_of_plays: number;
    sum_of_time: number;
};
export type PCAReportEnergyByFieldResponse = {
    campaignStats?: CampaignStats;

    reportData: Record<string, PCAReportEnergyStats>;
};

export type PCAReportByFieldDailyResponse = {
    campaignStats?: CampaignStats;
    reportData: Record<string, PCAReportDailyResponse['reportData']>;
};

export type PCAReportByFieldHourlyResponse = {
    campaignStats?: CampaignStats;
    reportData: Record<string, PCAReportHourlyResponse['reportData']>;
};

/**
 * MAP
 */

export type PCAMapReportRequestPayload = {
    campaignId: number;
    startDate: string;
    endDate: string;
    metric: PCAReportMetric;
    campaignStats?: boolean;
    environment?: string;
    source?: 'es' | 'athena';

    /**
     * Defaults to all
     */
    countFields?: PCAReportCountField[];

    fieldFilters?: Partial<Record<PCAReportField, any[]>>;
};

export type PCAMapReportResponse = {
    campaignStats?: CampaignStats;

    /**
     * The key could is the frame id, for example, '1234627105'
     *
     * Example:
     * const example = {
     *     reportData: {
     *         "1234627105": [
     *             0,
     *             15120,
     *             "0.0000",
     *
     *             123,
     *             1234,
     *             1,
     *
     *             "51.54158",
     *             "-0.00438",
     *
     *             "Stratford West Ham",
     *             "Greater London",
     *             "1.35",
     *             "Greater London",
     *             "Transport",
     *             "D6",
     *             "Stratford Station, Station Street, Stratford, Greater London\r",
     *             "Global"
     *             "1920x1080",
     *         ],
     *     },
     * };
     */
    reportData: Record<string, PCAMapReportPlayStats>;
};

export type PCAMapReportPlayStats = [
    actual: number,
    expected: number,
    percentage: string,

    inSchedule: number,
    outSchedule: number,
    unbooked: number,

    latitude: string,
    longitude: string,

    town: string,
    tvRegion: string,
    surfaceArea: string,
    conurbation: string,
    environment: string,
    format: string,
    lineItemName: string,
    mediaOwner: string,
    resolution: string,
    display_id: string,
];

export type PCAMapReportResponseMinimal = {
    campaignStats?: CampaignStats;

    /**
     * The key could is the frame id, for example, '1234627105'
     *
     * Example:
     * const example = {
     *   "data": {
     *      "1234625525": [
     *         5519,
     *         53.4043,
     *         -2.9793,
     *         0,
     *         90,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         540,
     *         0,
     *         0,
     *         0
     *      ],
     *      "1234626091": [
     *         5533,
     *         53.4083,
     *         -2.9797,
     *         0,
     *         90,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         0,
     *         540,
     *         0,
     *         0,
     *         0
     *     ]
     *   }
     * }
     */
    reportData: Record<string, PCAMapReportPlayStatsMinimal>;
};
export type PCAMapReportPlayStatsMinimal = [
    display_id: number,
    display_lat: number,
    display_long: number,
    sum_of_play: number,
    expected_plays_in_schedule: number,
    plays_received_in_schedule: number,
    plays_received_out_of_schedule: number,
    plays_received_unbooked: number,
    expected_impacts_in_schedule: number,
    audience: number,
    impacts_received_in_schedule: number,
    impacts_received_out_of_schedule: number,
    impacts_received_unbooked: number,
    sum_of_time: number,
    expected_time_in_schedule: number,
    time_received_in_schedule: number,
    time_received_out_of_schedule: number,
    time_received_unbooked: number,
];

export type PCACampaignFramesRequestPayload = {
    campaignId: number;
    metric: PCAReportMetric;
    fieldFilters?: Partial<Record<PCAReportField, any[]>>;
    startDate?: string;
    endDate?: string;
    environment?: string;
};

export type PCAFrameDetails = {
    latitude: number;
    longitude: number;

    town: string;
    tvRegion: string;
    surfaceArea: string;
    conurbation: string;
    environment: string;
    format: string;
    lineItemName: string;
    mediaOwner: string;
    resolution: string;

    address: string;
    postcode: string;
    frameId: string;

    actual: number;
    expected: number;
    percentage: number;

    in_schedule: number;
    out_schedule: number;
    unbooked: number;
    unbookedBool?: boolean;
};

/**
 * Analytics
 */

export type PCAAnalyticsReportRequestPayload = {
    campaignId: number;
    startDate: string;
    endDate: string;
    environment?: string;

    /**
     * Defaults to all
     */
    countFields?: PCAReportCountField[];

    fieldFilters?: Partial<Record<PCAReportField, any[]>>;
};

export type PCAAnalyticsReportResponse = {
    campaignStats?: CampaignStats;

    dailyImpacts: Record<string, PCAReportPlayStats>;
    dailyPlays: Record<string, PCAReportPlayStats>;

    dayOfWeek: Record<string, PCAReportPlayStats>;
    tvRegion: Record<string, PCAReportPlayStats>;
    lineItemName: Record<string, PCAReportPlayStats>;
    mediaOwner: Record<string, PCAReportPlayStats>;
    hour: Record<string, PCAReportPlayStats>;
};
