import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { API, BaseEntity } from 'c-sdk';
import { addEntityMap } from '../../moduleConfig';
import { BaseSliceInitialState, BaseSliceOptions, EntitySchemaType } from '../../Types';
import createExtraReducers from './createExtraReducers';
import baseReducers from './base-reducers';
import baseAdapter from './base-adapter';
import selectors from './base-selectors';
import baseThunks from './base-thunks';
import { FakeApi } from '../../lib/thunks/callFakeApi';

const createBaseSlice =
    <RootState>(entitySchemas: EntitySchemaType, apiClient: API) =>
    <
        Entity extends BaseEntity,
        State extends BaseSliceInitialState<Entity> = BaseSliceInitialState<Entity>,
        CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
    >(
        {
            name,
            entityName,
            adapterOptions,
            resetOnLogout = true,
            resetOnTimeout = true,

            initialState,

            reducers,
            createReducers,

            extraReducers,
            entityMap,
        }: BaseSliceOptions<State, Entity, CaseReducers, RootState>,
        fakeApi?: FakeApi<Entity>,
    ) => {
        const adapter = baseAdapter(adapterOptions);
        const initialStoreState = adapter.getInitialState({ ...initialState });
        if (entityMap) {
            addEntityMap(entityName as string, entityMap as <T>(entity: T) => T);
        }
        const slice = createSlice({
            name,
            initialState,
            extraReducers: createExtraReducers({
                adapter,
                resetOnLogout,
                resetOnTimeout,
                initialState: initialStoreState,
                extraReducers,
            }),
            reducers: {
                ...baseReducers(adapter),
                ...(createReducers ? createReducers(adapter) : {}),
                ...reducers,
            },
        });

        return {
            adapter,
            slice,
            thunks: baseThunks<RootState>(entitySchemas, apiClient)<Entity>(
                entityName,
                slice.actions,
                fakeApi,
            ),
            initialState: initialStoreState,
            selectors: selectors<Entity>(adapter, name),
        };
    };

export default createBaseSlice;
