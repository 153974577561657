import React, { useEffect, useMemo } from 'react';
import { MetaLayout, PreAuthLayout } from 'c-wrapper';
import { useCommonTranslation } from 'c-translation';
import { useSelector } from 'react-redux';
import { NetworkRequestState } from 'c-data-layer';
import { FlashMessage } from 'c-components';
import { useHistory } from 'react-router-dom';
import { PreAuthRoutes } from 'c-routes';
import PasswordResetCodeForm from '../Components/PasswordResetCodeForm';
import { passwordResetSelectors } from '../Slices/PasswordReset';

const PasswordResetCodePage = () => {
    const $history = useHistory();
    const resetReqState = useSelector(passwordResetSelectors.passwordResetPasswordRequestState);
    const resetReqError = useSelector(passwordResetSelectors.passwordResetPasswordRequestError);

    const flashMessage = useMemo(() => {
        if (resetReqState === NetworkRequestState.Error) {
            return <FlashMessage status="error">{resetReqError}</FlashMessage>;
        }

        return null;
    }, [resetReqState, resetReqError]);

    useEffect(() => {
        if (resetReqState === NetworkRequestState.Success) {
            $history.push(PreAuthRoutes.Login);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetReqState]);

    return (
        <MetaLayout metaTitle="Pages.ForgotPasswordCode.metaTitle">
            <PreAuthLayout
                flashMessage={flashMessage}
                title={useCommonTranslation('Pages.ForgotPasswordCode.title')}
            >
                <PasswordResetCodeForm submitLabel="Pages.ForgotPasswordCode.submitLabel" />
            </PreAuthLayout>
        </MetaLayout>
    );
};

export default PasswordResetCodePage;
