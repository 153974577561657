import React from 'react';
import { Provider } from 'react-redux';
import { Store } from '@reduxjs/toolkit';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist';

type Props = React.PropsWithChildren<{
    store: Store;
    persistor: Persistor;
    loading?: React.ReactNode;
}>;

const AppProviders = ({ store, persistor, children, loading }: Props) => (
    <Provider store={store}>
        <PersistGate loading={loading} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
);

export default AppProviders;
