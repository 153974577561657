import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UpdateCampaignLineItemsFormSchema } from 'c-main/Types';
import { difference, uniq } from 'ramda';
import { FormWrapper } from 'c-components';
import { isoDateStringNoTimeDiff, numberOfDays } from 'c-lib';
import { NetworkRequestState } from 'c-data-layer';
import { CampaignSyncLineItemsRequest, Campaign } from 'c-sdk';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { generatePath, useHistory } from 'react-router-dom';
import { campaignLineItemSettings } from 'c-main/Lib';
import { usePrevious } from 'react-hanger';
import { PostAuthRoutes } from 'c-routes';
import { useAPIClientRequest } from 'c-hooks';

import EditCampaignFormStepper from './EditCampaignFormStepper';
import CampaignUpdateState from './CampaignUpdateState';

type Props = {
    campaign: Campaign;
    source: string;
};

const lineItemIds = (campaign: Campaign) =>
    uniq(campaign?.lineItems?.map(item => String(item.id)) ?? []);

const lineItemDays = (campaign: Campaign) =>
    campaign?.budgetSummary?.lineItems?.reduce(
        (accDays, lineItem) => ({ ...accDays, [lineItem.line_item_id]: lineItem.days }),
        {},
    );

const lineStartDates = (campaign: Campaign) =>
    campaign?.budgetSummary?.lineItems?.reduce(
        (accDays, lineItem) => ({
            ...accDays,
            [lineItem.line_item_id]:
                typeof lineItem.start_date === 'string'
                    ? isoDateStringNoTimeDiff(lineItem.start_date)
                    : '',
        }),
        {},
    );

const lineEndDates = (campaign: Campaign) =>
    campaign?.budgetSummary?.lineItems?.reduce(
        (accDays, lineItem) => ({
            ...accDays,
            [lineItem.line_item_id]:
                typeof lineItem.end_date === 'string'
                    ? isoDateStringNoTimeDiff(lineItem.end_date)
                    : '',
        }),
        {},
    );

const EditCampaignLineItems: React.FC<Props> = ({ campaign, source }) => {
    const defaultFormData = useMemo(
        () => ({
            defaultValues: {
                name: campaign.name,
                reference: campaign.reference,
                buyer_id: campaign.buyer_id,
                agency_id: campaign.agency_id,
                client_id: campaign.client_id,
                start_datetime: isoDateStringNoTimeDiff(campaign.date.date_start),
                end_datetime: isoDateStringNoTimeDiff(campaign.date.date_end),

                line_items: lineItemIds(campaign),
                start_dates: lineStartDates(campaign),
                end_dates: lineEndDates(campaign),
                days: lineItemDays(campaign),

                originalLineItems: lineItemIds(campaign),
                originalDays: lineItemDays(campaign),
            } as UpdateCampaignLineItemsFormSchema,
        }),
        [campaign],
    );

    const [loadingState, setLoadingState] = useState<NetworkRequestState>(NetworkRequestState.Idle);
    const { start } = useAPIClientRequest(apiClient.Entities.Campaign.syncLineItems);

    const onSubmit = useCallback(
        async (data: UpdateCampaignLineItemsFormSchema) => {
            const maxDays = numberOfDays(
                new Date(data.start_datetime),
                new Date(data.end_datetime),
            );

            const modifiedFormData: CampaignSyncLineItemsRequest = {
                attach: campaignLineItemSettings(data, maxDays),
                detach: difference(data.originalLineItems, data.line_items),
                start_datetime: data.start_datetime,
                end_datetime: data.end_datetime,
            };

            setLoadingState(NetworkRequestState.InProgress);

            const [err, success] = await to(start(campaign.id, modifiedFormData, source));

            if (!err && success?.data?.data?.id === campaign.id) {
                setLoadingState(NetworkRequestState.Success);
            } else {
                setLoadingState(NetworkRequestState.Error);
            }
        },
        [campaign.id, source, start],
    );

    const $history = useHistory();
    const prevLoadingState = usePrevious(loadingState);
    useEffect(() => {
        if (
            prevLoadingState != null &&
            prevLoadingState === NetworkRequestState.InProgress &&
            loadingState === NetworkRequestState.Success
        ) {
            $history.push(
                generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id: campaign.id }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState]);
    return (
        <>
            <FormWrapper onSubmit={onSubmit} formOptions={defaultFormData}>
                <EditCampaignFormStepper campaign={campaign} />
            </FormWrapper>
            <CampaignUpdateState loadingState={loadingState} />
        </>
    );
};

export default EditCampaignLineItems;
