import {
    combineReducers,
    ConfigureEnhancersCallback,
    configureStore,
    DeepPartial,
    Middleware,
    StoreEnhancer,
} from '@reduxjs/toolkit';

import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';
import config from '../moduleConfig';

export const createStoreWithMiddleware = <RootState>({
    version,
    reducers,
    preloadedState,
    middleware = [],
    enhancers = [],
    transforms = [],
    blacklist = [],
}: {
    version: string;
    reducers: { [reducerName in keyof Partial<RootState>]: any };
    preloadedState?: DeepPartial<RootState>;
    middleware?: Middleware[];
    enhancers?: StoreEnhancer[] | ConfigureEnhancersCallback;
    transforms?: PersistConfig<RootState>['transforms'];
    blacklist?: string[];
}) => {
    const rootReducer = combineReducers<RootState>({ ...reducers, ...config.reducers });
    const persistedReducer = persistReducer(
        {
            key: `root-${version}`,
            storage,
            transforms,
            blacklist,
        },
        rootReducer,
    );
    const store = configureStore({
        reducer: persistedReducer,
        preloadedState: preloadedState as any,
        enhancers,
        middleware: getDefaultMiddleware => [
            ...getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
            ...middleware,
        ],
    });
    const persistor = persistStore(store);

    return { store, persistor };
};
