import React, { useCallback, useMemo } from 'react';
import useCampaignSchedule from 'c-main/Components/Schedule/useCampaignSchedule';
import { useAPIClientRequest, useAutoResetBoolean } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { PermissionName, SchedulableType, ScheduleGet, UpdateSchedulePayload } from 'c-sdk';
import to from 'await-to-js';
import { uniq } from 'ramda';
import { Box, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import { AddCircle, RemoveCircle, WarningAmber } from '@mui/icons-material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useCommonTranslation } from 'c-translation';

type Props = {
    campaignId: number;
    schedule: ScheduleGet;
};

const attachPerms = [
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesRead,
    PermissionName.UniliveapiSchedulesDestroy,
];

const SchedulableAssociateButtons: React.FC<Props> = ({ campaignId, schedule }) => {
    const { selectedLineItems, selectedScreens, selectedSchedulables, updateSchedules } =
        useCampaignSchedule(campaignId);

    const {
        start: StartAttach,
        isLoading: IsAttaching,
        hasFailed: AttachFailed,
        error: AttachError,
    } = useAPIClientRequest(apiClient.Entities.Schedule.update);
    const { value: AttachSuccess, setTrue: SetAttachSuccess } = useAutoResetBoolean(false, 2000);

    const onAttachSelectedLineItems = useCallback(async () => {
        const currentLineItems = schedule?.schedulables
            ?.filter(s => s.schedulable_type === SchedulableType.LineItem)
            .map(s => s.schedulable_id);
        const currentScreens = schedule?.schedulables
            ?.filter(s => s.schedulable_type === SchedulableType.Screen)
            .map(s => Number(s.schedulable_id));

        // this is purely additive, so glue together existing selected line items & screens
        // so none are removed
        const [err, succ] = await to(
            StartAttach({
                schedule_id: schedule?.id,
                campaign_id: campaignId,
                line_items: uniq([...currentLineItems, ...selectedLineItems]),
                screens: uniq([...currentScreens, ...selectedScreens]),
                includes: ['schedulables', 'rules'],
            } as UpdateSchedulePayload),
        );

        if (!err && succ?.data?.data?.id != null) {
            updateSchedules([succ.data.data]);
            SetAttachSuccess();
        }
    }, [
        StartAttach,
        schedule?.id,
        schedule?.schedulables,
        campaignId,
        selectedLineItems,
        selectedScreens,
        updateSchedules,
        SetAttachSuccess,
    ]);

    const {
        start: StartDetach,
        isLoading: IsDetaching,
        hasFailed: DetachFailed,
        error: DetachError,
    } = useAPIClientRequest(apiClient.Entities.Schedule.update);
    const { value: DetachSuccess, setTrue: SetDetachSuccess } = useAutoResetBoolean(false, 2000);

    const onDetachSelectedItems = useCallback(async () => {
        // this is purely additive, so glue together existing selected line items & screens
        // so none are removed
        const [err, succ] = await to(
            StartDetach({
                schedule_id: schedule?.id,
                campaign_id: campaignId,
                line_items: uniq(
                    schedule?.schedulables
                        ?.filter(
                            s =>
                                s.schedulable_type === SchedulableType.LineItem &&
                                // filter out selected line items so they are detached
                                selectedLineItems.indexOf(s.schedulable_id) === -1,
                        )
                        .map(s => s.schedulable_id),
                ),
                screens: uniq(
                    schedule?.schedulables
                        ?.filter(
                            s =>
                                s.schedulable_type === SchedulableType.Screen &&
                                // filter out selected screens so they are detached
                                selectedScreens.indexOf(Number(s.schedulable_id)) === -1,
                        )
                        .map(s => Number(s.schedulable_id)),
                ),
                includes: ['schedulables', 'rules'],
            } as UpdateSchedulePayload),
        );

        if (!err && succ?.data?.data?.id != null) {
            updateSchedules([succ.data.data]);
            SetDetachSuccess();
        }
    }, [
        SetDetachSuccess,
        StartDetach,
        campaignId,
        schedule?.id,
        schedule?.schedulables,
        selectedLineItems,
        selectedScreens,
        updateSchedules,
    ]);

    const attachColor = useMemo(() => {
        if (!IsAttaching) {
            if (AttachSuccess) return 'success';
            if (AttachFailed) return 'warning';
        }

        return 'primary';
    }, [AttachFailed, AttachSuccess, IsAttaching]);
    const detachColor = useMemo(() => {
        if (!IsDetaching) {
            if (DetachSuccess) return 'success';
            if (DetachFailed) return 'warning';
        }

        return 'error';
    }, [DetachFailed, DetachSuccess, IsDetaching]);

    const t = useCommonTranslation();
    const attachTooltip = useMemo(() => {
        if (AttachFailed) return String(AttachError);

        return t('Modules.Main.Campaigns.CampaignSchedule.Schedulables.addSelectedToSchedule', {
            count: selectedSchedulables.length,
        });
    }, [AttachFailed, AttachError, t, selectedSchedulables.length]);

    const detachTooltip = useMemo(
        () =>
            t('Modules.Main.Campaigns.CampaignSchedule.Schedulables.removeSelectedToSchedule', {
                count: selectedSchedulables.length,
            }),
        [t, selectedSchedulables.length],
    );

    const attachIcon = useMemo(() => {
        if (AttachFailed) return <WarningAmber fontSize="inherit" />;
        return IsAttaching ? <CircularProgress size={24} /> : <AddCircle fontSize="inherit" />;
    }, [IsAttaching, AttachFailed]);

    const detachIcon = useMemo(() => {
        if (DetachFailed) return <WarningAmber fontSize="inherit" />;
        return IsDetaching ? <CircularProgress size={24} /> : <RemoveCircle fontSize="inherit" />;
    }, [DetachFailed, IsDetaching]);

    return (
        <>
            <IfHasAllPermissions permissions={attachPerms}>
                <Stack display="flex" direction="row">
                    <Tooltip title={attachTooltip}>
                        <Box>
                            <IconButton
                                color={attachColor}
                                onClick={onAttachSelectedLineItems}
                                disabled={IsAttaching}
                            >
                                {attachIcon}
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Tooltip title={detachTooltip}>
                        <Box>
                            <IconButton
                                color={detachColor}
                                onClick={onDetachSelectedItems}
                                disabled={IsDetaching}
                            >
                                {detachIcon}
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Stack>
            </IfHasAllPermissions>
        </>
    );
};

export default SchedulableAssociateButtons;
