import { EntityId } from '@reduxjs/toolkit';
import { BaseEntity } from 'c-sdk';
import { BaseSliceTaggedEntityPaginatedList } from '../../Types';

const deletePaginatedEntityInLists = <Entity extends BaseEntity>(
    lists: BaseSliceTaggedEntityPaginatedList<Entity>,
    deletedEntityId: EntityId,
) =>
    Object.keys(lists).reduce((acc, tag) => {
        acc[tag] = {
            ...lists[tag],
            data: lists[tag].data.filter(entityId => entityId !== deletedEntityId),
        };
        return acc;
    }, {} as BaseSliceTaggedEntityPaginatedList<Entity>);

export default deletePaginatedEntityInLists;
