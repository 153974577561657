import React, { useCallback, useEffect, useMemo } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { CircularProgress, ListItem, Tooltip, Typography, TypographyProps } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import { AutoGrid, ListItemText } from 'c-components';
import { formatBytes } from 'c-lib';
import { CheckCircle, Error } from '@mui/icons-material';

type Props = {
    file: File;
};

const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};

const ChannelFrameImportListItem: React.FC<Props> = ({ file }) => {
    const { start, requestState, error } = useAPIClientRequest(
        apiClient.PopDisplays.channelFrameImport,
    );

    const uploadFile = useCallback(
        async (file: File) => {
            //
            const formData = new FormData();
            formData.set('file', file);
            await to(start({ formData }));
        },
        [start],
    );

    useEffect(() => {
        uploadFile(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);

    return (
        <ListItem>
            <ListItemText
                primary={file.name}
                primaryTypographyProps={textProps}
                secondaryTypographyProps={textProps}
                secondary={
                    <AutoGrid spacing={1}>
                        {formatBytes(file.size)} | {file.type}
                    </AutoGrid>
                }
            />
            {requestState === NetworkRequestState.InProgress && <CircularProgress size={25} />}
            {requestState === NetworkRequestState.Error && (
                <Tooltip title={String(error)}>
                    <Typography color="error.main">
                        <Error />
                    </Typography>
                </Tooltip>
            )}
            {requestState === NetworkRequestState.Success && (
                <Typography color="success.main">
                    <CheckCircle />
                </Typography>
            )}
        </ListItem>
    );
};

export default ChannelFrameImportListItem;
