import { MissingDisplay } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { MissingDisplayState } from './MissingDisplay-types';

const initialState: MissingDisplayState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: MissingDisplayState, action: PayloadAction<string>): MissingDisplayState => state,
};

const slice = createBaseSlice<MissingDisplay, MissingDisplayState, typeof additionalReducers>({
    name: 'missingDisplay',
    entityName: 'MissingDisplay',
    initialState,
    reducers: additionalReducers,
});

const missingDisplaySlice = {
    missingDisplayActions: slice.slice.actions,
    missingDisplayReducer: slice.slice.reducer,
    missingDisplayAdapter: slice.adapter,
    missingDisplaySelectors: slice.selectors,
    missingDisplayThunks: slice.thunks,
};

export default missingDisplaySlice;
export const {
    missingDisplayReducer,
    missingDisplayActions,
    missingDisplayAdapter,
    missingDisplaySelectors,
    missingDisplayThunks,
} = missingDisplaySlice;
