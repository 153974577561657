import React, { useCallback } from 'react';
import { useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName } from 'c-sdk';
import PCASettingsDialog from './PCASettingsDialog';

const PCATabControls: React.FC<{ id: number }> = ({ id }) => {
    const onBoxClick = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    return (
        <Box display="flex" alignItems="center">
            {useCommonTranslation('Modules.Main.Campaigns.Overview.Tabs.reporting')}
            <Box onClick={onBoxClick}>
                <IfHasAllPermissions permissions={[PermissionName.ReportingPcareportRead]}>
                    <PCASettingsDialog id={id} />
                </IfHasAllPermissions>
            </Box>
        </Box>
    );
};

export default PCATabControls;
