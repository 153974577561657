import { AllEntities, APIConstructorProps, IHttpClient } from './Types';
import { DEFAULT_ENDPOINT_MAP } from './Defaults';
import {
    Auth,
    BaseEntityModuleFuncs,
    Buyer,
    Campaign,
    Campaign_Chat,
    CampaignInstruction,
    CampaignPopShot,
    Creative,
    CreativeGroup,
    Displays_AnalogueScreen,
    Displays_AudienceUpload,
    Displays_Owner,
    Displays_Pack,
    Displays_Screen,
    Displays_Tag,
    LineItem,
    PopDisplays,
    Reports,
    ReportsElasticSearch,
    Role,
    Schedule,
    Skin,
    User as UserModule,
    VirtualCampaignBuilder,
} from './Modules';
import HttpClient from './HttpClient';
import ApiEntityEndpoints from './Types/ApiEntityEndpoints';

type EntitiesType = Omit<
    ApiEntityEndpoints,
    | 'Campaign'
    | 'Buyer'
    | 'CampaignInstruction'
    | 'CampaignPopShot'
    | 'Creative'
    | 'CreativeGroup'
    | 'User'
    | 'Schedule'
    | 'Skin'
    | 'Role'
    | 'Displays_AnalogueScreen'
    | 'Displays_AudienceUpload'
    | 'Displays_LineItem'
    | 'Displays_Pack'
    | 'Displays_Screen'
    | 'Displays_Tag'
    | 'Displays_Owner'
    | 'Campaign_Chat'
> & {
    Buyer: ReturnType<typeof Buyer>;
    Campaign: ReturnType<typeof Campaign>;
    CampaignInstruction: ReturnType<typeof CampaignInstruction>;
    CampaignPopShot: ReturnType<typeof CampaignPopShot>;
    Creative: ReturnType<typeof Creative>;
    CreativeGroup: ReturnType<typeof CreativeGroup>;
    Displays_AnalogueScreen: ReturnType<typeof Displays_AnalogueScreen>;
    Displays_AudienceUpload: ReturnType<typeof Displays_AudienceUpload>;
    Displays_LineItem: ReturnType<typeof LineItem>;
    Displays_Pack: ReturnType<typeof Displays_Pack>;
    Displays_Screen: ReturnType<typeof Displays_Screen>;
    Displays_Tag: ReturnType<typeof Displays_Tag>;
    Displays_Owner: ReturnType<typeof Displays_Owner>;
    Campaign_Chat: ReturnType<typeof Campaign_Chat>;

    User: ReturnType<typeof UserModule>;
    Schedule: ReturnType<typeof Schedule>;
    Skin: ReturnType<typeof Skin>;
    Role: ReturnType<typeof Role>;
};

class API {
    private readonly _httpClient: IHttpClient;

    private _entities: EntitiesType;

    private _auth: ReturnType<typeof Auth>;

    private _reports: ReturnType<typeof Reports>;

    private _reportsElasticSearch: ReturnType<typeof ReportsElasticSearch>;

    private _virtualCampaignBuilder: ReturnType<typeof VirtualCampaignBuilder>;

    private _popDisplays: ReturnType<typeof PopDisplays>;

    constructor({ baseUrl, endpoints = DEFAULT_ENDPOINT_MAP, httpClient }: APIConstructorProps) {
        this._httpClient = httpClient ?? new HttpClient(baseUrl);
        const di = { httpClient: this._httpClient, endpoints, baseUrl };

        const genericEndpointModules = Object.keys(new AllEntities()).reduce(
            (a, k) => ({ ...a, [k]: BaseEntityModuleFuncs(di, k as keyof AllEntities) }),
            {},
        ) as EntitiesType;

        this._entities = {
            ...genericEndpointModules,
            Buyer: Buyer(di),
            Campaign: Campaign(di),
            CampaignInstruction: CampaignInstruction(di),
            CampaignPopShot: CampaignPopShot(di),
            Creative: Creative(di),
            CreativeGroup: CreativeGroup(di),
            Displays_LineItem: LineItem(di),
            Role: Role(di),
            Schedule: Schedule(di),
            Skin: Skin(di),
            User: UserModule(di),
            SpotlightRecentResource: null,
            Displays_AnalogueScreen: Displays_AnalogueScreen(di),
            Displays_AudienceUpload: Displays_AudienceUpload(di),
            Displays_Pack: Displays_Pack(di),
            Displays_Screen: Displays_Screen(di),
            Displays_Tag: Displays_Tag(di),
            Displays_Owner: Displays_Owner(di),
            Campaign_Chat: Campaign_Chat(di),
        };
        this._auth = Auth(di);
        this._reports = Reports(di);
        this._reportsElasticSearch = ReportsElasticSearch(di);
        this._virtualCampaignBuilder = VirtualCampaignBuilder(di);
        this._popDisplays = PopDisplays(di);
    }

    get HttpClient() {
        return this._httpClient;
    }

    get Entities() {
        return this._entities;
    }

    get Auth() {
        return this._auth;
    }

    get Reports() {
        return this._reports;
    }

    get ReportsElasticSearch() {
        return this._reportsElasticSearch;
    }

    get VirtualCampaignBuilder() {
        return this._virtualCampaignBuilder;
    }

    get PopDisplays() {
        return this._popDisplays;
    }
}

export default API;
