import React, { useCallback, useEffect, useState } from 'react';
import { UseBoolean, usePrevious } from 'react-hanger';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import { Button, DateField, DialogV2, FlashMessage } from 'c-components';
import { Creative } from 'c-sdk';
import { NetworkRequestState } from 'c-data-layer';

type Props = {
    dialogState: UseBoolean;
    selectedCreatives: number[];

    // intended for `live_date` and `end_date`
    dateField: 'live_date' | 'end_date';

    title: TranslationPath;
    description: TranslationPath;

    updateCreatives: (creatives: Partial<Creative>[]) => void;
    updateCreativesState: NetworkRequestState;
    updateCreativesError?: string;
};
const UpdateDateDialog: React.FC<Props> = ({
    dialogState: { setFalse: CloseDialog, value: DialogOpen },
    selectedCreatives,
    dateField,
    title,
    description,
    updateCreatives,
    updateCreativesState,
    updateCreativesError,
}) => {
    const t = useCommonTranslation();
    const [newDate, setNewDate] = useState<string>(null);

    useEffect(() => {
        setNewDate(null);
    }, [DialogOpen]);

    const onSave = useCallback(() => {
        updateCreatives(selectedCreatives.map(id => ({ id, [dateField]: newDate })));
    }, [dateField, newDate, selectedCreatives, updateCreatives]);

    useEffect(() => {
        setNewDate(null);
    }, [DialogOpen]);

    const prevLoadingState = usePrevious(updateCreativesState);
    useEffect(() => {
        if (
            updateCreativesState === NetworkRequestState.Success &&
            prevLoadingState === NetworkRequestState.InProgress
        ) {
            CloseDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCreativesState]);

    return (
        <DialogV2
            onClose={CloseDialog}
            open={DialogOpen}
            title={title}
            description={description}
            actions={
                <>
                    <Button
                        disabled={updateCreativesState === NetworkRequestState.InProgress}
                        onClick={onSave}
                    >
                        {useCommonTranslation(
                            'Modules.Main.CreativeManagement.creatives.controls.dates.submitLabel',
                        )}
                    </Button>
                </>
            }
        >
            <Box textAlign="center">
                <Box>
                    <DateField value={newDate} onChange={setNewDate} />
                </Box>
                {updateCreativesState === NetworkRequestState.Error && (
                    <Box mt={2} textAlign="center">
                        <FlashMessage status="error">{String(updateCreativesError)}</FlashMessage>
                    </Box>
                )}
            </Box>
        </DialogV2>
    );
};

export default UpdateDateDialog;
