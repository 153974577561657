import React, { KeyboardEvent, MutableRefObject, useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { usePaginatedEntityData } from 'c-data';
import { SpotlightRecentResource } from 'c-sdk';
import { useUserImpersonation } from 'c-hooks';
import { Translate } from 'c-translation';
import { SystemSearchFilters, SystemSearchRecentPages, SystemSearchResultList } from '..';
import { SystemSearchEntityResource, SystemSearchSelectedItemGrid } from '../../types';

import { systemSearchSelectors, systemSearchThunks } from '../../Slices/SystemSearch';
import { RECENT_PAGES_PAGINATION_TAG } from '../../constants';

import { useGetRecentPageConfig, useSystemSearch } from '../../Hooks';
import splitTerms from '../../Lib/splitTerms';
import shouldOverwriteFilters from '../../Lib/shouldOverwriteFilters';
import SystemSearchSearchField from './SystemSearchSearchField';

const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey['400'],
        borderRadius: '8px',
    },
}));

type Props = {
    inputRef: MutableRefObject<HTMLInputElement>;
};

const SystemSearchSearch: React.FC<Props> = ({ inputRef }) => {
    const dispatch = useDispatch();
    const { search, getEntityResults, filters } = useSystemSearch();
    const { wrapper } = useStyles();
    const { getConfig } = useGetRecentPageConfig();
    const term = useSelector(systemSearchSelectors.searchTerm);
    const [selectedItem, setSelectedItem] = useState<SystemSearchSelectedItemGrid>({
        x: 0,
        y: -1,
    });
    // const inputRef = useRef<HTMLInputElement>();
    const $history = useHistory();

    const { paginatedData: recentPages } = usePaginatedEntityData<SpotlightRecentResource>(
        RECENT_PAGES_PAGINATION_TAG,
        'SpotlightRecentResource',
    );

    // const { paginatedData: recentCustomers } = usePaginatedEntityData<SystemSearchRecentResource>(
    //     RECENT_CUSTOMERS_PAGINATION_TAG,
    //     'SystemSearchRecentResource',
    // );

    const onFilterSet = useCallback(
        (filter: string) => {
            const shouldOverwriteFilter = shouldOverwriteFilters(term, filters);
            const splitTerm = splitTerms(term);

            // If the term matches a tag we overwrite it on filter change
            if (shouldOverwriteFilter) {
                dispatch(systemSearchThunks.updateSearchTerm(`${filter}:`));
            } else {
                dispatch(
                    systemSearchThunks.updateSearchTerm(
                        `${filter}:${splitTerm[1] ?? splitTerm[0]}`,
                    ),
                );
            }
            inputRef?.current?.focus();
        },
        [term, filters, inputRef, dispatch],
    );
    const updateTerm = useCallback(
        e => {
            dispatch(systemSearchThunks.updateSearchTerm(e.target.value));
        },
        [dispatch],
    );

    const searchResult = useMemo(() => {
        return search(term);
    }, [term, search]);

    const entityResults = useMemo(() => {
        return getEntityResults(searchResult.term);
    }, [getEntityResults, searchResult]);

    const results = useMemo(() => {
        const keyedPages = (searchResult?.entities ?? []).reduce((acc, curr) => {
            acc[curr.entityName] = curr;
            return acc;
        }, {} as { [entityName: string]: SystemSearchEntityResource });

        const entityPages = entityResults.results
            .map(res => {
                if (keyedPages[res.entityName]) {
                    return {
                        ...keyedPages[res.entityName],
                        route: generatePath(keyedPages[res.entityName]?.route, { id: res.id }),
                    };
                }
                return null;
            })
            .filter(page => page != null);
        return [...(searchResult.results ?? []), ...entityPages];
    }, [searchResult, entityResults]);

    const resultsLength = useMemo(() => {
        return results.length;
    }, [results]);

    const showResults = useMemo(() => {
        // can add extra logic here like to check if entity results are in the middle of loading
        return resultsLength > 0;
    }, [resultsLength]);

    const colsLengths = useMemo(() => {
        if (showResults) {
            return [resultsLength];
        }

        /**
         *  we don't currently have customer records in the system, but when they are included
         *  this has been written to support it.
         */

        return [recentPages?.data?.length ?? 0 /* , recentCustomers?.data?.length ?? 0 */];
    }, [recentPages, showResults, resultsLength /* , recentCustomers */]);

    const onSelected = useCallback(
        (x: number, y: number) => {
            if (y !== -1 && $history) {
                if (showResults) {
                    const result = results[y];
                    if (result && result.route) {
                        $history.push(result.route);
                        dispatch(systemSearchThunks.close());
                    }
                } else {
                    // recent pages/customers
                    // assume on first column for now (recent pages)
                    const { page, data } = getConfig(recentPages?.data[y]);

                    if (page) {
                        $history.push(
                            generatePath(page.systemSearch.route, { id: data?.entityId }),
                        );
                        dispatch(systemSearchThunks.close());
                    }
                }
            }
        },
        [$history, dispatch, getConfig, recentPages?.data, results, showResults],
    );

    const onResultSelected = useCallback(
        (index: number) => {
            onSelected(0, index);
        },
        [onSelected],
    );

    const onEnter = useCallback(
        (e: KeyboardEvent) => {
            e.preventDefault();
            // Checking for history because it doesn't exist in storybook
            if (selectedItem.y !== -1) {
                onSelected(selectedItem.x, selectedItem.y);
            }
        },
        [selectedItem, onSelected],
    );

    const onEscape = useCallback(
        (e: KeyboardEvent) => {
            e.preventDefault();
            if (selectedItem.y === -1) {
                // focused on input
                dispatch(systemSearchThunks.close());
            } else {
                // focused on a search result
                // reset selection to -1 (no result focused) and focus the search input
                inputRef.current?.focus();
                setSelectedItem({ x: 0, y: -1 });
            }
        },
        [dispatch, inputRef, selectedItem, setSelectedItem],
    );

    const onArrowKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft' || (e.key === 'ArrowRight' && selectedItem.y === -1)) {
                // not focused on any element, so let users move their caret around the search input
                return;
            }
            if (
                e.key === 'ArrowDown' ||
                e.key === 'ArrowUp' ||
                e.key === 'ArrowLeft' ||
                e.key === 'ArrowRight'
            ) {
                if (e.type === 'keydown') {
                    const numberOfCols = colsLengths.length;
                    const currentColLength = colsLengths[selectedItem.x];

                    if (e.key === 'ArrowDown') {
                        e.preventDefault();
                        if (selectedItem.y >= currentColLength - 1) {
                            // last currently selected
                            setSelectedItem({ x: 0, y: -1 }); // select nothing
                            inputRef.current?.focus();
                        } else {
                            inputRef.current?.blur();
                            setSelectedItem({ ...selectedItem, y: selectedItem.y + 1 });
                        }
                    }

                    if (e.key === 'ArrowUp') {
                        e.preventDefault();
                        if (selectedItem.y === -1) {
                            setSelectedItem({ ...selectedItem, y: currentColLength - 1 });
                        } else if (selectedItem.y === 0) {
                            // first item selected
                            setSelectedItem({ x: 0, y: -1 });
                            inputRef.current?.focus();
                        } else {
                            inputRef.current?.blur();
                            setSelectedItem({ ...selectedItem, y: selectedItem.y - 1 });
                        }
                    }

                    if (colsLengths.length > 1) {
                        e.preventDefault();
                        if (e.key === 'ArrowLeft') {
                            if (selectedItem.x === 0) {
                                setSelectedItem({ y: 0, x: numberOfCols - 1 });
                            }
                            if (selectedItem.x > 0) {
                                setSelectedItem({ y: 0, x: selectedItem.x - 1 });
                            }
                        }

                        if (e.key === 'ArrowRight') {
                            if (selectedItem.x === numberOfCols - 1) {
                                setSelectedItem({ y: 0, x: 0 });
                            } else {
                                setSelectedItem({ y: 0, x: selectedItem.x + 1 });
                            }
                        }
                    }
                }
            }

            if (e.key === 'Enter') {
                onEnter(e);
            }
            if (e.key === 'Escape' && e.type === 'keydown') {
                onEscape(e);
            }
        },
        [inputRef, onEnter, onEscape, selectedItem, setSelectedItem, colsLengths],
    );

    // for when a user isn't focused on the search input
    useHotkeys('up,down,left,right', onArrowKey as any, [onArrowKey]);
    useHotkeys('return', onEnter as any, [onEnter]);
    useHotkeys('escape', onEscape as any, [onEscape]);

    const onBoxClick = useCallback(e => e.stopPropagation(), []);

    const { isImpersonating } = useUserImpersonation();

    return (
        <Box className={wrapper} py={2} onClick={onBoxClick}>
            <Box px={2} color="white">
                <SystemSearchSearchField
                    id="system-search-value"
                    value={term}
                    onChange={updateTerm}
                    onKeyDown={onArrowKey}
                    onKeyPress={onArrowKey}
                    onKeyUp={onArrowKey}
                    inputRef={inputRef}
                    entityResults={entityResults}
                />
            </Box>
            {!showResults && (
                <>
                    <Box px={2} pt={1}>
                        <SystemSearchFilters onSelect={onFilterSet} />
                    </Box>
                    <Box px={2} pt={1}>
                        {!isImpersonating ? (
                            <SystemSearchRecentPages
                                selected={selectedItem}
                                onSelected={onSelected}
                            />
                        ) : (
                            <Typography variant="subtitle1" textAlign="center">
                                <Translate path="SystemSearch.impersonatingRecentPagesDisabled" />
                            </Typography>
                        )}
                    </Box>
                </>
            )}
            {results.length > 0 && (
                <SystemSearchResultList
                    results={results}
                    entityResults={entityResults?.results ?? []}
                    searchTerm={searchResult.term}
                    selectedIndex={selectedItem.y}
                    setHighlighted={setSelectedItem}
                    onClick={onResultSelected}
                />
            )}
        </Box>
    );
};

export default SystemSearchSearch;
