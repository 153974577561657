import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Dialog } from 'c-components/Dialog';
import { formatBytes } from 'c-lib';
import { useCommonTranslation } from 'c-translation';
import AutoGrid from 'c-components/AutoGrid';
import { CampaignError } from 'c-sdk';
import RawFilePreview from './RawFilePreview';
import OpenFileNewTab from './OpenFileNewTab';
import { fileHasResolution, generateFileResolution } from './lib';
import OpenFileDownload from './OpenFileDownload';
import { FileBrowserFile } from './types';
import CampaignErrorList from '../../Modules/Main/Components/Campaign/CampaignError/CampaignErrorList';
import FileDuration from './FileDuration';

type Props = {
    title?: React.ReactNode;
    file: FileBrowserFile;
    open: boolean;
    close: () => void;
    errors?: CampaignError[];
};

const FilePreviewDialog: React.FC<Props> = ({ title, file, open, close, errors }) => {
    const actualTitle = useMemo(() => (title || file?.name) ?? '', [title, file?.name]);
    const t = useCommonTranslation();

    const duration = useMemo(() => {
        if (typeof file?.durationMs === 'number' && file?.durationMs > 0) {
            return (
                <AutoGrid gap={0.5}>
                    <>{t('FileBrowser.filePreview.fileDuration')}:</>
                    <FileDuration file={file} />
                </AutoGrid>
            );
        }

        return '';
    }, [file, t]);
    return (
        <Dialog
            MuiDialogProps={{ maxWidth: 'xl' }}
            removeForm
            title={
                file && (
                    <>
                        <Typography
                            sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}
                            variant="subtitle1"
                            component={Box}
                        >
                            {actualTitle}
                        </Typography>
                        <Typography variant="body2" component={Box}>
                            <AutoGrid gap={1}>
                                {fileHasResolution(file) && <>{generateFileResolution(file)}</>}
                                <>({formatBytes(file.size)})</>
                                <>{file.mimeType}</>
                                {duration}
                            </AutoGrid>
                        </Typography>
                    </>
                )
            }
            onClose={close}
            show={open}
            content={
                file && (
                    <Box>
                        {errors?.length > 0 && (
                            <Box mb={2}>
                                <CampaignErrorList errors={errors} />
                            </Box>
                        )}
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <RawFilePreview file={file} showPdfEmbed maxHeight="70vh" />
                        </Box>
                    </Box>
                )
            }
            cancelLabel="FileBrowser.filePreview.previewModelClose"
            beforeActions={
                <>
                    <OpenFileDownload file={file} />
                    <OpenFileNewTab file={file} />
                </>
            }
        />
    );
};

export default FilePreviewDialog;
