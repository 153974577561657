import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, AutoGrid, Button, DialogV2 } from 'c-components';
import { Box, lighten, List, Stack, Typography } from '@mui/material';
import { ReValidateCampaign, UpdateCreatives } from 'c-main/Components/CreativeManagement/types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useCommonTranslation } from 'c-translation';
import { useTheme } from '@mui/styles';
import { uniq } from 'ramda';
import { Creative } from 'c-sdk';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useBoolean } from 'react-hanger';
import CreativeUploadListItem from './CreativeUploadListItem';

type Props = {
    isDialogOpen: boolean;
    closeDialog: () => void;
    updateCreatives: UpdateCreatives;
    reValidateCampaign: ReValidateCampaign;
    openGroup?: number;
    initialFilesToUpload?: File[];
    existingFilesArray: string[];
};

const empty: File[] = [];

const hasDisallowedChars = filename => {
    const disallowedCharsRegex = /[\\/:*?"<>|~]/g;
    return disallowedCharsRegex.test(filename);
};

const UploadDialog: React.FC<Props> = ({
    isDialogOpen,
    closeDialog,
    updateCreatives,
    reValidateCampaign,
    openGroup,
    initialFilesToUpload = empty,
    existingFilesArray,
}) => {
    const t = useCommonTranslation();
    const theme = useTheme();
    const dropzoneBg = useMemo(() => lighten(theme.palette.secondary.main, 0.85), [theme]);
    const [uploadFiles, setUploadFiles] = useState<Record<string, File>>({});
    const [uploadedCreatives, setUploadedCreatives] = useState<Creative[]>([]);
    const [failedFiles, setFailedFiles] = useState<string[]>([]);
    const [replicateFiles, setReplicateFiles] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const showWarningDialog = useBoolean(false);
    const isUploading = useMemo(
        () => uploadedCreatives.length + failedFiles.length < Object.keys(uploadFiles).length,
        [uploadedCreatives, failedFiles, uploadFiles],
    );
    const handleCloseDialog = useCallback(() => {
        if (isUploading) {
            showWarningDialog.setTrue();
            return;
        }
        if (uploadedCreatives.length > 0) {
            updateCreatives(uploadedCreatives);
        }
        showWarningDialog.setFalse();
        closeDialog();
    }, [isUploading, uploadedCreatives, closeDialog, showWarningDialog, updateCreatives]);

    const handleCancelClose = useCallback(() => {
        showWarningDialog.setFalse();
    }, [showWarningDialog]);

    const onUploadedFile = useCallback((id: string, creative: Creative) => {
        setUploadedCreatives(curr => [...curr, creative]);
    }, []);
    const onUploadedFileFailed = useCallback((id: string) => {
        setFailedFiles(curr => [...curr, id]);
    }, []);

    useEffect(() => {
        const filesLength = Object.keys(uploadFiles ?? {}).length;
        if (filesLength > 0 && failedFiles.length + uploadedCreatives.length >= filesLength) {
            reValidateCampaign();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [failedFiles, uploadedCreatives, uploadFiles]);

    const closeUploadDialog = useCallback(() => {
        if (uploadedCreatives.length > 0) {
            updateCreatives(uploadedCreatives);
        }
        // setReplicateFiles([]);
        showWarningDialog.setFalse();
        closeDialog();
    }, [uploadedCreatives, showWarningDialog, closeDialog, updateCreatives]);

    const addFiles = useCallback(
        (files: File[]) => {
            const newFiles: Record<string, File> = {};
            const rejectedFiles: string[] = [];

            files.forEach(file => {
                if (hasDisallowedChars(file.name)) {
                    rejectedFiles.push(file.name);
                } else {
                    let uniqueId = null;
                    while (uniqueId == null) {
                        const newId = String(Math.random());
                        if (uploadFiles[newId] == null && newFiles[newId] == null) uniqueId = newId;
                    }

                    newFiles[uniqueId] = file;
                }
            });

            if (rejectedFiles.length > 0) {
                setError(
                    `These files were rejected due to disallowed characters: ${rejectedFiles.join(
                        ', ',
                    )},    \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
                );
            }

            setUploadFiles(val => ({ ...val, ...newFiles }));
        },
        [uploadFiles],
    );

    useEffect(() => {
        if (isDialogOpen) {
            setUploadedCreatives([]);
            setFailedFiles([]);
            addFiles(initialFilesToUpload);
        } else {
            setUploadedCreatives([]);
            setFailedFiles([]);
            setUploadFiles({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen]);

    useEffect(() => {
        setReplicateFiles(
            uniq(
                Object.values(uploadFiles)
                    .filter(file => existingFilesArray.includes(file.name))
                    .map(f => f.name),
            ),
        );
    }, [uploadFiles, existingFilesArray]);

    const onDrop = useCallback(
        (data: { files: File[] }) => {
            addFiles(data.files);
        },
        [addFiles],
    );
    const onChange = useCallback(
        e => {
            const files: FileList = e.target.files;

            if (files.length > 0 && files.item(0)) {
                addFiles(Array.from(files));
                // reset selected files so the same file can be selected again and trigger change event
                e.target.value = '';
            }
        },
        [addFiles],
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        {
            accept: NativeTypes.FILE,
            drop: onDrop,
            collect: monitor => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
        },
        [onDrop],
    );

    const fileInputRef = useRef<HTMLInputElement>();

    const onBrowseClick = useCallback(() => {
        fileInputRef?.current?.click();
    }, []);

    const filesUploading = Object.keys(uploadFiles ?? {}).length > 0;

    const removeFiles = useCallback(
        fileName => {
            const filtered = Object.keys(uploadFiles)
                .filter(key => uploadFiles[key].name !== fileName)
                .reduce(
                    (result, key) => ({
                        ...result,
                        [key]: uploadFiles[key],
                    }),
                    {},
                );
            setUploadFiles(filtered);
            setReplicateFiles(currentReplicatedFiles =>
                currentReplicatedFiles.filter(file => file !== fileName),
            );
        },
        [uploadFiles],
    );

    const overrideFiles = useCallback(fileName => {
        setReplicateFiles(currentReplicatedFiles =>
            currentReplicatedFiles.filter(file => file !== fileName),
        );
    }, []);
    const alerts = useMemo(
        () => (
            <Stack gap={2}>
                {replicateFiles.length > 0 &&
                    replicateFiles.map((file, index) => (
                        <Alert
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${file}-${index}`}
                            severity="warning"
                            action={
                                <>
                                    <Box p={1}>
                                        <Button onClick={() => overrideFiles(file)}>
                                            {t(
                                                'Modules.Main.CreativeManagement.warnings.duplicateFileYesButton',
                                            )}
                                        </Button>
                                    </Box>
                                    <Box p={1}>
                                        <Button onClick={() => removeFiles(file)}>
                                            {t(
                                                'Modules.Main.CreativeManagement.warnings.duplicateFileNoButton',
                                            )}
                                        </Button>
                                    </Box>
                                </>
                            }
                        >
                            {t(
                                'Modules.Main.CreativeManagement.warnings.duplicateFileNameWarning',
                                {
                                    file,
                                },
                            )}
                        </Alert>
                    ))}
            </Stack>
        ),
        [overrideFiles, removeFiles, replicateFiles, t],
    );
    const filesStillUploading = useMemo(() => {
        const uploadedOrFailed = new Set([...uploadedCreatives.map(c => c.name), ...failedFiles]);
        return Object.values(uploadFiles)
            .filter(file => !uploadedOrFailed.has(file.name))
            .map(file => file.name);
    }, [uploadedCreatives, failedFiles, uploadFiles]);

    return (
        <>
            <DialogV2
                onClose={handleCloseDialog}
                open={isDialogOpen}
                title="Modules.Main.CreativeManagement.creatives.controls.upload.title"
            >
                <>
                    <DialogV2
                        open={showWarningDialog.value}
                        onClose={handleCancelClose}
                        title="Modules.Main.CreativeManagement.creatives.controls.upload.uploadsInProgress"
                        actions={
                            <>
                                <Button onClick={handleCancelClose}>
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.controls.upload.cancel',
                                    )}
                                </Button>
                                <Button onClick={closeUploadDialog} color="error">
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.controls.upload.closeAnyway',
                                    )}
                                </Button>
                            </>
                        }
                    >
                        <Stack>
                            <Typography>
                                {t(
                                    'Modules.Main.CreativeManagement.creatives.controls.upload.fileList',
                                )}
                            </Typography>
                            <ul>
                                {filesStillUploading.map(fileName => (
                                    <li key={fileName}>
                                        <Typography>{fileName}</Typography>
                                    </li>
                                ))}
                            </ul>
                            <Typography>
                                {t(
                                    'Modules.Main.CreativeManagement.creatives.controls.upload.confirmation',
                                )}
                            </Typography>
                        </Stack>
                    </DialogV2>

                    {alerts}
                    <AutoGrid spacing={2} xs={12}>
                        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                            <Box display="none">
                                <input
                                    accept="image/*,video/*,application/pdf"
                                    type="file"
                                    id="file-upload"
                                    multiple
                                    onChange={onChange}
                                    ref={fileInputRef}
                                />
                            </Box>
                            <Box
                                ref={drop}
                                mt={2}
                                mr={4}
                                p={4}
                                height={!filesUploading ? 350 : undefined}
                                sx={{
                                    borderWidth: 2,
                                    borderStyle: canDrop ? 'dashed' : 'none',
                                    borderColor:
                                        isOver && canDrop ? 'success.main' : 'primary.main',
                                    borderRadius: 1,
                                }}
                                bgcolor={dropzoneBg}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box textAlign="center">
                                    <Typography
                                        variant="h1"
                                        color={isOver && canDrop ? 'success.main' : 'primary'}
                                    >
                                        <CloudUploadOutlinedIcon
                                            fontSize="large"
                                            sx={{ fontSize: '2em' }}
                                        />
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color={isOver && canDrop ? 'success.main' : undefined}
                                    >
                                        {t(
                                            'Modules.Main.CreativeManagement.creatives.controls.upload.dropzoneText',
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </label>
                        <Box pr={4}>{error && <Alert severity="error">{error}</Alert>}</Box>

                        {!filesUploading && (
                            <Box textAlign="center" mt={2}>
                                <Button variant="outlined" onClick={onBrowseClick}>
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.controls.upload.browseBtnLabel',
                                    )}
                                </Button>
                            </Box>
                        )}
                        <Box>
                            <List disablePadding>
                                {Object.entries(uploadFiles).map(([id, file]) =>
                                    !replicateFiles.includes(file.name) ? (
                                        <CreativeUploadListItem
                                            key={id}
                                            id={id}
                                            file={file}
                                            onUploadComplete={onUploadedFile}
                                            onUploadFailed={onUploadedFileFailed}
                                            groupId={openGroup}
                                        />
                                    ) : null,
                                )}
                            </List>
                        </Box>
                    </AutoGrid>
                </>
            </DialogV2>
        </>
    );
};

export default UploadDialog;
