import { useEntityData } from 'c-data';
import { Buyer, BuyerCost } from 'c-sdk';
import { useCallback, useMemo } from 'react';
import { CampaignFormNames, NewCampaignFormSchema } from 'c-main/Types';

function useCampaignLineItemCost(buyerId: number) {
    const { getById: GetBuyer } = useEntityData<Buyer>('Buyer');
    const { getById: GetBuyerCost } = useEntityData<BuyerCost>('BuyerCost');
    const buyer = GetBuyer({ id: buyerId });
    const buyerCost = GetBuyerCost({ id: buyer?.latest_cost_id });

    const getLineItemDaysKey = useCallback(
        (id: number) => `${CampaignFormNames.lineItemDays}.${id}`,
        [],
    );

    const getLineItemStartDateKey = useCallback(
        (id: number) => `${CampaignFormNames.lineItemStartDates}.${id}`,
        [],
    );

    const getLineItemEndDateKey = useCallback(
        (id: number) => `${CampaignFormNames.lineItemEndDates}.${id}`,
        [],
    );

    const getCost = useCallback(
        (days: number) => {
            if (buyerCost) {
                return Math.ceil(days / buyerCost.days) * buyerCost.cost;
            }

            return 0;
        },
        [buyerCost],
    );

    const getCostMultiple = useCallback(
        (ids: number[], days: NewCampaignFormSchema['days'], defaultDays: number) => {
            const total = ids.reduce((runningTotal, lineItemId) => {
                return runningTotal + getCost(days?.[lineItemId] ?? defaultDays);
            }, 0);

            return { total, capped: Math.min(buyerCost?.max_cost ?? 0, total) };
            // return Math.ceil(days / buyerCost.days) * buyerCost.cost;
        },
        [getCost, buyerCost],
    );

    const hasCost = useMemo(() => buyerCost != null, [buyerCost]);
    // const getDateRange = useCallback(
    //     (id: number[]) => {
    //         const total = ids.reduce((runningTotal, lineItemId) => {
    //             return runningTotal + getCost(days?.[lineItemId]?.duration_days ?? defaultDays);
    //         }, 0);
    //
    //         return { total, capped: Math.min(buyerCost?.max_cost ?? 0, total) };
    //         // return Math.ceil(days / buyerCost.days) * buyerCost.cost;
    //     },
    //     [getCost, buyerCost],
    // );

    return {
        getCost,
        getCostMultiple,
        getLineItemDaysKey,
        getLineItemStartDateKey,
        getLineItemEndDateKey,
        hasCost,
    };
}

export default useCampaignLineItemCost;
