import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, CardContent, Stack } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { playoutExportIcon } from 'c-main/icons';
import { PermissionName, PopDisplaysPlayoutExportPayload } from 'c-sdk';
import {
    AllEntitiesDropdown,
    Button,
    ControlledFormInput,
    DateRangePickerFormWrapper,
    FlashMessage,
    FormWrapper,
    TransparentCard,
} from 'c-components';
import { useForm, useFormContext } from 'react-hook-form';
import { FormWrapperProps } from 'c-components/Forms/FormWrapper/FormWrapper';
import * as yup from 'yup';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import ReactGA from 'react-ga';

const schema = yup.object({
    source: yup.array().min(1).required(),
    start_date: yup.string().required(),
    end_date: yup.string().required(),
});

const PlayoutExportPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { control, handleSubmit } = useForm<PopDisplaysPlayoutExportPayload>({
        defaultValues: { source: [], end_date: null, start_date: null },
    });

    const { start, data, isLoading, hasFailed, error, hasSucceeded } = useAPIClientRequest(
        apiClient.PopDisplays.playoutExport,
    );
    const onSubmit = useCallback(
        async (data: PopDisplaysPlayoutExportPayload) => {
            await to(start(data));
        },
        [start],
    );

    const opts = useMemo<FormWrapperProps<PopDisplaysPlayoutExportPayload>['formOptions']>(
        () => ({
            defaultValues: {
                source: [],
                end_date: null,
                start_date: null,
            },
        }),
        [],
    );

    const t = useCommonTranslation();
    return (
        <TransparentCard
            title={useCommonTranslation('Modules.PopDisplays.PlayoutExport.metaTitle')}
        >
            <CardContent>
                <FormWrapper<PopDisplaysPlayoutExportPayload>
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    formOptions={opts}
                >
                    <Form isLoading={isLoading} />
                </FormWrapper>
                <Box mt={2} textAlign="center">
                    {hasSucceeded && (
                        <FlashMessage status="success">
                            {data?.data?.message ??
                                t('Modules.PopDisplays.PlayoutExport.successDefault')}
                        </FlashMessage>
                    )}
                    {hasFailed && (
                        <FlashMessage status="success">
                            {error
                                ? String(error)
                                : t('Modules.PopDisplays.PlayoutExport.errorDefault')}
                        </FlashMessage>
                    )}
                </Box>
            </CardContent>
        </TransparentCard>
    );
};

const today = new Date();
const Form: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const t = useCommonTranslation();
    const { formState } = useFormContext();
    return (
        <Stack gap={2}>
            <ControlledFormInput
                render={({ field }) => (
                    <AllEntitiesDropdown
                        {...field}
                        multi
                        useLocalEntityData
                        label={t('Modules.PopDisplays.PlayoutExport.sourcesLabel')}
                        entityName="PopDisplays_LogImportSource"
                        labelField="name"
                        valueField="name"
                    />
                )}
                name="source"
            />
            <Box display="flex" justifyContent="center">
                <ControlledFormInput
                    render={() => (
                        <DateRangePickerFormWrapper
                            startDateKey="start_date"
                            endDateKey="end_date"
                            moveRangeOnFirstSelection={false}
                            months={2}
                            direction="horizontal"
                            maxDate={today}
                        />
                    )}
                    name="source"
                />
            </Box>
            <Box textAlign="center">
                <Button type="submit" disabled={!formState.isValid || isLoading}>
                    {t('Modules.PopDisplays.PlayoutExport.submitLabel')}
                </Button>
            </Box>
        </Stack>
    );
};

const setup: PageConfig = {
    id: 'PlayoutExportPage',
    component: PlayoutExportPage,
    systemSearch: {
        title: 'Modules.PopDisplays.PlayoutExport.metaTitle',
        description: 'Modules.PopDisplays.PlayoutExport.description',
        type: 'page',
        icon: playoutExportIcon,
        aliases: 'SystemSearch.aliases.popDisplaysPlayoutExport',
        route: PostAuthRoutes.PopDisplays.PlayoutExport,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.PlayoutExport.metaTitle' },
        ],
        permissions: [PermissionName.Pop_displaysLog_file_parsedPlayout_export],
    },
};

export default setup;
