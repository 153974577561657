import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosRequestData, HttpProgressUpdate, IHttpClient } from './Types';

const DEFAULT_AXIOS_HEADERS = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

class HttpClient implements IHttpClient {
    private baseUrl: string;

    private _dev = false;

    private axios: AxiosInstance;

    constructor(baseUrl: string, defaultHeaders: Record<string, string> = {}) {
        this.baseUrl = baseUrl;

        this.axios = Axios.create({
            headers: {
                ...DEFAULT_AXIOS_HEADERS,
                ...defaultHeaders,
            },
        });
    }

    Axios(): AxiosInstance {
        return this.axios;
    }

    Headers() {
        return this.axios.defaults.headers;
    }

    BaseUrl(): string {
        return this.baseUrl;
    }

    setDev(dev: boolean) {
        this._dev = dev;
        // server side  Node only. It's to stop this from rejecting self signed certs
        // when pointing at a local HTTPS server
        // @ts-ignore
        this.axios.defaults.httpsAgent = new require('https').Agent({
            rejectUnauthorized: !this._dev,
        });
    }

    addHeader(name: string, value: string) {
        this.axios.defaults.headers = {
            ...this.axios.defaults.headers,
            [name]: value,
        };
    }

    removeHeader(name: string) {
        const { [name]: _, ...rest } = this.axios.defaults.headers;

        this.axios.defaults.headers = rest;
    }

    get<T>(url: string, config?: AxiosRequestConfig): AxiosRequestData<T> {
        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;
        return {
            controller,
            request: this.axios.get<T>(this.baseUrl + url, {
                withCredentials: true,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    put<T>(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestData<T> {
        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;
        return {
            controller,
            request: this.axios.put<T>(this.baseUrl + url, data, {
                withCredentials: true,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    post<T>(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestData<T> {
        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;
        return {
            controller,
            request: this.axios.post(this.baseUrl + url, data, {
                withCredentials: true,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    patch<T>(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestData<T> {
        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;
        return {
            controller,
            request: this.axios.patch(this.baseUrl + url, data, {
                withCredentials: true,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    postFile(
        url: string,
        data: FormData,
        onProgressUpdate?: HttpProgressUpdate,
        additionalHeaders?: any,
        config?: AxiosRequestConfig,
    ): AxiosRequestData<any> {
        const headers = { ...this.axios.defaults.headers };
        delete headers['Content-Type'];

        if (additionalHeaders) {
            for (const key of Object.keys(additionalHeaders)) {
                headers[key] = additionalHeaders[key];
            }
        } else {
            headers['Content-Type'] = 'multipart/form-data';
        }

        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;

        return {
            controller,
            request: this.axios.post<any>(this.baseUrl + url, data, {
                withCredentials: true,
                headers,
                maxContentLength: Infinity,
                onUploadProgress: onProgressUpdate,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    putFile(
        url: string,
        data: FormData,
        onProgressUpdate?: HttpProgressUpdate,
        additionalHeaders?: any,
        config?: AxiosRequestConfig,
    ): AxiosRequestData<any> {
        const headers = { ...this.axios.defaults.headers };
        delete headers['Content-Type'];

        if (additionalHeaders) {
            for (const key of Object.keys(additionalHeaders)) {
                headers[key] = additionalHeaders[key];
            }
        } else {
            headers['Content-Type'] = 'multipart/form-data';
        }

        const controller = typeof AbortController !== 'undefined' ? new AbortController() : null;

        return {
            controller,
            request: this.axios.put<any>(this.baseUrl + url, data, {
                withCredentials: true,
                headers,
                maxContentLength: Infinity,
                onUploadProgress: onProgressUpdate,
                signal: controller?.signal,
                ...config,
            }),
        };
    }

    delete<T>(url: string, data?: any, config?: AxiosRequestConfig): AxiosRequestData<T> {
        const controller = typeof AbortController != 'undefined' ? new AbortController() : null;
        return {
            controller,
            request: this.axios.delete<T>(this.baseUrl + url, {
                withCredentials: true,
                signal: controller?.signal,
                data,
                ...config,
            }),
        };
    }
}

export default HttpClient;
