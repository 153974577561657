import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, TableContainerProps, TableProps } from '@mui/material';
import { Displays_LineItem, Buyer } from 'c-sdk';
import { useEntityData, usePaginatedEntityData } from 'c-data';
import { useFormContext } from 'react-hook-form';
import {
    FilterableEntityTable,
    FilterableEntityTableProps,
    FilterableEntityTableRefAPI,
    generateColumn,
} from 'c-pagination';
import { displaysLineItemsEntityTableProps } from 'c-main/entityColumns';
import { CampaignFormNames } from 'c-main/Types';
import ListItemPickerControls from './ListItemPickerControls';
import SelectedToggle from './SelectedToggle';
import useSelectedLineItems from './useSelectedLineItems';
import BulkAttachFrames from './BulkAttachFrames';

type Props = {
    //
};

const paginationTag = 'new-campaign-line_item_search';

const tableContainerProps: TableContainerProps = { sx: { maxHeight: 450 } };
const tableProps: TableProps = { stickyHeader: true };

const entityTableProps = {
    ...displaysLineItemsEntityTableProps,
    columns: [
        generateColumn<Displays_LineItem>({
            headerTitle: '',
            field: 'id',
            entityName: 'Displays_LineItem',
            RenderCell: ListItemPickerControls,
            RenderHeader: SelectedToggle,
        }),
        ...displaysLineItemsEntityTableProps.columns,
    ],
};

const CampaignLineItemPicker = () => {
    const { getById } = useEntityData<Buyer>('Buyer');
    const { reset, getPaginationData } = usePaginatedEntityData<Displays_LineItem>(
        paginationTag,
        'Displays_LineItem',
    );
    const tableRef = useRef<FilterableEntityTableRefAPI>();
    const { showSelected, value } = useSelectedLineItems();

    const { watch } = useFormContext();
    const selectedBuyerId = watch(CampaignFormNames.buyer);

    const buyer = getById({ id: selectedBuyerId });
    const marketId = useMemo(() => {
        if (buyer) {
            return buyer.market_id;
        }
        return null;
    }, [buyer]);

    useEffect(() => {
        getPaginationData({ page: 1, orderBy: 'name', perPage: 100 });
        return () => {
            reset();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateSearchPayload = useCallback<FilterableEntityTableProps['updateSearchPayload']>(
        payload => ({
            ...payload,
            // when we want to show selected line items, we only want results which include our selected line items
            filters: {
                ...payload.filters,
                'filter.id': showSelected ? value : [],
                'filter.market.id': marketId ? [marketId] : [],
            },
        }),
        [showSelected, value, marketId],
    );

    useEffect(() => {
        if (tableRef?.current) {
            // trigger a new search when we want to show/hide selected line items
            tableRef.current.search(1);
        }
    }, [showSelected]);

    const onAddedLineItems = useCallback(() => {
        if (showSelected) {
            tableRef?.current?.search(1);
        }
    }, [showSelected]);

    return (
        <Box>
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                ref={tableRef}
                {...entityTableProps}
                updateSearchPayload={updateSearchPayload}
                tableContainerProps={tableContainerProps}
                tableProps={tableProps}
                smartFilters
                filterFilters
                afterFilters={
                    <BulkAttachFrames
                        onAddedLineItems={onAddedLineItems}
                        marketId={buyer?.market_id}
                    />
                }
            />
        </Box>
    );
};

export default React.forwardRef(CampaignLineItemPicker);
