import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { useEntityPage } from 'c-hooks';
import { AutoGrid, Button, LinkButton } from 'c-components';
import apiClient from 'c-data/apiClient';
import { downloadIcon } from 'c-main/icons';
import { PermissionName, Displays_LineItem } from 'c-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { FileUpload } from '@mui/icons-material';
import { useSetAtom } from 'jotai';
import { atom_uploadPopShotDialogLineItem } from './atoms';

type Props = RenderColumnDataProps<Displays_LineItem>;

const SpecsDownloadPermissions = [PermissionName.UniledportalCampaignSpecsDownload];
const UploadPopshotPermissions = [
    PermissionName.UniledportalFileCreate,
    PermissionName.UniledportalCampaign_pop_shotsCreate,
];

const PopShotLineItemsControlsCell: React.FC<Props> = ({ entity }) => {
    const { id: campaign } = useEntityPage();
    const setPopshotUploadDialogId = useSetAtom(atom_uploadPopShotDialogLineItem);

    const openUploadDialog = useCallback(() => {
        setPopshotUploadDialogId(entity.id);
    }, [entity.id, setPopshotUploadDialogId]);

    const downloadLink = useMemo(
        () =>
            apiClient.Entities.Campaign.downloadLink(campaign, {
                line_item_ids: [entity.id],
                include_display_specs: true,
            }),
        [campaign, entity.id],
    );

    return (
        <AutoGrid xs="auto" gap={1} flexWrap="nowrap">
            <IfHasAllPermissions permissions={SpecsDownloadPermissions}>
                <LinkButton iconChild size="small" to={downloadLink} target="_blank">
                    {downloadIcon}
                </LinkButton>
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={UploadPopshotPermissions}>
                <Button size="small" iconChild onClick={openUploadDialog}>
                    <FileUpload fontSize="inherit" />
                </Button>
            </IfHasAllPermissions>
        </AutoGrid>
    );
};

export default PopShotLineItemsControlsCell;
