import { normalize, Schema } from 'normalizr';
import { getEntityMapper } from '../moduleConfig';
import { CoreThunk } from '../Types/CoreThunk';
import { getEntitySchemaActions } from './EntitySchemaActions';
import mapEntities from './mapEntities';

/**
 * Conditionally upserts entity data into each entity data store.
 *
 * @param baseSchema - pass the baseSchema as an array if you're passing in an array of data. If you're passing in a single entity, pass baseSchema as a single schema.
 * @param data
 */
const updateWithNormalisedData =
    <RootState>(
        baseSchema: Schema,
        data: Record<string, unknown> | Array<Record<string, unknown>>,
    ): CoreThunk<RootState> =>
    async (dispatch, getState) => {
        //
        const entityMap = getEntityMapper();
        const mappedData = mapEntities<RootState>(baseSchema, data, entityMap, getState);
        const normalised = normalize(mappedData, baseSchema).entities;

        const entityNames = Object.keys(normalised);
        entityNames.forEach(name => {
            const actions = getEntitySchemaActions(name);
            if (actions) {
                dispatch(actions.actions.upsertEntities(Object.values(normalised[name])));
            }
        });
    };

export const normaliseDataThunks = {
    updateWithNormalisedData,
};
