import React, { useCallback, useMemo } from 'react';
import { BudgetSummary, PermissionName } from 'c-sdk';
import {
    AutoGrid,
    ControlledFormInput,
    CurrencyFormat,
    Dialog,
    EntityField,
    FlashMessage,
    TableCell,
    TableRow,
    TextField,
} from 'c-components';
import { useBoolean } from 'react-hanger';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Box, IconButton } from '@mui/material';
import { editIcon } from 'c-main/icons';
import { Translate, useCommonTranslation } from 'c-translation';
import { Info } from '@mui/icons-material';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from 'c-data-layer';
import * as yup from 'yup';
import setup from 'c-main/Pages/Campaign/CampaignOverviewPage/CampaignOverviewPage';
import to from 'await-to-js';
import { User, useUpdateEntityData } from 'c-data';
import CampaignBudgetSummaryLineItems from './CampaignBudgetSummaryLineItems';

type Props = {
    summary: BudgetSummary;
    current: boolean;
};

const CampaignCostRow: React.FC<Props> = ({ summary, current }) => {
    const { formatDateString } = useDateUtils();
    const open = useBoolean(false);
    const created = useMemo(
        () => formatDateString({ date: summary.created_at, removeTime: false }),
        [summary.created_at, formatDateString],
    );

    const t = useCommonTranslation();

    return (
        <>
            <TableRow>
                <TableCell>
                    {created}
                    {' - '}
                    <EntityField<User> id={summary.created_by} entityName="User" field="name" />
                </TableCell>
                <TableCell>
                    {summary.lineItems?.length}
                    <IconButton onClick={open.setTrue}>
                        <Info fontSize="inherit" />
                    </IconButton>
                    {open.value && (
                        <Dialog
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.Costs.lineItemsDialogTitle" />
                            }
                            onClose={open.setFalse}
                            show={open.value}
                            content={
                                <CampaignBudgetSummaryLineItems lineItems={summary.lineItems} />
                            }
                            removeForm
                        />
                    )}
                </TableCell>
                <TableCell>
                    <IfHasAllPermissions
                        permissions={[PermissionName.UniledportalBudget_summaryOverride]}
                        otherwise={<CurrencyFormat value={summary.total} />}
                    >
                        <BudgetSummaryOverride summary={summary} current={current} />
                    </IfHasAllPermissions>
                </TableCell>
                <TableCell>
                    {current
                        ? t('Modules.Main.Campaigns.Overview.Costs.statusActive')
                        : t('Modules.Main.Campaigns.Overview.Costs.statusInactive')}
                </TableCell>
                <IfHasAllPermissions
                    permissions={[PermissionName.UniledportalBudget_summaryOverride]}
                >
                    <>
                        <TableCell>{summary.override_reason}</TableCell>
                        <TableCell>
                            <CurrencyFormat value={summary.original_total} />
                        </TableCell>
                    </>
                </IfHasAllPermissions>
            </TableRow>
        </>
    );
};

const overrideFormValidation = yup.object({
    price: yup.number().required().min(0),
    reason: yup.string().required(),
});
const BudgetSummaryOverride: React.FC<Props> = ({ summary, current }) => {
    const open = useBoolean(false);
    const t = useCommonTranslation();
    const { requestState, start, error } = useAPIClientRequest(
        apiClient.Entities.Campaign.overrideBudgetSummary,
    );
    const { upsert } = useUpdateEntityData('Campaign');

    const onSubmit = useCallback(
        async (data: { price: number; reason: string }) => {
            const [_, success] = await to(
                start(
                    summary.campaign_id,
                    data.price,
                    data.reason,
                    setup.systemSearch.defaultIncludes,
                ),
            );

            if (success?.data?.data?.id === summary.campaign_id) {
                upsert(success?.data?.data);
            }

            open.setFalse();
        },
        [open, start, summary.campaign_id, upsert],
    );

    return (
        <>
            <AutoGrid spacing={1} alignItems="center">
                <CurrencyFormat value={summary.total} />
                {current && <IconButton onClick={open.setTrue}>{editIcon}</IconButton>}
            </AutoGrid>
            <Dialog
                title="Modules.Main.Campaigns.Overview.Costs.overrideDialog.title"
                description="Modules.Main.Campaigns.Overview.Costs.overrideDialog.description"
                onClose={
                    requestState === NetworkRequestState.InProgress ? open.setTrue : open.setFalse
                }
                show={open.value}
                content={
                    <Box mt={2}>
                        <AutoGrid spacing={2} xs={12}>
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoFocus
                                        required
                                        type="number"
                                        label={t(
                                            'Modules.Main.Campaigns.Overview.Costs.overrideDialog.priceLabel',
                                        )}
                                    />
                                )}
                                name="price"
                            />
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={5}
                                        required
                                        label={t(
                                            'Modules.Main.Campaigns.Overview.Costs.overrideDialog.reasonLabel',
                                        )}
                                    />
                                )}
                                name="reason"
                            />
                            {error && (
                                <FlashMessage status="error">
                                    {t(
                                        'Modules.Main.Campaigns.Overview.Costs.overrideDialog.failedOverride',
                                    )}
                                </FlashMessage>
                            )}
                        </AutoGrid>
                    </Box>
                }
                validationSchema={overrideFormValidation}
                onSubmit={onSubmit}
                submitDisabled={requestState === NetworkRequestState.InProgress}
            />
        </>
    );
};

export default CampaignCostRow;
