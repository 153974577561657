import { atom, useAtom } from 'jotai/index';
import { Displays_LineItem, ListSearchFilter, ListSearchOptions, ScheduleGet } from 'c-sdk';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { useCallback, useEffect, useMemo } from 'react';
import to from 'await-to-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import uniq from 'lodash/uniq';
import { NetworkRequestState } from 'c-data-layer';
import generateSearchColumnsData from '../../../../Pagination/Lib/generateSearchColumnsData';

interface Item {
    id: number | string;
    name: string;
    owner: string;
    schedules: string[];
    type: string;
    frameId?: string;
}
interface ScheduleOptions {
    page?: number;
    perPage?: number;
    searchables?: string;
}

const transformApiFiltersToFilterModel = (apiFilters: ListSearchFilter[]) =>
    apiFilters
        .filter(filter => filter.expose)
        .map((filter, index) => ({
            ...filter,
            options: filter.options.map(option => ({
                label: option.name as string,
                value: option.value as number,
            })),
        }));

const textSearchColumnsLineItems = ['name', 'owner', 'type'];
const textSearchColumnsLineItemSchedules = ['name'];
const atom_newCampaignId = atom<number>(null as number);
const atom_schdeuleLineItems = atom<Displays_LineItem[]>([]);
const atom_schedules = atom<ScheduleGet[]>([]);
const atom_newSelectedLineItems = atom<Item[]>([]);
const atom_pages = atom<number>(1);
const atom_page = atom<number>(1);
const atom_loading = atom<boolean>(false);
const atom_paginationPage = atom<number>(1);
const atom_paginationTotalPages = atom<number>(0);
const aton_perPage = atom<number>(25);
const atom_rowCount = atom<number>(0);
const atom_targets = atom([]);
const atom_selectedScreens = atom<Item[]>([]);
const atom_prevSelectedScreens = atom<string>('');
const atom_schedulesCurrentPage = atom<number>(1);
const atom_schedulesTotalPages = atom<number>(0);
const atom_schedulesRowCount = atom<number>(0);
const atom_schedulesPerPage = atom<number>(25);
const atom_loadingSchedule = atom<boolean>(false);
const atom_expandedScheduleId = atom<number | null>(null as number);
const atom_lineItemOptions = atom({} as ListSearchOptions);
const atom_lineItemScreenOptions = atom({});
const atom_lineItemScheduleOptions = atom({} as unknown as ScheduleOptions);
const atom_lineItemFilters = atom([]);
const atom_targetsCurrentPage = atom<number>(1);
const atom_targetsTotalPages = atom<number>(0);
const atom_targetsRowCount = atom<number>(0);
const atom_targetsPerPage = atom<number>(25);
const atom_lineItemSearch = atom<string>('');
const atom_scheduleSearch = atom<string>('');
const atom_targetsSearch = atom<string>('');
const atom_AttachStateRequest = atom<NetworkRequestState>(NetworkRequestState.Idle);
const atom_DetachStateRequest = atom<NetworkRequestState>(NetworkRequestState.Idle);

const sortSchedules = (schedules: ScheduleGet[]) =>
    schedules.sort((a, b) => (b.is_default ? 1 : -1) - (a.is_default ? 1 : -1));
const useNewCampaignSchedule = id => {
    const [currentCampaignId, setCurrentCampaignId] = useAtom(atom_newCampaignId);
    const [scheduleLineItems, setScheduleLineItems] = useAtom(atom_schdeuleLineItems);
    const [schedules, setSchedules] = useAtom(atom_schedules);
    const [selected, setSelected] = useAtom(atom_newSelectedLineItems);
    const [pages, setPages] = useAtom(atom_pages);
    const [page, setPage] = useAtom(atom_page);
    const [loading, setLoading] = useAtom(atom_loading);
    const [currentPage, setCurrentPage] = useAtom(atom_paginationPage);
    const [totalPages, setTotalPages] = useAtom(atom_paginationTotalPages);
    const [perPage, setPerPage] = useAtom(aton_perPage);
    const [rowCount, setRowCount] = useAtom(atom_rowCount);
    const [targets, setTargets] = useAtom(atom_targets);
    const [selectedScreens, setSelectedScreens] = useAtom(atom_selectedScreens);
    const [prevSelectedLineItemId, setPrevSelectedLineItemId] = useAtom(atom_prevSelectedScreens);
    const [schedulesCurrentPage, setSchedulesCurrentPage] = useAtom(atom_schedulesCurrentPage);
    const [schedulesTotalPages, setSchedulesTotalPages] = useAtom(atom_schedulesTotalPages);
    const [schedulesRowCount, setSchedulesRowCount] = useAtom(atom_schedulesRowCount);
    const [schedulesPerPage, setSchedulesPerPage] = useAtom(atom_schedulesPerPage);
    const [scheduleLoading, setScheduleLoading] = useAtom(atom_loadingSchedule);
    const [expandedScheduleId, setExpandedScheduleId] = useAtom(atom_expandedScheduleId);
    const [lineItemOptions, setLineItemOptions] = useAtom(atom_lineItemOptions);
    const [lineItemScheduleOptions, setLineItemScheduleOptions] = useAtom(
        atom_lineItemScheduleOptions,
    );
    const [targetsCurrentPage, setTargetsCurrentPage] = useAtom(atom_targetsCurrentPage);
    const [targetsTotalPages, setTargetsTotalPages] = useAtom(atom_targetsTotalPages);
    const [targetsRowCount, setTargetsRowCount] = useAtom(atom_targetsRowCount);
    const [targetsPerPage, setTargetsPerPage] = useAtom(atom_targetsPerPage);
    const [lineItemFilters, setLineItemFilters] = useAtom(atom_lineItemFilters);
    const [lineItemSearch, setLineItemSearch] = useAtom(atom_lineItemSearch);
    const [scheduleSearch, setScheduleSearch] = useAtom(atom_scheduleSearch);
    const [targetsSearch, setTargetsSearch] = useAtom(atom_targetsSearch);
    const [attachStateRequest, setAttachStateRequest] = useAtom(atom_AttachStateRequest);
    const [detachStateRequest, setDetachStateRequest] = useAtom(atom_DetachStateRequest);
    const toggleSelectItem = useCallback(
        (item: Item) => {
            setSelected(prevSelected => {
                if (prevSelected.some(selectedItem => selectedItem.id === item.id)) {
                    return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
                }
                return [...prevSelected, item];
            });
        },
        [setSelected],
    );
    const scheduleIds = useMemo(() => {
        const ids = schedules.map(schedule => schedule.id);
        return uniq(ids);
    }, [schedules]);

    const lineItemIds = useMemo(() => {
        const ids = scheduleLineItems.map(lineItem => lineItem.id);
        return uniq(ids);
    }, [scheduleLineItems]);
    const { start, isLoading } = useAPIClientRequest(ApiClient.Entities.Campaign.scheduleLineItems);

    const { start: startSchedules, isLoading: isLoadingSchedules } = useAPIClientRequest(
        ApiClient.Entities.Campaign.schedules,
    );
    const { start: startTargets, isLoading: isLoadingTargets } = useAPIClientRequest(
        ApiClient.Entities.Campaign.listTargets,
    );

    useEffect(() => {
        if (id !== null && id !== currentCampaignId) {
            setCurrentCampaignId(id);
            setSchedules([]);
            setScheduleLineItems([]);
            setSelected([]);
            setPages(1);
            setPage(1);
            setLoading(false);
            setCurrentPage(1);
            setTotalPages(0);
            setPerPage(25);
            setRowCount(0);
            setTargets([]);
            setSelectedScreens([]);
            setPrevSelectedLineItemId('');
            setSchedulesCurrentPage(1);
            setSchedulesTotalPages(0);
            setSchedulesRowCount(0);
            setSchedulesPerPage(25);
            setScheduleLoading(false);
            setLineItemOptions({});
            setLineItemScheduleOptions({});
            setLineItemFilters([]);
            setTargetsCurrentPage(1);
            setTargetsTotalPages(0);
            setTargetsRowCount(0);
            setTargetsPerPage(25);
            setLineItemFilters([]);
            setLineItemSearch('');
            setScheduleSearch('');
            setTargetsSearch('');
            setAttachStateRequest(NetworkRequestState.Idle);
            setDetachStateRequest(NetworkRequestState.Idle);
        }
    }, [
        currentCampaignId,
        id,
        setCurrentCampaignId,
        setSchedules,
        setScheduleLineItems,
        setSelected,
        setPages,
        setPage,
        setLoading,
        setCurrentPage,
        setTotalPages,
        setPerPage,
        setRowCount,
        setTargets,
        setSelectedScreens,
        setPrevSelectedLineItemId,
        setSchedulesCurrentPage,
        setSchedulesTotalPages,
        setSchedulesRowCount,
        setSchedulesPerPage,
        setScheduleLoading,
        setLineItemOptions,
        setLineItemScheduleOptions,
        setLineItemFilters,
        setTargetsCurrentPage,
        setTargetsTotalPages,
        setTargetsRowCount,
        setTargetsPerPage,
        setLineItemSearch,
        setScheduleSearch,
        setTargetsSearch,
        setAttachStateRequest,
        setDetachStateRequest,
    ]);

    const updateSchedules = useCallback(
        (newSchedules: ScheduleGet[]) => {
            const newScheduleList = [...schedules];

            newSchedules.forEach(schedule => {
                const index = schedules.findIndex(s => s.id === schedule.id);
                if (index == -1) newScheduleList.push(schedule);
                else newScheduleList[index] = schedule;
            });

            setSchedules(newScheduleList);
        },
        [setSchedules, schedules],
    );
    const removeSchedules = useCallback(
        (scheduleIds: number[]) => {
            setSchedules(schedules.filter(s => scheduleIds.indexOf(s.id) === -1));
        },
        [setSchedules, schedules],
    );

    const getScheduleLineItems = useCallback(
        async (
            page = 1,
            perPage = 25,
            orderBy = '',
            direction: 'asc' | 'desc' = 'asc',
            filters = {},
            theSearchTerm = '',
        ) => {
            if (isLoading) {
                return;
            }
            const options: ListSearchOptions = {
                page,
                perPage,
                orderBy,
                direction,
                showFilters: true,
                filters,
                filterFilters: true,
                includes: ['owner', 'frame_id'],
                searchables: generateSearchColumnsData(
                    textSearchColumnsLineItems,
                    theSearchTerm,
                    false,
                ),
            };
            setLineItemOptions(options);
            setLineItemSearch(theSearchTerm);
            const [err, success] = await to(start(id, options));
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                setScheduleLineItems(success.data.data);
                setCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setTotalPages((success?.data as any)?.meta.pagination.total_pages);
                setRowCount((success?.data as any)?.meta.pagination.total);
                setLineItemFilters(transformApiFiltersToFilterModel(success?.data?.meta?.filters));
            }
        },
        [
            isLoading,
            setLineItemOptions,
            setLineItemSearch,
            start,
            id,
            setScheduleLineItems,
            setCurrentPage,
            setTotalPages,
            setRowCount,
            setLineItemFilters,
        ],
    );

    const listTargets = useCallback(
        async (page = 1, perPage = 25, search = '') => {
            setTargetsSearch(search);
            const [err, success] = await to(startTargets(id, [], page, perPage, search));
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                setTargets(success.data.data);
                setTargetsCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setTargetsTotalPages((success?.data as any)?.meta.pagination.total_pages);
                setTargetsRowCount((success?.data as any)?.meta.pagination.total);
            }
        },
        [
            id,
            setTargets,
            setTargetsCurrentPage,
            setTargetsRowCount,
            setTargetsSearch,
            setTargetsTotalPages,
            startTargets,
        ],
    );
    const getSchedules = useCallback(
        async (page = 1, perPage = 25, theSearchTerm = '') => {
            const options = {
                page,
                perPage,
                searchables: theSearchTerm,
            };
            setScheduleSearch(theSearchTerm);
            setLineItemScheduleOptions(options);
            const [err, success] = await to(
                startSchedules(id, ['rules', 'scheduleFile'], page, perPage, theSearchTerm),
            );
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                let defaultSchedule = schedules.find(schedule => schedule.is_default);
                const newSchedules = success.data.data;

                const newDefaultSchedule = newSchedules.find(schedule => schedule.is_default);

                if (newDefaultSchedule) {
                    defaultSchedule = newDefaultSchedule;
                }

                if (defaultSchedule && !newDefaultSchedule) {
                    newSchedules.push(defaultSchedule);
                }
                const sortedSchedules = sortSchedules(newSchedules);
                setSchedules(sortedSchedules);
                setSchedulesTotalPages((success as any).data.meta.pagination.total_pages);
                setSchedulesCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setSchedulesRowCount((success?.data as any)?.meta.pagination.total);
            }
        },
        [
            setScheduleSearch,
            setLineItemScheduleOptions,
            startSchedules,
            id,
            schedules,
            setSchedules,
            setSchedulesTotalPages,
            setSchedulesCurrentPage,
            setSchedulesRowCount,
        ],
    );

    const fetchData = useCallback(() => {
        getScheduleLineItems();
        getSchedules(schedulesCurrentPage, schedulesPerPage, scheduleSearch);
        listTargets();
    }, [
        getScheduleLineItems,
        getSchedules,
        listTargets,
        scheduleSearch,
        schedulesCurrentPage,
        schedulesPerPage,
    ]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading, setLoading]);
    useEffect(() => {
        setScheduleLoading(isLoadingSchedules);
    }, [isLoadingSchedules, setScheduleLoading]);

    const handlePageSizeChange = useCallback(
        newPageSize => {
            setPerPage(newPageSize);
            getScheduleLineItems(
                currentPage,
                newPageSize,
                lineItemOptions.orderBy,
                lineItemOptions.direction,
                lineItemOptions.filters,
                lineItemSearch,
            );
        },
        [
            setPerPage,
            getScheduleLineItems,
            currentPage,
            lineItemOptions.orderBy,
            lineItemOptions.direction,
            lineItemOptions.filters,
            lineItemSearch,
        ],
    );

    const handlePageChange = useCallback(
        newPage => {
            setCurrentPage(newPage);
            getScheduleLineItems(
                newPage,
                perPage,
                lineItemOptions.orderBy,
                lineItemOptions.direction,
                lineItemOptions.filters,
                lineItemSearch,
            );
        },
        [
            setCurrentPage,
            getScheduleLineItems,
            perPage,
            lineItemOptions.orderBy,
            lineItemOptions.direction,
            lineItemOptions.filters,
            lineItemSearch,
        ],
    );
    const handleSchedulesPageChange = useCallback(
        newPage => {
            setSchedulesCurrentPage(newPage);
            getSchedules(newPage, schedulesPerPage, scheduleSearch);
        },
        [getSchedules, scheduleSearch, schedulesPerPage, setSchedulesCurrentPage],
    );

    const handleSchedulesPageSizeChange = useCallback(
        newPageSize => {
            setSchedulesPerPage(newPageSize);
            getSchedules(schedulesCurrentPage, newPageSize, scheduleSearch);
        },
        [getSchedules, schedulesCurrentPage, setSchedulesPerPage, scheduleSearch],
    );

    const handleTargetsPageChange = useCallback(
        newPage => {
            setTargetsCurrentPage(newPage);
            listTargets(newPage, targetsPerPage, targetsSearch);
        },
        [listTargets, targetsPerPage, setTargetsCurrentPage, targetsSearch],
    );

    const handleTargetsPageSizeChange = useCallback(
        newPageSize => {
            setTargetsPerPage(newPageSize);
            listTargets(targetsCurrentPage, newPageSize, targetsSearch);
        },
        [setTargetsPerPage, listTargets, targetsCurrentPage, targetsSearch],
    );

    return useMemo(
        () => ({
            handleTargetsPageChange,
            handleTargetsPageSizeChange,
            fetchData,
            scheduleLineItems,
            schedules,
            getScheduleLineItems,
            getSchedules,
            scheduleIds,
            lineItemIds,
            loading,
            toggleSelectItem,
            selected,
            setSelected,
            setScheduleLineItems,
            pages,
            page,
            handlePageChange,
            currentPage,
            totalPages,
            rowCount,
            perPage,
            setPerPage,
            handlePageSizeChange,
            updateSchedules,
            removeSchedules,
            targets,
            setTargets,
            listTargets,
            selectedScreens,
            setSelectedScreens,
            handleSchedulesPageSizeChange,
            handleSchedulesPageChange,
            schedulesCurrentPage,
            schedulesTotalPages,
            schedulesRowCount,
            schedulesPerPage,
            setPrevSelectedLineItemId,
            prevSelectedLineItemId,
            scheduleLoading,
            expandedScheduleId,
            setExpandedScheduleId,
            lineItemFilters,
            lineItemOptions,
            setLineItemOptions,
            lineItemScheduleOptions,
            isLoadingTargets,
            targetsCurrentPage,
            targetsTotalPages,
            targetsRowCount,
            targetsPerPage,
            setTargetsCurrentPage,
            setTargetsPerPage,
            attachStateRequest,
            setAttachStateRequest,
            setScheduleSearch,
            scheduleSearch,
            detachStateRequest,
            setDetachStateRequest,
        }),
        [
            handleTargetsPageChange,
            handleTargetsPageSizeChange,
            fetchData,
            scheduleLineItems,
            schedules,
            getScheduleLineItems,
            getSchedules,
            scheduleIds,
            lineItemIds,
            loading,
            toggleSelectItem,
            selected,
            setSelected,
            setScheduleLineItems,
            pages,
            page,
            handlePageChange,
            currentPage,
            totalPages,
            rowCount,
            perPage,
            setPerPage,
            handlePageSizeChange,
            updateSchedules,
            removeSchedules,
            targets,
            setTargets,
            listTargets,
            selectedScreens,
            setSelectedScreens,
            handleSchedulesPageSizeChange,
            handleSchedulesPageChange,
            schedulesCurrentPage,
            schedulesTotalPages,
            schedulesRowCount,
            schedulesPerPage,
            setPrevSelectedLineItemId,
            prevSelectedLineItemId,
            scheduleLoading,
            expandedScheduleId,
            setExpandedScheduleId,
            lineItemFilters,
            lineItemOptions,
            setLineItemOptions,
            lineItemScheduleOptions,
            isLoadingTargets,
            targetsCurrentPage,
            targetsTotalPages,
            targetsRowCount,
            targetsPerPage,
            setTargetsCurrentPage,
            setTargetsPerPage,
            attachStateRequest,
            setAttachStateRequest,
            setScheduleSearch,
            scheduleSearch,
            detachStateRequest,
            setDetachStateRequest,
        ],
    );
};
export default useNewCampaignSchedule;
