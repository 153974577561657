import React, { useEffect } from 'react';
import { Box, CardContent, Paper } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { PermissionName } from 'c-sdk';
import { virtualCampaignBuilderIcon } from 'c-main/icons';
import { TransparentCard } from 'c-components';
import ReactGA from 'react-ga';
import VirtualCampaignBuilderForm from './VirtualBuilderCampaignForm';

const VirtualCampaignBuilderPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard
            title={useCommonTranslation('Modules.PopDisplays.VirtualCampaignBuilder.metaTitle')}
        >
            <CardContent>
                <Paper elevation={2}>
                    <Box p={2}>
                        <VirtualCampaignBuilderForm />
                    </Box>
                </Paper>
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'VirtualCampaignBuilderPage',
    component: VirtualCampaignBuilderPage,
    systemSearch: {
        title: 'Modules.PopDisplays.VirtualCampaignBuilder.metaTitle',
        description: 'Modules.PopDisplays.VirtualCampaignBuilder.description',
        type: 'page',
        icon: virtualCampaignBuilderIcon,
        aliases: 'SystemSearch.aliases.popDisplaysVirtualCampaignBuilder',
        route: PostAuthRoutes.PopDisplays.VirtualCampaignBuilder,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.VirtualCampaignBuilder.metaTitle' },
        ],
        permissions: [
            PermissionName.Pop_displaysVirtual_campaignCreate,
            PermissionName.Pop_displaysDisplaysLog_file_parsed_mediaRead,
        ],
    },
};

export default setup;
