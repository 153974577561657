import { Schedule } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { ScheduleState } from './Schedule-types';

const initialState: ScheduleState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: ScheduleState, action: PayloadAction<string>): ScheduleState => state,
};

const slice = createBaseSlice<Schedule, ScheduleState, typeof additionalReducers>({
    name: 'schedule',
    entityName: 'Schedule',
    initialState,
    reducers: additionalReducers,
});

const scheduleSlice = {
    scheduleActions: slice.slice.actions,
    scheduleReducer: slice.slice.reducer,
    scheduleAdapter: slice.adapter,
    scheduleSelectors: slice.selectors,
    scheduleThunks: slice.thunks,
};
export default scheduleSlice;
export const {
    scheduleReducer,
    scheduleActions,
    scheduleAdapter,
    scheduleSelectors,
    scheduleThunks,
} = scheduleSlice;
