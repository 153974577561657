import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { ModuleConstructorProps, User as UserModel, UserPasswordReset } from '../Types';
import { BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

const User = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<UserModel>({ endpoints, httpClient, baseUrl }, 'User'),
    me: (config?: AxiosRequestConfig) =>
        httpClient.get<{ data: UserModel }>(endpoints.Entities.User.me, config),
    updateMe: (user: UserModel, config?: AxiosRequestConfig) =>
        httpClient.put<{ data: UserModel }>(endpoints.Entities.User.updateMe, user, config),
    resendInvitation: (userId: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ data: UserPasswordReset }>(
            urlcat(endpoints.Entities.User.resendInvitation, { id: userId }),
            null,
            config,
        ),
    updateMyPassword: (
        currentPassword: string,
        newPassword: string,
        confirmPassword: string,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            endpoints.Entities.User.changeMyPassword,
            {
                current_password: currentPassword,
                password: newPassword,
                password_confirmation: confirmPassword,
            },
            config,
        ),
});

export default User;
