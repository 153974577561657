import React, { PropsWithChildren, useCallback } from 'react';
import { Box } from '@mui/material';
import { BaseEntity } from 'c-sdk';
import { EntityColumn, EntityListRow, RenderRowProps } from 'c-pagination';
import { MuiRouterLink, TableCell, TableRow } from 'c-components';

const zeroPaddingSx = { p: 0 };

function PaginatedEntityRow<Entity extends BaseEntity>({
    RenderColumnData,
    row,
    columns,
    tag,
    onClick,
    paginationData,
    baseEntityName,
    generateSx,
    disabledRowDividers,
    dense,
}: RenderRowProps<Entity>) {
    const onRowClick = useCallback(
        (event: React.MouseEvent<HTMLTableRowElement>) => {
            onClick?.(event);
        },
        [onClick],
    );
    return (
        <TableRow
            id={`${row.baseEntityName}-${tag}-record-${row.id}`}
            className={`${row.baseEntityName}-${tag}-record`}
            onClick={onRowClick}
            sx={generateSx?.(row.index, row.id)}
            disabledRowDividers={disabledRowDividers}
            dense={dense}
        >
            {columns.map(col => (
                <TableCell
                    key={`${col.entityName}-${col.field}-${col.headerTitle}`}
                    disabledRowDividers={disabledRowDividers}
                    dense={dense}
                    sx={row.link && zeroPaddingSx}
                >
                    <Wrapper row={row}>
                        <EntityColumn
                            column={col}
                            row={row}
                            RenderColumnData={RenderColumnData}
                            paginationData={paginationData}
                            baseEntityName={baseEntityName}
                        />
                    </Wrapper>
                </TableCell>
            ))}
        </TableRow>
    );
}

const Wrapper: React.FC<PropsWithChildren<{ row: EntityListRow }>> = ({ row, children }) => {
    if (row.link) {
        return (
            <MuiRouterLink color="inherit" underline="none" to={row.link}>
                <Box p={2}>{children}</Box>
            </MuiRouterLink>
        );
    }
    return <>{children}</>;
};

export default PaginatedEntityRow;
