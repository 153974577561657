import { AllEntities, BaseEntity } from 'c-sdk';
import {
    generateSlicesResources,
    BaseSliceThunks as BaseSliceThunksCore,
    useAllEntityData as useAllEntityDataCore,
    useCreateEntity as useCreateEntityCore,
    useEntityData as useEntityDataCore,
    useCursorPaginatedEntityData as useCursorPaginatedEntityDataCore,
    usePaginatedEntityData as usePaginatedEntityDataCore,
    useUpdateEntityData as useUpdateEntityDataCore,
    useDeleteEntityData as useDeleteEntityDataCore,
    CustomListSearchGenerator,
} from 'c-data-layer';
import { CommonState } from 'c-types';
import apiClient from './apiClient';
import { commonSchemas } from './schemas';

export const {
    createBaseSlice,
    baseInitialState,
    baseAdapter,
    getBaseSliceSelectors,
    getBaseSliceThunks,
} = generateSlicesResources<CommonState>(commonSchemas, apiClient);

export const useAllEntityData = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    useAllEntityDataCore<CommonState, Entity>(entityName);

export const useCreateEntity = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    useCreateEntityCore<CommonState, Entity>(entityName);

export const useEntityData = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    useEntityDataCore<CommonState, Entity>(entityName);

export const usePaginatedEntityData = <Entity extends BaseEntity>(
    tag: string,
    entityName: keyof AllEntities,
    customListSearch?: CustomListSearchGenerator,
) => usePaginatedEntityDataCore<CommonState, Entity>(tag, entityName, customListSearch);

export const useCursorPaginatedEntityData = <Entity extends BaseEntity>(
    tag: string,
    entityName: keyof AllEntities,
    customListSearch?: CustomListSearchGenerator,
) => useCursorPaginatedEntityDataCore<CommonState, Entity>(tag, entityName, customListSearch);

export const useUpdateEntityData = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    useUpdateEntityDataCore<CommonState, Entity>(entityName);

export const useDeleteEntityData = <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
    useDeleteEntityDataCore<CommonState, Entity>(entityName);

export type BaseSliceThunks<Entity extends BaseEntity> = BaseSliceThunksCore<CommonState, Entity>;
