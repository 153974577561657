import React, { useMemo } from 'react';
import { AllEntities, BaseEntity } from 'c-sdk';
import { EntityStores, getBaseSliceSelectors, SlicePaginationData } from 'c-data-layer';
import { path } from 'ramda';
import { EntityListColumn, EntityListRow, PaginatedTableProps } from 'c-pagination';
import { useSelector } from 'react-redux';
import { useModelFieldTranslation } from 'c-translation';
import columnPaginationFilterOrSearchValue from '../../Lib/columnPaginationFilterOrSearchValue';

type Props<Entity extends BaseEntity> = {
    column: EntityListColumn<Entity>;
    row: EntityListRow;
    RenderColumnData: PaginatedTableProps<Entity>['RenderColumnData'];
    baseEntity?: Entity;
    baseEntityName: keyof AllEntities;
    loadRelation?: boolean;
    paginationData: SlicePaginationData<Entity>;
};

function EntityColumn<Entity extends BaseEntity>({
    column,
    row,
    RenderColumnData,

    baseEntity,
    loadRelation,
    paginationData,
    baseEntityName,
}: Props<Entity>) {
    const mt = useModelFieldTranslation();

    const entityName: keyof AllEntities = useMemo(() => {
        if (loadRelation && baseEntity) {
            return column.entityName;
        }
        return row.baseEntityName;
    }, [row, column, loadRelation, baseEntity]);
    const entityId = useMemo(() => {
        if (loadRelation && baseEntity) {
            return path((column.relationIdKey as string).split('.'), baseEntity) as number;
        }
        return row.id;
    }, [row, column, loadRelation, baseEntity]);

    const entity = useSelector(
        (state: EntityStores<Entity>) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getBaseSliceSelectors(entityName).selectById(state, entityId) as Entity,
    );

    const columnText = useMemo(() => {
        return path(column.field.split('.'), entity) as string;
    }, [column, entity]);

    const lastPart = useMemo(() => column.field.split('.').pop(), [column]);

    const translatedText = useMemo(
        () => mt(entityName, lastPart, columnText),
        [entityName, lastPart, columnText, mt],
    );

    const matchText = useMemo(
        () =>
            columnPaginationFilterOrSearchValue(
                paginationData,
                column,
                baseEntityName,
                columnText,
                translatedText,
                entity,
            ),
        [entity, columnText, column, paginationData, baseEntityName, translatedText],
    );

    const columnData = useMemo(() => {
        // if (entity == null) {
        // console.log('null', column.field, column.entityName);
        // return null;
        // }

        if (column.entityName !== entityName) {
            // need to find relation
            return (
                <EntityColumn
                    row={row}
                    column={column}
                    RenderColumnData={RenderColumnData}
                    baseEntity={entity as Entity}
                    loadRelation
                    paginationData={paginationData}
                    baseEntityName={baseEntityName}
                />
            );
        }

        const defaultContent = (
            <RenderColumnData
                column={column}
                row={row}
                paginationData={paginationData}
                baseEntity={(baseEntity ?? entity) as Entity}
                entity={entity}
                highlightText={matchText}
                columnText={translatedText}
            />
        );

        if (column.RenderCell != null) {
            const RenderCell = column.RenderCell;

            return (
                <RenderCell
                    column={column}
                    row={row}
                    paginationData={paginationData}
                    baseEntity={(baseEntity ?? entity) as Entity}
                    entity={entity}
                    highlightText={matchText}
                    columnText={translatedText}
                    defaultContent={defaultContent}
                />
            );
        }

        return defaultContent;
    }, [
        entity,
        column,
        entityName,
        RenderColumnData,
        row,
        baseEntity,
        matchText,
        translatedText,
        paginationData,
        baseEntityName,
    ]);

    return <>{columnData}</>;
}

export default EntityColumn;
