import React, { useMemo } from 'react';
import { CampaignOwner, CampaignOwnerContactedDate } from 'c-sdk';
import { DialogV2, NumberFormat, Table, TableCell, TableHead, TableRow } from 'c-components';
import { Box, Stack, TableBody, TableContainer, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useDateUtils } from 'c-hooks';
import LastContactedMediaOwnerControls from './LastContactedMediaOwnerControls';
import { UserProfilePopOver } from 'c-admin/Components';
import { userInitials } from 'c-admin/Lib';

type Props = {
    onClose: () => void;
    owners: CampaignOwner[];
    contacts: CampaignOwnerContactedDate[];

    onSaveDate: (ownerId: number, newDate: Date) => void;
    isLoading: boolean;
    overallContent: React.ReactNode;
};

const LastContactedMediaOwnerDetails: React.FC<Props> = ({
    onClose,
    owners,
    contacts,
    onSaveDate,
    isLoading,
    overallContent,
}) => {
    //

    return (
        <DialogV2 open onClose={onClose}>
            <>
                <TableContainer>
                    <Table spacedOutRows>
                        <TableHead>
                            <TableRow isHeader>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.DataStatus.LastContactedMediaOwner.mediaOwnerCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.DataStatus.LastContactedMediaOwner.percentageCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.DataStatus.LastContactedMediaOwner.lastContactedCol',
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {owners.map(own => (
                                <OwnerRow
                                    key={own.owner_id}
                                    owner={own}
                                    allContacts={contacts}
                                    newDateBtn={
                                        <LastContactedMediaOwnerControls
                                            ownerId={own.owner_id}
                                            onSaveDate={onSaveDate}
                                            isLoading={isLoading}
                                        />
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box pr={1}>{overallContent}</Box>
            </>
        </DialogV2>
    );
};

const OwnerRow: React.FC<{
    allContacts: CampaignOwnerContactedDate[];
    owner: CampaignOwner;
    newDateBtn: React.ReactNode;
}> = ({ allContacts, owner, newDateBtn }) => {
    const contacts = useMemo(
        () => allContacts.filter(c => c.owner_id === owner.owner_id),
        [allContacts, owner],
    );
    const { formatDateStringIgnoreTimeDiff } = useDateUtils();

    const contactDates = useMemo(
        () =>
            contacts.map(contact => (
                <Stack key={contact.id} direction="row" alignItems="center" gap={0.5}>
                    <Typography variant="caption" sx={{ pl: 1, pt: 0.25 }}>
                        {formatDateStringIgnoreTimeDiff({
                            date: contact.contacted_at,
                            removeTime: false,
                        })}
                    </Typography>
                    {contact.user != null && (
                        <UserProfilePopOver userId={contact.user_id}>
                            <>
                                -{' '}
                                <Typography variant="caption">
                                    {userInitials(contact.user)}
                                </Typography>
                            </>
                        </UserProfilePopOver>
                    )}
                </Stack>
            )),
        [contacts, formatDateStringIgnoreTimeDiff],
    );

    return (
        <TableRow>
            <TableCell>{owner?.owner?.name}</TableCell>
            <TableCell>
                {owner.playout_percentage != null && (
                    <Box
                        component="span"
                        color={
                            Number(owner.playout_percentage) >= 100 ? 'success.main' : 'error.main'
                        }
                    >
                        <NumberFormat value={owner.playout_percentage} />%
                    </Box>
                )}
            </TableCell>
            <TableCell>
                <Stack gap={1} justifyContent="flex-start">
                    {contactDates}
                    <Box>{newDateBtn}</Box>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default LastContactedMediaOwnerDetails;
