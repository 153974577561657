import { AllEntities, API, BaseEntity } from 'c-sdk';
import { EntitySchemaType } from './Types';
import createBaseSlice from './Slices/Base/base-slice';
import baseAdapter from './Slices/Base/base-adapter';
import baseInitialState from './Slices/Base/base-initialState';
import { getBaseSliceSelectors, getBaseSliceThunks } from './Data';

export const generateSlicesResources = <RootState>(
    entitySchemas: EntitySchemaType,
    apiClient: API,
) => ({
    createBaseSlice: createBaseSlice<RootState>(entitySchemas, apiClient),
    baseAdapter,
    baseInitialState,
    getBaseSliceSelectors: <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
        getBaseSliceSelectors<Entity>(entityName),
    getBaseSliceThunks: <Entity extends BaseEntity>(entityName: keyof AllEntities) =>
        getBaseSliceThunks<Entity, BaseEntity>(entityName),
});
