import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ElasticSearchFrameIdMapDataResponse,
    PCAMapReportPlayStatsMinimal,
    PCAMapReportResponseMinimal,
    PCAReportMetric,
} from 'c-sdk';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { generateHeatMapData, generateMarkerLocations, MarkerLocation } from 'c-main/Lib';
import { HeatmapLayerProps } from '@react-google-maps/api';
import { mapDataToSqlMapData } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';
import { useUrlState, useUrlStateValue } from 'c-hooks';

const MapHeatValues: MapDataType[] = [
    MapDataType.HeatNone,
    MapDataType.HeatActual,
    MapDataType.HeatExpected,
    MapDataType.HeatPerformance,
    MapDataType.HeatInSchedule,
    MapDataType.HeatOutSchedule,
    MapDataType.HeatUnbooked,
];

function usePCAMapState(metric: PCAReportMetric) {
    const [selectedFrameMarker, setSelectedFrameMarker] = useState<string>(null);
    const [pcaData, setPcaData] = useState<
        PCAMapReportResponseMinimal | ElasticSearchFrameIdMapDataResponse
    >(null);

    const actualPcaData = useMemo<PCAMapReportResponseMinimal>(
        () => mapDataToSqlMapData(metric, pcaData as ElasticSearchFrameIdMapDataResponse),
        [pcaData, metric],
    );

    const boundingBoxData = useMemo<google.maps.LatLngBounds>(() => {
        const bounds = new google.maps.LatLngBounds();
        const points: { lat: number; lng: number }[] = [];
        if (actualPcaData == null) {
            console.log('no bounds');
            return bounds;
        }
        Object.keys(actualPcaData.reportData).forEach(key => {
            const [actual, expected, performance, inSchedule, outSchedule, unbooked, lat, lng] =
                actualPcaData.reportData[key];

            const latNum = Number(lat);
            const lngNum = Number(lng);

            // Check if we have valid coordinates
            if (
                !Number.isNaN(latNum) &&
                !Number.isNaN(lngNum) &&
                latNum >= -90 &&
                latNum <= 90 &&
                lngNum >= -180 &&
                lngNum <= 180
            ) {
                points.push({ lat: latNum, lng: lngNum });
                bounds.extend(new google.maps.LatLng(latNum, lngNum));
            }
        });

        const minSize = 1;
        // If we have valid points, check if we need to extend bounds
        if (points.length > 0) {
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            const latDiff = Math.abs(ne.lat() - sw.lat());
            const lngDiff = Math.abs(ne.lng() - sw.lng());

            if (latDiff < minSize || lngDiff < minSize) {
                const center = bounds.getCenter();
                bounds.extend(
                    new google.maps.LatLng(center.lat() + minSize / 2, center.lng() + minSize / 2),
                );
                bounds.extend(
                    new google.maps.LatLng(center.lat() - minSize / 2, center.lng() - minSize / 2),
                );
            }
        } else {
            // If no valid points, set default bounds (e.g., center of map)
            bounds.extend(new google.maps.LatLng(0, 0));
            bounds.extend(new google.maps.LatLng(minSize, minSize));
        }

        return bounds;
    }, [actualPcaData]);

    const mapRef = useRef<google.maps.Map | null>(null);

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            if (map && boundingBoxData) {
                mapRef.current = map;
                map.fitBounds(boundingBoxData);
            }
        },
        [boundingBoxData],
    );

    useEffect(() => {
        const map = mapRef.current;
        if (map && boundingBoxData) {
            // Add a small delay to ensure map is ready
            setTimeout(() => {
                if (mapRef.current) {
                    // Check again in case map was unmounted
                    mapRef.current.fitBounds(boundingBoxData);
                }
            }, 100);
        }
    }, [boundingBoxData]);

    const onMapUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const dataTypesUrlState = useUrlStateValue('pca.map.dataTypes', [MapDataType.HeatActual]);

    const [dataTypes, setDataTypes] = useState<MapDataType[]>(dataTypesUrlState);
    const { push, pushMany } = useUrlState();

    useEffect(() => {
        if (dataTypesUrlState !== dataTypes) {
            setDataTypes(dataTypesUrlState);
        }
    }, [dataTypesUrlState, dataTypes]);

    const selectedFrameInfo = useMemo<PCAMapReportPlayStatsMinimal>(
        () => actualPcaData?.reportData?.[selectedFrameMarker],
        [actualPcaData, selectedFrameMarker],
    );

    const heatLayerSelected = useMemo(
        () => dataTypes.find(t => MapHeatValues.indexOf(t) !== -1),
        [dataTypes],
    );

    const toggleMapType = useCallback(
        (type: MapDataType) => {
            if (dataTypes.indexOf(type) === -1) {
                // new type currently not selected
                let newMapDataTypes = [...dataTypes];
                if (MapHeatValues.indexOf(type) !== -1) {
                    // turn off all heat types
                    newMapDataTypes = newMapDataTypes.filter(t => MapHeatValues.indexOf(t) === -1);
                }

                setDataTypes([...newMapDataTypes, type]);
                push('pca.map.dataTypes', [...newMapDataTypes, type]);
            } else {
                // deselect data type that was already selected
                setDataTypes(dataTypes.filter(t => t !== type));
                push(
                    'pca.map.dataTypes',
                    dataTypes.filter(t => t !== type),
                );
            }
        },
        [dataTypes, push],
    );

    const heatMapData = useMemo<HeatmapLayerProps['data']>(
        () => generateHeatMapData(actualPcaData?.reportData, heatLayerSelected),
        [actualPcaData, heatLayerSelected],
    );

    const markerLocations = useMemo<MarkerLocation[]>(
        () => generateMarkerLocations(actualPcaData?.reportData, heatLayerSelected),
        [actualPcaData, heatLayerSelected],
    );

    const onMarkerClick = useCallback(
        (markerIndex: number) => {
            setSelectedFrameMarker(markerLocations[markerIndex].frameId);
        },
        [markerLocations],
    );

    const repositionedOnce = useRef(false);

    useEffect(() => {
        if (markerLocations.length > 0 && !repositionedOnce.current) {
            // basically repositioning the map on first load when the first set of markers (screens) are loaded
            const bounds = new google.maps.LatLngBounds();
            markerLocations.forEach(loc => {
                if (loc.location != null) bounds.extend(loc.location);
            });

            mapRef?.current?.fitBounds?.(bounds);
            repositionedOnce.current = true;
        }
    }, [markerLocations]);

    return {
        mapRef,
        onMapLoad,
        onMapUnmount,
        selectedFrameMarker,
        selectedFrameInfo,
        pcaData: actualPcaData,
        setPcaData,
        dataTypes,
        toggleMapType,
        heatLayerSelected,
        heatMapData,
        markerLocations,
        onMarkerClick,
    };
}

export default usePCAMapState;
