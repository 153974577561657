import { Resolution } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { ResolutionState } from './Resolution-types';

const initialState: ResolutionState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: ResolutionState, action: PayloadAction<string>): ResolutionState => state,
};

const slice = createBaseSlice<Resolution, ResolutionState, typeof additionalReducers>({
    name: 'resolution',
    entityName: 'Resolution',
    initialState,
    reducers: additionalReducers,
});

const resolutionSlice = {
    resolutionActions: slice.slice.actions,
    resolutionReducer: slice.slice.reducer,
    resolutionAdapter: slice.adapter,
    resolutionSelectors: slice.selectors,
    resolutionThunks: slice.thunks,
};

export default resolutionSlice;
export const {
    resolutionReducer,
    resolutionActions,
    resolutionAdapter,
    resolutionSelectors,
    resolutionThunks,
} = resolutionSlice;
