/* eslint-disable no-param-reassign */
import { ByIdNetworkRequests, ByIdNetworkRequestsActions, NetworkRequestState } from 'c-data-layer';
import { PayloadAction } from '@reduxjs/toolkit';

const createByIdNetworkRequestsActions =
    <State>() =>
    <Prop extends keyof State, Name extends string>(
        prop: Prop,
        name: Name,
    ): State[Prop] extends ByIdNetworkRequests
        ? {
              [K in keyof ByIdNetworkRequestsActions<State> &
                  string as `${K}${Name}ById`]: ByIdNetworkRequestsActions<State>[K];
          }
        : never => {
        return {
            [`start${name}ById`]: (state: State, { payload }: PayloadAction<number | string>) => {
                (state[prop] as any)[payload] = { state: NetworkRequestState.InProgress };
            },
            [`finished${name}ById`]: (
                state: State,
                { payload }: PayloadAction<number | string>,
            ) => {
                (state[prop] as any)[payload] = { state: NetworkRequestState.Success };
            },
            [`failed${name}ById`]: (
                state: State,
                { payload: { id, error } }: PayloadAction<{ id: number | string; error: string }>,
            ) => {
                (state[prop] as any)[id] = { state: NetworkRequestState.Error, error };
            },
            [`reset${name}ById`]: (state: State, { payload }: PayloadAction<number | string>) => {
                (state[prop] as any)[payload] = { state: NetworkRequestState.Idle };
            },
        } as any;
    };

export default createByIdNetworkRequestsActions;
