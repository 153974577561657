import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';
import Avatar from 'c-components/Avatar';
import FileIcon from 'c-components/FileBrowser/FileIcon';
import { Upload } from '@mui/icons-material';
import { popShotToFile } from 'c-main/Lib';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import { useDeleteEntityData } from 'c-data';
import { PermissionName, CampaignPopShot } from 'c-sdk';
import { Checkbox, DeleteEntityDialog } from 'c-components';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import { css } from '@emotion/react';
import { deleteIcon } from 'c-main/icons';
import { onClickStopPropagation } from 'c-lib';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { useUserPermissions } from 'c-auth-module/Hooks';

type Props = {
    popShot: CampaignPopShot;
    index: number;
    onClick: (index: number) => void;
    onDelete: () => void;
    onUpdated: () => void;
};

const deletePerms = [
    PermissionName.UniledportalFileDestroy,
    PermissionName.UniledportalCampaign_pop_shotsRead,
];
const updatePerms = [
    PermissionName.UniledportalFileCreate,
    PermissionName.UniledportalCampaign_pop_shotsUpdate,
];

const controlsClass = 'controls';

const styles = css(`
    .${controlsClass} {
        pointer-events: none;
        opacity: 0;
        transition: all 200ms;
        z-index: 10;
    }
    &:hover {
        .${controlsClass} {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }

`);

const paperSx = { borderRadius: 1, cursor: 'pointer', position: 'relative' };

const PopShotTile: React.FC<Props> = ({ popShot, index, onClick, onDelete, onUpdated }) => {
    const popshotFile = useMemo(() => popShotToFile(popShot), [popShot]);
    const { formatDateString } = useDateUtils();
    const { deleteEntity, getDeletingById } = useDeleteEntityData('CampaignPopShot');
    const onClicked = useCallback(() => onClick(index), [onClick, index]);
    const { value: isDeleteOpen, setFalse: closeDelete, setTrue: openDelete } = useBoolean(false);

    const onDeleteClicked = useCallback(
        e => {
            e.stopPropagation();
            openDelete();
        },
        [openDelete],
    );
    const t = useCommonTranslation();
    const description = useMemo(
        () =>
            t('Modules.Main.Campaigns.PopShots.delete.description', {
                name: popShot?.file?.filename,
            }),
        [popShot, t],
    );

    const isPro = useMemo(() => popShot.type === 'professional', [popShot]);
    const { start, isLoading, hasFailed, error, requestStateRef } = useAPIClientRequest(
        apiClient.Entities.CampaignPopShot.update,
    );

    const togglePro = useCallback(
        async (state: boolean) => {
            const [err, succ] = await to(
                start([{ id: popShot.id, type: state ? 'professional' : 'pop_shot' }]),
            );

            if (!err) onUpdated();
        },
        [start, popShot, onUpdated],
    );

    const { hasAll } = useUserPermissions();
    const canEditOrDelete = useMemo(() => hasAll(deletePerms) || hasAll(updatePerms), [hasAll]);

    const controls = useMemo(
        () => (
            <Box
                position="absolute"
                top={2}
                right={2}
                className={controlsClass}
                onClick={onClickStopPropagation}
            >
                <Paper elevation={2}>
                    <Stack direction="row" gap={1} p={1}>
                        <IfHasAllPermissions permissions={deletePerms}>
                            <IconButton size="small" color="error" onClick={onDeleteClicked}>
                                {deleteIcon}
                            </IconButton>
                        </IfHasAllPermissions>
                        <IfHasAllPermissions permissions={updatePerms}>
                            <Checkbox
                                disabled={isLoading}
                                isBoolean
                                value={isPro}
                                label={t('Modules.Main.Campaigns.PopShots.table.isPro')}
                                icon={isLoading ? <CircularProgress size={24} /> : undefined}
                                checkedIcon={isLoading ? <CircularProgress size={24} /> : undefined}
                                onChange={togglePro}
                            />
                        </IfHasAllPermissions>
                    </Stack>
                </Paper>
            </Box>
        ),
        [isLoading, isPro, onDeleteClicked, t, togglePro],
    );

    return (
        <>
            <DeleteEntityDialog
                entityName="CampaignPopShot"
                title="Modules.Main.Campaigns.PopShots.delete.title"
                description={description}
                show={isDeleteOpen}
                form={null}
                entityId={popShot.id}
                onClose={closeDelete}
                onSuccess={onDelete}
            />
            <Paper key={popshotFile.id} elevation={2} sx={paperSx} onClick={onClicked} css={styles}>
                {canEditOrDelete && controls}
                <Box display="flex" justifyContent="center">
                    <RawFilePreview
                        file={popshotFile}
                        showPdfEmbed={false}
                        showVideoPreview={false}
                        maxHeight={200}
                        imgStyles={{
                            height: 200,
                        }}
                        preferredSize="full"
                        fallback={
                            <Avatar sx={{ borderRadius: 1, height: 200, width: 'auto' }}>
                                <FileIcon file={popshotFile} />
                            </Avatar>
                        }
                    />
                </Box>
                <Stack height={80} gap={2} justifyContent="space-between" alignItems="center" p={2}>
                    {popShot.lineItem != null && (
                        <Box width="100%" overflow="hidden">
                            <Typography variant="body2" align="center" noWrap>
                                <b>{popShot.lineItem.name}</b>
                            </Typography>
                        </Box>
                    )}
                    <Stack
                        gap={1}
                        justifyContent="center"
                        overflow="hidden"
                        width="100%"
                        textAlign="center"
                    >
                        <Typography noWrap variant="caption" align="center">
                            {popShot.file.filename}
                        </Typography>
                        <Box mx="auto">
                            <Typography
                                noWrap
                                variant="caption"
                                align="center"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Upload fontSize="inherit" />{' '}
                                {formatDateString({
                                    date: popShot.created_at,
                                    removeTime: false,
                                })}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
};

export default PopShotTile;
