import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NetworkRequestState } from 'c-data-layer';
import { TranslationPath as CommonTranslationPath, useCommonTranslation } from 'c-translation';
import Button from '../Button';
import { ButtonProps } from '@mui/material';

const SubmitButton = <TranslationPath extends string = CommonTranslationPath>({
    id = 'button-submit',
    state,
    label,
    disabled,
    form,
    className,
    color,
}: {
    id?: string;
    state?: NetworkRequestState;
    label?: TranslationPath;
    disabled?: boolean;
    form?: string;
    className?: string;
    color?: ButtonProps['color'];
}) => (
    <Button
        id={id}
        type="submit"
        disabled={
            !useFormContext()?.formState?.isValid ||
            disabled ||
            state === NetworkRequestState.InProgress
        }
        form={form}
        className={className}
        color={color}
    >
        {useCommonTranslation(label ?? 'SubmitButton.defaultLabel')}
    </Button>
);

export default SubmitButton;
