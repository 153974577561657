import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import {
    isCampaignArchived,
    isCampaignLive,
    isCampaignPast,
    isCampaignUpcoming,
} from 'c-main/Lib/Campaign';
import { Chip } from 'c-components';
import { ChipProps } from '@mui/material';

type Props = RenderColumnDataProps<Campaign>;

const CampaignStatusTableCell: React.FC<Props> = ({ entity }) => {
    const isArchived = useMemo(() => isCampaignArchived(entity), [entity]);

    const isUpcoming = useMemo(() => isCampaignUpcoming(entity), [entity]);
    const isLive = useMemo(() => isCampaignLive(entity), [entity]);
    const isPast = useMemo(() => isCampaignPast(entity), [entity]);
    const t = useCommonTranslation();

    const chip = useMemo(() => {
        let label = null;
        let color: ChipProps['color'] = 'default';
        if (isArchived) label = <>{t('Modules.Main.Campaigns.List.scopes.archived')}</>;
        else if (isUpcoming) {
            color = 'warning';
            label = <>{t('Modules.Main.Campaigns.List.scopes.upcoming')}</>;
        } else if (isLive) {
            color = 'success';
            label = <>{t('Modules.Main.Campaigns.List.scopes.live')}</>;
        } else if (isPast) {
            color = 'error';
            label = <>{t('Modules.Main.Campaigns.List.scopes.past')}</>;
        }

        return <Chip label={label} variant="outlined" color={color} />;
    }, [isArchived, isLive, isPast, isUpcoming, t]);

    if (entity.date == null) return <></>;

    return chip;
};

export default CampaignStatusTableCell;
