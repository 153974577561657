import { Displays_AudienceUpload as ScreenModel, ModuleConstructorProps } from '../Types';
import { BaseEntityModuleFuncs, uploadManualAudienceFunc } from './BaseEntityModuleFuncs';

const Displays_AudienceUpload = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        'Displays_AudienceUpload',
    ),
    ...uploadManualAudienceFunc<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_AudienceUpload.uploadManualAudience,
    ),
});

export default Displays_AudienceUpload;
