import React, { useState, useMemo, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Typography,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Paper,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useCommonTranslation } from 'c-translation';
import { Alert } from 'c-components';

const ParamsTable = ({ params, param, onDeleteSelected, loading, getParamsLoading, delError }) => {
    const t = useCommonTranslation();
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());

    const allSelected = useMemo(
        () => params.length > 0 && params.every(paramData => selectedIds.has(paramData.id)),
        [params, selectedIds],
    );
    const sortedParams = useMemo(
        () => [...params].sort((a, b) => b.media.length - a.media.length),
        [params],
    );

    const handleSelectAll = useCallback(() => {
        setSelectedIds(prevSelected => {
            if (allSelected) {
                return new Set();
            }
            return new Set(params.map(paramData => paramData.id));
        });
    }, [allSelected, params]);

    const handleCheckboxChange = useCallback((id: number) => {
        setSelectedIds(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }
            return newSelected;
        });
    }, []);

    const handleDeleteSelected = useCallback(() => {
        if (onDeleteSelected) {
            onDeleteSelected([...selectedIds]);
        }
        setSelectedIds(new Set());
    }, [onDeleteSelected, selectedIds]);

    const handleDeleteRow = useCallback(
        (id: number) => {
            onDeleteSelected([id]);
            setSelectedIds(prev => {
                const newSelected = new Set(prev);
                newSelected.delete(id);
                return newSelected;
            });
        },
        [onDeleteSelected],
    );

    return (
        <Box width="100%">
            {params?.length > 0 && (
                <TableContainer>
                    <Table component={Paper} sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={allSelected}
                                        indeterminate={
                                            selectedIds.size > 0 && selectedIds.size < params.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.paramsEditDialog.identifier',
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.paramsEditDialog.mediaCount',
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.mediaTable.paramsEditDialog.actions',
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedParams.map(paramData => (
                                <TableRow key={paramData.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedIds.has(paramData.id)}
                                            onChange={() => handleCheckboxChange(paramData.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{paramData.identifier}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{paramData.media.length}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeleteRow(paramData.id)}
                                            disabled={loading}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {params?.length === 0 && (
                <Typography align="center" color="textSecondary">
                    {t(
                        'Modules.Main.Campaigns.CampaignSchedule.mediaTable.paramsEditDialog.noneFound',
                        { param },
                    )}
                </Typography>
            )}
            <Box mt={2} display="flex" justifyContent="flex-start" gap={2}>
                {params?.length > 0 && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteSelected}
                        disabled={selectedIds.size === 0 || loading}
                    >
                        {loading || getParamsLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            t(
                                'Modules.Main.Campaigns.CampaignSchedule.mediaTable.paramsEditDialog.deleteSelected',
                            )
                        )}
                    </Button>
                )}
            </Box>
            <Box pt={1}>{delError && <Alert severity="error">{String(delError)}</Alert>}</Box>
        </Box>
    );
};

export default ParamsTable;
