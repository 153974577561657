import React, { useCallback, useMemo, useState } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_LineItem } from 'c-sdk';
import { Button, DialogV2 } from 'c-components';
import { Box, Stack, Typography } from '@mui/material';
import FileBrowserCarouselView from 'c-components/FileBrowser/FileBrowserCarouselView';
import { popShotToFile } from 'c-main/Lib';
import CampaignPopShotThumbnail from 'c-main/Components/Campaign/CampaignPopShot/CampaignPopShotThumbnail';
import { Translate } from 'c-translation';

type Props = RenderColumnDataProps<Displays_LineItem>;

const PreviewIconSx = {
    '&:hover': { transform: 'scale(1.5)' },
    transition: '400ms all',
    cursor: 'pointer',
};

const numberOfPreviews = 5;
/**
 * We're assuming this component will be used in on a page which includes the `campaignPopShots.file` relationship on line items.
 */
const LineItemPopShotsCell: React.FC<Props> = ({ entity }) => {
    const [openPreviewIndex, setOpenPreviewIndex] = useState<number>(null);

    const allFiles = useMemo(
        () => entity?.campaignPopShots?.map(popshot => popShotToFile(popshot)) ?? [],
        [entity],
    );
    const openPreviewFile = useMemo(
        () => (openPreviewIndex != null ? allFiles[openPreviewIndex] : null),
        [allFiles, openPreviewIndex],
    );
    const closePreview = useCallback(() => {
        setOpenPreviewIndex(null);
    }, []);
    const openPreviewPlus = useCallback(() => {
        setOpenPreviewIndex(numberOfPreviews);
    }, []);
    const filePreviews = useMemo(
        () =>
            entity?.campaignPopShots
                ?.slice(0, numberOfPreviews)
                ?.reduce((previews, popshot, currentIndex) => {
                    const file = popshot.file;
                    const index = Number(currentIndex);
                    if (file) {
                        previews.push(
                            <Box
                                height={35}
                                width={35}
                                mr={1.5}
                                sx={PreviewIconSx}
                                key={file.id}
                                onClick={() => setOpenPreviewIndex(index)}
                            >
                                <CampaignPopShotThumbnail popShot={popshot} />
                            </Box>,
                        );
                    }
                    return previews;
                }, [] as React.ReactNode[]) ?? [],
        [entity],
    );

    const diff = useMemo(
        () =>
            entity?.campaignPopShots?.length > numberOfPreviews
                ? Math.abs((entity?.campaignPopShots?.length ?? 0) - numberOfPreviews)
                : 0,
        [entity],
    );

    return (
        <>
            {allFiles.length === 0 && (
                <Typography variant="caption">
                    <Translate path="Modules.Main.Campaigns.Create.LineItemsTable.popShotsNoImages" />
                </Typography>
            )}
            {allFiles.length > 0 && (
                <Stack direction="row" gap={0.5}>
                    {filePreviews}
                    {diff > 0 && (
                        <Button variant="text" size="small" onClick={openPreviewPlus}>
                            + {diff}
                        </Button>
                    )}
                </Stack>
            )}
            <DialogV2
                open={openPreviewFile != null}
                onClose={closePreview}
                maxWidth={'90vw' as any}
            >
                {openPreviewFile && (
                    <Box height="85vh">
                        <FileBrowserCarouselView
                            files={allFiles}
                            onFileClick={null}
                            ListWrapper={null}
                            filters={{ aspectRatios: [], resolutions: [], term: '', fileTypes: [] }}
                            onFileToggled={null}
                            selectedFiles={null}
                            preferredSize="raw"
                            maxHeight="65vh"
                            initialSelectedFileIndex={openPreviewIndex}
                        />
                    </Box>
                )}
            </DialogV2>
        </>
    );
};

export default LineItemPopShotsCell;
