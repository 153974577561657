import { PopDisplays_JobStatus } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_JobStatusState } from './JobStatus-types';

const initialState: PopDisplays_JobStatusState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PopDisplays_JobStatusState, action: PayloadAction<string>): PopDisplays_JobStatusState => state,
};

const slice = createBaseSlice<
    PopDisplays_JobStatus,
    PopDisplays_JobStatusState,
    typeof additionalReducers
>({
    name: 'jobStatus',
    entityName: 'PopDisplays_JobStatus',
    initialState,
    reducers: additionalReducers,
});

const jobStatusSlice = {
    jobStatusActions: slice.slice.actions,
    jobStatusReducer: slice.slice.reducer,
    jobStatusAdapter: slice.adapter,
    jobStatusSelectors: slice.selectors,

    jobStatusThunks: slice.thunks,
};

export default jobStatusSlice;
export const {
    jobStatusReducer,
    jobStatusActions,
    jobStatusAdapter,
    jobStatusSelectors,
    jobStatusThunks,
} = jobStatusSlice;
