import { Displays_AnalogueScreen as ScreenModel, ModuleConstructorProps } from '../Types';
import { BaseEntityModuleFuncs, brochureFuncs } from './BaseEntityModuleFuncs';

const Displays_AnalogueScreen = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        'Displays_AnalogueScreen',
    ),
    ...brochureFuncs<ScreenModel>(
        { endpoints, httpClient, baseUrl },
        endpoints.Entities.Displays_AnalogueScreen.brochure,
        endpoints.Entities.Displays_AnalogueScreen.brochure,
    ),
});

export default Displays_AnalogueScreen;
