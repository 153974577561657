import React from 'react';
import { EntityListColumn } from 'c-pagination';
import { Displays_LineItem } from 'c-sdk';
import { Box } from '@mui/material';
import { AutoGrid, Button } from 'c-components';
import useSelectedLineItems from './useSelectedLineItems';

/**
 * There are lots of 'broken' urls in the DB
 *
 * Some URLs which are just paths to local file systems and URLs which aren't really URLs.
 *
 * So just doing some basic checks.
 */
// const isValidUrl = (url?: string) => {
//     if (!url) {
//         return false;
//     }
//
//     try {
//         // in case run on an old browser which doesn't support urls
//         return ['http:', 'https:'].indexOf(new URL(url).protocol) !== -1;
//     } catch {
//         return url?.indexOf('http:') !== -1 || url?.indexOf('https:') !== -1;
//     }
// };

const ListItemPickerControls: EntityListColumn<Displays_LineItem>['RenderCell'] = ({ entity }) => {
    const { add, remove, isSelected } = useSelectedLineItems();

    // const validBrochureFile = useMemo(() => isValidUrl(entity.brochure), [entity.brochure]);
    // const validBrochureLink = useMemo(
    //     () => isValidUrl(entity.brochure_link),
    //     [entity.brochure_link],
    // );
    return (
        <>
            <Box width="100%">
                <AutoGrid spacing={1} justifyContent="center">
                    {!isSelected(entity.id) && (
                        <Button size="small" variant="outlined" onClick={() => add([entity.id])}>
                            Add
                        </Button>
                    )}
                    {isSelected(entity.id) && (
                        <Button
                            size="small"
                            color="success"
                            variant="outlined"
                            onClick={() => remove(entity.id)}
                        >
                            Added
                        </Button>
                    )}

                    {/* {!isSelected(entity.id) && ( */}
                    {/*    <IconButton size="small" onClick={() => add(entity.id)}> */}
                    {/*        <AddCircle /> */}
                    {/*    </IconButton> */}
                    {/* )} */}
                    {/* { isSelected(entity.id) && ( */}
                    {/*    <IconButton size="small" onClick={() => remove(entity.id)}> */}
                    {/*        <Delete /> */}
                    {/*    </IconButton> */}
                    {/* )} */}

                    {/* {isSelected && ( */}
                    {/*    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}> */}
                    {/*        <IconButton size="small" onClick={remove}> */}
                    {/*            <Delete /> */}
                    {/*        </IconButton> */}
                    {/*        <Box display="inline" ml={1}> */}
                    {/*            <Chip */}
                    {/*                variant="outlined" */}
                    {/*                color="success" */}
                    {/*                label={ */}
                    {/*                    <Box display="flex" alignItems="center"> */}
                    {/*                        <Translate path="Modules.Main.Campaigns.Create.LineItemsTable.lineItemSelected" /> */}
                    {/*                        <CheckCircle fontSize="inherit" /> */}
                    {/*                    </Box> */}
                    {/*                } */}
                    {/*            /> */}
                    {/*        </Box> */}
                    {/*    </Box> */}
                    {/* )} */}

                    {/*{validBrochureLink && (*/}
                    {/*    <Tooltip title={entity.brochure_link}>*/}
                    {/*        <IconButton size="small" target="_blank" href={entity.brochure_link}>*/}
                    {/*            <Info />*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*)}*/}

                    {/*{validBrochureFile && (*/}
                    {/*    <Tooltip title={entity.brochure}>*/}
                    {/*        <IconButton size="small" target="_blank" href={entity.brochure}>*/}
                    {/*            <Info />*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*)}*/}
                </AutoGrid>
            </Box>
        </>
    );
};

export default ListItemPickerControls;
