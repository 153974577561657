import { BaseEntity } from 'c-sdk';
import { SearchPayload, SlicePaginationData } from '../Types';

function paginatedDataToSearchPayload<Entity extends BaseEntity>(
    paginationData: SlicePaginationData<Entity>,
    overrides: Partial<SearchPayload<Entity>> = {},
): SearchPayload<Entity> {
    return {
        ...paginationData,
        ...overrides,
    };
}

export default paginatedDataToSearchPayload;
