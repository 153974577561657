import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, AutoGrid, Button, Checkbox } from 'c-components';
import { Box, CircularProgress, lighten, List, Stack, Typography } from '@mui/material';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { CampaignPopShot } from 'c-sdk';
import { useTheme } from '@mui/styles';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import PopShotUploadListItem from './PopShotUploadListItem';

type Props = {
    lineItemId: string;
    initialFilesToUpload?: File[];
};

const empty = [];

const isValidFilename = filename => {
    const disallowedChars = /[\\/:*?"<>|~]/;
    return !disallowedChars.test(filename);
};

const CampaignPopShotUpload: React.FC<Props> = ({ lineItemId, initialFilesToUpload = empty }) => {
    const [fileNameError, setFileNameError] = useState<string | null>(null);
    const theme = useTheme();
    const dropzoneBg = useMemo(() => lighten(theme.palette.secondary.main, 0.85), [theme]);

    const [uploadFiles, setUploadFiles] = useState<Record<string, File>>({});
    const [uploadedPopShots, setUploadedPopShots] = useState<CampaignPopShot[]>([]);
    const [failedFiles, setFailedFiles] = useState<string[]>([]);

    const allFilesUploaded = useMemo(
        () =>
            Object.keys(uploadFiles ?? {}).length === uploadedPopShots.length + failedFiles.length,
        [failedFiles.length, uploadFiles, uploadedPopShots.length],
    );

    const onUploadedFile = useCallback((id: string, popshot: CampaignPopShot) => {
        setUploadedPopShots(curr => [...curr, popshot]);
    }, []);
    const onUploadedFileFailed = useCallback((id: string) => {
        setFailedFiles(curr => [...curr, id]);
    }, []);

    const addFiles = useCallback((files: File[]) => {
        setFileNameError(null);
        const invalidFiles = [];

        setUploadFiles(val => {
            const newFiles: Record<string, File> = {};

            files.forEach(file => {
                if (isValidFilename(file.name)) {
                    let uniqueId = null;
                    while (uniqueId == null) {
                        const newId = String(Math.random());
                        if (val[newId] == null && newFiles[newId] == null) uniqueId = newId;
                    }
                    newFiles[uniqueId] = file;
                } else {
                    invalidFiles.push(file.name);
                }
            });

            return { ...val, ...newFiles };
        });

        if (invalidFiles.length > 0) {
            setFileNameError(
                `Some files were skipped due to invalid characters in their names: \n${invalidFiles.join(
                    '\n',
                )}    \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
            );
        }
    }, []);

    useEffect(() => {
        setUploadedPopShots([]);
        setFailedFiles([]);
        addFiles(initialFilesToUpload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDrop = useCallback(
        (data: { files: File[] }) => {
            addFiles(data.files);
        },
        [addFiles],
    );
    const onChange = useCallback(
        e => {
            const files: FileList = e.target.files;

            if (files.length > 0 && files.item(0)) {
                addFiles(Array.from(files));
                // reset selected files so the same file can be selected again and trigger change event
                e.target.value = '';
            }
        },
        [addFiles],
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        {
            accept: NativeTypes.FILE,
            drop: onDrop,
            collect: monitor => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
        },
        [onDrop],
    );

    const fileInputRef = useRef<HTMLInputElement>();

    const onBrowseClick = useCallback(() => {
        fileInputRef?.current?.click();
    }, []);

    const filesUploading = Object.keys(uploadFiles ?? {}).length > 0;
    const t = useCommonTranslation();
    const { value: isPro, setValue: togglePro } = useBoolean(false);

    const { start, isLoading, hasFailed, error, hasSucceeded } = useAPIClientRequest(
        apiClient.Entities.CampaignPopShot.update,
    );

    const onTogglePro = useCallback(
        async (isPro: boolean) => {
            togglePro(isPro);
            const [err, succ] = await to(
                start(
                    uploadedPopShots.map(pop => ({
                        id: pop.id,
                        type: !isPro ? 'pop_shot' : 'professional',
                    })),
                ),
            );
            if (!err && Array.isArray(succ?.data?.data)) {
                // update local copies of popshots to new data
                setUploadedPopShots(curr =>
                    curr.map(popshot => succ.data.data.find(p => p.id === popshot.id) ?? popshot),
                );
            }
        },
        [start, togglePro, uploadedPopShots],
    );

    const proCheckboxDisabled = useMemo(
        () => !allFilesUploaded && !isLoading,
        [allFilesUploaded, isLoading],
    );
    return (
        <>
            <Stack gap={2}>
                <Checkbox
                    isBoolean
                    label={t('Modules.Main.Campaigns.PopShots.upload.isProLabel')}
                    value={isPro}
                    onChange={onTogglePro}
                    icon={isLoading ? <CircularProgress size={24} /> : undefined}
                    checkedIcon={isLoading ? <CircularProgress size={24} /> : undefined}
                    disabled={proCheckboxDisabled}
                />
                {hasFailed && <Alert severity="error">{String(error)}</Alert>}
            </Stack>
            <AutoGrid spacing={2} xs={12}>
                <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                    <Box display="none">
                        <input
                            accept="image/*,video/*,application/pdf"
                            type="file"
                            id="file-upload"
                            multiple
                            onChange={onChange}
                            ref={fileInputRef}
                        />
                    </Box>
                    <Box
                        ref={drop}
                        mt={2}
                        p={4}
                        height={!filesUploading ? 350 : undefined}
                        sx={{
                            borderWidth: 2,
                            borderStyle: canDrop ? 'dashed' : 'none',
                            borderColor: isOver && canDrop ? 'success.main' : 'primary.main',
                            borderRadius: 1,
                        }}
                        bgcolor={dropzoneBg}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box textAlign="center">
                            <Typography
                                variant="h1"
                                color={isOver && canDrop ? 'success.main' : 'primary'}
                            >
                                <CloudUploadOutlinedIcon
                                    fontSize="large"
                                    sx={{ fontSize: '2em' }}
                                />
                            </Typography>
                            <Typography
                                variant="body1"
                                color={isOver && canDrop ? 'success.main' : undefined}
                            >
                                {useCommonTranslation(
                                    'Modules.Main.CreativeManagement.creatives.controls.upload.dropzoneText',
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </label>
                {fileNameError && <Alert severity="error">{fileNameError}</Alert>}

                {!filesUploading && (
                    <Box textAlign="center" mt={2}>
                        <Button variant="outlined" onClick={onBrowseClick}>
                            {t(
                                'Modules.Main.CreativeManagement.creatives.controls.upload.browseBtnLabel',
                            )}
                        </Button>
                    </Box>
                )}
                <Box>
                    <List disablePadding>
                        {Object.entries(uploadFiles).map(([id, file]) => (
                            <PopShotUploadListItem
                                key={id}
                                id={id}
                                file={file}
                                lineItemId={lineItemId}
                                onUploadComplete={onUploadedFile}
                                onUploadFailed={onUploadedFileFailed}
                                pro={isPro}
                            />
                        ))}
                    </List>
                </Box>
            </AutoGrid>
        </>
    );
};

export default CampaignPopShotUpload;
