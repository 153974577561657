import { CommonState } from 'c-types';
import { createSelector } from '@reduxjs/toolkit';
import { EntityStores, NetworkRequestState } from 'c-data-layer';

export const isOpen = (state: CommonState) => state.systemSearch.open;

export const searchTerm = (state: CommonState) => state.systemSearch.searchTerm;

export const entityLoadingStates = createSelector(
    (state: CommonState) => state.systemSearch.entityLoading,
    loading => loading,
);

export const entityLoadingStateByTag = createSelector(
    entityLoadingStates,
    (state: EntityStores, tag: string) => tag,
    (loading, tag) => loading[tag]?.state ?? NetworkRequestState.Idle,
);

export const entitySearchTimeByTag = createSelector(
    entityLoadingStates,
    (state: EntityStores, tag: string) => tag,
    (loading, tag) => loading[tag]?.searchTime ?? null,
);
