import { HeatmapLayerProps } from '@react-google-maps/api';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { Campaign, PCAMapReportResponseMinimal } from 'c-sdk';

export type MarkerLocation = {
    location: google.maps.LatLng;
    frameId: string;
    hidden: boolean;
};

const defaultMapCenter = {
    lat: 54.434379,
    lng: -6.7816466,
};

const mapCenters = {
    'United States of America': {
        lat: 39.8283,
        lng: -98.5795,
    },
    Australia: {
        lat: -25.727363,
        lng: 135.126994,
    },
    'United Kingdom': defaultMapCenter,
};

const mapZoom = {
    'United States of America': 4.5,
    Australia: 5,
    'United Kingdom': 5.5,
};

export function getCampaignReportingMapCenter(campaign: Campaign) {
    return mapCenters[campaign?.market?.name] ?? defaultMapCenter;
}

export function getCampaignReportingMapZoom(campaign: Campaign) {
    return mapZoom[campaign?.market?.name] ?? 5.5;
}

export function generateHeatMapData(
    data: PCAMapReportResponseMinimal['reportData'],
    heatLayer: MapDataType,
): HeatmapLayerProps['data'] {
    if (data == null || heatLayer === MapDataType.HeatNone) {
        return [];
    }

    return Object.keys(data).reduce((acc, curr) => {
        const [actual, expected, performance, inSchedule, outSchedule, unbooked, lat, lng] =
            data[curr];
        let heatValue = actual;
        if (heatLayer === MapDataType.HeatExpected) heatValue = expected;
        if (heatLayer === MapDataType.HeatPerformance) heatValue = Number(performance);
        if (heatLayer === MapDataType.HeatInSchedule) heatValue = Number(inSchedule);
        if (heatLayer === MapDataType.HeatOutSchedule) heatValue = Number(outSchedule);
        if (heatLayer === MapDataType.HeatUnbooked) heatValue = Number(unbooked);

        if (heatValue === 0) return acc;

        acc.push({
            location: new google.maps.LatLng(Number(lat), Number(lng)),
            weight: heatValue,
        });
        return acc;
    }, [] as google.maps.visualization.WeightedLocation[]);
}

export function generateMarkerLocations(
    data: PCAMapReportResponseMinimal['reportData'],
    heatLayer: MapDataType,
) {
    if (data == null) {
        return [];
    }

    return Object.keys(data).reduce((acc, frameId) => {
        const [
            actual,
            expected,
            percentage,
            inSchedule,
            outSchedule,
            unbooked,
            latitude,
            longitude,
        ] = data[frameId];

        const isValidLatLng =
            !Number.isNaN(latitude) &&
            !Number.isNaN(longitude) &&
            latitude >= -90 &&
            latitude <= 90 &&
            longitude >= -180 &&
            longitude <= 180;

        if (!isValidLatLng) return acc;

        const markerLoc: MarkerLocation = {
            frameId,
            location: isValidLatLng
                ? new google.maps.LatLng(Number(latitude), Number(longitude))
                : undefined,
            hidden: true,
        };

        if (heatLayer != null && heatLayer !== MapDataType.HeatNone) {
            if (heatLayer === MapDataType.HeatActual && actual > 0) {
                markerLoc.hidden = false;
            } else if (heatLayer === MapDataType.HeatExpected && expected > 0) {
                markerLoc.hidden = false;
            } else if (heatLayer === MapDataType.HeatPerformance && +percentage > 0) {
                markerLoc.hidden = false;
            } else if (heatLayer === MapDataType.HeatInSchedule && inSchedule > 0) {
                markerLoc.hidden = false;
            } else if (heatLayer === MapDataType.HeatOutSchedule && outSchedule > 0) {
                markerLoc.hidden = false;
            } else if (heatLayer === MapDataType.HeatUnbooked && unbooked > 0) {
                markerLoc.hidden = false;
            }
        } else {
            markerLoc.hidden = false;
        }

        acc.push(markerLoc);
        return acc;
    }, [] as MarkerLocation[]);
}

// https://mapstyle.withgoogle.com/

export const ReportingMapStyles = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry',
        stylers: [
            {
                color: '#7b777b',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry',
        stylers: [
            {
                color: '#7b777b',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#8f8f8f',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
];

export const ReportingMapOptions = {
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    styles: ReportingMapStyles,
    streetViewControl: false,
    fullscreenControl: false,
};
