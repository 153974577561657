import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { PCAReportEnvironment, PCAReportRequestPayload } from '../Types/Reports';
import { generateArrayQSParams } from '../Lib';
import {
    ElasticSearchFilters,
    ElasticSearchFrameIdMapDataResponse,
    ElasticSearchFrameIdRequestPayload,
    ElasticSearchFrameIdStatsDataResponse,
    ElasticSearchGroupByFieldRequestPayload,
    ElasticSearchGroupByFieldResponse,
    ElasticSearchHistogramRequestPayload,
    ElasticSearchHistogramResponse,
    ElasticSearchSummaryRequestPayload,
    ElasticSearchSummaryResponse,
    EsPCAReportFilterField,
    ModuleConstructorProps,
} from '../Types';

const ReportsElasticSearch = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    pcaGroupByField: (
        {
            campaignId,
            startDate,
            endDate,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
            field,
            showStats = false,
            showFilters = true,
            dataStatus,
            source,
        }: ElasticSearchGroupByFieldRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start_date: startDate,
            end_date: endDate,
            environment,
            showFilters,
            source,
        };
        if (showStats) {
            // @ts-ignore
            data.showStats = true;
        }

        return httpClient.post<ElasticSearchGroupByFieldResponse>(
            `${urlcat(endpoints.reports.pcaElasticSearch.groupByField, data)}`,
            { ...generateFieldFilterObject(fieldFilters), data_status: dataStatus, field },
            config,
        );
    },
    histogram: (
        {
            campaignId,
            interval,
            startDate,
            endDate,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
            source,
            field,
            showStats = false,
            dataStatus,
        }: ElasticSearchHistogramRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start_date: startDate,
            end_date: endDate,
            environment,
            source,
            interval,
        };
        if (showStats) {
            // @ts-ignore
            data.showStats = true;
        }

        return httpClient.post<ElasticSearchHistogramResponse>(
            `${urlcat(endpoints.reports.pcaElasticSearch.histogram, data)}`,
            { ...generateFieldFilterObject(fieldFilters), data_status: dataStatus, field },
            config,
        );
    },
    frameIdMapData: (
        {
            campaignId,
            startDate,
            endDate,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
            showStats = false,
            dataStatus,
            source,
            showFilters = false,
        }: ElasticSearchFrameIdRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start_date: startDate,
            end_date: endDate,
            environment,
            source,
        };

        if (showStats) {
            // @ts-ignore
            data.showStats = true;
        }
        const showFiltersParams = showFilters === true ? '&showFilters=true' : '';

        return httpClient.post<ElasticSearchFrameIdMapDataResponse>(
            `${urlcat(
                endpoints.reports.pcaElasticSearch.frameIdMapData,
                data,
            )}${showFiltersParams}`,
            { ...generateFieldFilterObject(fieldFilters), data_status: dataStatus },
            config,
        );
    },
    frameIdStats: (
        {
            campaignId,
            startDate,
            endDate,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
            showStats = false,
            dataStatus,
            source,
        }: ElasticSearchFrameIdRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start_date: startDate,
            end_date: endDate,
            environment,
            source,
        };

        if (showStats) {
            // @ts-ignore
            data.showStats = true;
        }

        return httpClient.post<ElasticSearchFrameIdStatsDataResponse>(
            `${urlcat(endpoints.reports.pcaElasticSearch.stats, data)}`,
            { ...generateFieldFilterObject(fieldFilters), data_status: dataStatus },
            config,
        );
    },
    displayFrameIdMapData: (
        id: number | string,
        displayId: number | string,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.get(
            `${urlcat(endpoints.reports.pcaElasticSearch.displayMapData, { id, displayId })}`,
            config,
        ),

    summary: (
        {
            campaignId,
            startDate,
            endDate,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
            showStats = false,
            dataStatus,
            source,
        }: ElasticSearchSummaryRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start_date: startDate,
            end_date: endDate,
            environment,
            source,
        };

        if (showStats) {
            // @ts-ignore
            data.showStats = true;
        }

        return httpClient.post<ElasticSearchSummaryResponse>(
            `${urlcat(endpoints.reports.pcaElasticSearch.summary, data)}`,
            { ...generateFieldFilterObject(fieldFilters), data_status: dataStatus },
            config,
        );
    },
});

const allowedFilterKeys = Object.values(EsPCAReportFilterField) as string[];

const generateFieldFilterObject = (fieldFilters: ElasticSearchFilters) =>
    Object.entries(fieldFilters)
        // filtering out fields that the API doesn't allow
        .filter(([key]) => allowedFilterKeys.indexOf(key) !== -1)
        .reduce(
            (acc, [key, values]) => ({ ...acc, [`filters.${key}`]: values }),
            {} as Record<string, string[]>,
        );

const generateFieldFilterQueryString = (
    fieldFilters: PCAReportRequestPayload['fieldFilters'],
    firstAsQuestionMark = false,
) =>
    Object.keys(fieldFilters)
        // filtering out fields that the API doesn't allow
        .filter(field => allowedFilterKeys.indexOf(field) !== -1)
        .reduce((acc, curr, index) => {
            acc.push(
                generateArrayQSParams(
                    fieldFilters[curr],
                    `filters.${curr}`,
                    index === 0 && firstAsQuestionMark ? '?' : '&',
                ),
            );
            return acc;
        }, [] as string[])
        .join('');

export default ReportsElasticSearch;
