import React, { useCallback, useMemo } from 'react';
import { CampaignDataNotice, Campaign } from 'c-sdk';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { uniq } from 'ramda';
import { Box, Typography } from '@mui/material';
import { AutoGrid, Dialog } from 'c-components';
import { calculateCounts, emptyIssueCounts } from './lib';

const ChildNoticesButton: React.FC<{
    noticeKey: keyof CampaignDataNotice;
    btnLabel: TranslationPath;
    entity: Campaign;
}> = ({ noticeKey, btnLabel, entity }) => {
    const moreDialog = useBoolean(false);

    const mediaOwnerIssues = useMemo(() => {
        if (entity?.notices?.media_owners != null) {
            return calculateCounts(entity.notices.media_owners, noticeKey);
        }
        return emptyIssueCounts;
    }, [entity, noticeKey]);

    const lineItemIssues = useMemo(() => {
        if (entity?.notices?.line_items != null) {
            return calculateCounts(entity.notices.line_items, noticeKey);
        }
        return emptyIssueCounts;
    }, [entity, noticeKey]);

    const mediaOwnerNames = useMemo(() => uniq(mediaOwnerIssues.names), [mediaOwnerIssues.names]);
    const lineItemNames = useMemo(() => uniq(lineItemIssues.names), [lineItemIssues.names]);

    const onClose = useCallback(
        e => {
            e.stopPropagation();
            moreDialog.setFalse();
        },
        [moreDialog],
    );

    const t = useCommonTranslation();

    return (
        <>
            {mediaOwnerNames.length > 0 && (
                <Box>
                    <Typography variant="subtitle2" noWrap>
                        {t(btnLabel)}
                    </Typography>
                    {mediaOwnerNames.map(name => (
                        <Typography key={name} variant="body2" noWrap>
                            {name}
                        </Typography>
                    ))}
                </Box>
            )}

            <Box
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <Dialog
                    title=""
                    removeForm
                    onClose={onClose}
                    show={moreDialog.value}
                    content={
                        <AutoGrid xs={12} spacing={2}>
                            {mediaOwnerNames.length > 0 && (
                                <Box>
                                    <Typography variant="subtitle2">
                                        <Translate path="Modules.Main.Campaigns.DataStatus.table.noticesMediaOwnersTitle" />
                                    </Typography>
                                    {mediaOwnerNames.map(l => (
                                        <Typography key={l} variant="body2">
                                            {l}
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                            {lineItemNames.length > 0 && (
                                <Box>
                                    <Typography variant="subtitle2">
                                        <Translate path="Modules.Main.Campaigns.DataStatus.table.noticesLineItemsTitle" />
                                    </Typography>
                                    {lineItemNames.map(l => (
                                        <Typography key={l} variant="body2">
                                            {l}
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                        </AutoGrid>
                    }
                />
            </Box>
        </>
    );
};

export default ChildNoticesButton;
