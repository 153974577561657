import { SliceCaseReducers } from '@reduxjs/toolkit';
import { BaseEntity } from 'c-sdk';
import { NetworkRequestState } from '../../Types/network';
import { emptyObjects } from '../../lib';
import deletePaginatedEntityInLists from '../Lib/deletePaginatedEntityInLists';
import {
    BaseCaseReducers,
    BaseSliceEntityAdapter,
    BaseSliceInitialState,
    SlicePaginationData,
} from '../../Types/slices';

const baseReducers = (
    adapter: BaseSliceEntityAdapter<any>,
): BaseCaseReducers<
    BaseEntity,
    BaseSliceInitialState<BaseEntity>,
    SliceCaseReducers<BaseSliceInitialState<BaseEntity>>
> => ({
    startLoadingById: (state, action) => ({
        ...state,
        loadingById: {
            ...state.loadingById,
            [action.payload.id]: {
                state: NetworkRequestState.InProgress,
            },
        },
    }),

    finishedLoadingById: (state, action) => ({
        ...state,
        loadingById: {
            ...state.loadingById,
            [action.payload.entity.id]: {
                state: NetworkRequestState.Idle,
                error: undefined,
                status: action.payload.status,
            },
        },
    }),
    failedLoadingById: (state, action) => ({
        ...state,
        loadingById: {
            ...state.loadingById,
            [action.payload.id]: {
                state: NetworkRequestState.Error,
                error: action.payload.error,
                status: action.payload.status,
            },
        },
    }),
    resetLoadingByIdState: (state, action) => ({
        ...state,
        loadingById: {
            ...state.loadingById,
            [action.payload.id]: {
                state: NetworkRequestState.Idle,
            },
        },
    }),

    startUpdatingById: (state, action) => ({
        ...state,
        updatingById: {
            ...state.updatingById,
            [action.payload.id]: { state: NetworkRequestState.InProgress, error: undefined },
        },
    }),
    finishedUpdatingById: (state, action) => ({
        ...state,
        updatingById: {
            ...state.updatingById,
            [action.payload.id]: {
                state: NetworkRequestState.Success,
            },
        },
    }),
    failedUpdatingById: (state, action) => ({
        ...state,
        updatingById: {
            ...state.updatingById,
            [action.payload.id]: {
                state: NetworkRequestState.Error,
                error: action.payload.error,
                validation: action.payload.validation,
            },
        },
    }),
    resetUpdatingByIdState: (state, action) => ({
        ...state,
        updatingById: {
            ...state.updatingById,
            [action.payload.id]: {
                state: NetworkRequestState.Idle,
            },
        },
    }),

    startCreating: state => ({
        ...state,
        create: {
            state: NetworkRequestState.InProgress,
        },
        newestCreatedId: undefined,
    }),
    finishedCreating: (state, action) => ({
        ...state,
        create: {
            state: NetworkRequestState.Success,
        },
        newestCreatedId: action.payload.id,
    }),
    failedCreating: (state, action) => ({
        ...state,
        create: {
            state: NetworkRequestState.Error,
            error: action.payload.error,
            validation: action.payload.validation,
        },
    }),
    resetCreateState: state => ({
        ...state,
        create: {
            state: NetworkRequestState.Idle,
        },
        newestCreatedId: undefined,
    }),

    startDeletingById: (state, action) => ({
        ...state,
        deletingById: {
            ...state.deletingById,
            [action.payload.id]: { state: NetworkRequestState.InProgress, error: undefined },
        },
    }),
    finishedDeletingById: (state, action) => ({
        ...adapter.removeOne({ ...state }, action.payload.id),
        deletingById: {
            ...state.deletingById,
            [action.payload.id]: {
                state: NetworkRequestState.Success,
            },
        },
        pagination: deletePaginatedEntityInLists(state.pagination, action.payload.id),
    }),
    failedDeletingById: (state, action) => ({
        ...state,
        deletingById: {
            ...state.deletingById,
            [action.payload.id]: {
                state: NetworkRequestState.Error,
                error: action.payload.error,
            },
        },
    }),
    resetDeletingByIdState: (state, action) => ({
        ...state,
        deletingById: {
            ...state.deletingById,
            [action.payload.id]: {
                state: NetworkRequestState.Idle,
            },
        },
    }),

    startLoadingPaginatedList: (state, action) => ({
        ...state,
        pagination: {
            ...state.pagination,
            [action.payload.tag]: {
                ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
                includes: action.payload.includes,
                filters: action.payload.filters,
                searchables: action.payload.searchables,
                page: action.payload.page,
                perPage: action.payload.perPage,
                direction: action.payload.direction,
                orderBy: action.payload.orderBy,
                loadingState: { state: NetworkRequestState.InProgress },

                showFilters: action.payload.showFilters,
                showSearchables: action.payload.showSearchables,
                showIncludes: action.payload.showIncludes,
                filterFilters: action.payload.filterFilters,
            } as SlicePaginationData<BaseEntity>,
        },
    }),
    finishedLoadingPaginatedList: (state, action) => ({
        ...state,
        pagination: {
            ...state.pagination,
            [action.payload.tag]: {
                ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
                loadingState: { state: NetworkRequestState.Idle },
                data: !action.payload.wasCursorRequest
                    ? action.payload.data.map(e => e.id)
                    : // if it was a cursor pagination request, we want to append the IDs to the end of the existing IDs
                      [
                          ...(state.pagination[action.payload.tag]?.data ?? []),
                          ...action.payload.data.map(e => e.id),
                      ],
                meta: {
                    ...action.payload.meta,
                    filters:
                        state.pagination[action.payload.tag]?.meta?.filters != null &&
                        action.payload.meta.filters == null
                            ? // keep existing filters if they are already set and next response doesn't have them
                              state.pagination[action.payload.tag]?.meta?.filters
                            : action.payload.meta.filters,
                    includes:
                        state.pagination[action.payload.tag]?.meta?.includes != null &&
                        action.payload.meta.includes == null
                            ? // keep existing includes if they are already set and next response doesn't have them
                              state.pagination[action.payload.tag]?.meta?.includes
                            : action.payload.meta.includes,
                    searchables:
                        state.pagination[action.payload.tag]?.meta?.searchables != null &&
                        action.payload.meta.searchables == null
                            ? // keep existing searchables if they are already set and next response doesn't have them
                              state.pagination[action.payload.tag]?.meta?.searchables
                            : action.payload.meta.searchables,
                },
            } as SlicePaginationData<BaseEntity>,
        },
    }),
    failedLoadingPaginatedList: (state, action) => ({
        ...state,
        pagination: {
            ...state.pagination,
            [action.payload.tag]: {
                ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
                loadingState: { state: NetworkRequestState.Error, error: action.payload.error },
            } as SlicePaginationData<BaseEntity>,
        },
    }),
    updatePaginatedListFilter: (state, action) => {
        const newPagination = {
            ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
        };
        if (newPagination.filters == null) {
            newPagination.filters = {};
        }
        newPagination.filters = {
            ...newPagination.filters,
            [action.payload.key]: action.payload.value,
        };
        return {
            ...state,
            pagination: {
                ...state.pagination,
                [action.payload.tag]: newPagination as SlicePaginationData<BaseEntity>,
            },
        };
    },
    togglePaginatedListFilterValue: (state, action) => {
        const newPagination = {
            ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
        };
        if (newPagination.filters == null) {
            newPagination.filters = {};
        }

        let newValue: any[] = newPagination.filters[action.payload.key];

        if (Array.isArray(newValue)) {
            const valueIndex = newValue.indexOf(action.payload.value);
            if (valueIndex === -1) {
                newValue = [...newValue, action.payload.value];
            } else {
                newValue = newValue.filter((val, idx) => idx != valueIndex);
            }
        } else {
            newValue = [action.payload.value];
        }

        newPagination.filters = {
            ...newPagination.filters,
            [action.payload.key]: newValue,
        };
        return {
            ...state,
            pagination: {
                ...state.pagination,
                [action.payload.tag]: newPagination as SlicePaginationData<BaseEntity>,
            },
        };
    },
    resetPaginatedListFilters: (state, action) => {
        const newPagination = {
            ...(state.pagination[action.payload.tag] ?? emptyObjects.slicePaginationData()),
        };
        newPagination.filters = action.payload.value ?? {};
        return {
            ...state,
            pagination: {
                ...state.pagination,
                [action.payload.tag]: newPagination as SlicePaginationData<BaseEntity>,
            },
        };
    },
    resetPaginatedList: (state, action) => {
        const newPagination = { ...state.pagination };
        delete newPagination[action.payload.tag];
        return {
            ...state,
            pagination: newPagination,
        };
    },

    websocketDeletedEntity: (state, action) => ({
        ...adapter.removeOne({ ...state }, action.payload.entity_id),
        pagination: deletePaginatedEntityInLists(state.pagination, action.payload.entity_id),
    }),

    upsertEntities: (state, action) => ({
        ...adapter.conditionallyUpsert({ ...state }, action.payload),
    }),
});

export default baseReducers;
