import React, { useEffect } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { logFileImportIcon } from 'c-main/icons';
import { PermissionName, PopDisplays_LogFileImport } from 'c-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { LogFileImportFileNameCell } from 'c-pop-displays/Components/LogFileImportTableCells';
import ReactGA from 'react-ga';

const paginationTag = 'log-file-import-list-search';
const textSearchCols = ['file'];

const columns = [
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.typeCol',
        field: 'type',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.statusCol',
        field: 'status',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.dateCol',
        field: 'datetime',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.fileCol',
        field: 'file',
        entityName: 'PopDisplays_LogFileImport',
        RenderCell: LogFileImportFileNameCell,
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.fileSizeCol',
        field: 'filesize',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'fileSize',
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.createdCol',
        field: 'created_at',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.updatedCol',
        field: 'updated_at',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
];

const LogFileImportListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <FullPageEntityTableWrapper title="Modules.PopDisplays.LogFileImport.metaTitle">
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="PopDisplays_LogFileImport"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                showFilters
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'LogFileImportListPage',
    component: LogFileImportListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.LogFileImport.metaTitle',
        description: 'Modules.PopDisplays.LogFileImport.description',
        type: 'page',
        icon: logFileImportIcon,
        aliases: 'SystemSearch.aliases.popDisplaysLogFileImportList',
        route: PostAuthRoutes.PopDisplays.LogFileImportList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.LogFileImport.metaTitle' },
        ],
        permissions: [PermissionName.Pop_displaysLog_file_importsRead],
    },
};

export default setup;
