import { CampaignDataNotice } from 'c-sdk';

export type IssueCounts = { names: string[]; count: number };
export const emptyIssueCounts: IssueCounts = { names: [], count: 0 };
export const calculateCounts = (
    notices: Record<string, CampaignDataNotice>,
    key: keyof CampaignDataNotice,
) =>
    Object.entries(notices).reduce((acc, [name, notices]) => {
        if (notices[key]) return { ...acc, count: acc.count + 1, names: [...acc.names, name] };

        return acc;
    }, emptyIssueCounts);
