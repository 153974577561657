import React, { useCallback, useMemo, useState } from 'react';
import {
    ReportConfig,
    ReportDateTimeframe,
    ReportProps,
    ReportType,
    ReportWrapperProps,
    TableReportHeader,
    TableReportRow,
} from 'c-reports/Types';
import { format } from 'date-fns';
import { Box, Stack } from '@mui/material';
import { useDateUtils } from 'c-hooks';
import { ReportWrapper } from 'c-reports/Components';
import { DisplayMediaBreakdownData } from 'c-sdk';
import { useDebounce } from 'use-debounce';
import { Checkbox } from 'c-components';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import { parsedLogFileParsedMediaDataDates, parseLogFileParsedMediaData } from './lib';
import { groupByHourRows, groupByMediaByHourRows, groupByMediaRows } from './LogFileParsedRows';

type Props = {
    minDate: Date;
    maxDate: Date;
    chartName: string;
    fetchData: ReportConfig<{ data: DisplayMediaBreakdownData[] }>['fetchData'];

    showGroupByCheckboxes?: boolean;
    defaultGroupByMedia?: boolean;
    defaultGroupByHour?: boolean;

    startAtEnd?: boolean;
};

const stackSx = { maxWidth: '100%', overflow: 'hidden', width: '100%' };

const LogFileParsedDaily: React.FC<Props> = ({
    minDate,
    maxDate,
    chartName,
    fetchData,

    showGroupByCheckboxes = true,
    defaultGroupByMedia = true,
    defaultGroupByHour = false,

    startAtEnd = true,
}) => {
    const [data, setData] = useState<DisplayMediaBreakdownData[]>([]);

    const hourlyState = useBoolean(defaultGroupByHour);
    const groupByMediaState = useBoolean(defaultGroupByMedia);

    const [showMediaGrouped] = useDebounce(groupByMediaState.value, 500);
    const [showHourly] = useDebounce(hourlyState.value, 500);

    const groupByMedia = useMemo(() => showMediaGrouped, [showMediaGrouped]);
    const groupByHour = useMemo(
        () => showHourly && showMediaGrouped,
        [showHourly, showMediaGrouped],
    );

    const onDataUpdated: ReportConfig<{ data: DisplayMediaBreakdownData[] }>['onDataUpdated'] =
        useCallback(data => {
            setData(data?.data ?? []);
        }, []);

    const reportWrapperProps = useMemo<ReportWrapperProps<any>>(
        () => ({
            fetchData,
            // onDataUpdated: setData,
            onDataUpdated,
            initialStartDate: startAtEnd ? maxDate : minDate,
            initialEndDate: maxDate,
            reportChartWrapperProps: { height: 500 },
            dateSettings: {
                timeframe: ReportDateTimeframe.Weekly,
                showGoStart: true,
                showGoEnd: true,
                minDate,
                maxDate,
                availableTimeframes: [
                    ReportDateTimeframe.Weekly,
                    ReportDateTimeframe.BiWeekly,
                    ReportDateTimeframe.Monthly,
                    ReportDateTimeframe.Range,
                ],
            },
        }),
        [fetchData, maxDate, minDate, onDataUpdated, startAtEnd],
    );
    const transformedData = useMemo(() => parseLogFileParsedMediaData(data), [data]);
    const allDatesInRange = useMemo(
        () => parsedLogFileParsedMediaDataDates(transformedData),
        [transformedData],
    );

    const { formatDateString, dayMonthYearApiFormat } = useDateUtils();

    const tableHeaders = useMemo<TableReportHeader[]>(
        () => [
            { content: '' },
            ...allDatesInRange.map(day => {
                let colSpan = 0;
                const dateKey = format(day, dayMonthYearApiFormat);
                if (groupByMedia && groupByHour)
                    colSpan = Object.keys(transformedData.day?.[dateKey]?.hours).length;
                return {
                    tableCellProps: {
                        colSpan,
                        sx: { textAlign: 'left', position: 'sticky', left: 0 },
                    },
                    content: (
                        <Box fontSize=".8em" lineHeight="1.2em">
                            <Box sx={{ textTransform: 'uppercase' }}>
                                {formatDateString({ date: day, dateFormatString: 'EEE' })}
                            </Box>
                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                {formatDateString({ date: day, dateFormatString: 'do MMM' })}
                            </Box>
                        </Box>
                    ),
                };
            }),
        ],
        [
            allDatesInRange,
            dayMonthYearApiFormat,
            formatDateString,
            groupByHour,
            groupByMedia,
            transformedData.day,
        ],
    );

    const tableRows = useMemo<TableReportRow[]>(() => {
        if (groupByMedia && groupByHour)
            return groupByMediaByHourRows(allDatesInRange, transformedData, dayMonthYearApiFormat);
        if (groupByMedia)
            return groupByMediaRows(allDatesInRange, transformedData, dayMonthYearApiFormat);

        return groupByHourRows(allDatesInRange, transformedData, dayMonthYearApiFormat);
    }, [groupByMedia, allDatesInRange, transformedData, groupByHour, dayMonthYearApiFormat]);

    const reportProps = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Table,
                namePrefix: chartName,
                data,
                rows: tableRows,
                headers: tableHeaders,
                disableVirtualization: true,
                dense: true,
            },
            // } as TableReportProps<any>,
        ],
        [data, chartName, tableHeaders, tableRows],
    );

    const t = useCommonTranslation();

    return (
        <Stack gap={2} sx={stackSx}>
            {showGroupByCheckboxes && (
                <Box px={2}>
                    <Checkbox
                        isBoolean
                        value={groupByMediaState.value}
                        onChange={groupByMediaState.setValue}
                        label={t('Component.LogFileParsedDaily.groupByMediaCheckbox')}
                    />
                    {groupByMediaState.value && (
                        <Checkbox
                            isBoolean
                            value={hourlyState.value}
                            onChange={hourlyState.setValue}
                            label={t('Component.LogFileParsedDaily.groupByHourCheckbox')}
                        />
                    )}
                </Box>
            )}
            <Box height="75vh" overflow="hidden" display="flex" width="100%">
                <ReportWrapper
                    {...reportWrapperProps}
                    reportProps={reportProps}
                    wrapperProps={{
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflow: 'hidden',
                        },
                    }}
                    reportWrapperProps={{ sx: { flex: 1, overflow: 'auto' } }}
                />
            </Box>
        </Stack>
    );
};

export default LogFileParsedDaily;
