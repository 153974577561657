import React, { useCallback, useEffect } from 'react';
import { generateColumn } from 'c-pagination';
import {
    Displays_AnalogueScreen,
    Displays_Country,
    Displays_Owner,
    Displays_Pack,
    Displays_Screen,
    Displays_Tag,
    Market,
} from 'c-sdk';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox } from 'c-components';
import { copyTextToClipboard } from 'c-lib';
import { generatePath, useHistory } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import {
    DeleteEntityButtonFromTable,
    DisplaysBrochureColumn,
    OwnersDisplaysListButton,
    OwnersPacksListButton,
    PacksListButton,
    TagsListButton,
} from './EntityButtons';

export const displayPacksColumns = [
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.id',
        field: 'id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.marketCol',
        field: 'market.name',
        entityName: 'Displays_Pack',
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.resolutionCol',
        field: 'resolution',
        entityName: 'Displays_Pack',
        orderable: true,
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Stack key={resolution.id} p={1}>
                        <Typography variant="inherit" textAlign="center">
                            {resolution.width} x {resolution.height}
                        </Typography>
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.nameCol',
        field: 'name',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.ownerName',
        field: 'owner.name',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.sizeCol',
        field: 'size',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.displaysCol',
        field: 'displays',
        entityName: 'Displays_Pack',
        RenderCell: PacksListButton,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.brochure',
        field: 'brochure',
        entityName: 'Displays_Pack',
        RenderCell: ({ entity }) => (
            <Box onClick={e => e.stopPropagation()}>
                <DisplaysBrochureColumn entity={entity} entityName="Displays_Pack" />
            </Box>
        ),
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.deletePack',
        field: 'id',
        entityName: 'Displays_Pack',
        RenderCell: ({ entity }) => (
            <Box onClick={e => e.stopPropagation()}>
                <DeleteEntityButtonFromTable id={entity.id} entityName="Displays_Pack" />
            </Box>
        ),
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.editPack',
        field: 'id',
        entityName: 'Displays_Pack',
        RenderCell: ({ entity }) => {
            const id = entity.id;
            const history = useHistory();
            const rout = generatePath(PostAuthRoutes.Displays.DisplaysPackEdit, {
                id,
            });
            return (
                <IconButton onClick={() => history.push(rout)}>
                    <EditIcon />
                </IconButton>
            );
        },
    }),
];
export const displayPacksColumnsForOwnersPAge = [
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.id',
        field: 'id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.marketCol',
        field: 'market.name',
        entityName: 'Displays_Pack',
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.resolutionCol',
        field: 'resolution',
        entityName: 'Displays_Pack',
        orderable: true,
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Stack key={resolution.id} p={1}>
                        <Typography variant="inherit" textAlign="center">
                            {resolution.width} x {resolution.height}
                        </Typography>
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.nameCol',
        field: 'name',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.sizeCol',
        field: 'size',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
];

export const displayAllocationColumns = (setIds, ids, paginatedIds) => [
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.allocation',
        RenderHeader: () => {
            const allExist = paginatedIds.every(id => ids.includes(id));
            const handleCheckboxChange = () => {
                setIds(prevIds => {
                    if (allExist) {
                        return prevIds.filter(id => !paginatedIds.includes(id));
                    }
                    const combinedIds = new Set([...prevIds, ...paginatedIds]);
                    return Array.from(combinedIds);
                });
            };
            return <Checkbox checked={allExist} onChange={handleCheckboxChange} />;
        },
        field: 'checkbox',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => {
            const isChecked = ids.includes(entity.id);

            const handleCheckboxChange = event => {
                event.stopPropagation();
                event.preventDefault();
                if (event.target.checked) {
                    if (!isChecked) {
                        setIds([...ids, entity.id]);
                    }
                } else {
                    setIds(ids.filter(id => id !== entity.id));
                }
            };

            return (
                <Box onClick={e => e.stopPropagation()}>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                </Box>
            );
        },
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.idCol',
        field: 'id',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.addressCol',
        field: 'address',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.frameID',
        field: 'frame_id',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.mediaOwners',
        field: 'mediaOwners.0.name',
        entityName: 'Displays_Screen',
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.conurbation',
        field: 'conurbation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.animation',
        field: 'animation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.resolution',
        field: 'resolutions.0.width',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Stack key={resolution.id} p={1}>
                        <Typography variant="inherit" textAlign="center">
                            {resolution.width} x {resolution.height}
                        </Typography>
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.size',
        field: 'size',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.environment',
        field: 'env',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
];

export const displayScreenColumns = (setIds, ids, paginatedIds, setDeletedIds, deletedIds) => [
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.idCol',
        field: 'id',
        RenderHeader: () => {
            const allExist = paginatedIds.every(id => ids.includes(id));
            const t = useCommonTranslation();
            const handleCheckboxChange = () => {
                setIds(prevIds => {
                    if (allExist) {
                        return prevIds.filter(id => !paginatedIds.includes(id));
                    }
                    const combinedIds = new Set([...prevIds, ...paginatedIds]);
                    return Array.from(combinedIds);
                });
            };
            return (
                <Stack direction="row">
                    <Box onClick={e => e.stopPropagation()}>
                        <Checkbox checked={allExist} onChange={handleCheckboxChange} />
                    </Box>
                    <Box pt={1}>{t('Modules.Displays.DisplaysList.table.idCol')}</Box>
                </Stack>
            );
        },
        entityName: 'Displays_Screen',
        orderable: true,
        RenderCell: ({ entity }) => {
            const isChecked = ids.includes(entity.id);

            const handleCheckboxChange = event => {
                event.stopPropagation();
                event.preventDefault();
                if (event.target.checked) {
                    if (!isChecked) {
                        setIds([...ids, entity.id]);
                    }
                } else {
                    setIds(ids.filter(id => id !== entity.id));
                }
            };
            const addToDeletedIds = id => {
                setDeletedIds(prevIds => {
                    if (!prevIds.includes(id)) {
                        return [...prevIds, id];
                    }
                    return prevIds;
                });
            };
            useEffect(() => {
                if (entity.deleted_at != null) {
                    addToDeletedIds(entity.id);
                }
            }, [entity]);
            const disabled = entity.deleted_at != null;

            const handleCopyClick = useCallback(
                e => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyTextToClipboard(String(entity?.id));
                },
                [entity?.id],
            );

            return (
                <Box>
                    <Stack direction="row">
                        <Box onClick={e => e.stopPropagation()}>
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                disabled={disabled}
                            />
                        </Box>
                        <Box pt={1}>{entity.id}</Box>
                        <IconButton onClick={handleCopyClick}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Box>
            );
        },
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.addressCol',
        field: 'address',
        entityName: 'Displays_Screen',
        orderable: true,
        RenderCell: ({ entity }) => {
            const handleCopyClick = useCallback(
                e => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyTextToClipboard(entity?.address);
                },
                [entity?.address],
            );

            return (
                <Box>
                    <Stack direction="row">
                        {entity.address}
                        <IconButton onClick={handleCopyClick}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Box>
            );
        },
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.frameID',
        field: 'frame_id',
        entityName: 'Displays_Screen',
        orderable: true,
        RenderCell: ({ entity }) => {
            const vendorIds = entity.displaysVendorIds;
            const frameId = String(entity.frame_id);

            return (
                <Box>
                    {vendorIds.length > 0 && (
                        <Stack direction="column">
                            {vendorIds.map(vendor => (
                                <Stack gap={1} key={vendor.id} direction="row">
                                    <Box fontWeight="bold">{vendor.vendor_name}:</Box>
                                    <Box>{vendor.vendor_id}</Box>
                                </Stack>
                            ))}
                        </Stack>
                    )}
                    {vendorIds.length === 0 && frameId}
                </Box>
            );
        },
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.status',
        field: 'status',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => {
            if ((entity as any).status) {
                return <DoneIcon fontSize="small" />;
            }
            return <CloseIcon fontSize="small" />;
        },
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.mediaOwners',
        field: 'mediaOwners.0.name',
        entityName: 'Displays_Screen',
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.pack',
        field: 'packs',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => <>{entity.packs[0]?.name}</>,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.tag',
        field: 'tag',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => <>{entity.tags[0]?.name}</>,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.conurbation',
        field: 'conurbation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.animation',
        field: 'animation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.resolution',
        field: 'resolutions.0.width',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Stack key={resolution.id} p={1}>
                        <Typography variant="inherit" textAlign="center">
                            {resolution.width} x {resolution.height}
                        </Typography>
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.size',
        field: 'size',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.environment',
        field: 'env',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.actions',
        field: 'id',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => {
            const id = entity.id;
            const history = useHistory();
            const rout = generatePath(PostAuthRoutes.Displays.DisplaysDisplayEdit, {
                id,
            });
            return (
                <Stack direction="row">
                    <Box onClick={e => e.stopPropagation()}>
                        {entity.deleted_at == null && (
                            <DisplaysBrochureColumn entity={entity} entityName="Displays_Screen" />
                        )}
                    </Box>
                    <Box onClick={e => e.stopPropagation()}>
                        {entity.deleted_at == null && (
                            <DeleteEntityButtonFromTable
                                id={entity.id}
                                entityName="Displays_Screen"
                            />
                        )}
                    </Box>
                    <Box onClick={e => e.stopPropagation()}>
                        {!deletedIds.includes(id) && (
                            <IconButton onClick={() => history.push(rout)}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </Box>
                </Stack>
            );
        },
    }),
];
export const displayScreenColumnsOtherTables = [
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.idCol',
        field: 'id',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.addressCol',
        field: 'address',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.frameID',
        field: 'frame_id',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.mediaOwners',
        field: 'mediaOwners.0.name',
        entityName: 'Displays_Screen',
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.conurbation',
        field: 'conurbation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.animation',
        field: 'animation',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.resolution',
        field: 'resolutions.0.width',
        entityName: 'Displays_Screen',
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Stack key={resolution.id} p={1}>
                        <Typography variant="inherit" textAlign="center">
                            {resolution.width} x {resolution.height}
                        </Typography>
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.size',
        field: 'size',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.environment',
        field: 'env',
        entityName: 'Displays_Screen',
        orderable: true,
    }),
];

export const displayOwnerColumns = [
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.name',
        field: 'name',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.partner',
        field: 'partner.name',
        entityName: 'Displays_Owner',
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.extId',
        field: 'external_id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.market',
        field: 'market',
        entityName: 'Displays_Owner',
        RenderCell: ({ entity }) => (
            <>
                {entity.market?.map(market => (
                    <Stack key={market.id} gap={1}>
                        {market.name}
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.ownerId',
        field: 'id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.countries',
        field: 'countries',
        entityName: 'Displays_Owner',
        RenderCell: ({ entity }) => (
            <>
                {entity.countries.map(country => (
                    <Stack key={country.id} gap={1}>
                        {country.name}
                    </Stack>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.displays',
        field: 'displays',
        entityName: 'Displays_Owner',
        RenderCell: OwnersDisplaysListButton,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.packs',
        field: 'displays',
        entityName: 'Displays_Owner',
        RenderCell: OwnersPacksListButton,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.deleteOwner',
        field: 'id',
        entityName: 'Displays_Owner',
        RenderCell: ({ entity }) => (
            <Box>
                <DeleteEntityButtonFromTable id={entity.id} entityName="Displays_Owner" />
            </Box>
        ),
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.editOwner',
        field: 'edit',
        entityName: 'Displays_Owner',
        RenderCell: ({ entity }) => {
            const id = entity.id;
            const history = useHistory();
            const rout = generatePath(PostAuthRoutes.Displays.DisplaysOwnerEdit, {
                id,
            });
            return (
                <IconButton onClick={() => history.push(rout)}>
                    <EditIcon />
                </IconButton>
            );
        },
    }),
];

export const displayAnalogueColumns = [
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.id',
        field: 'id',
        entityName: 'Displays_AnalogueScreen',
        orderable: true,
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_AnalogueScreen',
        orderable: true,
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.nameCol',
        field: 'name',
        entityName: 'Displays_AnalogueScreen',
        orderable: true,
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.owner',
        field: 'owner.name',
        entityName: 'Displays_AnalogueScreen',
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.marketCol',
        field: 'market.name',
        entityName: 'Displays_AnalogueScreen',
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.sizeCol',
        field: 'size',
        entityName: 'Displays_AnalogueScreen',
        orderable: true,
    }),
    generateColumn<Displays_AnalogueScreen>({
        headerTitle: 'Modules.Displays.Analogue.table.description',
        field: 'description',
        entityName: 'Displays_AnalogueScreen',
        orderable: true,
    }),
    generateColumn<Displays_Screen>({
        headerTitle: 'Modules.Displays.DisplaysList.table.brochure',
        field: 'brochure',
        entityName: 'Displays_AnalogueScreen',
        RenderCell: ({ entity }) => (
            <Box onClick={e => e.stopPropagation()}>
                <DisplaysBrochureColumn entity={entity} entityName="Displays_AnalogueScreen" />
            </Box>
        ),
    }),
];
export const displayTagColumns = [
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.id',
        field: 'id',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.nameCol',
        field: 'name',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.operationalCol',
        field: 'operational',
        entityName: 'Displays_Tag',
        RenderCell: ({ entity }) => {
            const t = useCommonTranslation();
            return (
                <>
                    {entity.operational === 0
                        ? t('Modules.Displays.Tags.table.no')
                        : t('Modules.Displays.Tags.table.yes')}
                </>
            );
        },
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.linkedCol',
        field: 'linked',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.displaysCol',
        field: 'displays',
        entityName: 'Displays_Tag',
        orderable: true,
        RenderCell: TagsListButton,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.dwot',
        field: 'dwot',
        entityName: 'Displays_Tag',
        orderable: true,
        RenderCell: ({ entity }) => <>{entity.screensCount - entity.operationalTimeScreensCount}</>,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.deleteTag',
        field: 'id',
        entityName: 'Displays_Tag',
        RenderCell: ({ entity }) => (
            <Box>
                <DeleteEntityButtonFromTable id={entity.id} entityName="Displays_Tag" />
            </Box>
        ),
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.editTag',
        field: 'id',
        entityName: 'Displays_Tag',
        RenderCell: ({ entity }) => {
            const id = entity.id;
            const history = useHistory();
            const rout = generatePath(PostAuthRoutes.Displays.DisplaysTagEdit, {
                id,
            });
            return (
                <Box onClick={e => e.stopPropagation()}>
                    <IconButton onClick={() => history.push(rout)}>
                        <EditIcon />
                    </IconButton>
                </Box>
            );
        },
    }),
];
export const displayEditTagColumns = [
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.id',
        field: 'id',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.nameCol',
        field: 'name',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.operationalCol',
        field: 'operational',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.linkedCol',
        field: 'linked',
        entityName: 'Displays_Tag',
        orderable: true,
    }),
    generateColumn<Displays_Tag>({
        headerTitle: 'Modules.Displays.Tags.table.dwot',
        field: 'dwot',
        entityName: 'Displays_Tag',
        orderable: true,
        RenderCell: ({ entity }) => <>{entity.screensCount - entity.operationalTimeScreensCount}</>,
    }),
];
export const displayEditPacksColumns = [
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.id',
        field: 'id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.extIdCol',
        field: 'ext_id',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.marketCol',
        field: 'market.name',
        entityName: 'Displays_Pack',
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.resolutionCol',
        field: 'resolution',
        entityName: 'Displays_Pack',
        orderable: true,
        RenderCell: ({ entity }) => (
            <>
                {entity.resolutions.map(resolution => (
                    <Typography variant="inherit" key={resolution.id} textAlign="center">
                        {resolution.width} x {resolution.height}
                    </Typography>
                ))}
            </>
        ),
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.nameCol',
        field: 'name',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
    generateColumn<Displays_Pack>({
        headerTitle: 'Modules.Displays.Packs.table.sizeCol',
        field: 'size',
        entityName: 'Displays_Pack',
        orderable: true,
    }),
];
export const displayEditOwnerColumns = [
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.partner',
        field: 'partner_id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.extId',
        field: 'external_id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.ownerId',
        field: 'id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.name',
        field: 'name',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.id',
        field: 'id',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
    generateColumn<Displays_Owner>({
        headerTitle: 'Modules.Displays.OwnerList.table.countries',
        field: 'countries.0.name',
        entityName: 'Displays_Owner',
        orderable: true,
    }),
];
export const displayCountryColumns = [
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.id',
        field: 'id',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.extId',
        field: 'ext_id',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.name',
        field: 'name',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.alpha2',
        field: 'alpha_2',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.alpha3',
        field: 'alpha_3',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.numericCode',
        field: 'code_num',
        entityName: 'Displays_Country',
        orderable: true,
    }),
    generateColumn<Displays_Country>({
        headerTitle: 'Modules.Displays.CountriesList.table.currency',
        field: 'currency.name',
        entityName: 'Displays_Country',
    }),
];
export const displayMarketColumns = [
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.id',
        field: 'id',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.name',
        field: 'name',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.country',
        field: 'country.name',
        entityName: 'Market',
        orderable: true,
    }),
    generateColumn<Market>({
        headerTitle: 'Modules.Displays.MarketsList.table.currency',
        field: 'currency.name',
        entityName: 'Market',
    }),
];
