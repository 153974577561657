import { PCAMapReportPlayStatsMinimal } from './Reports';
import { CampaignNoticeKey } from './EntityTypes';

export enum EsPCAReportField {
    Audience = 'audience',
    AudienceSov = 'audience_sov',
    CampaignId = 'campaign_id',
    CarbonEmissionKgsCo2e = 'carbon_emission_kgs_co2e',
    CreativeId = 'creative_id',
    CreativeName = 'creative_name',
    Day = 'day',
    DisplayConurbation = 'display_conurbation',
    DisplayEnv = 'display_env',
    DisplayFileTypes = 'display_filetypes',
    DisplayFormat = 'display_format',
    DisplayId = 'display_id',
    DisplayLat = 'display_lat',
    DisplayLineItemId = 'display_line_item_id',
    DisplayLineItemName = 'display_line_item_name',
    DisplayLong = 'display_long',
    DisplayOwner = 'display_owner',
    DisplayResolution = 'display_resolution',
    DisplayScreenNameSummary = 'display_screen_name_summary',
    DisplaySlotDuration = 'display_slot_duration',
    DisplaySlots = 'display_slots',
    DisplaySov = 'display_sov',
    DisplaySurfaceArea = 'display_surface_area',
    DisplayTown = 'display_town',
    DisplayTvRegion = 'display_tv_region',
    DisplayType = 'display_type',
    Dow = 'dow',
    Downtime = 'downtime',
    EndDateTime = 'end_datetime',
    EndDate = 'end_date',
    EnergyUsesKwh = 'energy_uses_kwh',
    Environment = 'environment',
    ExpectedImpacts = 'expected_impacts',
    ExpectedImpactsInSchedule = 'expected_impacts_in_schedule',
    ExpectedPlays = 'expected_plays',
    ExpectedPlaysInSchedule = 'expected_plays_in_schedule',
    ExpectedTime = 'expected_time',
    ExpectedTimeInSchedule = 'expected_time_in_schedule',
    FrameId = 'frame_id',
    Hour = 'hour',
    ImpactsReceived = 'impacts_received',
    ImpactsReceivedInSchedule = 'impacts_received_in_schedule',
    ImpactsReceivedOutOfSchedule = 'impacts_received_out_of_schedule',
    ImpactsReceivedUnbooked = 'impacts_received_unbooked',
    ImpactsWithVerification = 'impacts_with_verification',
    InsidePopDate = 'inside_pop_date',
    IssueId = 'issue_id',
    NumberOfUniqueCreatives = 'number_of_unique_creatives',
    PlaysReceivedInSchedule = 'plays_received_in_schedule',
    PlaysReceivedOutOfSchedule = 'plays_received_out_of_schedule',
    PlaysReceivedUnbooked = 'plays_received_unbooked',
    PlaysWithVerification = 'plays_with_verification',
    Schedule = 'schedule',
    SlotDuration = 'slot_duration',
    Sov = 'sov',
    StartDateTime = 'start_datetime',
    StartDate = 'start_date',
    SumOfPlays = 'sum_of_plays',
    SumOfTime = 'sum_of_time',
    TimeReceivedInSchedule = 'time_received_in_schedule',
    TimeReceivedOutOfSchedule = 'time_received_out_of_schedule',
    TimeReceivedUnbooked = 'time_received_unbooked',
    TimeWithVerification = 'time_with_verification',
}

export enum EsPCAReportFilterField {
    CreativeName = 'creative_name',
    DisplayTown = 'display_town',
    DisplayTvRegion = 'display_tv_region',
    DisplayConurbation = 'display_conurbation',
    DisplayEnv = 'display_env',
    DisplayFormat = 'display_format',
    DisplayOwner = 'display_owner',
    DisplayType = 'display_type',
    DisplayResolution = 'display_resolution',
    FrameId = 'frame_id',
    DisplayLineItemId = 'display_line_item_id',
    DisplayId = 'display_id',
    DisplayLineName = 'display_line_item_name',
    Schedule = 'scheduled',
    Dow = 'dow',
    Day = 'day',
    Hour = 'hour',
    IssueId = 'issue_id',
}

export type ElasticSearchFilters = Partial<Record<EsPCAReportFilterField, string[]>>;

export type ElasticSearchGroupByFieldRequestPayload = {
    campaignId: number;
    environment?: string;
    field: EsPCAReportField;
    fieldFilters?: ElasticSearchFilters;
    dataStatus?: CampaignNoticeKey[];

    showStats?: boolean;

    // yyyy-MM-dd
    startDate?: string;

    // yyyy-MM-dd
    endDate?: string;
    source?: string;
    showFilters?: boolean;
};

export type ElasticSearchHistogramRequestPayload = {
    campaignId: number;
    interval: ElasticSearchHistogramInterval;
    showFilters?: boolean;

    environment?: string;
    source?: string;
    field: EsPCAReportField;
    fieldFilters?: ElasticSearchFilters;
    dataStatus?: CampaignNoticeKey[];

    showStats?: boolean;

    // yyyy-MM-dd
    startDate?: string;

    // yyyy-MM-dd
    endDate?: string;
};

export type ElasticSearchFrameIdRequestPayload = {
    campaignId: number;
    environment?: string;
    source?: string;
    fieldFilters?: ElasticSearchFilters;
    dataStatus?: CampaignNoticeKey[];

    showStats?: boolean;

    // yyyy-MM-dd
    startDate?: string;

    // yyyy-MM-dd
    endDate?: string;
    showFilters?: boolean;
};

export type ElasticSearchSummaryRequestPayload = {
    campaignId: number;
    environment?: string;
    source?: string;
    showFilters?: boolean;
    fieldFilters?: ElasticSearchFilters;
    dataStatus?: CampaignNoticeKey[];

    showStats?: boolean;

    // yyyy-MM-dd
    startDate?: string;

    // yyyy-MM-dd
    endDate?: string;
};

export enum ElasticSearchHistogramInterval {
    Hour = 'hour',
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Quarter = 'quarter',
    Year = 'year',
}

export enum ElasticSearchSchedule {
    In = 'IN',
    Out = 'OUT',
    Unbooked = 'UNBOOKED',
    OutOfHours = 'OUT OF HOURS',
}

/**
 *  For example:
 *  "filters": {
 *    "creative_name": {
 *      "starbucks-mall-and-rail-27th-sept-v1.zip": true,
 *      "d48-starbucks-coffee-company-uk-ltd-v1.zip": false,
 *      "Starbucks_JCD_Rail_1080x1920_210928": true,
 *    },
 *    "display_env": {
 *      "Rail": true,
 *      "Roadside": true,
 *      "Retail": true,
 *      "Transport": false,
 *      "": true
 *    },
 *
 * These filters are self refining, so only values with `true` can be used to further refine the results.
 */
export type ElasticSearchAvailableFilters = Record<EsPCAReportFilterField, Record<string, boolean>>;

export type ElasticSearchPerformanceStats = {
    expected_time_in_schedule: number;
    impacts_received_out_of_schedule: number;
    plays_received_unbooked: number;
    expected_impacts_in_schedule: number;
    time_received_out_of_schedule: number;
    impacts: number;
    plays_received_out_of_schedule: number;
    impacts_received_unbooked: number;
    kilowatts: number;
    sum_of_time: number;
    plays_received_in_schedule: number;
    time_received_in_schedule: number;
    time_received_unbooked: number;
    impacts_received_in_schedule: number;
    kgco2: number;
    sum_of_plays: number;
    expected_plays_in_schedule: number;

    // Will only exist within ElasticSearchGroupByFieldResponse
    // when grouping by either `EsPCAReportField.DisplayOwner` and `EsPCAReportField.DisplayLineItemName`

    // only added when grouping by `EsPCAReportField.DisplayOwner`
    display_owner_id?: number;

    // only added when grouping by `EsPCAReportField.DisplayLineItemName`
    display_line_item_id?: string;
};

/**
 * The key is the name of the grouped field.
 *
 * For example, if grouping by EsPCAReportField.DisplayLineName
 * one key could be "JC Decaux Rail D6s"
 */
export type ElasticSearchGroupByFieldResponse = {
    data: Record<string, ElasticSearchPerformanceStats>;
    filters: ElasticSearchAvailableFilters;

    // only present if showStats is set to true on the request
    campaign_stats?: ElasticSearchPerformanceStats;
};

/**
 * The first level key is the date in the format of `yyyy-MM-dd HH:mm`
 *
 * The second level key is the name of the grouped field.
 *
 * For example, if grouping by EsPCAReportField.DisplayLineName
 * one key could be "JC Decaux Rail D6s".
 *
 * Example of day interval:
 * {
 *     "2021-06-28 00:00": {
 *         "Global D48": {...stats},
 *         "JC Decaux Regional Roadside D6s": {...stats}
 *     },
 *     "2021-06-29 00:00": {
 *         "Global D48": {...stats},
 *         "JC Decaux Regional Roadside D6s": {...stats}
 *     }
 * }
 */
export type ElasticSearchHistogramResponse = {
    data: Record<string, Record<string, ElasticSearchPerformanceStats>>;
    filters: ElasticSearchAvailableFilters;

    // only present if showStats is set to true on the request
    campaign_stats?: ElasticSearchPerformanceStats;
};

/**
 * The key is the frame id
 */
export type ElasticSearchFrameIdStatsDataResponse = {
    data: Record<string, ElasticSearchFrameIdData>;
    filters: ElasticSearchAvailableFilters;

    // only present if showStats is set to true on the request
    campaign_stats?: ElasticSearchPerformanceStats;
};

export type ElasticSearchFrameIdMapDataResponse = {
    data: Record<string, PCAMapReportPlayStatsMinimal>;
    filters: ElasticSearchAvailableFilters;

    // only present if showStats is set to true on the request
    campaign_stats?: ElasticSearchPerformanceStats;
};

export type ElasticSearchSummaryResponse = {
    data: {
        // only present if showStats is set to true on the request
        campaign_stats?: ElasticSearchPerformanceStats;

        // key is media owner name
        display_owner: Record<string, ElasticSearchPerformanceStats>;

        // key is line item name
        display_line_item_name: Record<string, ElasticSearchPerformanceStats>;

        // key is `yyyy-MM-dd` date
        daily_stats: Record<string, ElasticSearchPerformanceStats>;

        // key is day of week name, e.g. Sunday
        day_of_week: Record<string, ElasticSearchPerformanceStats>;

        // key is tv region name
        display_tv_region: Record<string, ElasticSearchPerformanceStats>;
    };

    filters: ElasticSearchAvailableFilters;
};

export type ElasticSearchFrameIdData = {
    display_surface_area?: string;
    display_env?: string;
    display_id: number;
    expected_impacts_in_schedule?: number;
    display_line_item_id?: string;
    time_received_out_of_schedule?: number;
    display_type?: number;
    display_lat: number;
    display_resolution?: string;
    kilowatts?: number;
    sum_of_time: number;
    display_conurbation?: string;
    plays_received_in_schedule?: number;
    time_received_in_schedule?: number;
    display_long: number;
    impacts_received_in_schedule?: number;
    sum_of_plays: number;
    expected_plays_in_schedule?: number;
    expected_time_in_schedule?: number;
    impacts_received_out_of_schedule?: number;
    plays_received_unbooked?: number;
    display_tv_region?: string;
    display_town?: string;
    display_owner?: string;
    impacts: number;
    plays_received_out_of_schedule?: number;
    display_filetypes?: string;
    display_sov?: string;
    display_slot_duration?: string;
    impacts_received_unbooked?: number;
    display_line_item_name?: string;
    display_screen_name_summary?: string;
    display_slots?: string;
    display_format?: string;
    time_received_unbooked?: number;
    kgco2?: number;
};
