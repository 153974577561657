import { BaseEntity } from 'c-sdk';
import { NetworkRequestState, SlicePaginationData } from '../../Types';

const slicePaginationData = <Entity extends BaseEntity>(): SlicePaginationData<Entity> => ({
    data: [],
    filters: {},
    includes: [],
    searchables: {},
    loadingState: { state: NetworkRequestState.Idle },
});

export default slicePaginationData;
