import React, { useCallback, useMemo, useEffect } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { useEntityPage } from 'c-hooks';
import { Displays_Pack, PermissionName } from 'c-sdk';
import * as yup from 'yup';
import { useUpdateEntityData } from 'c-data';
import { useForm } from 'react-hook-form';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { OperationalTimings } from 'c-displays/Components/OperationalTimings';
import { DisplayPackForm } from 'c-displays/Pages/DisplaysPackEdit/DisplayPackForm';
import { removeEmptyProperties } from 'c-pagination/Components/EntityFilters/utils';
import ReactGA from 'react-ga';
import DeAllocations from 'c-displays/Components/DeAllocations';

const DisplaysPackEditPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { entity, id } = useEntityPage<Displays_Pack>();
    const t = useCommonTranslation();
    const [value, setValue] = React.useState('1');
    const validationSchema = yup.object().shape({});
    const { update, getUpdatingById } = useUpdateEntityData('Displays_Pack');
    const { state, error } = useMemo(() => getUpdatingById(id), [getUpdatingById, id]);
    const defaultValues = useMemo(
        () => ({
            ...entity,
            filetypes: entity.filetypes.map(fileType => fileType.type),
            sov: entity.slots !== null ? Number(100 / entity.slots).toFixed(10) : null,
        }),
        [entity],
    );
    const handleFormSubmit = useCallback(
        data => {
            const newData = removeEmptyProperties(data);

            update(id, newData);
        },
        [id, update],
    );

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        getValues,
        watch,
    } = useForm({
        defaultValues,
    });
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab
                            label={t('Modules.Displays.DisplaysPackEdit.tabs.details')}
                            value="1"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysPackEdit.tabs.opTimings')}
                            value="2"
                        />
                        <Tab label={t('Modules.Displays.deAllocation')} value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Box pt={2}>
                        <Typography variant="h1" pb={2}>
                            {t('Modules.Displays.DisplaysPackEdit.description')}
                        </Typography>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <DisplayPackForm
                                control={control}
                                state={state}
                                error={error}
                                id={id}
                                setFormValue={setFormValue}
                                getValues={getValues}
                                watch={watch}
                            />
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value="2">
                    <OperationalTimings
                        operationalTimings={entity.operationalTimes}
                        id={id}
                        entityName="Displays_Pack"
                    />
                </TabPanel>
                <TabPanel value="3">
                    <DeAllocations type="Displays_Pack" id={id} />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

const setup: PageEntityConfig<Displays_Pack> = {
    id: 'DisplaysPackEditPage',
    component: DisplaysPackEditPage,
    systemSearch: {
        title: undefined,
        description: undefined,
        icon: undefined,
        type: 'entity',
        aliases: undefined,
        route: PostAuthRoutes.Displays.DisplaysPackEdit,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.Packs.metaTitle',
                link: PostAuthRoutes.Displays.PackList,
            },
        ],
        entityName: 'Displays_Pack',
        nameGen: (entity, id) => String(id),
        permissions: [PermissionName.Admin],
        defaultIncludes: ['operationalTimes', 'filetypes', 'resolutions', 'sov'],
    },
};

export default setup;
