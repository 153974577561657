import { useCommonTranslation } from 'c-translation';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Displays_ScreenAnimationType, Displays_ScreenType } from 'c-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    DynamicAutocomplete,
    SelectField,
    TextField,
} from 'c-components';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient } from 'c-data';

const NewScreenForm = () => {
    const t = useCommonTranslation();
    const methods = useFormContext();
    const { control, setValue: setFormValue, getValues, watch } = methods;
    const { append, fields, remove } = useFieldArray({
        control,
        name: 'resolution',
    });
    const [environmentOptions, setEnvironmentOptions] = useState([]);
    const env = watch('env');
    useEffect(() => {
        if (typeof env !== 'string' && env !== null) {
            setFormValue('env', env?.value);
        }
    }, [env, setFormValue]);
    const { start, data, isLoading } = useAPIClientRequest(
        apiClient.Entities.Displays_Screen.listEnvironments,
    );
    useEffect(() => {
        start();
    }, []);
    useEffect(() => {
        if (data?.data) {
            setEnvironmentOptions((data as any)?.data?.map(env => ({ label: env, value: env })));
        }
    }, [data]);
    if (getValues('partner_id') === undefined) {
        setFormValue('partner_id', 1);
    }

    if (getValues('status') === undefined) {
        setFormValue('status', 0);
    }

    if (getValues('type') === undefined) {
        setFormValue('type', Displays_ScreenType.Display);
    }

    const handleSlotsChange = useCallback(
        e => {
            const slotsValue = e.target.value;
            if (slotsValue != null) {
                const calculatedSov = slotsValue !== '' ? 100 / slotsValue : '';
                setFormValue('slots', slotsValue);
                setFormValue('sov', Number(calculatedSov).toFixed(10));
            }
        },
        [setFormValue],
    );

    const handleSovChange = useCallback(
        e => {
            const sovValue = e.target.value;
            if (sovValue != null) {
                const calculatedSlots = sovValue !== '' ? 100 / sovValue : '';
                setFormValue('sov', sovValue);
                setFormValue('slots', Number(calculatedSlots).toFixed(10));
            }
        },
        [setFormValue],
    );
    const removeResolution = useCallback(
        index => {
            remove(index);
            const updatedResolutions = [...getValues('resolutions')];
            updatedResolutions.splice(index, 1);
            setFormValue('resolutions', updatedResolutions);
        },
        [getValues, remove, setFormValue],
    );
    const market = watch('market_id');

    const ownerField = useMemo(
        () => (
            <Controller
                control={control}
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [control, market, t],
    );
    return (
        <Box pt={2}>
            <AutoGrid xs={5} pb={2}>
                <Controller
                    name="type"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.type')} *
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: Displays_ScreenType.Display,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.display',
                                        ),
                                    },
                                    {
                                        value: Displays_ScreenType.Logo,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.logo',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                />
            </AutoGrid>
            <AutoGrid xs={2} gap={2} pb={2}>
                <Controller
                    name="market_id"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.market')} *
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Market"
                                labelField="name"
                                useLocalEntityData
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    name="partner_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.partner')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_Partner"
                                labelField="name"
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                {ownerField}
                <Controller
                    name="frame_id"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.frameId')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.status')} *
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: 0,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.inactive',
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.active',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                    name="status"
                />
                <Controller
                    name="env"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.environment')}
                            </FormLabel>
                            <DynamicAutocomplete
                                {...field}
                                value={field.value ? field.value : null}
                                setValue={field.onChange}
                                options={environmentOptions}
                                setOptions={setEnvironmentOptions}
                                disabled={isLoading}
                            />
                        </>
                    )}
                />
                <Controller
                    name="animation"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.animation')}
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: Displays_ScreenAnimationType.Static,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.static',
                                        ),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Motion,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.motion',
                                        ),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Subtle,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.subtle',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    name="size"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.size')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="slot_length"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.slotLen')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="slots"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.slots')} *
                            </FormLabel>
                            <TextField {...field} onChange={handleSlotsChange} />
                        </>
                    )}
                />
                <Controller
                    name="sov"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.sov')} *
                            </FormLabel>
                            <TextField {...field} onChange={handleSovChange} />
                        </>
                    )}
                />
                <Controller
                    name="address"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.address')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="town"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.town')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="postcode"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.postCode')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="location"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.location')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="tv_region"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.tvRegion')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="conurbation"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.conurbation')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="lat"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.latitude')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="long"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.longitude')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="surface_area"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.sa')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="frame"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.frame')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="channel"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.channel')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    name="filetypes"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.fileType')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_FileType"
                                labelField="type"
                                valueField="type"
                                multi
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                <Controller
                    name="brochurelink"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.link')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
            </AutoGrid>
            <AutoGrid>
                <Controller
                    name="resolutions"
                    render={() => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.resolutions')}:
                            </FormLabel>
                            {fields.map((resolution, index) => (
                                <Stack key={resolution.id} direction="row">
                                    <Box>
                                        <Stack pr={1}>
                                            <FormLabel>
                                                {t(
                                                    'Modules.Displays.DisplaysDisplayEdit.fields.resolutionWidth',
                                                )}
                                            </FormLabel>
                                            <ControlledFormInput
                                                name={`resolutions[${index}].width`}
                                                render={({ field }) => <TextField {...field} />}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack>
                                            <FormLabel>
                                                {t(
                                                    'Modules.Displays.DisplaysDisplayEdit.fields.resolutionHeight',
                                                )}
                                            </FormLabel>
                                            <ControlledFormInput
                                                name={`resolutions[${index}].height`}
                                                render={({ field }) => <TextField {...field} />}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box p={1}>
                                        <IconButton
                                            sx={{ marginTop: '100%' }}
                                            size="small"
                                            onClick={() => removeResolution(index)}
                                        >
                                            <RemoveIcon fontSize="inherit" color="error" />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            ))}
                            <Box>
                                <IconButton
                                    size="small"
                                    sx={{ marginRight: '86%', marginLeft: '14%', p: 1 }}
                                    onClick={() => append({ width: '', height: '' })}
                                >
                                    <AddIcon fontSize="inherit" color="info" />
                                </IconButton>
                            </Box>
                        </>
                    )}
                />
            </AutoGrid>
        </Box>
    );
};
export default NewScreenForm;
