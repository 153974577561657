import { CampaignDisplay } from 'c-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { CampaignDisplayState } from './CampaignDisplay-types';

const initialState: CampaignDisplayState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: CampaignDisplayState, action: PayloadAction<string>): CampaignDisplayState => state,
};

const slice = createBaseSlice<CampaignDisplay, CampaignDisplayState, typeof additionalReducers>({
    name: 'campaignDisplay',
    entityName: 'CampaignDisplay',
    initialState,
    reducers: additionalReducers,
});

const campaignDisplaySlice = {
    campaignDisplayActions: slice.slice.actions,
    campaignDisplayReducer: slice.slice.reducer,
    campaignDisplayAdapter: slice.adapter,
    campaignDisplaySelectors: slice.selectors,
    campaignDisplayThunks: slice.thunks,
};

export default campaignDisplaySlice;
export const {
    campaignDisplayReducer,
    campaignDisplayActions,
    campaignDisplayAdapter,
    campaignDisplaySelectors,
    campaignDisplayThunks,
} = campaignDisplaySlice;
