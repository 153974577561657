import React, { useCallback, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import { ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SpotlightRecentResource } from 'c-sdk';
import { useEntityData } from 'c-data';
import { ListItemIcon, ListItemText } from 'c-components';
import { useDateUtils } from 'c-hooks';
import { useGetRecentPageConfig } from '../../Hooks';

type Props = {
    id: number;
    onClick: (x: number, y: number) => void;
    x: number;
    y: number;
    active: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        padding: theme.spacing(1),
    },
}));

const SystemSearchRecentPage: React.FC<Props> = ({ id, onClick, active, x, y }) => {
    const { item } = useStyles();
    const { getById } = useEntityData<SpotlightRecentResource>('SpotlightRecentResource');
    const { getConfig } = useGetRecentPageConfig();

    const data = getById({ id });
    const res = useMemo(() => {
        return getConfig(id).page;
    }, [getConfig, id]);

    const onPageClick = useCallback(() => {
        onClick(x, y);
    }, [x, y, onClick]);

    const { formatDateString } = useDateUtils();
    const dateTime = useMemo(
        () => formatDateString({ date: data?.created_at, removeTime: false }),
        [formatDateString, data?.created_at],
    );
    return (
        <ListItem button className={item} onClick={onPageClick} selected={active}>
            <ListItemIcon sx={{ fontSize: '2em' }}>{res?.systemSearch.icon}</ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={data?.label}
                secondary={dateTime}
            />
        </ListItem>
    );
};

export default SystemSearchRecentPage;
