import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from 'c-sdk';
import { Box, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { AutoGrid, Button, Checkbox } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Description } from '@mui/icons-material';
import UpdatedByUserCaption from 'c-components/UpdatedByUserCaption';
import { getCampaignBookingSummaries } from 'c-main/Lib';
import { useBoolean } from 'react-hanger';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';
import CampaignBookingSummaryDialog from '../CampaignBookingSummary/CampaignBookingSummaryDialog';

import CampaignUserEditedBooleanCheckbox from './CampaignUserEditedBooleanCheckbox';

type Props = RenderColumnDataProps<Campaign>;

const CampaignSignOffCell: React.FC<Props> = ({ entity }) => {
    const updateSignOff = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({ id: campaign.id, sign_off: newState } as Campaign),
        [],
    );
    const updateInFlight = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({
                id: campaign.id,
                data_status: { ...campaign.data_status, inflight_report_sent: newState },
            } as Campaign),
        [],
    );
    const updatePCAReport = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({
                id: campaign.id,
                data_status: { ...campaign.data_status, pca_report_sent: newState },
            } as Campaign),
        [],
    );
    const count = useMemo(() => getCampaignBookingSummaries(entity).length, [entity]);
    const dialogState = useBoolean(false);

    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity.id).state === NetworkRequestState.InProgress,
        [entity.id, getUpdatingById],
    );

    const onChange = useCallback(() => {
        update(entity.id, {
            id: entity.id,
            booking_summary_validated: !entity.booking_summary_validated,
        } as Campaign);
    }, [update, entity.id, entity.booking_summary_validated]);

    const { hasAdminPermission } = useUserPermissions();
    return (
        <Stack gap={1}>
            <AutoGrid columnGap={0} flexWrap="nowrap" alignItems="center">
                <Tooltip
                    title={useCommonTranslation(
                        'Modules.Main.Campaigns.DataStatus.table.bookingSummaryValidated',
                    )}
                >
                    <Box>
                        <Checkbox
                            disabled={loading || !hasAdminPermission}
                            checked={entity.booking_summary_validated}
                            label={
                                <Typography variant="caption" noWrap>
                                    {useCommonTranslation(
                                        'Modules.Main.Campaigns.DataStatus.table.bookingSummaryCol',
                                    )}
                                </Typography>
                            }
                            onChange={onChange}
                            isBoolean
                            checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                            icon={loading ? <CircularProgress size={15} /> : undefined}
                            formControlLabelSx={{ mr: 0 }}
                        />
                    </Box>
                </Tooltip>
                <Button
                    variant="text"
                    sx={{ whiteSpace: 'nowrap' }}
                    size="small"
                    onClick={dialogState.setTrue}
                >
                    <Description fontSize="inherit" sx={{ mr: 0.5 }} />
                    {useCommonTranslation(
                        'Modules.Main.Campaigns.DataStatus.table.bookingSummaryFileCount',
                        { count },
                    )}
                </Button>
            </AutoGrid>
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.data_status.inflight_report_sent}
                dateKey="inflight_report_requested_at"
                userIdKey="inflight_report_requested_by"
                label="Modules.Main.Campaigns.DataStatus.table.inflightReportSentCheckboxLabel"
                generateSaveData={updateInFlight}
            />
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.sign_off}
                dateKey="sign_off_updated_at"
                userIdKey="sign_off_updated_by"
                label="Modules.Main.Campaigns.DataStatus.table.signOffCheckboxLabel"
                generateSaveData={updateSignOff}
            />
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.data_status.pca_report_sent}
                dateKey="pca_report_requested_at"
                userIdKey="pca_report_requested_by"
                label="Modules.Main.Campaigns.DataStatus.table.pcaReportSentCheckboxLabel"
                generateSaveData={updatePCAReport}
            />
            <CampaignBookingSummaryDialog
                open={dialogState.value}
                onClose={dialogState.setFalse}
                campaign={entity}
            />

            {entity?.booking_summary_validated_updated_by != null && (
                <UpdatedByUserCaption
                    userId={entity.booking_summary_validated_updated_by}
                    updatedAt={entity.booking_summary_validated_updated_at}
                />
            )}
        </Stack>
    );
};

export default CampaignSignOffCell;
