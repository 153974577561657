import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, Switch } from '@mui/material';
import { RenderColumnDataProps } from 'c-pagination';
import { PermissionName, PopDisplays_LogImportSource } from 'c-sdk';
import { AutoGrid } from 'c-components';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from 'c-data-layer';
import { IfHasAllPermissions } from 'c-auth-module/Components';

type Props = RenderColumnDataProps<PopDisplays_LogImportSource>;

const updatePerms = [PermissionName.Pop_displaysLog_import_sourceUpdate];

const LogFileSourceStatusCell: React.FC<Props> = ({ entity, defaultContent }) => {
    const { update, getUpdatingById } = useUpdateEntityData<PopDisplays_LogImportSource>(
        'PopDisplays_LogImportSource',
    );
    const loading = useMemo(
        () => getUpdatingById(entity?.id).state === NetworkRequestState.InProgress,
        [entity?.id, getUpdatingById],
    );

    const onChange = useCallback(() => {
        if (entity) {
            update(entity.id, {
                id: entity.id,
                active: !entity.active,
            } as PopDisplays_LogImportSource);
        }
    }, [update, entity]);

    return (
        <IfHasAllPermissions
            otherwise={
                <Box width="100%" display="flex" justifyContent="center">
                    {defaultContent}
                </Box>
            }
            permissions={updatePerms}
        >
            <AutoGrid columnGap={2}>
                <Switch checked={entity.active} onChange={onChange} size="small" />
                <CircularProgress size={15} sx={{ visibility: loading ? 'visible' : 'hidden' }} />
            </AutoGrid>{' '}
        </IfHasAllPermissions>
    );
};

export default LogFileSourceStatusCell;
