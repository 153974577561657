import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormWrapper } from 'c-components';
import * as yup from 'yup';
import { generatePath, useHistory } from 'react-router-dom';
import { usePrevious } from 'react-hanger';
import { PostAuthRoutes } from 'c-routes';
import { omit } from 'ramda';
import { numberOfDays } from 'c-lib';
import { useCreateEntity } from 'c-data';
import { Buyer, Campaign } from 'c-sdk';
import { campaignLineItemSettings } from 'c-main/Lib';
import { CampaignFormNames, NewCampaignFormSchema } from 'c-main/Types';
import NewCampaignStepper from './NewCampaignStepper';
import { stepOneSchema } from './types';
import NewCampaignCreateState from './NewCampaignCreateState';

type Props = {
    defaultValues?: Partial<NewCampaignFormSchema>;
};

/**
 * just for development so I can start with a prefilled complete form
 */
const devNewCampaignDefaults: NewCampaignFormSchema = {
    start_datetime: '2022-05-10',
    end_datetime: '2022-05-20',
    name: 'dev test',
    reference: 'dev test ref',
    buyer_id: 13,
    days: {},
    start_dates: {},
    end_dates: {},
    line_items: [
        '531f90ac-680b-42cb-9122-c33acaf690f6',
        '60205a4c-723c-4b3f-bced-df84095a5b14',
        '694a6ea6-0c7a-4efb-b394-d69f671d8f83',
        '36809724-06e4-4151-9b2c-6442ccc29352',
        'ef2384a1-73dd-4b59-a3a1-bf2aa8150048',
        '35c65343-a58b-4d01-ad66-f3c15c286f6a',
        'b0752fa2-1959-4d25-9a95-e4d544153b55',
        '00723ce3-e981-49cc-974e-7db90312fd66',
        '19ce262a-9a52-4044-bf44-f62bd8a6f0b1',
        '0ec0e73a-c7f6-4c27-ad19-9653094c3ef9',
        '5008622c-12de-4c29-a92f-8dff220e3dc4',
        'cdb89cc9-0783-4e5c-8586-748342205b9f',
        '123c2358-f5a5-4f2a-b5be-8cccbccaa5a0',
        'a926b774-071c-4d26-969f-93c781cadffd',
        '5f750eca-b96a-43be-9f3d-28ff1288eaed',
        '14c950b8-2c38-492a-8fd4-5cf8dd1af579',
        '9a9b2b58-35bc-4caf-b798-6423a8858e64',
        '7e31a289-b1ed-4914-b2df-792da57e4251',
        '6e041741-7144-4a58-aba2-bccd800a9df8',
        '4834abd0-6265-4a0d-a534-64a986a4de08',
        '6d41b880-7979-458f-9320-c9cc32acb206',
        '0dfcb7fb-009f-4325-bf93-aaa121a9922d',
        'b0ab0d7b-ba89-4cf5-baa1-602987bdae54',
        '36167cb3-ff26-4220-8698-9abd1f1a2850',
        'aa7d385c-cec3-4a5c-a825-ace20cc36bec',
        '0b2e0159-3ec6-48ae-b0d9-7ff08bafacf6',
        '57a11b59-e5ed-499c-8203-d070c671e51f',
        'db690139-7692-40c8-9794-c041f1e7674a',
        '2b3ecc84-3236-4c19-a8be-0e2933370d77',
        'd18e005c-7e4b-4495-97f3-29e20162bad8',
        '5e0e15f7-612d-4849-b36b-7b4005cd71ec',
        'f43f9400-3b18-45af-a0ff-e6ea5d962da4',
        '8ba125bb-3fec-4006-afe5-974f74bc2753',
        '32480b8f-164d-43c5-9656-197ee70785a9',
        'a734de12-c668-46b7-9385-d456e4cf36b3',
        '85b0c28c-3bec-48c8-a2c4-f5e1873b8afd',
        '5a03b222-b0f4-40da-b24d-0b1e14aef659',
        '3c70699b-ed23-40a8-b8e0-081f0972cf0d',
        'b78641ce-fe30-4a0c-8512-c7bbcd9f368f',
        'cc6b9332-1f65-449e-9adb-27597a787767',
        '5dd62228-08d0-4f6e-8009-17a568232704',
    ],
};

const NewCampaignForm: React.FC<Props> = ({ defaultValues = null }) => {
    const { newestCreatedId, createEntity, saveState, saveError, resetCreateState } =
        useCreateEntity('Campaign');
    const $history = useHistory();
    const prevNewestId = usePrevious(newestCreatedId);

    const onSubmit = useCallback(
        async (data: NewCampaignFormSchema) => {
            const maxDays = numberOfDays(
                new Date(data.start_datetime),
                new Date(data.end_datetime),
            );
            const modifiedFormData = {
                ...omit(['start_dates', 'end_dates', 'days'], data),
                line_items: campaignLineItemSettings(data, maxDays),
                [CampaignFormNames.agency_name]: data[CampaignFormNames.client_name],
            };

            createEntity(modifiedFormData as unknown as Campaign);
        },
        [createEntity],
    );

    useEffect(() => {
        resetCreateState();
        return () => {
            resetCreateState();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (prevNewestId == null && newestCreatedId != null) {
            $history.push(
                generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id: newestCreatedId }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newestCreatedId]);

    const [step, setStep] = useState(0);
    const [buyer, setBuyer] = useState<Buyer | null>(null);

    const buyerReferenceOverrides = useMemo(() => {
        if (
            buyer != null &&
            (buyer?.reference_prefix?.length >= 0 || buyer?.reference_type?.length >= 0)
        ) {
            return {
                [CampaignFormNames.reference]: yup
                    .string()
                    .required()
                    // .min(buyer?.reference_min_length ?? 3)
                    // .max(buyer?.reference_max_length ?? 99)
                    .test('', 'Invalid ref', val => {
                        const valLength = val?.length ?? 0;
                        const minLength = buyer?.reference_min_length ?? 1;
                        const maxLength = buyer?.reference_max_length ?? Infinity;

                        const passedLength = valLength >= minLength && val?.length <= maxLength;
                        const passedPrefix = (val ?? '').startsWith(buyer?.reference_prefix ?? '');

                        return passedLength && passedPrefix;
                    }),
            };
        }

        return {};
    }, [buyer]);
    const validation = useMemo(() => {
        const validations = [stepOneSchema(buyerReferenceOverrides as any)];
        return validations?.[step] ?? null;
    }, [step, buyerReferenceOverrides]);

    return (
        <FormWrapper<NewCampaignFormSchema>
            onSubmit={onSubmit}
            validationSchema={(validation as any) ?? null}
            // validationSchema={validations?.[step] ?? null}
            formOptions={defaultValues != null ? { defaultValues } : undefined}
            // formOptions={{ defaultValues: devNewCampaignDefaults }}
        >
            <NewCampaignStepper
                onStepChange={setStep}
                onBuyerChange={setBuyer}
                campaignCreate={
                    <NewCampaignCreateState
                        newCampaignState={saveState}
                        newlyCreatedCampaignId={newestCreatedId}
                    />
                }
            />
        </FormWrapper>
    );
};

export default NewCampaignForm;
